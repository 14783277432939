import { SINGLE_COLLECTION_UPDATE_STATE } from '../actions/single-collection/vars';

const initialState = {
    'id': '',
    'hash_id': '',
    'image': '', // collection image
    'title' : '',
    'description': '',
    'page': 'collection_details',
    'properties' : [],
    'offset':0,
    'element_height' : 0,
    'meta': {},
    dataLoaded: false,
    'flag' : {
        no_more_result : false,
        api : {
            is_loading: false
        }
    }
}

const fnc = (state = initialState, action) => {
    switch (action.type) {
        case SINGLE_COLLECTION_UPDATE_STATE:
            return Object.assign({}, state, action.payload);
           
        case 'CLEAR_REDUX_STATE':
            return { ...state, dataLoaded: false }
            
        default:
            return state;
    }
}
export default fnc;