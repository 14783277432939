import {SET_STATE_SIGNUP_OBJ} from './../actions/signup/vars';
const initialState = {
    model: {
        last_name:'',
        first_name:'',
        email:'',
        userId:'',
        password:'',
        confirm:''
    },
    flag:{
        field_valid :{
            first_name:false,
            last_name:false,
            email : false,
            password:false,
            confirm:false,
        },
        signin:false,
        status:false,
        show:false,
        validation:true,
        loading:false,
        termConditionModal:false,
        privacyPolicyModal:false,
        gender_validation:false
    },
    add_email:0,
    body_bg_color: '#fff',  
    active_request_count: '',
    gender:'',         
    source:'0',
    referral_code:'',
    message:'',
    height:'100%',
    access_token:'',
    currency:'',
    device_type:'mobileWeb',
    base_currency:'',
    email:'',
    first_name:'',
    profile_image:'',
    last_name:'',
    is_mobile_verified:'',
    is_user_referred:'',
    mobile:'',
    afterLogin: ''
}

const signupEmail = (state = initialState, action) => {
    switch (action.type) {
        case SET_STATE_SIGNUP_OBJ :
            let newState = Object.assign({}, state, action.payload);
            return newState;

        default:
            newState = Object.assign({}, state);
            return newState;
    }
}
export default signupEmail;