import React from 'react';
import {Link} from 'react-router-dom';
import { connect } from 'react-redux'
import { hasAccessToken } from '../../services';

class PasswordChanged extends React.Component {
   constructor(props) {
        super(props);
        this.state = {
            'body_bg_color': '#00ba8c'
        }
        if (hasAccessToken()) {
            if (props.signup.afterLogin.length > 0) {
                this.props.history.replace(props.signup.afterLogin)
           } else {
                this.props.history.replace('/')
           }
        }
    }
    componentDidMount(){
        window.a = this;
        //console.log(this.state.body_bg_color, 'mount');
        document.body.style.backgroundColor = this.state.body_bg_color;
        document.body.setAttribute('data-color', this.constructor.name);
    }
    componentWillUnmount(){
        //console.log(this.state.body_bg_color, 'unmount');
        if (document.body.getAttribute('data-color') === this.constructor.name) {
            document.body.style.backgroundColor = null;
        }
    }
    render() {
        return (
            <div className="password-wrapper">
                <div className="img reset"></div>
                <div className="text">Whoo Whoo!</div>
                <div className="small-text">Your password has been reset sucessfully!<br/> Now login with your new password.</div>
                <Link to="/login/email"><div className="btn white link margin">Login</div></Link>
            </div>
        );
    }
}

export default connect(state => ({ signup: state.signupEmail }))(PasswordChanged);