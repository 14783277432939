import React from 'react';
import {Link} from 'react-router-dom';
import ReactDOM from 'react-dom';
import {Button} from 'react-materialize'
import PrepaymentBookingDetails from '../../components/prepayment/prepaymentBookingDetails';
import Invoice from '../../components/prepayment/invoice';
import PaymentSection from '../../components/prepayment/payment';
import Promocode from "../../components/prepayment/modal/promocode";
import CouponConfirmation from "../../components/prepayment/modal/couponConfirmModal";
import WalletModal from "../../components/prepayment/modal/wallet";
import TermCondition from "../../components/modal/term/term";
import SelectGuestsModal from '../../components/home/searchBarContainer/selectGuestsModal.js';
import DateRangeCalendar from '../../components/home/searchBarContainer/dateRangeCalendar.js';
import noInternet from "../../static/img/infoWindow/noInternet.svg";
import RequestSentModel from "../../components/request/request_sent.js";
import { connect } from 'react-redux';

//services
import {getRequestPrepaymentDataApi, saveUrlLogin, getRequestPaymentApi, hasAccessToken, addClassToHtml,removeClassToHtml} from '../../services/index.js';

import {trackPageView, trackError} from '../../services/tracker/events.js';

/**
 * Actions
 */
import { updatePropertyState } from '../../actions/property/actions';
import { updateBookingDataState } from '../../actions/booking-data/actions';
import { updateSearchState } from '../../actions/search/actions';
import { updatePrepaymentState } from '../../actions/prepayment/actions';
import { updateErrorState } from '../../actions/error/actions';

class PrepamentPropertyDetails extends React.Component {
	constructor(props) {
		super(props);

		this.page="prepayment_request";

		this.promocodeOpen = this.promocodeOpen.bind(this);
		this.promocodeClose = this.promocodeClose.bind(this);
		this.couponConfirmation = this.couponConfirmation.bind(this);
		this.walletModalOpen = this.walletModalOpen.bind(this);
		this.walletModalClose = this.walletModalClose.bind(this);
		this.confirmWallet = this.confirmWallet.bind(this);
		this.savepromocode = this.savepromocode.bind(this);
		this.getPaymentMathod = this.getPaymentMathod.bind(this);
		this.opentermCondition = this.opentermCondition.bind(this);
		this.submitPropertyDataToApi = this.submitPropertyDataToApi.bind(this);
		this.makePayment = this.makePayment.bind(this);

		this.openMaxNightsAlert = this.openMaxNightsAlert.bind(this);
		this.closeMaxNightsAlert = this.closeMaxNightsAlert.bind(this);
		this.openUnitAlert = this.openUnitAlert.bind(this);
		this.closeUnitAlert = this.closeUnitAlert.bind(this);
		this.modalRouting = this.modalRouting.bind(this);
		this.goBack = this.goBack.bind(this);
		this.openUnavailableDate = this.openUnavailableDate.bind(this);
		this.closeUnavailableDate = this.closeUnavailableDate.bind(this);
		this.closeErrorMessage_modal = this.closeErrorMessage_modal.bind(this);
	}

	walletModalOpen(){
		if(this.props.prepayment.discount_section.discount.discount_type ==='wallet' && this.props.prepayment.discount_section.discount.discount_valid){
			this.props.updatePrepaymentState({apply_wallet:0}).then( () => {
				let urlParams = '?payment_method='+ this.props.prepayment.payment_method +'&apply_wallet=0';
				this.getRequestPrepaymentApi(this.props.match.params.hashId + urlParams);
				
				ReactDOM.findDOMNode(this.refs['wallet']).checked = 0;
			});
		}else {
			if(this.props.prepayment.discount_type === 'coupon' && this.props.prepayment.discount_section.discount.discount_valid){
				window.location.hash = 'walletModal';
			}else{
				this.confirmWallet();
			}
		}
	}

	walletModalClose(){
		if(this.props.prepayment.discount_section.discount.discount_type ==='coupon' && this.props.prepayment.discount_section.discount.discount_valid){
			ReactDOM.findDOMNode(this.refs['promo']).checked = 1;
		}
		this.props.updatePrepaymentState({ apply_wallet: 0 }).then(() => {
			this.goBack();
			ReactDOM.findDOMNode(this.refs['wallet']).checked = 0;
		});
	}

	confirmWallet(back){
		this.props.updatePrepaymentState({ apply_wallet: 1 }).then(() => {
			let urlParams ='?apply_wallet='+this.props.prepayment.apply_wallet +'&payment_method='+ this.props.prepayment.payment_method;
			this.getRequestPrepaymentApi(this.props.match.params.hashId + urlParams);
			if(back){
				this.goBack();
			}
		})
	}

	promocodeOpen(){
		if(this.props.prepayment.discount_section.discount.discount_type ==='coupon' && this.props.prepayment.discount_section.discount.discount_valid){
			let urlParams = '?payment_method='+ this.props.prepayment.payment_method + '&coupon_code=';
			this.getRequestPrepaymentApi(this.props.match.params.hashId + urlParams);
			ReactDOM.findDOMNode(this.refs['promo']).checked = 0;
		}else {
			if (this.props.prepayment.discount_type === 'wallet') {
				window.location.hash = 'couponConfirmation';
			} else {
				window.location.hash = 'promocodeWallet'
			}
		}
	}

	promocodeClose(){
		if(this.props.prepayment.discount_section.discount.discount_type ==='wallet' && this.props.prepayment.discount_section.discount.discount_valid){
			ReactDOM.findDOMNode(this.refs['wallet']).checked = 1;
		}
		ReactDOM.findDOMNode(this.refs['promo']).checked = 0;
		this.goBack();
	}

	savepromocode(promocode){
		let urlParams = '?coupon_code='+ promocode +'&payment_method='+ this.props.prepayment.payment_method;
		this.getRequestPrepaymentApi(this.props.match.params.hashId + urlParams);
		this.goBack();
	}

	couponConfirmation() {
		let flag = this.props.prepayment.flag;
		flag.modal.promocodes = true;
		flag.modal.couponConfirmation = false;
		this.props.updatePrepaymentState({ 'flag': flag })
	}


	getPrepaymentApiDataSuccess(response) {
		const this_el = this;
		this.props.updateBookingDataState({
			'location': response.data.data.property_section.tile.location.location_name,
			'area': response.data.data.property_section.tile.location.area,
			'city': response.data.data.property_section.tile.location.city,
			'state': response.data.data.property_section.tile.location.state,
			'country': response.data.data.property_section.tile.location.country,
			'lat': response.data.data.property_section.tile.location.latitude,
			'lng': response.data.data.property_section.tile.location.longitude,
			'room_type': response.data.data.property_section.tile.room_type,
			'checkin_date': this.toDate(response.data.data.property_section.start_date),
			'checkout_date': this.toDate(response.data.data.property_section.end_date),
			'guest_count': response.data.data.property_section.guests,
			'unit_count': response.data.data.property_section.required_units,
		});
		this.props.updatePropertyState({
			'property_title': response.data.data.property_section.tile.property_title,
			'title': response.data.data.property_section.tile.title,
			'property_type': response.data.data.property_section.tile.property_type,
			'max_nights': response.data.data.property_section.max_nights,
			'min_nights': response.data.data.property_section.min_nights,
			'available_units': response.data.data.property_section.available_units,
			'guests_per_unit': response.data.data.property_section.guests_per_unit,

		});

		let flag = Object.assign({}, this.props.prepayment.flag);
		flag.apiData = true;
		flag.footerPriceVisibility = true;
		this.props.updatePrepaymentState({
			'property_images': response.data.data.property_section.tile.property_images,
			'property_section': response.data.data.property_section,
			'invoice': response.data.data.invoice,
			'discount_section': response.data.data.discount_section,
			'footer': response.data.data.footer_data.footer,
			'payment_methods': response.data.data.payment_methods,
			'left_div': response.data.data.footer_data.left_div,
			'right_div': response.data.data.footer_data.right_div,
			'is_mobile_verified': response.data.data.user_section.is_mobile_verified,
			'is_user_referred': response.data.data.user_section.is_user_referred,
			'promocode': response.data.data.discount_section.discount.discount_code,
			'discount_type': response.data.data.discount_section.discount.discount_type,
			'bookable_as_unit': response.data.data.property_section.bookable_as_unit,
			'payment_method': response.data.data.invoice.selected_payment_method,
			'misconception': response.data.data.misconception,
			'misconception_code': response.data.data.misconception_code,
			'flag': flag,
			dataLoaded: true,
			cacheHash: this.props.match.params.hashId
		}).then(() => {
			if(response.data.data.discount_section.discount.discount_type.length > 0){
				if(this.props.prepayment.discount_section.discount.discount_type ==='coupon' && this.props.prepayment.discount_section.discount.discount_valid){
					ReactDOM.findDOMNode(this.refs['promo']).checked = 1;
					this.props.updatePrepaymentState({apply_wallet:0});
				}else {
					ReactDOM.findDOMNode(this.refs['promo']).checked = 0;
				}
				if(this.props.prepayment.discount_section.discount.discount_type ==='wallet' && this.props.prepayment.discount_section.discount.discount_valid) {
					ReactDOM.findDOMNode(this.refs['wallet']).checked = 1;
					this.props.updatePrepaymentState({apply_wallet:1});
				}
			}

			let urlParams = '?checkin=' + this.dateConvertor(this.props.booking.checkin_date) + '&checkout=' + this.dateConvertor(this.props.booking.checkout_date) + '&guests=' + this.props.booking.guest_count + '&units=' + this.props.booking.unit_count + '&payment_method=' + this.props.prepayment.payment_method;
			if (this.props.prepayment.discount_type === 'coupon') {
				urlParams = urlParams + '&coupon_code=' + this.props.prepayment.promocode + '&discount_type=' + this.props.prepayment.discount_type;
			} else if (this.props.prepayment.discount_type === 'wallet') {
				urlParams = urlParams + '&apply_wallet=' + this.props.prepayment.apply_wallet + '&discount_type=' + this.props.prepayment.discount_type;
			}

			let flag = Object.assign({}, this_el.props.error.flag);
			flag.network = false;
			flag.server = false; 
			this_el.props.updateErrorState({
				flag : flag
			});
			
			this.props.history.replace('/prepayment/request/' + this.props.match.params.hashId + urlParams);
			
		});
	}

	getPrepaymentApiDataError(error) {
		const this_el = this;
		// console.log('error',error);
		if (typeof error.response !== "undefined" && error.response.status === 1000) {
			let error_flag = Object.assign({}, this_el.props.error.flag);
			error_flag.network = true;
			this.props.updateErrorState({ flag: error_flag });
		}else if(error.response.status === 500){
			//console.log('dgdg',error);
			let flag =  this.props.error.flag;
			flag.server = true; 
			this.props.updateErrorState({ flag: flag });
			
		} else {
			if (typeof error.response.data !== "undefined" && error.response.data.error.length > 0) {

				let a = error.response.data.error.map(function (msg) {
					return msg.message
				})

				this.props.updatePrepaymentState({
					errorMessage: a.join(', '),
				}).then(() => {
					this.openErrorMessage_modal();
				})

			} else {
				trackError(error);
			}
		}
	}

	getRequestPrepaymentApi(data){
		let this_el = this;
		//console.log("request", data);
		getRequestPrepaymentDataApi(data).then(function(response){
			// success
			this_el.getPrepaymentApiDataSuccess(response);
		}).catch(function(error){
			// error
			this_el.getPrepaymentApiDataError(error);
		});
	}

	getPaymentMathod(payment_method){
		let flag = Object.assign({}, this.props.prepayment.flag);
		flag.footerPriceVisibility = false;

		this.props.updatePrepaymentState({
			'payment_method': payment_method,
			'flag': flag
		}).then(() => {
			let urlParams = '?&payment_method='+ payment_method;
			if(this.props.prepayment.discount_type === 'coupon'){
				urlParams = urlParams +'&coupon_code='+ this.props.prepayment.promocode;
			}else if(this.props.prepayment.discount_type === 'wallet'){
				urlParams = urlParams +'&apply_wallet='+this.props.prepayment.apply_wallet;
			}else if(this.props.prepayment.discount_type === ""){
				urlParams = urlParams +'&apply_wallet='+this.props.prepayment.apply_wallet;
			}
			this.getRequestPrepaymentApi(this.props.match.params.hashId + urlParams);
		});
	}
   
	componentDidMount(){

		let urlParams= "?";
		if(this.props.prepayment.payment_method !== ""){
			urlParams = urlParams + 'payment_method='+ this.props.prepayment.payment_method;
		}
		if(this.props.prepayment.discount_type === 'coupon'){
			urlParams = urlParams +'&coupon_code='+ this.props.prepayment.promocode;
		}else if(this.props.prepayment.discount_type === 'wallet'){
			urlParams = urlParams +'&apply_wallet='+this.props.prepayment.apply_wallet;
		}

		this.getRequestPrepaymentApi(this.props.match.params.hashId + urlParams);       
		
		// on url change fuction
		let this_el = this;
		window.onpopstate = function(e) {
			this_el.modalRouting(e.currentTarget.location.hash);
		};

		// fuction to check if any modal open
		this.modalRouting(this.props.history.location.hash);

		trackPageView(this.props.tracker, this.page);

	}


	toDate(dateStr) {
		if(dateStr !== null) {
			var parts = dateStr.split("-");
			return new Date(parts[2], parts[1] - 1, parts[0]);
		}else{
			return 1;
		}
	}

	dateConvertor(data){
		if(data !== 1){
			let dd = data.getDate();
			let mm = data.getMonth()+1; //January is 0!

			let yyyy = data.getFullYear();
			if(dd<10){
				dd='0'+dd;
			} 
			if(mm<10){
				mm='0'+mm;
			} 
			return dd+'-'+mm+'-'+yyyy;
		}
	}

	shakeGuestCount() {
		const this_el = this;
		this.props.updateSearchState({ shake_animation: 'shakeAnimation' })
		setTimeout(function () {
			this_el.props.updateSearchState({ shake_animation: '' })
		}, 500)
	}

	shakeUnitCount() {
		const this_el = this;
		this.props.updatePropertyState({ shake_animation_unit: 'shakeAnimation' })
		setTimeout(function () {
			this_el.props.updatePropertyState({ shake_animation_unit: '' })
		}, 500)
	}


	openDateRangeCalendar() {
		const this_el = this;
		let flag = Object.assign({}, this_el.props.search.flag);
		flag.modal.date_range = true;
		this_el.props.updateSearchState({
			flag: flag
		});
	}

	submitPropertyDataToApi() {
		const this_el = this;
		if (this.props.booking.checkin_date === 1) {
			this.openDateRangeCalendar();
		} else if (this.props.booking.guest_count < 1 || this.props.booking.unit_count < 1) {
			if (this.props.booking.guest_count < 1) {
				this.shakeGuestCount();
			}
			if (this.props.booking.unit_count < 1) {
				this.shakeUnitCount();
			}


			if ("vibrate" in navigator) {
				navigator.vibrate(500);

			}
		} else {
			let urlParams = '?&checkin=' + this.dateConvertor(this.props.booking.checkin_date) + '&checkout=' + this.dateConvertor(this.props.booking.checkout_date) + '&guests=' + this.props.booking.guest_count + '&units=' + this.props.booking.unit_count + '&payment_method=' + this.props.prepayment.payment_method;

			if (this.props.prepayment.discount_type === 'coupon') {
				urlParams = urlParams + '&coupon_code=' + this.props.prepayment.promocode;
			} else if (this.props.prepayment.discount_type === 'wallet') {
				urlParams = urlParams + '&apply_wallet=' + this.props.prepayment.apply_wallet;
			}
			this.getRequestPrepaymentApi(this.props.match.params.hashId + urlParams);
			let flag = Object.assign({}, this_el.props.search.flag);
			flag.modal.select_guest = false;
			this_el.props.updateSearchState({
				flag: flag
			});

			removeClassToHtml();
		}
	}

	getRequestPaymentDataApi(data){
		const this_el = this;
		getRequestPaymentApi(data)
		.then(function(response){
			// success
			//console.log(response);
			if(window.location.port){
				window.location.href = response.data.data.payment_url + '&origin='+ window.location.protocol+'//'+window.location.hostname+':'+window.location.port;
			}else{
				window.location.href = response.data.data.payment_url + '&origin='+ window.location.protocol+'//'+ window.location.hostname;
			}
		})
		.catch(function(error){
			// error
			//console.log(error);
			if(typeof error.response.data !== undefined && error.response.data.error.length > 0 ){
					
				let a = error.response.data.error.map(function (msg){
					return msg.message
				})

				let flag = Object.assign({}, this_el.props.prepayment.flag);
				flag.refreshPage = true;
				this_el.props.updatePrepaymentState({
					errorMessage: a.join(', '),
					flag: flag
				}).then(() => {
					this_el.openErrorMessage_modal();
				})

			}else{
				trackError(error)
			}
		});
	}

	makePayment(e){
		let urlParams = '?payment_method='+ this.props.prepayment.payment_method;
		if(this.props.prepayment.discount_type === 'coupon'){
			urlParams = urlParams +'&coupon_code='+ this.props.prepayment.promocode+'&discount_type='+ this.props.prepayment.discount_type;
		}else if(this.props.prepayment.discount_type === 'wallet'){
			urlParams = urlParams +'&apply_wallet='+this.props.prepayment.apply_wallet+'&discount_type='+ this.props.prepayment.discount_type;
		}else if(this.props.prepayment.discount_type === ''){
			urlParams = urlParams +'&apply_wallet='+this.props.prepayment.apply_wallet;
		}
			
		let request_hash_id = this.props.match.params.hashId;

		if((window.innerHeight + window.scrollY + 100) >= document.body.scrollHeight){
			let args = {
				request_hash_id: request_hash_id,
				payment_method:this.props.prepayment.invoice.selected_payment_method,
				payable_amount:parseInt(this.props.prepayment.footer.final_amount, 10),
				coupon_code:this.props.prepayment.promocode,
				apply_wallet:this.props.prepayment.apply_wallet,
				discount_type: this.props.prepayment.discount_type
			}
			this.getRequestPaymentDataApi(args);
			e.target.classList.add('disable');
			this.props.history.replace('/prepayment/request/'+ request_hash_id + urlParams);
		} else {
			window.scrollTo(0,document.body.scrollHeight);
		}
		
	}

	openMaxNightsAlert(){
		this.refs.max_nights_alert_overlay.classList.add('true')
	}

	closeMaxNightsAlert(){
		this.refs.max_nights_alert_overlay.classList.remove('true')
	}

	openUnavailableDate(){
		this.refs.unavailable_date_overlay.classList.add('true')
	}

	closeUnavailableDate(){
		this.refs.unavailable_date_overlay.classList.remove('true')
	}

	openUnitAlert(){
		this.refs.unitAlert.classList.add('true')
	}

	closeUnitAlert(){
		this.refs.unitAlert.classList.remove('true')
	}
	openErrorMessage_modal(){
		this.refs.errorMessage_modal.classList.add('true');
	}
	closeErrorMessage_modal(){
		if(this.props.prepayment.flag.refreshPage){
			this.reload();
		} else {
			this.refs.errorMessage_modal.classList.remove('true');
			this.props.history.replace('/')
		}
	}
	reload(){
		document.location.reload();
	}

	goBack(){
		this.props.history.goBack();
	}

	modalRouting(hash) {
		addClassToHtml();
		let flag = this.props.prepayment.flag;
		switch (hash) {
			case '#termCondition':
				flag.modal.termCondition = true;
				break;

			case '#walletModal':
				flag.modal.walletModal = true;
				break;

			case '#promocodeWallet':
				flag.modal.promocodes = true;
				break;

			case '#couponConfirmation':
				flag.modal.promocodes = false;
				flag.modal.couponConfirmation = true;
				break;

			default:
				flag.modal.termCondition = false;
				flag.modal.walletModal = false;
				flag.modal.promocodes = false;
				flag.modal.couponConfirmation = false;
				removeClassToHtml();
		}
		this.props.updatePrepaymentState({ 'flag': flag })
	}

	opentermCondition() {
		window.location.hash = 'termCondition'
	}

    render() {
        const this_el = this;
        return (
            <div className="prepayment">
                <header className="header active">
                    <div className="row">
                        <div className="col-12 text-left">
                            <span className="back-icon" onClick={this.goBack}></span>
                            <span className="middle-text text-center">Booking details</span>
                        </div>                                                      
                    </div>
                </header>

                {!this.props.error.flag.network || this.props.prepayment.dataLoaded ?
					<div>
						{!this.props.error.flag.server ?
						<div>
						<PrepaymentBookingDetails request={true}/>

						{this_el.props.prepayment.flag.apiData &&
							<div>
							{/* invoice details section*/}
							<Invoice/>

							{/* wallet and coupon checkbox section*/}
							<section className="wallet-coupon">
								<div className="heading">Have a coupon code or wallet balance?</div>
								<div className="check-box-wrap">  
									<div className="box">
										<div className="row">
											<div className="col-10">
												<span className="wallet-icon"></span>
												{this.props.prepayment.discount_section.wallet.wallet_money > 0
													?
													<span className="text">Use {this.props.prepayment.discount_section.wallet.wallet_currency_symbol}{(this.props.prepayment.discount_section.wallet.wallet_money % 1 !== 0) ? (this.props.prepayment.discount_section.wallet.wallet_money.toFixed(2)) : (this.props.prepayment.discount_section.wallet.wallet_money.toLocaleString('en'))} from My Wallet</span>
													:
													<span className="text">No balance in wallet</span>
												}
												{this.props.prepayment.discount_section.discount.discount_type === 'wallet' &&
													<span>
														{this.props.prepayment.discount_section.discount.discount_valid
														?
														<span className="success-msg">{this.props.prepayment.discount_section.discount.discount_message}</span>
														:
														<span className="error-msg">{this.props.prepayment.discount_section.discount.discount_message}</span>
														}
													</span>
												}
											</div>
											<div className="col-2 text-right">
												{this.props.prepayment.discount_section.wallet.applicable === 1
													?
													<span>
													<input type="radio" name="checkbox" id="radio4" onChange={() => this.walletModalOpen()} className="css-checkbox" ref='wallet' />
													<label htmlFor="radio4" className="css-label radGroup2"></label>
													</span>
													:
													<span>
														<input type="radio" name="checkbox" id="radio4" className="css-checkbox disable" disabled/>
														<label htmlFor="radio4" className="css-label disable radGroup2"></label>
													</span>
												}
											</div>
										</div>
									</div>
									<div className="box" >
										<div className="row">
											<div className="col-10">
												<span className="promo-code"></span>
												<span className="text">Apply promo code</span>
												{this.props.prepayment.discount_section.discount.discount_type === 'coupon' && 
													<span>
														{this.props.prepayment.discount_section.discount.discount_valid
														?
														<span className="success-msg">{this.props.prepayment.discount_section.discount.discount_message}</span>
														:
														<span className="error-msg">{this.props.prepayment.discount_section.discount.discount_message}</span>
														}
													</span>
												}
											</div>
											<div className="col-2 text-right">
												{this.props.prepayment.discount_section.coupon.applicable === 1
												?
												<span>
												<input type="radio" name="checkbox" id="radio5" onChange={() => this.promocodeOpen()} ref='promo' className="css-checkbox" />
												<label htmlFor="radio5" className="css-label radGroup2" ></label>
												</span>
												:
												<span>
												<input type="radio" name="checkbox" id="radio5"  className="css-checkbox" />
												<label htmlFor="radio5" className="css-label disable  radGroup2" ></label>
												</span>
												}
											</div>
										</div>
									</div>
								</div>
							</section>

							{/* Payment section */}
							<PaymentSection getPaymentMathod={this.getPaymentMathod}/>
					
							{this.props.prepayment.left_div.title.length > 0 && this.props.prepayment.right_div.title.length > 0 ?
								<section>
									<div className="row extra-feature">
										<div className="col-6 pull-left">
											<h4>{this.props.prepayment.left_div.title}</h4>
											<p>{this.props.prepayment.left_div.text}</p>
										</div>
										<div className="col-6 pull-left">
											<h4>{this.props.prepayment.right_div.title}</h4>
											<p>{this.props.prepayment.right_div.text}</p>
										</div>
									</div>
								</section>
							:
								<section>
									{this.props.prepayment.left_div.title.length > 0 &&
									<div className="row extra-feature full-length">
										<div className="col-12 pull-left">
											<h4>{this.props.prepayment.left_div.title}</h4>
											<p>{this.props.prepayment.left_div.text}</p>
										</div>
									</div>
									}
									{this.props.prepayment.right_div.title.length > 0 &&
									<div className="row extra-feature full-length">
										<div className="col-12 pull-left">
											<h4>{this.props.prepayment.right_div.title}</h4>
											<p>{this.props.prepayment.right_div.text}</p>
										</div>
									</div>
									}
								</section>
							}

							<section className="term">					
								<span className='text'>By procedding, I agree to accept GuestHouser’s <br/><span className="green-color" onClick={() => this.opentermCondition()}>T&C, Cancellation and Refund Policy</span></span>
							</section>

							<section className="pay-btn">
								<div className="row">
									{this.props.prepayment.flag.footerPriceVisibility 
										?
											<div className="col-6">
												<div className="text">{this.props.prepayment.footer.title}<div className="price">{this.props.prepayment.footer.sub}</div></div>
											</div>
										:
											<div className="col-6 loading"></div>
									}

									<div className="col-6 text-right">
										{hasAccessToken() 
											?
												<Button waves='light' className="pay-button" onClick={this.makePayment}>{this.props.prepayment.property_section.instant_book === 1 && <span className="reserve-icon"></span>} {this.props.prepayment.footer.button_text}</Button>
											:
												<Link to="/signup" onClick={saveUrlLogin}>
													<Button waves='light' className="pay-button" >{this.props.prepayment.property_section.instant_book === 1 && <span className="reserve-icon"></span>} {this.props.prepayment.footer.button_text}</Button>
												</Link>								
										}
									</div>
								</div>
							</section>
							<TermCondition/>

							<Promocode
								promocodeClose={this.promocodeClose}
								savepromocode={this.savepromocode}
							/>

							<CouponConfirmation
								couponConfirmationClose={this.promocodeClose}
								couponConfirmation={this.couponConfirmation}
							/>

							<WalletModal
								walletModalClose={this.walletModalClose}
								confirmWallet={this.confirmWallet}
							/>

							<SelectGuestsModal
								submitPropertyDataToApi={this.submitPropertyDataToApi}
							/>

							<DateRangeCalendar 
								openMaxNightsAlert={this.openMaxNightsAlert}
								openUnitAlert = {this.openUnitAlert}
								openUnavailableDate={this.openUnavailableDate}
							/>

							<RequestSentModel />

							<div ref="max_nights_alert_overlay" className='max_nights_alert_overlay'>
								<div className="max_nights_alert">
									<div className="modal-body text-center">
									
										<h2 className="text">Non-Availability</h2>
										<div className='description'>
											<p>Minimum stay: {this.props.property.min_nights} night</p>
											<p>Maximum stay: {this.props.property.max_nights} nights</p>
										</div>
										<Button waves='light' className="btn" onClick={this.closeMaxNightsAlert}> Done</Button>
									</div>
								</div>
							</div>

							<div ref="unavailable_date_overlay" className='unavailable_date_overlay'>
								<div className="max_nights_alert">
									<div className="modal-body text-center">
									
										<h2 className="text">Non-Availability</h2>
										<div className='description'>
											<p>Date selection is invalid</p>
										</div>
										<Button waves='light' className="btn" onClick={this.closeUnavailableDate}> Done</Button>
									</div>
								</div>
							</div>

							<div ref="unitAlert" className='unitAlert'>
								<div className="max_nights_alert">
									<div className="modal-body text-center">
									
										<h2 className="text">Non-Availability</h2>
										<div className='description'>
											<p>Only {this.props.prepayment.available_units} {this.props.prepayment.available_units > 1 ? 'units are' :'unit is'} available on selected date</p>
										</div>
										<div className="btn" onClick={this.closeUnitAlert}>Done</div>
									</div>
								</div>
							</div>
							<img src={noInternet} className='hidden' alt='no internet'/>
					</div>
						}
					</div>
					:
					<div className="info-window">
					<div className="window-container">
							<div className="imgb">
							<div className="img" style={{backgroundImage:'url(' + noInternet + ')'}}></div>
							</div>
							<div className="txtb"><p>Oops! You don't seem to be connected to the internet.</p></div>
							<button  className="retry" onClick={this.reload}>Retry</button>
						</div>
					</div>
					}
					</div>
				:
					<div className="info-window">
						<div className="window-container">
							<div className="imgb">
							<div className="img" style={{backgroundImage:'url(' + noInternet + ')'}}></div>
							</div>
							<div className="txtb"><p>Oops! You don't seem to be connected to the internet.</p></div>
							<button className="retry" onClick={this.reload}>Retry</button>
						</div>
					</div>
				}
				
				<div ref="errorMessage_modal" className='unavailable_date_overlay'>
					<div className="max_nights_alert">
						<div className="modal-body text-center">
						
							<h2 className="text">Error!</h2>
							<div className='description'>
								<p>{this.props.prepayment.errorMessage}</p>
							</div>
							<Button waves='light' className="btn" onClick={this.closeErrorMessage_modal}> Done</Button>
						</div>
					</div>
				</div>
				
			</div>
		);
	}
}


const mapStateToProps = (store) => {
	return {
		search: store.search,
		booking: store.booking,
		property: store.property,
		prepayment: store.prepayment,
		error: store.error,
		tracker: store.tracker.tracker
	}
}

const mapDispatchToProps = {
	updatePropertyState,
	updateBookingDataState,
	updateSearchState,
	updateErrorState,
	updatePrepaymentState
}

export default connect(mapStateToProps, mapDispatchToProps)(PrepamentPropertyDetails);
