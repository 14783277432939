import React from 'react';
import OtpSend from '../../components/otp/sendOtp.js';
import OtpVerify from '../../components/otp/verifyOtp.js';
// services
import {getOtpSendApi, hasAccessToken} from '../../services/index.js';
import {trackPageView} from '../../services/tracker/events.js';
import {connect} from 'react-redux';
/**
 * Actions
 */
import {updateOtpStateObj} from './../../actions/otp/actions';
import {updateSignupStateObj} from '../../actions/signup/actions'
import { Route } from 'react-router-dom'

class OTP extends React.Component {
  constructor(props) {
        super(props);
        this.page="otp_send";
        this.updateState = this.updateState.bind(this);
        this.goBack = this.goBack.bind(this);
        this.showVerifyScreen = this.showVerifyScreen.bind(this);
        this.openNewUrl = this.openNewUrl.bind(this);
        this.submitOTPDetail = this.submitOTPDetail.bind(this);
        this.showLoader = this.showLoader.bind(this);
        this.openErrorMessage_modal = this.openErrorMessage_modal.bind(this);
        this.closeErrorMessage_modal = this.closeErrorMessage_modal.bind(this);
        this.closeErrorModal=this.closeErrorModal.bind(this);
    }

    componentDidMount(){
        document.body.style.backgroundColor = this.props.otp.body_bg_color;
        document.body.setAttribute('data-color', this.constructor.name);
        this.props.updateOtpStateObj({contact_number:sessionStorage.getItem('contact_number')});
        this.page = this.props.otp.show_send_otp === false ? 'otp_verify' : 'otp_send';
        trackPageView(this.props.tracker, this.page);
    }

    componentWillUnmount(){
        if (document.body.getAttribute('data-color') === this.constructor.name) {
            document.body.style.backgroundColor = null;
        }
        sessionStorage.removeItem('UrlAfterLogin');
    }

    submitOTPDetail(args, successCallback, errorCallback){
        let this_el = this;
        getOtpSendApi(args).then(function(response){
            // success
            successCallback(response);
            this_el.props.updateOtpStateObj({loading:false});
        }).catch(function(error){
            // error
            this_el.props.updateOtpStateObj({loading:false});
            if(error.hasOwnProperty('response') && error.response === undefined){
                let networkError = {
                    response:{
                        data:{
                            status:false,
                            error:[{
                                message:'Please check your internet connection'
                            }]
                        }
                    }
                }
                errorCallback(networkError);
            }else if(error.hasOwnProperty('response') && error.response.status === 500){
                let ServerError = {
                    response:{
                        data:{
                             message:'Server Error! please try after sometime'
                        }
                    }
                }
                errorCallback(ServerError);
            }else{
                errorCallback(error);
            }
        });
    }

    showVerifyScreen() {
        this.props.updateOtpStateObj({show_send_otp: false});
    }

    showLoader(){
        this.props.updateOtpStateObj({loading:true});
    }

    updateState(json) {
        this.props.updateOtpStateObj(json);
    }
    openErrorMessage_modal(){
		this.refs.errorMessage_modal.classList.add('true');
    }
    closeErrorModal(){
        this.refs.errorMessage_modal.classList.remove('true');
    }
	closeErrorMessage_modal(){
        this.refs.errorMessage_modal.classList.remove('true');
        localStorage.setItem('SkipModal',1);
		this.props.history.goBack();
	}
    goBack() {
        //alert();
        if(localStorage.getItem('SkipModal') == 0){
            this.openErrorMessage_modal();
        }else{
            this.props.history.goBack();
        }
        
    }

    openNewUrl(url, callback) {
        console.log('from function', url)
        this.props.history.push(url);
        if (callback) {
            callback()
        }
    }

    componentWillMount(){
        this.auth();
       
    }
    auth(){
        if(!hasAccessToken()){
            this.props.history.push('/signup');
        }
    }
    render() {
        var loader = this.props.otp.loading ? 'loading active' : 'loading ';
        return (
            <div className="optscreen">
            <div className={loader}></div>
            {this.props.otp.show_send_otp   ?
                <OtpSend 
                    contact_number={this.props.otp.contact_number}
                    dial_code={this.props.otp.dial_code}
                    dial_codes={this.props.otp.dial_codes}
                    otp_method={this.props.otp.otp_method}
                    referral_code={this.props.otp.referral_code}
                    goBack={this.goBack}
                    updateState={this.updateState}
                    showVerifyScreen={this.showVerifyScreen}
                    submitOTPDetail={this.submitOTPDetail}
                    showLoader={this.showLoader}
                />
            :
                <OtpVerify
                    contact_number={this.props.otp.contact_number}
                    dial_code={this.props.otp.dial_code}
                    otp_method={this.props.otp.otp_method}
                    goBack={this.goBack}
                    updateState={this.updateState}
                    openNewUrl={this.openNewUrl}
                    submitOTPDetail={this.submitOTPDetail}
                    showLoader={this.showLoader}
                />
            }
                <div ref="errorMessage_modal" className='unavailable_date_overlay'>
                    <div className="max_nights_alert">
                        <div className="modal-body text-center">
                            <span className="close-btn" onClick={this.closeErrorModal}></span>
                            <div className='description'>
                               <p>Are you sure you want to continue <br/>without verify phone number?</p>
                            </div>
                            <button className="btn skip" onClick={this.closeErrorMessage_modal}> Yes</button>
                        </div>
                    </div>
                </div>
            </div>

        );
    }
}
const mapStateToProps = (store) => {
	return {
        otp : store.otp,
        signup : store.signupEmail,
        tracker: store.tracker.tracker
    }
}
const mapDispatchToProps = {
    updateOtpStateObj,
    updateSignupStateObj
}
export default connect(mapStateToProps, mapDispatchToProps) (OTP);