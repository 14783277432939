import TagManager from 'react-gtm-module'

const tagManagerArgs = {
    gtmId: 'GTM-548F88L',
}

function getGaImpressionObj(page, currency, properties){
  return {
     "currencyCode":currency,
     "impressions":getProperitesObj(properties, page)
  }
}

function getGAProductClickObj(page, currency_code, properties){

  if(properties === undefined){
    return {};
  }
  return {
     "currencyCode":currency_code,
     "click": {
       "actionField": {"list":page },
       "products": getProperitesObj(properties, page)
      }
  }
}

function getGaProductViewObj(page, currency, properties){
    return {
     "currencyCode":currency,
     "detail": {
       "actionField": {"list":"search"},
       "products":getProperitesObj(properties, page)
    }
  }
}

function getGaAddToCartObj(page, currency, properties){
    return  {
     "currencyCode":currency,
     "add": {
       "products": getProperitesObj(properties, page)
    }}
}

function getGACheckOutObj(page, instant, properties){
   return {
        "checkout": {
        "actionField": {"step":1,"option":instant},
        "products": getProperitesObj(properties, page)
      }
    }
}

function getGaPurchaseObj(page, instant, hbrid, payable_amount, properties, payment_page, coupon){

  if(!payment_page){
    return {};
  }

  return {
    "purchase": {
       "actionField": {
         "id":hbrid,
         "affiliation":"Guesthouser",
         "revenue":payable_amount,
         "step":2,
         "option":instant,
         "coupon":coupon
      },
       "products": getProperitesObj(properties, page)
    }
  }
}

function getProperitesObj(properties, page){
  var obj = [];
  var i=0;

  properties.forEach(function(property){
    obj.push({
      "id":property.hpid,
      "name":property.title,
      "price": property.price,
      "list": page,
      "position": i++,
    });
  });
  return obj;
}
function getGtmTrackEventListener(){
  TagManager.initialize(tagManagerArgs);
  return function (event, eventsHistory) {
      switch(event.type) {
        case 'EVENT_NAME':
        tagManagerArgs['dataLayer']=  {'event': event.type};
        TagManager.dataLayer(tagManagerArgs);
          break;
        case 'page_view':
        tagManagerArgs['dataLayer']=  {'event': event.type, 'page':event.data.page, };
        TagManager.dataLayer(tagManagerArgs);
          break;
        case 'goto_property_page':
        tagManagerArgs['dataLayer']=  {'event': event.type, 'page':event.data.page, 
          'source': event.data.source, 'hpid': event.data.hash_id,
         'ecommerce': getGAProductClickObj(event.data.page, event.data.currency_code, event.data.properties)
         };
        TagManager.dataLayer(tagManagerArgs);
          break;
        case 'goto_collection_page':
        tagManagerArgs['dataLayer']=  {'event': event.type, 'page':event.data.page, 'source': event.data.source, 'cid': event.data.cid};
        TagManager.dataLayer(tagManagerArgs);
          break;

        case 'goto_collection_list_page':
        case 'goto_trending_cities_list_modal':
        case 'goto_recently_view_list_modal':
        tagManagerArgs['dataLayer']=  {'event': event.type, 'page':event.data.page, 'source': event.data.source};
        TagManager.dataLayer(tagManagerArgs);
          break;
        case 'goto_search_page':
        	tagManagerArgs['dataLayer']=  {'event': event.type, 'page':event.data.page, 'source': event.data.source,
  	       'city': event.data.city,
  		    'state': event.data.state,
  		    'country': event.data.country,
  		    'checkin': event.data.checkin,
  		    'checkout': event.data.checkout,
  		    'guests': event.data.guests,
  		    'lat': event.data.lat,
  		    'lng': event.data.lng,
  		    'area': event.data.area,
  		    'location': event.data.location
     		};
        	TagManager.dataLayer(tagManagerArgs);
          break;

        case 'search':
          tagManagerArgs['dataLayer']=  {'event': event.type, 'page':event.data.page, 'source': event.data.source,
          'city': event.data.city,
          'state': event.data.state,
          'country': event.data.country,
          'country_name': event.data.country_name,
          'checkin': event.data.checkin,
          'checkout': event.data.checkout,
          'guests': event.data.guests,
          'lat': event.data.lat,
          'lng': event.data.lng,
          'area': event.data.area,
          'location': event.data.location,
          'pids': event.data.pids,
          'hpids': event.data.hpids,
          'ecommerce': getGaImpressionObj(event.data.page, event.data.currency_code, event.data.properties)
        };
          TagManager.dataLayer(tagManagerArgs);
          break;
       case 'property_view':
       tagManagerArgs['dataLayer']=  {'event': event.type, 'page':event.data.page, 'source': event.data.source,
        'city': event.data.city,
        'state': event.data.state,
        'country': event.data.country,
        'country_name': event.data.country_name,
        'checkin': event.data.checkin,
        'checkout': event.data.checkout,
        'guests': event.data.guests,
        'lat': event.data.lat,
        'lng': event.data.lng,
        'area': event.data.area,
        'location': event.data.location,
        'pid': event.data.pid,
        'hpid': event.data.hpid,
        'ecommerce': getGaProductViewObj(event.data.page, event.data.currency_code, event.data.properties )
      };
        TagManager.dataLayer(tagManagerArgs);
        break;
       case 'prepayment_view':
        tagManagerArgs['dataLayer']=  {'event': event.type, 'page':event.data.page, 'source': event.data.source,
        'city': event.data.city,
        'state': event.data.state,
        'country': event.data.country,
        'country_name': event.data.country_name,
        'checkin': event.data.checkin,
        'checkout': event.data.checkout,
        'guests': event.data.guests,
        'lat': event.data.lat,
        'lng': event.data.lng,
        'area': event.data.area,
        'location': event.data.location,
        'pid': event.data.pid,
        'hpid': event.data.hpid,
        'ecommerce': getGaAddToCartObj(event.data.page, event.data.currency_code, event.data.properties)
      };
        TagManager.dataLayer(tagManagerArgs);
        break;
       case 'booking_request':
        tagManagerArgs['dataLayer']=  {'event': event.type, 'page':event.data.page, 'source': event.data.source,
        'city': event.data.city,
        'state': event.data.state,
      'country': event.data.country,
        'country_name': event.data.country_name,
        'checkin': event.data.checkin,
        'checkout': event.data.checkout,
        'guests': event.data.guests,
        'lat': event.data.lat,
        'lng': event.data.lng,
        'area': event.data.area,
        'location': event.data.location,
        'pid': event.data.pid,
        'instant': event.data.instant,
        'request_code': event.data.request_code,
        'hpid':event.data.hpid,
        'user_id':event.data.user_id,
        'ecommerce': getGACheckOutObj(event.data.page, event.data.instant, event.data.properties)
      };
        TagManager.dataLayer(tagManagerArgs);
        break;
      case 'trip_details_view':
        tagManagerArgs['dataLayer']=  {'event': event.type, 'page':event.data.page, 'source': event.data.source,
        'city': event.data.city,
        'state': event.data.state,
        'country': event.data.country,
        'country_name': event.data.country_name,
        'checkin': event.data.checkin,
        'checkout': event.data.checkout,
        'guests': event.data.guests,
        'lat': event.data.lat,
        'lng': event.data.lng,
        'area': event.data.area,
        'location': event.data.location,
        'pid': event.data.pid,
        'hpid': event.data.hpid,
        'hbrid': event.data.hbrid,
        'payment_page':event.data.payment_page,
        'payable_amount':event.data.payable_amount,
        'payment_currency':event.data.payment_currency,
        'ecommerce': getGaPurchaseObj(event.data.page, event.data.instant, event.data.hbrid,
          event.data.payable_amount, event.data.properties, 
          event.data.payment_page, event.data.coupon_code_used)
      };
        TagManager.dataLayer(tagManagerArgs);
        break;
         case 'goto_stay_page':
        	tagManagerArgs['dataLayer']=  {'event': event.type, 'page':event.data.page, 'source': event.data.source,'stay_url': event.data.stay_url};
        	TagManager.dataLayer(tagManagerArgs);
          break;
        case 'login':
        tagManagerArgs['dataLayer']=  {'event': event.type, 'page':event.data.page, 'source': event.data.source,'user_id': event.data.user_id, 'type':event.data.type};
        TagManager.dataLayer(tagManagerArgs);
        break;
        case 'signup':
        tagManagerArgs['dataLayer']=  {'event': event.type, 'page':event.data.page, 'source': event.data.source,'user_id': event.data.user_id, 'type':event.data.type};
        TagManager.dataLayer(tagManagerArgs);
        break;
        default:
          // Silence
      }
  }

}

export default getGtmTrackEventListener;