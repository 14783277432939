import React from 'react';
import LazyLoad from 'react-lazyload';

class GhFeatures extends React.Component {
	render() {
	
		return (
			<div>
				<div className="heading-section">
					<h2 className="heading">Why book with us?</h2>
				</div>
				<div className="features-inner-section">
					<div className="row">
						<div className="col-6">
							<div className="  features-item">
								<LazyLoad height={100}>
								<div className="imgb feature1"></div>
								</LazyLoad>
								<div className="txtb">
									<h3>Free cancellation</h3>
									<p>No cancellation fees and a full refund on all our holiday homes</p>
								</div>
							</div>
						</div>
						<div className="col-6">
							<div className="features-item">
								<LazyLoad height={100}>
								<div className="imgb feature2"></div>
								</LazyLoad>
								<div className="txtb">
									<h3>Extra 50% Off</h3>
									<p>Last minute trip? Get upto 50% off on selected properties</p>
								</div>
							</div>
						</div>
					</div>
					<div className="row">
						<div className="col-6">
							<div className="features-item">
								<LazyLoad height={100}>
								<div className="imgb feature3"></div>
								</LazyLoad>
								<div className="txtb">
									<h3>Early bird Offers</h3>
									<p>Plan ahead of time and get 20% cashback on your booking amount</p>
								</div>
							</div>
						</div>
						<div className="col-6">
							<div className="features-item">
								<LazyLoad height={100}>
								<div className="imgb feature4"></div>
								</LazyLoad>
								<div className="txtb">
									<h3>Pay later</h3>
									<p>Block your stay at a great price and a few days before</p>
								</div>
							</div>
						</div>
					</div>
				</div>
			</div>
		);
	}
}
export default GhFeatures;