import { PREPAYMENT_UPDATE_STATE } from "../actions/prepayment/vars";
import { getURLParameter } from '../services';


const initialState = {
    'property_images':[],
    'property_section': '',
    'invoice': '',
    'discount_section': '',
    'footer': '',
    'payment_methods': [],
    'left_div': '',
    'right_div': '',
    'is_mobile_verified': '',
    'is_user_referred': '',
    'bookable_as_unit': 0,
    'misconception': '',
    'misconception_code': '',
    'errorMessage':'',
    'request_id': '',
    'messageHeader': '',
    'booking_status': '',

    'payment_method': window.location.search.includes('payment_method') ? (getURLParameter(window.location.search, 'payment_method')) : (''),
    'promocode': window.location.search.includes('coupon_code') ? (getURLParameter(window.location.search, 'coupon_code')) : (''),
    'apply_wallet': window.location.search.includes('apply_wallet') ? (parseInt(getURLParameter(window.location.search, 'apply_wallet'), 10)) : (0),
    'discount_type': window.location.search.includes('discount_type') ? (getURLParameter(window.location.search, 'discount_type')) : (''),
    dataLoaded: false,
    cacheHash: "",
    extendedDataLoaded: false,
    'flag': {
        'modal':{
            'termCondition': false,
            'requestSentModal': false,
            'couponConfirmation': false,
            'promocodes': false,
            'walletModal': false,
            'forceCreateModal': false,

        },
        'refreshPage': false,
        'footerPriceVisibility': false,
        'apiData': false
    }
}

const fnc = (state = initialState, action) => {
    switch(action.type) {
        case PREPAYMENT_UPDATE_STATE:
            return Object.assign({}, state, action.payload);
    
        default:
            return Object.assign({}, state);
    }
}
export default fnc;
