import React from 'react';
import Modal from 'react-modal';
import {connect} from 'react-redux';
import { withRouter } from 'react-router';

// Services
import { getAddressDetails, ddMMYYYY } from '../../../services/index.js';
import { getVal } from '../../../services/helpers';

//Actions
import { updateBookingDataState } from './../../../actions/booking-data/actions'; 
import { updateSearchState } from './../../../actions/search/actions';
import { updateErrorState } from './../../../actions/error/actions';
import { updateHomeState } from './../../../actions/home/actions';
import { trackGoToSearchPage } from '../../../services/tracker/events.js';

let generateDeviceUniqueId = function() {
	let dt = new Date().getTime();
	let uuid = 'xxxxxxxx-xxxx-4xxx-yxxx-xxxxxxxxxxxx'.replace(/[xy]/g, function(c) {
			let r = (dt + Math.random()*16)%16 | 0;
			dt = Math.floor(dt/16);
			return (c === 'x' ? r : (r & (0x3|0x8))).toString(16);
	});
	return uuid;
}

class SearchLocationModal extends React.Component {

	constructor(props) {
		if (!props.clearSearch) {
			props.history.go(-2)
		}
		super(props);
		this.closeModal = this.closeModal.bind(this);
		this.afterOpenModal = this.afterOpenModal.bind(this);
		this.searchInputChange = this.searchInputChange.bind(this);
		this.keyUpFunction = this.keyUpFunction.bind(this);
		this.state = {
			sessionToken: null,
		}
	}

	componentDidMount() {
		const this_el = this;
		// resseting previous booking data
		this_el.props.updateBookingDataState({}, 1).then(() => {
			// caling popular search api data
			this_el.getPopularSearchApiData();
		});
		setTimeout(() => {
			this.mounted = true;	
		}, 500);
	}

	closeModal(){
		this.props.history.goBack()
	}

	afterOpenModal(){
		this.refs.searchInput.focus();
	}

	generateSessionTokenAndHitApi = address => {
		if (!this.state.sessionToken) {
			const sessionToken = new window.google.maps.places.AutocompleteSessionToken();
			this.setState({ sessionToken }, () => this.googlePlaceApi(address));
		} else {
			this.googlePlaceApi(address);
		}
	}

	googlePlaceApi(address){
		const this_el = this;
		console.log(this.state.sessionToken);
		var autocompleteService = new window.google.maps.places.AutocompleteService();
		autocompleteService.getPlacePredictions({
			input: address,
			types: ['(regions)'],
			sessiontoken: this.state.sessionToken,
		}, function (places, status) {
			this_el.props.updateHomeState({
				googlePlaceList: places
			})
		})
	}

	searchInputChange(inputData){
		this.generateSessionTokenAndHitApi(inputData.target.value)
		const this_el = this;

		// search page flags
		let search_flag = Object.assign({}, this.props.search.flag);
		// check wether to show popular search tab or not
		if (inputData.target.value.length > 0) {
			search_flag.popular_search_data = false;
		} else {
			search_flag.popular_search_data = true;
		}

		search_flag.modal.date_range = false
		search_flag.modal.select_guest = false

		let header_data = Object.assign({}, this_el.props.search.header);
		header_data.address = inputData.target.value;

		// updating search popular search data flag
		this.props.updateSearchState({
			flag: search_flag,
			header: header_data
		});

	}

	getPopularSearchApiData() {

		const this_el = this;
		let search_header = Object.assign({}, this_el.props.search.header);

		const localArray = localStorage.getItem('recentSearch') ? JSON.parse(localStorage.getItem('recentSearch')) : this.props.search.header.recent_popular_search

		search_header.recent_popular_search = localArray
			.concat(this.props.home.popular_search_list)
			.filter((x, i, arr) => arr.findIndex(y => y.location === x.location) === i)
			.slice(0, 5)

		this_el.props.updateSearchState({
			header: search_header
		});

	}

	formatDate(value) {

		// checking if it is default value or undefined
		if (value === 1 || typeof value === 'undefined') {
			return false;
		}

		// checking if it is date type of string, if string then convert to date
		let date = (typeof value === 'string') ? new Date(value) : value;
		const locale = "en-us";

		if (typeof date.toLocaleString !== 'undefined') {
			// pulling month name
			let monthName = date.toLocaleString(locale, { month: "short" });
			return date.getDate() + " " + monthName;
		} else {
			return false;
		}

	}

	printResults() {

		if (this.props.search.flag.popular_search_data){
			return (
				<div>
					{this.props.search.header.recent_popular_search.map((item, index) => {
						let secondaryText = item.location.split(',');
						secondaryText.shift();

						return (this.popularSearchList({
							'index': index,
							'googleResult': false,
							'checkin': this.formatDate(item.checkin_date),
							'checkout': this.formatDate(item.checkout_date),
							'mainText': item.location.split(',')[0],
							'secondaryText': secondaryText.join(','),
						}));
					})}
				</div>
			)
		}else {
			return (
				<div>

					{this.props.home.googlePlaceList !== null 
						? 
							this.props.home.googlePlaceList.map((item, index) => {
								return (this.popularSearchList({
									'index': index,
									'googleResult':true,
									'checkin': '',
									'checkout': '',
									'mainText': item.structured_formatting.main_text,
									'secondaryText': item.structured_formatting.secondary_text
								}));
							})
						:

						this.popularSearchList({
							'index': 0,
							'googleResult': true,
							'checkin': '',
							'checkout': '',
							'mainText': 'No results',
							'secondaryText': ''
						})

				}
				</div>
			)
		}
	}

	popularSearchList(location_data) {
		let date = '';
		if (location_data.checkin) {
			date = location_data.checkin + ' - ' + location_data.checkout;
		}

		return (
			<div
				className="location-item"
				key={location_data.index}
				data-index={location_data.index}
				onClick={(e) => { this.selectLocation(e.currentTarget.dataset.index, location_data.mainText + ', ' + location_data.secondaryText) }}
			>
				<span className={location_data.checkin ? ('date') : (!location_data.googleResult ? 'trending-icon' : '')}>{date}</span>
				<strong>{location_data.mainText}, <small>{location_data.secondaryText}</small></strong>
				
			</div>
		)
	}


	/**
	* Select location
	*/
	selectLocation(index, location) {
		const this_el = this;

		if (!this.mounted) {
			return;
		}

		// recent popular search row
		let selectedLocation = {};
		if(this.props.search.flag.popular_search_data){
			selectedLocation = this_el.props.search.header.recent_popular_search[index];
			this_el.updateBookingState(selectedLocation);

		} else {

			let googlePlaceList = this_el.props.home.googlePlaceList[index];
			console.log(this.state.sessionToken);
			const placeDetails = new window.google.maps.places.PlacesService(document.getElementById('map'));
			placeDetails.getDetails({ 'placeId': googlePlaceList.place_id, sessiontoken: this.state.sessionToken }, (results, status) => {
				let data = getAddressDetails(results);
				selectedLocation = {
					location : location,
					area : data.area,
					city : data.city,
					state : data.state,
					country : data.country,
					latitude : results.geometry.location.lat(),
					longitude : results.geometry.location.lng()
				}
				this_el.updateBookingState(selectedLocation);
				this.setState({ sessionToken: null });
			});
		}


	}

	updateBookingState(selectedLocation){
		const this_el = this;
		let booking_data = Object.assign({}, this_el.props.booking);

		booking_data.location = getVal(selectedLocation.location);
		booking_data.area = getVal(selectedLocation.area);
		booking_data.city = getVal(selectedLocation.city);
		booking_data.state = getVal(selectedLocation.state);
		booking_data.country = getVal(selectedLocation.country);
		booking_data.lat = getVal(selectedLocation.latitude);
		booking_data.lng = getVal(selectedLocation.longitude);

		this.props.updateSearchState({
			search_address_data: {
				...this.props.search.search_address_data,
				location: getVal(selectedLocation.location),
				area: getVal(selectedLocation.area),
				city: getVal(selectedLocation.city),
				state: getVal(selectedLocation.state),
				country: getVal(selectedLocation.country),
				lat: getVal(selectedLocation.latitude),
				lng: getVal(selectedLocation.longitude),
			}
		})

		if (selectedLocation !== undefined && selectedLocation.checkin_date !== undefined) {
			booking_data.checkin_date = getVal(selectedLocation.checkin_date);
			booking_data.checkout_date = getVal(selectedLocation.checkout_date);
			booking_data.guest_count = getVal(selectedLocation.guest_count);
		} else {
			booking_data.checkin_date = 1
			booking_data.checkout_date = 1
			booking_data.guest_count = 0
		}

		this_el.props.updateBookingDataState(booking_data).then(() => {
			if (!this.props.search.flag.popular_search_data){
				this_el.refs.searchInput.value = Object.keys(this_el.props.booking.location).length > 0 ? this_el.props.booking.location.split(',')[0] : '';
			}
			
			// open date range calendar when don't have checkin and checkout dates
			let checkindate = selectedLocation.checkin_date
			if (checkindate === undefined || !checkindate || checkindate === 1) {
				let search_flag = Object.assign({}, this_el.props.search.flag);
				search_flag.modal.date_range = true;
				this_el.props.updateSearchState({
					flag: search_flag
				})
			} else {
				//open search result
				this.goToSearchResult();
			}
		});
	}



	goToSearchResult() {

		const this_el = this;
		let search_flag = Object.assign({}, this_el.props.search.flag);
		search_flag.modal.guest_count = false;
		search_flag.modal.date_range = false;
		search_flag.modal.location = false;
		search_flag.modal.select_guest = false;
		search_flag.show_loader = true;
		search_flag.api.is_loading = true;


		this_el.props.updateSearchState({
			flag: search_flag

		}).then(() => {


			// submitting search

			let booking_data = Object.assign({}, this_el.props.booking);
			let urlParams = [];


			urlParams.push('checkin=' + ddMMYYYY(booking_data.checkin_date));
			urlParams.push('checkout=' + ddMMYYYY(booking_data.checkout_date));
			urlParams.push('guests=' + booking_data.guest_count);
			urlParams.push('lat=' + booking_data.lat);
			urlParams.push('lng=' + booking_data.lng);
			urlParams.push('location=' + booking_data.location);
			urlParams.push('area=' + booking_data.area);
			urlParams.push('city=' + booking_data.city);
			urlParams.push('state=' + booking_data.state);
			urlParams.push('country=' + booking_data.country.ccode);

			let search_url_params = '?' + urlParams.join('&').replace('undefined', '');

			
			this.props.history.push('/')

			this_el.props.history.push({
				pathname: "/search/s",
				search: search_url_params,
			});

			this.props.updateHomeState({
				homeHistory: [
					...this.props.home.homeHistory,
					"/search/s" + search_url_params
				]
			})
			localStorage.setItem('homeHistory', JSON.stringify([
				...this.props.home.homeHistory,
				"/search/s" + search_url_params
			]))

			const search_track_data = {
				checkin: ddMMYYYY(booking_data.checkin_date),
				checkout: ddMMYYYY(booking_data.checkout_date),
				guests: booking_data.guest_count,
				lat: booking_data.lat,
				long: booking_data.lng,
				location: booking_data.location,
				area: booking_data.area,
				city: booking_data.city,
				state: booking_data.state,
				country: booking_data.country.ccode,
			}

			trackGoToSearchPage(this.props.tracker, this_el.props.home.page, "recent search", search_track_data);
			

		});
	}

	keyUpFunction(e){
		const this_el = this;
		if (e.keyCode === 13) {
			this_el.refs.searchInput.blur();
		}
	}


	render() {
		return (
			this.props.clearSearch && (
				<Modal
				isOpen
				onRequestClose={this.closeModal}
				onAfterOpen={this.afterOpenModal}

				contentLabel="Modal"

				overlayClassName={{
					base: 'overlay-modal',
					afterOpen: '',
					beforeClose: ''
				}}
				className={{
					base: 'content-modal',
					afterOpen: 'search-location-modal full-screen-modal',
					beforeClose: ''
				}}

			>
				<div className="modal-header">
					<span className="reset-btn" onClick={this.closeModal}> Cancel</span>
					{/* {(this.props.search.flag.modal.location) &&
						<SearchInput/>  
					} */}
					<i className="location-icon"></i>
					<input type="text" placeholder="Where do you want to go?" onChange={this.searchInputChange} onFocus={this.searchInputChange} onKeyUp={this.keyUpFunction} ref="searchInput" />
					{this.printResults()}
				</div>

			</Modal>
			)
		);
	}
}


const mapStateToProps = store => {
	return {
		booking: store.booking,
		search: store.search,
		home: store.home,
		error: store.error,
		tracker: store.tracker.tracker

	};
};

const mapDispatchToProps = {
	updateBookingDataState,
	updateSearchState,
	updateHomeState,
	updateErrorState
};
export default connect(
	mapStateToProps,
	mapDispatchToProps
)(withRouter(SearchLocationModal));