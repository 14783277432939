import React from 'react';
import DocumentMeta from 'react-document-meta';
import { connect } from "react-redux";
import deviceVar from '../../services/variables/device.js';
import ResponseMessage from '../../components/response/';
// components
import SearchBarContainer from "../../components/home/searchBarContainer/";
import HomeTrending from "../../components/home/homeTrending.js";
import RecentlyViewed from "../../components/home/homeRecentlyViewed.js";
import GhFeatures from "../../components/home/ghFeatures.js";
import OfferBanners from "../../components/home/offerBanners.js";
import Collection from "../../components/home/collection.js";
import noInternet from "../../static/img/infoWindow/noInternet.svg";
import serverError from "../../static/img/serverError.png";
//images
import showcase from "../../static/img/home/showcase.jpg";
import logo from "../../static/img/home/logo.png";
// css
import "../../static/css/vendors/swiper.css";
import {
	trackPageView,
	trackGoToTrendingCitiesListModal,
	trackError
} from "../../services/tracker/events.js";

//
import {
	getHomePageDataApi,
	addClassToHtml,
	removeClassToHtml,
	hasAccessToken,
	getPopularSearchApi
} from "../../services/index.js";

/**
* Actions
*/
import { updateHomeState } from "./../../actions/home/actions";
import { updateCollectionState } from "./../../actions/collection/actions";
import { updateSearchState } from "./../../actions/search/actions";
import { updateBookingDataState } from "./../../actions/booking-data/actions"; 
import { updatePropertyState } from "./../../actions/property/actions";
import { updateErrorState } from "./../../actions/error/actions";
import { updateSingleCollectionState } from '../../actions/single-collection/actions';
import OfflineStatus from '../../components/Offlinestatus';
import { checkIfOffline } from '../../services/helpers'

class Home extends React.Component {
	constructor(props) {
		super(props);
		this.goBack = this.goBack.bind(this);
		this.modalRouting = this.modalRouting.bind(this);
		this.openMaxNightsAlert = this.openMaxNightsAlert.bind(this);
		this.closeMaxNightsAlert = this.closeMaxNightsAlert.bind(this);
		this.reload = this.reload.bind(this);
		this.resetMessages = this.resetMessages.bind(this);

		if (!(props.prevRoute && (x => x.includes('/home/') || x.includes('/stay') || x.includes('/search/s'))(props.prevRoute.pathname))) {
			props.home.homeHistory
				.filter(x => !(x.includes('searchlocation')))
				.filter((x, i, arr) => arr.indexOf(x) === i)
				.forEach(x => {
					props.history.push(x)
				})
		}
	}

	componentWillReceiveProps(props) {
		if (props.home.homeHistory.length > 0 && props.prevRoute && (x => x.includes('/home/') || x.includes('/stay') || x.includes('/search/s'))(props.prevRoute.pathname)) {
			props.updateHomeState({
				homeHistory: []
			})
			props.prevRoute.pathname !== "/home/searchlocation" && props.updateSearchState({}, 1); // Condition because header name is also cleared
			props.updatePropertyState({}, 1);
			props.updateBookingDataState({}, 1);
			// Clear Search Filter State
			setTimeout(() => {
				props.updateSearchState({
					flag: {
						...props.search.flag,
						modal: {
							...props.search.flag.modal,
							filter: false,
							date_range: false,
							select_guest: false
						}
					}
				})
			})
			localStorage.setItem('homeHistory', JSON.stringify([]))
		}
	}

	resetMessages(){
        this.props.updateErrorState({
            'message':'please check your internet connection',
            'show':false,
        });
    }

	getHomeApiDataSuccess(response) {
		const this_el = this;

		// updating network state
		let error_flag = Object.assign({}, this_el.props.error.flag);
		error_flag.network = false;
		error_flag.server =false;
		this_el.props.updateErrorState({
			flag: error_flag
		});
		// updating network state

		this.props.updateHomeState({
			home_banners: response.data.data.home_banner,
			popular_cities: response.data.data.popular_cities,
			popular_cities_subheading:response.data.data.home_explore_content.cities_sub_heading,
			property_types: response.data.data.property_types,
			recently_viewed_properties:response.data.data.recently_viewed_properties,
			active_request_count: response.data.data.active_request_count,
			offer: response.data.data.offer,
			keyword: response.data.data.meta.keyword,
			meta_desc: response.data.data.meta.meta_desc,
			meta_title: response.data.data.meta.meta_title,
			collections:response.data.data.collections,
			canonical_url: response.data.data.meta.canonical_url,
			dataLoaded: true
		}).then(() => {

			// Went Online
			this.props.updateErrorState({ flag: { network: false } });

			/**
			* updating collection data
			*/
			this_el.props.updateCollectionState({
				collections: response.data.data.collections
			});
			localStorage.setItem("request_count",(this_el.props.home.active_request_count === undefined ? 0: this_el.props.home.active_request_count));
			this_el.props.updateActiveTripCount();


		});
		this.getPopularSearchApiData();
	}
	


	getHomeApiDataError(error) {
		if (typeof error.response !== "undefined" && error.response.status === 1000){
			let flag =  this.props.error.flag;
			flag.network = true; 
			this.props.updateErrorState({ flag: flag });
		}else if(error.response.status === 500){
			//console.log('dgdg',error);
			let flag =  this.props.error.flag;
			flag.server = true; 
			this.props.updateErrorState({ flag: flag });
			
		}else {
			//console.log('errorCOde',);
			trackError(error);
		}
	}

	getHomeApiData(args) {


		//checking if already home data stored in home store
		if (!this.props.home.dataLoaded || this.props.error.flag.network){
			let this_el = this;
			getHomePageDataApi(args)
			.then(function (response) {
				// success
				this_el.getHomeApiDataSuccess(response);
			})
			.catch(function (error) {
				// error
				this_el.getHomeApiDataError(error);
			});
		}
	}

	getPopularSearchApiData(){
		const this_el = this;
		getPopularSearchApi().then(function(response) {
			this_el.props.updateHomeState({
				popular_search_list:response.data.data.list
			})
		})		
		.catch(function(msg) {
			this_el.props.updateErrorState({
				message: msg
			});
		});	
	}



	componentWillUnmount() {
		
		this.props.updateHomeState({
			'home_scroll': window.pageYOffset
		})
	}
	componentDidMount() {
	
		if (checkIfOffline()) {
			this.props.updateErrorState({ flag: { network: true } });
		} else {
			this.props.updateErrorState({ flag: { network: false } });
		}

		this.props.updateSingleCollectionState({
			'offset':1
		})
		window.scrollTo(0, this.props.home.home_scroll);
	}

	ApiCall(){
		const this_el = this;

		let param;
		let pathname = window.location.pathname.split("/");
		let pathnameArray = this.cleanArray(pathname);

		if (pathnameArray[0] === "offers") {
			if (pathnameArray.length > 1) {
				param = {
					offers: pathnameArray[1]
				};
			} else {
				param = {
					offers: "default"
				};
			}
			this.props.updateHomeState({ offerPage: true });
			this.getHomeApiData(param);
		} else {
			this.props.updateHomeState({ offerPage: false });
			this.getHomeApiData();
		}


		let page = this_el.props.home.page;
		if (this.props.home.offerPage === true) {
			if (param.offers === "default") {
				page = 'offer_details';
			} else {
				page = 'offer';
			}
		}
		this_el.props.updateHomeState({
			page: page
		}).then(() => {

			/**
			 * removing booking and search store value
			 */


			// by clearing the search data in home, it also clearing the recent search data in search.
			// reset whole data except the search.header.recent_popular_result

			this_el.props.updatePropertyState({}, 1);



			trackPageView(this_el.props.tracker, this_el.props.home.page);
		});


	}
	
	componentWillMount(){
		const this_el = this;

		this.ApiCall();

		window.onpopstate = function (e) {
			this_el.modalRouting(e.currentTarget.location.hash);
		};
		this.modalRouting(window.location.hash);

	}

	cleanArray(actual) {
		var newArray = [];
		for (var i = 0; i < actual.length; i++) {
			if (actual[i]) {
				newArray.push(actual[i]);
			}
		}
		return newArray;
	}

	reload() {
		this.props.updateHomeState({
			keyword:'',
		}).then( () => {
			this.ApiCall();
		});
	}

	goBack() {
		this.props.history.goBack();
	}

	modalRouting(hash) {

		const this_el = this;
		let flag = Object.assign({}, this_el.props.home.flag);

		addClassToHtml();
		switch (hash) {
			case "#trendingCities":
			flag.modal.trending_cities = true;
			this_el.props.updateHomeState({
				flag: flag
			});
			trackGoToTrendingCitiesListModal(this.props.tracker,this_el.props.home.page,"trending_cities_direct");
			break;

			default:
			flag.modal.trending_cities = false;
			this_el.props.updateHomeState({flag: flag});
			removeClassToHtml();
		}
	}

	openMaxNightsAlert() {
		this.refs.max_nights_alert_overlay.classList.add('true')
	}

	closeMaxNightsAlert() {
		this.refs.max_nights_alert_overlay.classList.remove('true')
	}

	render() {
		const this_el = this;
		const meta = {
			title: this_el.props.home.meta_title,
			description: this_el.props.home.meta_desc,
			canonical: this_el.props.home.canonical_url,
			meta: {
				charset: "utf-8",
				property: {
					"og:site_name": "Guesthouser",
					"og:title": this_el.props.home.meta_title,
					"og:url": this_el.props.home.canonical_url,
					"og:type": "mobile web",
					"og:description": this_el.props.home.meta_desc,
					"og:image": "https://www.guesthouser.com/images/logo/logo_share.png"
				},
				name: {
					keywords: this_el.props.home.keyword
				}
			}
		};
		return (
			<DocumentMeta {...meta}>
			<div>
					
			{!this.props.error.flag.network || this.props.home.dataLoaded ?
				<div>
				{!this.props.error.flag.server ?
					<div>
						<OfflineStatus
							offline={checkIfOffline()}
						/>
						{/* showcase */}
						
						<div className="outer-showcase">
							<img src={logo} className="logo" alt="Guesthouser logo" />
						
							{/* search input */}
							{deviceVar.getDeviceUniqueId() &&
								<div>
									{this.props.home.popular_search_list.length > 0 ?
										<SearchBarContainer prevRoute={this.props.prevRoute} history={this.props.history} location={this.props.location} openMaxNightsAlert={this.openMaxNightsAlert} />
									:
										<div className="search-bar-container" onClick={this.openLocationModal}>
											<div className='search-input-box'>
												<span className='pull-right'><i className="search-icon"></i></span>
												Where do you want to go?
											</div>
										</div>
									}
								</div>
							}

							<div className="showcase center" style={{backgroundImage:"url(" +(this.props.home.offerPage ? this.props.home.offer.img_url : showcase) + ")"}}>
								{!this.props.home.offerPage && (
									<h1>175,000 holiday homes <br />2,200 destinations</h1>
								)}
							</div>
						</div>
							
						{/* Recently viewed */}
						{hasAccessToken() && this.props.home.recently_viewed_properties.length > 0 && (
							<div className="recent-section">
								<RecentlyViewed/>
							</div>
						)}
								
						{/* Trending cities */}
						<div className="trending-section">
							<HomeTrending updateHomeState={this.props.updateHomeState} tracker={this.props.tracker} />
						</div>
								
						{/* offer banners */}
						<div className="offers-section">
							<OfferBanners updateHomeState={this.props.updateHomeState} />
						</div>
						
						{this.props.home.collections.length > 0 && (
							<div className="explore-collection">
								<Collection />
							</div>
						)}
										
						{/* GH Features */}
						<div className="features-section">
							<GhFeatures />
						</div>
					</div>
					:
					<div className="info-window">
						<div className="window-container">
							<div className="imgb">
								<div className="img" style={{ backgroundImage: "url(" + serverError + ")" }}/>
							</div>
							<div className="txtb">
								<p>Sorry, our servers are busy. Please try again in a bit.</p>
							</div>
							<button className="retry" onClick={this.reload}>Retry</button>
						</div>
					</div>
				}
			</div>
			:
				<div className="info-window">
					<div className="window-container">
						<div className="imgb">
							<div className="img" style={{ backgroundImage: "url(" + noInternet + ")" }}/>
						</div>
						<div className="txtb">
							<p>Oops! You don't seem to be connected to the internet.</p>
						</div>
						<button className="retry" onClick={this.reload}>Retry</button>
					</div>
				</div>
			}

			{/* </div>
			} */}
			<div ref="max_nights_alert_overlay" className='max_nights_alert_overlay'>
				<div className="max_nights_alert">
					<div className="modal-body text-center">
						<h2 className="text">Non-Availability</h2>
						<div className='description'>
							<p>Minimum stay: {this_el.props.property.min_nights} night</p>
							<p>Maximum stay: {this_el.props.property.max_nights} nights</p>
						</div>
						<button className="btn" onClick={this.closeMaxNightsAlert}>Done</button>
					</div>
				</div>
			</div>

		</div>
		</DocumentMeta>
		);
	}
}


const mapStateToProps = store => {
	return {
		home: store.home,
		tracker: store.tracker.tracker,
		collection : store.collection,
		single_collection : store.single_collection,
		search : store.search,
		booking: store.booking,
		error: store.error,
		property:store.property
	};
};

const mapDispatchToProps = {
	updateHomeState,
	updateCollectionState,
	updateSearchState,
	updateBookingDataState,
	updatePropertyState,
	updateErrorState,
	updateSingleCollectionState,
	clearModals: () => dispatch => dispatch({ type: 'CLEAR_MODALS' })
};

export default connect(mapStateToProps,mapDispatchToProps)(Home);
