// actions
import { TRACKER_UPDATE_STATE } from "../actions/tracker/vars";

// initialState
const initialState = {
    tracker : ''
}



// action functions
const fnc = (state = initialState, action) => {
    switch (action.type) {
        case TRACKER_UPDATE_STATE:
            state.tracker = action.payload.tracker;
            return state;

        default:
            return Object.assign({}, state);
    }
};
export default fnc;
