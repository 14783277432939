import React from 'react';
import walletImage from "../../static/img/wallet.png";
import Tab1 from '../../components/wallet/tab.js';
import {getWalletApi} from '../../services/index.js';
import noInternet from "../../static/img/infoWindow/noInternet.svg";
import {addClassToHtml, removeClassToHtml} from '../../services/index.js';
import ReferEarn from "../../components/modal/share/share.js";
import {trackPageView, trackError} from '../../services/tracker/events.js';
import { connect } from 'react-redux';
import serverError from "../../static/img/serverError.png";
/**
 * Actions
 */
import { updateWalletStateObj } from '../../actions/wallet/actions';
import { updateErrorState } from '../../actions/error/actions';
import OfflineStatus from '../../components/Offlinestatus';
import { checkIfOffline } from '../../services/helpers';

class Wallet extends React.Component {

	constructor(props) {
		super(props);
		this.page="wallet";
		this.state = {
			apiData:false,
			isTop:true,
			isShadow:true,
			referEarnModal:false,

		}
		this.openTrips = this.openTrips.bind(this);
		this.getWalletDataApi = this.getWalletDataApi.bind(this);
		this.reload = this.reload.bind(this);
		this.goBack = this.goBack.bind(this);
		this.referEarnOpen = this.referEarnOpen.bind(this);
	}

	openTrips(){
		sessionStorage.setItem('tripToWallet','1');
		this.props.history.push('../trips?fromWallet=true');
	}

	getWalletDataApi(){
		const this_el = this;
		getWalletApi().then(function(response) {
			//console.log(response);
			this_el.props.updateWalletStateObj({ ...response.data.data, walletDataLoaded: true });
			this_el.setState({
				apiData:true
			});
			// updating network state
			let error_flag = Object.assign({}, this_el.props.error.flag);
			error_flag.network = false;
			error_flag.server = false;
			this_el.props.updateErrorState({
				flag: error_flag
			});
		}).catch(function(error){
			//console.log(error);
			if(typeof error.response !== 'undefined' && error.response.status === 1000){
				let error_flag = Object.assign({}, this_el.props.error.flag);
				error_flag.network = true;

				// updating network state
				this_el.props.updateErrorState({
					flag: error_flag
				});
			}else if(error.response.status === 500){
				//console.log('dgdg',error);
				let flag =  this.props.error.flag;
				flag.server = true; 
				this.props.updateErrorState({ flag: flag });
				
			}else{
				trackError(error);
			}
		});
	}

	componentDidMount(){
		
		this.getWalletDataApi();

		if (this.props.wallet.walletDataLoaded) {
			this.setState({
				apiData: true
			})
		}

		document.addEventListener('scroll', () => {
		  const isTop = window.scrollY < 230;
		  if (isTop !== this.state.isTop) {
			  this.setState({ isTop: isTop })
			}
		  const isShadow = window.scrollY < 5;
		  if (isShadow !== this.state.isShadow) {
			  this.setState({ isShadow: isShadow })
			}
		});

    	const this_el = this;
        window.onpopstate = function(e) {
            this_el.modalRouting(e.currentTarget.location.hash);
        };
        this.modalRouting(window.location.hash);
        trackPageView(this.props.tracker, this.page);
	}

	reload(){
		this.getWalletDataApi();
	}

    goBack(){
    	this.props.history.goBack();
    }

    modalRouting(hash){
        addClassToHtml();
        switch(hash){
            case '#referEarnModal':
                this.setState({referEarnModal: true});
                break;

            default:
                this.setState({
                	referEarnModal: false,
                });
                removeClassToHtml();
        }
    }
	referEarnOpen() {
		window.location.hash = 'referEarnModal';
	}
 
	render() {
		let walletData = this.props.wallet;
		return (
		  <div className="Wallet">
			 <header className={this.state.isShadow ? 'header active' : 'header active isShadow'}>
				<div className="row">
					<div className="col-12 text-left">
						<span className="back-icon" onClick={this.goBack}></span>
						<span className="middle-text text-center">Wallet</span>
						<span className={this.state.isTop ? 'header-balance pull-right' : 'header-balance pull-right active'}>
							{this.state.apiData && walletData.wallet.balance}
						</span>
					</div>														
				</div>
			</header>
			{!this.props.error.flag.network || this.props.wallet.walletDataLoaded ?
			<div>
				{!this.props.error.flag.server ?
					<div>
				<OfflineStatus
					offline={checkIfOffline()}
					bottom
				/>
				{this.state.apiData &&
				<div>
					<div className="wallet-image">
						<img src={walletImage} alt='walletImage' />
					</div>
					<div className="wallet-ballance">
						Current balance : {this.state.apiData && walletData.wallet.balance}
					</div>
					{this.state.apiData &&
						<Tab1 walletData={walletData} openTrips={this.openTrips} referEarnOpen={this.referEarnOpen} scrollAble={this.state.isTop}/>
					}
					<img src={noInternet} className="hidden" alt='no internet'/>
				</div>
				}
				<ReferEarn error={this.props.error} referEarnModal={this.state.referEarnModal} updateErrorState={this.props.updateErrorState} referEarnClose={this.goBack} />
			</div>
			:
				<div className="info-window">
					<div className="window-container">
						<div className="imgb">
							<div className="img" style={{ backgroundImage: "url(" + serverError + ")" }}/>
						</div>
						<div className="txtb">
							<p>Sorry, our servers are busy. Please try again in a bit.</p>
						</div>
						<button className="retry" onClick={this.reload}>Retry</button>
					</div>
				</div>
			}
			</div>
			:
			<div className="info-window">
				<div className="window-container">
					<div className="imgb">
						<div className="img" style={{backgroundImage:'url(' + noInternet + ')'}}></div>
					</div>
					<div className="txtb"><p>Oops! You don't seem to be connected to the internet.</p></div>
					<button className="retry" onClick={this.reload}>Retry</button>
				</div>
			</div>
			}

		  </div>
		);
	}
}

const mapStateToProps = (store) => {
	return {
		wallet: store.wallet,
		tracker: store.tracker.tracker,
		error: store.error
	}
}
const mapDispatchToProps = {
	updateWalletStateObj,
	updateErrorState
}

export default connect(mapStateToProps, mapDispatchToProps) (Wallet);