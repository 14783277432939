

/* APIs */
// get trip detail page data
let getTripDetailApi = function (data, args)
{
    return data.AXIOS.get(data.BASE_URL_V+'/booking/trip/'+args, {
        responseType: 'json',
        headers: {
            'Accept': 'application/json',
            'Content-Type': 'application/x-www-form-urlencoded',
            'device-unique-id': data.DEVICE_UNIQUE_ID,
            'Authorization' :'Bearer ' + data.ACCESS_TOKEN,
        }
    });

}

exports.getTripDetailApi = getTripDetailApi;