import React from 'react'
import Cropper from "react-cropper";

import "cropperjs/dist/cropper.css";

class ImageCropper extends React.Component {
    cropImage = () => {
        if (typeof this.cropper.getCroppedCanvas() === "undefined") {
          return;
        }
        const cropResult = this.cropper.getCroppedCanvas().toDataURL(); 
        this.cropper.getCroppedCanvas().toBlob(blob => {
            this.props.updateImage(cropResult, blob);
        }); 
        document.body.style.overflow = 'auto';
        document.body.style.position = 'inherit';
        this.props.close();
    }

    render () {
        const { src, close } = this.props
        return (
            <div className='image_cropper' >
                <div className='image_cropper_overlay'/>
                <div className='cropper_wrapper' >
                    <div className="back-btn-crooper" onClick={() => {
                        close()
                        document.body.style.overflow = 'auto';
                        document.body.style.position = 'inherit';
                    }} />
                    <Cropper
                        style={{ height: 400, width: "100%" }}
                        aspectRatio={1.3 / 1}
                        guides={false}
                        src={src}
                        ref={cropper => {
                            this.cropper = cropper;
                        }}
                    />
                </div>
                <div className='crop_button' onClick={this.cropImage} >Crop Image</div>
            </div>
        )
    }
}

export default ImageCropper