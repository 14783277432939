import React from 'react';
import ResponseMessage from '../../components/response/'

// services
import {getResetPasswordApi, getURLParameter} from '../../services/index.js';
import {trackPageView} from '../../services/tracker/events.js';
import {connect} from 'react-redux';
import {updateLoginStateObj} from './../../actions/login/actions';


var Form = require('react-formal');

var yup = require('yup');

function samePassword() {
 let parent = this.parent;
 if((parent.confirm !== undefined && parent.confirm.length === 0) || (parent.password !== undefined && parent.password.length === 0)){
   return true;
 }
 return  parent.password === parent.confirm
}

var useResetPasswordSchema = yup.object({
    otp_code: yup.string().required('OTP required'),
    password: yup.string().required('Please enter a password').min(6,'minimum length 6 character'),
    confirm: yup.string().required('Please confirm your password').test('Passwords-match', 'Passwords do not match', samePassword)
    
});
//reset_password_via
class ResetPassword extends React.Component {
   constructor(props) {
        super(props);
        this.page="reset_password";
        this.state = {
            'body_bg_color': '#00ba8c',
        }
        this.submitResetPasswordDetail = this.submitResetPasswordDetail.bind(this);
        this.getResetPasswordApiDataError = this.getResetPasswordApiDataError.bind(this);
        this.getResetPasswordApiDataSuccess = this.getResetPasswordApiDataSuccess.bind(this);
        this.resetMessages = this.resetMessages.bind(this);
        this.handleSubmit = this.handleSubmit.bind(this);
        this.onBlurShowMessage=this.onBlurShowMessage.bind(this);
        this.onFocusHideMessage=this.onFocusHideMessage.bind(this);
        this.formErrorHandle = this.formErrorHandle.bind(this);
        this.keyUpFunction = this.keyUpFunction.bind(this)
    }

    keyUpFunction(e){
        if (e.keyCode === 13) {
            e.target.blur();
        }
    }

    formErrorHandle(errors){

        //console.log(errors);
        for (var property in errors) {
            if (errors.hasOwnProperty(property) && errors[property] !== undefined && errors[property].length > 1) {
                errors[property] = errors[property][0]['message'];
                //console.log("\n\n\n\n", property, errors[property],errors[property].length);
            }
        }
        // errors.forEach((error, index) => {
        //   console.log("\n "+index + "  : ",error);
        // });
    }
    resetMessages(){
        this.props.updateLoginStateObj({
            'message':'',
            'show':false,
        });
    }

    componentDidMount(){
        document.body.style.backgroundColor = this.state.body_bg_color;
        document.body.setAttribute('data-color', this.constructor.name);
        trackPageView(this.props.tracker, this.page);
    }

    componentWillUnmount(){
        if (document.body.getAttribute('data-color') === this.constructor.name) {
            document.body.style.backgroundColor = null;
        }
    }
  

    handleSubmit(event) {
        this.props.updateLoginStateObj().then(() => {
            this.submitResetPasswordDetail({
                'otp_code': this.props.login.model.otp_code ,
                'password': this.props.login.model.password,
                'reset_password_via':getURLParameter(this.props.location.search, 'reset_password_via')
            });  
        });
    }

    submitResetPasswordDetail(args){
        let this_el = this;
        getResetPasswordApi(args).then(function(response){
            // success
            this_el.getResetPasswordApiDataSuccess(response);
        }).catch(function(error){
            // error
            this_el.getResetPasswordApiDataError(error);
        });
    }

    getResetPasswordApiDataError(error) {
        this.props.updateLoginStateObj({
            'message': error.response.data.error[0].message,
            'status': error.response.data.status,
            'show':true,
        });
    }
    
    getResetPasswordApiDataSuccess(response) {
        this.props.history.push('/user/password/success');
    }
    
    changeHandle(model){
        let formData = Object.assign({},this.props.login.model);
        for (var key in model) {
            if (model.hasOwnProperty(key) && formData.hasOwnProperty(key)) {
                formData[key] = model[key];
            }
        }
        this.props.updateLoginStateObj({
            model : formData
        });
    }
    onFocusHideMessage(e){

        let field_valid = Object.assign({},this.props.login.field_valid);
        field_valid[e.target.name] = true;
        //console.log(e.target.name, fiel);
        this.props.updateLoginStateObj({ field_valid : field_valid});
    }
    onBlurShowMessage(e){

        let field_valid = Object.assign({},this.props.login.field_valid);
        field_valid[e.target.name] = false;

        //console.log(e.target.name, fiel);
        this.props.updateLoginStateObj({ field_valid : field_valid});
        //this.setState({ validation:true,});
    }
    render() {
        console.log(this.props);
        return (
            <div className="password-wrapper">
                <header className="header">
                    <div className="row">
                        <div className="col-12 text-left">
                            <span className="cross-icon-white" onClick={() => this.props.history.goBack()}></span>
                        </div>                                                      
                    </div>
                </header>  
                <div className="img reset"></div>
                <div className="text">Reset your password</div>
                <div className="small-text">We have sent a four digit code on your<br/> phone/email.</div>
               <Form schema={useResetPasswordSchema} 
                onSubmit={this.handleSubmit} 
                onChange={model => this.changeHandle(model)}
                //errors={this.state ? this.state.errors : {}}
                onError={errors => this.formErrorHandle(errors)}
               > 
                <Form.Field type="text" name="otp_code" onKeyUp={(e) => this.keyUpFunction(e)} onFocus={(e) =>this.onFocusHideMessage(e)} onBlur={(e) => this.onBlurShowMessage(e)} className="" placeholder="Four digit code"/>
                {!this.props.login.field_valid.otp_code &&
                <Form.Message  for='otp_code'/>
                }
                <Form.Field type="password" name="password" onKeyUp={(e) => this.keyUpFunction(e)} onFocus={(e) =>this.onFocusHideMessage(e)} onBlur={(e) => this.onBlurShowMessage(e)} className="" placeholder="New password"/>
                {!this.props.login.field_valid.password &&
                    <Form.Message  for='password'/>
                }
                <Form.Field type="password" name="confirm" onKeyUp={(e) => this.keyUpFunction(e)} onFocus={(e) =>this.onFocusHideMessage(e)} onBlur={(e) => this.onBlurShowMessage(e)} className="reset1" placeholder="Confirm password"/>
                {!this.props.login.field_valid.confirm &&
                <Form.Message  for='confirm'/>
                }
                <Form.Button type="submit" className="btn white link">Reset Password</Form.Button>
                </Form>

                <div className={'responseAPI '+this.props.login.show }>
                    <ResponseMessage responseMessage={this.props.login.message}  responseStatus={this.props.login.status} resetMessages={this.resetMessages}/>
                </div>
            </div>
        );
    }
}
const mapStateToProps = (store) => {
	return {
        login : store.loginEmail,
        tracker: store.tracker.tracker
	}
}
const mapDispatchToProps = {
    updateLoginStateObj
}
export default connect(mapStateToProps,mapDispatchToProps) (ResetPassword);