import React from 'react';
import ResponseMessage from '../../components/response/';
import TermCondition from '../../components/modal/term/term.js'
import PrivacyPolicy from '../../components/modal/term/policy.js'
// services
import {getSignUpApi,login, SignupLoginSocial,hasAccessToken,} from '../../services/index.js';


import {trackPageView, trackLoginSignup} from '../../services/tracker/events.js';
import {connect} from 'react-redux';
import GoogleLogin from 'react-google-login';
import FacebookLogin from 'react-facebook-login';
/**
 * Actions
 */
import {updateSignupStateObj} from './../../actions/signup/actions';
import { updateHomeState } from "./../../actions/home/actions";

var Form = require('react-formal');
var yup = require('yup');

function samePassword() {
  let parent = this.parent;

  return  parent.password === parent.confirm
}

var userSignupSchema = yup.object({
    first_name:yup.string().required('First name is required').matches(/^[A-Za-z\s]{1,},{0,1}[A-Za-z\s]{0,}$/, 'Invalid first name'),
    last_name:yup.string().required('Last name is required').matches(/^[A-Za-z\s]{1,},{0,1}[A-Za-z\s]{0,}$/, 'Invalid last name'),
    email: yup.string().required('Email required').matches(/^([a-zA-Z0-9_\-\.]+)@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.)|(([a-zA-Z0-9\-]+\.)+))([a-zA-Z]{2,4}|[0-9]{1,3})(\]?)$/, 'invalid email'),
    password: yup.string().required('Please enter a password').min(6,'minimum length 6 character'),
    confirm: yup.string().required('Please confirm your password').test('Passwords-match', 'Passwords do not match', samePassword)  
});

class Signup extends React.Component {
    constructor(props) {
        super(props);
        this.page="signup";
        this.state = {
            current_url : window.location.href,
            gender_validation:false
        }
        
        this.submitSignupDetail = this.submitSignupDetail.bind(this);
        this.getSignupApiDataError = this.getSignupApiDataError.bind(this);
        this.getSignupApiDataSuccess = this.getSignupApiDataSuccess.bind(this);
        this.genderChange = this.genderChange.bind(this);
        this.handleSubmit = this.handleSubmit.bind(this);
        this.changeHandle = this.changeHandle.bind(this);
        this.showErrorMSg = this.showErrorMSg.bind(this);
        this.opentermCondition = this.opentermCondition.bind(this);
        //this.closetermCondition = this.closetermCondition.bind(this);
        this.resetMessages = this.resetMessages.bind(this);
        this.formErrorHandle = this.formErrorHandle.bind(this);
        this.onBlurShowMessage=this.onBlurShowMessage.bind(this);
        this.onFocusHideMessage=this.onFocusHideMessage.bind(this);
        this.openprivacyPolicy = this.openprivacyPolicy.bind(this);
        //this.closeprivacyPolicy = this.closeprivacyPolicy.bind(this);
        this.goBack = this.goBack.bind(this); 
        this.modalRouting = this.modalRouting.bind(this);
        this.keyUpFunction = this.keyUpFunction.bind(this)

        // Redirect if login logic
        if(hasAccessToken()){
            if (props.signup.afterLogin.length > 0) {
                if (props.signup.afterLogin === '/email/signup') { // if afterlogin is /email/signup itself ie this page
                    this.props.history.replace('/account')
                    this.props.updateSignupStateObj({
                        afterLogin: '/account'
                    })
                } else {
                    this.props.history.replace(props.signup.afterLogin)
                }
           } else {
                this.props.history.replace('/')
           }
        }
    }
    
    keyUpFunction(e){
        if (e.keyCode === 13) {
            e.target.blur();
        }
    }
    formErrorHandle(errors){

        //console.log(errors);
        for (var property in errors) {
            if (errors.hasOwnProperty(property) && errors[property] !== undefined && errors[property].length > 1) {
                errors[property] = errors[property][0]['message'];
                //console.log("\n\n\n\n", property, errors[property],errors[property].length);
            }
        }
    }
    resetMessages(){
        this.props.updateSignupStateObj({
            'errorMSg': true,
            'show':false
        });
    }

    showErrorMSg(){
        const this_el = this;
        if(this_el.props.signup.gender === ''){
            this.setState({
                gender_validation:true
            })
        }
        return;
    }

    componentDidMount(){
        this.props.updateSignupStateObj({gender:''})
        window.scrollTo(0, 0);
        let urlAfterLogin = sessionStorage.getItem('UrlAfterLogin');
        document.body.style.backgroundColor = this.props.signup.body_bg_color;
        document.body.setAttribute('data-color', this.constructor.name);
         // on url change fuction
        let this_el = this;
        window.onpopstate = function(e) {
            this_el.modalRouting(e.currentTarget.location.hash);
        };

        // fuction to check if any modal open
        this.modalRouting(this.props.history.location.hash);
        trackPageView(this.props.tracker, this.page);
    }

    
    componentWillUnmount(){
        if (document.body.getAttribute('data-color') === this.constructor.name) {
            document.body.style.backgroundColor = null;
        }
    }

    
    
    genderChange(e) {
        this.setState({gender_validation:false})
        this.props.updateSignupStateObj({
            gender: e.target.value,
        });
    }
    handleSubmit(event) {
        const this_el =this
        if(this_el.props.signup.gender === ''){
            this.setState({gender_validation:true})
            return;
        }
        let flag = this.props.signup.flag;
        flag.loading=true;
        this.props.updateSignupStateObj({
            flag:flag
        }).then(() => {
            this.submitSignupDetail({
                'first_name': this_el.props.signup.model.first_name,   
                'last_name': this_el.props.signup.model.last_name,
                'gender': this_el.props.signup.gender,
                'email': this_el.props.signup.model.email,
                'password': this_el.props.signup.model.password,
                'confirm': this_el.props.signup.model.confirm,
                'source': this_el.props.signup.source,    
                'referral_code': this_el.props.signup.referral_code,
                'device_type': this_el.props.signup.mobileWeb,
            });      
        });
    }
    getSignupApiDataError(error){
        let this_el = this;
        this_el.props.updateSignupStateObj({
            'message': error.response.data.error[0].message,
            'status':false,
            'show':true,
        })
    }
    getSignupApiDataSuccess(response) {
        console.log(response);
        let this_el = this;
        trackLoginSignup(this_el.props.tracker, this_el.page, 'direct', {'user_id':response.data.data.user_profile.user_id, 
                type:'email', event:response.data.data.user_profile.event});
        this_el.props.updateSignupStateObj({
            'message': response.data.data.message,
            'status': response.data.status,
            'is_user_referred':response.data.data.user_profile.is_user_referred,
            'is_mobile_verified':response.data.data.user_profile.is_mobile_verified,
            'add_email':response.data.data.user_profile.add_email,
            'first_name':response.data.data.user_profile.first_name,
            'last_name':response.data.data.user_profile.last_name,
            'profile_image':response.data.data.user_profile.profile_image,
            'active_request_count':response.data.data.user_profile.active_request_count ,
        });

        if(response.data.status === true){
            login(response.data.data.access_token, response.data.data.refresh_token);

            // for hit home api
            this.props.updateHomeState({
                dataLoaded: false,
            });
            // end for hit home api

            this_el.props.history.push('/user/contact');
        }
		localStorage.setItem("request_count",(this_el.props.signup.active_request_count === undefined ? 0: this_el.props.signup.active_request_count));
        localStorage.setItem('addEmail', response.data.data.user_profile.add_email)
        localStorage.setItem("is_user_referred", this.props.signup.is_user_referred);
        localStorage.setItem('SkipModal',this.props.signup.is_mobile_verified);
        localStorage.setItem("first_name", this_el.props.signup.first_name);
        localStorage.setItem("last_name", this_el.props.signup.last_name);
        localStorage.setItem("profile_image", this_el.props.signup.profile_image);

        return Promise.resolve()
    }

    submitSignupDetail(args){
        let this_el = this;
        console.log('signup:', args);
        getSignUpApi(args).then((response) => {
            // success
            this_el.getSignupApiDataSuccess(response).then(()=>{
                let flag = this.props.signup.flag;
                flag.loading=false;
                this_el.props.updateSignupStateObj({flag:flag});
            });
           
        }).catch((error)=>{
            // error
            let flag = this_el.props.signup.flag;
            flag.loading=false;
            this_el.props.updateSignupStateObj({flag:flag});

            if(error.hasOwnProperty('response') && error.response === undefined){
                let networkError = {
                    response:{
                        data:{
                            status:false,
                            error:[{
                                message:'Please check your internet connection'
                            }]
                        }
                    }
                }
                this_el.getSignupApiDataError(networkError);
            }else if(error.hasOwnProperty('response') && error.response.status === 500){
                let ServerError = {
                    response:{
                        data:{
                             message:'Server Error! please try after sometime'
                        }
                    }
                }
                this_el.getSignupApiDataError(ServerError);
            }else{
                this_el.getSignupApiDataError(error);
            }

        });
    }
    modalRouting(hash){
        let flag = this.props.signup.flag
        switch(hash){

            case '#termConditionModal':
                flag.termConditionModal=true;
                this.props.updateSignupStateObj({flag: flag});
                break;
            case '#privacyPolicyModal':
                flag.privacyPolicyModal=true;
                this.props.updateSignupStateObj({flag: flag});
                break;

            default:
                flag.termConditionModal=false;
                flag.privacyPolicyModal=false;
                this.props.updateSignupStateObj({
                    flag:flag
                });
        }
    }
    opentermCondition() {
        window.location.hash = 'termConditionModal'
    }

 
    openprivacyPolicy() {
        window.location.hash = 'privacyPolicyModal'
    }

    changeHandle(model){
        
        model.errorMSg = false;
        let formData = Object.assign({},this.props.signup.model);
        for (var key in model) {
            if (model.hasOwnProperty(key) && formData.hasOwnProperty(key)) {
                formData[key] = model[key];
            }
        }
        this.props.updateSignupStateObj({
            model : formData
        });
    }
    
    onFocusHideMessage(e){
        let field_valid = this.props.signup.flag.field_valid;
        field_valid[e.target.name] = true;
        //console.log(e.target.name, fiel);
        this.props.updateSignupStateObj({
            field_valid : field_valid
        });
    }
    onBlurShowMessage(e){
        let field_valid = this.props.signup.flag.field_valid;
        field_valid[e.target.name] = false;
        //console.log(e.target.name, fiel);
        this.props.updateSignupStateObj({
            field_valid : field_valid
        });
        //this.setState({ validation:true,});
    }
    goBack(){
        this.props.history.goBack();
    }
    signupCall(args){
        let this_el = this;
        SignupLoginSocial(args).then(function(response){
            // success
            this_el.apiDataSuccess(response, args);
            
        }).catch(function(error){
            // error
            this_el.apiDataErrors(error);
            
        });

    }


    apiDataSuccess(response, args) {
        let this_el = this;
        let flag = this.props.signup.flag;
        flag.loading=false;
        this_el.props.updateSignupStateObj({
            'is_mobile_verified':response.data.data.user_profile.is_mobile_verified,
            'is_user_referred':response.data.data.user_profile.is_user_referred,
            'mobile':response.data.data.user_profile.mobile,
            'add_email':response.data.data.user_profile.add_email,
            'first_name':response.data.data.user_profile.first_name,
            'last_name':response.data.data.user_profile.last_name,
            'profile_image':response.data.data.user_profile.profile_image,
            'active_request_count':response.data.data.user_profile.active_request_count ,
            flag:flag,

        }).then( () =>  {
            var type="facebook";
            if(args.source === "1"){
                type="google";
            }

            trackLoginSignup(this_el.props.tracker, this_el.page, 'direct', {'user_id':response.data.data.user_profile.user_id, 
                type:type, event:response.data.data.user_profile.event});
                
		    localStorage.setItem("request_count",(this_el.props.signup.active_request_count === undefined ? 0: this_el.props.signup.active_request_count));
            localStorage.setItem("first_name", this_el.props.signup.first_name);
            localStorage.setItem("last_name", this_el.props.signup.last_name);
            localStorage.setItem("profile_image", this_el.props.signup.profile_image);

            localStorage.setItem('addEmail', response.data.data.user_profile.add_email)
            localStorage.setItem("is_user_referred", this_el.props.signup.is_user_referred);
            localStorage.setItem('SkipModal',this_el.props.signup.is_mobile_verified);
            localStorage.setItem('contact_number',this_el.props.signup.mobile);                
            const apiData = response.data;
            try {

                if(apiData.status === true){
                    login(apiData.data.access_token, apiData.data.refresh_token);


                    
                    let urlAfterLogin = sessionStorage.getItem('UrlAfterLogin');
                    //console.log('ismobilevarifies',this_el.props.signup.is_mobile_verified );
                     if(this_el.props.signup.is_mobile_verified === 1){
                        let urlAfterLogin = this.props.signup.afterLogin;
                        this.props.history.replace(urlAfterLogin);
                    }else{
                        this_el.props.history.push("/user/contact");
                    }        
                    //this.props.history.push(urlAfterLogin);
                }
            } catch (error) {
                // write your code here to handle catch
            }
        });
    
    }
    
    apiDataErrors(error) {
        let flag = this.props.signup.flag;
        flag.show = true;
        flag.status = false;
        flag.loading=false;
        this.props.updateSignupStateObj({
            'message': error.response.data.error[0].message,
            flag:flag
        });
    }
    render() {
        const this_el =this;
        const responseGoogle = (response) => {
            let flag = this.props.signup.flag;
            flag.loading = true;
            this.props.updateSignupStateObj({
                flag:flag
            })
            //console.log(response);

            //const this_el=this
            if(typeof response.error === "undefined"){
                this.signupCall({
                'access_token':response.tokenId,
                'source':'1'
            }); 
            }
            else{
                // response.details // holds the error detail
                let flag = this.props.signup.flag;
                flag.show = true;
                flag.loading = false;
                this.props.updateSignupStateObj({
                    'message': "Not a valid origin" ,
                    'status': 'error',
                    flag:flag
                });
            }
        }

        const responseFacebook = (response) => { 
            let flag = this.props.signup.flag;
            flag.loading = true;  
            this.props.updateSignupStateObj({
                flag:flag
            })
            //console.log("Facebook Login Response \n", response);
            if(response.hasOwnProperty("accessToken")){
                this.signupCall({
                    'access_token':response.accessToken,
                    'source':'2',
                });
            }
            else{
                let flag = this.props.signup.flag;
                flag.show = true;
                flag.loading = false;   
                this.props.updateSignupStateObj({
                    'message': "Issue with facebook login",
                    'status': 'error',
                    flag:flag   
                });
            }
            
        }
        return (

            <div className="signup-container">
            <div className={this_el.props.signup.flag.loading ? 'loading active' : 'loading '}></div>
            <div className="login-signup" ref="table">
                <header className="header">
                    <div className="row">
                        <div className="col-12 text-left">
                            <span className="back-btn-grey" onClick={() => this.props.history.goBack()}></span>
                            <span className="middle-text text-center">Signup</span>
                        </div>
                    </div>
                </header>
                <div className="login-signup-wrap">
                    <FacebookLogin
                        appId={process.env.REACT_APP_FACEBOOK_CLIENT_ID}
                        fields="name,email,picture"
                        scope="public_profile,user_friends,user_gender,user_birthday,email"
                        cssClass="btn fb link "
                        textButton="Signup with Facebook"
                        callback={responseFacebook}
                        onClick={responseFacebook}
                        redirectUri={this.state.current_url}
                    />

                    <GoogleLogin
                        className="btn google link"
                        clientId={process.env.REACT_APP_GOOGLE_CLIENT_ID}
                        autoLoad ={false}
                        onClick = {responseGoogle}
                        onSuccess={responseGoogle}
                        buttonText="Signup with Google+"
                        onFailure={responseGoogle}
                        >
                    </GoogleLogin>

                    <div className="row line-wrap">
                        <div className="col-5 line"></div>
                        <div className="col-2 text-center or"></div>
                        <div className="col-5 line"></div>
                    </div>

                    <Form schema={userSignupSchema}
                            onSubmit={this.handleSubmit}
                           // errors={this.props.signup ? this.props.signup.errors : {}}
                            onChange={model => this.changeHandle(model)}
                            onError={errors => this.formErrorHandle(errors)}
                            >
                        
                        <Form.Field name='first_name' onKeyUp={(e) => this.keyUpFunction(e)} onFocus={(e) =>this.onFocusHideMessage(e)} onBlur={(e) => this.onBlurShowMessage(e)}  className="textbox" placeholder="First name" autoComplete="on"/>
                        {!this.props.signup.flag.field_valid.first_name &&
                            <Form.Message className="firstname-error" for='first_name'/>
                        }
                        
                        
                        <Form.Field type="text" name='last_name' onKeyUp={(e) => this.keyUpFunction(e)} onFocus={(e) =>this.onFocusHideMessage(e)} onBlur={(e) => this.onBlurShowMessage(e)}  className="textbox lastname" placeholder="Last name" autoComplete="on"/>
                        
                        {!this.props.signup.flag.field_valid.last_name &&
                        <Form.Message className="lastname-error " for='last_name'/>
                        }
                        <div className="middle">
                            <div className="row">
                                <div className="sec">
                                    <label htmlFor="genderMale">
                                        <input type="radio" name="gender" id="genderMale" value="Male" onClick={this.genderChange} />
                                        <div className="front-end box">
                                            <span>Male</span>
                                        </div>
                                    </label>
                                </div>
                               
                                <div className="sec">
                                    <label htmlFor="genderFemale">
                                        <input type="radio" name="gender" id="genderFemale" value="Female" onClick={this.genderChange}/>
                                        <div className="back-end box">
                                            <span>Female</span>
                                        </div>
                                    </label>
                                </div>
                            </div>
                            {this.state.gender_validation &&
                                <div className="validation-error">
                                    Please select gender
                                </div>
                            }
                        </div>

                        <Form.Field name='email' onKeyUp={(e) => this.keyUpFunction(e)} type="email" onFocus={(e) =>this.onFocusHideMessage(e)} onBlur={(e) => this.onBlurShowMessage(e)}  className="textbox" placeholder="Email" autoComplete="on"/>
                        {!this.props.signup.flag.field_valid.email &&
                        <Form.Message for='email'/>
                        }                        
                        
                        <Form.Field  type="password" onKeyUp={(e) => this.keyUpFunction(e)} onFocus={(e) =>this.onFocusHideMessage(e)} onBlur={(e) => this.onBlurShowMessage(e)}   name="password" placeholder="Password" />
                        {!this.props.signup.flag.field_valid.password &&
                        <Form.Message for='password'/>
                        }

                        <Form.Field  type="password" onKeyUp={(e) => this.keyUpFunction(e)} onFocus={(e) =>this.onFocusHideMessage(e)} onBlur={(e) => this.onBlurShowMessage(e)}   name="confirm" placeholder="Confirm password" />
                        {!this.props.signup.flag.field_valid.confirm &&
                        <Form.Message for='confirm'/>
                        }
                        <div className="condition" >By signing up, I agree to the <span onClick={this.opentermCondition}>Terms of Use</span> & <span onClick={this.openprivacyPolicy}>Privacy Policy</span></div>
                        
                        <Form.Button  type='submit' onClick={this.showErrorMSg} className="btn green link">Signup</Form.Button>
                        <TermCondition/>
                        <PrivacyPolicy/>
                    </Form>
                </div>
            </div>
            <div className={'responseAPI '+this.props.signup.show }>
                <ResponseMessage responseMessage={this.props.signup.message}  responseStatus={this.props.signup.status} resetMessages={this.resetMessages}/>
            </div>
            </div>
        );
    }
}
const mapStateToProps = (store) => {
	return {
        signup : store.signupEmail,
        tracker: store.tracker.tracker,
        error : store.error
	}
}
const mapDispatchToProps = {
    updateSignupStateObj,
    updateHomeState
}
export default connect(mapStateToProps, mapDispatchToProps) (Signup);