import React from 'react';
import { connect } from 'react-redux';
import { updateSearchState } from '../../actions/search/actions';

class PrepaymentBookingDetails extends React.Component {
    constructor(props){
        super(props);

        this.openSelectGuestsModal = this.openSelectGuestsModal.bind(this);
    }

    openSelectGuestsModal(){
        const this_el = this;
        let flag = Object.assign({}, this_el.props.search.flag);
        flag.modal.select_guest = true;
        flag.prepayment = true;
        flag.property_preview = false;

        this_el.props.updateSearchState({
            flag: flag
        });
    }

    render() {
        const ellipsis = {
             textOverflow: 'ellipsis',
             overflow:'hidden',
             WebkitBoxOrient: 'vertical'
        };

        const locale = "en-us";
        let checkin_month = this.props.booking.checkin_date.toLocaleString(locale, {month: "short" });
        let checkout_month = this.props.booking.checkout_date.toLocaleString(locale, { month: "short" });

        let room_type;
        if(this.props.booking.room_type === 'Private Room'){
            room_type = "Room"
        }else if(this.props.booking.room_type === 'Shared Room'){
            room_type = "Bed"
        }else {
            room_type = this.props.property.property_type;
        }

        return (
            <div className="booking-property-details">
                   <div className="row">
                        <div className="col-4">
                            {this.props.prepayment.property_images.map(function(item, index){
                                if(index < 1){
                                    return(
                                        <div className="property-image" key={index} style={{backgroundImage:'url('+item.image+')'}}></div>
                                    )
                                }else {
                                    return false
                                }
                            })}
                        </div>
                        <div className="col-8">
                            <div style={ellipsis} className="heading">
                                {this.props.property.property_title}
                            </div>
                            <div className="subheading">
                                {this.props.booking.location}
                            </div>
                        </div>
                   </div>

                  
                   <div className="row">
                        <div className="col-6">
                            {this.props.booking.checkin_date !== 1 && this.props.booking.checkout_date !== 1 &&
                                <div className="booking-date">
                                    {this.props.booking.checkin_date.getDate() + ' ' + checkin_month + ' - ' + this.props.booking.checkout_date.getDate() + ' ' + checkout_month + ' ' + this.props.booking.checkout_date.getFullYear() }
                                </div>
                            }
                            <div className="booking-details">
                                {this.props.booking.guest_count > 0 && <span>{this.props.booking.guest_count} {this.props.booking.guest_count > 1 ? 'Guests' : 'Guest'}</span>}
                                {this.props.booking.unit_count > 0 && <span>, {this.props.booking.unit_count + ' ' + room_type + '' + (this.props.booking.unit_count > 1 ? ('s') : (''))}</span>}
                            </div>
                        </div>
                        {!this.props.request &&
                            <div className="col-6">
                                <div className="modify-btn" onClick={this.openSelectGuestsModal}>
                                    Modify <span className="arrow-icon"></span>
                                </div>
                            </div>
                        }
                   </div>
                    
            </div>
        );
    }
}

const mapStateToProps = (store) => {
    return {
        booking : store.booking,
        property : store.property,
        search: store.search,
        prepayment: store.prepayment
    }
}

const mapDispatchToProps = {
    updateSearchState
}

export default connect(mapStateToProps, mapDispatchToProps) (PrepaymentBookingDetails);