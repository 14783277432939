Object.defineProperty(exports, "__esModule", {
  value: true
});


exports["default"] = {

  getPosition: function () {
    function getPosition(value, min, max) {
      return (value - min) / (max - min) * 100;
    }

    return getPosition;
  }(),
  getValue: function () {
    function getValue(pos, min, max) {

      
      if (pos === 0) {
        return min;
      } else if (pos === 100) {
        return max;
      }


      var decimal = pos / 100;
      let result = (max-min)*decimal +min;
      return  Math.round(result);

      /**
       * 
       */

      // let step = 0;
      // if(steps){


      //   let prevKey = steps[Object.keys(steps)[0]];
      //   for (var key in steps) {

      //       const percent = parseInt(key);
      //       const row = steps[key];

      //       if(parseInt(pos) > percent){  
      //           step = parseInt(steps[key][1]);
      //       }
      //       prevKey = key;
      //       //console.log(parseInt(pos),percent,step);
      //   }
      //   if(step){
      //     return Math.round(Math.ceil(result/step)*step);
      //   }
      // } 
      
      // return Math.round(Math.ceil((max*pos/100)));
    }

    return getValue;
  }()
};