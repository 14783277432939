import React from 'react';
import {connect} from 'react-redux';
import {updateTrackerState} from './../../actions/tracker/actions';


class Tracker extends React.Component {
    constructor(props) {
        super(props);
        let tracker = props.tracker;
        props.updateTrackerState({
            tracker : tracker
        });
    }

    render(){
        return(
            <div></div>
        )
    }
}



const mapStateToProps = store => {
    return {
        tracker_fnc : store.tracker
    };
};

const mapDispatchToProps = {
    updateTrackerState
};
export default connect(mapStateToProps,mapDispatchToProps)(Tracker);