import React from 'react';
import Modal from 'react-modal';
import {connect} from 'react-redux';
import { updateUserStateObj} from '../../../actions/setting/actions';

import ResponseMessage from '../../response/';

// services
import {getDeactivateAccountApi} from '../../../services/index.js';
import { checkIfOffline } from '../../../services/helpers';

var Form = require('react-formal');

var yup = require('yup');


var useResetPasswordSchema = yup.object({
	password: yup.string().required('Password required'),
});
class DeactivateAccount extends React.Component {
	 constructor() {
		super();
		this.handleSubmit = this.handleSubmit.bind(this);
		this.changeHandle = this.changeHandle.bind(this);
		this.showErrorMSg = this.showErrorMSg.bind(this);
		this.deactivateAccount = this.deactivateAccount.bind(this);
		this.getDeactivatedApiDataSuccess = this.getDeactivatedApiDataSuccess.bind(this);
		this.getDeactivateApiDataError = this.getDeactivateApiDataError.bind(this);
		this.resetMessages = this.resetMessages.bind(this);
		this.formErrorHandle = this.formErrorHandle.bind(this);
		this.onBlurShowMessage=this.onBlurShowMessage.bind(this);
        this.onFocusHideMessage=this.onFocusHideMessage.bind(this);
	}

	resetMessages(){
		this.props.updateUserStateObj({
			'message':'',
			'show':false,
		});
	}
	showErrorMSg(){
		this.props.updateUserStateObj({
			'errorMSg':true,
		})
	}
	handleSubmit(event){
		if (checkIfOffline()) {
			this.props.toggleStatus()
			return
		}
		this.deactivateAccount({
			'password': this.props.user.model.password ,
			'loading':true,
		});
	}
	changeHandle(model){
		this.props.updateUserStateObj({ model });
	}

	deactivateAccount(args){
        let this_el = this;
        getDeactivateAccountApi(args).then(function(response){
            // success
            this_el.getDeactivatedApiDataSuccess(response);
        }).catch(function(error){
			// error
			if(error.hasOwnProperty('response') && error.response === undefined){
                let networkError = {
                    response:{
                        data:{
							error:[
								{message:'Please check your internet connection'}
							]
                        }
                    }
                }
               // this_el.setState({status:false});
                this_el.getDeactivateApiDataError(networkError);
            }else if(error.hasOwnProperty('response') && error.response.status === 500){
				let networkError = {
                    response:{
                        data:{
							error:[
								{message:'Server Error! please try after sometime'}
							]
                        }
                    }
                }
				this_el.getDeactivateApiDataError(networkError);
            }else{
				this_el.getDeactivateApiDataError(error);
			}
            
        });
    }

    getDeactivateApiDataError(error) {
        this.props.updateUserStateObj({
            'message': error.response.data.error[0].message,
			'status': false,
			'show':true
        });
    }
    
    getDeactivatedApiDataSuccess(response) {
        let this_el = this;

        this.props.updateUserStateObj({
        	'status': response.data.status,
            'message': response.data.data.message,
            'loading':false
        });
        setTimeout(function() {
			this_el.props.goBack();
			this_el.props.goBackAccount();
        }, 4000);
        

        
    }
    formErrorHandle(errors){
        for (var property in errors) {
            if (errors.hasOwnProperty(property) && errors[property] !== undefined && errors[property].length > 1) {
                errors[property] = errors[property][0]['message'];
                //console.log("\n\n\n\n", property, errors[property],errors[property].length);
            }
        }
    }
    onFocusHideMessage(){
        this.props.updateUserStateObj({ validation:false,});
    }
    onBlurShowMessage(){
        this.props.updateUserStateObj({ validation:true,});
    }
  render() {
  	const this_el = this;
	return (
	  <div>
		<Modal
		  isOpen={this.props.deactivateModalOpen}
		  onAfterOpen={this.afterOpenModal}
		  onRequestClose={this.props.deactivateModalClose}
		  contentLabel="Modal"
				overlayClassName={{
				base: 'overlay-modal overlay-popup',
				afterOpen: '',
				beforeClose: ''
			  }}
		  className={{
				base: 'content-modal',
				afterOpen: 'editProfile deactivate',
				beforeClose: ''
			  }}
			>
		  <div className="modal-body text-center">
		  <div className={this.props.user.laoding?'loading active':'loading'}></div>
			<span className="back-btn" onClick={this.props.goBack}></span>
			<div className="verify-text1 text-left">I understand that deactivating my account will also deactivate my listings & profile.</div>
			<Form schema={useResetPasswordSchema} 
				onSubmit={this.handleSubmit}
				onChange={model => this.changeHandle(model)}
				//errors={this.props.user ? this.props.user.errors : {}}
                onError={errors => this.formErrorHandle(errors)}
				>	
				<Form.Field type="password"className="input-box" name="password" placeholder="Enter password" onFocus={this.onFocusHideMessage} onBlur={this.onBlurShowMessage} value={this.props.user.old_password}/>
				{this.props.user.validation &&
					<Form.Message for={['password']}/>
				}
				<br/><br/>
				<div className="response-msg">
					<ResponseMessage responseMessage={this_el.props.user.message} responseStatus={this_el.props.user.status}  resetMessages={this_el.resetMessages}/>
				</div>
				<br/>
				<Form.Button  type='submit' onClick={this_el.showErrorMSg}className="link green" >Deactivate</Form.Button>
			</Form>
		  </div>
		</Modal>
	  </div>
	);
  }
}
const mapStateToProps = (store) =>{
	return{
	   user : store.UserChangeData
   }
}
const mapDispatchToProps = {
   updateUserStateObj
}
export default connect(mapStateToProps, mapDispatchToProps) (DeactivateAccount);