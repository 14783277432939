import React from 'react';
import DatePicker from 'react-mobile-datepicker';
//import DateOfBirth from "../../components/modal/editProfile/dateOfBirth.js";
import MaritalStatus from "../../components/modal/editProfile/maritalStatus.js";
import Gender from "../../components/modal/editProfile/gender.js";
import ProfessionModal from "../../components/modal/editProfile/profession.js";
import SpokenLanguages from "../../components/modal/editProfile/spokenLanguages.js";
import TravelledPlaces from "../../components/modal/editProfile/travelledPlaces.js";
import Description from "../../components/modal/editProfile/description.js";
import noInternet from "../../static/img/infoWindow/noInternet.svg";
import {connect} from 'react-redux';
import {updateProfileStateObj} from '../../actions/profile/actions';
import { updateErrorState } from "./../../actions/error/actions";
import ImageCropper from '../../components/cropper'
import {updateSignupStateObj} from './../../actions/signup/actions';
import serverError from "../../static/img/serverError.png";
//services
import {getLoginAccountDataApi, getUpdateProfileApi,getURLParameter, 
	getEditprofileApi} from '../../services/index.js';

import {trackPageView,trackError} from '../../services/tracker/events.js';
import { checkIfOffline } from '../../services/helpers.js';
import OfflineStatus from '../../components/Offlinestatus/index.js';

class EditProfile extends React.Component {
	constructor(props) {
		super(props);
		this.page="edit_profile";
		this.state = {
            'host_id':getURLParameter(this.props.location.search, 'host_id'),
			showCropper: false,
			imageSrc: null,
			imageFile: null
		};

		this.localObj = Object.assign({}, this.props.profile);
		this.onTodoChange = this.onTodoChange.bind(this);
		this.getProfileApiData = this.getProfileApiData.bind(this);
		this.getProfileApiDataSuccess = this.getProfileApiDataSuccess.bind(this);
		this.getProfileApiDataError = this.getProfileApiDataError.bind(this);
		this.genderModalOpen = this.genderModalOpen.bind(this);
		this.maritalStatusOpen = this.maritalStatusOpen.bind(this);
		this.professionModalOpen = this.professionModalOpen.bind(this);
		this.saveGender = this.saveGender.bind(this);
		this.saveMaritalStatus = this.saveMaritalStatus.bind(this);
		this.spokenLanguagesOpen = this.spokenLanguagesOpen.bind(this);
		this.saveProfession = this.saveProfession.bind(this);
		this.saveLanguages = this.saveLanguages.bind(this);
		this.travelledPlacesOpen = this.travelledPlacesOpen.bind(this);
		this.savetravelledPlaces = this.savetravelledPlaces.bind(this);
		this.descriptionOpen = this.descriptionOpen.bind(this);
		this.savedescription = this.savedescription.bind(this);
		this.getEditProfileApiDataError=this.getEditProfileApiDataError.bind(this);
		this.getEditProfileApiDataSuccess=this.getEditProfileApiDataSuccess.bind(this);
		this.handleSubmit = this.handleSubmit.bind(this);
		this.checkDataChangeListner = this.checkDataChangeListner.bind(this);
        this.dob = this.dob.bind(this);
        this.toDate = this.toDate.bind(this);
        this.getProfilePicApiDataError = this.getProfilePicApiDataError.bind(this);
        this.getProfilePicApiDataSuccess = this.getProfilePicApiDataSuccess.bind(this);
        this.submitProfilePic = this.submitProfilePic.bind(this);
        this.redirectToProfile = this.redirectToProfile.bind(this);
		this.reload = this.reload.bind(this);
		this.goBack=this.goBack.bind(this);
		this.closeErrorMessage_modal = this.closeErrorMessage_modal.bind(this);
	}
	redirectToProfile(){
		this.props.updateProfileStateObj().then(()  => {
			if(this.props.profile.flag.api.profile_data === false && this.props.profile.flag.api.profile_image === false){
				this.props.history.push("/user/profile");
			}
		})
		
	}

	dob(date_of_birth){
		let inputDate = date_of_birth.split('-');
		let monthNum = inputDate[1];
		let years = inputDate[2];
		const months = ["Jan", "Feb", "Mar", "Apr", "May", "Jun","Jul", "Aug", "Sep", "Oct", "Nov", "Dec"];
		return (inputDate[0] + ' ' + months[monthNum-1] + ' '+ years);
	}
	toDate(dateStr) {
		var parts = dateStr.split("-");
		return new Date(parts[2], parts[1] - 1, parts[0]);
	}
	dateConvertor(data){
		let dd = data.getDate();
		let mm = data.getMonth()+1; //January is 0!

		let yyyy = data.getFullYear();
		if(dd<10){
			dd='0'+dd;
		} 
		if(mm<10){
			mm='0'+mm;
		} 
		return dd+'-'+mm+'-'+yyyy;
	}


	handleClick = () => {
			this.props.updateProfileStateObj({ isOpen: true});
			window.location.hash = 'dob'
		 document.body.style.overflow = 'hidden';
		 document.body.style.position = 'fixed';
	}
 
	handleCancel = () => {
		this.props.updateProfileStateObj({ isOpen: false });
		window.location.hash = ''
		document.body.style.overflow = 'auto';
		document.body.style.position = 'inherit';
	}
 
	handleSelect = (time) => {
		window.location.hash = ''
		this.props.updateProfileStateObj({
		 'dob' : this.dateConvertor(time),
		 'isOpen': false
		
	 });
	document.body.style.overflow = 'auto';
	document.body.style.position = 'inherit';
	}
	closeErrorMessage_modal(){
		this.refs.errorMessage_modal.classList.remove('true');
		//this.props.history.replace('/')
	}
	formatDate(date) {
	  var monthNames = [
		"Jan", "Feb", "Mar",
		"April", "May", "Jun", "Jul",
		"Aug", "Sep", "Oct",
		"Nov", "Dec"
	  ];

	  var day = date.getDate();
	  var monthIndex = date.getMonth();
	  var year = date.getFullYear();

	  //return day + ' ' + monthNames[monthIndex] + ' ' + year;
	  return day + ' ' + monthNames[monthIndex] + ' ' + year;
	}
	modalRouting(hash){
		let flag =this.props.profile.flag;
        switch(hash){
			case '#genderModal':
				flag.model.genderModal=true;
				this.props.updateProfileStateObj({flag:flag});
								break;
				case '#dob':
				this.props.updateProfileStateObj({ isOpen: true});
								break;
			case '#maritalStatus':
				flag.model.maritalStatus=true;
                this.props.updateProfileStateObj({flag:flag});
                break;
			case '#spokenLanguage':
				flag.model.spokenLanguage=true;
                this.props.updateProfileStateObj({flag:flag});
                break;
			case '#professionModal':
				flag.model.professionModal=true;
                this.props.updateProfileStateObj({flag:flag});
                break;
			case '#descriptions':
				flag.model.descriptions=true;
                this.props.updateProfileStateObj({flag:flag});
                break;
			case '#travelledPlaces':
				flag.model.travelledPlaces=true;
                this.props.updateProfileStateObj({flag:flag});
                break;
			default:
			this.props.updateProfileStateObj({ isOpen: false});
				flag.model.genderModal=false;
				flag.model.maritalStatus=false;
				flag.model.spokenLanguage= false;
				flag.model.professionModal= false;
				flag.model.descriptions=false;
				flag.model.travelledPlaces=false;
                this.props.updateProfileStateObj({flag:flag});
        }
    }
	genderModalOpen() {
		window.location.hash = 'genderModal'
	}

	maritalStatusOpen() {
		window.location.hash = 'maritalStatus'
	}

	professionModalOpen() {
		window.location.hash = 'professionModal'
	}
	spokenLanguagesOpen() {
		window.location.hash = 'spokenLanguage'
	}

	travelledPlacesOpen() {
		window.location.hash = 'travelledPlaces'
	}

	descriptionOpen(){
		window.location.hash = 'descriptions'
	}

	onTodoChange({target}){
		this.props.updateProfileStateObj({
			  [target.name]: target.value,
		});
	}


	getProfileApiDataSuccess(response) {
		let is_default_pic = this.props.profile.is_default_pic;
		if(response.data.data.hasOwnProperty('is_avatar_image')){
			if(response.data.data.is_avatar_image === 1 || response.data.data.is_avatar_image === true  || response.data.data.is_avatar_image === "true"){
				is_default_pic = true;
			}
		}

		this.props.updateProfileStateObj({
				'first_name': response.data.data.first_name,
				'last_name': response.data.data.last_name,
				'dob': response.data.data.dob,
				'marital_status': response.data.data.marital_status,
				'gender': response.data.data.gender,
				'profession': response.data.data.profession,
				'profile_image': response.data.data.profile_image,
				'spoken_languages': response.data.data.spoken_languages,
				'travelled_places': response.data.data.travelled_places,
				'description':response.data.data.description,
				'is_default_pic' : is_default_pic,
				extendedDataLoaded: true
			
		}).then(() => {
			this.localObj = Object.assign({}, this.props.profile);
			this.checkDataChangeListner();
		});
		let error_flag = Object.assign({}, this.props.error.flag);
		error_flag.network = false;
		error_flag.server = false;
		// updating network state
		this.props.updateErrorState({
			flag : error_flag
		});

	}

	getProfileApiDataError(error) {
		if(typeof error.response !== 'undefined' && error.response.status === 1000){
			let flag = this.props.error.flag;
			flag.network = true;
			this.props.updateErrorState({ flag: flag });
		}else if(error.response.status === 500){
			//console.log('dgdg',error);
			let flag =  this.props.error.flag;
			flag.server = true; 
			this.props.updateErrorState({ flag: flag });
			
		}else{
			trackError(error);
		}
	}

	getProfileApiData(args){
		let this_el = this;
		getLoginAccountDataApi(args).then(function(response){
			// success
			this_el.getProfileApiDataSuccess(response);

		}).catch(function(error){
			// error
			this_el.getProfileApiDataError(error);
		});
	}

	saveGender(gender){
		this.props.updateProfileStateObj({
			'gender' : gender
		});
	}

	saveProfession(profession){
		console.log('profession',profession)
		this.props.updateProfileStateObj({
			'profession' : profession
		});
	}

	saveMaritalStatus(maritalStatus){
		this.props.updateProfileStateObj({
			'marital_status' : maritalStatus
		});
	}
	saveLanguages(spokenLanguage){
		this.props.updateProfileStateObj({
			'spoken_languages' : spokenLanguage
		});
	}
	savetravelledPlaces(travelledPlace){
		this.props.updateProfileStateObj({
			'travelled_places' : travelledPlace
		});
	}
	savedescription(description){
		this.props.updateProfileStateObj({
			'description' : description
		});
	}
	componentDidMount() {
		if (checkIfOffline()) {
			this.props.updateErrorState({
				flag: {
					network: true
				}
			})
		} else {
			this.props.updateErrorState({ flag: { network: false } });
		}
		//this.refs.save_change_button.classList.remove('active');
		this.getProfileApiData(this.state.host_id);
		window.scrollTo(0, 0);
		document.addEventListener('scroll', () => {
		  const isTop = window.scrollY < 228;
		  if (isTop !== this.props.profile.isTop) {
			  this.props.updateProfileStateObj({ isTop })
			}
		});
		 // on url change fuction
        let this_el = this;
        window.onpopstate = function(e) {
            this_el.modalRouting(e.currentTarget.location.hash);
        };

        // fuction to check if any modal open
        this.modalRouting(this.props.history.location.hash);

        trackPageView(this.props.tracker, this.page);
    
	}

	handleSubmit(event) {

		let this_el = this;
		var profileData = Object.assign({}, this_el.props.profile);
		profileData.flag.api.profile_data = true;
		profileData.loading=true;

		if(profileData.flag.profile_image_changed){
			profileData.flag.api.profile_image = true;
		}



		this.props.updateProfileStateObj(profileData).then(() => {
			console.log(profileData);
			// Api Call to update profile
            this.submitProfileDetail({
				'first_name': this.props.profile.first_name,
				'last_name': this.props.profile.last_name,
				'dob': this.props.profile.dob,
				'marital_status': this.props.profile.marital_status,
				'gender': this.props.profile.gender,
				'profession': this.props.profile.profession,
				'spoken_languages': this.props.profile.spoken_languages,
				'travelled_places': this.props.profile.travelled_places,
				'description':this.props.profile.description,
			})  
			if(profileData.flag.profile_image_changed){
				//
				this.submitProfilePic({
					'picture': profileData.profile_image_file,
				})
			} 
		});
		console.log('profileData',profileData.profile_image)
		this.props.updateSignupStateObj({
			profile_image:profileData.profile_image
		});
		localStorage.setItem('profile_image',profileData.profile_image);
		this.props.updateProfileStateObj({
			profile_image:profileData.profile_image
		})
		
		
	}

	getEditProfileApiDataError(error) {
		const this_el =this;
		this.props.updateProfileStateObj({loading:false});
		if(typeof error.response !== 'undefined' && error.response.status === 1000){
			let error_flag = Object.assign({}, this_el.props.error.flag);
			error_flag.network = true;
			// updating network state
			this_el.props.updateErrorState({
				flag : error_flag
			});
			
		} else {
			
			if(typeof error.response.data !== "undefined" && error.response.data.error.length > 0 ){
				let a = error.response.data.error.map(function (msg){
					return msg.message
				})
				
				this_el.props.updateErrorState({
					message: a.join(', ')
				}).then(() => {
					this.openErrorMessage_modal();
				});
			}else{
				trackError(error);
			}
		}
	}

	getEditProfileApiDataSuccess(response) {
		let this_el = this;
		let flag = this_el.props.profile.flag
		var profileData = this_el.props.profile
		flag.api.profile_data = false
		profileData.message = response.data.data.message;
		this.props.updateProfileStateObj(profileData).then(() => {
			this.redirectToProfile();
		});
	}
	submitProfileDetail(args){
		console.log('args',args)
		let this_el = this;
		getEditprofileApi(args).then(function(response){
			// success
			this_el.getEditProfileApiDataSuccess(response);
		}).catch(function(error){
			// error
			this_el.getEditProfileApiDataError(error);
			// this.setState({
			// 	loading:false
			// });
		});
	}
	openErrorMessage_modal(){
		this.refs.errorMessage_modal.classList.add('true');
	}
	getProfilePicApiDataSuccess(response) {
		let this_el = this;
		let profileData = this_el.props.profile;
		var flag = this_el.props.profile.flag;
		flag.api.profile_image = false;
		flag.profile_image_changed = false;
		this.props.updateProfileStateObj(profileData).then(() => {
			this.redirectToProfile();
		});
	}

	getProfilePicApiDataError(error){
		let flag = this.props.profile.flag;
			flag.profile_image_changed =true
		this.props.updateProfileStateObj({
			flag : flag
		});
	}
	
	submitProfilePic(args){
		let this_el = this;
		getUpdateProfileApi(args).then(function(response){
			// success
			this_el.getProfilePicApiDataSuccess(response);
		}).catch(function(error){
			// error
			this_el.getProfilePicApiDataError(error);
		});
	}

	checkDataChangeListner(){
		const this_el = this;
		let  temp_state = Object.assign({}, this.props.profile);
		let  temp_local_obj = Object.assign({}, this.localObj);

		let keys_to_delete = ['time','isOpen','done','cancel','customHeader','theme','dateFormat','showFormat','message','button','isTop'];
		for(let i=0; i < keys_to_delete.length; i++){
			delete temp_state[keys_to_delete[i]];
			delete temp_local_obj[keys_to_delete[i]];
			//delete temp_state.keys_to_delete[i];
		}
		
		if(JSON.stringify(temp_local_obj) !== JSON.stringify(temp_state)){

			//console.log('checkDataChangeListner : show button');
			this_el.refs.save_change_button.classList.add('active');
		}else{
			//console.log('checkDataChangeListner : hide button');
			if(this_el.refs.save_change_button !== undefined){
				this_el.refs.save_change_button.classList.remove('active');
			}
		}
	}

	componentDidUpdate(){
		
		if (this.props.error.flag.network !== true){
			this.checkDataChangeListner();
		}
	}

	_handleImageChange(e) {
		e.preventDefault();
		const file = e.target.files[0];
		const reader = new FileReader();
		reader.onload = () => {
			this.setState({
				imageSrc: reader.result,
				imageFile: file,
				showCropper: true
			});
			document.body.style.overflow = 'hidden';
		 	document.body.style.position = 'fixed';
		};
		reader.readAsDataURL(file);
	}

	updateImage = (img, file) => {
		const flag = this.props.profile.flag;
		flag.profile_image_changed = true;
		this.props.updateProfileStateObj({
			profile_image_file : file,
			profile_image: img,
			flag : flag
		});
		document.body.style.overflow = 'auto';
		document.body.style.position = 'inherit';
	}

goBack(){
    this.props.history.goBack();
}
reload(){
	this.getProfileApiData(this.state.host_id);
}
setEditorRef = (editor) => this.editor = editor
	render() {
		const this_el = this;
		//console.log(this.props)

		let {profile_image} = this.props.profile;
		let profile_image_new_upload_class = 'profile-cover';

		if(this.state.is_default_pic && this.state.profile_image_changed === false){
				profile_image_new_upload_class = 'default-pic';
		}
	return (
		<div className="edit">
					<OfflineStatus 
						offline={checkIfOffline()}
						bottom
					/>
			{
				this.state.showCropper && (
					<ImageCropper
						src={this.state.imageSrc}
						updateImage={this.updateImage}
						close={_ => this.setState({ showCropper: false, imageSrc: null, imageFile: null })}
					/>
				)
			}
			{!this.props.error.flag.network || this.props.profile.extendedDataLoaded ?
			
			<div className="profile-edit-section">
				{!this.props.error.flag.server ?
				<div>
				<div className={this.props.profile.loading?'loading	 active':'loading ' }></div>
				<header className={this.props.profile.isTop ? 'header' : 'header active'}>
					<div className="row">
						<div className="col-4 text-left">
							<div className="back-icon" onClick={() => this.goBack()}>
							</div>
						</div>
						<div className="col-4 text-center">
							<div className="title">Edit profile</div>
						</div>
						<div className="col-4 text-right">
							<input
								type="file"
								className="camera-icon"
								accept="image/*"
								name="newImage"
								onChange={(e)=>this._handleImageChange(e)}
								onClick={e => { e.target.value = null }}
							/>
						</div>	
					</div>
				</header>
				
				<div className={"profile-pic " + profile_image_new_upload_class} style={{backgroundImage:'url('+ profile_image +')'}}></div>

				<div className="edit-details">	
					<div className="row">
						<div className="col-12">            			
							<div className="heading">Personal details</div>
						</div>
						<div className="col-12"> 
							<span className="profile-icon"></span>
							<input type="text" name="first_name" 
							className="profile" 
							value={this.props.profile.first_name}
							onChange={this.onTodoChange}
							placeholder="First Name"/> 

							<input type="text" 
							name="last_name"
							onChange={this.onTodoChange} 
							value={this.props.profile.last_name} 
							placeholder="Last Name"/>
						
						</div>	            	
					</div>
					<div className="row">
						<div className="more-about"   onClick={this.handleClick}>
							<div className="col-10">
								<div className="icon birth"></div>
								<div className="details">
									<div className="name">Date of birth</div>
									<div className="text">{this.props.profile.dob ? this.dob(this.props.profile.dob):'_'}</div>
								</div>
							</div>
							<div className="col-2 text-right">
								<div className="icons arrow"></div>
							</div>
						</div>
						<div className="more-about" onClick={() => this.maritalStatusOpen()}>
							<div className="col-10">
								<div className="icon marital"></div>
								<div className="details">
									<div className="name">Marital status</div>
									<div className="text">{this.props.profile.marital_status? this.props.profile.marital_status:'_'}</div>
								</div>
							</div>
							<div className="col-2 text-right">
								<div className="icons arrow"></div>
							</div>
						</div>
						<div className="more-about" onClick={() => this.genderModalOpen()}>
							<div className="col-10">
								<div className="icon gender"></div>
								<div className="details">
									<div className="name">Gender</div>
									<div className="text">{this.props.profile.gender}</div>
								</div>
							</div>
							<div className="col-2 text-right">
								<div className="icons arrow"></div>
							</div>
						</div>
						<div className="more-about" onClick={() => this.professionModalOpen()}>
							<div className="col-10">
								<div className="icon profession"></div>
								<div className="details">
									<div className="name">Profession</div>
									<div className="text">{this.props.profile.profession?this.props.profile.profession:'_'}</div>
								</div>
							</div>
							<div className="col-2 text-right">
								<div className="icons arrow"></div>
							</div>
						</div>
					</div>
				</div>
				<div className="row">
					<div className="col-12 wrap-more">
						<div className="heading">Tell us more about yourself</div>
						<div className="more-about" onClick={() => this.spokenLanguagesOpen()}>
							<div className="col-10">
								<div className="icon language"></div>
								<div className="details">
									<div className="name">Languages I speak</div>
									<div className="text">{this.props.profile.spoken_languages?this.props.profile.spoken_languages:'_'}</div>
								</div>
							</div>
							<div className="col-2 text-right">
								<div className="icons arrow"></div>
							</div>
						</div>
						<div className="more-about" onClick={() => this.travelledPlacesOpen()}>
							<div className="col-10">
								<div className="icon place"></div>
								<div className="details">
									<div className="name">Places I’ve travelled to</div>
									<div className="text">{this.props.profile.travelled_places?this.props.profile.travelled_places:'_'}</div>
								</div>
							</div>
							<div className="col-2 text-right">
								<div className="icons arrow"></div>
							</div>
						</div>
						<div className="more-about" onClick={() => this.descriptionOpen()}>
							<div className="col-10">
								<div className="icon tidbits"></div>
								<div className="details">
									<div className="name">Interesting tidbits about me</div>
									<div className="text">{this.props.profile.description?this.props.profile.description:'_'}</div>
								</div>
							</div>
							<div className="col-2 text-right">
								<div className="icons arrow"></div>
							</div>
						</div>
					</div>
				</div>
				<div className='floating-btn-box text-center' ref="save_change_button">
					{/*<a className="map"><i></i> Map</a>*/}
					<a className="floating-btn" onClick={this.handleSubmit}>Save changes</a>
					{/*<a className="filter" onClick={this.submitParams}><i></i> test</a>*/}
				</div>
				<div ref="errorMessage_modal" className='unavailable_date_overlay'>
					<div className="max_nights_alert">
						<div className="modal-body text-center">
							{/* <h2 className="text">Error!</h2> */}
							<div className='description'>
							<p>{this_el.props.error.message}</p>
							</div>
							<button className="btn" onClick={this.closeErrorMessage_modal}> Done</button>
						</div>
					</div>
				</div>
				<MaritalStatus
					maritalStatusOpen={this.props.profile.flag.model.maritalStatus}
					maritalStatus={this.props.profile.marital_status}
					saveMaritalStatus={this.saveMaritalStatus}
					goBack={this.goBack}
					/>
				<Gender
					genderModalOpen={this.props.profile.flag.model.genderModal}
					gender={this.props.profile.gender}
					saveGender={this.saveGender}
					goBack={this.goBack}
					/>
				<ProfessionModal
					professionModalOpen={this.props.profile.flag.model.professionModal}
					profession={this.props.profile.profession}
					saveProfession={this.saveProfession}
					goBack={this.goBack}

					/>
				<SpokenLanguages
					spokenLanguagesOpen={this.props.profile.flag.model.spokenLanguage}
					spokenLanguage={this.props.profile.spoken_languages}
					saveLanguages={this.saveLanguages}
					goBack={this.goBack}
					/>

				<TravelledPlaces
					travelledPlacesOpen={this.props.profile.flag.model.travelledPlaces}
					travelledPlace={this.props.profile.travelled_places}
					savetravelledPlaces={this.savetravelledPlaces}
					goBack={this.goBack}
					/>

				<Description
					descriptionOpen={this.props.profile.flag.model.descriptions}
					description={this.props.profile.description}
					savedescription={this.savedescription}
					goBack={this.goBack}
					/>
				
				<DatePicker
					value={this.props.profile.dob ? this.toDate(this.props.profile.dob) : new Date()}
					isOpen={this.props.profile.isOpen}
					onSelect={this.handleSelect}
					onCancel={this.handleCancel} 
					confirmText={this.props.profile.done}
					cancelText={this.props.profile.cancel}
					customHeader={this.props.profile.customHeader}
					theme={this.props.profile.theme}
					showFormat={this.props.profile.showFormat}
					dateFormat={this.props.profile.dateFormat}
					max={new Date()}
					min={new Date(1960, 0, 1)}
					/>
			</div>
			:	
			<div className="info-window">
				<div className="window-container">
					<div className="imgb">
						<div className="img" style={{ backgroundImage: "url(" + serverError + ")" }}/>
					</div>
					<div className="txtb">
						<p>Sorry, our servers are busy. Please try again in a bit.</p>
					</div>
					<button className="retry" onClick={this.reload}>Retry</button>
				</div>
			</div>
			}
			</div>
			:
			<div className="info-window">
				<div className="window-container">
					<div className="imgb">
						<div className="img" style={{backgroundImage:'url(' + noInternet + ')'}}></div>
					</div>
					<div className="txtb"><p>Oops! You don't seem to be connected to the internet.</p></div>
					<button className="retry" onClick={this.reload}>Retry</button>
				</div>
			</div>
			}
		</div>
			
		);
	}
}
const mapStateToProps = (store) => {
	return {
		profile : store.userProfile,
		tracker: store.tracker.tracker,
		error: store.error
    }
}
const mapDispatchToProps = {
	updateProfileStateObj,
	updateSignupStateObj,
	updateErrorState
}
export default connect(mapStateToProps,mapDispatchToProps)(EditProfile);
