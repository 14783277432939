

/* APIs */
// get signup data
let getSignUpApi = function (data, args)
{
    return data.AXIOS.post(data.BASE_URL_V+'/user', data.QS.stringify({
            first_name: args.first_name,
            last_name: args.last_name,
            email:args.email,
            gender:args.gender,
            password:btoa(args.password),
            source:args.source,
            referral_code:args.referral_code,
            device_type:'mobileWeb',
        }), {
            headers: {
                'Accept': 'application/json',
                'Content-Type': 'application/x-www-form-urlencoded',
                'device-unique-id': data.DEVICE_UNIQUE_ID,
            }
        }
    );
}

exports.getSignUpApi = getSignUpApi;