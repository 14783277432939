import React from 'react';
import DocumentMeta from 'react-document-meta';
import '../../static/css/vendors/swiper.css';
import PropertyGalleryList from '../../components/propertyPreview/propertyGalleryList.js';
import GallerySlider from '../../components/propertyPreview/gallerySlider.js';
import serverError from "../../static/img/serverError.png";
import {Route} from 'react-router-dom'
import { connect } from 'react-redux';


import PropertyDetail  from '../../components/propertyPreview/propertyDetail.js';
import SpaceList  from '../../components/propertyPreview/spaceList.js';
import AboutProperty  from '../../components/propertyPreview/aboutProperty.js';
import AmenitiesList  from '../../components/propertyPreview/amenitiesList.js';
import PropertyTags  from '../../components/propertyPreview/propertyTags.js';
import PropertyPhotos  from '../../components/propertyPreview/propertyPhotos.js';
import PricingInfo  from '../../components/propertyPreview/pricingInfo.js';
import PropertyPolicy  from '../../components/propertyPreview/propertyPolicy.js';
import PaymentOption  from '../../components/propertyPreview/paymentOption.js';
import PropertyReviews  from '../../components/propertyPreview/propertyReviews.js';
import PropertyLocation from '../../components/propertyPreview/propertyLocation.js';
import SimilarListing from '../../components/propertyPreview/similarListing.js';
import PropertyBottomOption from '../../components/propertyPreview/propertyBottomOption.js';
import AllReviewsList from '../../components/propertyPreview/allReviewsList.js';
import SelectGuestsModal from '../../components/home/searchBarContainer/selectGuestsModal.js';
import DateRangeCalendar from '../../components/home/searchBarContainer/dateRangeCalendar.js';
import PricingCalendar from '../../components/propertyPreview/pricingCalendar.js';
import Share from '../../components/modal/share/propertyShare.js';
import noInternet from "../../static/img/infoWindow/noInternet.svg";
import AboutPropertyModal from '../../components/propertyPreview/aboutPropertyModal';
import AllAmenitiesList from '../../components/propertyPreview/allAmenitiesList.js';
import MapFullView from '../../components/propertyPreview/mapFullView.js';
import OfflineStatus from '../../components/Offlinestatus'
//services
import { hasAccessToken, getPropertyPreviewDataApi, getWishlistAddDataApi, getWishlistDeleteDataApi, saveUrlLogin, getPricingCalendarDataApi, removeClassToHtml, addClassToHtml, getURLParameter, removeClassToBody} from '../../services/index.js';

import {trackPageView, trackPropertyView,trackError} from '../../services/tracker/events.js';
import { getObjectVal, updateStoredPropertyWishlist, checkIfOffline } from './../../services/helpers';


/**
* Actions
*/
import { updatePropertyState } from '../../actions/property/actions';
import { updateBookingDataState } from '../../actions/booking-data/actions';
import { updateSearchState } from '../../actions/search/actions';
import { updateErrorState } from '../../actions/error/actions';
import { updatePrepaymentState } from '../../actions/prepayment/actions';
import { updateHomeState } from "./../../actions/home/actions";
import { updateCollectionState } from '../../actions/collection/actions';
import { updateProfileStateObj} from '../../actions/profile/actions';

class PropertyPreview extends React.Component {
	
	constructor(props) {
		
		super(props);
		
		this.toggleActiveClass =this.toggleActiveClass.bind(this);
		this.openDateRangeCalendar = this.openDateRangeCalendar.bind(this);
		this.submitPropertyDataToApi = this.submitPropertyDataToApi.bind(this);
		this.toPrepayment = this.toPrepayment.bind(this);   
		this.toProperty = this.toProperty.bind(this);
		this.reload = this.reload.bind(this);
		
		this.goBack = this.goBack.bind(this); 
		this.wishListRef = this.wishListRef.bind(this); 
		this.modalRouting = this.modalRouting.bind(this);
		
		this.openShareModal = this.openShareModal.bind(this);
		this.toReviews = this.toReviews.bind(this);
		this.openMaxNightsAlert = this.openMaxNightsAlert.bind(this);
		this.closeMaxNightsAlert = this.closeMaxNightsAlert.bind(this);
		this.openUnitAlert = this.openUnitAlert.bind(this);
		this.closeUnitAlert = this.closeUnitAlert.bind(this);
		this.openUnavailableDate = this.openUnavailableDate.bind(this);
		this.closeUnavailableDate = this.closeUnavailableDate.bind(this);
		this.closeErrorMessage_modal = this.closeErrorMessage_modal.bind(this);

		if (this.props.property.prepaymentModify){
			let urlParams = '?&checkin=' + this.dateConvertor(this.props.booking.checkin_date) + '&checkout=' + this.dateConvertor(this.props.booking.checkout_date) + '&guests=' + this.props.booking.guest_count+ '&units=' + this.props.booking.unit_count;
			this.props.history.replace('/property/' + this.props.match.params.hashId + urlParams);
		}

	}
	
	wishListRef(element){
		if(element && hasAccessToken()){
			
			let this_el = this;
			let storedHashId = sessionStorage.getItem('hashId');
			let currentRefId = element.getAttribute("data-id");
			
			if(storedHashId !== null && storedHashId === currentRefId){
				this_el.getWishlistApi(storedHashId);
				element.classList.add('active');
				sessionStorage.removeItem('hashId');
			}
		}
	}
	
	
	openDateRangeCalendar(){
		let flag =  this.props.search.flag;
		flag.modal.date_range = true;
		this.props.updateSearchState({flag:flag})
	}
	
	getProperyPreviewApiDataSuccess(response){
		let this_el = this;
		this_el.props.updatePropertyState({
			prepaymentModify: false
		})

		this.getPricingCalendarDataApi(response.data.data.property_hash_id);
		
		this.props.updateBookingDataState({
			'location': response.data.data.location.location_name,
			'area': response.data.data.location.area,
			'city': response.data.data.location.city,
			'state': response.data.data.location.state,
			'country': response.data.data.location.country,
			'lat': response.data.data.location.latitude,
			'lng': response.data.data.location.longitude,
			'room_type': response.data.data.space[1].name,
			'unit_count': response.data.data.selected_units > 0 ? response.data.data.selected_units : response.data.data.required_units,
		});
		
		let property_flag = Object.assign({}, this_el.props.property.flag);
		property_flag.api.is_loading = true;

		if(response.data.data.property_video.length > 0){
			this.props.updatePropertyState({
				maxPhotos:4
			})
		}

		// updating network state
		let error_flag = Object.assign({}, this_el.props.error.flag);
		error_flag.network = false;
		error_flag.server = false;
		this_el.props.updateErrorState({
			flag: error_flag
		});
		// updating network state

		this.props.updatePropertyState({
			'bookable': response.data.data.bookable,
			'property_images': response.data.data.property_images,
			'host_id': response.data.data.host_id,
			'property_hash_id': response.data.data.property_hash_id,
			'property_id': response.data.data.id,
			'property_title': response.data.data.property_title,
			'title': response.data.data.title,
			'location_name': response.data.data.location.location_name,
			'property_image_count': response.data.data.property_image_count,
			'property_score': response.data.data.property_score,
			'host_image': response.data.data.host_image,
			'host_name': response.data.data.host_name,
			'property_pricing': response.data.data.property_pricing,
			'footer_data': response.data.data.footer_data,
			'left_div': response.data.data.footer_data.left_div,
			'right_div': response.data.data.footer_data.right_div,
			'review_count': response.data.data.review_count,
			'space': response.data.data.space,
			'description': response.data.data.description,
			'amenities': response.data.data.amenities,
			'tags': response.data.data.tags,
			'usp': response.data.data.usp,
			'cancellation_policy': response.data.data.cancellation_policy,
			'payment_methods': response.data.data.payment_methods,
			'reviews': response.data.data.reviews,
			'how_to_reach': response.data.data.how_to_reach,
			'about': response.data.data.about,
			'whishlist': response.data.data.is_wishlisted,
			'property_video': response.data.data.property_video,
			'flag': property_flag,
			'similar_properties': response.data.data.similar_properties,
			'canonical_url': response.data.data.meta.canonical_url,
			'meta_image': response.data.data.meta.meta_image,
			'meta_desc': response.data.data.meta.meta_desc,
			'meta_title': response.data.data.meta.meta_title,
			'available_units': response.data.data.available_units,
			'guests_per_unit': response.data.data.guests_per_unit,
			'max_nights': response.data.data.max_nights,
			'min_nights': response.data.data.min_nights,
			'property_type': response.data.data.space[0].name,
			selected_checkout: response.data.data.selected_checkout,
			selected_checkin: response.data.data.selected_checkin,
			selected_units: response.data.data.selected_units,
			selected_guests: response.data.data.selected_guests,
			dataLoaded: true,
			indexList: this.props.property.indexList.filter(x => x.hash === response.data.data.property_hash_id).length > 0
			? this.props.property.indexList
			: [
				...this.props.property.indexList,
				{
					hash: response.data.data.property_hash_id,
					index: 0
				}
			]
		}).then( () => {
			if (Object.keys(response.data.data).includes('selected_checkin') && (response.data.data.selected_checkin.length === 0 || response.data.data.selected_checkout.length === 0)) {
				this.props.updateBookingDataState({
					checkin_date: 1,
					checkout_date: 1,
					guest_count: response.data.data.selected_guests,
					unit_count: response.data.data.selected_units,
				})
				this.props.history.replace({
					pathname: this.props.location.pathname
				})
			}
			let property_data = {
				// using YYYY-MM-DD
				checkin: response.data.data.checkin,
				checkout: response.data.data.checkout,
				pid: this_el.props.property.property_id,
				hpid: this_el.props.property.property_hash_id,
				city: this_el.props.booking.city,
				state: this_el.props.booking.state,
				country: this_el.props.booking.country.ccode,
				country_name: this_el.props.booking.country.name,
				guests: this_el.props.booking.guest_count > 0 ? this_el.props.booking.guest_count : 1,
				lat: this_el.props.booking.lat,
				long: this_el.props.booking.lng,
				area: this_el.props.booking.area,
				location: this_el.props.booking.location,
				properties: [{
					hpid: response.data.data.property_hash_id,
					title: response.data.data.property_title,
					price: response.data.data.property_pricing.per_night_price_unformatted
				}],
				currency_code: response.data.data.property_pricing.currency.iso_code
			};
			trackPropertyView(this_el.props.tracker, this_el.page, 'direct', property_data);
		});
		
	}
	
	getProperyPreviewApiDataError(error) {		
		const this_el = this;
		
		if(typeof error.response !== 'undefined' && error.response.status === 1000){
			let error_flag = Object.assign({}, this_el.props.error.flag);
			error_flag.network = true;
			
			// updating network state
			this_el.props.updateErrorState({
				flag : error_flag
			});
			
		} else if (error.response.status === 500) {
			//console.log('dgdg',error);
			let flag = this.props.error.flag;
			flag.server = true;
			this.props.updateErrorState({ flag: flag });

		} else {
			
			if(typeof error.response.data !== "undefined" && error.response.data.error.length > 0 ){
				
				let a = error.response.data.error.map(function (msg){
					return msg.message
				})
				
				this_el.props.updateErrorState({
					message: a.join(', ')
				}).then(() => {
					this.openErrorMessage_modal();
				});
			}else{
				trackError(error);
			}
		}
	}
	getProperyPreviewApi(data){
		let this_el = this;
		
		getPropertyPreviewDataApi(data).then(function(response){
			// success
			this_el.getProperyPreviewApiDataSuccess(response);
			this_el.setDataInStore(response);

		}).catch(function(error){
			// error
			this_el.getProperyPreviewApiDataError(error);
		});
	}

	componentWillMount(){
		let this_el = this;

		let property_flag = Object.assign({}, this.props.property.flag);
		property_flag.api.is_loading = false;
		property_flag.new_similar_list = false;

		this.props.updatePropertyState({
			'flag': property_flag,
		});

		// on url change fuction
		this.props.history.listen(location => {
			this_el.modalRouting(location.hash);
		})
		
		// fuction to check if any modal open
		this.modalRouting(this.props.history.location.hash);
		trackPageView(this.props.tracker, this.page);
		
		
	}
	
		
	toggleActiveClass(event) {
		event.preventDefault();
		event.stopPropagation();
		if(hasAccessToken() && !checkIfOffline()){
			this.props.updateHomeState({
				dataLoaded: false
			});
			this.props.updateProfileStateObj({
				extendedDataLoaded:false,
				user_hash_id:'',
			});

			sessionStorage.setItem('wishlistForCollection', event.target.parentNode.id);

			if(event.target.className.indexOf('active') > -1 ){
				event.target.className = 'wishlist-icon';
				this.getWishlistDeleteApi(event.target.parentNode.id);
				updateStoredPropertyWishlist(event.target.parentNode.id,0)
			} else {
				event.target.className = 'wishlist-icon active';
				this.getWishlistApi(event.target.parentNode.id);
				updateStoredPropertyWishlist(event.target.parentNode.id, 1)
			}
		} else if (checkIfOffline()) {
			this.props.updateErrorState({
				flag: {
					network: true
				}
			})
		} else {
			saveUrlLogin();
			sessionStorage.setItem('hashId',event.target.parentNode.id);
			this.signup();
		}
	}
	
	signup(){
		this.props.history.push('/signup');
	}
	
	// get wishlist items
	getWishlistApiDataSuccess(response) {
		//console.log('added', response);
	}
	getWishlistApiDataError(error) {
		//console.log('error', error);
	}
	getWishlistApi(data){
		let this_el = this;
		getWishlistAddDataApi(data).then(function(response){
			// success
			this_el.getWishlistApiDataSuccess(response);
		}).catch(function(error){
			// error
			this_el.getWishlistApiDataError(error);
		});
	}
	
	// delete wishlist items
	getWishlistDeleteDataSuccess(response) {
		//console.log('Deleted', response);
	}
	getWishlistDeleteDataError(error) {
		//console.log('error', error);
	}
	getWishlistDeleteApi(data){
		let this_el = this;
		getWishlistDeleteDataApi(data).then(function(response){
			// success
			this_el.getWishlistDeleteDataSuccess(response);
		}).catch(function(error){
			// error
			this_el.getWishlistDeleteDataError(error);
		});
	}
	
	dateConvertor(data){
		if(data !== 1){
			let dd = data.getDate();
			let mm = data.getMonth()+1; //January is 0!
			
			let yyyy = data.getFullYear();
			if(dd<10){
				dd='0'+dd;
			} 
			if(mm<10){
				mm='0'+mm;
			} 
			return dd+'-'+mm+'-'+yyyy;
		}
	}
	
	shakeGuestCount(){
			this.props.updateSearchState({shake_animation:'shakeAnimation'})
		setTimeout(function(){
			this.props.updateSearchState({shake_animation:''})
		},500)
	}
	
	shakeUnitCount(){
		const this_el = this;
		this.props.updatePropertyState({shake_animation_unit:'shakeAnimation'})
		setTimeout(function(){
			this_el.props.updatePropertyState({shake_animation_unit:''})
		},500)
	}
	
	submitPropertyDataToApi(){
		
		const this_el = this;
		if(this.props.booking.checkin_date === 1){
			this.openDateRangeCalendar();
		} else if(this.props.booking.guest_count < 1 || this.props.booking.unit_count < 1){
			if(this.props.booking.guest_count < 1){
				this.shakeGuestCount();
			}
			if(this.props.booking.unit_count < 1){
				this.shakeUnitCount();
			}
			if ("vibrate" in navigator) {
				navigator.vibrate(500);
			}
		} else {
			if(this.props.booking.checkin_date ===1){
				this.getProperyPreviewApi(this.props.match.params.hashId);       
			}else{
				this.getProperyPreviewApi(this.props.match.params.hashId + '?checkin=' + this.dateConvertor(this.props.booking.checkin_date) +  '&checkout=' + this.dateConvertor(this.props.booking.checkout_date) + '&guests=' + this.props.booking.guest_count+ '&units=' + this.props.booking.unit_count);       
				let urlParams = '?&checkin=' + this.dateConvertor(this.props.booking.checkin_date) + '&checkout=' + this.dateConvertor(this.props.booking.checkout_date) + '&guests=' + this.props.booking.guest_count+ '&units=' + this.props.booking.unit_count;
				this.props.history.replace('/property/'+ this.props.match.params.hashId + urlParams);
			}
			
			let flag = this.props.search.flag;
			flag.modal.select_guest = false;
			this.props.updateSearchState({'flag':flag})
			
			this.toPrepayment(this_el.props.property.property_hash_id);
		}
	}
	
	toPrepayment(data){
		this.props.updatePrepaymentState({
			property_images: this.props.property.property_images
		})
		let urlParams = '?&checkin=' + this.dateConvertor(this.props.booking.checkin_date) + '&checkout=' + this.dateConvertor(this.props.booking.checkout_date) + '&guests=' + this.props.booking.guest_count + '&units=' + this.props.booking.unit_count;
		this.props.history.push({
			pathname: '/prepayment/'+ data,
			search:urlParams
		});
	}
	
	getPricingCalendarDataApi(args){
		let this_el = this;
		getPricingCalendarDataApi(args)
		.then(function (response) {
			this_el.props.updatePropertyState({
				'calendar_prices':response.data.data.exception,
				'default_prices':response.data.data.default,
				calendarPriceLoaded:true,
			});
		})
		.catch(function (error) {
			console.log(error);
		});
	}
	
	goBack(){
		if (navigator.userAgent.match('CriOS')) {
			this.props.history.goBack();
		} else {
			window.history.go(-1);
			if (
				window.location.pathname === '/' ||
				(window.history.state && window.history.state.key)
			) {
				return;
			}
			if (this.timer) {
				clearTimeout(this.timer);
			}
			this.timer = setTimeout(() => {
				this.props.history.goBack();
			}, 100);
		}
	}
	
	toProperty(data, index, hash){
		if(data !== undefined){

			let property_flag = Object.assign({}, this.props.property.flag);
			property_flag.api.is_loading = false;
			this.props.updatePropertyState({
				'property_hash_id': data.property_hash_id,
				'property_images': data.property_images,
				'property_title': data.property_title,
				'title': data.title,
				'property_score': data.property_score,
				'host_image': data.host_image,
				'host_name': data.host_name,
				'flag': property_flag,
				dataLoaded: false,
				indexList: this.props.property.indexList.filter(x => x.hash === hash).length > 0
				?	this.props.property.indexList.map((x) => {
						if (x.hash === hash) {
							return { hash, index }
						} else {
							return x
						}
					})
				: [ ...this.props.property.indexList, { hash, index } ]
			})
			this.props.updateBookingDataState({
				'location': data.location.location_name,
				'lat':  data.location.latitude,
    			'lng': data.location.longitude,
			})
		}
		
		this.props.history.push({
			pathname: '/property/'+data.property_hash_id,
			search : this.props.location.search,
		});
		window.scroll(0,0);
	}

	setDataInStore(response){
		Object.assign(response, {pUrl:this.props.location.search});
		let currentData = [response];
		let storedData = JSON.parse(sessionStorage.getItem('storedPropertyList'));
		let finalArray = [];

		if (storedData !== null) {
			let result = storedData.find(obj => {
				return obj.data.data.property_hash_id === this.props.property.property_hash_id && obj.pUrl === this.props.location.search
			})
			if (result === undefined){
				finalArray = currentData.concat(storedData);
			}else {
				finalArray = storedData;
			}
		}else {
			finalArray = currentData;
		}

		if (finalArray.length > 5){
			finalArray.length = 5;
		}

		sessionStorage.setItem('storedPropertyList', JSON.stringify(finalArray));

	}


	openMaxNightsAlert(){
		this.refs.max_nights_alert_overlay.classList.add('true')
	}
	
	closeMaxNightsAlert(){
		this.refs.max_nights_alert_overlay.classList.remove('true')
	}
	
	openUnavailableDate(){
		this.refs.unavailable_date_overlay.classList.add('true')
	}
	
	closeUnavailableDate(){
		this.refs.unavailable_date_overlay.classList.remove('true')
	}
	
	openUnitAlert(){
		this.refs.unitAlert.classList.add('true')
	}
	
	closeUnitAlert(){
		this.refs.unitAlert.classList.remove('true')
	}
	
	reload(){
		this.getProperyPreviewApi(this.props.match.params.hashId + this.props.location.search);
	}
	
	modalRouting(hash){
		let flag = Object.assign({}, this.props.property.flag);
		addClassToHtml();
		switch(hash){
			case '#shareModal':
				flag.modal.shareModal = true;
				break;
			
			case '#propertyGalleryList':
				flag.modal.propertyGalleryList = true;
				flag.modal.gallerySlider = false;
				break;
			
			case '#gallerySlider':
				flag.modal.gallerySlider = true;
				flag.modal.shareModal = false;
				break;
						
			case '#allAmenitiesList':
				flag.modal.allAmenitiesList = true;
				break;
			
			case '#pricingCalendar':
				flag.modal.pricingCalendar = true;
				break;
			
			case '#mapFullView':
				flag.modal.mapFullView = true;
				break;
			
			case '#allReviewsList':
				flag.modal.allReviewsList = true;
				break;

			default:
				flag.modal.shareModal = false;
				flag.modal.propertyGalleryList = false;
				flag.modal.gallerySlider = false;
				flag.modal.allAmenitiesList = false;
				flag.modal.pricingCalendar = false;
				flag.modal.mapFullView = false;
				flag.modal.allReviewsList = false;
				removeClassToHtml();
		}
		this.props.updatePropertyState({ flag: flag });
	}
	
	// Modal open functions
	openShareModal(){
		window.location.hash = 'shareModal'
	}
	toReviews() {
		let flag = Object.assign({}, this.props.property.flag);
		flag.api.is_loading = true;
		this.props.updatePropertyState({
			reviews_list: this.props.property.reviews,
			reviewOffset: 0,
			flag:flag,

		})
		this.props.history.push('/reviews/' + this.props.match.params.hashId);

	}
	openErrorMessage_modal(){
		this.refs.errorMessage_modal.classList.add('true');
	}
	closeErrorMessage_modal(){
		this.refs.errorMessage_modal.classList.remove('true');
		this.props.history.replace('/')
	}
	
	componentDidMount () {
		let this_el = this
		// for sticky header
		window.onscroll = () => {
			let isTop;
			if (window.scrollY < 200) {
				if (!isTop) {
					let flag = Object.assign({}, this_el.props.property.flag);
					flag.is_top = true;
					
					// updating property state
					this_el.props.updatePropertyState({
						flag : flag
					})
					isTop = true;
				}
			} else {
				isTop = false;
				if (this.props.property.flag.is_top) {
					let flag = Object.assign({}, this_el.props.property.flag);
					flag.is_top = false;
					
					// updating property state
					this_el.props.updatePropertyState({
						flag : flag
					})
				}
			}
		};

		window.scroll(0, 0);

		this.props.updateProfileStateObj({
			profilePage:false
		})
		if (checkIfOffline()) {
			this.props.updateErrorState({
				flag: {
					network: true
				}
			})
		} else {
			this.props.updateErrorState({ flag: { network: false } });
		}

		const toDate = (dateStr) => {
			if (dateStr !== null) {
				var parts = dateStr.split("-");
				return new Date(parts[2], parts[1] - 1, parts[0]);
			} else {
				return 1;
			}
		}
		let flag = this.props.search.flag;
		flag.modal.date_range = false;
		flag.modal.select_guest = false;
		this.props.updateSearchState({
			flag: flag
		});


		// console.log('url',(parseInt(getURLParameter(this.props.location.search, 'guests'), 10)))
		if (!this.props.property.prepaymentModify) {
			this.props.updateBookingDataState({
				checkin_date: this.props.location.search.includes('checkin') ? toDate(getURLParameter(this.props.location.search, 'checkin')) : (1),
				checkout_date: this.props.location.search.includes('checkout') ? toDate(getURLParameter(this.props.location.search, 'checkout')) : (1),
				guest_count: this.props.location.search.includes('guests') ? (parseInt(getURLParameter(this.props.location.search, 'guests'), 10)) : (0),
			})
		}

		let storedData = JSON.parse(sessionStorage.getItem('storedPropertyList'));
		if (storedData !== null) {
			let result = storedData.find(obj => {
				return obj.data.data.property_hash_id === this_el.props.match.params.hashId && obj.pUrl === this.props.location.search;
			})
			if (result === undefined || this.props.property.prepaymentModify) {
				if (this.props.booking.checkin_date === 1) {
					this.getProperyPreviewApi(this.props.match.params.hashId);
				} else {
					this.getProperyPreviewApi(this.props.match.params.hashId + '?checkin=' + this.dateConvertor(this.props.booking.checkin_date) + '&checkout=' + this.dateConvertor(this.props.booking.checkout_date) + '&guests=' + this.props.booking.guest_count+ '&units=' + this.props.booking.unit_count);
				}
			} else {
				this.getProperyPreviewApiDataSuccess(result);
			}
		}else {
			if (this.props.booking.checkin_date === 1) {
				this.getProperyPreviewApi(this.props.match.params.hashId);
			} else {
				this.getProperyPreviewApi(this.props.match.params.hashId + '?checkin=' + this.dateConvertor(this.props.booking.checkin_date) + '&checkout=' + this.dateConvertor(this.props.booking.checkout_date) + '&guests=' + this.props.booking.guest_count+ '&units=' + this.props.booking.unit_count);
			}
		}
	}

	componentDidUpdate(prevProps, prevState) {
		if(!this.props.location.pathname.includes('galleryList') && !this.props.location.pathname.includes('gallerySlider')){
			removeClassToBody();
		}
		const this_el = this;
		let storedData = JSON.parse(sessionStorage.getItem('storedPropertyList'));
		if (prevProps.match.params.hashId !== this.props.match.params.hashId){
			if(storedData !== null){
				let result = storedData.find(obj => {
					return obj.data.data.property_hash_id === this_el.props.match.params.hashId && obj.pUrl === this.props.location.search;
				})

				if (result === undefined) {
					this.getProperyPreviewApi(this.props.match.params.hashId + this.props.location.search);
				} else {
					this.getProperyPreviewApiDataSuccess(result);
				}

			}else {
				let property_flag = Object.assign({}, this.props.property.flag);
				property_flag.api.is_loading = false;
				this.props.updatePropertyState({
					'flag': property_flag,
				})

				this.getProperyPreviewApi(this.props.match.params.hashId + this.props.location.search);

			}
			
		}
		if (checkIfOffline()) {
			setTimeout(_ => {
				this.props.updateErrorState({
					flag: {
						network: true
					}
				})
			}, 100)
		}
	}
	
	render() {
		const this_el=this;
		const meta = {
			title: this.props.property.meta_title,
			description: this.props.property.meta_desc,
			canonical: this.props.property.canonical_url,
			meta: {
				charset: 'utf-8',
				property:{
					'og:site_name':'Guesthouser',
					'og:title':this.props.property.meta_title,
					'og:url':this.props.property.canonical_url,
					'og:type':'mobile web',
					'og:description':this.props.property.meta_desc,
					'og:image':this.props.property.meta_image,
				},
			}
		};
		let headerClass;
		if (this.props.error.flag.network){
			headerClass = 'property-preview-header';
		} else if (this.props.error.flag.server){
			headerClass = 'property-preview-header active';
		} else {
			if (this_el.props.property.flag.is_top){
				headerClass = 'property-preview-header';
			}else {
				headerClass = 'property-preview-header active';

			}
		}

		return (
			<DocumentMeta {...meta}>
				<div className="property-preview">
					<div className={headerClass}>
						<div className="row clearfix">
							<div className="col-2">
								<button className="back-btn" onClick={this.goBack}><i></i></button>
							</div>
							<div className="col-8">
								<div className="header-title">{this.props.property.title}</div>
							</div>
							<div className="col-2">
								<button className="share-btn pull-right" onClick={this.openShareModal}><i></i></button>
							</div>
						</div>
					</div>
					<OfflineStatus
						offline={checkIfOffline()}
						bottom={!this.props.property.dataLoaded}
					/>
					{!this.props.error.flag.server ?
						<div>
							<PropertyDetail/>
							{(!this.props.error.flag.network || this.props.property.dataLoaded || true) &&
								<div>
									<SpaceList/>
									{this.props.property.about !== '' &&
									<AboutProperty/>
									}
									<AmenitiesList/>
									<div className="share-option clearfix">
										<div className="col-6">
											<span onClick={this.openShareModal}><i className="share-icon"></i> Share</span>
										</div>
										<div className="col-6" id={this.props.property.property_hash_id}>
											<span ref={(ele) => this.wishListRef(ele)} data-id={this.props.property.property_hash_id} onClick={this.toggleActiveClass} className={this.props.property.whishlist === 1 ? "wishlist-icon active" : "wishlist-icon"}>Wishlist</span>
										</div>
									</div>
								
									{this.props.property.tags.length > 0 &&
										<PropertyTags />
									}
								
									{this.props.property.flag.api.is_loading &&
										<div>
											<PropertyPhotos history={this.props.history}/>
											<PricingInfo />
											<PropertyPolicy />
											<PaymentOption />
										</div>
									}
										
									{this.props.property.reviews.length > 0 &&
										<section className="clearfix">
											<h3>Reviews</h3>
											<PropertyReviews />
											<span className="read-more" onClick={this.toReviews}>Read all
												{this.props.property.review_count > 1 &&
													' (' + this.props.property.review_count + ' reviews)'
												}
											</span>
										</section>
									}

								
									{this.props.property.flag.api.is_loading &&
										<div>
											<PropertyLocation />
											<SimilarListing  toProperty={this.toProperty}/>
											<div>
												{(getObjectVal(this_el.props.property, 'left_div') && this_el.props.property.left_div.title.length > 0 && getObjectVal(this_el.props.property, 'right_div') && this_el.props.property.right_div.title.length > 0)
													?
													<div className="row extra-feature">
														<div className="col-6 pull-left">
															<h4>{this.props.property.left_div.title}</h4>
															<p>{this.props.property.left_div.text}</p>
														</div>
				
														<div className="col-6 pull-left">
															<h4>{this.props.property.right_div.title}</h4>
															<p>{this.props.property.right_div.text}</p>
														</div>
													</div>
													:
													<div>
														{(getObjectVal(this_el.props.property, 'left_div') && this_el.props.property.left_div.title.length > 0) &&
															<div className="row extra-feature  full-length">
																<div className="col-12 pull-left">
																	<h4>{this.props.property.left_div.title}</h4>
																	<p>{this.props.property.left_div.text}</p>
																</div>
															</div>
														}
				
														{(getObjectVal(this_el.props.property, 'right_div') && this_el.props.property.right_div.title.length > 0) &&
															<div className="row extra-feature  full-length">
																<div className="col-12 pull-left">
																	<h4>{this.props.property.right_div.title}</h4>
																	<p>{this.props.property.right_div.text}</p>
																</div>
															</div>
														}
													</div>
												}
											</div>
										</div>
									}
								</div>
							}
						
							{
								this.props.property.calendarPriceLoaded &&	
								<Route path='/property/pricingCalendar' render={props => <PricingCalendar />} />
							}

							<PropertyBottomOption
								toPrepayment={this.toPrepayment} />

							<SelectGuestsModal 			
								submitPropertyDataToApi={this.submitPropertyDataToApi}
							/>
														
							<DateRangeCalendar 
								openMaxNightsAlert={this.openMaxNightsAlert}
								openUnitAlert = {this.openUnitAlert}
								openUnavailableDate={this.openUnavailableDate}
							/>

							
						
							<Route path='/property/galleryList' render={props => <PropertyGalleryList dataLoaded={this.props.property.dataLoaded} />} />
								
							{
								this.props.property.flag.api.is_loading &&
								<div>
									<Route path='/property/about' render={props => <AboutPropertyModal />} />

									<Route path='/property/amenities' render={props => <AllAmenitiesList />} />

									<Route path='/property/MapFullView' render={props => <MapFullView/>} />
											
									<AllReviewsList/>

									<Route path='/property/gallerySlider' render={props => <GallerySlider />} />
									
									<Share />
								</div>
							}
										
								
							<div ref="max_nights_alert_overlay" className='max_nights_alert_overlay'>
								<div className="max_nights_alert">
									<div className="modal-body text-center">
										<h2 className="text">Non-Availability</h2>
										<div className='description'>
										<p>Minimum stay: {this_el.props.property.min_nights} night</p>
										<p>Maximum stay: {this_el.props.property.max_nights} nights</p>
									</div>
										<button  className="btn" onClick={this.closeMaxNightsAlert}>Done</button>
									</div>
								</div>
							</div>
							
							<div ref="unavailable_date_overlay" className='unavailable_date_overlay'>
								<div className="max_nights_alert">
								<div className="modal-body text-center">
									<h2 className="text">Non-Availability</h2>
									<div className='description'>
									<p>Date selection is invalid</p>
								</div>
									<button  className="btn" onClick={this.closeUnavailableDate}> Done</button>
								</div>
								</div>
							</div>
								
							<div ref="unitAlert" className='unitAlert'>
								<div className="max_nights_alert">
									<div className="modal-body text-center">
										<h2 className="text">Non-Availability</h2>
									<div className='description'>
										<p>Only {this_el.props.property.available_units} {this_el.props.property.available_units > 1 ? 'units are' :'unit is'} available on selected date</p>
									</div>
										<button  className="btn" onClick={this.closeUnitAlert}>Done</button>
									</div>
								</div>
							</div>
							<div ref="errorMessage_modal" className='unavailable_date_overlay'>
								<div className="max_nights_alert">
									<div className="modal-body text-center">
										<h2 className="text">Error!</h2>
										<div className='description'>
											<p>{this_el.props.error.message.toString()}</p>
										</div>
										<button className="btn" onClick={this.closeErrorMessage_modal}> Done</button>
									</div>
								</div>
							</div>
							<img src={noInternet} className='hidden' alt='no internet'/>
						</div>	
					:
						<div className="info-window">
							<div className="window-container">
								<div className="imgb">
									<div className="img" style={{ backgroundImage: "url(" + serverError + ")" }} />
								</div>
								<div className="txtb">
									<p>Sorry, our servers are busy. Please try again in a bit.</p>
								</div>
								<button className="retry" onClick={this.reload}>Retry</button>
							</div>
						</div>
					}
					<div ref="errorMessage_modal" className='unavailable_date_overlay'>
						<div className="max_nights_alert">
							<div className="modal-body text-center">
								<h2 className="text">Error!</h2>
								<div className='description'>
								<p>{this_el.props.error.message.toString()}</p>
								</div>
								<button className="btn" onClick={this.closeErrorMessage_modal}> Done</button>
							</div>
						</div>
					</div>
				</div>
			</DocumentMeta>
		);
		
	}
}


const mapStateToProps = (store) => {
	return{
		search: store.search,
		property: store.property,
		booking: store.booking,
		tracker: store.tracker.tracker,
		profilePage :store.userProfile.profilePage,
		error : store.error
	}
}
const mapDispatchToProps = {
	updateProfileStateObj,
	updatePropertyState,
	updateBookingDataState,
	updateSearchState,
	updateErrorState,
	updatePrepaymentState,
	updateHomeState,
	updateCollectionState
}
export default connect(mapStateToProps, mapDispatchToProps)(PropertyPreview);