

/* APIs */
// get trip page data
let getTripApi = function (data, args)
{
	return data.AXIOS.get(data.BASE_URL_V+'/booking/trip' + args.past,{
		responseType: 'json',
		params: {
			 offset: args.offset,
			 total: args.total,
		},
		headers: {
			'Accept': 'application/json',
			'Content-Type': 'application/x-www-form-urlencoded',
			'device-unique-id': data.DEVICE_UNIQUE_ID,
			'Authorization' :'Bearer ' + data.ACCESS_TOKEN,
		}
	});

}

exports.getTripApi = getTripApi;