import React from 'react'

class OfflineStatus extends React.Component {
    state = {
        show: false
    }

    // Cleanup if status changed
    componentWillReceiveProps (nextProps) {
        if (this.props.offline !== nextProps.offline) {
            if (!this.props.dontClear) {
                if (nextProps.offline) {
                    this.showToast()
                } else {
                    clearTimeout(this.timeout)
                    this.setState({ show: false })
                }
            } else {
                if (nextProps.offline && !this.timeout) {
                    this.showToast()
                }
            }
        }
    }

    componentDidMount () {
        if (this.props.offline && !this.props.dontClear) {
            this.showToast()
        }
    }

    componentWillUnmount () {
        clearTimeout(this.timeout)
    }

    showToast = () => {
        setTimeout(_ => {
            this.setState({
                show: true
            }, _ => {
                this.timeout = setTimeout(_ => {
                    console.log('False')
                    this.setState({ show: false })
                    this.timeout = undefined
                }, 5000)
            })
        }, 200)
    }

    render () {
        return (
            <div className={`responseAPI ${this.state.show && 'offline true'}`} style={{  backgroundColor: '#fddf49', bottom : this.props.bottom && 0, zIndex: this.props.highest && 999999999999 }}>
                <div>
                    <div className='error' style={{ backgroundColor: '#fddf49', color: '#7b7b7b' }} >
                        <span>You are offline</span>
                    </div>
                </div>
            </div>
        )
    }
}


export default OfflineStatus