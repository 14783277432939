import React from 'react';
import Modal from 'react-modal';
import { connect } from "react-redux";

class Promocode extends React.Component {
	constructor(props) {
		super(props);
		this.state = {
			'promocode':props.prepayment.promocode,
		}
		this.savepromocode = this.savepromocode.bind(this);

	}

	promocodeChange(e){
		this.setState({
			'promocode': (e.target.value).trim()
		}, function(){
			if(this.state.promocode.length > 0){
				this.refs.applyBtn.classList.remove('disable');
			} else {
				this.refs.applyBtn.classList.add('disable');
			}
		});
	}

	savepromocode(){
		this.props.savepromocode((this.state.promocode));
	}

	render() {
		return (
			<div>
				<Modal
					isOpen={this.props.prepayment.flag.modal.promocodes}
					onRequestClose={this.props.promocodeClose}
					contentLabel="Modal"
						overlayClassName={{
						base: 'overlay-modal overlay-popup',
						afterOpen: '',
						beforeClose: ''
					}}
					className={{
						base: 'content-modal',
						afterOpen: 'editProfile promo-code',
						beforeClose: ''
					}}
				>
					<div className="modal-body text-center">
							<span className="back-btn" onClick={this.props.promocodeClose}></span>
							<div className="verify-text1">Please enter your promo code</div>
							<input className="coupon_input" placeholder="Enter Coupon Code" onChange={ (e) => this.promocodeChange(e)}/>
							<button className="link green disable" ref='applyBtn' onClick={this.savepromocode}>Apply</button>
					</div>
				</Modal>
			</div>
		);
	}
}


const mapStateToPtops = (store) => {
	return {
		prepayment: store.prepayment
	}
}
export default connect(mapStateToPtops,null)(Promocode);