import React from 'react';
import Modal from 'react-modal';
class CanceledSuccess extends React.Component {
	constructor(props) {
	super(props);
	this.state = {
		
	}
}
	render() {
	return (
		<div>
		<Modal
			isOpen={this.props.canceledSuccessOpen}
			onAfterOpen={this.afterOpenModal}
			onRequestClose={this.props.canceledSuccessClose}
			contentLabel="Modal"
				overlayClassName={{
				base: 'overlay-modal overlay-popup',
				afterOpen: '',
				beforeClose: ''
				}}
			className={{
				base: 'content-modal',
				afterOpen: 'editProfile request-modal',
				beforeClose: ''
				}}
			>
			<div className="modal-body text-center">
				<span className="back-btn" onClick={this.props.canceledSuccessClose}></span>
				<div className="text-cacel">{this.props.cancelMessage}</div>
				<button onClick={this.props.canceledSuccessClose} className="link green">Done</button>
			</div>
		</Modal>
		</div>
	);
	}
}

export default CanceledSuccess;