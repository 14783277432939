import React from 'react';
import Modal from 'react-modal';
import { connect } from "react-redux";
import { withRouter } from "react-router";
import { updatePrepaymentState } from '../../../actions/prepayment/actions';

class ForceCreateModal extends React.Component {
    constructor(props) {
        super(props);
        this.gotoRequest = this.gotoRequest.bind(this);
    }

    gotoRequest() {
        let flag = this.props.prepayment.flag;
        flag.modal.forceCreateModal = false;
        this.props.updatePrepaymentState({
            flag: flag
        })
        if (this.props.prepayment.booking_status > 0) {
            this.props.history.push('/trip/' + this.props.prepayment.request_id);
        } else {
            this.props.history.push('/request/' + this.props.prepayment.request_id);
        }
    }

    render() {
        return (
            <div>
                <Modal
                    isOpen={this.props.prepayment.flag.modal.forceCreateModal}
                    onRequestClose={this.props.forceCreateModalClose}
                    contentLabel="Modal"
                    overlayClassName={{
                        base: 'overlay-modal overlay-popup',
                        afterOpen: '',
                        beforeClose: ''
                    }}
                    className={{
                        base: 'content-modal',
                        afterOpen: 'editProfile request-modal',
                        beforeClose: ''
                    }}
                >
                    <div className="modal-body text-center">
                        <span className="back-btn" onClick={this.props.forceCreateModalClose}></span>
                        <h2 className="verify-text1">{this.props.prepayment.messageHeader}</h2>
                        <div className="text-cacel">{this.props.prepayment.errorMessage}</div>
                        <button className="link green-outline" onClick={this.gotoRequest}>See {this.props.prepayment.booking_status > 0 ? 'Trip' : 'Request'}</button>
                        <button className="link green" onClick={this.props.createRequestForcely}>Proceed</button>
                    </div>
                </Modal>
            </div>
        );
    }
}

const mapStateToPtops = (store) => {
    return {
        prepayment: store.prepayment
    }
}

const mapDispatchToProps = {
    updatePrepaymentState
}

export default connect(mapStateToPtops, mapDispatchToProps)(withRouter(ForceCreateModal));
