import React from "react";
import Swiper from "react-id-swiper";
import { connect } from "react-redux";
import { withRouter } from "react-router";

/**
 * Actions
 */
import { updatePropertyState } from "../../actions/property/actions";
import { updateCollectionState } from "../../actions/collection/actions";
import { updateBookingDataState } from "../../actions/booking-data/actions";
import { updateSingleCollectionState } from "../../actions/single-collection/actions";
import {
  trackGoToCollectionPage,
  trackGoToCollectionListPage
} from "./../../services/tracker/events";

import {
  hasAccessToken,
  getWishlistAddDataApi,
  getWishlistDeleteDataApi,
  tileRatingColor,
  saveUrlLogin
} from "../../services/index.js";
import {
  updateStoredPropertyWishlist,
  checkIfOffline
} from "../../services/helpers.js";
import OfflineStatus from "../Offlinestatus";

class Collection extends React.Component {
  constructor(props) {
    super(props);
    this.toggleActiveClass = this.toggleActiveClass.bind(this);
    this.toCollectionList = this.toCollectionList.bind(this);
    this.seeAll = this.seeAll.bind(this);
    this.toProperty = this.toProperty.bind(this);

    this.state = {
      offlineStatus: false
    };
  }

  changeOfflineStatus() {
    console.log("Offline");
    this.setState(
      {
        offlineStatus: true
      },
      () => {
        setTimeout(() => {
          this.setState({
            offlineStatus: false
          });
        }, 200);
      }
    );
  }

  /**
   * See All Collection
   */
  seeAll(data) {
    const this_el = this;
    let collectionsFlag = Object.assign({}, this_el.props.collection.flag);
    collectionsFlag.no_more_result = false;

    this_el.props
      .updateCollectionState({
        collections: this.props.home.collections,
        flag: collectionsFlag,
        offset: 0,
        dataLoaded: true
      })
      .then(() => {
        this.props.history.push({
          pathname: "/collections"
        });
      });
    trackGoToCollectionListPage(
      this_el.props.tracker,
      this_el.props.home.page,
      "collection_see_more_btn"
    );
  }

  toProperty(data, itemIndex, parentIndex) {
    const this_el = this;
    this_el.props.updateCollectionState({
      itemIndex: itemIndex,
      parentIndex: parentIndex
    });
    if (data !== undefined) {
      this_el.props.updatePropertyState({
        property_hash_id: data.property_hash_id,
        property_images: data.property_images,
        property_id: data.property_id,
        property_title: data.property_title,
        title: data.title,
        property_score: data.property_score,
        host_image: data.host_image,
        host_name: data.host_name,
        apiData: false,
        tags: data.property_tags,
        property_type: data.property_type_name
      });
      this_el.props.updateBookingDataState({
        location: data.location.location_name,
        lat: data.location.latitude,
        lng: data.location.longitude,
        checkin_date: 1,
        checkout_date: 1,
        guest_count: 0,
        unit_count: 0
      });
    }

    this_el.props.history.push({
      pathname: "/property/" + data.property_hash_id
    });
  }

  /**
   * To Collection list
   */
  toCollectionList(data, itemIndex, parentIndex) {
    const this_el = this;

    this_el.props.updateCollectionState({
      itemIndex: itemIndex,
      parentIndex: parentIndex
    });

    let single_collection = Object.assign({}, this_el.props.single_collection);
    single_collection.id = data.collection_id;
    single_collection.hash_id = data.collection_hash_id;
    single_collection.image = data.collection_image;
    single_collection.title = data.collection_title;
    single_collection.description = data.description;
    single_collection.properties = data.properties;
    single_collection.flag.no_more_result = false;

    this_el.props.updateSingleCollectionState(single_collection).then(() => {
      trackGoToCollectionPage(
        this_el.props.tracker,
        this_el.props.home.page,
        "collection_tile",
        data.collection_hash_id
      );

      this.props.history.push({
        pathname: "/collection/" + single_collection.hash_id
      });
    });
  }

  toggleActiveClass(event, itemIndex, parentIndex) {
    const this_el = this;
    event.preventDefault();
    event.stopPropagation();
    let collectionList = this.props.home.collections;
    if (hasAccessToken()) {
      if (!checkIfOffline()) {
        if (
          collectionList[event.target.dataset.index].properties[
            event.target.dataset.id
          ].is_liked_by_user === 1
        ) {
          collectionList[event.target.dataset.index].properties[
            event.target.dataset.id
          ].is_liked_by_user = 0;
          event.target.className = "wishlist-icon";
          this_el.getWishlistDeleteApi(event.target.parentNode.parentNode.id);
          updateStoredPropertyWishlist(
            event.target.parentNode.parentNode.id,
            0
          );
        } else {
          collectionList[event.target.dataset.index].properties[
            event.target.dataset.id
          ].is_liked_by_user = 1;
          event.target.className = "wishlist-icon active";
          this_el.getWishlistApi(event.target.parentNode.parentNode.id);
          updateStoredPropertyWishlist(
            event.target.parentNode.parentNode.id,
            1
          );
        }
      } else {
        this.changeOfflineStatus();
      }
    } else {
      this_el.props.updateCollectionState({
        itemIndex: itemIndex,
        parentIndex: parentIndex
      });

      saveUrlLogin();
      sessionStorage.setItem("hashId", event.target.parentNode.parentNode.id);
      this_el.props.history.push("/signup");
    }
  }
  imageLoaded(e) {
    let imgSrc = e.target.src;
    e.target.nextElementSibling.style.backgroundImage = "url(" + imgSrc + ")";
  }

  getWishlistApi(data) {
    getWishlistAddDataApi(data)
      .then(function(response) {
        // success
      })
      .catch(function(error) {
        // error
      });
  }

  getWishlistDeleteApi(data) {
    getWishlistDeleteDataApi(data)
      .then(function(response) {
        // success
      })
      .catch(function(error) {
        // error
      });
  }

  componentDidMount() {
    const this_el = this;
    if (hasAccessToken()) {
      let storedHashId = sessionStorage.getItem("hashId");
      if (
        storedHashId !== null &&
        typeof this_el.refs[storedHashId] !== "undefined"
      ) {
        this_el.getWishlistApi(storedHashId);
        this_el.refs[storedHashId].classList.add("active");
        sessionStorage.removeItem("hashId");
      }
    }
  }

  render() {
    const this_el = this;
    const params = {
      slidesPerView: 1.24,
      spaceBetween: 20,
      freeMode: true,
      addSlidesAfter: 1,
      lazy: true,
      touchMoveStopPropagation: false,
      autoHeight: true,
      slidesOffsetBefore: 15,
      slidesOffsetAfter: 15,
      initialSlide: 0,
      noSwiping: false,
      on: {
        slideChange: () => {
          this_el.props.updateCollectionState({
            itemIndex: 0,
            parentIndex: 0
          });
        }
      }
    };
    const ellipsis = {
      textOverflow: "ellipsis",
      overflow: "hidden",
      WebkitBoxOrient: "vertical"
    };
    return (
      <div>
        <OfflineStatus
          offline={checkIfOffline() && this_el.state.offlineStatus}
          dontClear
        />
        <div className="collection-inner-section">
          {/* <div className="heading">Explore our collections</div> */}
          {this.props.home.collections.map(function(item, index) {
            if (index === this_el.props.collection.parentIndex) {
              params.initialSlide = this_el.props.collection.itemIndex;
            } else {
              params.initialSlide = 0;
            }
            let propertie_length = item.properties.length;
            // if(index <= 1){
            return (
              <div key={index}>
                <div className="header-wraper">
                  <div className="col-10">
                    <span className="title" style={ellipsis}>
                      {item.collection_title}
                    </span>
                  </div>
                  {propertie_length > 5 && (
                    <div className="col-2 text-right">
                      <span
                        className="seeall"
                        onClick={() => this_el.toCollectionList(item, 0, index)}
                      >
                        See all
                      </span>
                    </div>
                  )}
                </div>
                {item.properties.length === 1
                  ? (params.noSwiping = true)
                  : (params.noSwiping = false)}
                <Swiper {...params} key={index}>
                  {/* <div className="mask" data-index={index} style={{backgroundImage:'url('+item.collection_image+')', height:this_el.props.collection.element_height}}>
											<div className="innermask"><div className="text">{item.collection_title}</div></div>
										</div> */}
                  {item.properties.map(function(itemchild, indexchild) {
                    if (indexchild < 5) {
                      return (
                        <div
                          className="collection-item-tile"
                          id={itemchild.property_hash_id}
                          key={indexchild}
                          data-pindex={index}
                          data-index={indexchild}
                          onClick={() =>
                            this_el.toProperty(
                              itemchild,
                              indexchild,
                              index,
                              "collection_tile"
                            )
                          }
                        >
                          <img
                            data-src={itemchild.property_images[0].image}
                            onLoad={e => this_el.imageLoaded(e)}
                            className="hidden swiper-lazy"
                            alt="property"
                          />
                          <div className="imgb">
                            <span
                              data-name="wishlist"
                              ref={itemchild.property_hash_id}
                              data-index={index}
                              data-id={indexchild}
                              className={
                                itemchild.is_liked_by_user === 1
                                  ? "wishlist-icon active"
                                  : "wishlist-icon"
                              }
                              onClick={e =>
                                this_el.toggleActiveClass(e, indexchild, index)
                              }
                            />
                          </div>

                          <div className="description">
                            <div className="row">
                              <div className="col-10">
                                <h3>
                                  {itemchild.property_type_name}{" "}
                                  <div className="dot-separation" />
                                  {itemchild.accomodation} Guests
                                </h3>
                                <span className="location">
                                  {itemchild.location.location_name}
                                </span>
                                <span className="price">
                                  {itemchild.prices.price_after_discount}
                                </span>
                              </div>
                              <div className="col-2">
                                {itemchild.property_score > 0 && (
                                  <span
                                    className={
                                      "rating pull-right " +
                                      tileRatingColor(itemchild.property_score)
                                    }
                                  >
                                    {itemchild.property_score}
                                  </span>
                                )}
                              </div>
                            </div>
                          </div>
                        </div>
                      );
                    } else {
                      return null;
                    }
                  })}
                  {propertie_length > 5 && (
                    <div
                      className="collection-item-tile see-all-tile"
                      data-index={index}
                      onClick={() => this_el.toCollectionList(item, 5, index)}
                    >
                      <span>See all ></span>
                    </div>
                  )}
                </Swiper>
              </div>
            );
            // }
          })}
        </div>
        <div className="collection-btn" onClick={() => this_el.seeAll()}>
          See more collections
        </div>
      </div>
    );
  }
}

const mapStateToProps = store => {
  return {
    single_collection: store.single_collection,
    collection: store.collection,
    tracker: store.tracker.tracker,
    home: store.home
  };
};

const mapDispatchToProps = {
  updateSingleCollectionState,
  updateCollectionState,
  updatePropertyState,
  updateBookingDataState
};
export default connect(
  mapStateToProps,
  mapDispatchToProps
)(withRouter(Collection));
