

/* APIs */
// get signup data
let getDeactivateAccountApi = function (data, args)
{   
    return data.AXIOS.put(data.BASE_URL_V+'/user/delete', data.QS.stringify({
            password:btoa(args.password),
        }), {
            headers: {
                'Accept': 'application/json',
                'Content-Type': 'application/x-www-form-urlencoded',
                'device-unique-id': data.DEVICE_UNIQUE_ID,
                'Authorization' :'Bearer ' + data.ACCESS_TOKEN,
            }
        }
    );
}

exports.getDeactivateAccountApi = getDeactivateAccountApi;