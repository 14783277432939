import {combineReducers} from 'redux';
import homeReducer from './home-reducer';
import loginEmailReducer from './login-email-reducer';
import signupEmailReducer from './signup-reducer.js';
import otpReducer from './otp-reducer.js';
import profileReducer from './profile-reducer';
import settingReducer from './setting-reducer';
import collectionReducer from './collection-reducer';
import singleCollectionReducer from './single-collection-reducer';
import requestReducer from './request-reducer';
import tripReducer from './trip-reducer';
import bookingDataReducer from './booking-data-reducer';
import searchReducer from './search-reducer';
import errorReducer from './error-reducer';
import propertyReducer from './property-reducer';
import trackerReducer from './tracker-reducer';
import walletReducer from './wallet-reducer';
import wishlistReducer from './wishlist-reducer';
import prepaymentReducer from './prepayment-reducer';



const rootReducer = combineReducers({
    'home' : homeReducer,
    'loginEmail':loginEmailReducer,
    'signupEmail':signupEmailReducer,
    'otp':otpReducer,
    'userProfile':profileReducer,
    'UserChangeData':settingReducer,
    'collection':collectionReducer,
    'single_collection': singleCollectionReducer,
    'request': requestReducer,
    'tripData': tripReducer,
    'booking': bookingDataReducer,
    'search': searchReducer,
    'property': propertyReducer,
    'error': errorReducer,
    'wallet' : walletReducer,
    'wishlist': wishlistReducer,
    'tracker': trackerReducer,
    'prepayment': prepaymentReducer
});

export default rootReducer;