import React from 'react';
class ResponseMessage extends React.Component {

    constructor(props){
        super(props);
        this.state={
            show:true,
        }
    }
    componentWillReceiveProps(nextProps){
        let this_el= this;
        if(nextProps.responseMessage){
            this_el.setState({
                show:true,
            });
            setTimeout(function(){
                this_el.setState({
                    show:false,
                });
                nextProps.resetMessages();
            },5000)
        }
    }
    
    render() {
        if(this.state.show){
            return (
                <div>
                    <div className={this.props.responseStatus === false ? 'error' : 'success'}>
                        <span>{this.props.responseMessage}</span>
                    </div>
                </div>
            );
        }
        else{
            return(
                <div></div>
            );
        }
    }
}

export default ResponseMessage;