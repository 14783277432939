import React from 'react';
import {tileRatingColor} from '../../services/index.js'
import {trackError} from '../../services/tracker/events.js';
import { connect } from 'react-redux';
import {withRouter} from 'react-router';
import { updateProfileStateObj} from '../../actions/profile/actions';
import { checkIfOffline } from '../../services/helpers.js';
import OfflineStatus from '../Offlinestatus/index.js';
class AllReviewsList extends React.Component {
	constructor(props){
		super(props);
		this.goBack = this.goBack.bind(this);
	}
    decimaltrim(data){
        return Number.parseFloat(data).toFixed(1);
    }

    componentDidMount(){
    	let offset = sessionStorage.getItem('allReviewScroll');
    	const body = this.refs.modalBody;
    	if(this.props.allReviewsList){
	    	if(typeof body.scroll === "function"){
	    		body.scroll(0,offset);
	    	}else{
	    		//should nt be happening ,so loggin for a while
	    		trackError(new Error("body scroll not found, check console"));
	    	}
	    	sessionStorage.removeItem('allReviewScroll');
    	}
	}
	
	toUserProfile(data) {
		let urlParams = '?host_id=' + data.traveller_id;

		this.props.history.push({
			pathname: '/user/profile/',
			search: urlParams,
		});
		this.props.updateProfileStateObj({
			profile_image:data.traveller_image,
			first_name:data.traveller_name,
			is_host:1

		})
		let offsetreview = document.getElementById("filterModal").scrollTop;
		sessionStorage.setItem('allReviewScroll', offsetreview);
	}


	goBack(){
		this.props.history.goBack();
	}

	render(){
		const this_el = this;
		return(
			<div className={"overlay-modal review-modal-overlay " + this.props.property.flag.modal.allReviewsList}>
				<OfflineStatus offline={checkIfOffline()} bottom />
					<div className="content-modal full-screen-modal review-modal">
						<div className="filter-modal" >
							<div className="modal-header ">
								<div className="close-btn header-btn" onClick={this.goBack}> </div>
								<h2 className="text-center">Traveller reviews</h2>
								<div className="transparent-gredient"></div>
							</div>
							<div className="modal-body" id='filterModal' ref="modalBody">
								<div className="property-reviews" >
									<ul>
									 {(x => x.map(function(item, index){
										if(index < 10){
											return(
												<li key={index}>
													<div className="review-header clearfix">
														<div className="col-9 pull-left" onClick={(e) => this_el.toUserProfile(item)}>
															<span className="user-pic" style={{backgroundImage:'url('+item.traveller_image+')'}}></span>
															<span className="user-name">{item.traveller_name} <span>{item.review_date}</span></span>
														</div>
														<div className="col-3 pull-left">
															<span className={'rating ' + tileRatingColor(item.property_rating)}>{this_el.decimaltrim(item.property_rating)}</span>
														</div>
													</div>
													<div className="review-content">
														<p>{item.comment}</p>
													</div>
													{item.nights > 0 &&
													<span className='stay-duration'>- Stayed for {item.nights} {item.nights > 1 ? 'nights' : 'night'}</span>
													}
												</li>
											)
											}else {
												return false
											}
										}))(checkIfOffline() ? this.props.property.reviews : this.props.property.reviews_list)}
									</ul>
								</div>
							</div>
						</div>
					</div>

			</div>
		)
	}
}


const mapStateToProps = (store) => {
	return {
		property: store.property
	}
}
const mapDispatchToProps = {
	updateProfileStateObj
}

export default connect(mapStateToProps, mapDispatchToProps)(withRouter(AllReviewsList));