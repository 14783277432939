import React from 'react';
import {Tab, Tabs, TabList, TabPanel} from 'react-tabs';

class Tab1 extends React.Component {
	constructor(props){
		super(props);
		this.state = {
			walletData:props.walletData,
			isTop:true,
		}
	}
	
	componentDidMount(){
		sessionStorage.removeItem('tripToWallet');
	}

	render() {
		let tripToWallet = sessionStorage.getItem('tripToWallet');
		let tran_length;
		if(tripToWallet !== null){
			tran_length = 0
		} else {
			tran_length = this.state.walletData.transactions.length;
		}
		 
		const this_el = this;
		let walletData = this.state.walletData;
		return (
			<div>
			<Tabs onSelect={this.handleSelected} selectedIndex={this.index} defaultIndex={tran_length > 0 ? 0 : 1}>
				<TabList className="tab-menu">
					<Tab>Transactions</Tab>
					<Tab>Earn more</Tab>
				</TabList>              
				<TabPanel className={walletData.transactions.length > 0 ? (this.props.scrollAble ? "Transaction" : "Transaction scrollAble"):("Transaction no-transaction")}>
					<div className='background-color'>
					{walletData.transactions.map(function(item, index){
						return(
							<div className="row" key={index}>
								<div>
									<div className="col-7">
										<div className="wrap">
											<span className={item.type === 'OUT' ? 'icon paid':'icon unpaid'}></span>
											<span className="text left-side">{item.created_on} <span>{item.description}</span> <span>{item.booking_id && 'booking id: ' + item.booking_id}</span></span>
										</div>
									</div>
									<div className="col-5">
										<div className="wrap">
											<span className="pull-right text text-right">{item.type === 'OUT' && '-'} {item.amount} <span>{item.expire_on && 'Expires '+item.expire_on}</span></span>
										</div>
									</div>
								</div>
							
							</div>
						)
					})}
					</div>
					{walletData.transactions.length < 1 &&
						<div className="default-text">You haven't made any transaction yet</div>
					}
				</TabPanel>

				<TabPanel className={this.state.isTop ? "earn" : "earn scrollAble"}>
					<div className='background-color'>
					{walletData.earn_more.map(function(item, index) {
						return(
							<div className="row" key={index} onClick={item.event === 1 ? this_el.props.referEarnOpen : this_el.props.openTrips}>
								<div className="col-12">
									<div className="wrap">
										<span className="icon" style={{'background': 'url('+ item.image +')'}}></span>
										<span className="text">{item.title}<span>{item.description}</span></span>
										<span className="icons arrow pull-right"></span>
									</div>
								</div>
							</div>
						)
					})}
					</div>
				</TabPanel>
			</Tabs>
			</div>
		);
	}
}

export default Tab1;