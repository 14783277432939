import React from "react";
import GoogleMapReact from "google-map-react";
import mapPin from "../../static/img/mapPin.svg";
import { connect } from "react-redux";
import { withRouter } from "react-router";
import { getPropertyPreviewDataApi } from "../../services/index.js";

const AnyReactComponent = () => (
  <div
    style={{
      backgroundImage: "url(" + mapPin + ")",
      backgroundPosition: "center center",
      backgroundRepeat: "no-repeat",
      backgroundColor: "rgba(0,0,0,0.25)",
      width: "60px",
      height: "60px",
      display: "inline-flex",
      borderRadius: "100%",
      transform: "translate(-50%, -50%)",
      zIndex: "999999"
    }}
  />
);

class MapFullView extends React.Component {
  constructor(props) {
    super(props);
    console.log(props);
    this.state = {
      center: {
        lat: props.latitude.length > 0 ? parseFloat(props.latitude) : 15.60137,
        lng: props.longitude.length > 0 ? parseFloat(props.longitude) : 73.73454
      },
      zoom: 8,
      title: props.title || "",
      apihit: props.latitude.length > 0 ? true : false
    };

    this.goBack = this.goBack.bind(this);
  }

  componentDidMount() {
    if (!this.state.apihit) {
      getPropertyPreviewDataApi(this.props.match.params.hashId).then(
        response => {
          this.setState(
            {
              center: {
                lat: parseFloat(response.data.data.location.latitude),
                lng: parseFloat(response.data.data.location.longitude)
              },
              title: response.data.data.title,
              apihit: true
            },
            () => {
              console.log("after", this.state);
            }
          );
        }
      );
    }
  }

  goBack() {
    this.props.history.goBack();
  }

  render() {
    return (
      <div className="map-full-view">
        <header className="header active">
          <div className="row">
            <div className="col-12 text-left">
              <span
                className="cross-icon-white"
                onClick={() => this.props.history.goBack()}
              />
              <span className="middle-text text-center">
                {this.state.title}
              </span>
            </div>
          </div>
        </header>
        <div class="map-container">
          {this.state.apihit && (
            <GoogleMapReact
              bootstrapURLKeys={{ key: "AIzaSyDukEAc7oyPDkbs6fRnzTGE_iJbNvZz0PM" }}
              defaultCenter={this.state.center}
              defaultZoom={this.state.zoom}
              options={() => ({ gestureHandling: "greedy" })}
            >
              <AnyReactComponent
                lat={this.state.center.lat}
                lng={this.state.center.lng}
              />
            </GoogleMapReact>
          )}
        </div>
      </div>
    );
  }
}

const mapStateToProps = store => {
  return {
    property: store.property,
    latitude: store.booking.lat,
    longitude: store.booking.lng,
    title: store.booking.location
  };
};

export default connect(
  mapStateToProps,
  null
)(withRouter(MapFullView));
