

/* APIs */
// get home page data
let getCollectionPageDataApi = function (data, args)
{

    let headers = {
        'Accept': 'application/json',
        'Content-Type': 'application/x-www-form-urlencoded',
        'device-unique-id': data.DEVICE_UNIQUE_ID,
    }

    if(data.ACCESS_TOKEN){
        headers.Authorization = 'Bearer ' + data.ACCESS_TOKEN;
    }
    
    return data.AXIOS.get(data.BASE_URL_V+'/home/collections', {
        responseType: 'json',
        params: {
            offset: args.offset,
            total:5,
        },
        headers: headers
    });
}

exports.getCollectionPageDataApi = getCollectionPageDataApi;