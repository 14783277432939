import React from 'react';
import GoogleMapReact from 'google-map-react';
import mapPin from "../../static/img/mapPin.svg";
import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';
import { addClassToBody } from "../../services";

const AnyReactComponent = () => (
	<div style={{
		backgroundImage:'url('+mapPin+')',
		backgroundPosition:'center center',
		backgroundRepeat:'no-repeat',
		backgroundColor: 'rgba(0,0,0,0.25)',
		width:'60px',
		height:'60px',
		display: 'inline-flex',
		borderRadius: '100%',
		transform: 'translate(-50%, -50%)',
		zIndex:'999999'
		}}>
	</div>
);
class PropertyLocation extends React.Component{
	constructor(props){
		super(props);
		this.state = {
			defaultProps:{
			    center: {
			      lat: parseFloat(props.booking.lat),
			      lng: parseFloat(props.booking.lng)
			    },
			    zoom: 8
			}
		}
		this.openMapFullView = this.openMapFullView.bind(this);
	}
	openMapFullView() {
		sessionStorage.setItem("scrollVal", Math.max(window.pageYOffset, document.documentElement.scrollTop, document.body.scrollTop));
		addClassToBody();
		this.props.history.push("/property/mapFullView/"+ this.props.property_hash_id);
	}


	render(){
		const this_el = this;
		if(this_el.props.apiData){
			return(
				<section className="property-location clearfix">
					<h3>Location</h3>
					<h4>{this.props.booking.location}</h4>
					<div className="map-container" onClick={this.openMapFullView}>
						<GoogleMapReact
							defaultCenter={this.state.defaultProps.center}
							defaultZoom={this.state.defaultProps.zoom}
							options={() => ({gestureHandling: 'none'})}
							>
							<AnyReactComponent
								lat={this.state.defaultProps.center.lat}
								lng={this.state.defaultProps.center.lng}/>
						</GoogleMapReact>
						<div className="listing-ID">Listing Id : {this.props.property_hash_id}</div>
					</div>
					{this.props.how_to_reach.length > 0 &&
					<div>
						<h3>How to reach?</h3>
						<div className="space-amenities-list clearfix">
							<ul>
							{this.props.how_to_reach.map(function(item, index){
										if (item.value.length > 0) {
											return (
												<li key={index}><i className={item.key}></i><span>{item.value} </span></li>
											)
										}
										return null;
								})}
							</ul>
						</div>
					</div>
					}
				</section>
			);
			}
			else{
				return (<div></div>);
			}
		}
}


const mapStateToProps = (store) => {
	return {
		booking : store.booking,
		property_hash_id : store.property.property_hash_id,
		how_to_reach : store.property.how_to_reach,
		apiData : store.property.flag.api.is_loading

	}
}
export default connect(mapStateToProps,null)(withRouter(PropertyLocation));