import React from 'react';
import Modal from 'react-modal';
import {withRouter} from 'react-router';
import LazyLoad from 'react-lazyload';
import {connect} from 'react-redux';
import { updateHomeState } from "./../../../../actions/home/actions";


class TrendingCitiesModal extends React.Component {


	constructor(props) {
		super(props);
		this.closeModal = this.closeModal.bind(this);
	}
	
	closeModal(){
		const this_el = this;
		this_el.props.history.goBack();
	}

	componentDidMount () {
			if (this.props.home.trendingScrollState.length > 0) {
				setTimeout(() => {
					if (this.containerRef) {
						this.containerRef.scrollTop = this.props.home.trendingScrollState[0]
					}
				})
			}
	}

	render() {
		const this_el = this;
		return (
			<div>
				<Modal
					isOpen={this_el.props.home.flag.modal.trending_cities}
					onRequestClose={this_el.closeModal}
					contentLabel="Modal"

					overlayClassName={{
						base: 'overlay-modal',
						afterOpen: '',
						beforeClose: ''
					}}
					className={{
						base: 'content-modal',
						afterOpen: 'trending-cities full-screen-modal',
						beforeClose: ''
					}}
				>

					<div className="modal-header">
						<h2>Trending cities</h2>
						<div className="header-btn close-btn" onClick={this_el.closeModal}> </div>
						<div className="transparent-gredient"></div>
					</div>
					<div className="modal-body" ref={r => {
							this.containerRef = r;
							this.props.getRef(r)
						}}>
						<div className="trending-section">
							<div className="trending-inner-section center">
								<div className="row">
									{this_el.props.home.popular_cities.map(function(item, index) {
										return (
											<div className="col-4 text-center" key={index}>
												<div className="trending-item" data-index={index} onClick={(e) => this_el.props.trandingSearchSubmit(e)}>
													<LazyLoad height={100}>
														<div className="imgb" style={{backgroundImage: 'url('+item.property_images[0]+')',}}></div>
													</LazyLoad>
													<h3>{item.title} <span>{item.home_count}</span></h3>
												</div>
											</div>
										)
									})}
								</div>
							</div>
						</div>
					</div>
				</Modal>
			</div>
		);
	}
}



const mapStateToProps = store => {
	return {
		home: store.home
	};
};

const mapDispatchToProps = {
	updateHomeState
};

export default connect(
	mapStateToProps,
	mapDispatchToProps
)(withRouter(TrendingCitiesModal));