

/* APIs */
// get popular cities list data
let getPopularSearchApi = function (data, args)
{
    return data.AXIOS.get(data.BASE_URL_V+'/search/popular', {
        responseType: 'json',
        params: {
        },
        headers: {
            'Accept': 'application/json',
            'Content-Type': 'application/x-www-form-urlencoded',
            'device-unique-id': data.DEVICE_UNIQUE_ID,
        }
    });

}

exports.getPopularSearchApi = getPopularSearchApi;