import React from 'react';
import Modal from 'react-modal';
import {hasAccessToken, getCurrencyListApi} from '../../../services/index.js';
import {connect} from 'react-redux';
import { updateUserStateObj} from '../../../actions/setting/actions';


class Currency extends React.Component {
	constructor(props){
		super(props);
        this.saveCurrency=this.saveCurrency.bind(this)
        this.getCurrencyListData=this.getCurrencyListData.bind(this)
        this.getCurrencyListDataSuccess=this.getCurrencyListDataSuccess.bind(this)
        this.getCurrencyListDataError=this.getCurrencyListDataError.bind(this)
	}
	saveCurrency(e){
		this.props.saveCurrency(e.target.value)
	}

	getCurrencyListDataSuccess(response) {
		this.props.updateUserStateObj({
			'selected_currency':response.data.data.selected_currency,
			'currency_codes':response.data.data.currency_codes,
		});

	}

	getCurrencyListDataError(error) {
		console.log(error);
	}

	getCurrencyListData(){
		let this_el = this;
		getCurrencyListApi().then(function(response){ 
			// success
			this_el.getCurrencyListDataSuccess(response);
		})
		.catch(function(error) {
			// error
			this_el.getCurrencyListDataError(error);
		});
	}
	
	ApiCall = () => {
		if(hasAccessToken()){
			this.getCurrencyListData();
		}
	}
  render() {
		const this_el=this;
	return (
	  <div>
		<Modal
		  isOpen={this.props.currencyModalOpen}
		  onAfterOpen={this.ApiCall}
		  contentLabel="Modal"

			  overlayClassName={{
				base: 'overlay-modal',
				afterOpen: '',
				beforeClose: ''
			  }}
		  className={{
				base: 'content-modal',
				afterOpen: 'full-screen-modal currency',
				beforeClose: ''
			  }}

			>
		  <div className="modal-body">
			<div className="modal-header">
			  <div className="header-btn close-btn" onClick={this.props.goBack}></div>
			  <h2 className="text-center">Change currency</h2>
			</div>
			<div className="list">
			{this.props.user.currency_codes.map(function(item,index){
				return(
					<div className="box" key={index}>
					<label className="container">{item.code}
	                  <input type="radio" defaultChecked={this_el.props.user.selected_currency === item.code} onChange={(e) => this_el.saveCurrency(e)} name="currency" value={item.code}/>
	                  <span className="checkmark"></span>
	                </label>
                </div>
				)
			})}
			</div>
		  </div>
		</Modal>

	  </div>
	);
  }
}
const mapStateToProps = (store) =>{
	return{
	   user : store.UserChangeData
   }
}
const mapDispatchToProps = {
   updateUserStateObj
}
export default connect(mapStateToProps, mapDispatchToProps)  (Currency);