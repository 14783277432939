import React from 'react';
import {Link, withRouter} from 'react-router-dom';
import {hasAccessToken} from '../../services/index.js';
import { connect } from 'react-redux'
import { updateHomeState } from '../../actions/home/actions'
let classNames = require('classnames');

const data = {
    tabs: [
        { id: 1, name: 'Explore', iconClass: 'icon-explore', link: '/', ref : 'explore_ref', secondary_links: ['/stays', '/search' ]  },
        { id: 2, name: 'Requests', iconClass: 'icon-request', link: '/requests', ref : 'requests_ref' },
        { id: 3, name: 'Trips', iconClass: 'icon-trips', link: '/trips', ref : 'trips_ref' },
        { id: 4, name: 'Talk to us', iconClass: 'icon-talk-us', link: '/support', ref : 'talk_to_us_ref' },
        { id: 5, name: 'Account', iconClass: 'icon-account', link: '/account', ref : 'account_ref' },
    ]
}

class BottomTabs extends React.Component {
    constructor(props) {
        super(props);

        // set initial states
        this.state = {
            selectedTabId: localStorage.getItem('selectedTabId')?localStorage.getItem('selectedTabId'):1,
        }

        // This binding is necessary to make `this` work in the callback
        this.changeTab              = this.changeTab.bind(this);
        this.renderIndividualTab    = this.renderIndividualTab.bind(this);
        this.changeCount              = this.changeCount.bind(this);
        this.getCount              = this.getCount.bind(this);
        this.getCount              = this.getCount.bind(this);
        this.getClass              = this.getClass.bind(this);
        this.findTabIdActive              = this.findTabIdActive.bind(this);


        localStorage.removeItem('selectedTabId');

        this.changeCount()
    }


    findTabIdActive(item){
        var l = document.createElement("a");
        l.href = window.location.href;
        const pathname = l.pathname;
        return item.link === pathname || (item.secondary_links && item.secondary_links.filter(x => pathname.includes(x)).length > 0)
    }

    getCount(){
        let request_count = localStorage.getItem('request_count');
        if(request_count < 0 || request_count === undefined){
            request_count = 0;
        }

        return request_count;
    }


    getClass(){
        let request_count = this.getCount();
        if(request_count > 0){
            return 'active';
        }
        else{
            return '';
        }
    }

    changeCount(){
        // getter
        console.log('Changing Count')

        let request_count = this.getCount();
        

        const this_el = this;
        let request_count_ref = this_el.refs.request_count;

        if(request_count_ref !== undefined){
            if(request_count > 0){
                request_count_ref.innerHTML = request_count;
                request_count_ref.classList.add('active');
            }
            else{
                request_count_ref.classList.remove('active');
            }
        }
    }   

    componentDidMount(){
        const this_el = this;
        this_el.changeCount();

        this.props.giveCount(this.changeCount)
    }


    shouldComponentUpdate(nextProps, nextState) {
        // const this_el = this;
        // this_el.changeCount();
        // if (this.state.selectedTabId === nextState.selectedTabId) {
        //     // if next selected tab and current selected tab are same 
        //     // then don't render
        //     // return false;
        // }
         return true;
    }

    // change tab on user click
    changeTab(e, elId, link) {
        this.setState({selectedTabId: elId});
        localStorage.setItem('selectedTabId',elId);

        if (link === '/' && this.props.location.pathname === '/') {
            window.scrollTo(0,0)
        }
    }

    // render individual tab button
    renderIndividualTab(el) {
        let tabClasses = classNames({
            "bottom-tab": true,
            "color-text-grey": true,
            "active": this.findTabIdActive(el),
        });


        return (
            <li key={el.id} className={tabClasses}>
               
                    <Link to={el.link} onClick={(e) => this.changeTab(e, el.id, el.link)}>
                        <i className={el.iconClass}></i>
                        {el.name}
                    </Link>
                    {el.id === 2 && hasAccessToken() &&
                        <span className={this.getClass() + ' request-count'} ref='request_count' id='request_count'>{this.getCount()}</span>
                    }
                
            </li>
        );
    }

    render() {
        // generate all tabs
        var tabs = data.tabs.map(function (el, i) {
            return (this.renderIndividualTab(el));
        }, this);

        return !this.props.location.pathname.includes('mapFullView') && (
            <div className="bottom-tabs-ul">
                <ul className="bottom-tabs">
                    { tabs }
                </ul>
            </div>
        );
    }
}

const mapStateToProps = state => ({
    home: state.home
})

const mapDispatchToProps = {
	updateHomeState
};

export default connect(mapStateToProps, mapDispatchToProps, null, { withRef: true })(withRouter(BottomTabs, { withRef: true }))