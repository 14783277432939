import { SET_STATE_REQUEST_OBJ, SET_STATE_REQUEST_RESET } from '../actions/request/vars';


const initialState = {
    requests: [],
    archived_request_count: '',
    api: false,
    activeImg: false,
    active_request_count: '',
    info : '',
    request_hash_id : '',
    invoice_header : [],
    invoice_middle : [],
    invoice_footer : [],
    booking_status : '',
    cancellation_section : '',
    cancellation_reasons : [],
    cancellation_policy_info : '',
    seconds : 0,
    selected_payment_method : '',
    archivedRequests: [],
    property_section:{},
    requests_scroll:0,
    offset:0,
    lat: '',
    lng: '',
    isTop:true,
    Roffset:0,
    dataLoaded: false,
    RdataLoaded:false,
    requestHash: null,
    updateList: false,
    'flag' : {
        no_more_result : false,
        no_result : false,
        api : {
            is_loading: false,
            is_loader:false,
        }
    }
}

const fnc = (state = initialState, action) => {
    switch (action.type) {
        case SET_STATE_REQUEST_OBJ:
            let newState = Object.assign({}, state, action.payload);
            return newState;

        case SET_STATE_REQUEST_RESET:
            return Object.assign({}, initialState);

        case 'CLEAR_REDUX_STATE':
            return { ...state, dataLoaded: false, RdataLoaded: false, requests: [] }
            
        default:
            return state;
    }

}

export default fnc;