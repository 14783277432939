import React from 'react';
import DocumentMeta from 'react-document-meta';
import SearchTile from '../../components/propertyTile';
import SearchFilter from '../../components/searchResult/searchFilter';
import SearchHeader from '../../components/searchResult/searchHeader.js'
import noResultImg from '../../static/img/no-search-result.svg';
import DateRangeCalendar from '../../components/home/searchBarContainer/dateRangeCalendar.js';
import SelectGuestsModal from '../../components/home/searchBarContainer/selectGuestsModal.js';
import {withRouter} from 'react-router'
import {connect} from "react-redux"
import noInternet from '../../static/img/infoWindow/noInternet.svg'
import OfflineStatus from '../../components/Offlinestatus'
import ConfirmDateModal from './confirmdatesmodal'
import Placeholder from '../../static/img/placeholder-gallery.jpg'
import serverError from "../../static/img/serverError.png";
// services
import {
    getURLParameter,
    getSearchApi,
    updateURLParameter,
    ddMMYYYY
} from '../../services/index.js';

import {ObjEq, cloneObj, checkIfOffline} from './../../services/helpers.js';
import {getObjectVal, numStringToArr, stringToArr, getVal} from '../../services/helpers.js';
import {trackGoToPropertyPage, trackPageView} from '../../services/tracker/events.js';

/**
* Actions
*/
import {updateBookingDataState} from "./../../actions/booking-data/actions";
import {updatePropertyState} from "./../../actions/property/actions";
import {updateSearchState} from "./../../actions/search/actions";
import {updateErrorState} from '../../actions/error/actions'

// Animation loader
import AnimationLoader from './../../components/common/loading-animation.js';

let listner
class SearchResult extends React.Component {

    constructor(props) {
        super(props);
        
        /**
		* Curretly used functions
		*/

        this.search = this.search.bind(this); // hitting search api
        this.getSearchApiDataSuccess = this.getSearchApiDataSuccess.bind(this); // handling search api success response
        this.initialDataPull = this.initialDataPull.bind(this); // responsible to pull initial data if user direct land to page 8
        this.resetSearchApiParamsForStay = this.resetSearchApiParamsForStay.bind(this);
        this.expandPropertyType = this.expandPropertyType.bind(this); // toggle property list view more
        this.locationList = this.locationList.bind(this); // toggle location list view more
        this.openFilter = this.openFilter.bind(this); // open filter
        this.updateStore = this.updateStore.bind(this); // open filter
        this.toProperty = this.toProperty.bind(this);
        this.nextResult = this.nextResult.bind(this);
        this.previousResult = this.previousResult.bind(this);
        this.checkFilterChange = this.checkFilterChange.bind(this);
        this.openMaxNightsAlert = this.openMaxNightsAlert.bind(this);
        this.closeMaxNightsAlert = this.closeMaxNightsAlert.bind(this);

        this.state = {
            confirmDateModalOpen: false,
            prevScrollState: 0
        }
        /**
		* No idea about these functions, remove after search complete
		*/

        this.goBack = this.goBack.bind(this);

        if (props.location.hash === '#filter') {
            const this_el = this;
            let flags = this_el.props.search.flag;
            flags.modal.filter = true;
    
            this_el.props.updateSearchState({flag: flags});
        }

        if (!listner) {
            //url change listner example
            listner = this.props.history.listen((location, action) => {
                if (location.hash === '#filter') {
                    const this_el = this;
                    let flags = this_el.props.search.flag;
                    flags.modal.filter = true;

                    props.updateSearchState({flag: flags});
                } else {
                    const this_el = this;
                    let flags = this_el.props.search.flag;
                    flags.modal.filter = false;
                    flags.modal.amenity = false;
                    flags.modal.accomodation = false;
                    flags.modal.area = false;
            
                    props.updateSearchState({flag: flags});
                }
            });
        }
    }

    /**
	* return params that is required to hit search API
	* @return {Object} [params required to search]
	*/

             pullSearchApiParams() {
        const queries = this.props.location.search.slice(1, this.props.location.search.length).split('&')
        const this_el = this;

        let param = {

            lat: this_el.props.booking.lat,
            lng: this_el.props.booking.lng,
            per_page: this_el.props.search.per_page,
            item_count_offset: this_el.props.search.offset,
            country: (typeof this_el.props.booking.country.ccode !== 'undefined') ? this_el.props.booking.country.ccode : '',

            checkin: ddMMYYYY(this_el.props.booking.checkin_date),
            checkout: ddMMYYYY(this_el.props.booking.checkout_date),
            guests: this_el.props.booking.guest_count,

            // filter val

            // min_budget: this_el.props.search.filter.filter.price.min, // filter min budget
            // max_budget: this_el.props.search.filter.filter.price.max, // filter max budget
            // search_keyword: this_el.props.search.filter.filter.area.join(','), // filter area
            // bedroom: this_el.props.search.filter.filter.bedroom, // filter bedroom
            // roomtype: this_el.props.search.filter.filter.room_type, // filter room type
            // property_type: this_el.props.search.filter.filter.property_type.join(','), // filter property_type
            // amenities: this_el.props.search.filter.filter.amenities.join(','), // filter amenities
            // instant_book: this_el.props.search.filter.filter.instant_book, // filter instant book
            // cash_on_arrival: this_el.props.search.filter.filter.cash_on_arrival, // filter cash on arrival

            have_images: 1,
            within_distance: 500,
            sort: this_el.props.search.sort, // need to check for its value
            promo: Object.keys(this_el.props.search.promo).length > 0 ? this_el.props.search.promo : '', // need to check for its value
            slug: this_el.props.search.slug,

            view: '',
            prive: '',
            is_promotional: '',
            exclude_city: '',
            area: '',
            cancellation_policy: '',
            exclude_area: '',
            ...queries.reduce((acc, x) => ({ ...acc, [x.split('=')[0]]: x.split('=')[1] }), {}),
            city: this_el.props.booking.city,
            location: this_el.props.search.search_address_data.location,
            state: this_el.props.booking.state,
        }

        Object.keys(param).forEach((key) => {
            if (param[key] === '' || param[key] === undefined || param[key] === null){
                delete param[key];
            }
        })

        return param;

    }

    resetSearchApiParamsForStay() {

        const this_el = this;
        let params = this_el.pullSearchApiParams();

        let stay_keys = [
            'per_page',
            'item_count_offset',
            'exclude_city',
            'area',
            'exclude_area',
            'view',
            'cancellation_policy',
            'have_images',
            'within_distance',
            'prive',
            'is_promotional',
            'slug'
        ];
        let staySearchParams = {};

        if (stay_keys) {
            for (let key in stay_keys) {
                if (stay_keys[key] in params) {
                    staySearchParams[stay_keys[key]] = params[stay_keys[key]];
                }
            }
        }
        return staySearchParams;
    }

    getSearchApiDataSuccess(response, callback = undefined, reset) {

        const this_el = this;
        const data = response.data.data;

        let flag = Object.assign({}, this_el.props.search.flag);
        flag.no_more_result = (data.properties_list.length < this_el.props.search.per_page); // if no more result flag found, set no_more_result to true
        flag.show_loader = !flag.no_more_result;
        flag.api.is_loading = false;
        flag.popular_search_data = true;
        flag.modal.area = false;

        //Updating filter values*/
        let filter = Object.assign({}, this_el.props.search.filter);
        filter.values = data.filters;

        filter.filter.area = filter.filter.area.filter(
            x => filter.values.search_location.filter(y => y.name === x).length > 0
        )

        filter.filter_replica.area = filter.filter.area.filter(
            x => filter.values.search_location.filter(y => y.name === x).length > 0
        )

        //Merging with previous records if there is any previous API result

        let property_list = reset ? [] : Object.assign([], this_el.props.search.property_list);
        if (!this_el.props.search.flag.stay_page) {
            Array.prototype.push.apply(property_list, data.properties_list);
        }

        let meta = Object.assign({}, this_el.props.search.meta);
        meta.title = data.meta.meta_title;
        meta.desc = data.meta.meta_desc;
        meta.url = data.meta.meta_url;

        //Updating search state

        this_el.props.updateSearchState({
                property_list: property_list,
                flag: flag,
                filter: filter,
                promo: Object.keys(data.promo_banners).length > 0 ? data.promo_banners : '',
                search_address_data: data.search_address_data,
                seo_content: data.seo_content,
                canonical_url: data.meta.canonical_url,
                keyword: data.meta.keyword,
                meta: meta,
                title_prefix: data.meta.title_prefix
            })
            .then(() => {
                // state is updated, checking for callback if any
                if (typeof callback === 'function')
                    callback(true, false);
                }
            );
    }

    getStaySearchApiDataSuccess(response, callback = undefined, scroll_top = false) {

        const this_el = this;
        const data = response.data.data;

        let flag = Object.assign({}, this_el.props.search.flag);
        flag.no_more_result = (data.properties_list.length < this_el.props.search.per_page); // if no more result flag found, set no_more_result to true
        flag.api.is_loading = false;
        flag.show_loader = false; // animation hide in case of stay page
        flag.modal.area = false;

        //Updating filter values*/
        let filter = Object.assign({}, this_el.props.search.filter);
        filter.values = data.filters;

        // Property list
        let property_list = data.properties_list;

        let meta = Object.assign({}, this_el.props.search.meta);
        meta.title = data.meta.meta_title;
        meta.desc = data.meta.meta_desc;
        meta.url = data.meta.meta_url;

        //Updating search state
        this_el.props.updateSearchState({
                property_list: property_list,
                flag: flag,
                filter: filter,
                promo: Object.keys(data.promo_banners).length > 0 ? data.promo_banners : '',
                search_address_data: data.search_address_data,
                seo_content: data.seo_content,
                canonical_url: data.meta.canonical_url,
                keyword: data.meta.keyword,
                meta: meta,
                title_prefix: data.meta.title_prefix
            }).then(() => {

                this_el.props.updateBookingDataState({
                        location: this_el.props.search.search_address_data.location,
                        city: this_el.props.search.search_address_data.city,
                        state: this_el.props.search.search_address_data.state,
                        country: {
                            name: this_el.props.search.search_address_data.country_name,
                            ccode: this_el.props.search.search_address_data.country
                        }
                }).then(() => {

                    // only scroll when we hit prev or next pagination button
                    if(scroll_top){
                        window.scroll(-10, -10);
                    }

                    // state is updated, checking for callback if any
                    if (typeof callback === 'function')
                        callback(true, false);
                    }
                );
            });
    }

    search(search_params, callback = undefined, reset) {

        const this_el = this;
        console.log('Calling APi', search_params)
        //calling api
        getSearchApi(search_params).then((response) => {

            if (this_el.props.search.flag.stay_page) {

                // check for scroll_top key in search params, to scroll on top or not
                let scroll_top = search_params.hasOwnProperty('scroll_top');
                this_el.getStaySearchApiDataSuccess(response, callback, scroll_top);
                this.props.updateErrorState({
                    flag: {
                        network: false
                    }
                })

            } else {

                this_el.getSearchApiDataSuccess(response, callback, reset);
                this.props.updateErrorState({
                    flag: {
                        network: false
                    }
                })

                }
            })
            .catch(function (error) {
                console.log(error)
                // do something with error
                if (typeof callback === 'function')
                    callback(false, error);
                    this_el.props.updateErrorState({
                        flag: {
                            network: true
                        }
                    })
                }
            );
    }

    goBack(fn) {
        if (this.props.prevRoute.hash === '#filter') {
            this.props.history.replace('/');
        } else {
           fn && fn()
        }
    }

    toDate(dateStr) {
        if (dateStr !== null) {
            var parts = dateStr.split("-");
            return new Date(parts[2], parts[1] - 1, parts[0]);
        } else {
            return 1;
        }
    }

    reloadMoreResults() {

        const this_el = this;
        this.scrollFunction = function () {
            let search_page = this_el.props.location.pathname.includes('search')
            if (search_page ) {

            const scrollTopOffset = Math.max(window.pageYOffset, document.documentElement.scrollTop, document.body.scrollTop);

            let windowHeight = window.innerHeight;
            let bodyHeight = document.body.clientHeight - 1260;
            let offsetWindowHeight = windowHeight + scrollTopOffset;

            if ((offsetWindowHeight >= bodyHeight) && !this_el.props.search.flag.api.is_loading && !this_el.props.search.flag.no_more_result && !this_el.props.location.pathname.includes('stays')) {

                if (this_el.props.search.property_list.length > 0) {
                    let search_flag = Object.assign({}, this_el.props.search.flag);
                    search_flag.api.is_loading = true;
                    search_flag.popular_search_data = true;

                    this_el.props.updateSearchState({
                        offset: parseInt(this_el.props.search.offset, 10) + parseInt(this_el.props.search.per_page, 10),
                        flag: search_flag
                    }).then(() => {

                        let current_url = this_el.props.location.search;
                        // current_url = updateURLParameter(current_url, 'offset', this_el.props.search.offset);
                        this_el.props.history.replace({search: current_url, hash: this_el.props.location.hash});
                        this_el.search(this_el.pullSearchApiParams());
                    });
                }
            }
        }
        }
        window.onscroll = this.scrollFunction;
    }

    reload = () => {
        this.updateStore()
    }

    // next result
    nextResult() {

        const this_el = this;

        let flags = Object.assign({}, this_el.props.search.flag); 
        flags.api.is_loading = true;
        flags.show_loader = true;

        this_el.props.updateSearchState({
            offset: parseInt(this_el.props.search.offset, 10) + parseInt(this_el.props.search.per_page, 10),
            flag: flags,
            property_list:[]
        })
        .then(() => {
            let currentUrl = this_el.props.location.search;
            console.log(currentUrl)
            currentUrl = updateURLParameter(currentUrl, 'offset', this_el.props.search.offset);

            this_el.props.history.replace({
                search: currentUrl,
                hash: this_el.props.location.hash
            });

            let search_params = this_el.resetSearchApiParamsForStay();
            search_params.scroll_top = true; // this param is used to scroll on top when next result pulled from api
            this_el.search(search_params, (succ, error) => {});
        });
    }

    // previous result
    previousResult() {
        const this_el = this;

        let flags = Object.assign({}, this_el.props.search.flag);
        flags.api.is_loading = true;
        flags.show_loader = true;

        if (this_el.props.search.offset > 0) {
            this_el.props.updateSearchState({
                offset: parseInt(this_el.props.search.offset, 10) - parseInt(this_el.props.search.per_page, 10),
                flag: flags,
                property_list: []
            })
            .then(() => {
                let currentUrl = this_el.props.location.search;
                currentUrl = updateURLParameter(currentUrl, 'offset', this_el.props.search.offset);
                this_el.props.history.replace({search: currentUrl, hash: this_el.props.location.hash});

                let search_params = this_el.resetSearchApiParamsForStay();
                search_params.scroll_top = true; // this param is used to scroll on top when next result pulled from api
                this_el.search(search_params, (succ, error) => {});
            });
        }
    }

    initialDataPull() {

        const this_el = this;

        // default values
        const max_per_page = 50;
        const per_page = this_el.props.search.per_page;
        const current_offset = this_el.props.search.offset;
        let search_flags = Object.assign({}, this_el.props.search.flag);
        search_flags.api.is_loading = true;

        let offsetItemCountArr = [];

        if (this_el.props.search.flag.stay_page) {

            // in case of stay page, we don't have onscroll pagination we have prev and next
            // button
            offsetItemCountArr.push({item_offset: current_offset, per_page: per_page});
        } else {
            for (let i = 0; i <= current_offset; i += max_per_page) {
                const totalProductPull = (i + max_per_page > current_offset)
                    ? (current_offset - i) + 10
                    : max_per_page;
                offsetItemCountArr.push({item_offset: i, per_page: totalProductPull});
            }
        }

        this_el.props.updateSearchState({flag: search_flags}).then(() => {

            // its time to call api

            let property_list = [];
            let filters = {};
            let promo_banners = {};
            let seo_content = {};
            let search_address_data = this.props.search.search_address_data;
            let canonical_url = '';
            let keyword = '';
            let meta_desc = '';
            let meta_title = '';
            let meta_url = '';
            let title_prefix = '';
            let no_more_result = false;

            var callApi = function (val, idx) {

                return new Promise(function (resolve, rejected) {

                    let search_api_params = this_el.pullSearchApiParams();
                    search_api_params.per_page = val.per_page;
                    search_api_params.item_count_offset = val.item_offset;
                    // call api
                    getSearchApi(search_api_params).then(function (response) {
                        this.props.updateErrorState({
                            flag: {
                                network: false
                            }
                        })
                        let temp_property_list = response.data.data.properties_list;
                        filters = response.data.data.filters;
                        promo_banners = response.data.data.promo_banners;
                        seo_content = response.data.data.seo_content;
                        search_address_data = response.data.data.search_address_data;
                        canonical_url = response.data.data.meta.canonical_url;
                        keyword = response.data.data.meta.keyword;
                        meta_desc = response.data.data.meta.meta_desc;
                        meta_title = response.data.data.meta.meta_title;
                        meta_url = response.data.data.meta.meta_url;
                        title_prefix = response.data.data.meta.title_prefix;
                        no_more_result = (response.data.data.properties_list.length < this_el.props.search.per_page); // if no more result flag found, set no_more_result to true

                        //merging with previous property list
                        if (this_el.props.search.flag.stay_page) {
                            property_list = temp_property_list;
                        } else {
                            // only merge in case of search page
                            Array.prototype.push.apply(this_el.props.search.properties_list, temp_property_list);
                        }
                        resolve(true);
                    })
                        .catch(function (error) {
                            // write code to manage errors
                        });
                });
            };

            var results = Promise.all(offsetItemCountArr.map(callApi));
            results.then((data) => {

                let flag = Object.assign({}, this_el.props.search.flag);
                flag.no_more_result = no_more_result
                flag.api.is_loading = false;

                //Updating filter values*/
                let filter = Object.assign({}, this_el.props.search.filter);
                filter.values = filters;

                let meta = Object.assign({}, this_el.props.search.meta);
                meta.title = meta_title;
                meta.desc = meta_desc;
                meta.url = meta_url;

                this_el
                    .props
                    .updateSearchState({
                        property_list: property_list,
                        flag: {
                            ...flag,
                            modal: {
                                ...this.props.search.flag.modal,
                                area: false
                            }
                        },
                        filter: filter,
                        promo: Object.keys(promo_banners).length > 0 ? promo_banners : '',
                        search_address_data: search_address_data,
                        seo_content: seo_content,
                        canonical_url: canonical_url,
                        keyword: keyword,
                        meta: meta,
                        title_prefix: title_prefix
                    })
                    .then(() => {
                        // store is updated now

                    }); // update searchState ends

            }); // promise all ends here

        }); // update search state flag update

    }

    toProperty(data, index) {
        const this_el = this;
        if (navigator.userAgent.match('CriOS')) {
            localStorage.setItem('iosScrollState', window.scrollY)
        }
        // first resetting all property data
        this.props.updatePropertyState({},1).then(() => {
            // updating store data
            this.props.updatePropertyState({
                'property_images': data.property_images,
                'property_title': data.property_title,
                'title': data.title,
                'property_score': data.property_score,
                'host_image': data.host_image,
                'host_name': data.host_name,
                prevUrl: this.props.location.pathname + this.props.location.search
            }).then(() => {

                // updating booking data
                this.props.updateBookingDataState({
                    'location': data.location.location_name,
                    'lat':data.location.latitude,
                    'lng':data.location.longitude,

                }).then(() => {
                    trackGoToPropertyPage(
                        this_el.props.tracker,
                        this_el.props.search.page,
                        'search_tile',
                        data.property_hash_id,
                        {currency_code: this_el.props.search.filter.values.budget_currency.iso_code},
                        [{
                            hpid: data.property_hash_id,
                            title: data.property_title,
                            price: data.prices.price_after_discount_unformatted

                        }]
                    );

                    let currentUrl = this_el.props.location.search;
                    currentUrl = updateURLParameter(currentUrl, 'offset', this_el.props.search.offset);

                    this_el.props.history.replace({pathname: this_el.props.location.pathname, search: currentUrl, hash: data.property_hash_id});

                    if (this_el.props.booking.checkin_date === 1) {
                        this_el.props.history.push({
                            pathname: '/property/' + data.property_hash_id
                        });

                    } else {

                        let urlParams = '?&checkin=' + ddMMYYYY(this_el.props.booking.checkin_date) + '&checkout=' + ddMMYYYY(this_el.props.booking.checkout_date) + '&guests=' + this_el.props.booking.guest_count;

                        this.props.history.push({
                            pathname: '/property/' + data.property_hash_id,
                            search: urlParams
                        });
                    }
                });
            });
        })
    }

    componentDidUpdate () {
        if (this.props.prevRoute.pathname === '/search/s' && this.props.location.pathname.includes('stays') && !this.rerendered) {
            this.updateStore(true);
            this.rerendered = true;
        }

        // Showing Popup in search
        if (
            getURLParameter(this.props.location.search, 'checkin')
            && ((getURLParameter(this.props.location.search, 'checkin') !== ddMMYYYY(this.props.booking.checkin_date)) || (getURLParameter(this.props.location.search, 'checkout') !== ddMMYYYY(this.props.booking.checkout_date)))
            && !this.state.confirmDateModalOpen
            && this.props.location.pathname.includes('search')
            && !this.props.search.fromSearch
        ) {
            this.setState({
                confirmDateModalOpen: true
            })
        }

        // Showing Popup in stays
        if (
            !this.popupOpen && this.props.search.temp_dates.checkin_date !== null && (this.props.location.pathname.includes('stays') || this.props.location.search.includes('promo')) && !this.state.confirmDateModalOpen) {
            if (
                ddMMYYYY(this.props.search.temp_dates.checkin_date) !== ddMMYYYY(this.props.booking.checkin_date)
                || ddMMYYYY(this.props.search.temp_dates.checkout_date) !== ddMMYYYY(this.props.booking.checkout_date)
            ) {
                this.setState({
                    confirmDateModalOpen: true
                })
            }
        }
    }

    componentDidMount() {
        const this_el = this;

        if (this.props.search.scrollState[1] !== undefined) {
            window.scrollTo(...this.props.search.scrollState)
        } else {
            window.scroll(0,0);
        
            // checking store have data or not
            // Only for back action or hard refresh action
            if (this_el.props.search.property_list.length > 1 && this.props.history.action === "POP"){
                // back action no need to call API
            }else{
                if (!(this.props.prevRoute && this.props.prevRoute.pathname.includes('signup'))) {
                    this_el.updateStore(true);
                }
            }
            this.props.updatePropertyState({}, 1);
        }



        let search_flags = Object.assign({}, this_el.props.search.flag);
        console.log('Flag', search_flags);
        search_flags.search_page = true;
        this_el.props.updateSearchState({
            flag: search_flags
        }).then(() => {
            if (this.props.location.pathname.includes('/search/s')) {
                console.log('Listener added')
                this_el.reloadMoreResults();
            }
        })
    }

    componentWillUnmount() {
        const this_el = this;
        let search_flags = Object.assign({}, this_el.props.search.flag);
        search_flags.search_page =  false;
        this_el.props.updateSearchState({
            flag:search_flags,
            scrollState: [window.pageXOffset, window.pageYOffset]
        });
        //alert(search_flags.search_page);
        window.onscroll = () => {}

    }


    checkFilterChange(){
        const this_el = this;
        let filter = cloneObj(this_el.props.search.filter.filter);
        let filter_initial = cloneObj(this_el.props.search.filter.filter_initial);
        let isEqual = ObjEq(filter, filter_initial);
        return !isEqual
    }


    /**
	* used to pull data from url and update store, or act when new props passed
	*/
    updateStore(reset = false) {

        const this_el = this;

        let offset = this_el.props.location.search.includes('offset') ? (getURLParameter(this_el.props.location.search, 'offset')): 0
        let property_list = reset ? [] : Object.assign([], this_el.props.search.property_list);
        if (reset) {
            offset = 0;
        }

        let ccode = this_el.props.location.search.includes('country') ? (getURLParameter(this_el.props.location.search, 'country')): '';
        let ccname = (ccode === 'IN') ? 'India' : '';
        let country = {};
        if(ccname){
          country = {
            name: ccname,
            ccode: ccode
          }
        }
        this.props.updateSearchState({
            search_address_data: {
                ...this.props.search.search_address_data,
                location: this_el.props.location.search.includes('location') ? (getURLParameter(this_el.props.location.search, 'location')) : (this_el.props.booking.location !== null && this_el.props.booking.location)
            }
        })

        this.props.updateBookingDataState({
            checkin_date: this_el.props.location.search.includes('checkin') ? (this_el.toDate(getURLParameter(this_el.props.location.search, 'checkin'))): 1,
            checkout_date: this_el.props.location.search.includes('checkout')? (this_el.toDate(getURLParameter(this_el.props.location.search, 'checkout'))): 1,
            guest_count: this_el.props.location.search.includes('guests') ? (parseInt(getURLParameter(this_el.props.location.search, 'guests'), 10)): 0,
            ...(
                !this.props.location.pathname.includes('stay') && {
                    location: this_el.props.location.search.includes('location') ? (getURLParameter(this_el.props.location.search, 'location')) : (this_el.props.booking.location !== null && this_el.props.booking.location),
                    city: this_el.props.location.search.includes('city') ? (getURLParameter(this_el.props.location.search, 'city')) : '',
                    state: this_el.props.location.search.includes('state') ? (getURLParameter(this_el.props.location.search, 'state')) : '',
                    country: country,
                    lat: this_el.props.location.search.includes('lat') ? getURLParameter(this.props.location.search, 'lat') : '',
                    lng: this_el.props.location.search.includes('lng') ? getURLParameter(this.props.location.search, 'lng') : '',
                }
            )
        }).then(() => {

                //Initial Data


                // updating filters
                let filterObj = JSON.parse(JSON.stringify(this_el.props.search.filter));
                filterObj.filter.price.min = this_el.props.location.search.includes('min_budget') ? parseInt(getURLParameter(this_el.props.location.search, 'min_budget'), 10) : this_el.props.search.filter.slider.min;
                filterObj.filter.price.max = this_el.props.location.search.includes('max_budget') ? parseInt(getURLParameter(this_el.props.location.search, 'max_budget'), 10) : this_el.props.search.filter.slider.max;
                filterObj.filter.roomtype = this_el.props.location.search.includes('roomtype') && getURLParameter(this_el.props.location.search, 'roomtype') !== null ? parseInt(getURLParameter(this_el.props.location.search, 'roomtype'), 10) : 0;
                filterObj.filter.instant_book = this_el.props.location.search.includes('instant_book') && getURLParameter(this_el.props.location.search, 'instant_book') !== null ? parseInt(getURLParameter(this_el.props.location.search, 'instant_book'), 10) : 0;
                filterObj.filter.cash_on_arrival = this_el.props.location.search.includes('cash_on_arrival') && getURLParameter(this_el.props.location.search, 'cash_on_arrival') !== null ? parseInt(getURLParameter(this_el.props.location.search, 'cash_on_arrival'), 10) : 0;
                filterObj.filter.bedroom = this_el.props.location.search.includes('bedroom') && getURLParameter(this_el.props.location.search, 'bedroom') !== null ? parseInt(getURLParameter(this_el.props.location.search, 'bedroom'), 10) : 0;
                filterObj.filter.property_type = this_el.props.location.search.includes('property_type')  ? numStringToArr((getURLParameter(this_el.props.location.search, 'property_type'))) : [];
                filterObj.filter.amenities = this_el.props.location.search.includes('amenities')  ? numStringToArr((getURLParameter(this_el.props.location.search, 'amenities'))) : [];
                filterObj.filter.area = this_el.props.location.search.includes('area')  ? stringToArr((getURLParameter(this_el.props.location.search, 'area'))) : [];

                const filter_replica = JSON.parse(JSON.stringify(filterObj.filter));
                filterObj.filter_replica = filter_replica;


                let search_flags = Object.assign({}, this_el.props.search.flag);
                search_flags.api.is_loading = true;
                search_flags.show_loader = true;
                search_flags.no_more_result = false;
                search_flags.search_page = true;
                let page = this_el.props.page;

                if (this_el.props.match.path === '/stays/:location') {
                    search_flags.stay_page = true;
                    page = 'stay';
                } else {
                    search_flags.stay_page = false;
                    page = 'search';
                }

                this_el.props.updateSearchState({
                	flag: search_flags,
                	slug: this.props.match.params.location,
                	page: page,
                	filter : filterObj,
                	offset: offset,
                	property_list: property_list,
                	promo: this_el.props.location.search.includes('promo')  ? (getURLParameter(this_el.props.location.search, 'promo')) : '',

                }).then(() => {

                    // checking if any filter applied or not
                    this_el.checkFilterChange();    

                    //Calling Tracker
                    trackPageView(this.props.tracker, this_el.props.search.page);

                    let search_params = {};
                    if (this_el.props.search.flag.stay_page) {
                        search_params = this_el.resetSearchApiParamsForStay();
                    } else {
                        search_params = this_el.pullSearchApiParams();
                    }

                    this_el.search(search_params, (succ, error) => {
                        // if(!this_el.props.search.flag.stay_page && !reset){ 	// calling initial data for previous offsets
                        // 	this_el.initialDataPull();
                        // }
                        if (this.rerendered && this.props.location.pathname.includes('stays')) {
                            this.rerendered = false;
                            window.scrollTo(0, this.state.prevScrollState)
                        }
                        if (navigator.userAgent.match('CriOS')) {
                            if (localStorage.getItem('iosScrollState')) {
                                window.scrollTo(0, localStorage.getItem('iosScrollState'))
                                localStorage.setItem('iosScrollState', null)
                            }
                        }
                    }, reset);
                }); // search data update callback ends here

            }); // booking data update calback ends here
    }

    /**
	* expand all
	*/

    expandPropertyType(e) {
        if (this.refs.propertyTypeList.classList.value === 'expand') {
            this.refs.propertyTypeList.classList.remove('expand');
        } else {
            this.refs.propertyTypeList.classList.add('expand');
        }

        if (e.target.innerHTML === 'View less') {
            e.target.innerHTML = 'View all';
        } else {
            e.target.innerHTML = 'View less';
        }
    }

    getUrlWithParameters = (area, property_type) => {
        const this_el = this;
        let filter_replica = { ...this.props.search.filter.filter_replica };
        let booking = Object.assign({}, this_el.props.booking);
        let urlParams = [];

        urlParams.push('checkin=' + ddMMYYYY(booking.checkin_date, ''));
        urlParams.push('checkout=' + ddMMYYYY(booking.checkout_date, ''));
        urlParams.push('guests=' + getVal(booking.guest_count));
        urlParams.push('lat=' + getVal(this.props.search.search_address_data.lat));
        urlParams.push('lng=' + getVal(this.props.search.search_address_data.lng));
        urlParams.push('location=' + getVal(this.props.search.search_address_data.location));
        urlParams.push('area=' + getVal(filter_replica.area.join(',')));
        urlParams.push('city=' + getVal(this.props.search.search_address_data.city));
        urlParams.push('state=' + getVal(this.props.search.search_address_data.state));
        urlParams.push('country=' + getVal(booking.country.ccode));
        urlParams.push('min_budget=' + getVal(filter_replica.price.min));
        urlParams.push('max_budget=' + getVal(filter_replica.price.max));
        urlParams.push('roomtype=' + getVal(filter_replica.roomtype));
        urlParams.push('instant_book=' + getVal(filter_replica.instant_book));
        urlParams.push('cash_on_arrival=' + getVal(filter_replica.cash_on_arrival));
        urlParams.push('bedroom=' + getVal(filter_replica.bedroom));
        urlParams.push('property_type=' + (property_type ? property_type : getVal(filter_replica.property_type).join(',')));
        urlParams.push('amenities=' + getVal(filter_replica.amenities).join(','));
        urlParams.push('area=' + (area ? area : getVal(filter_replica.area).join(',')));
        urlParams.push('offset=0');

        console.log(urlParams)
        urlParams = urlParams.filter(x => (x.slice(-1) !== '=' && x.slice(-2) !== '=0') || x.includes('min_budget') || x.includes('max_budget'))
        console.log('Params', urlParams)
        
        let search_url_params = '?' + urlParams.join('&');

        return search_url_params
    }

    locationList(e) {
        if (this.refs.locationList.classList.value === 'expand') {
            this.refs.locationList.classList.remove('expand');
        } else {
            this.refs.locationList.classList.add('expand');
        }

        if (e.target.innerHTML === 'View less') {
            e.target.innerHTML = 'View all';
        } else {
            e.target.innerHTML = 'View less';
        }
    }

    /**
	* Open filter modal
	*/
    openFilter() {
        this.props.history.push({
            pathname: this.props.history.location.pathname,
            search: this.props.location.search,
            hash: 'filter'
        })
    }

    // When the user clicks on the button, scroll to the top of the document
    topFunction() {
        document.body.scrollTop = 0;
        document.documentElement.scrollTop = 0;
    }
    
    readMoreDesc(e){
        e.target.style.display = "none";
        e.target.previousSibling.classList.add('active');
    }

    openMaxNightsAlert() {
        this.refs.max_nights_alert_overlay.classList.add('true')
    }

    closeMaxNightsAlert() {
        this.refs.max_nights_alert_overlay.classList.remove('true')
    }

    onLoad = (e) => {
        e.target.nextSibling.classList.add("hidden");
        e.target.classList.remove("hidden");
    }


    render() {
        const this_el = this;
        const meta = {
            title: this_el.props.search.meta.title,
            description: this_el.props.search.meta.desc,
            canonical: this_el.props.search.canonical_url,
            meta: {
                charset: 'utf-8',
                property: {
                    'og:site_name': 'Guesthouser',
                    'og:title': this_el.props.search.meta.title,
                    'og:url': this_el.props.search.meta.url,
                    'og:type': 'mobile web',
                    'og:description': this_el.props.search.meta.desc,
                    'og:image': 'https://www.guesthouser.com/images/logo/logo_share.png'
                },
                name: {
                    keywords: this_el.props.search.keyword
                }
            }
        };

        const ellipsis = {
            WebkitBoxOrient: 'vertical'
        }
        
		return(

			<DocumentMeta {...meta}>
				<div className='search-page' id="search_page">
					<SearchHeader goBack={this.goBack} prevRoute={this.props.prevRoute} />
                    {
                        !this.props.error.flag.network
                        ? !this.props.error.flag.server
                            ?   (
                                <React.Fragment>
                                <div className="search-result-section">

                                {this_el.props.search.flag.stay_page &&
                                    <div>
                                        <div className='hidden'>
                                            <ul className="breadcrumb">
                                                <li><a href="/">Home</a></li>
                                                <li><a>search</a></li>
                                                <li>Goa</li>
                                            </ul>
                                        </div>
        
                                        <div className='stay-page-content'>
        
                                            {getObjectVal(this_el.props.search.seo_content, 'title') &&
                                                <h2>{getObjectVal(this_el.props.search.seo_content, 'title')}</h2>
                                            }
                                            {getObjectVal(this_el.props.search.seo_content, 'description')&&
                                                <div>
                                                    <p className='stay-page-description' style={ellipsis} dangerouslySetInnerHTML={{ __html: getObjectVal(this_el.props.search.seo_content, 'description') }} />
                                                    <span className="read-more" onClick={this.readMoreDesc}>Read more</span>
                                                </div>
                                            }
                                            
        
                                        </div>
                                    </div>
                                }
        
                                {/*
                                    checking for promo mobile url
        
                                */}
        
                                {getObjectVal(this_el.props.search.promo, 'mobile_url')&&
                                    <div className='promo-banner'>
                                        <img src={getObjectVal(this_el.props.search.promo, 'mobile_url')} className='hidden' alt='promo-banner' onLoad={(e) => this.onLoad(e)}/>
                                        <img src={Placeholder} />
                                    </div>
                                }
        
        
                                {/*
                                    Showing search title
                                */}
        
                                {this_el.props.search.property_list.length > 0 &&
                                    <div>
                                        <SearchTile
                                            properties_list={this_el.props.search.property_list}
                                            toProperty={this_el.toProperty}
                                        />
                                    </div>
                                }
        
                                {this_el.props.location.pathname.includes('stays') && this_el.props.search.property_list.length === 0 &&
                                    <AnimationLoader/>
                                }

                                {this_el.props.location.pathname.includes('search') && this_el.props.search.flag.show_loader &&
                                    <AnimationLoader/>
                                }

                                {
                                    this_el.props.search.property_list.length > 0 && this_el.props.search.flag.no_more_result &&
                                    <div style={{ textAlign: 'center', marginBottom: '70px' }} >No more results</div>
                                }
        
        
                                {/*
                                    Show loading bar for initial load and check for filter applied
                                */}
                                {(this_el.props.search.property_list.length === 0 && !this_el.props.search.flag.api.is_loading && this_el.props.search.flag.no_more_result) &&
                                    <div>
                                        {this_el.checkFilterChange()
                                            ?
                                            /* Filter is applied and their is no result !! showing no result due to filters */
                                            <div className='inline-filter'>
                                                <h2>Didn't find what you were looking for?</h2>
                                                <p>Too many filters can do that. Remove some to broaden search results.</p>
                                                <button className='no-results' onClick={this.openFilter}>Reset Filter</button>
                                            </div>
        
                                            :
                                            /* no result in current search */
                                            <div className='no-result active '>
                                                <img src={noResultImg} alt='loader'/>
                                                <p>{"Sorry! We don't have homes here yet."}</p>
                                            </div>
                                        }
                                        <div className="sticky-button" >
                                            <button ref={r => (this.filter_btn = r)} className="filter btn" onClick={this_el.openFilter}><i></i> Filter</button>
                                        </div>
                                    </div>
        
                                }
        
        
                                {this.props.location.pathname.includes('stays') && this_el.props.search.property_list.length > 0 &&
                                    <div>
                                        <div className='nav-control'>
                                            <span className='pull-right' onClick={this.nextResult}>Next <i></i></span>
                                            <span className='prev' onClick={this.previousResult}><i></i> Prev</span>
                                        </div>
                                        <div className='stay-page-content'>
                                            <div dangerouslySetInnerHTML={{ __html: this_el.props.search.seo_content.details }} />
                                        </div>
        
                                        <div className='stays-link'>
                                            <h3>
                                                Property types in {this_el.props.search.search_address_data.length !== 0 ? this_el.props.search.search_address_data.location.split(',')[0] : null}
                                            </h3>
        
                                            {getObjectVal(this_el.props.search.filter.values, 'property_types') &&
                                                <ul ref='propertyTypeList'>
                                                    {getObjectVal(this_el.props.search.filter.values, 'property_types').map(function(item, index){
                                                            return (
                                                                <li key={index} >
                                                                    <div
                                                                        onClick={() => {
                                                                            this_el.setState({
                                                                                prevScrollState: window.scrollY
                                                                            }, () => {
                                                                                this_el.props.history.push({
                                                                                    pathname: '/search/s',
                                                                                    search: this_el.getUrlWithParameters(undefined, item.id)
                                                                                })
                                                                                window.scrollTo(0,0)
                                                                                this_el.updateStore(true);
                                                                            })
                                                                        }}
                                                                    >
                                                                        {item.name}
                                                                    </div>
                                                                </li>
                                                            )
                                                        })
                                                    }
                                                </ul>
                                            }
        
                                            {getObjectVal(this_el.props.search.filter.values, 'property_types') && Object.keys(getObjectVal(this_el.props.search.filter.values, 'property_types')).length > 6 &&
                                                <div className='read-more' onClick={this.expandPropertyType.bind(this)}>View all</div>
                                            }
        
        
                                        </div>
        
                                        <div className='stays-link last'>
                                            <h3>Homes by location</h3>
        
                                            {getObjectVal(this_el.props.search.filter.values, 'search_location') &&
                                                <ul ref='locationList'>
                                                    {getObjectVal(this_el.props.search.filter.values, 'search_location').map(function(item, index){
                                                            return (
                                                                <li key={index} >
                                                                    <div
                                                                        onClick={() => {
                                                                            this_el.props.history.push({
                                                                                pathname: '/search/s',
                                                                                search: this_el.getUrlWithParameters(item.name, undefined)
                                                                            })
                                                                            window.scrollTo(0,0)
                                                                            this_el.updateStore(true);
                                                                        }}
                                                                    >
                                                                        {item.name}
                                                                    </div>
                                                                </li>
                                                            )
                                                        })
                                                    }
                                                </ul>
                                            }
                                            {getObjectVal(this_el.props.search.filter.values, 'search_location') && Object.keys(getObjectVal(this_el.props.search.filter.values, 'search_location')).length > 6 &&
                                                <div className='read-more'  onClick={this.locationList.bind(this)}>View all </div>
                                            }
        
                                        </div>
        
                                    </div>
                                }
        
                            </div>
        
        
                            {
                                this.props.search.property_list.length > 0 && (
                                    <div className="sticky-button" >
                                        <button ref='filter_btn' className={`filter btn ${this.checkFilterChange() && 'active'}`} onClick={this_el.openFilter}><i></i> Filter</button>
                                    </div>
                                ) 
                            }
        
                            {/*Search filter*/}
                            <SearchFilter
                                checkFilterChange={this.checkFilterChange}
                                updateStore={this_el.updateStore}
                            />

                            <ConfirmDateModal
                                confirmDateModalOpen={this.state.confirmDateModalOpen}
                                checkin={ddMMYYYY(this.props.booking.checkin_date)}
                                checkout={ddMMYYYY(this.props.booking.checkout_date)}
                                onProceed={() => {
                                    let url = this.props.location.search;
                                    if (this.props.location.pathname.includes('search') && !this.props.location.search.includes('promo')) {
                                        let checkinUpdate = updateURLParameter(url, 'checkin', ddMMYYYY(this.props.booking.checkin_date));
                                        let checkoutUpdate = updateURLParameter(checkinUpdate, 'checkout', ddMMYYYY(this.props.booking.checkout_date));
                                        this.props.history.replace({
                                            search: updateURLParameter(checkoutUpdate, 'checkin', ddMMYYYY(this.props.booking.checkin_date))
                                        })
                                        this.setState({
                                            confirmDateModalOpen: false
                                        }, () => {
                                            this.updateStore(true)
                                        })
                                    } else {
                                        this.popupOpen = true;
                                        this.setState({
                                            confirmDateModalOpen: false
                                        })
                                        this.props.updateSearchState({
                                            temp_dates: {
                                                checkin_date: this.props.booking.checkin_date,
                                                checkout_date: this.props.booking.checkout_date
                                            }
                                        })
                                    }
                                }}
                                close={() => {
                                    if (this.props.location.pathname.includes('search') && !this.props.location.search.includes('promo')) {
                                        this.props.updateBookingDataState({
                                            checkin_date: this.toDate(getURLParameter(this.props.location.search, 'checkin')),
                                            checkout_date: this.toDate(getURLParameter(this.props.location.search, 'checkout')),
                                            guest_count: getURLParameter(this.props.location.search, 'guests')
                                        }).then(() => {
                                            this.setState({
                                                confirmDateModalOpen: false
                                            })
                                        })
                                    } else {
                                        this.popupOpen = true;
                                        this.props.updateBookingDataState({
                                            checkin_date: this.props.search.temp_dates.checkin_date,
                                            checkout_date: this.props.search.temp_dates.checkout_date
                                        }).then(() => {
                                            this.setState({
                                                confirmDateModalOpen: false
                                            })
                                        })
                                    }
                                }}
                            />
        
        
                            {/*add search filter here*/}
                            <SelectGuestsModal location={this.props.location}  updateStore={this_el.updateStore}/>
                            <DateRangeCalendar openMaxNightsAlert={this.openMaxNightsAlert} />
                            
                            <div ref="max_nights_alert_overlay" className='max_nights_alert_overlay'>
                                <div className="max_nights_alert">
                                    <div className="modal-body text-center">
                                        <h2 className="text">Non-Availability</h2>
                                        <div className='description'>
                                            <p>Minimum stay: {this_el.props.property.min_nights} night</p>
                                            <p>Maximum stay: {this_el.props.property.max_nights} nights</p>
                                        </div>
                                        <button className="btn" onClick={this.closeMaxNightsAlert}>Done</button>
                                    </div>
                                </div>
                            </div>
                            </React.Fragment>
                            )
                            : (
                                <div className="info-window">
                                    <div className="window-container">
                                        <div className="imgb">
                                            <div className="img" style={{ backgroundImage: "url(" + serverError + ")" }} />
                                        </div>
                                        <div className="txtb">
                                            <p>Sorry, our servers are busy. Please try again in a bit.</p>
                                        </div>
                                        <button className="retry" onClick={this.reload}>Retry</button>
                                    </div>
                                </div>
                            )
                        :   <div className="info-window" style={{ marginTop: '60px' }} >
                            <div className="window-container">
                                <div className="imgb">
                                    <div className="img" style={{backgroundImage:'url(' + noInternet + ')'}}></div>
                                </div>
                                <div className="txtb"><p>Oops! You don't seem to be connected to the internet.</p></div>
                                <button className="retry" onClick={this.reload}>Retry</button>
                            </div>
                        </div>       
                    }
				</div>
			</DocumentMeta>
		)

    } // render ends

} // class ends

const mapStateToProps = store => {
    return {booking: store.booking, tracker: store.tracker.tracker, error: store.error, search: store.search, property : store.property};
};

const mapDispatchToProps = {
    updateBookingDataState,
    updatePropertyState,
    updateSearchState,
    updateErrorState
};
export default connect(mapStateToProps, mapDispatchToProps)(withRouter(SearchResult));