

/* APIs */
// get home page data
let getWishlistDeleteDataApi = function (data, args)
{
    sessionStorage.setItem('wishlistApiHit', true);
    //console.log('hey', args)
    return data.AXIOS.delete(data.BASE_URL_V+'/user/wishlist/'+args,
        {
            headers: {
                'Accept': 'application/json',
                'Content-Type': 'application/x-www-form-urlencoded',
                'device-unique-id': data.DEVICE_UNIQUE_ID,
                'Authorization' :'Bearer ' + data.ACCESS_TOKEN,
            }
        }
    );
}

exports.getWishlistDeleteDataApi = getWishlistDeleteDataApi;