

/* APIs */
// get request details page data
let getRequestDetailsApi = function (data, args)
{

    return data.AXIOS.get(data.BASE_URL_V+'/booking/request/' + args, {
        responseType: 'json',
        headers: {
            'Accept': 'application/json',
            'Content-Type': 'application/x-www-form-urlencoded',
            'device-unique-id': data.DEVICE_UNIQUE_ID,
            'Authorization' :'Bearer ' + data.ACCESS_TOKEN,
        }
    });
}

exports.getRequestDetailsApi = getRequestDetailsApi;