import { COLLECTION_UPDATE_STATE} from '../actions/collection/vars';

const initialState = {
    'collections':[],
    'offset':0,
    'page' : 'collections',
    'element_height' : 0,
    'meta':'',
    dataLoaded: false,
    'itemIndex':0,
    'parentIndex':0,
    'flag' : {
        no_more_result : false,
        api : {
            is_loading: false
        }
    }

}

const fnc = (state = initialState, action) => {
    switch (action.type) {
        case COLLECTION_UPDATE_STATE :
            return Object.assign({}, state, action.payload);
        
        case 'CLEAR_REDUX_STATE':
			return { ...state, dataLoaded: false }
			
        default:
            return state;
    }
}
export default fnc;