import React from 'react';
import {Link} from 'react-router-dom';
import ProfileIMGh from "../../static/img/profile-icon.png";
import DownloadApp from "../../components/modal/downloadApp";
import ReferEarn from "../../components/modal/share/share.js";
import noInternet from "../../static/img/infoWindow/noInternet.svg";
import {connect} from 'react-redux';
import {updateProfileStateObj} from '../../actions/profile/actions';
import {updateHomeState} from '../../actions/home/actions';
import { updateErrorState } from "./../../actions/error/actions";
import Offlinestatus from '../../components/Offlinestatus'

//services
import {getLoginAccountDataApi, getURLParameter, hasAccessToken,logout, refreshLogin,saveUrlLogin} from '../../services/index.js';

import {trackPageView,trackError} from '../../services/tracker/events.js';
import { checkIfOffline } from '../../services/helpers';



class Account extends React.Component {
	constructor(props) {
        super(props);
        this.page="account";
        this.state = {
			'host_id':'',
			toggleOfflineStatus: false
        };
      
        this.downloadAppOpen = this.downloadAppOpen.bind(this);
        this.downloadAppClose = this.downloadAppClose.bind(this);
        this.referEarnOpen = this.referEarnOpen.bind(this);
       // this.referEarnClose = this.referEarnClose.bind(this);
		this.resetLogin=this.resetLogin.bind(this);
		this.toProfile=this.toProfile.bind(this);
    }
 
    resetLogin(args){
        refreshLogin(args).then(function(response){
        }).catch(function(error){
            logout();
            window.location.reload();
        })
    }

    componentDidMount() {
		window.scrollTo(0,0)
		const this_el = this;
		if (checkIfOffline()) {
			this.props.updateErrorState({ flag: { network: true } });
		} else {
			this.props.updateErrorState({ flag: { network: false } });
		}
		window.onpopstate = function(e) {
            this_el.modalRouting(e.currentTarget.location.hash);
        };
        trackPageView(this.props.tracker, this.page);

	}
	toProfile(){
		const this_el =this
		this_el.props.updateHomeState({
			itemIndex:0
		})
		this_el.props.history.push('/user/profile?host_id=' + this.state.host_id);
		this.props.updateProfileStateObj({
			profilePage:true
		})
	}
    downloadAppOpen() {
        this.props.updateProfileStateObj({downloadAppOpen: true});
    }
    downloadAppClose() {
        this.props.updateProfileStateObj({downloadAppOpen: false});
    }
    
 
    // referEarnClose() {
    //    this.props.updateProfileStateObj({referEarn: false});
	// }
	
	modalRouting(hash){
        switch(hash){
			case '#referEarn':
				this.props.updateProfileStateObj({referEarn:true});
                break;
			default:
                this.props.updateProfileStateObj({
					referEarn:false
				});
        }
	}
	
	referEarnOpen() {
        window.location.hash = 'referEarn'
    }
  //   reload = () =>{
	//    this.getAccountApiData(this.state.host_id);
	// }
	
	toggleStatus = () => {
		this.setState({
			toggleOfflineStatus: !this.state.toggleOfflineStatus
		}, () => {
			this.setState({
				toggleOfflineStatus: !this.state.toggleOfflineStatus
			})
		})
	}
	goBack =() =>{
		this.props.history.goBack();
	}

    render() {
	 var ProfileIMG ={
	    backgroundImage:'url('+ ProfileIMGh +')',
	  }
	  let profileImage = this.props.signup.profile_image?this.props.signup.profile_image:localStorage.getItem('profile_image')
        return (
        	<div>
				
				<div className="accounts">
					<div className="row">
						<div className="col-12">
							<Offlinestatus
								offline={checkIfOffline() && !this.state.toggleOfflineStatus}
							/>
							{hasAccessToken()
								?
									// <Link to={"/user/profile?host_id="+this.state.host_id}>
									<div className="profile" onClick={this.toProfile} profile={true}>
										<div className="pr-image" style={{backgroundImage:'url('+ profileImage +')'}}></div>
										<div className="pr-name">
											<div className="name">{this.props.signup.first_name?this.props.signup.first_name:localStorage.getItem('first_name')} {this.props.signup.last_name?this.props.signup.last_name:localStorage.getItem('last_name')}</div>
											<div className="view">View profile</div>
										</div>
										<div className="icons p-arrow pull-right"></div>
									</div>
								// </Link>
								:
								<div onClick={_ => {
									if (checkIfOffline()) {
										this.toggleStatus()
									} else {
										this.props.history.push('/signup')
										saveUrlLogin()
									}
								}}>
									<div className="profile">
										<div className="pr-image" style={ProfileIMG}></div>
										<div className="pr-name">
											<div className="name">Login/Signup</div>
										</div>
										<div className="icons p-arrow pull-right"></div>
									</div>
								</div>
							}
							{/*
							<div className="switch-host"  onClick={this.downloadAppOpen}>
								<span className="switch-icon"></span>
								<span className="text">Switch to host account</span>
								<span className="icons p-arrow-green pull-right"></span>   						
							</div>
							*/}
						</div>
						<div className="col-12">
							<ul className="list">
							{hasAccessToken()
								&&
								<li>
									<Link to='/user/wishlist'>
										<span className="icon wishlist"></span>
										<span className="text">Wishlist</span>
										<span className="icons p-arrow-gray pull-right"></span>
									</Link>
								</li>
							}
							{hasAccessToken()
								&&
								<li>
									<Link to="/user/wallet">
										<span className="icon wallet"></span>
										<span className="text">Wallet</span>
										<span className="icons p-arrow-gray pull-right"></span>
									</Link>
								</li>
							}
							{hasAccessToken()
								&&
								<li onClick={() => this.referEarnOpen()}>
	                                <Link to="#">
									<span className="icon refer"></span>
									<span className="text">Refer & Earn</span>
									<span className="icons p-arrow-gray pull-right"></span>
	                                </Link>
								</li>
								}
	                           {/*
								<li>
									<Link to='#'>
										<span className="icon add-pro"></span>
										<span className="text">Host with us</span>
										<span className="icons p-arrow-gray pull-right"></span>
									</Link>
								</li>
							
								<li>
									<Link to='#'>
										<span className="icon work"></span>
										<span className="text">How it works</span>
										<span className="icons p-arrow-gray pull-right"></span>
									</Link>
								</li>
								*/}
								<li>
									<Link to='/support?fromAccount=true'>								
									<span className="icon help"></span>
									<span className="text">Need help</span>
									<span className="icons p-arrow-gray pull-right"></span>	
									</Link>							
								</li>
	                        
								<li>
									<Link to="/settings">
										<span className="icon setting"></span>
										<span className="text">Settings</span>
										<span className="icons p-arrow-gray pull-right"></span>
									</Link>
								</li>
							</ul>
						</div>
					</div>
					<div className="row">
						<div className="col-12">
							<div className="app-wrap">
								<div className="heading">Download the app now</div>
								<div className="dowload-image">
				                	<div className="apple"><a href="https://itunes.apple.com/in/app/guesthouser/id962240144?mt=8" className="apple"> </a></div>
				                	<div className="google"><a href="https://play.google.com/store/apps/details?id=com.guesthouser" className="google"> </a></div>
				              	</div>
							</div>
						</div>
					</div>
	                <DownloadApp downloadAppOpen={this.props.profile.downloadAppOpen} downloadAppClose={this.downloadAppClose}/>
	                <ReferEarn error={this.props.error} goBack={this.goBack} updateErrorState={this.props.updateErrorState}  referEarnModal={this.props.profile.referEarn}/>
				</div>
			
			</div>
        );
    }
}
const mapStateToProps = (store) => {
	return {
		signup : store.signupEmail,
		profile : store.userProfile,
		tracker: store.tracker.tracker,
		error: store.error
    }
}
const mapDispatchToProps = {
	updateProfileStateObj,
	updateErrorState,
	updateHomeState

}
export default connect(mapStateToProps, mapDispatchToProps) (Account);