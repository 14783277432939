import React from 'react';
import { connect } from 'react-redux';

class PropertTags extends React.Component{
	render(){
		return(
			<section className="property-tags clearfix">
                <h3>Tags and USP</h3>
                        <div  className="tags-list clearfix">
                            <ul>
                                {this.props.tags.map(function(item, index){
                                return(
                                    <li key={index} className={item.class}><i></i> {item.text}</li>
                                  )
                                })}
                            </ul>
                        </div>
                        
                {this.props.usp && <p>{this.props.usp}</p>}
                
            </section>
		);
	}
}

const mapStateToProps = (store) => {
    return {
        tags: store.property.tags,
        usp: store.property.usp
    }
}
export default connect(mapStateToProps,null)(PropertTags);