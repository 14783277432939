import React from 'react';
import {tileRatingColor} from '../../services/index.js';
import { connect } from 'react-redux';
import { withRouter } from 'react-router';
import { updateProfileStateObj} from '../../actions/profile/actions';



class PropertyReviews extends React.Component{
	constructor(){
		super();
		this.toUserProfile = this.toUserProfile.bind(this);
	}

    decimaltrim(data){
        return Number.parseFloat(data).toFixed(1);
	}

	toUserProfile(data) {
		let urlParams = '?host_id=' + data.traveller_id+ '&traveller_name=' + data.traveller_name;

		this.props.history.push({
			pathname: '/user/profile/',
			search: urlParams,
		});

		this.props.updateProfileStateObj({
			profile_image:data.traveller_image,
			first_name:data.traveller_name,
			is_host:1
		})

		let offsetreview = document.getElementById("filterModal").scrollTop;
		sessionStorage.setItem('allReviewScroll', offsetreview);
	}

	render(){
		const this_el = this
		return(
			<div className="property-reviews">
				<ul>
				 {this.props.reviews.map(function(item, index){
					if(index < 2){
						return(
							<li key={index}>
								<div className="review-header clearfix">
									<div className="col-9 pull-left" onClick={(e) => this_el.toUserProfile(item)}>
										<span className="user-pic" style={{backgroundImage:'url('+item.traveller_image+')'}}></span>
										<span className="user-name">{item.traveller_name} <span>{item.review_date}</span></span>
									</div>
									<div className="col-3 pull-left">
										<span className={'rating ' + tileRatingColor(item.property_rating)}>{this_el.decimaltrim(item.property_rating)}</span>
									</div>
								</div>
								<div className="review-content">
									<p style={{WebkitBoxOrient: 'vertical'}}>{item.comment}</p>
								</div>
								{item.nights > 0 &&
								<span className='stay-duration'>- Stayed for {item.nights} {item.nights > 1 ? 'nights' : 'night'}</span>
								}
							</li>
						)
						}else {
							return false
						}
					})}                      
				</ul>
			</div>
		);
	}
}


const mapStateToProps = (store) => {
	return {
		reviews: store.property.reviews
	}
}

const mapDispatchToProps = {
	updateProfileStateObj
}


export default connect(mapStateToProps, mapDispatchToProps)(withRouter(PropertyReviews));