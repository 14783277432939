import React from 'react';
import Modal from 'react-modal';

class LogOutModal extends React.Component {
    constructor(props){
		super(props);
    }
  render() {
    return (
      <div>
        <Modal
          isOpen={this.props.logOutModalOpen}
          contentLabel="Modal"
                overlayClassName={{
                base: 'overlay-modal overlay-popup',
                afterOpen: '',
                beforeClose: ''
              }}
          className={{
                base: 'content-modal',
                afterOpen: 'editProfile',
                beforeClose: ''
              }}

        >
          <div className="modal-body text-center"> 
              <a className="back-btn" onClick={this.props.close}> </a>
              <div className="text-center varificationEmail">Are you sure you want to log out?</div>
              <button className="link green" onClick={this.props.logOut}>Log out</button>
          </div>
        </Modal>
      </div>
    );
  }
}

export default LogOutModal;