import React from 'react';
import { connect } from 'react-redux';
import { updateSearchState } from '../../actions/search/actions';
import {getObjectVal} from './../../../src/services/helpers';

class PropertyBottomOption extends React.Component{
    constructor(props){
        super(props);
        this.renderUserMessage= this.renderUserMessage.bind(this);
    }
    openSelectGuestsModal(){
        let flag = this.props.search.flag;
        flag.modal.select_guest = true;
        flag.property_preview = true;
        flag.prepayment = false;
        this.props.updateSearchState({
            flag: flag
        });
    }

    openDateRangeCalendar(){
        let flag = this.props.search.flag;
        flag.modal.date_range = true;
        flag.property_preview = true;
        flag.prepayment = false;
        this.props.updateSearchState({
            flag: flag
        });
    }

    renderUserMessage(){
        if(this.props.bookable === 1){

            if (this.props.checkin_date !== 1 && this.props.unit_count > 0){
                return (
                    <button onClick={() => this.props.toPrepayment(this.props.hashId)} className="btn pull-right"><span className="reserve-icon"></span> {this.props.footer_data.footer.button_text}</button>

                    )
                }
            else if (this.props.unit_count === 0 && this.props.checkin_date !== 1){
                return (
                        <button  className="btn pull-right" onClick={() => this.openSelectGuestsModal()}>Add Room</button>
                    )
                }
            else {
                return (
                    <button className='btn pull-right' onClick={() => this.openDateRangeCalendar()}>{this.props.footer_data.footer.button_text}</button>
                )
            }
        }else {
            return (
                <button className='btn pull-right disable' onClick={() => this.openDateRangeCalendar()}>{this.props.footer_data.footer.button_text}</button>
            )
        }

    }
	render(){
        const this_el = this;
        if(this.props.apiData){
    		return(
    			<div className="property-bottom clearfix">
                    {
                        getObjectVal(this.props.footer_data, 'footer')
                        &&
                            <div>
                                <div className="col-6 pull-left"><h5>{this.props.footer_data.footer.title}<span> {this.props.footer_data.footer.sub}</span></h5></div>
                                <div className="col-6 pull-left">
                                    {this_el.renderUserMessage()}
                                </div>
                            </div>

                    }
                </div>
    		);
        }else{
            return (<div></div>);
        }
	}
}

const mapStateToProps = (store) => {
    return {
        footer_data : store.property.footer_data,
        bookable : store.property.bookable,
        apiData: store.property.flag.api.is_loading,
        checkin_date : store.booking.checkin_date,
        checkout_date : store.booking.checkout_date,
        unit_count : store.booking.unit_count,
        hashId: store.property.property_hash_id,
        search:store.search

    }
}

const mapDispatchToProps = {
    updateSearchState
}

export default connect(mapStateToProps,mapDispatchToProps)(PropertyBottomOption);
