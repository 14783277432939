import React from 'react';

class LoadingAnimation extends React.Component{

	render(){
		return(
			<div className='loader active'>
				<div className="timeline-item">
					<div className="animated-background facebook">
						<div className="background-masker after-image"></div>
						<div className="background-masker after-heading"></div>
						<div className="background-masker after-heading-right"></div>
						<div className="background-masker after-subheading-right"></div>
					</div>
				</div>
				<div className="timeline-item">
					<div className="animated-background facebook">
						<div className="background-masker after-image"></div>
						<div className="background-masker after-heading"></div>
						<div className="background-masker after-heading-right"></div>
						<div className="background-masker after-subheading-right"></div>
					</div>
				</div>
			</div>
		);
	}
}


export default LoadingAnimation;