import React from 'react';
import Modal from 'react-modal';

class CurrencyChangedModal extends React.Component {
  render() {
	return (
	  <div>
		<Modal
		  isOpen={this.props.CurrencyChangedModalOpen}
		  onAfterOpen={this.afterOpenModal}
		  onRequestClose={this.props.CurrencyChangedClose}
		  contentLabel="Modal"
				overlayClassName={{
				base: 'overlay-modal overlay-popup',
				afterOpen: '',
				beforeClose: ''
			  }}
		  className={{
				base: 'content-modal',
				afterOpen: 'editProfile',
				beforeClose: ''
			  }}

		>
		  <div className="modal-body text-center"> 
			  <div className="header-btn back-btn" onClick={this.props.CurrencyChangedClose}> </div>
			  <div className="text-center varificationEmail">your currency preference has been updated</div>
			  <button className="link green" onClick={this.props.CurrencyChangedClose}>Done</button>
		  </div>
		</Modal>
	  </div>
	);
  }
}

export default CurrencyChangedModal;