import React from 'react';
import {Link} from 'react-router-dom';
import RecentlyViewed from '../../components/home/homeRecentlyViewed.js';
import VarifyEmailModal from "../../components/modal/editProfile/varifyEmail.js";
import noInternet from "../../static/img/infoWindow/noInternet.svg";
import {connect} from 'react-redux';
import { updateProfileStateObj} from '../../actions/profile/actions';
import { updateErrorState } from '../../actions/error/actions';
import serverError from "../../static/img/serverError.png";

//services
import {getLoginAccountDataApi,SignupLoginFacebook, saveUrlLogin, getURLParameter, getVarifyEmailApi, hasAccessToken, addClassToHtml, removeClassToHtml} from '../../services/index.js';

import {trackPageView,trackError} from '../../services/tracker/events.js';
import { checkIfOffline } from '../../services/helpers.js';
import OfflineStatus from '../../components/Offlinestatus/index.js';

class Profile extends React.Component {
	constructor(props) {
        super(props);
        this.page="profile";
        this.state = {
			'host_id': getURLParameter(this.props.location.search, 'host_id'),
			'network':true,
			'apiData':false,
			'isTop':true,
        };
        
        this.dateFormat = this.dateFormat.bind(this);
        this.dob = this.dob.bind(this);
        this.getAge = this.getAge.bind(this);
        this.getProfileApiDataSuccess = this.getProfileApiDataSuccess.bind(this);
        this.getProfileApiDataError = this.getProfileApiDataError.bind(this);
        this.getVarifyEmailApiDataSuccess =this.getVarifyEmailApiDataSuccess.bind(this)
        this.getVarifyEmailApiDataError =this.getVarifyEmailApiDataError.bind(this)
        this.submitEamilVarifyDetail =this.submitEamilVarifyDetail.bind(this)
        this.VarifyEmailModalOpen=this.VarifyEmailModalOpen.bind(this)
        this.handleSubmit = this.handleSubmit.bind(this);
        this.signupLoginFacebookApiDataError=this.signupLoginFacebookApiDataError.bind(this);
        this.signupLoginFacebookApiDataSuccess=this.signupLoginFacebookApiDataSuccess.bind(this);
        this.responseFacebook=this.responseFacebook.bind(this);
        this.toProperty = this.toProperty.bind(this);
        this.goBack = this.goBack.bind(this);
        this.editFinalList=this.editFinalList.bind(this);
		this.modalRouting = this.modalRouting.bind(this);
		this.closeErrorMessage_modal = this.closeErrorMessage_modal.bind(this);
		this.reload = this.reload.bind(this);

    }
    getProfileApiDataSuccess(response) {
		const this_el = this;

		this.props.updateErrorState({ flag: { network: false } });

		let is_default_pic = this.props.profile.flag.is_default_pic;
		if(response.data.data.hasOwnProperty('is_avatar_image')){
			if(response.data.data.is_avatar_image === 1 || response.data.data.is_avatar_image === true  || response.data.data.is_avatar_image === "true"){
				is_default_pic = true;
			}
		}
		this.props.updateProfileStateObj({
			'first_name': response.data.data.first_name,
			'last_name': response.data.data.last_name,
			'member_since': response.data.data.member_since,
			'dob': response.data.data.dob,
			'marital_status': response.data.data.marital_status,
			'gender': response.data.data.gender,
			'profession': response.data.data.profession,
			'email': response.data.data.email,
			'is_email_verified':response.data.data.is_email_verified,
			'dial_code': response.data.data.dial_code,
			'mobile': response.data.data.mobile,
			'is_mobile_verified':response.data.data.is_mobile_verified,
			'facebook_id': response.data.data.facebook_id,
			'profile_image': response.data.data.profile_image,
			'spoken_languages': response.data.data.spoken_languages,
			'description':response.data.data.description,
			'travelled_places': response.data.data.travelled_places,
			'guests_served_count': response.data.data.guests_served_count,
			'trips_count': response.data.data.trips_count,
			'property_listings':response.data.data.property_listings,
			'is_user_referred': response.data.data.is_user_referred,
			'user_id': response.data.data.user_id,
			'user_hash_id':response.data.data.user_hash_id,
			'is_default_pic' : is_default_pic,
			'host_name':response.data.data.host_name,
			'apiData':true,
			'is_host': parseInt(response.data.data.is_host),
			extendedDataLoaded: true
		}).then(() => {
			localStorage.setItem("is_user_referred", this.props.profile.is_user_referred);
			let error_flag = Object.assign({}, this_el.props.error.flag);
			error_flag.network = false;
			error_flag.server = false;
			this_el.props.updateErrorState({	
				flag: error_flag
			});
		});
    }

    getProfileApiDataError(error) {
		const this_el = this;
		
		if(typeof error.response !== 'undefined' && error.response.status === 1000){
			let error_flag = Object.assign({}, this_el.props.error.flag);
			error_flag.network = true;
			
			// updating network state
			this_el.props.updateErrorState({
				flag : error_flag
			});
			
		}else if(error.response.status === 500){
			//console.log('dgdg',error);
			let flag =  this.props.error.flag;
			flag.server = true; 
			this.props.updateErrorState({ flag: flag });
			
		} else {
			
			if(typeof error.response.data !== "undefined" && error.response.data.error.length > 0 ){
				
				let a = error.response.data.error.map(function (msg){
					return msg.message
				})
				
				this_el.props.updateErrorState({
					message: a.join(', ')
				}).then(() => {
					this.openErrorMessage_modal();
				});
			}else{
				trackError(error);
			}
		}

    }


    getHostProfileApiData(data){
		let this_el = this;
		if ((!this.props.profile.extendedDataLoaded || !this.props.error.flag.network) && (this.state.host_id !== this.props.profile.user_hash_id) ) {
			getLoginAccountDataApi(data).then(function(response){
				// success
				this_el.getProfileApiDataSuccess(response);
			}).catch(function(error){
				// error
				this_el.getProfileApiDataError(error);
			});
		}
    }

    componentDidMount() {
    	window.scrollTo(0,0);
        // if(hasAccessToken()){
        //     this.getHostProfileApiData(this.state.host_id); 
		// }
		if (checkIfOffline()) {
			this.props.updateErrorState({
				flag: {
					...this.props.error.flag,
					network: true
				}
			})
		} else {
			this.props.updateErrorState({
				flag: {
					...this.props.error.flag,
					network: false
				}
			})
		}
        document.addEventListener('scroll', () => {
          const isTop = window.scrollY < 228;
          if (isTop !== this.props.profile.isTop) {
              this.props.updateProfileStateObj({ isTop})
            }
        });
        document.body.style.overflow = 'auto';
        document.body.style.position = 'inherit';

        const this_el = this;
        window.onpopstate = function(e) {
            this_el.modalRouting(e.currentTarget.location.hash);
        };
        this.modalRouting(window.location.hash);
        trackPageView(this.props.tracker, this.page);
    }
    dateFormat(member_since){
		let inputDate = member_since.split('-');
		let monthNum = inputDate[1];
		let years = inputDate[2];
		const months = ["Jan", "Feb", "Mar", "Apr", "May", "Jun","Jul", "Aug", "Sep", "Oct", "Nov", "Dec"];
		return (months[monthNum-1] + ' '+ years);
	}
	 dob(date_of_birth){
		let inputDate = date_of_birth.split('-');
		let monthNum = inputDate[1];
		let years = inputDate[2];
		const months = ["Jan", "Feb", "Mar", "Apr", "May", "Jun","Jul", "Aug", "Sep", "Oct", "Nov", "Dec"];
		return (inputDate[0] + ' ' + months[monthNum-1] + ' '+ years);
	}
	getAge(dob) {
        var months = ["Jan", "Feb", "Mar", "Apr", "May", "Jun","Jul", "Aug", "Sep", "Oct", "Nov", "Dec"];

	    var dates = dob.split("-");
	    var d = new Date();

	    var userday = dates[0];
	    var useryear = dates[2];
        var monthNum = months.indexOf(dates[1]) + 1;

	    var curday = d.getDate();
	    var curmonth = d.getMonth()+1;
	    var curyear = d.getFullYear();

	    var age = curyear - useryear;

	    if((curmonth < monthNum) || ( (curmonth === monthNum) && curday < userday   )){
	        age--;
	    } 
	    if(this.props.profile.dob === ''){
	    	return null;
	    }else{
	    	return age;
	    }
	}

    handleSubmit(event) {
        this.submitEamilVarifyDetail({
            'email': this.props.profile.email,
        });
    }

    submitEamilVarifyDetail(args){
        let this_el = this;
        getVarifyEmailApi(args).then(function(response){
            // success
            this_el.getVarifyEmailApiDataSuccess(response);
        }).catch(function(error){
            // error
            this_el.getVarifyEmailApiDataError(error);
        });
    }

    getVarifyEmailApiDataError(error) {
        console.log(error);

    }
    
    getVarifyEmailApiDataSuccess(response) {
		const this_el = this;
		let msg = response.data.data.message;
		this.props.updateProfileStateObj({'message':msg }).then(() => {
			console.log(msg);
			
			//console.log(this.props.profile.message);
			this_el.VarifyEmailModalOpen();
		});
    }

    VarifyEmailModalOpen(){
        window.location.hash = 'VerifyEmail';
    }

    componentWillMount(){
		this.props.updateProfileStateObj({loading:false});
        this.auth();
    }
	
    auth(){
        if(!hasAccessToken() && !this.state.host_id){
            this.props.history.push('/account');
        }else {
            this.getHostProfileApiData(this.state.host_id); 
        }
    }
    responseFacebook(response){    
        //console.log("Facebook Login Response \n", response);
        if(response.hasOwnProperty("accessToken") && response.hasOwnProperty("email")){
            this.signupLoginFacebookDetail({
               'access_token':response.accessToken,
               'source':'2'
            });
        }
        else{
            this.props.updateProfileStateObj({
                'message': "Issue with facebook login",
                'status': 'error'
            });
        }
        
    }
    signupLoginFacebookDetail(args){
        let this_el = this;
        SignupLoginFacebook(args).then(function(response){
            // success
            this_el.signupLoginFacebookApiDataSuccess(response);
        }).catch(function(error){
            // error
            this_el.signupLoginFacebookApiDataError(error);
        });
    }

    signupLoginFacebookApiDataError(error) {
        this.props.updateProfileStateObj({
            'message': error.response.data.error,
            'status': 'false'
        });
    }

    signupLoginFacebookApiDataSuccess(response) {
        //console.log(response);
    }

    goBack =() =>{
		const this_el =this;
		if(this.props.profile.profilePage){
			this.props.history.push('/account');
		}else{
			this.props.history.goBack();
			}
	    }

    toProperty(data,index){
            this.props.history.push({
                pathname: '/property/'+data.property_hash_id,
                state : this.state,
                data : {
                    pathname : this.props.location.pathname,
                    search : this.props.location.search,
                    tileData:data,
                    index:index,
                }
            });       
    }
    editFinalList(index){
        let finalList = this.props.profile.property_listings;
        if(finalList[index].is_liked_by_user === 1){
            finalList[index].is_liked_by_user = 0;
        } else {
            finalList[index].is_liked_by_user = 1;
        }
        this.props.updateProfileStateObj({
            property_listings:finalList,
        })
    }

    modalRouting(hash){
        addClassToHtml();
        switch(hash){
            case '#recentlyViewed':
                this.props.updateProfileStateObj({recentViewModal: true});
                break;

            case '#VerifyEmail':
                this.props.updateProfileStateObj({VarifyEmail: true});
                break;

            default:
                this.props.updateProfileStateObj({
                    recentViewModal:false,
                    VarifyEmail:false,
                });
                removeClassToHtml();
        }
	}
	openErrorMessage_modal(){
		this.refs.errorMessage_modal.classList.add('true');
	}
	closeErrorMessage_modal(){
		this.refs.errorMessage_modal.classList.remove('true');
		this.props.history.goBack();
	}
	changeImage(e){
        let imgSrc = e.target.src;
        e.target.nextElementSibling.style.backgroundImage = 'url('+imgSrc+')';

	}

	reload() {
		this.getHostProfileApiData(this.state.host_id);
	}


    render() {
    	let profile_image_new_upload_class = 'profile-cover';

		if(this.props.profile.flag.is_default_pic){
				profile_image_new_upload_class = 'default-pic';
		}
		const elipsis={
			textOverflow: 'ellipsis',
			overflow:'hidden',
			WebkitBoxOrient: 'vertical',
		}
         return (
            <div className="pro">
            {!this.props.error.flag.network || this.props.profile.extendedDataLoaded ?
			<div className="profile-section">
				{!this.props.error.flag.server ?
						<div>
					<OfflineStatus 
						offline={checkIfOffline()}
						bottom
					/>
					<header className={this.props.profile.isTop ? 'header' : 'header active'}>
                   
						<div className="row">
							<div className="col-2 text-left"><div className="back-icon" onClick={this.goBack}></div></div>
							<div className="col-8 text-center">
                                <div className="title">{this.state.host_id ? this.props.profile.first_name + '\'s profile'  : 'My profile'}</div>
                            </div>
                            {!this.state.host_id &&
                                <Link to="/user/editProfile">
    								<div className="col-2 text-right"><div className="profileEdit-icon pull-right"></div></div>
    							</Link>
                            }
						</div>
					</header>
					<img src={this.props.profile.profile_image} onLoad={(e) => this.changeImage(e)}  className='hidden'/>
	                <div className={"profile-pic " + profile_image_new_upload_class}  ></div>

	                <div className="profile-details">
	                	<div className="p_container">
	                		<div className="profile-name">
	        				{this.props.profile.first_name} {this.props.profile.last_name}{this.getAge(this.props.profile.dob) > 0 && ', ' +this.getAge(this.props.profile.dob) +' yrs'}
	                		</div>
	                		<div className="profile-duration">Member since {this.props.profile.member_since ? this.dateFormat(this.props.profile.member_since) : '_'}</div>
	                	</div>
	                	<div className="row">
		                	<div className="p_host">
		                		<div className="col-8">
			                		<div className="name">Guests hosted</div>
			                		<div className="number">{this.props.profile.guests_served_count}</div>
		                		</div>
		                		<div className="col-4 text-left">
		                			<div className="wrap">
			                			<div className="name">Trip completed</div>
			                			<div className="number text-left">{this.props.profile.trips_count}</div>
			                		</div>
		                		</div>
		                	</div>
	                	</div>
	                	<div className="row">
	                		<div className="pro-info">
	                			<div className="col-7">
	                				<div className="icon birth"></div>
	                				<div className="details">
	                					<div className="name">Date of birth</div>
	                					<div className="text">{this.props.profile.dob ? this.dob(this.props.profile.dob) : '_'}</div>
	                				</div>
	                			</div>
	                			<div className="col-5 text-left">
	                				<div className="p_details">
		                				<div className="icon marital"></div>
		                				<div className="details">
		                					<div className="name">Marital status</div>
		                					<div className="text">{this.props.profile.marital_status ? this.props.profile.marital_status : '_'}</div>
		                				</div>
	                				</div>
	                			</div>
	                			<div className="col-7">
	                				<div className="icon gender"></div>
	                				<div className="details">
	                					<div className="name">Gender</div>
	                					<div className="text">{this.props.profile.gender}</div>
	                				</div>
	                			</div>
	                			<div className="col-5 text-left">
	                				<div className="p_details">
		                				<div className="icon profession"></div>
		                				<div className="details">
		                					<div className="name">Profession</div>
		                					<div className="text" style={elipsis}>{this.props.profile.profession ? this.props.profile.profession : '_'}</div>
		                				</div>
	                				</div>
	                			</div>
	                		</div>
	                	</div>
	                	<div className="row">
	                		<div className="social-details">
		                		<div className="col-10">
		                			<div className="icon email"></div>
		            				<div className="details">
		            					<div className="name">Email address</div>
		            					{!this.state.host_id  ?
			            					<div className="varified-content">
		            							{this.props.profile.email ? this.props.profile.email : '_'}
		            						</div>
		            						:
		            						<div className={!this.state.host_id ? 'varified-content':'varified-content-green'}>
		            							{this.props.profile.email  && this.props.profile.is_email_verified ? 'Verified' : 'Not verified'}
		            						</div>
	            						}
		            				</div>
		            			</div>

		            			<div className="col-2 text-right">
	            					{this.props.profile.is_email_verified ?
	            						<div className="icons varify"></div>
	            						
	            					 	:
	            					 		<div>
		            					 		{!this.state.host_id  &&
				            					 	 <div onClick={this.handleSubmit}> 
				            							<span className="varify-text">Verify</span><span className="icons v-arrow" ></span>
				            						</div>
			            						}
		            						</div>
	            					}
		            			</div>
		            		</div>
                           
		            		<div className="social-details">
		                		<div className="col-10">
		                			<div className="icon phone-number"></div>
		            				<div className="details">
		            					<div className="name">Phone number</div>
		            						{!this.state.host_id  ?
			            						<div className="varified-content">
			            							{this.props.profile.mobile ? this.props.profile.mobile :'_'}
			            						</div>
			            						:
			            						<div className={!this.state.host_id ? 'varified-content':'varified-content-green'}>
			            							{this.props.profile.mobile &&  this.props.profile.is_mobile_verified ? 'Verified' :'Not verified'}
			            						</div>
		            						}
		            				</div>
		            			</div>
		            			
		            				<div className="col-2 text-right">
				            			{this.props.profile.mobile &&  this.props.profile.is_mobile_verified ?
				            				<div className="icons varify"></div>   
		                                     :
		                                     <div>
		            					 		{!this.state.host_id  &&
			                                    <Link to="/user/contact">  
			    		            				<div> 
			                							<span className="varify-text" onClick={saveUrlLogin}>Verify</span><span className="icons v-arrow" ></span>
			                						</div>
			                                    </Link>
			                                	}
			                                 </div>
				            			}
		            				</div>
		            		</div>
                            {/*
		            		<div className="social-details">
		                		<div className="col-9">
		                			<div className="icon facebook"></div>
		            				<div className="details">
		            					<div className="name">Facebook Account</div>
		            					{this.props.prprops.profile.is_host  ?
			            					<div className="varified-content">
		            							{this.props.profile.facebook_id ? this.props.profile.facebook_id : 'Connect facebook'}
		            						</div>
	            							:
		            						<div className="varified-content">
		            							{this.props.profile.facebook_id ? 'Verified' : 'Not verified'}
		            						</div>
	            						}
		            				</div>
		            			</div>
		            			<div className="col-3 text-right">
		            			{this.props.profile.facebook_id
		            			?
		            			<div className="icons varify"></div>
		            			: 
		            			<div>
        					 		{this.props.prprops.profile.is_host  &&
				            			<div>
				            				<span className="varify-text">
				            				<FacebookLogin
						                        appId={process.env.REACT_APP_FACEBOOK_CLIENT_ID}
						                        autoLoad={false}
						                        fields="name,email,picture"
						                        scope="public_profile,user_friends,user_actions.books"
						                        cssClass="linkP"
						                        textButton="Connect"
						                        callback={this.responseFacebook}
						                        redirectUri={this.props.profile.current_url}
						                    /> 
                    						</span><span className="icons v-arrow" ></span>
				            			</div>
			            			}
			            		</div>
		            			}
		            			</div>
		            		</div>
                        */}
	                	</div>
	                </div>
	            	<div className="row">
	        			<div className="col-12 wrap-more">
	        				<div className="heading">More about me</div>
	        				<div className="more-about">
		                		<div className="col-12">
		                			<div className="icon language"></div>
		            				<div className="details">
		            					<div className="name">Languages I speak</div>
		            					<div className="text">{this.props.profile.spoken_languages ? this.props.profile.spoken_languages : '_'}</div>
		            				</div>
		            			</div>
	            			</div>
	            			<div className="more-about">
		                		<div className="col-12">
		                			<div className="icon place"></div>
		            				<div className="details">
		            					<div className="name">Places I’ve travelled to</div>
		            					<div className="text">{this.props.profile.travelled_places ? this.props.profile.travelled_places : '_'}</div>
		            				</div>
		            			</div>
	            			</div>
	            			<div className="more-about">
		                		<div className="col-12">
		                			<div className="icon tidbits"></div>
		            				<div className="details">
		            					<div className="name">Interesting tidbits about me</div>
		            					<div className="text">{this.props.profile.description ? this.props.profile.description : '_'}</div>
		            				</div>
		            			</div>
	            			</div>
	        			</div>
	        		</div>
	        		{this.props.profile.property_listings.length > 0 &&
	        			<div className="recent-section property-listing">
							<RecentlyViewed 
                            tracker={this.props.tracker} 
                            myListing={true} 
                            editFinalList={this.editFinalList} 
                            goBack={this.goBack}/>
						</div>
	        		}
	        		
                    <VarifyEmailModal
                        VarifyEmailModalOpen={this.props.profile.VarifyEmail}
                        goBack={this.goBack}
                        email={this.props.profile.email}
                    />

					<div ref="errorMessage_modal" className='unavailable_date_overlay'>
						<div className="max_nights_alert">
							<div className="modal-body text-center">
								{/* <h2 className="text">Error!</h2> */}
								<div className='description'>
								<p>{this.props.error.message.toString()}</p>
								</div>
								<button className="btn" onClick={this.closeErrorMessage_modal}> Done</button>
							</div>
						</div>
					</div>


            </div>
			:
				<div className="info-window">
					<div className="window-container">
						<div className="imgb">
							<div className="img" style={{ backgroundImage: "url(" + serverError + ")" }}/>
						</div>
						<div className="txtb">
							<p>Sorry, our servers are busy. Please try again in a bit.</p>
						</div>
						<button className="retry" onClick={this.reload}>Retry</button>
					</div>
				</div>	
			}
			</div>
            :
            <div className="info-window">
				<header className={'header active'}>
                   
				   <div className="row">
					   <div className="col-3 text-left"><div className="back-icon" onClick={this.goBack}></div></div>
				   </div>
			   </header>
                <div className="window-container" style={{ marginTop: '40px' }} >
                    <div className="imgb">
                        <div className="img" style={{backgroundImage:'url(' + noInternet + ')'}}></div>
                    </div>
                    <div className="txtb"><p>Oops! You don't seem to be connected to the internet.</p></div>
                    <button className="retry" onClick={this.reload}>Retry</button>
                </div>
            </div>
            }
            </div>
        );
    }
}
const mapStateToProps = (store) => {
	return {
		profile : store.userProfile,
		tracker: store.tracker.tracker,
		error: store.error
    }
}
const mapDispatchToProps = {
	updateProfileStateObj,
	updateErrorState
}
export default connect(mapStateToProps, mapDispatchToProps) (Profile);


				