import React from 'react';
import Modal from 'react-modal';
import ResponseMessage from '../../../components/response/';
import {getCancelBookingRequestDataApi} from '../../../services/index.js';
import { connect } from 'react-redux';
import { updateTripState } from '../../../actions/trip/actions';
import { updateRequestState } from '../../../actions/request/actions';
import { withRouter } from "react-router-dom";


class CancelModal extends React.Component {
	constructor(props){
		super(props);
		this.state = {
			textAreaActive: false,
			reason_id:4,
			reason_detail:'',
			network:true,
			message:'',
			show:false,
			chars_left: 120,
			max_char:120
		};
    	this.reason_detailChange =this.reason_detailChange.bind(this);
		this.getCancelBookingRequestApi = this.getCancelBookingRequestApi.bind(this);
		this.getCancelBookingRequestApiDataError = this.getCancelBookingRequestApiDataError.bind(this);
		this.getCancelBookingRequestApiDataSuccess = this.getCancelBookingRequestApiDataSuccess.bind(this);
		this.handleSubmit= this.handleSubmit.bind(this);
		this.resetMessages = this.resetMessages.bind(this);
		this.cancellationReasonRef =[];
		this.afterCancelModalOpen = this.afterCancelModalOpen.bind(this);
	}

	resetMessages(){
        this.setState({
            show:false,
            message:'',
        });
    }
	click(e) {
		//console.log(e.target.value)
		this.setState({
            'reason_id': e.target.id,
            'reason_detail':e.target.value.trimLeft()
     	})
		if(e.target.id === '4' || e.target.id === '8'){
			//console.log(this.state.textAreaActive);
	        this.setState({textAreaActive: true});
		} else {
	        this.setState({textAreaActive: false});
		}
    }
    reason_detailChange(value){
    	const charCount = value.length;
        const maxChar = this.state.max_char;
        const charLength = maxChar - charCount;

    	this.setState({
            'reason_detail': value.trimLeft(),
            'chars_left': charLength,
        },function(){
        });
    }
    handleSubmit(event) {
    	const this_el = this;
    	let hitApi = false;
    	let error_msg = '';
		const other_textarea_ele = this_el.refs.other_textarea;
    	this_el.cancellationReasonRef.map((sRef) => {
    		if(sRef.checked){
    			if((sRef.id === '4' || sRef.id === '8')){
					console.log(other_textarea_ele.value.length);
    				if(other_textarea_ele !== undefined && other_textarea_ele.value.length > 1){
    					hitApi = true;
    				}
    				else{
    					error_msg = 'Please type your input';
    				}
    			} else{
    				hitApi = true;
    			}
			} 
			return false;
    	});

    	if(!hitApi){
    		this_el.setState({
				message : (error_msg) ? error_msg : 'Please select reason to cancel .',
				show : true,
			});
    		return;
    	} 

		this.getCancelBookingRequestApi({
			request_hash_id: this.props.info.request_hash_id,
			request_status: this.props.booking_status.status,
			reason_id:this.state.reason_id,
			reason_detail:this.state.reason_detail,
		});  
		
	}
	
	getCancelBookingRequestApiDataSuccess(response) {
		if(this.props.gotoRequest){
			this.props.cancelModalClose();
			this.props.cancelRequest();
			this.props.updateRequestState({}, 1); // refresh requests store for new user
			this.props.updateRequestState({ RdataLoaded: false });// refresh request store when cancel requests
		}
		if(this.props.gotoTrip){
			this.props.history.goBack();
			this.props.cancelRequest();
			this.props.canceledSuccessOpen(response.data.data.message);
			this.props.updateTripState({}, 1); // refresh trips store for new user
			this.props.updateTripState({ tripDataLoaded: false });// refresh trip store when cancel trips
		}
	}

	getCancelBookingRequestApiDataError(error) {
		//console.log(error.response);
		if(typeof error.response !== 'undefined' && error.response.status === 1000){
			// updating network state
			this.setState({
				'message': 'Please check your internet connection',
				'network':false,
				'show':true,
			})
			
		} else {
			this.setState({
				'message': error.response.data.error[0].message,
				'network':false,
				'show':true,
			})
		}
	}
	getCancelBookingRequestApi(args){
		let this_el = this;
		getCancelBookingRequestDataApi(args).then(function(response){
			// success
			this_el.getCancelBookingRequestApiDataSuccess(response);
		}).catch(function(error){
			// error
			this_el.getCancelBookingRequestApiDataError(error);
		});
	}
	afterCancelModalOpen(){
        this.setState({textAreaActive: false});
	}

  render() {
		const this_el=this;
	return (
	  <div>
		<Modal
		  isOpen={this.props.cancelModalOpen}
		  onAfterOpen={this.afterCancelModalOpen}
		  onRequestClose={this.props.cancelModalClose}
		  contentLabel="Modal"
			  overlayClassName={{
				base: 'overlay-modal',
				afterOpen: '',
				beforeClose: ''
			  }}
		  className={{
				base: 'content-modal',
				afterOpen: 'full-screen-modal cancel-modal',
				beforeClose: ''
			  }}

			>
		  <div className="modal-body">
			<div className="modal-header">
				<div className="header-btn close-btn" onClick={() => this.props.cancelModalClose()}></div>
			</div>
			<div className="heading">Please select a reason to cancel ?</div>
			<div className="list">
			{this.props.cancellation_reasons.map(function(item, index){
					return(
						<label key={index} className="bullet" >{item.reason_title}
							<input type="radio"   
								id={item.id} 
								value={item.reason_title} 
								onClick={(e) => this_el.click(e)} name="radio"
								ref={(ref) => {this_el.cancellationReasonRef[item.id] = ref}}
								/>
							<span className="checkmark"></span>
						</label>
					)
				})
			}
			</div>
			<div className="footer-wrap text-center">
				<div className="textarea-wrap">
					<textarea ref="other_textarea"   value={this.state.reason_detail === "Other" ? '' : this.state.reason_detail} placeholder="Please tell us what went wrong?" className={"textarea " + this.state.textAreaActive} onChange={ e => this.reason_detailChange(e.target.value)}>Please tell us what we went wrong?</textarea>
					{/* <div className={'word-limit ' + this.state.textAreaActive}>{this.state.chars_left} Characters limit</div> */}
				</div>
				<button className="button" onClick={this.handleSubmit}>{this.props.gotoRequest ? 'Cancel request' : 'Cancel booking'}</button>
			</div>
			<div className={'responseAPI '+this.state.show }>
                <ResponseMessage responseMessage={this.state.message} responseStatus={this.state.status} resetMessages={this.resetMessages}/>
            </div>
		  </div>
		</Modal>

	  </div>
	);
  }
}

const mapDispatchToProps = {
	updateRequestState,
	updateTripState
}

export default withRouter(connect(null, mapDispatchToProps) (CancelModal));