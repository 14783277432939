import React from 'react';
import Loading from '../../static/img/loading.gif';

// services
import {getEmailVarifySuccessApi, getURLParameter} from '../../services/index.js';

class EmailVarifySuccess extends React.Component{
  constructor(props){
        super(props);
        this.state = {
            confirmation_code:getURLParameter(this.props.location.search, 'code'),
            message:'',
            status:'',
            isLoading:true
        }   
        this.handleSubmit = this.handleSubmit.bind(this);  
        this.submitEamilVarifySuccessDetail = this.submitEamilVarifySuccessDetail.bind(this);  
        this.getVarifyEmailSuccessApiDataError=this.getVarifyEmailSuccessApiDataError.bind(this);
        this.getVarifyEmailSuccessApiDataSuccess=this.getVarifyEmailSuccessApiDataSuccess.bind(this);
    }
    handleSubmit(event) {
        this.submitEamilVarifySuccessDetail({
            'confirmation_code': this.state.confirmation_code,
        });
    }
    submitEamilVarifySuccessDetail(args){
        let this_el = this;
        getEmailVarifySuccessApi(args).then(function(response){
            // success
            this_el.getVarifyEmailSuccessApiDataSuccess(response);
        }).catch(function(error){
            // error
            this_el.getVarifyEmailSuccessApiDataError(error);
        });
    }

    getVarifyEmailSuccessApiDataError(error) {
        this.setState({
            'message': error.response.data.error.message,
            'status': error.response.data.status,
        });
    }
    
    getVarifyEmailSuccessApiDataSuccess(response) {
        this.setState({
            'message': response.data.data.message,
            'isLoading':false
        });
    }
    render() {
        const loader ={
            width: '100%',
            textAlign: 'center',
            verticalAlign: 'middle',
            height: '100%'
        }
        const loadingImg={
            transform: 'translate(-50%, -50%)',
            top: '50%',
            position: 'absolute',
            left: '50%'
        }
        return (
            <div onLoad={this.handleSubmit}>
            {this.state.isLoading?
                <div style={loader}>
                    <img style={loadingImg} src={Loading} alt='loader'/>
                </div>
                :
                <div>
                    {this.state.message}
                </div>
             }
            </div>
        );
    }
}

export default EmailVarifySuccess;