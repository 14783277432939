

/* APIs */
// get get payment link instant book
let getRequestPaymentDataApi = function (data, args)
{

	let discount = {};
	if(args.discount_type === "wallet"){
		
		discount = {'apply_wallet': args.apply_wallet};

	}else if(args.discount_type === "coupon"){
		discount = {'coupon_code': args.coupon_code};
	}else if(args.discount_type === ""){
		discount = {'apply_wallet': 0};
	}
//console.log(args);
	return data.AXIOS.put(data.BASE_URL_V+'/booking/request/'+args.request_hash_id, data.QS.stringify(Object.assign(discount,{
			// request_hash_id: args.request_hash_id,
			payment_method:args.payment_method,
			payable_amount:args.payable_amount
		})), {
			headers: {
				'Accept': 'application/json',
				'Content-Type': 'application/x-www-form-urlencoded',
				'device-unique-id': data.DEVICE_UNIQUE_ID,
				'Authorization' : 'Bearer ' + data.ACCESS_TOKEN,
			}
		}
	);
}

exports.getRequestPaymentDataApi = getRequestPaymentDataApi;