import React from 'react';
import {dateFormat} from '../../services/index.js';
import {withRouter} from 'react-router';
import {connect} from 'react-redux';

/**
 * Actions
 */

import {updateSearchState} from './../../actions/search/actions';
import {updateBookingDataState} from '../../actions/booking-data/actions'


class SearchHeader extends React.Component {
	constructor(props){
		super(props);

		this.openModal =  this.openModal.bind(this);
	}

	openModal(){

		const this_el = this;
		let flags = Object.assign({}, this_el.props.search.flag);
		if(this_el.props.booking.checkin_date === 1){
			flags.modal.date_range = true;
		}else{
			flags.modal.select_guest = true;
		}

		this_el.props.updateSearchState({
			flag : flags
		});

	}

	goBack = () => {
		this.props.goBack(() => {
			if (navigator.userAgent.match('CriOS')) {
				this.props.history.replace('/');
				return
			}
			window.history.go(-1);
			if (
				window.location.pathname === '/' ||
				(window.history.state && window.history.state.key)
			) {
				return;
			}
			if (this.timer) {
				clearTimeout(this.timer);
			}
			this.timer = setTimeout(() => {
			}, 100);
		});
	}

	render(){

		const this_el = this;
		return(
			<header className="header active">
					<div className="row">
					<div className="col-8 text-left">
						<span className="back-icon" onClick={this.goBack}></span>
						<h1>
							{ this_el.props.search.search_address_data.location && this_el.props.search.search_address_data.location.split(',')[0]}
							{this_el.props.booking.location !== null && this_el.props.booking.location.length > 0 &&
								<span>
									{this_el.props.booking.checkin_date && this_el.props.booking.checkin_date !== 1 && this_el.props.booking.checkout_date && this_el.props.booking.checkout_date !== 1
										? dateFormat(this_el.props.booking.checkin_date)+' - '+dateFormat(this_el.props.booking.checkout_date)+
											(this_el.props.booking.guest_count > 0 ? (', ' + this_el.props.booking.guest_count)+(this_el.props.booking.guest_count > 1 ? ' Guests' : ' Guest'):'')
										: 'Add dates & guests'

									}
									{/* {(this_el.props.booking.checkin_date === 1 || this_el.props.booking.checkout_date === 1) && 'Add dates & guests'} */}
								</span>
							}
						</h1>
					</div>
					<div className="col-4 text-left">
						<span className='modify-btn' onClick={this_el.openModal}>
							{
								this_el.props.booking.checkin_date && this_el.props.booking.checkin_date !== 1 && this_el.props.booking.checkout_date && this_el.props.booking.checkout_date !== 1  && this_el.props.booking.guest_count > 0
								?
									<i className='modify-icon'></i>
								:
									<i className='add-icon'></i>
							}
						</span>
					</div>
				</div>
			</header>
		)
	}
}

const mapStateToProps = store => {
	return {
		booking: store.booking,
		search : store.search
	};
};

const mapDispatchToProps = {updateSearchState, updateBookingDataState};

export default connect(mapStateToProps, mapDispatchToProps)(withRouter(SearchHeader));
