import React, {Component} from 'react';
import { tileRatingColor } from '../../services/index.js'
import { connect } from 'react-redux';
import { withRouter } from 'react-router';
import noInternet from "../../static/img/infoWindow/noInternet.svg";


//api
import {getPropertyReviewDataApi} from '../../services/index.js';
import { trackError } from '../../services/tracker/events.js';


// actions
import { updatePropertyState } from '../../actions/property/actions';
import { updateErrorState } from '../../actions/error/actions';
import { updateProfileStateObj } from '../../actions/profile/actions';
import { checkIfOffline } from '../../services/helpers.js';
import OfflineStatus from '../../components/Offlinestatus/index.js';

class AllReviews extends Component {
    state = {
        toggleOfflineStatus: false
    }

    getReviewListApi(args) {
        let this_el = this;
        getPropertyReviewDataApi(args).then((response) => {
            this_el.apiSuccessFunc(response);
        }).catch((error) => {
            this.apiErrorFunc(error);
        });
    }

    apiSuccessFunc(response){
        let flag = Object.assign({}, this.props.property.flag);
        flag.no_more_result = (response.data.data.reviews.length < 12); // if no more result flag found, set no_more_result to true
        flag.api.is_loading = false;

        let reviewList = Object.assign([], this.props.property.reviews_list);
        if (reviewList.length > 2){
            Array.prototype.push.apply(reviewList, response.data.data.reviews); 
        }else {
            reviewList = response.data.data.reviews
        }

        let networkFlag = Object.assign({}, this.props.error.flag);
        networkFlag.network = false;
        this.props.updateErrorState({
            flag: networkFlag
        });

        this.props.updatePropertyState({ 
            'reviews_list': reviewList,
            flag:flag
        });
    }

    apiErrorFunc(error) {
        const this_el = this;
        if (typeof error.response !== 'undefined' && error.response.status === 1000) {
            let flag = Object.assign({}, this_el.props.error.flag);
            flag.network = true;
            this_el.props.updateErrorState({
                flag: flag
            });
        } else {
            trackError(error);
        }
    }

    decimaltrim(data) {
        return Number.parseFloat(data).toFixed(1);
    }

    componentDidMount(){
        document.body.style.backgroundColor = '#fff';

        window.scroll(0, 0);

        this.submitParams();
        this.loadMoreResults();
    }
    componentWillUnmount(){
        document.body.removeAttribute('style');
    }
    loadMoreResults() {
        const this_el = this;

        window.onscroll = function () {
            let reviews_listing = false;
            if (document.getElementById('reviews_listing') !== null) {
                reviews_listing = true;
            } else {
                reviews_listing = false;
            }
            if (reviews_listing) {

                const scrollTopOffset = Math.max(window.pageYOffset, document.documentElement.scrollTop, document.body.scrollTop);

                let windowHeight = window.innerHeight;
                let bodyHeight = document.body.clientHeight - 260; // was 260;
                let offsetWindowHeight = windowHeight + scrollTopOffset;
                if ((offsetWindowHeight >= bodyHeight) && !this_el.props.property.flag.api.is_loading && !this_el.props.property.flag.no_more_result && !checkIfOffline()) {

                    /**
                     * Updating api state to true, so in case of scroll no more api hit called, untill last api result popup
                     */

                    let new_offset = this_el.props.property.reviewOffset + 12;
                    let flag = Object.assign({}, this_el.props.property.flag);
                    flag.api.is_loading = true;

                    this_el.props.updatePropertyState({
                        reviewOffset: new_offset,
                        flag: flag
                    }).then(() => {
                        this_el.submitParams();
                    });

                } 			
                if ((offsetWindowHeight >= bodyHeight) && checkIfOffline()) {
					this_el.toggleStatus()
				}
            }
        }
    }

    toggleStatus = () => {
		this.setState({
			toggleOfflineStatus: !this.state.toggleOfflineStatus
		}, () => {
			this.setState({
				toggleOfflineStatus: !this.state.toggleOfflineStatus
			})
		})
	}

    submitParams() {
        let args = {
            id: this.props.match.params.hashId,
            offset: this.props.property.reviewOffset,
        }
        this.getReviewListApi(args);
    }

    reload = () => {
        this.submitParams();
		/**
		 * Active scroll listner
		 */
        this.loadMoreResults();

    }

    toUserProfile(data) {
        let urlParams = '?host_id=' + data.traveller_id;

        this.props.history.push({
            pathname: '/user/profile/',
            search: urlParams,
        });

        this.props.updateProfileStateObj({
            profile_image: data.traveller_image,
            first_name: data.traveller_name,
            is_host:1
        })
    }


    render(){
        const this_el = this;
        return (
            <div className="all-property-reviews" id="reviews_listing">
                <header className="header white">
                    <div className="row">
                        <div className="col-12 text-left">
                            <span className="cross-icon" onClick={() => this_el.props.history.goBack()}></span>
                            <span className="middle-text text-center">
                                <span>Traveller reviews</span>
                            </span>
                        </div>
                    </div>
                    <div className="transparent-gredient"></div>
                </header>
                    <div>
                        <OfflineStatus 
                            offline={checkIfOffline() && !this.state.toggleOfflineStatus}
                            bottom
                            dontClear
                        />
                        <div className="property-reviews" >
                            <ul>
                                {(x => x.map(function (item, index) {
                                    return (
                                        <li key={index}>
                                            <div className="review-header clearfix">
                                                <div className="col-9 pull-left" onClick={(e) => this_el.toUserProfile(item)}>
                                                    <span className="user-pic" style={{ backgroundImage: 'url(' + item.traveller_image + ')' }}></span>
                                                    <span className="user-name">{item.traveller_name} <span>{item.review_date}</span></span>
                                                </div>
                                                <div className="col-3 pull-left">
                                                    <span className={'rating ' + tileRatingColor(item.property_rating)}>{this_el.decimaltrim(item.property_rating)}</span>
                                                </div>
                                            </div>
                                            <div className="review-content">
                                                <p>{item.comment}</p>
                                            </div>
                                            {item.nights > 0 &&
                                                <span className='stay-duration'>- Stayed for {item.nights} {item.nights > 1 ? 'nights' : 'night'}</span>
                                            }
                                        </li>
                                    )
                                }))(checkIfOffline() ? this.props.property.reviews : this.props.property.reviews_list)}
                            </ul>
                        </div>
                        {!this_el.props.property.flag.no_more_result && this_el.props.property.flag.api.is_loading && !checkIfOffline() ?
                            <div className={'loader text-center'}>
                                <div className="lds-spinner"><div></div><div></div><div></div><div></div><div></div><div></div><div></div><div></div><div></div><div></div><div></div><div></div></div>
                            </div>
                        :
                            <p className='text-center padding-top'>{!checkIfOffline() && 'No more reviews!'}</p>
                        }
                    </div>

            </div>

        )
    }
}

const mapStateToProps = (store) => {
    return {
        property: store.property,
        error: store.error

    }
}

const mapDispatchToProps = {
    updatePropertyState,
    updateErrorState,
    updateProfileStateObj
}
export default connect(mapStateToProps, mapDispatchToProps)(withRouter(AllReviews));