import React from 'react';
import Modal from 'react-modal';
import OfflineStatus from '../../Offlinestatus';
import { checkIfOffline } from '../../../services/helpers';


class DownloadApp extends React.Component {
  render() {
  	
    return (
      <div>
        <Modal
          isOpen={this.props.downloadAppOpen}
          onAfterOpen={this.afterOpenModal}
          contentLabel="Modal"

    		  overlayClassName={{
    		    base: 'overlay-modal',
    		    afterOpen: '',
    		    beforeClose: ''
    		  }}
          className={{
    	    	base: 'content-modal',
    		    afterOpen: 'download-app full-screen-modal',
    		    beforeClose: ''
    		  }}

        >
          <OfflineStatus 
            bottom
            offline={checkIfOffline()}
          />
          <div className="modal-body">
            <a className="back-btn" onClick={this.props.goBack}> </a>
              <div className="top-text text-center">Download the app for</div>
              <div className="middle-text text-center">24x7 chat support <br/>Better browsing experience <br/>Great deals and offers</div>
              <div className="bottom-text">You can also reach out to us on <br/><a href="tel:18001020404" className="c-no">1800-102-0404</a></div>
              <div className="dowload-image">
                <div className="apple"><a href="https://itunes.apple.com/in/app/guesthouser/id962240144?mt=8" className="apple"> </a></div>
                <div className="google"><a href="https://play.google.com/store/apps/details?id=com.guesthouser" className="google"> </a></div>
              </div>
          </div>
        </Modal>
      </div>
    );
  }
}

export default DownloadApp;