import React from 'react';
import { connect } from 'react-redux';


class PropertyPolicy extends React.Component{
	render(){
		return(
			<section className="property-policy clearfix">
                <h3>Cancellation policy</h3>
                <p><strong>{this.props.cancellation_policy.title}</strong>{this.props.cancellation_policy.desc}</p>
            </section>
		);
	}
}

const mapStateToProp = (store) => {
    return {
        cancellation_policy:store.property.cancellation_policy
    }
}
export default connect(mapStateToProp,null)(PropertyPolicy);