import React from 'react';
import Modal from 'react-modal';
import {connect} from 'react-redux';
import { withRouter } from 'react-router';
import {CopyToClipboard} from 'react-copy-to-clipboard';

import {
  FacebookShareButton,
  TwitterShareButton,
  WhatsappShareButton,
  EmailShareButton,
} from 'react-share';


class Share extends React.Component {
	constructor(props) {
		super(props);

		this.copied = this.copied.bind(this);
		this.goBack = this.goBack.bind(this);
	}

	copied(){
		const this_el = this;
		let snackbar = this.refs.snackbar;
		let copyBtn = this.refs.copyBtn;
		snackbar.classList.add('show');
		copyBtn.classList.add('touchableOff');
		setTimeout(function(){ 
			snackbar.classList.remove('show');
			copyBtn.classList.remove('touchableOff');
			this_el.goBack();
		}, 3000);
	}

	goBack(){
		this.props.history.goBack();
	}

	render() {
		let url= window.location.origin+''+window.location.pathname;
		const inputStyle = { opacity:0, height:0}
		return (
		  <div>

			<Modal
				isOpen={this.props.property.flag.modal.shareModal}
				onRequestClose={this.goBack}
				contentLabel="Modal"

				overlayClassName={{
					base: 'overlay-modal overlay-popup',
					afterOpen: '',
					beforeClose: ''
				}}
				
				className={{
					base: 'content-modal',
					afterOpen: 'editProfile share',
					beforeClose: ''
				}}

				>
			  <div className="modal-body">
				<input type="text" value={url} ref="myInput" style={inputStyle} readOnly='readOnly'/>
				<div className="text">Share with</div>	
				<div className="share-icon">
					<ul>
						<li>
							<FacebookShareButton
								url={url}
								className='fb'
							>
							</FacebookShareButton>
						</li>
						<li>
							<EmailShareButton
									url={url}
									className='email'
							>
							</EmailShareButton>
						</li>
						<li>
							<WhatsappShareButton 
								url={url}
								className="whatsapp"
							>
							</WhatsappShareButton>
						</li>
						<li>
							<TwitterShareButton
								url={url}
								className="twitter"
							>
							</TwitterShareButton>
						</li>
						<li ref="copyBtn">
							<CopyToClipboard text={url} >
								<div 
									onClick={this.copied}
									url={url}
									className="copy">
								</div>
							</CopyToClipboard>
						</li>
					</ul>
				</div>
				<div className="cancel" onClick={this.goBack}> Cancel</div>
				<div ref="snackbar" id="snackbar">Copied successful</div>
			  </div>
			</Modal>
		  </div>
		);
  }
}

const mapStateToProps = (store) => {
	return{
		property: store.property
	}
}

export default connect(mapStateToProps, null)(withRouter(Share));