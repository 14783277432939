/**
 * get value from Object, checking for undefined, null etc.
 * @param  Object to get value from
 * @param  String Object key
 * @return Mixed if key exist return key value otherwise return false
 */

exports.getObjectVal = (data, key) => {

	let val = false;
	if(typeof data === 'object' && typeof key === 'string'){

		if(data.hasOwnProperty(key)){
			val = data[key];
		}
	}
	return val;
}

exports.getVal = (val , default_val = '') => {

	//console.log('get val ', typeof val, val);
	let notValidType = ['null', 'undefined', 'empty'];
	let notValidString = ['null', 'undefined', 'empty'];

	let return_val = default_val;

	if(notValidType.indexOf(typeof val) === -1 && val !== null){

		if(notValidString.indexOf(val) === -1){
			return_val = val;
		}
		//return_val = val;
	}

	return return_val;
}



exports.ObjEq = (obj1, obj2) => {

    function _equals(obj1, obj2) {

        // var clone = $.extend(true, {}, obj1),
        //     cloneStr = JSON.stringify(clone);
        // return cloneStr === JSON.stringify($.extend(true, clone, obj2));
        return JSON.stringify(Object.assign({}, obj1)) === JSON.stringify(Object.assign({}, obj2));
    }

    return _equals(obj1, obj2) && _equals(obj2, obj1);
}


exports.cloneObj = (obj) => {

	if(typeof obj !== 'object'){
		return {}
	}


	return JSON.parse(JSON.stringify(obj));
}


exports.numStringToArr = (numString) => {
	if(typeof numString === 'string' || typeof numString === 'number'){
		if(typeof numString === 'number'){

			let str = numString.toString();
			let numArr = str.split(',').map(Number);
			numArr.sort(function(a, b){return a - b});
			return numArr;
		}else{

			let numArr = numString.split(',').map(Number);
			numArr.sort(function(a, b){return a - b});
			return numArr;
		}
	}else{
		return [];
	}
}


exports.stringToArr = (commaSepString) => {

	if(typeof commaSepString === 'string'){
		let str = commaSepString.split(',').sort()
		return str;
	}else{
		return [];
	}

}

exports.locationNameFormatter = (city = '', state = '') => {
	
	let location_name = [];
	if(city) location_name.push(city);
	if(state) location_name.push(state);
	return location_name.join(', ');
}

exports.updateStoredPropertyWishlist = (id, status) => {
	let storedData = JSON.parse(sessionStorage.getItem('storedPropertyList'));
	if (storedData !== null) {
		let result = storedData.find(obj => {
			return obj.data.data.property_hash_id === id;
		})
		if (result !== undefined) {
			result.data.data.is_wishlisted = status;
		}

		let index = storedData.indexOf(result);
		if (~index) {
			storedData.splice(index, 1, result);
		}

		sessionStorage.setItem('storedPropertyList', JSON.stringify(storedData));
	}
}
exports.checkIfOffline = () => navigator.onLine ? false : true

exports.attachBlockZoomListener = () => {
	// document.documentElement.addEventListener('touchstart', (event) => {
	// 	if (event.touches.length > 1) {
	// 		event.preventDefault();
	// 	}
	// }, false);
	// let lastTouchEnd = 0;
	// document.documentElement.addEventListener('touchend', (event) => {
	// 	const now = (new Date()).getTime();
	// 	if (now - lastTouchEnd <= 300) {
	// 		event.preventDefault();
	// 	}
	// 	lastTouchEnd = now;
	// }, false);
}

exports.removeBlockZoomListener = () => {
	// document.documentElement.removeEventListener('touchstart', (event) => {
	// 	if (event.touches.length > 1) {
	// 		event.preventDefault();
	// 	}
	// }, false);
	// let lastTouchEnd = 0;
	// document.documentElement.removeEventListener('touchend', (event) => {
	// 	const now = (new Date()).getTime();
	// 	if (now - lastTouchEnd <= 300) {
	// 		event.preventDefault();
	// 	}
	// 	lastTouchEnd = now;
	// }, false);

}