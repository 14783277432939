import React from 'react';
import Modal from 'react-modal';
import { connect } from "react-redux";

class CouponConfirmation extends React.Component {
	render() {
		return (
			<div>
				<Modal
					isOpen={this.props.prepayment.flag.modal.couponConfirmation}
					onRequestClose={this.props.couponConfirmationClose}
					contentLabel="Modal"
						overlayClassName={{
						base: 'overlay-modal overlay-popup',
						afterOpen: '',
						beforeClose: ''
					}}
					className={{
						base: 'content-modal',
						afterOpen: 'editProfile',
						beforeClose: ''
					}}

				>
					<div className="modal-body text-center"> 
							<a className="back-btn" onClick={this.props.couponConfirmationClose}> </a>
							<div className="text-center varificationEmail">If you want to use coupon then your applied wallet will be removed. </div>
							<button className="link green" onClick={this.props.couponConfirmation}>Ok</button>
					</div>
				</Modal>
			</div>
		);
	}
}

const mapStateToPtops = (store) => {
	return {
		prepayment: store.prepayment
	}
}
export default connect(mapStateToPtops, null)(CouponConfirmation);