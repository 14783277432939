import React from 'react';
import Modal from 'react-modal';

class SpokenLanguages extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      'spoken_languages':props.spokenLanguage,
      'errorMsg':false,
      //'spokenLanguage':props.spokenLanguagesClose
    }

    this.spokenLanguagesChange =this.spokenLanguagesChange.bind(this);
    this.saveLanguages = this.saveLanguages.bind(this);
    this.afterOpenModal = this.afterOpenModal.bind(this);
        //console.log(this.state.gender + " : " + props.gender);
    }

    spokenLanguagesChange(value) {
        this.setState({
            'spoken_languages': value.trimLeft()
        });
    }
    hideErroMsg=()=>{
      this.setState({
        errorMsg:false
      })
    }
    afterOpenModal(){
      let this_el = this;
      this.setState({
            'spoken_languages': this_el.props.spokenLanguage,
            errorMsg:false,
      });
    }

    saveLanguages=(e)=>{
      e.preventDefault();
      if(this.state.spoken_languages.length> 0){
      this.props.saveLanguages(this.state.spoken_languages);
      this.props.goBack();
    }else{
      this.setState({
        errorMsg:true
      })
    }
  }
  render() {
    return (
      <div>
        <Modal
          isOpen={this.props.spokenLanguagesOpen}
          onAfterOpen={this.afterOpenModal}
          contentLabel="Modal"
                overlayClassName={{
                base: 'overlay-modal overlay-popup',
                afterOpen: '',
                beforeClose: ''
              }}
          className={{
                base: 'content-modal',
                afterOpen: 'editProfile',
                beforeClose: ''
              }}

        >
          <div className="modal-body text-center">
              <a className="back-btn" onClick={this.props.goBack}> </a>
              <div className="verify-text1 text-left">Languages you can speak</div>
              <form class="editprofile_form" onSubmit={(e) => this.saveLanguages(e)} >
              <textarea className="texarea" placeholder="Spoken Languages" value={this.state.spoken_languages} onFocus={() => this.hideErroMsg()} onChange={ e => this.spokenLanguagesChange(e.target.value)}>
              </textarea>
              {
                this.state.errorMsg &&
                <span className="error textarea">Field can not be empty</span>
              }
              <button type="submit" className="link green">Done</button>
              </form>
          </div>
        </Modal>
      </div>
    );
  }
}

export default SpokenLanguages;