import { SET_STATE_WISHLIST_OBJ }  from '../actions/wishlist/vars';

const initialState = {
    properties_wishlist: [],
    offset:0,
    flag : {
        no_more_result : false,
        api : {
            is_loading: false,
        }
    },
    dataLoaded: false
}

const fnc = (state = initialState, action) => {
    switch (action.type) {
        case SET_STATE_WISHLIST_OBJ:
            console.log(action.payload);
            let newState = Object.assign({}, state, action.payload);
            return newState;
            
        default:
            return state;

    }
}

export default fnc;