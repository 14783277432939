import React from 'react';
import Modal from 'react-modal';


import { withRouter } from 'react-router';
import {connect} from 'react-redux';


import {cloneObj, getObjectVal} from '../../../services/helpers.js';

//actions
import { updateSearchState} from './../../../actions/search/actions.js';




class LocationList extends React.Component {
	constructor(props) {

		super(props);


 		// methods in use
 		this.closeModal = this.closeModal.bind(this);


 		/**
 		 * dont know
 		 */
 		this.selectTag = this.selectTag.bind(this);
 		this._handleOnChange = this._handleOnChange.bind(this);
 		this.localSelected = this.localSelected.bind(this);
 		this.showSubmitButton = this.showSubmitButton.bind(this);
 		this.clear = this.clear.bind(this);
 		this.searchFilter = this.searchFilter.bind(this);
 		this.clearSearch = this.clearSearch.bind(this);
 		this.filterCheckbox = this.filterCheckbox.bind(this);
 		this.submit = this.submit.bind(this);


 		/**
 		 * Local variables
 		 */
 		this.pre_selected_ref_suffix = '_pre_selected';
		this.top_location_ref_suffix = '_top_location';
		this.all_ref_suffix = '_all';
		this.checkbox_ref_suffix = '_chbx';


		this.defaultTag = 'area';
		this.activeTag = this.defaultTag;

		this.state = {
			selectedTab: null,
			selectedList: []
		}
	}

	componentWillReceiveProps(nextProps) {
		if (nextProps.search.filter.filter.area.length !== this.state.selectedList.length) {
			this.setState({
				selectedList: nextProps.search.filter.filter.area
			})
		}
	} 

	closeModal(){
		const this_el = this;

		let flag = Object.assign({}, this_el.props.search.flag);
		flag.modal.area = false;

 		this_el.props.updateSearchState({
 			flag : flag
 		});

	}


    /**
     * Local changes handler's
     */


     /**
      * Submittin selected ids
      */

      submit(){
      	const this_el = this;
		const localSelected = this_el.localSelected();


		let searchObj = cloneObj(this_el.props.search);
 		searchObj.filter.filter_replica.area = localSelected;
 		searchObj.flag.modal.area = false;

		this_el.props.updateSearchState(searchObj).then(() => {
			this_el.props.checkFilterChange();
		});
      }



    /**
     * Method used to filter all area li
     */

    clearSearch(){
		const tagName = this.activeTag
		this.refs['search_input_span'].classList.remove('active');
		this.refs['search_input'].value = '';
		const area_tag_ele = this.refs.area_tag
		if (area_tag_ele !== undefined) area_tag_ele.classList.remove('location-group-hide');

    	this.hideOrShowTopSelectedCheckbox(tagName);
    	this.updateTagAndToogleCheckbox(tagName);
    }


    searchFilter(e){

    	const this_el = this;
    	const query = this_el.tagName(e.target.value);
    	const tagName = this_el.activeTag
    	const search_input_span_ele = this_el.refs['search_input_span'];
    	const top_location_cont_ele = this_el.refs.selected_top_location_container;
    	const area_tag_ele = this_el.refs.area_tag;


    	if(query.length){
    		search_input_span_ele.classList.add('active');


    		/**
    		 * Hidding top pre selected and top 10 list in case of search
    		 */
    		if(top_location_cont_ele !== undefined){
    			top_location_cont_ele.classList.add('selected_top_location_container');
    		}

    		/**
    		 * Filtering bottom list
    		 * By default search work in ALL tag area,
    		 * If you want to search in specific area, then change 2nd param to activeTag Name [tagName]
    		 */
    		this_el.filterCheckbox(query, this_el.defaultTag);

    		/**
			 * Updating other text html to ALL tag
			 */
			this_el.updatingStaticOtherLocationText(this_el.defaultTag);


    		/**
    		 * to hide area tag
    		 */
    		if(area_tag_ele !== undefined) area_tag_ele.classList.add('location-group-hide');

    	}
    	else{
    		search_input_span_ele.classList.remove('active');
    		this_el.hideOrShowTopSelectedCheckbox(tagName);
    		this_el.updateTagAndToogleCheckbox(tagName);
    		/**
    		 * to show area tag
    		 */
    		if(area_tag_ele !== undefined) area_tag_ele.classList.remove('location-group-hide');
    	}

    }


    filterCheckbox(query, tagName){


		/**
		 * Filter checkbox acc to the selected tag
		 *
		 */
		const this_el = this;
		const list = this_el.props.search.filter.values.search_location;


		if(list){

			for(let key in list){

				const row = list[key];
				const id_name = this_el.tagName(row.name);
				const row_tag_name = this_el.tagName(row.tag);

				/**
				 * Finding reference's of all checkbox li
				 *
				 */
				const all_li_ref = this_el.refs[id_name+this_el.all_ref_suffix];

				/**
				 * Initially hide each checkbox, then check conditions
				 *
				 */
				if(all_li_ref !== undefined) all_li_ref.classList.remove('active');


				if(tagName === this_el.defaultTag){

					// search all area tag
					if(id_name.indexOf(query) !== -1 && all_li_ref !== undefined){
						all_li_ref.classList.add('active');
					}
				}
				else{
					// search in speicific tag and hide other
					if(id_name.indexOf(query) !== -1 && all_li_ref !== undefined && tagName === row_tag_name){
						all_li_ref.classList.add('active');
					}
				}

			}
		}

    }



    /**
     * Fitler function ends here
     *  -----------------     *     -----------------------
     */



	/**
	 * Method to check wether each row checbox is selected or not.
	 * If it is not in pre selected area's then return false
	 */
     isSelected(area_name){

     	const this_el = this;
     	let area_name_id = this_el.tagName(area_name);
     	const selected = this_el.props.search.filter.filter_replica.area;

     	if(selected){
     		for(let key in selected){
     			const selected_name_id = this_el.tagName(selected[key]);
     			if(area_name_id === selected_name_id){
     					return true;
     			}
     		}
     	}
     	return false;
     }



     /**
      * Checkbox change handler
      * Responsible to check all three checkbox once, [Pre selected, top 10 list, All list]
      */

    _handleOnChange(e){
			if (this.state.selectedList.includes(e.target.value)) {
				this.setState({
					selectedList: this.state.selectedList.filter(x => x !== e.target.value)
				})
			} else {
				this.setState(({ selectedList: [ ...this.state.selectedList, e.target.value ] }))
			}
    	const this_el = this;
    	const ele = e.target;
    	const id_name = this_el.tagName(ele.value);



    	/**
		 * Finding reference's of all checkbox li
		 *
		 */
		const pre_selected_li_checkbox_ref = this_el.refs[id_name+this_el.pre_selected_ref_suffix+this_el.checkbox_ref_suffix];
		const top_location_li_checkbox_ref = this_el.refs[id_name+this_el.top_location_ref_suffix+this_el.checkbox_ref_suffix];
		const all_li_checkbox_ref = this_el.refs[id_name+this_el.all_ref_suffix+this_el.checkbox_ref_suffix];

		if(pre_selected_li_checkbox_ref !== undefined) pre_selected_li_checkbox_ref.checked = ele.checked;
		if(top_location_li_checkbox_ref !== undefined) top_location_li_checkbox_ref.checked = ele.checked;
		if(all_li_checkbox_ref !== undefined) all_li_checkbox_ref.checked = ele.checked;


		/**
		 * Checking wether to show submit button or not
		 */
		this_el.showSubmitButton();

    }


    localSelected(){

    	let localSelected = [];
    	const this_el = this;
    	const list = this_el.props.search.filter.values.search_location;

    	if(list){
    		for(var key in list){
    			const row = list[key];
    			const id_name = this_el.tagName(row.name);
    			const checkbox = this_el.refs[id_name+this_el.all_ref_suffix+this_el.checkbox_ref_suffix];
    			if(checkbox !== undefined && checkbox.checked){
    				localSelected.push(row.name);
    			}
    		}
    	}

    	localSelected.sort();
    	return localSelected;

    }

    showSubmitButton(){


    	const this_el = this;
    	const selected = this_el.props.search.filter.filter_replica.area;
    	const localSelected = this_el.localSelected();

    	if(localSelected.length !== selected.length){
    		this.refs.floatBtn.classList.add('active');
    		return;
    	}
    	else{

    		if(selected){
	    		for(var key in selected){
	    			const selected_area_name = selected[key];
	    			if(localSelected.indexOf(selected_area_name) === -1){
	    				this.refs.floatBtn.classList.add('active');
    					return;
	    			}
	    		}
	    	}

    	}

    	this.refs.floatBtn.classList.remove('active');
    }



    /**
     * Method used to hide or show top 10 list and the pre selected area wrt to tag selected
     * In all area case, then show top selected tag otherwise hide top selected
     */

    hideOrShowTopSelectedCheckbox(tagName){

    	const this_el = this;
    	const element = this_el.refs.selected_top_location_container;
    	if(element !== undefined){

    		if(tagName === this_el.defaultTag){
				element.classList.remove('selected_top_location_container');
	    	}
	    	else{
	    		element.classList.add('selected_top_location_container');
	    	}
    	}
    }

    /**
     * Change the text of "Other locations" to area tab name or vice versa
     */

    updatingStaticOtherLocationText(tagName){

    	//let tagName = document.querySelectorAll('.tag-item,.active')[3].id;
    	const this_el = this;

    	if(tagName === this_el.defaultTag){
    		document.getElementById("other_tab_static").innerHTML = 'Other locations';
    	}
    	else{
    		document.getElementById("other_tab_static").innerHTML = this_el.tagName(tagName, true);
    	}



    }

    updateTagAndToogleCheckbox(tagName,selectedTagEle = false){
    	/**
		 * Update current selected tag by updating active class
		 */
		const this_el = this;

		if(selectedTagEle){
			let tagItems = document.getElementsByClassName("tag-item");
			if(tagItems.length){
				for(let i =0; i< tagItems.length; i++){
					tagItems[i].classList.remove('active');
				}
			}
			selectedTagEle.target.className = 'tag-item active';
		}

		/**
		 * Filter checkbox acc to the selected tag
		 *
		 */
		const list = this_el.props.search.filter.values.search_location;
		if(list){

			for(let key in list){

				const row = list[key];
				const id_name = this_el.tagName(row.name);

				/**
				 * Finding reference's of all checkbox li
				 *
				 */
				const pre_selected_li_ref = this_el.refs[id_name+this_el.pre_selected_ref_suffix];
				const top_location_li_ref = this_el.refs[id_name+this_el.top_location_ref_suffix];
				const all_li_ref = this_el.refs[id_name+this_el.all_ref_suffix];


				/**
				 * Initially hide each checkbox, then check conditions
				 *
				 */
				if(pre_selected_li_ref !== undefined) pre_selected_li_ref.classList.remove('active');
				if(top_location_li_ref !== undefined) top_location_li_ref.classList.remove('active' );
				if(all_li_ref !== undefined) all_li_ref.classList.remove('active');


				/**
				 * Conditionally check
				 */
				if(tagName === this_el.defaultTag){

					// all area tag selected show all checkbox
					//
					if(pre_selected_li_ref !== undefined) pre_selected_li_ref.classList.add('active');
					if(top_location_li_ref !== undefined) top_location_li_ref.classList.add('active');
					if(all_li_ref !== undefined) all_li_ref.classList.add('active');

				}
				else{

					if(all_li_ref !== undefined && all_li_ref.getAttribute('tag') === tagName){
						// displaying only selected tag area from all list
						all_li_ref.classList.add('active');
					}
				}
			}
		}
    }


	selectTag(e, item){
		this.setState({ selectedTab: item })
		const this_el = this;
		const tagName = e.target.id;
		this_el.activeTag = tagName;

		/**
		 * clearSearch
		 */
		this_el.clearSearch();

		/**
		 *
		 */
		this_el.updateTagAndToogleCheckbox(tagName, e);


		/**
		 *
		 */
		this_el.hideOrShowTopSelectedCheckbox(tagName);


		/**
		 * Updating other text html
		 */
		this_el.updatingStaticOtherLocationText(tagName);
	}

	tagName(tagName, reverse = false){

		if(reverse){

			let tagSplitArr = tagName.split('-');
			for(let i =0; i< tagSplitArr.length; i++){
				tagSplitArr[i] = tagSplitArr[i].charAt(0).toUpperCase()+tagSplitArr[i].slice(1);
			}
			return tagSplitArr.join(' ');
		}
		else{
			return tagName.toLowerCase().split(' ').join('-');
		}

	}

	/**
	 * Clear all selected checboxes
	 */

	clear(){
		const this_el = this;
		const list = this_el.props.search.filter.values.search_location;

		if(list){

			for(let key in list){

				const row = list[key];
				const id_name = this_el.tagName(row.name);

				const pre_selected_li_checkbox_ref = this_el.refs[id_name+this_el.pre_selected_ref_suffix+this_el.checkbox_ref_suffix];
				const top_location_li_checkbox_ref = this_el.refs[id_name+this_el.top_location_ref_suffix+this_el.checkbox_ref_suffix];
				const all_li_checkbox_ref = this_el.refs[id_name+this_el.all_ref_suffix+this_el.checkbox_ref_suffix];

				if(pre_selected_li_checkbox_ref !== undefined){
					pre_selected_li_checkbox_ref.checked = false;
					pre_selected_li_checkbox_ref.removeAttribute('checked');
				}

				if(top_location_li_checkbox_ref !== undefined){
					top_location_li_checkbox_ref.checked = false;
					top_location_li_checkbox_ref.removeAttribute('checked');
				}

				if(all_li_checkbox_ref !== undefined){
					all_li_checkbox_ref.checked = false;
					all_li_checkbox_ref.removeAttribute('checked');
				}

			}
		}
		
		/**
		 * Checking wether to show submit button or not
		 */
		this.setState({
			selectedList: []
		})
		this_el.showSubmitButton();
	}

	inputFocus(){
    	this.refs.floatBtn.classList.remove('active');
	}
	inputBlur(){
		this.showSubmitButton();

	}

	render(){
		const this_el = this;


		return(
			<div>
				<Modal
					isOpen={this_el.props.search.flag.modal.area}
					onRequestClose={this.closeModal}


					overlayClassName={{
						base: 'overlay-modal',
						afterOpen: '',
						beforeClose: ''
					}}
					className={{
						base: 'content-modal',
						afterOpen: 'full-screen-modal location-list',
						beforeClose: ''
					}}>
					<form>
						<div className="filter-modal">
							<div className="modal-header">
								<div className="header-btn back-btn" onClick={this.closeModal}></div>
								<h2 className="text-center">Filter by location</h2>
								<span className={`reset-btn ${this.state.selectedList.length === 0 && 'disabled'}`} onClick={() => this.state.selectedList.length !== 0 && this_el.clear()} >Reset</span>
									<div className="location-search-input-outer">
										<div className="location-search-input">
											<span ref={'search_input_span'} onClick={this_el.clearSearch}></span>
											<input type="text"
												placeholder="Select an area within the city"
												ref="search_input"
												onKeyUp={(e) =>this_el.searchFilter(e)}
												onFocus={this.inputFocus.bind(this)}
												onBlur={this.inputBlur.bind(this)}
											/>
										</div>
									</div>
							</div>
							<div className="modal-body">
								<div className="amenities-filter">



									{/* Area Tab  */}
									<div className={"location-group "} ref={"area_tag"}>
										<ul>

											<li className="tag-item active" id={this_el.defaultTag} onClick={(e) => this_el.selectTag(e)}>
												All
											</li>

											{getObjectVal(this_el.props.search.filter.values, 'location_tags') && this_el.props.search.filter.values.location_tags.map(function(item, index){
												return(
													<li
														key={index}
														className='tag-item'
														id={this_el.tagName(item)}
														onClick={(e) => this_el.selectTag(e, item)}
													>
														{item}
													</li>
												)
											})}
										</ul>
									</div>





									<div className='' ref='selected_top_location_container' >


										{/* Pre Selected Ids */}

										{/* Write your code here to show pre selected ids come from url */}
										<ul className="amenities-list top-location">
											{getObjectVal(this_el.props.search.filter.values, 'search_location') && this_el.props.search.filter.values.search_location.map(function(item, index){
												if(this_el.isSelected(item.name)){
													return(
														<li key={index}
															className={this_el.defaultTag+' active '+this_el.tagName(item.tag)}
															tag={this_el.tagName(item.tag)}
															ref={this_el.tagName(item.name)+this_el.top_location_ref_suffix}
															id={this_el.tagName(item.name)+this_el.top_location_ref_suffix}
														>
															<label>
																{item.name}
																<input type="checkbox"
																	name={item.name}
																	value={item.name}
																	className="pull-right"
																	title={item.tag}
																	id={this_el.tagName(item.name)+this_el.top_location_ref_suffix+this_el.checkbox_ref_suffix}
																	ref={this_el.tagName(item.name)+this_el.top_location_ref_suffix+this_el.checkbox_ref_suffix}
																	onChange={(e) =>this_el._handleOnChange(e)}
																	defaultChecked={true}
																/>
																<span className="pull-right"></span>
															</label>
														</li>
													)
												} else { 
													return false;
												}
											})}
										</ul>



										{/* Top location list only 10 showing from list */}

										<h3>Top locations</h3>
										<ul className="amenities-list top-location">
											{getObjectVal(this_el.props.search.filter.values, 'popular_similar_locations') && this_el.props.search.filter.values.popular_similar_locations.map(function(item, index){
												return(
													<li key={index}
														className={this_el.defaultTag+' active '+this_el.tagName(item.tag)}
														tag={this_el.tagName(item.tag)}
														ref={this_el.tagName(item.name)+this_el.top_location_ref_suffix}
														id={this_el.tagName(item.name)+this_el.top_location_ref_suffix}
													>
														<label>
															{item.name}{(x => x ? '-' + x : item.tag.length > 0 && '-' + item.tag)(this_el.state.selectedTab)}
															<input type="checkbox"
																name={item.name}
																value={item.name}
																className="pull-right"
																title={item.tag}
																id={this_el.tagName(item.name)+this_el.top_location_ref_suffix+this_el.checkbox_ref_suffix}
																ref={this_el.tagName(item.name)+this_el.top_location_ref_suffix+this_el.checkbox_ref_suffix}
																onChange={(e) =>this_el._handleOnChange(e)}
																defaultChecked={this_el.isSelected(item.name)}
																data-default-checked={this_el.isSelected(item.name)}

															/>
															<span className="pull-right"></span>
														</label>
													</li>
												)
											})}
										</ul>
									</div>



									{/* Area specific list */}

									<h3 ref='other_tab_static' id='other_tab_static'>
										Other Locations
									</h3>

									<ul className="amenities-list ">
										{getObjectVal(this_el.props.search.filter.values, 'search_location') && this_el.props.search.filter.values.search_location.map((item, index) => {
											if(item.show === 1){
												return(
													<li key={index}
														className={this_el.defaultTag+' active '+this_el.tagName(item.tag)}
														tag={this_el.tagName(item.tag)}
														ref={this_el.tagName(item.name)+this_el.all_ref_suffix}
														id={this_el.tagName(item.name)+this_el.all_ref_suffix}
													>
															<label>
																{item.name}{(x => x ? '-' + x : item.tag.length > 0 && '-' + item.tag)(this_el.state.selectedTab)}
																<input type="checkbox"
																	name={item.name}
																	value={item.name}
																	className="pull-right"
																	title={item.tag}
																	id={this_el.tagName(item.name)+this_el.all_ref_suffix+this_el.checkbox_ref_suffix}
																	ref={this_el.tagName(item.name)+this_el.all_ref_suffix+this_el.checkbox_ref_suffix}
																	onChange={(e) =>this_el._handleOnChange(e)}
																	defaultChecked={this_el.isSelected(item.name)}
																/>
																<span className="pull-right"></span>
															</label>
													</li>
												)
											} else {
												return false;
											}
										})}
									</ul>
								</div>
								<div className="floating-btn-box text-center" ref="floatBtn">
									<span className="floating-btn" onClick={this_el.submit}>Done</span>
								</div>
							</div>
						</div>
					</form>
				</Modal>
			</div>
		)
	}
}



const mapStateToProps = store => {
	return {
		search : store.search
	};
};

const mapDispatchToProps = {
	updateSearchState
};

export default connect(mapStateToProps,mapDispatchToProps)(withRouter(LocationList));
