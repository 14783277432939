import { SET_STATE_USER_OBJ} from '../actions/setting/vars';

const initialState = {
    'currency':'',
    'email':'',
    'old_password':'',
    'password':'',
    'message':'',
    'errorMSg':false,
    'status':false,
    'show':false,
    'currencyModal':false,
    'CurrencyChangedModal':false,
    'VarifyEmail':false,
    'updateEmail':false,
    'changePasswordModal':false,
    'deleteAccount':false,
    'privacyPolicyModal':false,
    'termConditionModal':false,
    'contentPolicyModal':false,
    'passwordChangedsuccess':false,
    'loading':false,
    'logoutModal':false,
    'validation':true,
    'selected_currency':'',
    'currency_codes':[],
    'model':{
        'email':'',
        'new_password':'',
        'confirm_password':'',
    },
    'field_valid' :{
        'old_password':false,
        'new_password':false,
        'confirm_password':false,
    },
}

const UserChangeData = (state = initialState, action) => {
    switch (action.type) {
        case SET_STATE_USER_OBJ :
            let newState = Object.assign({}, state, action.payload);
            return newState;

        default:
            return state;
    }
}
export default UserChangeData;