import { SET_STATE_REQUEST_OBJ, SET_STATE_REQUEST_RESET } from './vars';

export const updateRequestState = (obj = {}, reset = 0) => dispatch => {
    if(!reset){
        dispatch({
            type: SET_STATE_REQUEST_OBJ,
            payload: obj
        });
    }else{
        dispatch({
            type: SET_STATE_REQUEST_RESET,
        });
    }
    return Promise.resolve();
}