import React from 'react';
import CancelModal from "../../components/modal/cancelModal/";
import noInternet from "../../static/img/infoWindow/noInternet.svg";
import RequestApprovedModel from "../../components/request/requestApproved.js";
import CancelPopUp from "../../components/modal/cancelModal/cancelPopup.js";
import OfflineStatus from '../../components/Offlinestatus'
import { checkIfOffline } from '../../services/helpers'
import { connect } from 'react-redux';
import serverError from "../../static/img/serverError.png";
//services
import {hasAccessToken, getRequestDetailsApi,getResendRequestApi, addClassToHtml, removeClassToHtml} from '../../services/index.js';
import {trackPageView,trackError} from '../../services/tracker/events.js';

/**
 * Actions
 */
import { updateRequestState } from '../../actions/request/actions';
import { updateErrorState } from '../../actions/error/actions';
import { updatePropertyState } from '../../actions/property/actions';
import { updateBookingDataState } from '../../actions/booking-data/actions';
import { updatePrepaymentState } from '../../actions/prepayment/actions';

class RequestDetails extends React.Component {
	constructor(props){
		super(props);
		this.page="request_details";
		this.state = {
			'booking_info_section':'',
			'cancelModal':false,
			'time': {}, 
			'requestApproved':false,
			'request':true,
			'cancelPopUp':false,
		}
		this.toDate = this.toDate.bind(this);
		this.countDown = this.countDown.bind(this);
		this.cancelModalOpen = this.cancelModalOpen.bind(this);
		this.timer = 0;
		this.requestinterval = 0;
		this.toPrepayment = this.toPrepayment.bind(this);
		this.ResendRequest = this.ResendRequest.bind(this);
		this.cancelRequest = this.cancelRequest.bind(this);
		this.requestApprovedOpenModel=this.requestApprovedOpenModel.bind(this);
		this.requestApprovedCloseModel=this.requestApprovedCloseModel.bind(this);
		this.cancelPopUpOpen=this.cancelPopUpOpen.bind(this);
		this.checkOtherDate =  this.checkOtherDate.bind(this);
		this.goBack = this.goBack.bind(this);
		this.toProperty = this.toProperty.bind(this);

	}

	getRequestApiDataSuccess(response, first_call) {
		console.log('data', response)

		if (typeof this.props.request.booking_status === 'object' && this.props.request.booking_status.status !== response.data.data.booking_info_section.info.booking_status.status) {
			this.props.updateRequestState({
				updateList: true
			})
		}

		const this_el = this;
		this.props.updateRequestState({
			info: response.data.data.booking_info_section.info,
			booking_status: response.data.data.booking_info_section.info.booking_status,
			seconds: response.data.data.booking_info_section.info.expires_in,
			request_hash_id: response.data.data.booking_info_section.info.request_hash_id,
			invoice_header: response.data.data.invoice_section.invoice_header,
			invoice_middle: response.data.data.invoice_section.invoice_middle,
			invoice_footer: response.data.data.invoice_section.invoice_footer,
			selected_payment_method: response.data.data.invoice_section.selected_payment_method,
			cancellation_section: response.data.data.cancellation_section.cancellable,
			cancellation_reasons: response.data.data.cancellation_section.cancellation_reasons,
			cancellation_policy_info: response.data.data.cancellation_section.cancellation_policy_info,
			property_section: response.data.data.property_section,
			lat: response.data.data.property_section.tile.location.latitude,
    		lng: response.data.data.property_section.tile.location.longitude,
		});

		if(this.props.request.booking_status.status === 0){

			if(sessionStorage.getItem('status') === 0 || sessionStorage.getItem('status') === null){
				this.requestApprovedOpenModel();
				sessionStorage.setItem('status', 1);
			}
		}
		let error_flag = Object.assign({}, this_el.props.error.flag);
			error_flag.network = false;
			error_flag.server = false;
			this_el.props.updateErrorState({
				flag: error_flag
		});

		if(first_call){
			let timeLeftVar = this.secondsToTime(this.props.request.seconds);
			this.setState({ time: timeLeftVar });
			this.startTimer();
			this.apiCall();	
		}

		this.props.updatePropertyState({
			'property_title': response.data.data.property_section.tile.property_title,
			'title': response.data.data.property_section.tile.title,
			'property_type': response.data.data.property_section.tile.property_type,

		});
		this.props.updatePrepaymentState({
			'property_images': response.data.data.property_section.tile.property_images,
		})
	}

	requestApprovedOpenModel(){
		this.setState({
			requestApproved:true
		});
	}

	requestApprovedCloseModel(){
		this.setState({
			requestApproved:false
		});
	}

	ResendRequest(args){
		let this_el = this;
		getResendRequestApi(args).then(function(response){
				//console.log(response);
				this_el.props.history.push({
					pathname: '/request/'+response.data.data.request_id,
		        });
		        this_el.getRequestApiData(response.data.data.request_id, true);
		}).catch(function(error){
			//console.log(error);		
		});
	}


	getRequestApiDataError(error) {
		let this_el = this;
		if(typeof error.response !== 'undefined' && error.response.status === 1000){
			let error_flag = Object.assign({}, this_el.props.error.flag);
			error_flag.network = true;

			// updating network state
			this_el.props.updateErrorState({
				flag: error_flag
			});
		}else if(error.response.status === 500){
			//console.log('dgdg',error);
			let flag =  this.props.error.flag;
			flag.server = true; 
			this.props.updateErrorState({ flag: flag });
			
		}else{
			trackError(error);
		}
	}

	getRequestApiData(args, first_call){
		let this_el = this;
		getRequestDetailsApi(args).then(function(response){
			// success
			this_el.getRequestApiDataSuccess(response, first_call);
		}).catch(function(error){
			// error
			this_el.getRequestApiDataError(error);
		});
	}

	componentWillMount() {
		if (hasAccessToken()) {
			this.getRequestApiData(this.props.match.params.hashId, true);
		}
		if (checkIfOffline()) {
			this.props.updateErrorState({ flag: { network: true } })
		} else {
			this.props.updateErrorState({ flag: { network: false } })
		}
	}


	componentDidMount() {
		this.props.updateRequestState({ isTop :true})

		window.scrollTo(0, 0);
    	const this_el = this;
        window.onpopstate = function(e) {
            this_el.modalRouting(e.currentTarget.location.hash);
        };
        this.modalRouting(window.location.hash);
		 trackPageView(this.props.tracker, this.page);
		 
		 document.addEventListener('scroll', () => {
			const isTop = window.scrollY < 118;
			if (isTop !== this.props.request.isTop) {
				this.props.updateRequestState({ isTop})
			  }
		  });
	}

	componentWillUnmount() {
		clearInterval(this.timer);
		clearInterval(this.requestinterval);
	}

	apiCall(){
		const this_el = this;
		this.requestinterval = setInterval(function(){
			if (this_el.props.request.booking_status.status === -1 && this_el.props.request.seconds > 0){
				this_el.getRequestApiData(this_el.props.match.params.hashId);
			}else{
				clearInterval(this.requestinterval);
			}
		}, 30000);
	}
	

	toDate(dateStr) {
		if(dateStr !== undefined){
			let parts = dateStr.split(" ");
			return parts[0]+' '+ parts[1];
		}
	}

	secondsToTime(secs){
		let hours = Math.floor(secs / (60 * 60));
		if(hours<10){
			hours = "0"+hours;
		}
		let divisor_for_minutes = secs % (60 * 60);
		let minutes = Math.floor(divisor_for_minutes / 60);
		if(minutes<10){
			minutes = "0"+minutes;
		}
		let divisor_for_seconds = divisor_for_minutes % 60;
		let seconds = Math.ceil(divisor_for_seconds);
		if(seconds<10){
			seconds = "0"+seconds;
		}
		let obj = {
		  "h": hours,
		  "m": minutes,
		  "s": seconds
		};
		return obj;
	  }

	  startTimer() {
		if (this.timer === 0) {
		  this.timer = setInterval(this.countDown, 1000);
		}
	  }

	  countDown() {
		// Remove one second, set state so a re-render happens.
		let seconds = this.props.request.seconds - 1;
		  this.props.updateRequestState({
			  seconds: seconds,
		  });

		this.setState({
		  time: this.secondsToTime(seconds),
		});
		
		// Check if we're at zero.
		if (seconds < 0) { 
		  clearInterval(this.timer);
			this.props.updateRequestState({
				seconds: 0,
			});
		}
	  }

	
    toPrepayment(data){
			if (this.props.prepayment.cacheHash !== this.props.match.params.hashId) {
				this.props.updatePrepaymentState({
					dataLoaded: false,
					cacheHash: ""
				})
			}
			this.props.history.push({
				pathname: '/prepayment/request/'+	this.props.match.params.hashId,
				prepaymentData : {
							pathname : this.props.location.pathname,
							search : this.props.location.search,
				}
			});
    }

    checkOtherDate(){
        this.props.history.push({
			pathname: '/property/'+this.props.request.info.property_hash_id,
        });
    }

	reload = () => {
		if (hasAccessToken()) {
			this.getRequestApiData(this.props.match.params.hashId, true);
		}
    }

    cancelRequest(){
    	this.getRequestApiData(this.props.match.params.hashId)
    }

    goBack(){
    	this.props.history.goBack();
    }

    modalRouting(hash){
        addClassToHtml();
        switch(hash){
			case '#cancelPopUp':
				this.setState({cancelPopUp: true,cancelModal: false});
				break;

			case '#cancelModal':
				this.setState({cancelModal: true,cancelPopUp:false});
				break;

            default:
                this.setState({
                	cancelPopUp:false,
                	cancelModal:false,
                });
                removeClassToHtml();
        }
    }

	cancelPopUpOpen(){
		window.location.hash = 'cancelPopUp';
	}

	cancelModalOpen(){
		window.location.replace('#cancelModal')
	}

	toProperty() {
		this.props.updatePropertyState({
			'property_hash_id': this.props.request.info.property_hash_id,
			'property_images': this.props.request.property_section.tile.property_images,
			'property_id': this.props.request.property_section.tile.property_id,
			'title': this.props.request.property_section.tile.title,
			'apiData': false,
			'property_type': this.props.request.property_section.tile.property_type,
		})
		this.props.updateBookingDataState({
			'location': this.props.request.property_section.tile.location.location_name,
			'lat': this.props.request.property_section.tile.location.latitude,
			'lng': this.props.request.property_section.tile.location.longitude,
			checkin_date: 1,
			checkout_date: 1,
			guest_count: 0,
			unit_count: 0

		})

		this.props.history.push({
			pathname: '/property/' + this.props.request.info.property_hash_id,
		});

	}
	

	render() {
		const this_el= this;
		if(this_el.props.request.booking_status.status === 0){
			this_el.apiCall();
		}
		return (
			<div className="booking-details afterlogin">
				{!this.props.error.flag.network || this.props.request.requestHash === this.props.match.params.hashId ?
				<div>
					{!this.props.error.flag.server ?
					<div>
					<OfflineStatus
						offline={checkIfOffline()}
						bottom={this.props.request.booking_status.status !== 0}
					/>
					<header className={this.props.request.isTop?'header ': 'header ' + this.props.request.booking_status.class}>
						<div className="row">
							<div className="col-6 text-left">
								<div className="back-icon" onClick={this.goBack}></div>
							</div>
							<div className="col-6 text-right">
								{this.props.request.cancellation_section === 1 &&
									<div className="cancelled-btn" onClick={this.cancelPopUpOpen}></div>
								}
							</div>
						</div>
					</header>
						<div className={"payment-response " + this.props.request.booking_status.class}>
							{this.props.request.booking_status.status === -1 || this.props.request.booking_status.status === 0 ?
								<div className="timer">
									{this.props.request.seconds === 0 ?
										<span>00:00:00</span>
										:
										<span>{this.state.time.h}:{this.state.time.m}:{this.state.time.s}</span>
									}
								</div>
							:
								<div className={'image ' + this.props.request.booking_status.class}></div>
							}
						<div className="text">
							{this.props.request.booking_status.status === 0 &&
								<span>We have blocked this property for you!<br/>Pay before the timer runs out.</span>
							}
							{this.props.request.booking_status.status === -5 &&
								<span>Oh! You have cancelled your request.</span>
							}
							{(this.props.request.booking_status.status === -4 || this.props.request.booking_status.status === -7) &&
								<span>Sorry! Your payment time has been expired.</span>
							}
							{this.props.request.booking_status.status === -2 &&
								<span>Sorry! This property is not available for your selected dates.</span>
							}
							{this.props.request.booking_status.status === -1 &&
								<span>We’re checking availability with the host!<br/>Once confirmed, you can proceed to pay.</span>
							}
						</div>
					</div>
					<div className="request-wrap">
						<div className="row">
							<div className="col-6">
								<div className="booking-date">{this.toDate(this.props.request.info.checkin_formatted)} - {this.props.request.info.checkout_formatted}</div>
								<div className="booking-details">{this.props.request.info.guests} {this.props.request.info.guests > 1 ? 'Guests' : 'Guest'}, {this.props.request.info.units} {this.props.request.info.units > 1 ? 'Rooms':'Room'}</div>
							</div>
							<div className="col-6">
								<div className="modify-btn" onClick={this.toProperty}>View property <span className="arrow-icon"></span></div>
							</div>
						</div>
					</div>

					{this.props.request.booking_status.status === -5 
						?
						<div className="cancel-by-user">
								<div className="row">   
									<div className="col-12">
										<div className="heading"><span className="icon request-details"></span> Request details</div>
									</div>
								</div>
								<div className="box">
									<div className="row">   
										<div className="col-6">
											<div className="icon request-details"></div>
											<div className="wrap">
												<div className="title">Request ID</div>
												<div className="subtitle">{this.props.request.info.request_hash_id}</div>
											</div>
										</div>
										<div className="col-6">
											<div className="icon guest"></div>
											<div className="wrap">
												<div className="title">No. of Guests</div>
												<div className="subtitle">{this.props.request.info.guests}</div>
											</div>
										</div>
										<div className="col-6">
											<div className="icon checkin"></div>
											<div className="wrap">
												<div className="title">Check in</div>
												<div className="subtitle">{this.props.request.info.checkin_formatted}</div>
											</div>
										</div>
										<div className="col-6">
											<div className="icon checkout"></div>
											<div className="wrap">
												<div className="title">Check out</div>
												<div className="subtitle">{this.props.request.info.checkout_formatted}</div>
											</div>
										</div>
									</div>
								</div>
						</div>
						:
						<div className="invoice">
							<div className="row">
								<div className="col-6">
									<div className="heading"><span className="invoice-icon"></span> Invoice</div>
								</div>
								<div className="col-6">
									<div className="requestID">Request ID : {this.props.request.info.request_hash_id}</div>
								</div>
							</div>
							<div className="invoice-details">
								<section className="first">
									{this.props.request.invoice_header.map(function(item, index){
										if(item.show === 1){
											return(
												<div className="cell" key={index}>
													<div className="row">
														<div className="col-8 text-left">
															<div className="title">{item.key}</div>
															<div className="subtitle">{item.sub_key}</div>
														</div>
														<div className="col-4 text-right">                                    
															<div className="cost">{item.value}</div>
														</div>
													</div>
												</div>
											)
										} else {
											return false;
										}
									})}
								</section> 
								
								<section className="second">
									{this.props.request.invoice_middle.map(function(item, index){
										const color =item.hasOwnProperty("color") ? {color : item.color} : {};
										const bold =item.hasOwnProperty("bold") ? {fontWeight:'bold',color: '#7b7b7b', fontSize:'16px'}:{};
										if(item.show === 1){
											return(
												<div className="cell" key={index}>
													<div className="row">
														<div className="col-8 text-left">
															{item.bold === 1 ?
															<div className="title" style={bold}>{item.key}</div>
															:
															<div className="title" style={color}>{item.key}</div>
															}
															<div className="subtitle">{item.sub_key}</div>
														</div>
														<div className="col-4 text-right">   
															{item.bold === 1 ?                                 
															<div className="cost" style={bold}>{item.value}</div>
															:
															<div className="cost" style={color}>{item.value}</div>
															}
														</div>
													</div>
												</div>
											)
										} else {
											return false;
										}
									})}
								</section> 
								<section className="third">
									{this.props.request.invoice_footer.map(function(item, index){
										const color =item.hasOwnProperty("color") ? {color : item.color} : {};
										const bold =item.hasOwnProperty("bold") ? {fontWeight:'bold',color: '#7b7b7b', fontSize:'16px'}:{};
										if(item.show === 1){
											return(
												<div className="payable" key={index}>
													<div className="row">
														<div className="col-8 text-left">
															{item.bold === 1 ? 
																<div className="title"style={bold}>{item.key}</div>
																:
																<div className="title">{item.key}
																	{item.sub_key.length > 0  &&
																		<div className="green-color">{item.sub_key}</div>
																	}
																</div>
															}
														</div>
														<div className="col-4 text-right"> 
															{item.bold === 1 ?                                   
															<div className="cost" style={bold}>{item.value}</div>
															:
																<div className="cost" style={color}>{item.value}</div>
															}
														</div>
													</div>
												</div>
											)
										} else {
											return false;
										}
									})}
								</section>
								{this.props.request.info.resend_request === 1 &&
									<div className="button" onClick={() => this.ResendRequest(this.props.request.request_hash_id)}>Resend request</div>
								}
								{this.props.request.info.check_other_date === 1 &&
									<div className="button" onClick={this.checkOtherDate}>Check other dates</div>
								}
							</div>   
							{this.props.request.booking_status.status === 0 &&
								<div className="approved-text">Availbility for this home is confirmed. Please proceed with the payment within 30 minutes before time runs out
								</div>
							}
							{this.props.request.booking_status.status === 0 &&
								<div className="property-bottom clearfix">
									<div className="col-6 pull-left">
										<h5>Full refund
											<span> Free cancellation</span>
										</h5>
									</div>
									<div className="col-6 pull-left">
										<button className="btn waves-effect waves-light pull-right" onClick={this.toPrepayment}>Book Now</button>
									</div>
								</div>
							}
						</div>
					}
					
					<CancelPopUp
						cancelPopUpOpen={this.state.cancelPopUp}
						cancelPopUpClose={this.goBack}
						cancelModalOpen={this.cancelModalOpen}
					/>

					<CancelModal
						cancelModalOpen={this.state.cancelModal}
						cancelModalClose={this.goBack}
						cancelRequest={this.cancelRequest}
						gotoRequest={this.state.request}
						booking_status={this.props.request.booking_status}
						info={this.props.request.info}
						cancellation_reasons={this.props.request.cancellation_reasons}
					/>

					<RequestApprovedModel
						requestApprovedOpenModel={this.state.requestApproved}
						requestApprovedCloseModel={this.requestApprovedCloseModel}
					/>
					
				</div>
				:
				<div className="info-window">
					<div className="window-container">
						<div className="imgb">
							<div className="img" style={{ backgroundImage: "url(" + serverError + ")" }}/>
						</div>
						<div className="txtb">
							<p>Sorry, our servers are busy. Please try again in a bit.</p>
						</div>
						<button className="retry" onClick={this.reload}>Retry</button>
					</div>
				</div>
				}
				</div>
				:
				<div className="info-window no-internet">
							<div className="window-container">
									<header className="header active">
										<div className="row">
											<span className="back-icon" onClick={() =>	this.goBack()}></span>
										</div>
									</header>
									<div className="imgb">
											<div className="img" style={{backgroundImage:'url(' + noInternet + ')'}}></div>
									</div>
									<div className="txtb"><p>Oops! You don't seem to be connected to the internet.</p></div>
									<button className="retry" onClick={this.reload}>Retry</button>
							</div>
					</div>
				}
			</div>  
		);
	}
}

const mapStateToProps = (store) => {
	return {
		request: store.request,
		tracker: store.tracker.tracker,
		error: store.error,
		prepayment: store.prepayment
	}
}

const mapDispatchToProps = {
	updateRequestState,
	updateErrorState,
	updateBookingDataState,
	updatePropertyState,
	updatePrepaymentState
}

export default connect(mapStateToProps, mapDispatchToProps)(RequestDetails);