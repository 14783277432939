// actions
import { SEARCH_UPDATE_STATE, SEARCH_UPDATE_STATE_RESET, SEARCH_UPDATE_STATE_RESET_FILTER } from "../actions/search/vars";

import {cloneObj} from './../services/helpers.js';

/**
* Pulling recent popular search from localstorage, if it found then update recent popular search data otherwise set it blank
*/
const recentPopularSearch = () =>{
    let recentSearch = localStorage.getItem('recentSearch');
    if(typeof recentSearch === undefined || typeof recentSearch === 'undefined' || recentSearch === null){
        return [];
    }
    else{
        return JSON.parse(recentSearch);
    }
}


// defining dates
let todays_date = new Date();
let year = todays_date.getFullYear();
let month = todays_date.getMonth();
let day = todays_date.getDate();



const filter = {
    'webicon' : '',
    'price' :{
        'min' : 100,
        'max' : 70000
    },
    'cash_on_arrival' : 0, // pay later
    'instant_book' : 0, // instant book
    'roomtype' : 0, // entire home
    'bedroom' : 0, //bedroom
    
    'amenities':[], // amenities,
    'area' : [], // location,
    'property_type' : [] // accomodation
};

const filter_replica = JSON.parse(JSON.stringify(filter));
const initial_filter_replica = JSON.parse(JSON.stringify(filter));
//const filter_replica = Object.assign({}, filter);
//filter_replica.price.max = 5000;


// initialState
const initialState = {
    
    page : 'search',
    shake_animation : '',
    
    min_date: new Date(year, month, day),
    max_date: new Date(year + 1, month, day - 1),
    header : {
        'recent_popular_search' : recentPopularSearch(),
        'address' : '',
    },
    property_list : [],
    filter : {
        values : {},
        filter_initial : initial_filter_replica, // this key is used to compare if any filter is applied or not
        filter : filter,
        filter_replica : filter_replica,
        slider : {
            min : 100,
            max : 70000
        }
    },

    offset : 0,
    per_page : 10, // per page search result pull
    filter_btn_class : '',
    seo_content:{},
    search_address_data:[],
    canonical_url:'',
    keyword:'',
    title_prefix:'',
    slug : '',
    sort : '',
    promo : '', 
    meta : {
        desc : '',
        title : '',
        url : ''
    },
    scrollState: [],
    dataLoaded: false,
    flag : {
        api : {
            is_loading : false,
        },
        modal: {
            date_range: false,
            select_guest: false,
            filter : false,
            accomodation : false,
            amenity : false,
            area : false
        },
        no_more_result : false,
        popular_search_data: true,
        property_preview : false,
        prepayment : false,
        stay_page : false,
        filter_applied : false,
        show_loader : true,
        search_page:false
    },
    fromSearch: false,
    shake_animation_dates: '',
    temp_dates: {
        checkin_date: null,
        checkout_date: null
    }
}



// action functions
const fnc = (state = initialState, action) => {
    switch (action.type) {

        case SEARCH_UPDATE_STATE:
            return Object.assign({}, state, action.payload);
        
        case SEARCH_UPDATE_STATE_RESET:
            return initialState;

        case SEARCH_UPDATE_STATE_RESET_FILTER:

            let store_state = cloneObj(state);
            store_state.filter.filter_replica = JSON.parse(JSON.stringify(initialState.filter.filter_replica));
            return store_state;

        case 'CLEAR_MODALS':
            return {
                ...state,
                flag: {
                    ...state.flag,
                    modal: {
                        date_range: false,
                        select_guest: false,
                        filter : false,
                        accomodation : false,
                        amenity : false,
                        area : false
                    }
                }
            }

        default:
            return Object.assign({}, state);
    }
};
export default fnc;
