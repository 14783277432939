import React from 'react';
// This is another lazy load library, not the same
// we are using react-lazyload at other places
import LazyLoad from 'react-lazy-load';
import { connect } from 'react-redux';
import { withRouter } from 'react-router';
import OfflineStatus from "../Offlinestatus";
import { addClassToBody } from "../../services";

/**
 * Actions
 */
import { updatePropertyState } from '../../actions/property/actions';
import { checkIfOffline } from '../../services/helpers';

class PropertyGalleryList extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      offlineStatus: false
    };
    this.openGallerySlider = this.openGallerySlider.bind(this);
    this.goBack = this.goBack.bind(this);
  }

  imageLoaded(e) {
    let imgSrc = e.target.src;
    e.target.nextElementSibling.style.backgroundImage = "url(" + imgSrc + ")";
  }

  componentDidMount() {
    setTimeout(() => {
      this.forceUpdate();
    }, 2000);

    if (checkIfOffline()) {
      this.changeOfflineStatus();
    }
  }

  componentDidUpdate() {
    if (!this.props.location.pathname.includes("gallerySlider")) {
      document.body.style = ``;
      if (window.listenerFn) {
        window.removeEventListener("orientationchange", window.listenerFn);
      }
      if (window.mainListenerFn) {
        window.removeEventListener("orientationchange", window.mainListenerFn);
      }
    }
  }

  openGallerySlider(sliderIndex) {
    if (!checkIfOffline()) {
      let finalSliderIndex;
      if (this.props.property_video.length > 0) {
        finalSliderIndex = sliderIndex + 1;
      } else {
        finalSliderIndex = sliderIndex;
      }
      this.props
        .updatePropertyState({
          slider_index: finalSliderIndex,
          galleryLoaded: false
        })
        .then(() => {
          sessionStorage.setItem("scrollVal", Math.max(window.pageYOffset, document.documentElement.scrollTop, document.body.scrollTop));
          addClassToBody();
          this.props.history.push(
            "/property/gallerySlider/" + this.props.property.property_hash_id
          );
        });
    } else {
      this.changeOfflineStatus();
    }
  }

  changeOfflineStatus() {
    this.setState(
      {
        offlineStatus: true
      },
      () => {
        setTimeout(() => {
          this.setState({
            offlineStatus: false
          });
        }, 200);
      }
    );
  }

  goBack() {
    this.props.history.goBack();
  }

  render() {
    const this_el = this;
    if (this.props.apiData) {
      return (
        <div>
          <div className="overlay-modal">
            <div className="content-modal full-screen-modal property-gallery-list">
              <div className="filter-modal">
                <div className="modal-header">
                  <div className="back-btn header-btn" onClick={this.goBack} />
                  <h2 className="text-center">Photos and Videos</h2>
                  <div className="transparent-gredient" />
                </div>
                <div className="modal-body">
                  <OfflineStatus
                    offline={checkIfOffline() && this_el.state.offlineStatus}
                    dontClear
                    bottom
                  />

                  <ul>
                    {this.props.property_video.map(function(item, index) {
                      return (
                        <li key={index}>
                          <LazyLoad
                            height={125}
                            debounce={false}
                            threshold={1100}
                          >
                            <div>
                              <img
                                src={item.thumbnail}
                                onLoad={e => this_el.imageLoaded(e)}
                                className="hidden"
                                alt="thumb"
                              />
                              <div
                                onClick={() => this_el.openGallerySlider(-1)}
                              >
                                <i className="play-btn" />
                              </div>
                            </div>
                          </LazyLoad>
                        </li>
                      );
                    })}
                    {this.props.property_images.map(function(item, index) {
                      let thumb = item.image.replace("2x", "thumb");
                      return (
                        <li key={index}>
                          <LazyLoad
                            height={125}
                            debounce={false}
                            threshold={1100}
                          >
                            <div>
                              <img
                                src={thumb}
                                onLoad={e => this_el.imageLoaded(e)}
                                className="hidden"
                                alt="thumb"
                              />
                              <div
                                onClick={() => this_el.openGallerySlider(index)}
                              />
                            </div>
                          </LazyLoad>
                        </li>
                      );
                    })}
                  </ul>
                </div>
              </div>
            </div>
          </div>
        </div>
      );
    } else {
      return <div />;
    }
  }
}

const mapStateToProps = (store) => {
	return {
		property_images: store.property.property_images,
		property_video: store.property.property_video,
		apiData: store.property.flag.api.is_loading,
		property: store.property
	}
}
const mapDispatchToProps = {
	updatePropertyState
}

export default connect(mapStateToProps, mapDispatchToProps)(withRouter(PropertyGalleryList));