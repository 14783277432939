import React from 'react';
import LazyLoad from 'react-lazyload';
import { withRouter } from 'react-router';
import { connect } from "react-redux";

import TrendingCitiesModal from './modals/trendingCities/';
import {removeClassToHtml} from '../../services/index.js';
import {locationNameFormatter} from '../../services/helpers';

import { trackGoToTrendingCitiesListModal, trackGoToStayPage} from '../../services/tracker/events.js';


// Actions
import { updateBookingDataState}  from './../../actions/booking-data/actions';
import { updateSearchState } from '../../actions/search/actions';

class HomeTrending extends React.Component {

	constructor(props){
		super(props);
		this.trandingSearchSubmit = this.trandingSearchSubmit.bind(this);
		this.openTrendingCitiesModal =  this.openTrendingCitiesModal.bind(this);
		this.openTrendingCitiesModal =  this.openTrendingCitiesModal.bind(this);
	}

	trandingSearchSubmit(e){
		const this_el = this;
		const key_index = e.target.dataset.index;
		const current_city_data = this_el.props.home.popular_cities[key_index];

		this.props.updateSearchState({
			search_address_data : {
				...this.props.search.search_address_data,
				city: current_city_data.city,
				state: current_city_data.state,
				country: current_city_data.country,
				lat: current_city_data.latitude,
				lng: current_city_data.longitude,
				location : locationNameFormatter(current_city_data.city, current_city_data.state)
			}
		})

		this.props.updateHomeState({
			trendingScrollState: this.containerRef ? [this.containerRef.scrollTop] : []
		})

		this_el.props.updateBookingDataState({
			city: current_city_data.city,
			state: current_city_data.state,
			country: current_city_data.country,
			lat: current_city_data.latitude,
			lng: current_city_data.longitude,
			location : locationNameFormatter(current_city_data.city, current_city_data.state)
		}).then(() => {
			// redirecting to stay page
			let url = "/" + current_city_data.url;
			this_el.props.history.push({ pathname:  url});
			this.props.updateHomeState({
				homeHistory: [
					...this.props.home.homeHistory,
					url
				]
			})
			localStorage.setItem('homeHistory', JSON.stringify([
				...this.props.home.homeHistory,
				url
			]))
			trackGoToStayPage(
				this_el.props.tracker,
				this_el.props.home.page,
				"trending_cities_tile",
				url
			);
			removeClassToHtml();
		});
	}

	openTrendingCitiesModal(){
		window.location.hash = 'trendingCities';
		trackGoToTrendingCitiesListModal(this.props.tracker, this.props.page, 'trending_cities_see_more_btn');
	}

	render() {
		const this_el = this;
		return (
			<div>
				<div className="heading-section">
					<h2 className="heading">Trending Cities</h2>
					<span className="description">{this_el.props.home.popular_cities_subheading}</span>
				</div>

				<div className="trending-inner-section center">
					<div className="row">
						{this_el.props.home.popular_cities.map(function(item, index) {
							if (index <= 5) {
								return (
									<div className="col-4 text-center" key={index}>
										<div className="trending-item" data-index={index} onClick={this_el.trandingSearchSubmit}>
											<LazyLoad height={100}>
												<div className="imgb" style={{
													backgroundImage: 'url('+item.property_images[0]+')',
												}}></div>
											</LazyLoad>
												<h3>{item.title}</h3>
										</div>
									</div>
								);
							}else {
								return false;
							}
						})}
					{this_el.props.home.popular_cities.length > 0 &&
						<div className="col-12 text-center">
					 		<span className="see-all text-center" onClick={this.openTrendingCitiesModal}>See all</span>
						 </div>
					}
					</div>
					
				</div>
				<TrendingCitiesModal home={this.props.home} trandingSearchSubmit={this_el.trandingSearchSubmit} getRef={r => {this.containerRef = r}} />


			</div>
		);
	}
}


const mapStateToProps = store => {
	return {
		home: store.home,
		booking : store.booking,
		search: store.search
	};
};

const mapDispatchToProps = {
	updateBookingDataState,
	updateSearchState,
};

export default connect(
	mapStateToProps,
	mapDispatchToProps
)(withRouter(HomeTrending));
