import React from 'react';
import Modal from 'react-modal';
import { withRouter } from 'react-router';

import {connect} from 'react-redux';
import { getObjectVal} from '../../../services/helpers.js';
// actions
import { updateSearchState} from './../../../actions//search/actions.js';


class AccommodationList extends React.Component {
 	constructor(props){


 		super(props);

 		// in use methods
 		this.closeModal = this.closeModal.bind(this);


 		this.showSubmitButton = this.showSubmitButton.bind(this);
 		this.isSelected = this.isSelected.bind(this);
 		this.submit = this.submit.bind(this);
 		this.localSelected = this.localSelected.bind(this);
 		this.onChange = this.onChange.bind(this);
 		this.clear = this.clear.bind(this);

		 this.state = {
			 selectedList: []
		 }
 	}


 	closeModal(){
 		const this_el = this;

 		let flag = Object.assign({}, this_el.props.search.flag);
 		flag.modal.accomodation = false;

 		this_el.props.updateSearchState({
 			flag : flag
 		});
 	}

 	isSelected(item){

 		
 		const this_el = this;
 		let selected = this_el.props.search.filter.filter_replica.property_type;

 		if(selected.length){
 			for (var key in selected) {
			    const id = selected[key];
			    if(item.id === id){
					return true;
			    }
			}
 		}

 		return false;
 	}

    onChange(e, name){
			console.log(name)
			if (this.state.selectedList.includes(name)) {
				this.setState({
					selectedList: this.state.selectedList.filter(x => x !== name)
				})
			} else {
				this.setState(({ selectedList: [ ...this.state.selectedList, name ] }))
			}

			this.showSubmitButton();
    }

    localSelected(){

    	const this_el = this;
    	let list = this_el.props.search.filter.values.property_types;
    	let localSelected = [];


    	if(list){
    		for(var key in list){
    			const row = list[key];
    			let checkbox = this_el.refs[row.id];
    			if(checkbox !== undefined && checkbox.checked){
    				localSelected.push(row.id);
    			}
    		}
    	}

    	return localSelected;
    }

    showSubmitButton(){

    	const this_el = this;
 		let selected = this_el.props.search.filter.filter_replica.property_type;
 		let localSelected = this.localSelected();

    	if(localSelected.length !== selected.length){
    		this.refs.floatBtn.classList.add('active');		
    		return;
    	}
    	else{

    		if(selected){
	    		for(var key in selected){
	    			const selected_id = selected[key];
	    			if(localSelected.indexOf(parseInt(selected_id, 10)) === -1){
	    				this.refs.floatBtn.classList.add('active');		
    					return;				
	    			}
	    		}
	    	}

    	}
    	this.refs.floatBtn.classList.remove('active');
    }

   
	submit(){
		/**
		 * write your code to submit selected property type to main filter
		 */

		const this_el = this;
		let localSelected = this.localSelected();
		localSelected.sort(function(a, b){return a - b}) // sorting ids ASC

		let searchObj = Object.assign({}, this_el.props.search);
		let filter_replica = Object.assign({}, searchObj.filter.filter_replica);
		filter_replica.property_type = localSelected;

		searchObj.filter.filter_replica = filter_replica;
		searchObj.flag.modal.accomodation = false;

		this_el.props.updateSearchState(searchObj).then(() => {
			this_el.props.checkFilterChange();
		});
	}



	/**
	 * Clear all selected checboxes
	 */
	
	clear(){

		const this_el = this;
		this.setState({
			selectedList: []
		}, () => {
			setTimeout(() => {
				let list = this_el.props.search.filter.values.property_types;

				if(list){
					for(let key in list){
						const row = list[key];
						let checkbox = this_el.refs[row.id];
						if(checkbox !== undefined && typeof checkbox.removeAttribute !== 'undefined'){
							checkbox.checked = false; 
							checkbox.removeAttribute('checked');
						}
	
						//if(checkbox != undefined) checkbox.checked = false; // code not in use
					}
				}
	
				this_el.showSubmitButton();
			})
		})
	}

	componentDidMount () {
		if (this.props.search.filter.filter_replica.property_type.length > 0) {
			this.setState({
				selectedList: this.props.search.filter.filter_replica.property_type
			})
		}
	}



	render(){
		const this_el = this;
		return(
			<div>
				<Modal
					isOpen={this_el.props.search.flag.modal.accomodation}
					onRequestClose={this_el.closeModal}
					contentLabel="Modal"
					//onAfterOpen={}
					//
					overlayClassName={{
						base: 'overlay-modal',
						afterOpen: '',
						beforeClose: ''
					}}
					className={{
						base: 'content-modal',
						afterOpen: 'full-screen-modal',
						beforeClose: ''
					}}>
					<form>
						<div className="filter-modal">
							<div className="modal-header">
								<div className="header-btn back-btn" onClick={this_el.closeModal}></div>
								<h2 className="text-center">Filter by accommodation</h2>
								<button className={`reset-btn ${this.state.selectedList.length === 0 && 'disabled'}`} type="reset" value="reset" onClick={() => this.state.selectedList.length !== 0 && this_el.clear()} >Clear</button>
	                            <div className='transparent-gredient'></div>
							</div>
							<div className="modal-body">
								<div className="amenities-filter">
									<ul className="amenities-list" id='accommodationList'>

										{getObjectVal(this_el.props.search.filter.values, 'property_types') && this_el.props.search.filter.values.property_types.map(function(item, index){
											if(this_el.isSelected(item)){
												return (
													<li key={index} >
														<label>
															{item.name} 
															<input type="checkbox" name={item.name} className="pull-right checkbox" id={item.id} 
															value={item.name}
															onChange={(e) => this_el.onChange(e, item.name)} 
															ref={item.id}
															defaultChecked={true}/>
															<span className="pull-right"></span>
														</label>
													</li>
												)
											} else {
												return false;
											}
												
										})}

										{getObjectVal(this_el.props.search.filter.values, 'property_types') && this_el.props.search.filter.values.property_types.map(function(item, index){
												if(!this_el.isSelected(item) && item.show === 1){
													return (
														<li key={index} >
															<label>
																{item.name} 
																<input type="checkbox" name={item.name} className="pull-right checkbox" id={item.id} 
																	onChange={(e) => this_el.onChange(e, item.name)} 
																	ref={item.id}
																/>
																<span className="pull-right"></span>
															</label>
														</li>
													)
												} else {
													return false;
												}
												
											})
										}
										
									</ul>
								</div>
								<div className="floating-btn-box text-center" ref='floatBtn'>
									<span className="floating-btn" onClick={this.submit}>Done</span>
								</div>
							</div>
						</div>
					</form>
				</Modal>
			</div>
		)
	}
}



const mapStateToProps = store => {
	return {
		booking: store.booking,
		tracker: store.tracker.tracker,
		error : store.error,
		search : store.search
	};
};

const mapDispatchToProps = {
	updateSearchState
};

export default connect(mapStateToProps,mapDispatchToProps)(withRouter(AccommodationList));

