import {SET_STATE_OTP_OBJ} from './../actions/otp/vars';
const initialState = {
    'body_bg_color': '#fff',
    'show_send_otp': true,
    'otp_method': 1,
    'referral_code':'',
    'model':{
        'contact_number' :localStorage.getItem('contact_number') ? localStorage.getItem('contact_number') : null,
        'dial_code': '91',
    },
    'dial_codes': [],
    'message':'',
    'loading':false,
    'show_referral_code': false,
    'referral_text': true,
    'dial_code_list':[],
    'show':false,
    'validation':true,
    'show_resend_otp': false,
    'inputValue':['', '', '', ''],
    'status':false,
    'otpTimer':0,
}

const otp = (state = initialState, action) => {
    switch (action.type) {
        case SET_STATE_OTP_OBJ :
            let newState = Object.assign({}, state, action.payload);
            return newState;
            
        default:
            newState = Object.assign({}, state);
            return newState;
    }
}
export default otp;