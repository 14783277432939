import React from 'react';
import Modal from 'react-modal';
import { connect } from 'react-redux';

class CancelPopUpTrip extends React.Component {
  render() {
	return (
	  <div>
		<Modal
		  isOpen={this.props.cancelPopUpOpen}
		  onAfterOpen={this.afterOpenModal}
		  onRequestClose={this.props.cancelPopUpClose}
		  contentLabel="Modal"
				overlayClassName={{
				base: 'overlay-modal overlay-popup',
				afterOpen: '',
				beforeClose: ''
			  }}
		  className={{
				base: 'content-modal',
				afterOpen: 'editProfile request-modal',
				beforeClose: ''
			  }}
			>
		  <div className="modal-body text-center">
			  <span className="back-btn" onClick={this.props.cancelPopUpClose}></span>
			  <div className="text-cacel">{this.props.tripDetailData.cancel_text}</div>
			  <button className="link green" onClick={this.props.cancelModalOpen}>Cancel booking</button>
		  </div>
		</Modal>
	  </div>
	);
  }
}

const mapStateToProps = (store) => {
	return {
		tripDetailData: store.tripData,
	}
}

export default connect(mapStateToProps, null)(CancelPopUpTrip);