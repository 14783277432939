import React from 'react';
import DownloadApp from "../../components/modal/downloadApp";
import {getURLParameter} from '../../services/index.js';
import {trackPageView} from '../../services/tracker/events.js';
import {connect} from 'react-redux';
import OfflineStatus from '../../components/Offlinestatus';
import { checkIfOffline } from '../../services/helpers';
import { updateErrorState } from '../../actions/error/actions';
class TalkToUs extends React.Component {
	constructor() {
		super();
		this.page="talk_to_us";
		this.state = {
			downloadAppOpen: false,
			referEarn:false,
		};

		this.downloadAppOpen = this.downloadAppOpen.bind(this);
		this.modalRouting=this.modalRouting.bind(this);
        this.goBack = this.goBack.bind(this);
	}
	componentDidMount() {
         // on url change fuction
        let this_el = this;
        window.onpopstate = function(e) {
            this_el.modalRouting(e.currentTarget.location.hash);
        };

		if (checkIfOffline()) {
			this.props.updateErrorState({ flag: { network: true } });
		} else {
			this.props.updateErrorState({ flag: { network: false } });
		}

        // fuction to check if any modal open
        this.modalRouting(this.props.history.location.hash);
		trackPageView(this.props.tracker, this.page);
    }

	modalRouting(hash){
        switch(hash){
            case '#downloadAppOpen':
                this.setState({downloadAppOpen: true});
                break;
            default:
            this.setState({
                downloadAppOpen:false,
            });
        }
    }
	downloadAppOpen() {
		window.location.hash = 'downloadAppOpen'
	}
	goBack(){
        this.props.history.goBack();
    }
    render() {
		let fromAccount = false;
		if(this.props.location.search.includes('fromAccount')){
			fromAccount = getURLParameter(this.props.location.search, 'fromAccount');
		} else {
			fromAccount = false;
		}

        return (
	      	<div className="talk-to-us">
	      		<header className="header active">
	      			<div className="row">
						<div className="col-12 text-left">
							{fromAccount &&
								<span className="back-icon" onClick={() => this.props.history.goBack()}></span>
							}
							<span className="middle-text text-center">Talk to us</span>
						</div>														
					</div>
	      		</header>
				<OfflineStatus
					offline={checkIfOffline()}
				/>
	      		<div className="outer-box" onClick={this.downloadAppOpen}>
	      			<div className="inner-box">
	      				<span className="icon chat"></span>
						<span className="text">Chat with our customer service<br/> team instantly</span>
						<span className="icons arrow-dark-gray pull-right"></span>
	      			</div>
	      			<div className="inner-box">
	      				<span className="icon email-icon"></span>
						<span className="text">Write an email regarding any <br/>issues or feedback</span>
						<span className="icons arrow-dark-gray pull-right"></span>		      				
	      			</div>
	      			<div className="inner-box">
	      				<span className="icon phone"></span>
						<span className="text">Contact with us on phone. <br/>Available all days, 9:00am - 9:00pm</span>
						<span className="icons arrow-dark-gray pull-right"></span>		      				
	      			</div>
	      		</div>
	      		<DownloadApp goBack={this.goBack} downloadAppOpen={this.state.downloadAppOpen} />
	      	</div>	
        );
    }
}
const mapStateToProps = (store) => { 
    return {
		tracker: store.tracker.tracker,
		error: store.error
    }
}
const mapDispatchToProps = {
	updateErrorState
};
export default connect(mapStateToProps, mapDispatchToProps) (TalkToUs);