import { BOOKING_DATA_UPDATE_STATE, BOOKING_DATA_UPDATE_STATE_RESET } from "./vars";
export const updateBookingDataState = (obj = {}, reset = 0) => dispatch => {

	if(!reset){
		dispatch({
			type: BOOKING_DATA_UPDATE_STATE,
			payload: obj
		});
	}else{
		dispatch({
			type: BOOKING_DATA_UPDATE_STATE_RESET,
			payload: obj
		});
	}
	return Promise.resolve();
};
