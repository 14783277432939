import React from 'react';
import SearchLocationModal from './searchLocation.js';
import DateRangeCalendar from './dateRangeCalendar.js';
import SelectGuestsModal from './selectGuestsModal.js';
import { connect } from "react-redux";
import { Route } from 'react-router-dom'
import {resumeBodyScroll} from '../../../services/index.js';


/**
 * Actions
 */
import {updateBookingDataState}  from './../../../actions/booking-data/actions';
import { updateSearchState }  from './../../../actions/search/actions';
import { updateHomeState } from '../../../actions/home/actions'

class SearchBarContainer extends React.Component {

	constructor(props){
		super(props);
		this.state = {
			dateRangeCalendar:false,
			shakeAnimation:'',
			searchLocationRender:false,
			clearSearch: props.location.pathname === '/home/searchlocation' ? true: false,
			resettedDates: {},
		}
		
		this.openLocationModal = this.openLocationModal.bind(this);
		this.closeDateRangeCalendar = this.closeDateRangeCalendar.bind(this);
		this.openDateRangeCalendar = this.openDateRangeCalendar.bind(this);
		this.closeSelectGuestsModal = this.closeSelectGuestsModal.bind(this);
		this.openSelectGuestsModal = this.openSelectGuestsModal.bind(this);
		this.submitSearchData = this.submitSearchData.bind(this);
		this.resetDateRangeCalendar = this.resetDateRangeCalendar.bind(this);
	}

	componentDidMount() {
		this.setState({
			searchLocationRender:true
		})
	}

	openLocationModal(){
		this.props.history.push('/home/searchlocation')
		this.setState({ clearSearch: true })
		this.props.updateHomeState({
			homeHistory: [
				...this.props.home.homeHistory,
				'/home/searchlocation'
			]
		})
		localStorage.setItem('homeHistory', JSON.stringify([
			...this.props.home.homeHistory,
			'/home/searchlocation'
		]))
		window.scroll(0,0);
	}

	closeDateRangeCalendar(f){
		this.setState({
			dateRangeCalendar:false
		}, ()=>{
			if(typeof f === "function"){
				f();
			}
		});
	}

	openDateRangeCalendar(){
		alert('akdjalkdjkadj');
		let flag = Object.assign({}, this.props.search.flag);
		flag.modal.date_range = true;
		this.props.updateSearchState({
			flag : flag
		});
	}

	resetDateRangeCalendar(){
		this.props.updateBookingDataState({
			checkinDate: 1,
			checkoutDate: 1,
		});
	}

	closeSelectGuestsModal(e){
		let flag = Object.assign({}, this.props.search.flag);
		flag.modal.select_guest = false;
		this.props.updateSearchState({
			flag: flag
		});
	}

	openSelectGuestsModal(){

		let flag = Object.assign({}, this.props.search.flag);
		flag.modal.select_guest = true;
		this.props.updateSearchState({
			flag: flag
		});

	}


	shakeGuestCount(){
		const this_el = this;
		this.setState({shakeAnimation:'shakeAnimation'})
		setTimeout(function(){
			this_el.setState({shakeAnimation:''})
		},500)
	}

	submitSearchData() {
		if(this.props.booking.guestCount < 1){
			this.shakeGuestCount();
			if ("vibrate" in navigator) {
				navigator.vibrate(500);

			}
		} else {

			// submit search
			this.props.passSearchData();
			resumeBodyScroll();
		}
	}

	render() {
		return (
			<div className="search-bar-container" onClick={this.openLocationModal}>
				<div className='search-input-box'>
					<span className='pull-right'><i className="search-icon"></i></span>
					Where do you want to go?
				</div>
				{this.state.searchLocationRender &&
					<div>
						<Route path='/home/searchlocation' render={props => <SearchLocationModal clearSearch={this.state.clearSearch} />} />

						<SelectGuestsModal resettedDates={this.state.resettedDates} />

						<DateRangeCalendar updateResettedDates={(dates, cb) => this.setState({ resettedDates: dates }, cb)} resettedDates={this.state.resettedDates} openMaxNightsAlert={this.props.openMaxNightsAlert} />
					</div>
				}
			</div>
		);
	}
}



const mapStateToProps = store => {
	return {
		booking: store.booking,
		search: store.search,
		home: store.home
	};
};

const mapDispatchToProps = {
	updateBookingDataState,
	updateSearchState,
	updateHomeState
};
export default connect(
	mapStateToProps,
	mapDispatchToProps
)(SearchBarContainer);
