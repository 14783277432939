

/* APIs */
// get signup data
let refreshLogin = function (data, args)
{
    //console.log(args)
    return data.AXIOS.post(data.BASE_URL+'/oauth/token', data.QS.stringify({
            refresh_token:args,
            client_id: data.CLIENT_ID,
            client_secret:data.CLIENT_SECRET,
            grant_type:data.REFRESH_GRAND_TYPE,
            scopes:data.SCOPE,
        }), {
            headers: {
                'Accept': 'application/json',
                'Content-Type': 'application/x-www-form-urlencoded',
            }
        }
    );
}

exports.refreshLogin = refreshLogin;