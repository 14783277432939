import React from 'react';
import {getSimilarListingDataApi} from '../../services/index.js';
import noInternet from "../../static/img/infoWindow/noInternet.svg";
import { connect } from 'react-redux';
import { withRouter } from 'react-router';
import { updatePropertyState } from '../../actions/property/actions';
import {trackError} from '../../services/tracker/events.js';
import SearchTile from '../../components/propertyTile';
import { updateBookingDataState } from '../../actions/booking-data/actions';
import { updateErrorState } from '../../actions/error/actions'
import { checkIfOffline } from '../../services/helpers.js';
import OfflineStatus from '../../components/Offlinestatus/index.js';


class SimilarListingAll extends React.Component{
	constructor(props){
		super(props);
		this.toProperty=this.toProperty.bind(this);
		
		this.state = {
			toggleOfflineStatus: false
		}
	}
    getsimilarListingDataSuccess(response) {
		const this_el = this;

		// pulling data from API response		
		let flag = Object.assign({}, this_el.props.property.flag);
		flag.no_more_result = (response.data.data.properties.length < 5); // if no more result flag found, set no_more_result to true
		flag.api.is_loading = false;

		let propertiesAll = Object.assign([], this_el.props.property.similar_properties_all);
		Array.prototype.push.apply(propertiesAll,  response.data.data.properties); 

		// updating single collection store
		this_el.props.updatePropertyState({
			'similar_properties_all': propertiesAll.filter((x, i, arr) => arr.findIndex(y => y.property_id === x.property_id) === i),
			similiarAllLoaded: true,
			flag:flag
		})

		this.props.updateErrorState({
			flag: {
				network: false
			}
		})
	}

	getsimilarListingDataError(error) {
		const this_el = this;
		if(typeof error.response !== 'undefined' && error.response.status === 1000){
			let flag = Object.assign({}, this_el.props.error.flag);
			flag.network = false;
			this_el.props.updateErrorState({
				flag : flag
			});
		}else{
			trackError(error);
		}
	}
    getSimilarListingApi(args){
		let this_el = this;
        getSimilarListingDataApi(args).then(function(response){ 
			// success
			this_el.getsimilarListingDataSuccess(response);
		})
		.catch(function(error) {
			// error
			this_el.getsimilarListingDataError(error);
		});
	}
	
	componentDidMount () {
		if (checkIfOffline()) {
			this.props.updateErrorState({
				flag: {
					network: true
				}
			})
			this.toggleStatus()
		} else {
			this.props.updateErrorState({ flag: { network: false } });
		}
	}

	componentWillMount() {
		const this_el = this;
        if(this.props.property.flag.new_similar_list){
            window.scroll(0,0);
		}

		/**
		 * Calling Api
		 */
		if(!this.props.property.similar_properties_all.length > 0){
			this_el.submitParams();
		}
		
		let flag = Object.assign({}, this_el.props.property.flag);
		flag.api.is_loading = false;
		this_el.props.updatePropertyState({
			flag: flag,
		})
		
		/**
		 * Active scroll listner
		 */
		this_el.loadMoreResults();
    }
	submitParams(){
		const this_el = this;
		let args = {
            id:this_el.props.match.params.property_hash_id,
            offset:this_el.props.property.offset,
			loader: true,
		}
		this_el.getSimilarListingApi(args);
		
		
    }
    
    toProperty(data) {
        const this_el = this;

        if (data !== undefined) {
            this_el.props.updatePropertyState({
                'property_hash_id': data.property_hash_id,
                'property_images': data.property_images,
                'property_id': data.property_id,
                'property_title': data.property_title,
                'title': data.title,
                'property_score': data.property_score,
                'host_image': data.host_image,
                'host_name': data.host_name,
                'apiData': false,
                'tags': data.property_tags,
                'property_type': data.property_type_name,
            })
            this_el.props.updateBookingDataState({
				'location': data.location.location_name,
				'lat':  data.location.latitude,
    			'lng': data.location.longitude,
            })
        }

        this_el.props.history.push({
			pathname: '/property/' + data.property_hash_id,
			search: this.props.location.search,
        });

    }

	loadMoreResults(){
		const this_el = this;
		
		window.onscroll = function(){
			let similar_listing =false;
            if (document.getElementById('similar_listing') !== null){
                similar_listing = true;
            }else {
                similar_listing = false;
			}
			if(similar_listing){
			//let offset = document.documentElement.scrollTop;
			const scrollTopOffset = Math.max(window.pageYOffset, document.documentElement.scrollTop, document.body.scrollTop);

			let windowHeight = window.innerHeight;
			let bodyHeight = document.body.clientHeight - 1260; // was 260;
			let offsetWindowHeight = windowHeight + scrollTopOffset;

			if ((offsetWindowHeight >= bodyHeight) && !this_el.props.property.flag.api.is_loading && !this_el.props.property.flag.no_more_result && !checkIfOffline()){

				/**
				 * Updating api state to true, so in case of scroll no more api hit called, untill last api result popup
				 */

				let new_offset = this_el.props.property.offset + 5;
				let flag = Object.assign({}, this_el.props.property.flag);
				flag.api.is_loading = true;

				//console.log((offsetWindowHeight >= bodyHeight) , !this_el.props.collection.flag.api.is_loading);
				this_el.props.updatePropertyState({
					offset : new_offset,
					flag : flag
				}).then(() => {
					let cycle = 0
					let timeout = setInterval(() => {
						if (cycle === 3) {
							clearInterval(timeout)
						}
						this_el.submitParams();
						cycle++;
					}, 1000)
				});

				}
				if ((offsetWindowHeight >= bodyHeight) && checkIfOffline()) {
					this_el.toggleStatus()
				}
			}
		}
	}

	toggleStatus = () => {
		this.setState({
			toggleOfflineStatus: !this.state.toggleOfflineStatus
		}, () => {
			this.setState({
				toggleOfflineStatus: !this.state.toggleOfflineStatus
			})
		})
	}

	render(){
		const this_el = this;
		return(
			<div className="recently-view" id="similar_listing">
				<div className="collection-list-container">
				<header className="header active">
					<div className="row">
						<div className="col-12 text-left">
							<span className="back-icon" onClick={() => this_el.props.history.goBack()}></span>
							<span className="middle-text text-center">
                                <span>Similar Listing</span>
                            </span>
						</div>                                                      
					</div>
				</header>
				{(!this_el.props.error.flag.network || this.props.property.similiarAllLoaded || this.props.property.similar_properties_all.length > 0 )?
					<div>
						<OfflineStatus
							offline={checkIfOffline() && !this.state.toggleOfflineStatus}
							bottom
							dontClear
						/>
						<div className="collection-list" ref="myDiv">
                            <SearchTile 
								properties_list={this.props.property.similar_properties_all} 
								editFinalList={this_el.props.updateWishlist}			
								toProperty={this_el.toProperty}
							/>
						</div>
					</div>
					:
					<div className="info-window">
		                <div className="window-container">
		                    <div className="imgb">
		                        <div className="img" style={{backgroundImage:'url(' + noInternet + ')'}}></div>
		                    </div>
		                    <div className="txtb"><p>Oops! You don't seem to be connected to the internet.</p></div>
		                    <button className="retry" onClick={this.reload}>Retry</button>
		                </div>
		            </div>
					}
				</div>
				{!this_el.props.property.flag.no_more_result && this_el.props.property.flag.api.is_loading ?
					<div className={'loader'}>
						<div className="timeline-item">
							<div className="animated-background facebook"></div>
						</div>
					</div>
					:
					<p className='text-center'>{!checkIfOffline() && 'No more result!'}</p>
				}
			</div>
		);
	}
}

const mapStateToProps = (store) => {
	return {
        property : store.property,
        tracker : store.tracker.tracker,
		error: store.error
    }
   
}
const mapDispatchToProps = {
    updatePropertyState,
	updateBookingDataState,
	updateErrorState
}
export default connect(mapStateToProps, mapDispatchToProps)(withRouter(SimilarListingAll));