import React from 'react';
import ResponseMessage from '../../components/response/'
import {connect} from 'react-redux';
import {updateLoginStateObj} from './../../actions/login/actions';

// services
import {getForgetPasswordApi} from '../../services/index.js';
import {trackPageView} from '../../services/tracker/events.js';

var Form = require('react-formal');

var yup = require('yup');
var useForgetSchema = yup.object({
    reset_password_via: yup.string().required('Required your email or number to update your password')
});
class ForgotPassword extends React.Component {

    constructor(props) {
        super(props);
        this.page="forgot_password";
        this.state = {
            'body_bg_color': '#00ba8c',
            'model':{
                'reset_password_via' :this.props.match.params.userId,
            }
        }
        this.submitForgetPasswordDetail = this.submitForgetPasswordDetail.bind(this);
        this.getForgetPasswordApiDataError = this.getForgetPasswordApiDataError.bind(this);
        this.getForgetPasswordApiDataSuccess = this.getForgetPasswordApiDataSuccess.bind(this);
        this.handleSubmit = this.handleSubmit.bind(this);
        this.resetMessages = this.resetMessages.bind(this);
        this.onBlurShowMessage=this.onBlurShowMessage.bind(this);
        this.onFocusHideMessage=this.onFocusHideMessage.bind(this);
        this.keyUpFunction = this.keyUpFunction.bind(this);
    }
    keyUpFunction(e){
        if (e.keyCode === 13) {
            e.target.blur();
        }
    }

    resetMessages(){
        this.props.updateLoginStateObj({
            'message':'',
            'show':false,
        });
    }
    componentDidMount(){
        document.body.style.backgroundColor = this.state.body_bg_color;
        document.body.setAttribute('data-color', this.constructor.name);
        trackPageView(this.props.tracker, this.page);
    }
    componentWillUnmount(){
        if (document.body.getAttribute('data-color') === this.constructor.name) {
            document.body.style.backgroundColor = null;
        }
        this.setState({
            model : {
                reset_password_via : this.props.match.params.userId
            }
        });
    }
    
    handleSubmit(event){
        this.submitForgetPasswordDetail({
            'reset_password_via': this.state.model.reset_password_via ,
        });
    }

    submitForgetPasswordDetail(args){
        let this_el = this;
        getForgetPasswordApi(args).then(function(response){
            // success
            this_el.getForgetPasswordApiDataSuccess(response);
        }).catch(function(error){
            // error
            this_el.getForgetPasswordApiDataError(error);
        });
    }

    getForgetPasswordApiDataError(error) {
        this.props.updateLoginStateObj({
            'message': error.response.data.error[0].message,
            'status': error.response.data.status,
            'show':true,
        });
    }
    
    getForgetPasswordApiDataSuccess(response) {
        if(response.data.data.sms_sent === 1){
            this.props.history.push('/user/password/reset?reset_password_via='+this.state.model.reset_password_via);
            this.props.updateLoginStateObj({
                'isLoggedIn': true,
            });
        }else{
            this.props.updateLoginStateObj({
                'message': response.data.data.message,
                //'status': response.data.status
            });
            this.props.history.push('/user/password/forgot/');
        }
    }

    onFocusHideMessage(){
        this.props.updateLoginStateObj({ validation:false,});
    }
    onBlurShowMessage(){
        this.props.updateLoginStateObj({ validation:true,});
    }

    changeHandle(model){
        this.setState({ model });
        //this.setState({ model });
    }

    render() {
        return (
    			<div className="password-wrapper">
    				<header className="header">
    	      			<div className="row">
    						<div className="col-12 text-left">
    							<span className="cross-icon-white" onClick={() => this.props.history.goBack()}></span>
    						</div>														
    					</div>
              	     </header>	
                     <div className="img forgot"></div>
                     <div className="text">It’s okay! Reset your password</div>
                     <Form schema={useForgetSchema} onSubmit={this.handleSubmit} 
                     onChange={model => this.changeHandle(model)} 
                     defaultValue={this.state.model}>
                        <div className="wrap">
                            <Form.Field type="text" onKeyUp={(e) => this.keyUpFunction(e)} name="reset_password_via" onFocus={this.onFocusHideMessage} onBlur={this.onBlurShowMessage} className="reset" placeholder="Email/Phone number"/>
                            {this.props.login.validation &&
                                <Form.Message  for='reset_password_via'/>
                            }
                        </div>
                         <Form.Button type="submit" className="btn white link">Continue</Form.Button>
                     </Form>
                     
                    <div className={'responseAPI '+this.props.login.show }>
                        <ResponseMessage responseMessage={this.props.login.message}  responseStatus={this.props.login.status} resetMessages={this.resetMessages}/>
                    </div>
                </div>
        );
    }
}
const mapStateToProps = (store) => {
	return {
        login : store.loginEmail,
        tracker: store.tracker.tracker
	}
}
const mapDispatchToProps = {
    updateLoginStateObj
}
export default connect(mapStateToProps,mapDispatchToProps) (ForgotPassword);