import React from 'react';
import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';
import { addClassToBody } from '../../services';

class PricingInfo extends React.Component{

	openPricingCalendar() {
		sessionStorage.setItem("scrollVal", Math.max(window.pageYOffset, document.documentElement.scrollTop, document.body.scrollTop));
        addClassToBody();
        this.props.history.push("/property/pricingCalendar/"+ this.props.property.property_hash_id);
	}

	render(){
		const this_el  = this;
		return(
			<section className="pricing-info clearfix">
				<h3>Pricing information</h3>
				<div className="row clearfix">
					<div className="col-6 pull-left extra-guest-cost">
						<i></i>
						<h4>
							<span>Extra Guest Cost</span>
							{this.props.property_pricing.per_night_per_guest_extra_guest_price > 0 ?
								<span className='sub-info'>{this.props.property_pricing.currency.webicon}{this.props.property_pricing.per_night_per_guest_extra_guest_price.toLocaleString('en')} after {this.props.property_pricing.per_unit_guests} {this.props.property_pricing.per_unit_guests > 1 ? 'guests':'guest'}</span>

								:'-'
							}
						</h4>
					</div>
					<div className="col-6 pull-left cleaning-fees">
						<i></i>
						<h4>
							<span>Cleaning fees</span> 
							{this.props.property_pricing.cleaning_price > 0 ? 
								<span className='sub-info'>{this.props.property_pricing.currency.webicon}{this.props.property_pricing.cleaning_price.toLocaleString('en')}</span>
								: 
								'-'
							}
						</h4>
					</div>
				</div>
				{(Object.keys(this_el.props.property.default_prices).length) > 0 ?
					<span className="read-more"  onClick={this.openPricingCalendar.bind(this)}>See pricing and availability <i></i></span>
				:
					<span className="read-more">See pricing and availability <i></i></span>

				}

			</section>
		);
	}
}

const mapStateToProps = (store) => {
	return {
		property_pricing: store.property.property_pricing,
		property:store.property
	}
}
export default withRouter(connect(mapStateToProps,null)(PricingInfo));