import React from 'react';
import Modal from 'react-modal';

class MaritalStatus extends React.Component {
    constructor(props) {
      super(props);
        this.state = {
          'marital_status':props.maritalStatus,
          //'maritalStatus':props.maritalStatusClose
        }
        this.maritalStatusChange =this.maritalStatusChange.bind(this);
        this.saveStatus =this.saveStatus.bind(this);
    }

    maritalStatusChange(e) {
      this.setState({
            'marital_status': e.target.value
     })
    }
    saveStatus(){
      this.props.saveMaritalStatus(this.state.marital_status);
      this.props.goBack();
     //this.props.maritalStatusClose(this.state.maritalStatus)
    }

  render() {
    return (
      <div>
        <Modal
          isOpen={this.props.maritalStatusOpen}
          onAfterOpen={this.afterOpenModal}
          contentLabel="Modal"
                overlayClassName={{
                base: 'overlay-modal overlay-popup',
                afterOpen: '',
                beforeClose: ''
              }}
          className={{
                base: 'content-modal',
                afterOpen: 'editProfile',
                beforeClose: ''
              }}

        >
          <div className="modal-body text-center">
           <a className="back-btn" onClick={()=>this.props.goBack()}> </a>
              <div className="verify-text1 text-left">Please tell us your marital status</div>
              <div className="row">
                <div className="col-12">
                <div className="box">
                  <label className="container">Single
                    <input type="radio" value="Single" defaultChecked={this.props.maritalStatus === 'Single'} onChange={this.maritalStatusChange} name="radio"/>
                    <span className="checkmark"></span>
                  </label>
                  <label className="container">Married
                    <input type="radio" value="Married" defaultChecked={this.props.maritalStatus === 'Married'} onChange={this.maritalStatusChange} name="radio"/>
                    <span className="checkmark"></span>
                </label>
                </div>
                </div>
              </div>
              <button className="link green" onClick={this.saveStatus}>Done</button>
          </div>
        </Modal>
      </div>
    );
  }
}

export default MaritalStatus;