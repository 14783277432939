import React from 'react';
import Modal from 'react-modal';

class ConfirmDateModal extends React.Component {
    constructor(props){
		super(props);
    }
  render() {
    return (
      <div>
        <Modal
          isOpen={this.props.confirmDateModalOpen}
          contentLabel="Modal"
                overlayClassName={{
                base: 'overlay-modal overlay-popup',
                afterOpen: '',
                beforeClose: ''
              }}
          className={{
                base: 'content-modal',
                afterOpen: 'editProfile',
                beforeClose: ''
              }}

        >
          <div className="modal-body text-center"> 
              <a className="back-btn" onClick={this.props.close}> </a>
              <div className="text-center varificationEmail">Show me properties available between {this.props.checkin} and {this.props.checkout}</div>
              <button className="link green" onClick={this.props.onProceed}>Proceed</button>
          </div>
        </Modal>
      </div>
    );
  }
}

export default ConfirmDateModal;