

/* APIs */
// get home page data
let getWishlistDataApi = function (data, args)
{
    //console.log('hey', data)
    return data.AXIOS.get(data.BASE_URL_V+'/user/wishlist', {
        responseType: 'json',
        params:{
            offset:args.offset,
            total:args.total,
        },
        headers: {
            'Accept': 'application/json',
            'Content-Type': 'application/x-www-form-urlencoded',
            'device-unique-id': data.DEVICE_UNIQUE_ID,
            'Authorization' :'Bearer ' + data.ACCESS_TOKEN,
        }
    });
}

exports.getWishlistDataApi = getWishlistDataApi;