import React from 'react';
import { connect } from 'react-redux';
import { withRouter } from "react-router-dom";
import {addClassToBody} from "../../services/index";

class AmenitiesList extends React.Component{

	openAllAmenitiesList() {
		sessionStorage.setItem("scrollVal", Math.max(window.pageYOffset, document.documentElement.scrollTop, document.body.scrollTop));
        addClassToBody();
        this.props.history.push("/property/amenities/"+ this.props.property.property_hash_id);

	}
	
	render(){

		const this_el = this;
		return(

			<section className={this_el.props.property.amenities.length > 0 ? "property-amenities clearfix" : 'marginNone'}>
				{this_el.props.property.flag.api.is_loading ?
					<div>
					{this_el.props.property.amenities.length > 0 &&
					<div>
						<h3>Amenities</h3>
						<div className="space-amenities-list clearfix">
							<ul>
								{this_el.props.property.amenities.map(function(item, index){
									if(index < 6){
										return(
											<li key={index}><i className={'amenities-icon-id-' + item.id}></i> {item.name}</li>
										)
									}else{
										return false
									}
								})}
							</ul>
						</div>
						{this_el.props.property.amenities.length > 6 &&
							<span className="read-more" onClick={this.openAllAmenitiesList.bind(this)}>View All ({this_el.props.property.amenities.length} Amenities)</span>
						}
							
					</div>
					}
					</div>
				:
					<div>
						<h3 style={{'paddingTop':'10px'}}>Amenities</h3>
						<div className="animated-b animated-2"> 
							<div className="background-masker after-heading-left-overlay"></div>
							<div className="background-masker after-heading-right-overlay"></div>
							<div className="background-masker after-heading-content"></div>
							<div className="background-masker after-heading-content-1"></div>
							<div className="background-masker after-heading-content-2"></div>
							<div className="background-masker after-heading-10"></div>
						</div>
					</div>
				}
			</section>
		);
	}
}

const mapStateToProps = (store) => {
	return {
		property : store.property
	}
}
export default withRouter(connect(mapStateToProps,null)(AmenitiesList));