import React from 'react';
import Modal from 'react-modal';
import AllAmenities from './allAmenities.js';
import AccommodationList from './accommodationList.js'
import LocationList from './locationList.js'
import RheoPriceSlider from './rheopriceslider.js'
import { withRouter } from 'react-router'
import {connect} from 'react-redux';
import {ddMMYYYY, getURLParameter} from '../../../services/index.js';
import {ObjEq, getVal, cloneObj, getObjectVal} from '../../../services/helpers.js';

// actions

import { updateSearchState, resetSearchFilter} from './../../../actions/search/actions.js';


class SearchFilter extends React.Component {


	constructor(props) {

		super(props);


		/**
		 * Local static values
		 */
		this.amenity_suffix = '_amenity';

		// functions
		this.closeFilter = this.closeFilter.bind(this);
		this.resetFilter = this.resetFilter.bind(this);
		this.setPriceFilterValue = this.setPriceFilterValue.bind(this);
		this.applyFilter = this.applyFilter.bind(this);
		this.checkFilterChange = this.checkFilterChange.bind(this);
		this.toggleRoomType = this.toggleRoomType.bind(this);
		this.toggleInstantBook = this.toggleInstantBook.bind(this);
		this.toggleCashOnArrival = this.toggleCashOnArrival.bind(this);
		this.decrementBedroom = this.decrementBedroom.bind(this);
		this.incrementBedroom = this.incrementBedroom.bind(this);
		this.openAccomodationModal = this.openAccomodationModal.bind(this);
		this.openAmenityModal = this.openAmenityModal.bind(this);
		this.isAmenitySelected = this.isAmenitySelected.bind(this);
		this.localSelectedAmenities = this.localSelectedAmenities.bind(this);
		this._handleChangeAmenity = this._handleChangeAmenity.bind(this);
		this.openLocationModal = this.openLocationModal.bind(this);

		this.state = {
			reset: false,
			disabled: true
		}
	}


	closeFilter(){

		const this_el = this;

		this_el.props.updateSearchState({
			filter : {
				...JSON.parse(JSON.stringify(this.props.search.filter)),
				filter_replica: JSON.parse(JSON.stringify(this.props.search.filter.filter))
			}
		});

		this.setState({
			disabled: true
		})

		this.props.history.goBack()
	}

	resetFilter(){
		const this_el = this;
		setTimeout(_ => {
			this.setState({ reset: true }, _ => {
				this.setState({ reset: false })
			})
		})
		this_el.props.resetSearchFilter().then(() => {
			this_el.checkFilterChange();
		});
		this_el.resetSlider()
	}


	setPriceFilterValue(min, max){


		const this_el = this;
		let filterObj = Object.assign({}, this_el.props.search.filter);
		let filter_replica = Object.assign({}, filterObj.filter_replica);
		//let filter = Object.assign({}, filterObj.filter);
		filter_replica.price.min = min;
		filter_replica.price.max = max;
		filterObj.filter_replica = filter_replica;


		this_el.props.updateSearchState({
			filter : filterObj
		}).then(() => {
			this_el.checkFilterChange();
		});
	}



	toggleRoomType(){

		const this_el = this;

		const filterObj = JSON.parse(JSON.stringify(this_el.props.search.filter));
		let roomtype = (this_el.props.search.filter.filter_replica.roomtype);

		// alternating room type value

		if(roomtype === 1){
			roomtype = 0;
		}else{
			roomtype = 1;
		}

		// setting room type value
		filterObj.filter_replica.roomtype = roomtype;

		// udpating store
		this_el.props.updateSearchState({
			filter : filterObj
		}).then(() => {
			this_el.checkFilterChange();
		});

	}



	toggleInstantBook(){

		const this_el = this;

		const filterObj = JSON.parse(JSON.stringify(this_el.props.search.filter));
		let instant_book = (this_el.props.search.filter.filter_replica.instant_book);

		// alternating room type value

		if(instant_book === 1){
			instant_book = 0;
		}else{
			instant_book = 1;
		}

		// setting room type value
		filterObj.filter_replica.instant_book = instant_book;

		// udpating store
		this_el.props.updateSearchState({
			filter : filterObj
		}).then(() => {
			this_el.checkFilterChange();
		});

	}



	toggleCashOnArrival(){

		const this_el = this;

		const filterObj = JSON.parse(JSON.stringify(this_el.props.search.filter));
		let cash_on_arrival = (this_el.props.search.filter.filter_replica.cash_on_arrival);

		// alternating room type value

		if(cash_on_arrival === 1){
			cash_on_arrival = 0;
		}else{
			cash_on_arrival = 1;
		}

		// setting room type value
		filterObj.filter_replica.cash_on_arrival = cash_on_arrival;

		// udpating store
		this_el.props.updateSearchState({
			filter : filterObj
		}).then(() => {
			this_el.checkFilterChange();
		});

	}


	decrementBedroom(){

		const this_el = this;
		let bedroom = this_el.props.search.filter.filter_replica.bedroom;
		let filterObj = cloneObj(this_el.props.search.filter);


		if(bedroom > 0){
			bedroom -= 1;
		}

		// setting new bedroom
		filterObj.filter_replica.bedroom = bedroom;

		// updating store
		this_el.props.updateSearchState({
			filter : filterObj
		}).then(() => {
			this_el.checkFilterChange();
		});

	}

	incrementBedroom(){

		const this_el = this;
		let bedroom = this_el.props.search.filter.filter_replica.bedroom;
		let filterObj = cloneObj(this_el.props.search.filter);


		if(bedroom < 30){
			bedroom += 1;
		}

		// setting new bedroom
		filterObj.filter_replica.bedroom = bedroom;

		// updating store
		this_el.props.updateSearchState({
			filter : filterObj
		}).then(() => {
			this_el.checkFilterChange();
		});

	}



	openAccomodationModal(){
		const this_el = this;

 		let flag = Object.assign({}, this_el.props.search.flag);
 		flag.modal.accomodation = true;

 		this_el.props.updateSearchState({
 			flag : flag
 		});
	}


	/**
	 * Amenity functions
	 */


	openAmenityModal(){


		const this_el = this;
 		let local_selected_amenities = this_el.localSelectedAmenities();
 		let searchObj = cloneObj(this_el.props.search);
 		searchObj.filter.filter_replica.amenities = local_selected_amenities;
 		searchObj.flag.modal.amenity = true;
 		this_el.props.updateSearchState(searchObj);

	}

	isAmenitySelected(item){

		const this_el = this;
 		const selected = this_el.props.search.filter.filter_replica.amenities;

 		if(selected.length){
 			for (var key in selected) {
			    const id = selected[key];
			    if(item.id === id){
					return true;
			    }
			}
 		}
 		return false;

	}

	_handleChangeAmenity(){

		const this_el = this;
		this_el.checkFilterChange();
	}

	localSelectedAmenities(){

    	const this_el = this;
    	const list = this_el.props.search.filter.values.amenities;
    	let localSelected = [];

    	if(list){
    		for(var key in list){
    			const row = list[key];
    			const checkbox = this_el.refs[row.id+this_el.amenity_suffix];
    			if(checkbox !== undefined && checkbox.checked){
    				localSelected.push(String(row.id));
    			}
    		}
    	}

    	localSelected = localSelected.map(Number);
    	localSelected.sort(function(a, b){return a - b}) // sorting ids ASC
    	return localSelected;
	}


	openLocationModal(){

		const this_el = this;

 		let flag = Object.assign({}, this_el.props.search.flag);
 		flag.modal.area = true;

 		this_el.props.updateSearchState({
 			flag : flag
 		});

	}





	checkFilterChange(){

		const this_el = this;

		let filter = cloneObj(this_el.props.search.filter.filter);

		// updating local selected amenities in filter_replica.amentites, so that we can compare with filter applied
		let local_selected_amenities = this_el.localSelectedAmenities();
		let filter_replica = cloneObj(this_el.props.search.filter.filter_replica);
		filter_replica.amenities = local_selected_amenities;


		let isEqual = ObjEq(filter, filter_replica);
		console.log(filter, filter_replica);
		if(isEqual){
			this_el.refs.apply_filter.classList.remove('active');
			this.setState({
				disabled: true
			})
		}else{
			this_el.refs.apply_filter.classList.add('active');
			this.setState({
				disabled: false
			})
		}

	}

	applyFilter(){

		const this_el = this;
		this.setState({ reset: false })

		let local_selected_amenities = this_el.localSelectedAmenities();
 		let searchObj = cloneObj(this_el.props.search);
 		searchObj.filter.filter_replica.amenities = local_selected_amenities;
		searchObj.flag.modal.filter = false;
		searchObj.flag.modal.area = false;
		console.log('Search', this.props.search)

 		this_el.props.updateSearchState(searchObj).then(() => {


			// submitting search

			let filter_replica = searchObj.filter.filter_replica;
			let booking = Object.assign({}, this_el.props.booking);
			let urlParams = [];

			urlParams.push('checkin=' + ddMMYYYY(booking.checkin_date, ''));
			urlParams.push('checkout=' + ddMMYYYY(booking.checkout_date, ''));
			urlParams.push('guests=' + getVal(booking.guest_count));
			urlParams.push('lat=' + getVal(this.props.search.search_address_data.lat));
			urlParams.push('lng=' + getVal(this.props.search.search_address_data.lng));
			urlParams.push('location=' + getVal(this.props.search.search_address_data.location));
			urlParams.push('area=' + getVal(filter_replica.area.join(',')));
			urlParams.push('city=' + getVal(this.props.search.search_address_data.city));
			urlParams.push('state=' + getVal(this.props.search.search_address_data.state));
			urlParams.push('country=' + getVal(booking.country.ccode));
			urlParams.push('min_budget=' + getVal(filter_replica.price.min));
			urlParams.push('max_budget=' + getVal(filter_replica.price.max));
			urlParams.push('roomtype=' + getVal(filter_replica.roomtype));
			urlParams.push('instant_book=' + getVal(filter_replica.instant_book));
			urlParams.push('cash_on_arrival=' + getVal(filter_replica.cash_on_arrival));
			urlParams.push('bedroom=' + getVal(filter_replica.bedroom));
			urlParams.push('property_type=' + getVal(filter_replica.property_type).join(','));
			urlParams.push('amenities=' + getVal(filter_replica.amenities).join(','));
			urlParams.push('area=' + getVal(filter_replica.area).join(','));
			if (this.props.location.search.includes('promo')) {
				urlParams.push('promo=' + getURLParameter(this.props.location.search, 'promo'));
			}
			urlParams.push('offset=0');

			urlParams = urlParams.filter(x => (x.slice(-1) !== '=' && x.slice(-2) !== '=0') || x.includes('min_budget') || x.includes('max_budget'))
			console.log('Params', urlParams)
			
			let search_url_params = '?' + urlParams.join('&');

			if(this_el.props.match.path === '/search/s' || this_el.props.match.path === '/stays/:location'){
				// already on search page
				this_el.props.history.replace({
					pathname: "/search/s",
					search: search_url_params,
				});

			}else{
				// never visited the search page
				this_el.props.history.push({
					pathname: "/search/s",
					search: search_url_params,
				});
			}

			if(typeof this_el.props.updateStore !== 'undefined'){
				let reset = true;
				this_el.props.updateStore(reset);
			}

		});

		this.setState({
			disabled: true
		})
	}


	componentDidMount(){
		this.setState({ reset: false, disabled: true })
	}

	componentWillUnmount(){
	}

	render(){

		const this_el = this
		let amenitiesShowCounter = 0;

		return(
			<div>
				<Modal
					isOpen={this_el.props.search.flag.modal.filter}
					onRequestClose={this.closeFilter}
					onAfterClose={() => this.setState({ disabled: true })}
					onAfterOpen={() => {
						if (this.props.checkFilterChange() && this.state.disabled) {
							this.setState({
								disabled: false
							})
						}
					}}
					contentLabel="Modal"
					overlayClassName={{
						base: 'overlay-modal',
						afterOpen: '',
						beforeClose: ''
					}}
					className={{
						base: 'content-modal',
						afterOpen: 'full-screen-modal',
						beforeClose: ''
					}}>
					<form>
						<div className="filter-modal">
							<div className="modal-header">
								<button className={`reset-btn ${this.state.disabled && 'disabled'}`} type='reset' value='reset' onClick={(e) => !this.state.disabled && this.resetFilter(e)}>Reset</button>
								<span className="close-btn" onClick={this.closeFilter}>Close</span>
								<h2 className="text-center">Filter</h2>
								<div className='transparent-gredient'></div>
							</div>
							<div className="modal-body">
								<div className="price-range-filter">
									<h3>Budget/Night</h3>
									<div className="price_range_slider">

									<RheoPriceSlider
										id="slider"
										attachResetHandler={fn => (this.resetSlider = fn)}
										value={this_el.props.search.filter.filter.price}
										recentValue={this_el.props.search.filter.filter_replica.price}
										helper={this_el.setPriceFilterValue}
										slider={this_el.props.search.filter.slider}
										max={this.props.search.filter.values.max_budget}
										webicon={this.props.search.filter.values.budget_currency && this.props.search.filter.values.budget_currency.webicon}
									/>
									</div>
								</div>
								<div className="booking-type-filter">
									<label className="pull-left">
										<input type="checkbox" name="booking-type" checked={!this.state.reset && this_el.props.search.filter.filter_replica.instant_book === 1} onChange={(e) => this_el.toggleInstantBook(e)} />
										<i className="instant-book-icon"></i>
										<span>Instant Book</span>
									</label>
									<label>
										<input type="checkbox" name="booking-type" checked={!this.state.reset && this_el.props.search.filter.filter_replica.cash_on_arrival === 1} onChange={(e) => this_el.toggleCashOnArrival(e)}  />
										<i className="pay-later-icon"></i>
										<span>Pay Later</span>
									</label>
									<label className="pull-right">
										<input type="checkbox" name="booking-type" checked={!this.state.reset && this_el.props.search.filter.filter_replica.roomtype === 1} onChange={(e) => this_el.toggleRoomType(e)} />
										<i className="entire-home-icon"></i>
										<span>Entire Home</span>
									</label>
								</div>
								<div className="bedrooms-filter">
									<div className="pull-right bedrooms-filter-inner">
										<span className="minus-btn" onClick={this_el.decrementBedroom} ></span>
										<input type="text" name="bedrooms" className="total-bedrooms text-center" value={(x => x === 0 ? 'Select' : x)(this_el.props.search.filter.filter_replica.bedroom)} readOnly="readonly"/>
										<span className="plus-btn"  onClick={this_el.incrementBedroom}></span>
									</div>
									<h3>Bedrooms</h3>
								</div>


								
								<div className="loaction-filter">
									{Object.keys(this_el.props.search.filter.values).length > 0 && this_el.props.search.filter.values.search_location.length > 1 &&
										<div>
											<h3>Location search</h3>
											<span className={this_el.props.search.filter.filter_replica.area.length ? "sub-filter-btn greenTxt" : "sub-filter-btn"} onClick={this.openLocationModal}>
												{this_el.props.search.filter.filter_replica.area.length
													? (this_el.props.search.filter.filter_replica.area.length > 1
														? (this_el.props.search.filter.filter_replica.area.length + ' locations selected')
														: (this_el.props.search.filter.filter_replica.area.length + ' location selected')
													)
													: ('Select an area within the city')
												}
												<i></i>
											</span>
										</div>
									}							

									{Object.keys(this_el.props.search.filter.values).length > 0 &&  this_el.props.search.filter.values.property_types.length > 1 &&
										<div>
											<h3>Accommodation type</h3>
											<span className={this_el.props.search.filter.filter_replica.property_type.length ? "sub-filter-btn greenTxt" : "sub-filter-btn"} onClick={this.openAccomodationModal}>

												{this_el.props.search.filter.filter_replica.property_type.length
													? (this_el.props.search.filter.filter_replica.property_type.length > 1
														? (this_el.props.search.filter.filter_replica.property_type.length + ' accommodation types selected')
														: (this_el.props.search.filter.filter_replica.property_type.length + ' accommodation type selected')
													)
													: ('Pick from a variety of property types')
												}
												<i></i>
											</span>
										</div>
									}

								</div>



								<div className="amenities-filter">
									<h3><a className="see-all pull-right" onClick={this_el.openAmenityModal}>See all</a> Amenities</h3>
									<ul className="amenities-list no-border">

										{getObjectVal(this_el.props.search.filter.values, 'amenities')&&
											<div>
												{this_el.props.search.filter.values.amenities.map(function(item, index){
													if(this_el.isAmenitySelected(item)) {
														amenitiesShowCounter++;
														return(
															<li key={index} className={amenitiesShowCounter > 4 ? 'hide-amenity-li' : ''}>
																<label>
																	<i className={'amenities-icon-id-' + item.id}></i> {item.amenity_name}
																	<input
																		type="checkbox"
																		id={item.id}
																		value={item.id}
																		name={item.amenity_name}
																		className="pull-right amenitiy-chbx"
																		ref={item.id+this_el.amenity_suffix}
																		onChange={this_el._handleChangeAmenity}
																		defaultChecked={this_el.isAmenitySelected(item)}
																	/>
																	<span className="pull-right"></span>
																</label>
															</li>
														)
													} else {
														return false;
													}
												})}

												{this_el.props.search.filter.values.amenities.map(function(item, index){
													if(!this_el.isAmenitySelected(item) && amenitiesShowCounter < 4) {
														amenitiesShowCounter++;
														return(
															<li key={index}>
																<label>
																	<i className={'amenities-icon-id-' + item.id}></i> {item.amenity_name}
																	<input
																		type="checkbox"
																		id={item.id}
																		value={item.id}
																		name={item.amenity_name}
																		className="pull-right amenitiy-chbx"
																		ref={item.id+this_el.amenity_suffix}
																		onChange={this_el._handleChangeAmenity}
																		defaultChecked={false}
																	/>
																	<span className="pull-right"></span>
																</label>
															</li>
														)
													} else {
														return false
													}
												})}
												</div>

										}
									</ul>
								</div>






							</div>
						</div>
					</form>


					<AccommodationList
						checkFilterChange={this_el.checkFilterChange}
					/>

					<AllAmenities
						checkFilterChange={this_el.checkFilterChange}
						localSelectedAmenities={this_el.localSelectedAmenities}
					/>



					<LocationList  checkFilterChange={this_el.checkFilterChange} />



					<div className={"floating-btn-box text-center"} ref='apply_filter'>
						<a className="floating-btn" onClick={this_el.applyFilter}>Apply</a>
					</div>

				</Modal>
			</div>
		)
	}
}



const mapStateToProps = store => {
	return {
		booking: store.booking,
		tracker: store.tracker.tracker,
		error : store.error,
		search : store.search
	};
};

const mapDispatchToProps = {
	updateSearchState,
	resetSearchFilter
};

export default connect(mapStateToProps,mapDispatchToProps)(withRouter(SearchFilter));
