import React from 'react';
import Modal from 'react-modal';

class Description extends React.Component {
  constructor(props) {
	super(props);
	this.state = {
		'description':props.description,
		'errorMsg':false,
	  //'descriptions':props.descriptionClose
	}

	this.descriptionChange =this.descriptionChange.bind(this);
	this.savedescription = this.savedescription.bind(this);
	this.afterOpenModal = this.afterOpenModal.bind(this);
	}

	descriptionChange(value){
		this.setState({
			'description': value.trimLeft()
		});
	}
	hideErroMsg=()=>{
		this.setState({
			errorMsg:false
		})
	}
	afterOpenModal(){
	  let this_el = this;
	  this.setState({
			'description': this_el.props.description,
				errorMsg:false,
	  });
	}

	savedescription(e){
		e.preventDefault();
		if(this.state.description.length> 0){
	  this.props.savedescription(this.state.description);
		this.props.goBack();
		}else{
			this.setState({
				errorMsg:true
			})
		}
	}

  render() {
	return (
	  <div>
		<Modal
		  isOpen={this.props.descriptionOpen}
		  onAfterOpen={this.afterOpenModal}
		  contentLabel="Modal"
				overlayClassName={{
				base: 'overlay-modal overlay-popup',
				afterOpen: '',
				beforeClose: ''
			  }}
		  className={{
				base: 'content-modal',
				afterOpen: 'editProfile',
				beforeClose: ''
			  }}
			>
		  <div className="modal-body text-center">
			  <span className="back-btn" onClick={this.props.goBack}></span>
			  <div className="verify-text1 text-left">Interesting tidbits about me</div>
				<form class="editprofile_form" onSubmit={(e) => this.savedescription(e)} >
			  <textarea className="texarea" placeholder="Interesting tidbits" value={this.state.description} onFocus={() => this.hideErroMsg()} onChange={ e => this.descriptionChange(e.target.value)}>
			  </textarea>
				{
					this.state.errorMsg &&
					<span className="error textarea">Field can not be empty</span>
				}
			  <button type="submit" className="link green" >Done</button>
				</form>
		  </div>
		</Modal>
	  </div>
	);
  }
}

export default Description;