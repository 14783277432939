

/* APIs */
// get signup data
let getForgetPasswordApi = function (data, args)
{
    return data.AXIOS.post(data.BASE_URL_V+'/user/password/reset', data.QS.stringify({
            reset_password_via:args.reset_password_via,
        }), {
            headers: {
                'Accept': 'application/json',
                'Content-Type': 'application/x-www-form-urlencoded',
                'device-unique-id': data.DEVICE_UNIQUE_ID,
            }
        }
    );
}

exports.getForgetPasswordApi = getForgetPasswordApi;