import React from 'react';
import {Link} from 'react-router-dom';
import ReactDOM from 'react-dom';
import serverError from "../../static/img/serverError.png";
import PrepaymentBookingDetails from '../../components/prepayment/prepaymentBookingDetails';
import Invoice from '../../components/prepayment/invoice';
import PaymentSection from '../../components/prepayment/payment';
import Promocode from "../../components/prepayment/modal/promocode";
import CouponConfirmation from "../../components/prepayment/modal/couponConfirmModal";
import WalletModal from "../../components/prepayment/modal/wallet";
import TermCondition from "../../components/modal/term/term";
import SelectGuestsModal from '../../components/home/searchBarContainer/selectGuestsModal.js';
import DateRangeCalendar from '../../components/home/searchBarContainer/dateRangeCalendar.js';
import noInternet from "../../static/img/infoWindow/noInternet.svg";
import RequestSentModel from "../../components/request/request_sent.js";
import ForceCreateModal from "../../components/prepayment/modal/forceCreateModal";
import { connect } from 'react-redux';

//services
import {getPrepaymentDataApi, saveUrlLogin, getInstantBookPaymentApi, hasAccessToken,addClassToHtml,
	removeClassToHtml, getURLParameter} from '../../services/index.js';

import { trackPageView, trackPrepaymentView, trackError, trackBookingRequest} from '../../services/tracker/events.js';

/**
 * Actions
 */
import { updatePropertyState } from '../../actions/property/actions';
import { updateBookingDataState } from '../../actions/booking-data/actions';
import { updateSearchState } from '../../actions/search/actions';
import { updatePrepaymentState } from '../../actions/prepayment/actions';
import { updateErrorState } from '../../actions/error/actions';
import { checkIfOffline } from '../../services/helpers';
import OfflineStatus from '../../components/Offlinestatus';

class PrepamentPropertyDetails extends React.Component {
	constructor(props) {
		super();
		this.page="prepayment";

		this.toDate = this.toDate.bind(this);
		this.promocodeOpen = this.promocodeOpen.bind(this);
		this.promocodeClose = this.promocodeClose.bind(this);
		this.walletModalOpen = this.walletModalOpen.bind(this);
		this.walletModalClose = this.walletModalClose.bind(this);
		this.savepromocode = this.savepromocode.bind(this);
		this.getPaymentMathod = this.getPaymentMathod.bind(this);
		this.opentermCondition = this.opentermCondition.bind(this);
		this.submitPropertyDataToApi = this.submitPropertyDataToApi.bind(this);
		this.makePayment = this.makePayment.bind(this);
		this.openMaxNightsAlert = this.openMaxNightsAlert.bind(this);
		this.closeMaxNightsAlert = this.closeMaxNightsAlert.bind(this);
		this.reload = this.reload.bind(this);
		this.confirmWallet = this.confirmWallet.bind(this);
		this.openUnitAlert = this.openUnitAlert.bind(this);
		this.closeUnitAlert = this.closeUnitAlert.bind(this);
		this.openRequestSentModal = this.openRequestSentModal.bind(this);
		this.modalRouting = this.modalRouting.bind(this);
		this.goBack = this.goBack.bind(this);
		this.openUnavailableDate = this.openUnavailableDate.bind(this);
		this.closeUnavailableDate = this.closeUnavailableDate.bind(this);
		this.couponConfirmation = this.couponConfirmation.bind(this);
		this.closeMisconceptionModal =  this.closeMisconceptionModal.bind(this);
		this.closeErrorMessage_modal = this.closeErrorMessage_modal.bind(this);
		this.closeRequestExist_modal = this.closeRequestExist_modal.bind(this); 
		this.createRequestForcely = this.createRequestForcely.bind(this);

		this.state = {
			toggleOfflineStatus: false
		}

	}

	walletModalOpen(){
		if (checkIfOffline()) {
			this.toggleStatus()
		} else {
			if(this.props.prepayment.discount_section.discount.discount_type ==='wallet' && this.props.prepayment.discount_section.discount.discount_valid){
				this.props.updatePrepaymentState({apply_wallet:0}).then( () => {
					let urlParams = '?checkin=' + this.dateConvertor(this.props.booking.checkin_date) +  '&checkout=' + this.dateConvertor(this.props.booking.checkout_date) + '&guests=' + this.props.booking.guest_count + '&units=' + this.props.booking.unit_count+'&payment_method='+ this.props.prepayment.payment_method;
					this.getPrepaymentApi(this.props.match.params.hashId + urlParams);
					
					ReactDOM.findDOMNode(this.refs['wallet']).checked = 0;
				});
			}else {
				if(this.props.prepayment.discount_type === 'coupon' && this.props.prepayment.discount_section.discount.discount_valid){
					window.location.hash = 'walletModal';
				}else{
					this.confirmWallet();
				}
			}
		}
		return false
	}

	walletModalClose(){
		if(this.props.prepayment.discount_section.discount.discount_type ==='coupon' && this.props.prepayment.discount_section.discount.discount_valid){
			ReactDOM.findDOMNode(this.refs['promo']).checked = 1;
		}
		this.props.updatePrepaymentState({ apply_wallet: 0 }).then(() => {
			this.goBack();
			ReactDOM.findDOMNode(this.refs['wallet']).checked = 0;
		});
	}

	confirmWallet(back){
		this.props.updatePrepaymentState({ apply_wallet: 1 }).then(() => {
			let urlParams ='?checkin=' + this.dateConvertor(this.props.booking.checkin_date) +  '&checkout=' + this.dateConvertor(this.props.booking.checkout_date) + '&guests=' + this.props.booking.guest_count + '&units=' + this.props.booking.unit_count +'&apply_wallet='+this.props.prepayment.apply_wallet +'&payment_method='+ this.props.prepayment.payment_method;
			this.getPrepaymentApi(this.props.match.params.hashId + urlParams);
			if(back){
				this.goBack();
			}
		})
	}

	couponConfirmation(){
		let flag = this.props.prepayment.flag;
		flag.modal.promocodes = true;
		flag.modal.couponConfirmation = false;
		this.props.updatePrepaymentState({'flag' : flag})
	}

	promocodeOpen(){
		if (!checkIfOffline()) {
			if(this.props.prepayment.discount_section.discount.discount_type ==='coupon' && this.props.prepayment.discount_section.discount.discount_valid){
				let urlParams = '?checkin=' + this.dateConvertor(this.props.booking.checkin_date) +  '&checkout=' + this.dateConvertor(this.props.booking.checkout_date) + '&guests=' + this.props.booking.guest_count + '&units=' + this.props.booking.unit_count+'&payment_method='+ this.props.prepayment.payment_method;
				this.getPrepaymentApi(this.props.match.params.hashId + urlParams);
				ReactDOM.findDOMNode(this.refs['promo']).checked = 0;
			}else {
				if(this.props.prepayment.discount_type === 'wallet'){
					window.location.hash = 'couponConfirmation';
				}else{
					window.location.hash = 'promocodeWallet'
				}
			}
		} else {
			this.toggleStatus()
			return false
		}
	}

	promocodeClose(){
		if(this.props.prepayment.discount_section.discount.discount_type ==='wallet' && this.props.prepayment.discount_section.discount.discount_valid){
			ReactDOM.findDOMNode(this.refs['wallet']).checked = 1;
		}
		ReactDOM.findDOMNode(this.refs['promo']).checked = 0;
		this.goBack();
	}

	savepromocode(promocode){
		let urlParams = '?checkin=' + this.dateConvertor(this.props.booking.checkin_date) +  '&checkout=' + this.dateConvertor(this.props.booking.checkout_date) + '&guests=' + this.props.booking.guest_count + '&units=' + this.props.booking.unit_count +'&coupon_code='+ promocode +'&payment_method='+ this.props.prepayment.payment_method;
		this.getPrepaymentApi(this.props.match.params.hashId + urlParams);
		this.goBack();
	}

	getPrepaymentApiDataSuccess(response) {
		const this_el = this;
		this.props.updateBookingDataState({
			'location': response.data.data.property_section.tile.location.location_name,
			'area': response.data.data.property_section.tile.location.area,
			'city': response.data.data.property_section.tile.location.city,
			'state': response.data.data.property_section.tile.location.state,
			'country': response.data.data.property_section.tile.location.country,
			'lat': response.data.data.property_section.tile.location.latitude,
			'lng': response.data.data.property_section.tile.location.longitude,
			'room_type': response.data.data.property_section.tile.room_type,
			'checkin_date': this.toDate(response.data.data.property_section.start_date),
			'checkout_date': this.toDate(response.data.data.property_section.end_date),
			'guest_count': response.data.data.property_section.guests,
			'unit_count': response.data.data.property_section.required_units,
		});
		this.props.updatePropertyState({
			'property_title': response.data.data.property_section.tile.property_title,
			'title': response.data.data.property_section.tile.title,
			'property_type': response.data.data.property_section.tile.property_type,
			'max_nights': response.data.data.property_section.max_nights,
			'min_nights': response.data.data.property_section.min_nights,
			'available_units': response.data.data.property_section.available_units,
			'guests_per_unit': response.data.data.property_section.guests_per_unit,

		});
		
		let flag = Object.assign({},this.props.prepayment.flag);
		flag.apiData = true;
		flag.footerPriceVisibility = true;
		this.props.updatePrepaymentState({
			'property_images': response.data.data.property_section.tile.property_images,
			'property_section': response.data.data.property_section,
			'invoice': response.data.data.invoice,
			'discount_section': response.data.data.discount_section,
			'footer': response.data.data.footer_data.footer,
			'payment_methods': response.data.data.payment_methods,
			'left_div': response.data.data.footer_data.left_div,
			'right_div': response.data.data.footer_data.right_div,
			'is_mobile_verified': response.data.data.user_section.is_mobile_verified,
			'is_user_referred': response.data.data.user_section.is_user_referred,
			'promocode': response.data.data.discount_section.discount.discount_code,
			'discount_type': response.data.data.discount_section.discount.discount_type,
			'bookable_as_unit': response.data.data.property_section.bookable_as_unit,
			'payment_method': response.data.data.invoice.selected_payment_method,
			'misconception': response.data.data.misconception,
			'misconception_code': response.data.data.misconception_code,
			'flag' : flag,
			extendedDataLoaded: true
		}).then( () => {
			if (response.data.data.discount_section.discount.discount_type.length > 0) {
				if (this.props.prepayment.discount_section.discount.discount_type === 'coupon' && this.props.prepayment.discount_section.discount.discount_valid) {
					ReactDOM.findDOMNode(this.refs['promo']).checked = 1;
					this.props.updatePrepaymentState({ apply_wallet: 0 });
				} else {
					ReactDOM.findDOMNode(this.refs['promo']).checked = 0;
				}
				if (this.props.prepayment.discount_section.discount.discount_type === 'wallet' && this.props.prepayment.discount_section.discount.discount_valid) {
					ReactDOM.findDOMNode(this.refs['wallet']).checked = 1;
					this.props.updatePrepaymentState({ apply_wallet: 1 });
				}
			}

			if (this.props.prepayment.misconception !== '') {
				this.openMisconceptionModal();
			}


			let urlParams = '?checkin=' + this.dateConvertor(this.props.booking.checkin_date) + '&checkout=' + this.dateConvertor(this.props.booking.checkout_date) + '&guests=' + this.props.booking.guest_count + '&units=' + this.props.booking.unit_count + '&payment_method=' + this.props.prepayment.payment_method;
			if (this.props.prepayment.discount_type === 'coupon') {
				urlParams = urlParams + '&coupon_code=' + this.props.prepayment.promocode + '&discount_type=' + this.props.prepayment.discount_type;
			} else if (this.props.prepayment.discount_type === 'wallet') {
				urlParams = urlParams + '&apply_wallet=' + this.props.prepayment.apply_wallet + '&discount_type=' + this.props.prepayment.discount_type;
			}
			this.props.history.replace('/prepayment/' + this.props.match.params.hashId + urlParams);


			let property_data = {
				// using YYYY-MM-DD
				checkin: this.getDateFormatted(this.props.booking.checkin_date),
				checkout: this.getDateFormatted(this.props.booking.checkout_date),
				pid: response.data.data.property_section.tile.property_id,
				hpid: response.data.data.property_section.tile.property_hash_id,
				city: response.data.data.property_section.tile.location.city,
				state: response.data.data.property_section.tile.location.state,
				country: response.data.data.property_section.tile.location.country.ccode,
				country_name: response.data.data.property_section.tile.location.country.name,
				guests: response.data.data.property_section.guests,
				lat: response.data.data.property_section.tile.location.latitude,
				long: response.data.data.property_section.tile.location.longitude,
				area: response.data.data.property_section.tile.location.area,
				location: response.data.data.property_section.tile.location.location_name,
				properties: [{
					hpid: response.data.data.property_section.tile.property_hash_id,
					title: response.data.data.property_section.tile.title,
					price: parseInt(response.data.data.footer_data.footer.final_amount, 10)
				}],
				currency_code: response.data.data.invoice.currency_code
			};
			trackPrepaymentView(this.props.tracker, this.page, 'direct', property_data);
			let flag = Object.assign({}, this_el.props.error.flag);
			flag.network = false;
			flag.server = false; 
			this_el.props.updateErrorState({
				flag : flag
			});
		})

	}

	getDateFormatted(date){
		// date is supposed to be date object in mm-dd-yyyy
		// returning is yyyy-mm-dd
		if(date !== 1 && typeof date.getFullYear === "function") {
			let month = date.getMonth() > 8 ? date.getMonth() + 1 : "0"+(date.getMonth() +1);
			return date.getFullYear() + "-"+(month) +"-"+date.getDate();
		}
		return '';
	}

	getPrepaymentApiDataError(error) {
		const this_el = this;
		// console.log('error',error);
		if(typeof error.response !== "undefined" && error.response.status === 1000){
			let error_flag = Object.assign({}, this_el.props.error.flag);
			error_flag.network = true;
			this.props.updateErrorState({ flag: error_flag });
		}else if(error.response.status === 500){
			//console.log('dgdg',error);
			let flag =  this.props.error.flag;
			flag.server = true; 
			this.props.updateErrorState({ flag: flag });
			
		}else {
			if(typeof error.response.data !== "undefined" && error.response.data.error.length > 0 ){
					
				let a = error.response.data.error.map(function (msg){
					return msg.message
				})

				this.props.updatePrepaymentState({
					errorMessage:a.join(', '),
				}).then( () => {
					this.openErrorMessage_modal();
				})

			}else{
				trackError(error);
			}
		}
	}

	getPrepaymentApi(data){
		let this_el = this;
		getPrepaymentDataApi(data).then(function(response){
			// success
			this_el.getPrepaymentApiDataSuccess(response)

		}).catch(function(error){
			// error
			this_el.getPrepaymentApiDataError(error);
		});
	}


	getPaymentMathod(payment_method){
		let flag = Object.assign({}, this.props.prepayment.flag);
		flag.footerPriceVisibility = false;

		this.props.updatePrepaymentState({
			'payment_method' : payment_method,
			'flag': flag
		}).then( () => {
			let urlParams = '?checkin=' + this.dateConvertor(this.props.booking.checkin_date) +  '&checkout=' + this.dateConvertor(this.props.booking.checkout_date) + '&guests=' + this.props.booking.guest_count + '&units=' + this.props.booking.unit_count +'&payment_method='+ payment_method;
			if(this.props.prepayment.discount_type === 'coupon'){
				urlParams = urlParams +'&coupon_code='+ this.props.prepayment.promocode;
			}else if(this.props.prepayment.discount_type === 'wallet'){
				urlParams = urlParams +'&apply_wallet='+this.props.prepayment.apply_wallet;
			}
			this.getPrepaymentApi(this.props.match.params.hashId + urlParams);

		});
	}

	componentDidMount(){
		this.props.updatePrepaymentState({
			promocode: this.props.location.search.includes('coupon_code') ? getURLParameter(this.props.location.search, 'coupon_code') : '',
			apply_wallet: this.props.location.search.includes('apply_wallet') ? getURLParameter(this.props.location.search, 'apply_wallet') : '',
			discount_type: this.props.location.search.includes('discount_type') ? getURLParameter(this.props.location.search, 'discount_type') : '',
			payment_method: this.props.location.search.includes('payment_method') ? getURLParameter(this.props.location.search, 'payment_method') : '',
		})

		this.props.updateBookingDataState({
			checkin_date: this.props.location.search.includes('checkin') ? this.toDate(getURLParameter(this.props.location.search, 'checkin')) : (1),
			checkout_date: this.props.location.search.includes('checkout') ? this.toDate(getURLParameter(this.props.location.search, 'checkout')) : (1),
			guest_count: this.props.location.search.includes('guests') ? (parseInt(getURLParameter(this.props.location.search, 'guests'), 10)) : (0),
			unit_count: this.props.location.search.includes('units') ? (parseInt(getURLParameter(this.props.location.search, 'units'), 10)) : (0),
		}).then(() => {
			if (this.props.booking.checkin_date === 1) {
				this.getPrepaymentApi(this.props.match.params.hashId);
			} else {

				let urlParams = '?checkin=' + this.dateConvertor(this.props.booking.checkin_date) + '&checkout=' + this.dateConvertor(this.props.booking.checkout_date) + '&guests=' + this.props.booking.guest_count + '&units=' + this.props.booking.unit_count + '&payment_method=' + this.props.prepayment.payment_method;
				if (this.props.prepayment.discount_type === 'coupon') {
					urlParams = urlParams + '&coupon_code=' + this.props.prepayment.promocode;
				} else if (this.props.prepayment.discount_type === 'wallet') {
					urlParams = urlParams + '&apply_wallet=' + this.props.prepayment.apply_wallet;
				}
				this.getPrepaymentApi(this.props.match.params.hashId + urlParams);
			}
		})


		// on url change fuction
		let this_el = this;
		window.onpopstate = function(e) {
			this_el.modalRouting(e.currentTarget.location.hash);
		};

		// fuction to check if any modal open
		this.modalRouting(this.props.history.location.hash);

		trackPageView(this.props.tracker, this.page);

		window.scrollTo(0,0);
	}

	toDate(dateStr) {
		if(dateStr !== null) {
			var parts = dateStr.split("-");
			return new Date(parts[2], parts[1] - 1, parts[0]);
		}else{
			return 1;
		}
	}

	dateConvertor(data){
		if(data !== 1){
			let dd = data.getDate();
			let mm = data.getMonth()+1; //January is 0!

			let yyyy = data.getFullYear();
			if(dd<10){
				dd='0'+dd;
			} 
			if(mm<10){
				mm='0'+mm;
			} 
			return dd+'-'+mm+'-'+yyyy;
		}
	}

	shakeGuestCount() {
		const this_el = this;
		this.props.updateSearchState({ shake_animation: 'shakeAnimation' })
		setTimeout(function () {
			this_el.props.updateSearchState({ shake_animation: '' })
		}, 500)
	}

	shakeUnitCount() {
		const this_el = this;
		this.props.updatePropertyState({ shake_animation_unit: 'shakeAnimation' })
		setTimeout(function () {
			this_el.props.updatePropertyState({ shake_animation_unit: '' })
		}, 500)
	}


	openDateRangeCalendar(){
		const this_el = this;
		let flag = Object.assign({}, this_el.props.search.flag);
		flag.modal.date_range = true;
		this_el.props.updateSearchState({
			flag: flag
		});
	}

	submitPropertyDataToApi(){
		const this_el = this;
		this_el.props.updatePropertyState({
			prepaymentModify: true
		});

		if(this.props.booking.checkin_date === 1){
			this.openDateRangeCalendar();
		} else if(this.props.booking.guest_count < 1 || this.props.booking.unit_count < 1){
			if(this.props.booking.guest_count < 1){
				this.shakeGuestCount();
			}
			if(this.props.booking.unit_count < 1){
				this.shakeUnitCount();
			}


			if ("vibrate" in navigator) {
				navigator.vibrate(500);

			}
		} else {
			let urlParams = '?&checkin=' + this.dateConvertor(this.props.booking.checkin_date) + '&checkout=' + this.dateConvertor(this.props.booking.checkout_date) + '&guests=' + this.props.booking.guest_count + '&units=' + this.props.booking.unit_count+'&payment_method='+ this.props.prepayment.payment_method;
			if(this.props.prepayment.discount_type === 'coupon'){
				urlParams = urlParams +'&coupon_code='+ this.props.prepayment.promocode;
			}else if(this.props.prepayment.discount_type === 'wallet'){
				urlParams = urlParams +'&apply_wallet='+this.props.prepayment.apply_wallet;
			}
			this.getPrepaymentApi(this.props.match.params.hashId + urlParams);

			let flag = Object.assign({}, this_el.props.search.flag);
			flag.modal.select_guest = false;
			this_el.props.updateSearchState({
				flag: flag
			});

			removeClassToHtml();
		}
	}

	getInstantBookPaymentDataApi(data){
		const this_el = this;
		getInstantBookPaymentApi(data)
		.then(function(response){
			trackBookingRequest(this_el.props.tracker, this_el.page, 'direct', {
				checkin: this_el.getDateFormatted(this_el.props.booking.checkin_date),
			  	checkout: this_el.getDateFormatted(this_el.props.booking.checkout_date),
				pid: this_el.props.prepayment.property_section.tile.property_id,
				hpid: this_el.props.prepayment.property_section.tile.property_hash_id,
				city: this_el.props.prepayment.property_section.tile.location.city,
				state: this_el.props.prepayment.property_section.tile.location.state,
				country: this_el.props.prepayment.property_section.tile.location.country.ccode,
				country_name: this_el.props.prepayment.property_section.tile.location.country.name,
				guests: this_el.props.prepayment.property_section.guests,
				lat: this_el.props.prepayment.property_section.tile.location.latitude,
				long: this_el.props.prepayment.property_section.tile.location.longitude,
				area:this_el.props.prepayment.property_section.tile.location.area,
				location: this_el.props.prepayment.property_section.tile.location.location_name,
				hbrid:response.data.data.request_id, 
				instant:response.data.data.instant_book, 
				request_code:response.data.data.msg_code, 
				user_id: response.data.data.user_id, 
				properties:[{hpid:this_el.props.prepayment.property_section.tile.property_hash_id, 
				title:this_el.props.property.title, price: parseInt(this_el.props.prepayment.footer.final_amount, 10)}]
			});

			setTimeout(function(){

				// success
				if(response.data.data.msg_code === 'request_created'){

					if(response.data.data.instant_book === 0){
						this_el.props.updatePrepaymentState({ request_id: response.data.data.request_id })
						this_el.props.history.push('/request/' + response.data.data.request_id);
						// this_el.openRequestSentModal(response.data.data.request_id); remove sent request to the host modal as it not in IOS and Android
					}else{
						if(window.location.port){
						window.location.href = response.data.data.payment_url + '&origin='+ window.location.protocol+'//'+window.location.hostname+':'+window.location.port;
						} else{
							window.location.href = response.data.data.payment_url + '&origin='+ window.location.protocol+'//'+ window.location.hostname;
						}
						this_el.refs.payButton.classList.remove('disable');
					}

				}else if(response.data.data.msg_code === 'request_exists'){
					this_el.props.updatePrepaymentState({
						errorMessage: response.data.data.message,
						messageHeader: response.data.data.header,
						request_id: response.data.data.request_id
					}).then( () => {
						this_el.openRequestExist_modal();
					})

				} else if(response.data.data.msg_code === 'trip_exists'){
					this_el.props.updatePrepaymentState({
						errorMessage: response.data.data.message,
						messageHeader: response.data.data.header,
						request_id: response.data.data.request_id,
						booking_status: response.data.data.booking_status
					}).then( () => {
						this_el.openRequestExist_modal();
					})
				}
			}, 2000);
			
			
		}).catch(function(error){
			// error
			// console.log('error\n\n\n',error);

			if(typeof error.response === "undefined" && typeof error.response.data !== "undefined" && error.response.data.error.length > 0 ){
					
				let a = error.response.data.error.map(function (msg){
					return msg.message
				})

				let flag = Object.assign({}, this_el.props.prepayment.flag);
				flag.refreshPage = true;
				this_el.props.updatePrepaymentState({
					errorMessage:a.join(', '),
					flag:flag
				}).then( () => {
					this_el.openErrorMessage_modal();
				})

			}

		});
	}

	makePayment(e){
		e.stopPropagation();
		if (checkIfOffline()) {
			this.toggleStatus()
		} else {
			if((window.innerHeight + window.scrollY + 100) >= document.body.scrollHeight){

				let args = {
					property_hash_id: this.props.prepayment.property_section.tile.property_hash_id,
					checkin: this.dateConvertor(this.props.booking.checkin_date),
					checkout:this.dateConvertor(this.props.booking.checkout_date),
					guests:this.props.booking.guest_count,
					units:this.props.booking.unit_count,
					payment_method:this.props.prepayment.invoice.selected_payment_method,
					payable_amount:parseInt(this.props.prepayment.footer.final_amount, 10),
					apply_wallet:this.props.prepayment.apply_wallet,
					coupon_code:this.props.prepayment.promocode,
					discount_type: this.props.prepayment.discount_type
				}
				
				this.getInstantBookPaymentDataApi(args);
				e.target.classList.add('disable');
			} else {
				window.scrollTo(0,document.body.scrollHeight);
			}
		}
	}

	openMaxNightsAlert(){
		this.refs.max_nights_alert_overlay.classList.add('true');
	}

	closeMaxNightsAlert(){
		this.refs.max_nights_alert_overlay.classList.remove('true');
	}

	openUnavailableDate(){
		this.refs.unavailable_date_overlay.classList.add('true');
	}

	closeUnavailableDate(){
		this.refs.unavailable_date_overlay.classList.remove('true');
	}

	openUnitAlert(){
		this.refs.unitAlert.classList.add('true');
	}

	closeUnitAlert(){
		this.refs.unitAlert.classList.remove('true');
	}

	openMisconceptionModal(){
		this.refs.misconception_modal.classList.add('true');
	}

	closeMisconceptionModal(){
		this.refs.misconception_modal.classList.remove('true');
		this.props.updateBookingDataState({
			checkinDate:1,
			checkoutDate:1,
		}).then( () => {
			this.openDateRangeCalendar();
		});

	}
	openErrorMessage_modal(){
		this.refs.errorMessage_modal.classList.add('true');
	}
	closeErrorMessage_modal(){
		if(this.props.prepayment.flag.refreshPage){
			this.reload();
		} else {
			this.refs.errorMessage_modal.classList.remove('true');
			this.props.history.replace('/')
		}
	}


	//Force Request Creation
	openRequestExist_modal() {
		let flag = this.props.prepayment.flag;
		flag.modal.forceCreateModal = true;
		this.props.updatePrepaymentState({
			flag: flag
		})
	}

	closeRequestExist_modal() {
		let flag = this.props.prepayment.flag;
		flag.modal.forceCreateModal = false;
		this.props.updatePrepaymentState({
			flag: flag
		})
		this.refs.payButton.classList.remove('disable');
	}

	createRequestForcely() {
		let flag = this.props.prepayment.flag;
		flag.modal.forceCreateModal = false;
		this.props.updatePrepaymentState({
			flag: flag
		})
		this.forceCreate();
	}


	forceCreate(){
		let args = {
			property_hash_id: this.props.prepayment.property_section.tile.property_hash_id,
			checkin: this.dateConvertor(this.props.booking.checkin_date),
			checkout: this.dateConvertor(this.props.booking.checkout_date),
			guests: this.props.booking.guest_count,
			units: this.props.booking.unit_count,
			payment_method: this.props.prepayment.invoice.selected_payment_method,
			payable_amount: parseInt(this.props.prepayment.footer.final_amount, 10),
			apply_wallet: this.props.prepayment.apply_wallet,
			coupon_code: this.props.prepayment.promocode,
			discount_type: this.props.prepayment.discount_type,
			force_create:1,
		}

		this.getInstantBookPaymentDataApi(args);
	}
	// End Force Request Creation


	reload=()=>{
		this.props.updateBookingDataState({
			checkin_date: this.props.location.search.includes('checkin') ? this.toDate(getURLParameter(this.props.location.search, 'checkin')) : (1),
			checkout_date: this.props.location.search.includes('checkout') ? this.toDate(getURLParameter(this.props.location.search, 'checkout')) : (1),
			guest_count: this.props.location.search.includes('guests') ? (parseInt(getURLParameter(this.props.location.search, 'guests'), 10)) : (0),
			unit_count: this.props.location.search.includes('units') ? (parseInt(getURLParameter(this.props.location.search, 'units'), 10)) : (0),
		}).then(() => {
			if (this.props.booking.checkin_date === 1) {
				this.getPrepaymentApi(this.props.match.params.hashId);
			} else {

				let urlParams = '?checkin=' + this.dateConvertor(this.props.booking.checkin_date) + '&checkout=' + this.dateConvertor(this.props.booking.checkout_date) + '&guests=' + this.props.booking.guest_count + '&units=' + this.props.booking.unit_count + '&payment_method=' + this.props.prepayment.payment_method;
				if (this.props.prepayment.discount_type === 'coupon') {
					urlParams = urlParams + '&coupon_code=' + this.props.prepayment.promocode;
				} else if (this.props.prepayment.discount_type === 'wallet') {
					urlParams = urlParams + '&apply_wallet=' + this.props.prepayment.apply_wallet;
				}
				this.getPrepaymentApi(this.props.match.params.hashId + urlParams);
			}
		})
	}

	openRequestSentModal(request_id){
		let flag =  this.props.prepayment.flag;
		flag.modal.requestSentModal = true;
		this.props.updatePrepaymentState({flag:flag, request_id:request_id});
	}

	goBack(){
		this.props.history.goBack();
	}

	toggleStatus = () => {
		this.setState({
			toggleOfflineStatus: !this.state.toggleOfflineStatus
		}, () => {
			this.setState({
				toggleOfflineStatus: !this.state.toggleOfflineStatus
			})
		})
	}

	modalRouting(hash){
		addClassToHtml();
		let flag = this.props.prepayment.flag;
		switch(hash){
			case '#termCondition':
				flag.modal.termCondition = true;
				break;

			case '#walletModal':
				flag.modal.walletModal = true;
				break;

			case '#promocodeWallet':
				flag.modal.promocodes = true;
				flag.modal.couponConfirmation = false;
				break;

			case '#couponConfirmation':
				flag.modal.promocodes = false;
				flag.modal.couponConfirmation = true;
				break;


			default:
				flag.modal.termCondition = false;
				flag.modal.walletModal = false;
				flag.modal.promocodes = false;
				flag.modal.couponConfirmation = false;
				removeClassToHtml();
		}
		this.props.updatePrepaymentState({ 'flag' : flag})
	}

	opentermCondition() {
		window.location.hash = 'termCondition'
	}

    render() {
        const this_el = this;
        return (
            <div className="prepayment">
                <header className="header active">
                    <div className="row">
                        <div className="col-12 text-left">
                            <span className="back-icon" onClick={this.goBack}></span>
                            <span className="middle-text text-center">Booking details</span>
                        </div>                                                      
                    </div>
                </header>
                {!this.props.error.flag.network || this.props.prepayment.extendedDataLoaded ?
					<div>
						{!this.props.error.flag.server ?
						<div>
						<OfflineStatus
							offline={checkIfOffline() && !this.state.toggleOfflineStatus}
						/>
						<PrepaymentBookingDetails/>

						{this_el.props.prepayment.flag.apiData &&
							<div>
								<Invoice/>

								{/* wallet and coupon checkbox section*/}
								<section className="wallet-coupon">
									<div className="heading">
									Have a coupon code or wallet balance?
									</div>
									<div className="check-box-wrap">  
										<div className="box">
											<div className="row">
												<div className="col-10">
													<span className="wallet-icon"></span>
													{this.props.prepayment.discount_section.wallet.wallet_money > 0
														?
														<span className="text">Use {this.props.prepayment.discount_section.wallet.wallet_currency_symbol}{(this.props.prepayment.discount_section.wallet.wallet_money % 1 !== 0) ? (this.props.prepayment.discount_section.wallet.wallet_money.toFixed(2)) : (this.props.prepayment.discount_section.wallet.wallet_money.toLocaleString('en'))} from My Wallet</span>
														:
														<span className="text">No balance in wallet</span>
													}
													{this.props.prepayment.discount_section.discount.discount_type === 'wallet' &&
														<span>
															{this.props.prepayment.discount_section.discount.discount_valid
															?
															<span className="success-msg">{this.props.prepayment.discount_section.discount.discount_message}</span>
															:
															<span className="error-msg">{this.props.prepayment.discount_section.discount.discount_message}</span>
															}
														</span>
													}
												</div>
												<div className="col-2 text-right">
													{this.props.prepayment.discount_section.wallet.applicable === 1
														?
														<span>
														<input type="radio" name="checkbox" id="radio4" onChange={() => this.walletModalOpen()} className="css-checkbox" ref='wallet' />
														<label htmlFor="radio4" className="css-label radGroup2"></label>
														</span>
														:
														<span>
															<input type="radio" name="checkbox" id="radio4" className="css-checkbox disable" disabled/>
															<label htmlFor="radio4" className="css-label disable radGroup2"></label>
														</span>
													}
												</div>
											</div>
										</div>
										<div className="box" >
											<div className="row">
												<div className="col-10">
													<span className="promo-code"></span>
													<span className="text">Apply promo code</span>
													{this.props.prepayment.discount_section.discount.discount_type === 'coupon' && 
														<span>
															{this.props.prepayment.discount_section.discount.discount_valid
															?
															<span className="success-msg">{this.props.prepayment.discount_section.discount.discount_message}</span>
															:
															<span className="error-msg">{this.props.prepayment.discount_section.discount.discount_message}</span>
															}
														</span>
													}
												</div>
												<div className="col-2 text-right">
													{this.props.prepayment.discount_section.coupon.applicable === 1
													?
													<span>
													<input type="radio" name="checkbox" id="radio5" onChange={() => this.promocodeOpen()} ref='promo' className="css-checkbox" />
													<label htmlFor="radio5" className="css-label radGroup2" ></label>
													</span>
													:
													<span>
													<input type="radio" name="checkbox" id="radio5"  className="css-checkbox" />
													<label htmlFor="radio5" className="css-label disable  radGroup2" ></label>
													</span>
													}
												</div>
											</div>
										</div>
									</div>
								</section>

								{/* Payment section */}
								<PaymentSection toggleStatus={this.toggleStatus} getPaymentMathod={this.getPaymentMathod}/>
				
								{this.props.prepayment.left_div.title.length > 0 && this.props.prepayment.right_div.title.length > 0 ?
									<section>
										<div className="row extra-feature">
											<div className="col-6 pull-left">
												<h4>{this.props.prepayment.left_div.title}</h4>
												<p>{this.props.prepayment.left_div.text}</p>
											</div>
											<div className="col-6 pull-left">
												<h4>{this.props.prepayment.right_div.title}</h4>
												<p>{this.props.prepayment.right_div.text}</p>
											</div>
										</div>
									</section>
									:
									<section>
										{this.props.prepayment.left_div.title.length > 0 &&
										<div className="row extra-feature full-length">
											<div className="col-12 pull-left">
												<h4>{this.props.prepayment.left_div.title}</h4>
												<p>{this.props.prepayment.left_div.text}</p>
											</div>
										</div>
										}
										{this.props.prepayment.right_div.title.length > 0 &&
										<div className="row extra-feature full-length">
											<div className="col-12 pull-left">
												<h4>{this.props.prepayment.right_div.title}</h4>
												<p>{this.props.prepayment.right_div.text}</p>
											</div>
										</div>
										}
									</section>
								}

								{this.props.prepayment.property_section.instant_book === 1 ?
									<section className="term">					
										<span className='text'><span>By procedding, I agree to accept GuestHouser’s </span><span className="green-color" onClick={() => this.opentermCondition()}>T&C , <a href="https://www.guesthouser.com/cancellation_policy?app=1" target="_blank" rel="noopener noreferrer" className="green-color">Cancellation and Refund Policy</a></span></span>
									</section>
								:
									<section className="term book">
										<span className="request-icon"></span>
										<span className='text booking-avail'>This home is not instantly bookable, but confirming availability usually takes under 5 minutes!</span>
									</section>
								}

								<section className="pay-btn">
									<div className="row">
										{this.props.prepayment.property_section.instant_book === 1 &&
											<div>
												{this.props.prepayment.flag.footerPriceVisibility 
												?
													<div className="col-6">
														<div className="text">{this.props.prepayment.footer.title}<div className="price">{this.props.prepayment.footer.sub}</div></div>
													</div>
												:
													<div className="col-6 loading"></div>
												}
											</div>
										}

										<div className={this.props.prepayment.property_section.instant_book === 1 ? "col-6 text-right" : "col-12 text-center"}>
											{hasAccessToken() 
												?
													<div>
														{this.props.prepayment.is_mobile_verified ?
															<button ref="payButton" className="pay-button" onClick={this.makePayment}>{this.props.prepayment.property_section.instant_book === 1 && <span className="reserve-icon"></span>} {this.props.prepayment.footer.button_text}</button>
														:
															<Link to="/user/contact" onClick={saveUrlLogin}>
																<button className="pay-button" >{this.props.prepayment.property_section.instant_book === 1 && <span className="reserve-icon"></span>} {this.props.prepayment.footer.button_text}</button>
															</Link>
														}
													</div>
												:
													<Link to="/signup" onClick={saveUrlLogin}>
														<button className="pay-button" >{this.props.prepayment.property_section.instant_book === 1 && <span className="reserve-icon"></span>} {this.props.prepayment.footer.button_text}</button>
													</Link>								
											}
										</div>
									</div>
								</section>

								<TermCondition/>

								<Promocode
									promocodeClose={this.promocodeClose}
									savepromocode={this.savepromocode}
								/>
								
								<CouponConfirmation
									couponConfirmationClose={this.promocodeClose}
									couponConfirmation={this.couponConfirmation}
								/>

								<WalletModal
									walletModalClose={this.walletModalClose}
									confirmWallet={this.confirmWallet}
								/>

								<SelectGuestsModal
									submitPropertyDataToApi={this.submitPropertyDataToApi}
								/>

								<DateRangeCalendar 
									openMaxNightsAlert={this.openMaxNightsAlert}
									openUnitAlert = {this.openUnitAlert}
									openUnavailableDate={this.openUnavailableDate}
								/>

								<RequestSentModel />

								<div ref="misconception_modal" className='unavailable_date_overlay'>
									<div className="max_nights_alert">
										<div className="modal-body text-center">
										
											<h2 className="text">Non-Availability</h2>
											<div className='description'>
												<p>{this.props.prepayment.misconception}</p>
											</div>
											<button  className="btn" onClick={this.closeMisconceptionModal}> Done</button>
										</div>
									</div>
								</div>

								<div ref="max_nights_alert_overlay" className='max_nights_alert_overlay'>
									<div className="max_nights_alert">
										<div className="modal-body text-center">
										
											<h2 className="text">Non-Availability</h2>
											<div className='description'>
												<p>Minimum stay: {this.props.property.min_nights} night</p>
												<p>Maximum stay: {this.props.property.max_nights} nights</p>
											</div>
											<button  className="btn" onClick={this.closeMaxNightsAlert}> Done</button>
										</div>
									</div>
								</div>

								<div ref="unavailable_date_overlay" className='unavailable_date_overlay'>
									<div className="max_nights_alert">
										<div className="modal-body text-center">
										
											<h2 className="text">Non-Availability</h2>
											<div className='description'>
												<p>Date selection is invalid</p>
											</div>
											<button  className="btn" onClick={this.closeUnavailableDate}> Done</button>
										</div>
									</div>
								</div>

								<div ref="unitAlert" className='unitAlert'>
									<div className="max_nights_alert">
										<div className="modal-body text-center">
										
											<h2 className="text">Non-Availability</h2>
											<div className='description'>
												<p>Only {this.props.booking.available_units} {this.props.booking.available_units > 1 ? 'units are' :'unit is'} available on selected date</p>
											</div>
											<div className="btn" onClick={this.closeUnitAlert}>Done</div>
										</div>
									</div>
								</div>

								<img src={noInternet} className='hidden' alt='no internet'/>
							</div>
						}
					</div>
					:
						<div className="info-window">
							<div className="window-container">
								<div className="imgb">
									<div className="img" style={{ backgroundImage: "url(" + serverError + ")" }}/>
								</div>
								<div className="txtb">
									<p>Sorry, our servers are busy. Please try again in a bit.</p>
								</div>
								<button className="retry" onClick={this.reload}>Retry</button>
							</div>
						</div>
					}
				</div>
				:
					<div className="info-window">
						<div className="window-container">
							<div className="imgb">
							<div className="img" style={{backgroundImage:'url(' + noInternet + ')'}}></div>
							</div>
							<div className="txtb"><p>Oops! You don't seem to be connected to the internet.</p></div>
							<button  className="retry" onClick={this.reload}>Retry</button>
						</div>
					</div>        
				}

				<div ref="errorMessage_modal" className='unavailable_date_overlay'>
					<div className="max_nights_alert">
						<div className="modal-body text-center">

							<h2 className="text">Error!</h2>
							<div className='description'>
								<p>{this.props.prepayment.errorMessage}</p>
							</div>
							<button className="btn" onClick={this.closeErrorMessage_modal}> Done</button>
						</div>
					</div>
				</div>

				<ForceCreateModal
					forceCreateModalClose={this.closeRequestExist_modal}
					createRequestForcely={this.createRequestForcely}
					gotoRequest={this.gotoRequest}
				/>

			</div>
		);
	}
}

const mapStateToProps = (store) => {
	return {
		search: store.search,
		booking: store.booking,
		property: store.property,
		prepayment: store.prepayment,
		error: store.error,
		tracker: store.tracker.tracker
	}
}

const mapDispatchToProps = {
	updatePropertyState,
	updateBookingDataState,
	updateSearchState,
	updateErrorState,
	updatePrepaymentState
}

export default connect(mapStateToProps, mapDispatchToProps)(PrepamentPropertyDetails);