import React from 'react';
import {Link} from 'react-router-dom';
import booking_before_login from "../../static/img/infoWindow/booking_before_login.png";
import bookingRequestImg from "../../static/img/infoWindow/booking-request-after-request.png";
import activeImage from "../../static/img/infoWindow/activeImage.png";
import noInternet from "../../static/img/infoWindow/noInternet.svg";
import { connect } from 'react-redux';
import serverError from "../../static/img/serverError.png";
//services
import {hasAccessToken, getRequestDetailsDataApi,saveUrlLogin} from '../../services/index.js';
import {trackPageView,trackError} from '../../services/tracker/events.js';

/**
 * Actions
 */
import { updateRequestState } from '../../actions/request/actions';
import { updateErrorState } from '../../actions/error/actions';
import OfflineStatus from '../../components/Offlinestatus';
import { checkIfOffline } from '../../services/helpers';


class Request extends React.Component {
	constructor(props){
		super(props);
		this.page="request";
		this.torequestDetails = this.torequestDetails.bind(this);
		this.reload = this.reload.bind(this);
	}
	imageLoaded(e){
        let imgSrc = e.target.src;
        e.target.nextElementSibling.style.backgroundImage = 'url('+imgSrc+')';
    }
	getRequestApiDataSuccess(response, reset) {
		const this_el = this;

		this.props.updateErrorState({ flag: { network: false } });

		let flag = Object.assign({}, this_el.props.request.flag);
		flag.no_result = (response.data.data.requests.length < 12 ); // if no more result flag found, set no_result to true
		flag.api.is_loader = false;
		let properties = Object.assign([], this_el.props.request.requests);
		
		if (reset) {
			properties = response.data.data.requests
		} else {
			Array.prototype.push.apply(properties,  response.data.data.requests); 
		}
  
		this.props.updateRequestState({
			'requests': properties,
			'archived_request_count': response.data.data.archived_request_count,
			'api': response.data.data.requests.length === 0 && response.data.data.archived_request_count === 0 ? true : false,
			'activeImg': response.data.data.requests.length === 0 && response.data.data.archived_request_count > 0 ? true : false,
			'active_request_count': response.data.data.active_request_count,
			RdataLoaded: true,
			flag:flag
		}).then(() => {
			localStorage.setItem('request_count', (this_el.props.request.active_request_count === undefined) ? 0 : this_el.props.request.active_request_count);
			this_el.props.updateActiveTripCount();
			
			let error_flag = Object.assign({}, this_el.props.error.flag);
            error_flag.network = false;
            error_flag.server = false;
            this_el.props.updateErrorState({
                flag: error_flag
            });
		});
		
	}
	getRequestApiDataError(error) {
		const this_el = this;
		if(typeof error.response !== 'undefined' && error.response.status === 1000){
			let error_flag = Object.assign({}, this_el.props.error.flag);
			error_flag.network = true;

			// updating network state
			this_el.props.updateErrorState({
				flag: error_flag
			});
		}else if(error.response.status === 500){
			//console.log('dgdg',error);
			let flag =  this.props.error.flag;
			flag.server = true; 
			this.props.updateErrorState({ flag: flag });
			
		}else{
			trackError(error);
		}
	}

	getRequestApiData(args, reset){
		let this_el = this;
		getRequestDetailsDataApi(args).then(function(response){
			// success
			this_el.getRequestApiDataSuccess(response, reset);
		}).catch(function(error){
			// error
			this_el.getRequestApiDataError(error);
		});
	}

	componentWillMount(){
		const this_el = this;
		if(!this_el.props.request.RdataLoaded && hasAccessToken()){
			this_el.submitParams();
        }
		let flag = Object.assign({}, this.props.request.flag);
		flag.api.is_loader =  false;
		this.props.updateRequestState({
			flag:flag
		})
		//this_el.submitParams();
		/**
		 * Active scroll listner
		 */
		if (hasAccessToken()) {
			this_el.loadMoreResults();
		}
	}
	componentWillUnmount() {
		this.props.updateRequestState({
			'requests_scroll': window.pageYOffset
		})
	}
	
	componentDidMount() {
		if (checkIfOffline()) {
			this.props.updateErrorState({ flag: { network: true } });
		} else {
			this.props.updateErrorState({ flag: { network: false } });
		}

		trackPageView(this.props.tracker, this.page);

		window.scrollTo(0, this.props.request.requests_scroll);
	}

	torequestDetails(data){
		const this_el = this;
		let flag = Object.assign({}, this_el.props.request.flag);
		flag.api.is_loader = false;
		this.props.history.push({
			  pathname: '/request/'+data.request_hash_id,
		});
		if (!checkIfOffline()) {
			this.props.updateRequestState({
				requestHash: data.request_hash_id
			})
		}
		this.props.updateRequestState({
			booking_status: data.booking_status,
			request_hash_id: data.request_hash_id,
			flag:flag
		});
	}

	submitParams(reset){
		const this_el = this;
		let args = {
			archived:'?archived=' + 0,
			total:12,
			offset:this_el.props.request.Roffset,
			loader: true,
		}
		this.getRequestApiData(args, reset);
	}
	reload = () =>{
		const this_el = this;
		/**
		 * Calling Api
		 */
		this_el.submitParams();
		/**
		 * Active scroll listner
		 */
		this_el.loadMoreResults();
    }
    
    loadMoreResults(){
		const this_el = this;
		
		window.onscroll = function(){
			
			let request_list =false;
            if (document.getElementById('request_list') !== null){
                request_list = true;
            }else {
                request_list = false;
            }
			if(request_list){
			//let offset = document.documentElement.scrollTop;
			const scrollTopOffset = Math.max(window.pageYOffset, document.documentElement.scrollTop, document.body.scrollTop);

			let windowHeight = window.innerHeight;
			let bodyHeight = document.body.clientHeight-500; // was 260
			let offsetWindowHeight = windowHeight + scrollTopOffset;
			if ((offsetWindowHeight >= bodyHeight) && !this_el.props.request.flag.api.is_loader && !this_el.props.request.flag.no_result){

				/**
				 * Updating api state to true, so in case of scroll no more api hit called, untill last api result popup
				 */

				let new_offset = this_el.props.request.Roffset + 12;
				let flag = Object.assign({}, this_el.props.request.flag);
				flag.api.is_loader = true;

                this_el.props.updateRequestState({
					Roffset : new_offset,
					flag : flag
				}).then(() => {
					this_el.submitParams();
				});

			}
			}
		}
		}
		
		componentDidUpdate () {
			if (this.props.request.updateList) {
				this.props.updateRequestState({
					updateList: false
				})
				this.submitParams(true);
			}
		}
	
	
	render() {
		const this_el = this;
		const ellipsis = {
			 textOverflow: 'ellipsis',
			 overflow:'hidden',
			 WebkitBoxOrient: 'vertical'
		}
		return (
			<div className="booking-container" id="request_list">
				<header className="header active">
					<div className="row">
						<div className="col-12 text-left">
							<span className="middle-text text-center">Booking Requests</span>
						</div>                                                      
					</div>
				</header>
				{!this.props.error.flag.network || this.props.request.RdataLoaded ?
				<div>
				{!this.props.error.flag.server ?
				<div>
				<OfflineStatus
					offline={checkIfOffline()}
				/>
				{hasAccessToken()
					?
					<div>

						 {this.props.request.requests.length > 0 ?
							<div className='booking'>
								{this.props.request.requests.map(function(item, index){
									return(
										<div key={index} onClick={() => this_el.torequestDetails(item)}>
											<div className="booking-tile" >
												<div className="response">
													<div className="row">
														<div className="col-8">
															<div className={'text '+ item.booking_status.class}>{item.booking_status.text}</div>
														</div>
														<div className="col-4 text-right">
															<div className={'icon '+ item.booking_status.class}></div>
														</div>
													</div>
												</div>
												<div className="tile">
													<div className="row">
														<div className="col-4">
														{item.property_tile.property_images.length > 0 ?
															item.property_tile.property_images.map(function(imageItem, indexImage){
																let thumb = (imageItem.image).replace('2x', 'thumb');
																return(
																	<div key={indexImage} >
																	<img src={thumb} onLoad={(e) => this_el.imageLoaded(e)} className='hidden' alt="thumb" />
																	<div className="property-image" ></div>
																	</div>
																)
															})
															:
															<div className="property-image"></div>

														}
														</div>
														<div className="col-8">
															<div className="heading" style={ellipsis}><p>{item.property_tile.title}</p></div>
															<div className="subheading">{item.property_tile.location.location_name}</div>
															<div className="price">{item.booking_amount}</div>
														</div>
													</div>
												</div>
											</div>
										</div>
									)
								})}
								{!this_el.props.request.flag.no_result &&
									<div className={'loader text-center'}>
										<div className="lds-spinner"><div></div><div></div><div></div><div></div><div></div><div></div><div></div><div></div><div></div><div></div><div></div><div></div></div>
									</div>
								}
							</div>
							:
							<div>
								{this.props.request.activeImg &&
									<div className="active-request">
										<div className="active-container">
											<div className="imgb">
												<div className="img" style={{backgroundImage:'url(' + activeImage + ')'}}></div>
											</div>
											<div className="txtb"><p>Oops! You don't have any active booking request.</p></div>
										</div>
									</div>
								}
							</div>
							}
							{this.props.request.api && 
								<div className="info-window afterlogin">
									<div className="window-container">
										<div className="imgb">
											<div className="img" style={{backgroundImage:'url(' + bookingRequestImg + ')'}}></div>
										</div>
										<div className="txtb"><p>Looks like you haven't made a booking request yet</p></div>
									</div>
								</div>
							}
							
							{this.props.request.archived_request_count > 0 && this_el.props.request.flag.no_result &&
								<div className="row">
									<div className="col-12 text-center">
										<Link to="/archived/list" className="button">{this.props.request.archived_request_count} archived requests</Link>
									</div>
								</div>
							
							}
					</div> 
					:
					<div className="info-window">
						<div className="window-container">
							<div className="imgb">
							   <div className="img" style={{backgroundImage:'url(' + booking_before_login + ')'}}></div>
							</div>
							<div className="txtb"><p>Hi! Please log in to view your booking requests</p></div>
							<Link to="/signup" onClick={saveUrlLogin}><div className="btn">Login</div></Link>
						</div>
					</div> 
					}
				</div>
				:
					<div className="info-window">
						<div className="window-container">
							<div className="imgb">
								<div className="img" style={{ backgroundImage: "url(" + serverError + ")" }}/>
							</div>
							<div className="txtb">
								<p>Sorry, our servers are busy. Please try again in a bit.</p>
							</div>
							<button className="retry" onClick={this.reload}>Retry</button>
						</div>
					</div>
				}
				</div>
				:
				<div className="info-window">
	                <div className="window-container">
	                    <div className="imgb">
	                        <div className="img" style={{backgroundImage:'url(' + noInternet + ')'}}></div>
	                    </div>
	                    <div className="txtb"><p>Oops! You don't seem to be connected to the internet.</p></div>
	                    <button className="retry" onClick={this.reload}>Retry</button>
	                </div>
	            </div>
				}
			</div>
			
		);
	}
}

const mapStateToProps = (store) => {
	return {
		request:store.request,
		tracker: store.tracker.tracker,
		error: store.error
	}
}

const mapDispatchToProps = {
	updateRequestState,
	updateErrorState
}

export default connect(mapStateToProps,mapDispatchToProps)(Request);