import React from 'react';
import Modal from 'react-modal';
import ResponseMessage from '../../../components/response/';
import {getInvoiceSendApi} from '../../../services/index.js';
import { connect } from 'react-redux';
import OfflineStatus from '../../Offlinestatus';
import { checkIfOffline } from '../../../services/helpers';

class ViewInvoice extends React.Component {
    constructor(props){
        super(props);
        this.state={
            'message':'',
            'show':false,
            'status':false,
            toggleOfflineStatus: false
        }
        this.sendInvoice=this.sendInvoice.bind(this);
        this.handleSubmit=this.handleSubmit.bind(this);
        this.getsendInvoiceDataSuccess=this.getsendInvoiceDataSuccess.bind(this);
        this.getsendInvoiceDataError=this.getsendInvoiceDataError.bind(this);
        this.resetMessages = this.resetMessages.bind(this);
    }
    resetMessages(){
        this.setState({
            'message':'',
            'show':false,
        });
    }
    handleSubmit(event) {
        if (checkIfOffline()) {
            this.toggleStatus()
            return
        }
        this.sendInvoice({
            'request_hash_id': this.props.tripDetailData.request_hash_id,
        }); 

    }

    toggleStatus = () => {
        console.log('toggled')
		this.setState({
			toggleOfflineStatus: !this.state.toggleOfflineStatus
		}, () => {
            setTimeout(_ => {
                this.setState({
                    toggleOfflineStatus: !this.state.toggleOfflineStatus
                })
            }, 100)
		})
	}

    getsendInvoiceDataSuccess(response){
        this.setState({
            'message':response.data.data.message,
            'show':true,
        });
    }

    getsendInvoiceDataError(error){
        console.log(error);
    }

    sendInvoice(args){
        let this_el = this;
        getInvoiceSendApi(args).then(function(response){
            // success
            this_el.getsendInvoiceDataSuccess(response);

        }).catch(function(error){
            // error
            this_el.getsendInvoiceDataError(error);

        });
    }
   
    render() {
        return (
            <div>
                <Modal
                  isOpen={this.props.viewInvoiceOpen}
                  onRequestClose={this.props.viewInvoiceClose}
                  contentLabel="Modal"

                      overlayClassName={{
                        base: 'overlay-modal',
                        afterOpen: '',
                        beforeClose: ''
                      }}
                  className={{
                        base: 'content-modal',
                        afterOpen: 'full-screen-modal viewInvoiceModal',
                        beforeClose: ''
                      }}

                >
                <OfflineStatus
                    offline={checkIfOffline() && !this.state.toggleOfflineStatus}
                    bottom
                />
                <div className="modal-header viewInvoiceHeader">
                  <div className="header-btn back-btn" onClick={this.props.viewInvoiceClose}> </div>
                  <h2 className="text-center">Price breakup</h2>
                  <div className="transparent-gredient"></div>
                </div>
                <div className="modal-body">
                    <div className="viewInvoice">
                    </div>                    
                    <div className="invoice view-invoice">
                        <div className="invoice-details">
                             {/*<div className="heading">Price breakup</div>*/}
                            <section className="first">
                                {this.props.tripDetailData.invoice_header.map(function(item, index){
                                    if(item.show === 1){
                                        return(
                                            <div className="cell" key={index}>
                                                <div className="row">
                                                    <div className="col-8 text-left">
                                                        <div className="title">{item.key}</div>
                                                        <div className="subtitle">{item.sub_key}</div>
                                                    </div>
                                                    <div className="col-4 text-right">                                    
                                                        <div className="cost">{item.value}</div>
                                                    </div>
                                                </div>
                                            </div>
                                        )
                                    } else {
                                        return false
                                    }
                                })}
                            </section> 
                            <section className="second">
                                {this.props.tripDetailData.invoice_middle.map(function(item, index){
                                    const color =item.hasOwnProperty("color") ? {color : item.color} : {};
                                    const bold =item.hasOwnProperty("bold") ? {fontWeight:'bold',color: '#7b7b7b', fontSize:'16px'}:{};
                                    if(item.show === 1){
                                        return(
                                            <div className="cell" key={index}>
                                                <div className="row">
                                                    <div className="col-8 text-left">
                                                        {item.bold === 1 ?
                                                        <div className="title" style={bold}>{item.key}</div>
                                                        :
                                                        <div className="title" style={color}>{item.key}</div>
                                                        }
                                                        <div className="subtitle">{item.sub_key}</div>
                                                    </div>
                                                    <div className="col-4 text-right">   
                                                        {item.bold === 1 ?                                 
                                                        <div className="cost" style={bold}>{item.value}</div>
                                                        :
                                                        <div className="cost" style={color}>{item.value}</div>
                                                        }
                                                    </div>
                                                </div>
                                            </div>
                                        )
                                    } else {
                                        return false
                                    }
                                })}
                            </section> 
                            <section className="third">
                                {this.props.tripDetailData.invoice_footer.map(function(item, index){
                                    const color =item.hasOwnProperty("color") ? {color : item.color} : {};
                                    const bold =item.hasOwnProperty("bold") ? {fontWeight:'bold',color: '#7b7b7b', fontSize:'16px'}:{};
                                    if(item.show === 1){
                                        return(
                                            <div className="payable" key={index}>
                                                <div className="row">
                                                    <div className="col-8 text-left">
                                                        {item.bold === 1 ? 
                                                            <div className="title" style={bold}>{item.key}</div>
                                                            :
                                                            <div className="title" style={color}>{item.key}
                                                                {item.sub_key.length > 0  &&
                                                                    <div className="green-color">{item.sub_key}</div>
                                                                }
                                                            </div>
                                                        }
                                                    </div>
                                                    <div className="col-4 text-right"> 
                                                        {item.bold === 1 ?                                   
                                                        <div className="cost" style={bold}>{item.value}</div>
                                                        :
                                                        <div className="cost" style={color}>{item.value}</div>
                                                        }
                                                    </div>
                                                </div>
                                            </div>
                                        )
                                    } else {
                                        return false
                                    }
                                })}
                            </section> 
                        
                        </div>
                        <div className="policy">Guests are requested to provide valid identification at the time of check-in.</div>
                        <div className="row">
                            <div className="col-12 text-center">
                                <button onClick={this.handleSubmit} className="button">Send on email</button>
                            </div>
                        </div>
                    </div>
                    <div className={'responseAPI '+this.state.show }>
                    <ResponseMessage responseMessage={this.state.message} responseStatus={this.state.status} resetMessages={this.resetMessages}/>
                </div>
                </div>
                </Modal>
            </div>
        );
    }
}

const mapStateToProps = (store) => {
    return {
        tripDetailData: store.tripData,
    }
}

export default connect(mapStateToProps, null)(ViewInvoice);