import React from 'react';
import OTPImg from "../../static/img/otp1.png";
import ResponseMessage from '../../components/response/';
import {connect} from 'react-redux';
import { withRouter } from 'react-router-dom'
//services
import {getDialCodesApi, getVarifyEmailApi} from '../../services/index.js';
/**
 * Actions
 */
import {updateOtpStateObj} from './../../actions/otp/actions';
import {updateSignupStateObj} from './../../actions/signup/actions';
var Form = require('react-formal');

var yup = require('yup');
var userOtpSendSchema = yup.object({  
	contact_number: yup.string().nullable().required('Please enter contact number').min(10,'Invalid phone number'),
});
var addEmailSchema =yup.object({
	email: yup.string().required('Email required').email('Invalid email'),
})
class OtpSend extends React.Component {
	constructor(props) {
		super(props);
		this.getOtpApiDataError = this.getOtpApiDataError.bind(this);
		this.getOtpApiDataSuccess = this.getOtpApiDataSuccess.bind(this);
		this.handleSubmit = this.handleSubmit.bind(this);
		this.changeHandle = this.changeHandle.bind(this);
		this.showReferralCode = this.showReferralCode.bind(this);
		this.showErrorMSg= this.showErrorMSg.bind(this);
        this.resetMessages = this.resetMessages.bind(this);
        this.referalCodeChange=this.referalCodeChange.bind(this);
        this.formErrorHandle = this.formErrorHandle.bind(this);
        this.onBlurShowMessage=this.onBlurShowMessage.bind(this);
		this.onFocusHideMessage=this.onFocusHideMessage.bind(this);
		this.submitEamilVarifySuccessDetail = this.submitEamilVarifySuccessDetail.bind(this);  
        this.getVarifyEmailSuccessApiDataError=this.getVarifyEmailSuccessApiDataError.bind(this);
		this.getVarifyEmailSuccessApiDataSuccess=this.getVarifyEmailSuccessApiDataSuccess.bind(this);
		this.keyUpFunction = this.keyUpFunction.bind(this);
	}

	keyUpFunction(e){
        if (e.keyCode === 13) {
            e.target.blur();
        }
    }

	formErrorHandle(errors){
        for (var property in errors) {
            if (errors.hasOwnProperty(property) && errors[property] !== undefined && errors[property].length > 1) {
                errors[property] = errors[property][0]['message'];
                //console.log("\n\n\n\n", property, errors[property],errors[property].length);
            }
        }
    }


	dialCodeHandleChange = (e) => {
	 	let model = this.props.otp.model;
	 	model.dial_code = e.target.value;
	    this.props.updateOtpStateObj({ model : model });
	}

	resetMessages(){
        this.props.updateOtpStateObj({
            'message':'',
            'show':false,
        });
    }

	showErrorMSg(){
		this.props.updateOtpStateObj({
			'errorMSg':true,
		})
	}

	showReferralCode() {
		this.props.updateOtpStateObj({
			show_referral_code: true,
			referral_text: false
		});
	}

	handleSubmit(event) { 
		this.props.showLoader();
		this.props.submitOTPDetail({
			'dial_code': this.props.otp.model.dial_code,
			'contact_number': this.props.otp.model.contact_number,
			'referral_code' :this.props.otp.referral_code,
			'otp_method': this.props.otp_method
		}, this.getOtpApiDataSuccess, this.getOtpApiDataError);

		this.props.updateState({
			'contact_number': this.props.otp.model.contact_number,
			'dial_code': this.props.otp.model.dial_code,
			'referral_code':this.props.otp.referral_code,
		});

		localStorage.setItem('contact_number',this.props.otp.model.contact_number);
	}

	getOtpApiDataError(error) {
		this.props.updateOtpStateObj({
			'message': error.response.data.error[0].message,
			'status': error.response.data.status,
			'show':true,
		});
	}
	
	getOtpApiDataSuccess(response) {
		this.props.showVerifyScreen();
		localStorage.setItem('referral_code',this.props.otp.referral_code == null ? '' : this.props.otp.referral_code);
	}
	
	changeHandle(model){
		this.props.updateOtpStateObj({ model });
	}

	sortNumber(a,b) {
		return a.dial_code - b.dial_code;
	}

	getDialCodesDataSuccess(response) {
		let dialCodeArray = response.data.data.dial_codes;
		this.props.updateOtpStateObj({
			'dial_code_list':dialCodeArray.sort(this.sortNumber),
		});
	}

	getDialCodesDataError(error) {
	   console.log('error', error);
	}

	getDialCodesDataApi(){
		let this_el = this;
		getDialCodesApi().then(function(response){
			// success
			this_el.getDialCodesDataSuccess(response);
		}).catch(function(error){
			// error
			this_el.getDialCodesDataError(error);
		});
	}
	componentWillMount(){
		let model = this.props.otp.model;
	 	model.contact_number = localStorage.getItem('contact_number');

		this.props.updateOtpStateObj({
			model:model,
			referral_code:localStorage.getItem('referral_code')
		})
	}
	componentDidMount(){
		this.getDialCodesDataApi();
	}

	referalCodeChange(value){
      this.props.updateOtpStateObj({
            'referral_code': value
     	})
	}
	
	onFocusHideMessage(){
        this.props.updateOtpStateObj({ validation:false,});
    }
    onBlurShowMessage(){
        this.props.updateOtpStateObj({ validation:true,});
	}
	
	submitEamilVarifySuccessDetail(args){
        let this_el = this;
        getVarifyEmailApi(args).then(function(response){
            // success
            this_el.getVarifyEmailSuccessApiDataSuccess(response);
        }).catch(function(error){
            // error
            this_el.getVarifyEmailSuccessApiDataError(error);
        });
    }
    getVarifyEmailSuccessApiDataError(error) {
        let this_el = this;
        this_el.props.updateOtpStateObj({
			'message': error.response.data.error[0].message,
			'status': error.response.data.status,
			'show':true,
        });
    }
    
    getVarifyEmailSuccessApiDataSuccess(response) {
    	const this_el=this;
		localStorage.setItem('addEmail', 0)
        this_el.props.updateSignupStateObj({
            'add_email':0,
		}).then(_ => {
			if (this.props.mobileVerified !== 0) {
				this.props.history.replace('/?retry')
			}
		})
	}
	
	render() {
		const this_el = this;
		return (
			<div className="otp">  
				{
					this.props.addEmail === 0 ?
					<header className="header">
						<span className="back-btn-grey" onClick={() => this.props.goBack()}></span>
					</header>
				:
					<header className="header">
						{/* <span className="back-btn-grey" onClick={() => this.props.goBack()}></span> */}
					</header>
				}
				<div className="otpImg"><img src={OTPImg} alt='otp' /></div>
				<div className="otp-wrap">                    
					{this.props.addEmail === 0
					?<Form schema={userOtpSendSchema} 
							onSubmit={this.handleSubmit}
							onChange={model => this.changeHandle(model)} 
							defaultValue={this.props.otp.model} 
							errors={this.props.otp ? this.props.otp.errors : {}}
							onError={errors => this.formErrorHandle(errors)}
							className="step2">
							<div className="text">Verify your contact number</div>	
							<span className="select-wrapper">  
								<select className="form-control country-code"  value={this.props.otp.model.dial_code} onChange={(e)=>this.dialCodeHandleChange(e)}>
									{this_el.props.otp.dial_code_list.map(function(item, index){
										return(
											<option key={index} value={item.dial_code}>+{item.dial_code}</option>
										)
		
									})}
								</select>
							</span>
							<div className="input-wrap">
							<Form.Field
								type="number"
								string="NumberInput"
								onKeyUp={(e) => this.keyUpFunction(e)}
								pattern="\d*"
								name="contact_number"
								className="textbox mobile"
								placeholder="contact number"
								onFocus={this.onFocusHideMessage} 
								onBlur={this.onBlurShowMessage}
							/>
		
							{this.props.otp.validation &&
								<Form.Message for='contact_number'/>
							}
							</div>
							{localStorage.getItem("is_user_referred") === '0' &&
								<input type="text"  className="textbox refferal" value={this.props.otp.referral_code} placeholder="Enter referral code" onChange={ e => this.referalCodeChange(e.target.value)}/>
							}
							{/*
							<div className="referal" style={{ display: (this.props.otp.referral_text ? 'block': 'none') }} onClick={() => this.showReferralCode()}>Have a referral code?</div>
							*/}
							<div className="termss">We will send a four digit code on this number to verify your account.</div>
							<Form.Button  type='submit'  onClick={this.showErrorMSg} className="btn login link">Continue</Form.Button>
						</Form>				
					:	<Form schema={addEmailSchema} 
							onSubmit={this.submitEamilVarifySuccessDetail}
							onChange={model => this.changeHandle(model)} 
							defaultValue={this.props.otp.model} 
							errors={this.props.otp ? this.props.otp.errors : {}}
							onError={errors => this.formErrorHandle(errors)}
							className="step2"
						>
						<div className="text">Please add your email</div>
						
						<div className="input-wrap">
						<Form.Field
							name="email"
							onKeyUp={(e) => this.keyUpFunction(e)}
							className="textbox"
							placeholder="Email"
							onFocus={this.onFocusHideMessage} 
							onBlur={this.onBlurShowMessage}
						/>

						{this.props.otp.validation &&
							<Form.Message for='email'/>
						}
						</div>
						<Form.Button  type='submit'  onClick={this.showErrorMSg} className="btn login link">Continue</Form.Button>
					</Form>
		
					}
				</div>
				<div className={'responseAPI '+this.props.otp.show }>
                	<ResponseMessage responseMessage={this_el.props.otp.message} responseStatus={this_el.props.otp.status} resetMessages={this_el.resetMessages}/>
                </div>

			</div>
		);
	}
}
const mapStateToProps = (store) => {
	return {
		otp : store.otp,
		addEmail: store.signupEmail.add_email,
		mobileVerified: store.signupEmail.is_mobile_verified
    }
}
const mapDispatchToProps = {
	updateOtpStateObj,
	updateSignupStateObj
}
export default connect(mapStateToProps, mapDispatchToProps)(withRouter(OtpSend));