const trackError = function(error){
	if(window.Raven){
		//console.log(error);
		window.Raven.captureException(error)
	}
}

const trackPageView = function(tracker, page){
		tracker.trackEvent({
		  type: 'page_view',
		  data: {
		    page: page
		  }
		});
}

const trackSearch = function(tracker, page, source, search_data){
		tracker.trackEvent({
		  type: 'search',
		  data: {
		    page: page,
		    source:source,
		    city: search_data.city,
		    state: search_data.state,
		    country: search_data.country,
		    country_name:search_data.country_name,
		    checkin: search_data.checkin,
		    checkout: search_data.checkout,
		    guests: search_data.guests,
		    lat: search_data.lat,
		    lng: search_data.long,
		    area: search_data.area,
		    location: search_data.location,
		    pids:search_data.pids,
		    hpids:search_data.hpids,
		    currency_code: search_data.currency_code,
		    properties:search_data.properties
		  }
		});
}

const trackPropertyView = function(tracker, page, source, search_data){
		tracker.trackEvent({
		  type: 'property_view',
		  data: {
		    page: page,
		    source:source,
		    city: search_data.city,
		    state: search_data.state,
		    country: search_data.country,
		    country_name:search_data.country_name,
		    checkin: search_data.checkin,
		    checkout: search_data.checkout,
		    guests: search_data.guests,
		    lat: search_data.lat,
		    lng: search_data.long,
		    area: search_data.area,
		    location: search_data.location,
		    pid:search_data.pid,
		    hpid:search_data.hpid,
		    currency_code: search_data.currency_code,
		    properties:search_data.properties
		  }
		});
}
const trackPrepaymentView = function(tracker, page, source, search_data){
		tracker.trackEvent({
		  type: 'prepayment_view',
		  data: {
		    page: page,
		    source:source,
		    city: search_data.city,
		    state: search_data.state,
		    country: search_data.country,
		    country_name:search_data.country_name,
		    checkin: search_data.checkin,
		    checkout: search_data.checkout,
		    guests: search_data.guests,
		    lat: search_data.lat,
		    lng: search_data.long,
		    area: search_data.area,
		    location: search_data.location,
		    pid:search_data.pid,
		    hpid:search_data.hpid,
		    currency_code: search_data.currency_code,
		    properties:search_data.properties
		  }
		});
}

const trackTripDetailsView = function(tracker, page, source, search_data){
		tracker.trackEvent({
		  type: 'trip_details_view',
		  data: {
		    page: page,
		    source:source,
		    city: search_data.city,
		    state: search_data.state,
		    country: search_data.country,
		    country_name:search_data.country_name,
		    checkin: search_data.checkin,
		    checkout: search_data.checkout,
		    guests: search_data.guests,
		    lat: search_data.lat,
		    lng: search_data.long,
		    area: search_data.area,
		    location: search_data.location,
		    pid:search_data.pid,
		    hpid:search_data.hpid,
		    hbrid: search_data.hbrid,
		    payment_page: search_data.payment_page,
			payable_amount:search_data.payable_amount,
			payment_currency:search_data.payment_currency,
			instant:search_data.instant,
			coupon_code_used:search_data.coupon_code_used,
			wallet_money_used:search_data.wallet_money_used,
			properties:search_data.properties
		  }
		});
}

const trackGoToPropertyPage = function(tracker, page, source, hpid, data, properties){
		tracker.trackEvent({
		  type: 'goto_property_page',
		  data: {
		    page: page,
		    source:source,
		    hpid:hpid,
		    currency_code: (typeof data !== "undefined" && typeof data.currency_code !== "undefined") ? data.currency_code : '',
		    properties: properties
		  }
		});
}

const trackGoToCollectionPage = function(tracker, page, source, cid){
		tracker.trackEvent({
		  type: 'goto_collection_page',
		  data: {
		    page: page,
		    source:source,
		    cid:cid
		  }
		});
}

const trackGoToCollectionListPage = function(tracker, page, source){
		tracker.trackEvent({
		  type: 'goto_collection_list_page',
		  data: {
		    page: page,
		    source:source
		  }
		});
}

const trackGoToTrendingCitiesListModal = function(tracker, page, source){
		tracker.trackEvent({
		  type: 'goto_trending_cities_list_modal',
		  data: {
		    page: page,
		    source:source
		  }
		});
}
const trackGoToRecentlyViewListListModal = function(tracker, page, source){
		tracker.trackEvent({
		  type: 'goto_recently_view_list_modal',
		  data: {
		    page: page,
		    source:source
		  }
		});
}

const trackGoToSearchPage = function(tracker, page, source, search_data){
	tracker.trackEvent({
	  type: 'goto_search_page',
	  data: {
	    page: page,
	    source:source,
	    city: search_data.city,
	    state: search_data.state,
	    country: search_data.country,
	    checkin: search_data.checkin,
	    checkout: search_data.checkout,
	    guests: search_data.guests,
	    lat: search_data.lat,
	    lng: search_data.long,
	    area: search_data.area,
	    location: search_data.location
	  }
	});
}

const trackGoToStayPage = function(tracker, page, source, stay_url){
	tracker.trackEvent({
	  type: 'goto_stay_page',
	  data: {
	    page: page,
	    source:source,
	    stay_url: stay_url
	  }
	});
}

const trackLoginSignup = function(tracker, page, source, data){
	tracker.trackEvent({
      // event would be signup or login
	  type: data.event,
	  data: {
	    page: page,
	    source:source,
	    user_id: data.user_id,
	    type: data.type
	  }
	});
}

const trackBookingRequest = function(tracker, page, source, data){
	tracker.trackEvent({
	  type: 'booking_request',
	  data: {
	    page: page,
	    source:source, 
	    city: data.city,
	    state: data.state,
	    country: data.country,
	    country_name:data.country_name,
	    checkin: data.checkin,
	    checkout: data.checkout,
	    guests: data.guests,
	    lat: data.lat,
	    lng: data.long,
	    area: data.area,
	    location: data.location,
	    pid:data.pid,
	    hpid:data.hpid,
	    instant: data.instant,
	    request_code: data.request_code,
	    user_id:data.user_id,
	    properties:data.properties
	  }
	});
}

export {trackPageView,trackError, trackGoToPropertyPage, trackGoToCollectionPage, 
	trackGoToSearchPage, trackGoToStayPage, trackGoToCollectionListPage,
	trackGoToTrendingCitiesListModal, trackGoToRecentlyViewListListModal,
	trackSearch, trackPropertyView, trackPrepaymentView, trackTripDetailsView, 
	trackLoginSignup, trackBookingRequest};
