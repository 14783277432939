import React from 'react';
import { connect } from 'react-redux';
import { withRouter } from "react-router-dom";
import { addClassToBody } from "../../services/index";

class AboutProperty extends React.Component{

    openAboutProperty() {
        sessionStorage.setItem("scrollVal", Math.max(window.pageYOffset, document.documentElement.scrollTop, document.body.scrollTop));
        addClassToBody();
        this.props.history.push("/property/about/"+ this.props.property.property_hash_id);
    }

	render(){

        const this_el = this;

        const ellipsis = {
             textOverflow: 'ellipsis',
             overflow:'hidden',
             WebkitBoxOrient: 'vertical'
        }
		return(
			<section className="about-property clearfix">
                {this_el.props.property.flag.api.is_loading ?
                    <div>
                        <h3>About</h3>
                        <p style={ellipsis}>{this_el.props.property.about}</p>
                        <span className="read-more" onClick={this.openAboutProperty.bind(this)}>Read more</span>
                    </div>

                :
                    <div>
                    <h3>About</h3>
                    <div className="animated-b animated-3"> 
                        <div className="background-masker after-heading-left-overlay"></div>
                        <div className="background-masker after-heading-right-overlay"></div>
                        <div className="background-masker paragraph"></div>
                        <div className="background-masker paragraph-1"></div>
                        <div className="background-masker paragraph-2"></div>
                        <div className="background-masker paragraph-3"></div>
                    </div>
                    </div>
                }

            </section>
		);
	}
}
const mapStateToProps = (store) => {
    return {
        property : store.property
    }
}

export default withRouter(
  connect(
    mapStateToProps,
    null
  )(AboutProperty)
);