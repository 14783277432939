import React from 'react';
import Modal from 'react-modal';
import InfiniteCalendar, {Calendar, withRange} from 'react-infinite-calendar';
import 'react-infinite-calendar/styles.css'; // only needs to be imported once
import { withRouter } from 'react-router-dom'


import {connect} from 'react-redux';
import { updateBookingDataState } from './../../../actions/booking-data/actions';
import { updateSearchState } from './../../../actions/search/actions';
import { updatePropertyState } from './../../../actions/property/actions';
import { hasAccessToken } from '../../../services';



class DateRangeCalendar extends React.Component {
	constructor(props){
		super(props);

		this.onDateSelect = this.onDateSelect.bind(this);
		this.deactiveFloatBtn = this.deactiveFloatBtn.bind(this);
		this.activeFloatBtn = this.activeFloatBtn.bind(this);
		this.dateSelected = this.dateSelected.bind(this);
		this.setDisableDates =  this.setDisableDates.bind(this);
		this.rangeHasDisableDates =  this.rangeHasDisableDates.bind(this);

		this.afterOpen = this.afterOpen.bind(this);
		this.resetDateRangeCalendar = this.resetDateRangeCalendar.bind(this);
		this.selectedDateRangeArray = [];

		this.disabledDates = [];

		this.tempEnabledDate  = "";
		this.tempEnabledDateData =  {};

		this.closeModal = this.closeModal.bind(this);
		this.startDate = this.props.booking.checkin_date;
		this.endDate = this.props.booking.checkout_date;

		// used to store settimeout fns ref in afteropen
		this.setTimeoutFnc = function () { return };
		this.isDirty = false;
	}




	closeModal() {

		const this_el = this;

		let flag = Object.assign({}, this_el.props.search.flag);
		flag.modal.date_range = false;

		this_el.props.updateSearchState({
			flag: flag
		});

		if (this.props.booking.resetted) {
			this.props.updateBookingDataState({
				checkin_date: this.props.resettedDates !== undefined ? this.props.resettedDates.checkin_date : this.props.booking.checkin_date, 
				checkout_date: this.props.resettedDates !== undefined ? this.props.resettedDates.checkout_date : this.props.booking.checkout_date,
				resetted: false
			})
		}
	}



	activeFloatBtn(){
		this.refs.floatingBtn.classList.add('active');
	}

	deactiveFloatBtn(){
		this.refs.floatingBtn.classList.remove('active');
	}

	datediff(first, second){
		return Math.round((second-first)/(1000*60*60*24));
	}

	getDateArray(start, end) {
		let arr = [],
			dt = new Date(start);

		while (dt <= end) {
			arr.push(new Date(dt));
			dt.setDate(dt.getDate() + 1);
		}

		return arr;
	}

	onDateSelect(date) {

		const this_el = this;
		let startDate = date.start;
		let endDate = date.end;
		if(this.datediff(date.start , date.end)<0){
			startDate = date.end;
			endDate = date.start;

		}

		if(date.eventType === 1){
			// reseting first to clean old mess
			this.resetEnabledFirstDisabledDate();
			// enabling first disable date based on start date
			this.enableFirstDisabledDate(date.start);
		}

		if(date.eventType === 3) {

			if(date.end){
				// checking if temp enabled date is checkout date
				var selected = this.tempEnabledDate !== ""  && this.tempEnabledDate === this.getDateString(date.end);
				// disabling back temp enabled date, partially or fully depend upon selected
				this.resetEnabledFirstDisabledDate(selected);
			}

			if(this.datediff(date.start , date.end) > this_el.props.property.max_nights || this.datediff(date.start , date.end) < this_el.props.property.min_nights) {

				if(typeof this_el.props.openMaxNightsAlert === 'function'){
					this.props.openMaxNightsAlert();
				}

				this.forceUpdate();
				this.deactiveFloatBtn();
				setTimeout(function(){
					this_el.afterOpen();
				},10)

			} else {
				// end date selected
				if(date.start.getDate()+'.'+date.start.getMonth() !== date.end.getDate()+'.'+date.end.getMonth()){
					this.startDate = startDate;
					this.endDate = endDate;
					this.refs.checkin.innerHTML = this.formatDate(startDate);
					this.refs.checkout.innerHTML = this.formatDate(endDate);
					this.activeFloatBtn();

					// making a array of selected date range after formatting
					let selectedDateRangeArray = this.getDateArray(startDate,endDate);
					let finalDateArray = [];
					for(let i = 0; i < selectedDateRangeArray.length; i++){
						let date = selectedDateRangeArray[i];
						let finalDate = date.getFullYear()+'-'+("0" + (date.getMonth() + 1)).slice(-2) +'-'+("0" + (date.getDate())).slice(-2);
						finalDateArray.push(finalDate);
					}
					this.selectedDateRangeArray = finalDateArray;
				}
			}
			// flag to show popup if range has disable date exculding end date
			let hasDisableDate = this.rangeHasDisableDates(startDate, date.end);
			if(hasDisableDate){
				let this_el = this;
				this.props.openUnavailableDate();
				this.forceUpdate();
				this.deactiveFloatBtn();
				setTimeout(function(){
					this_el.afterOpen();
				},10)

			}
		} else {
			// start date selected
			this.deactiveFloatBtn();
			this.refs.checkout.innerHTML = 'Check-out';
			this.refs.checkin.innerHTML = this.formatDate(startDate);
		}

		this.isDirty = true;
	}

	// fetch html dom element in calender from date string
	fetchDateHtmlNodeFromCalender(date_string){
		return this.refs.calendarContainerModel.node.querySelector('div.modal-body>div.Cal__Container__root > div.Cal__Container__wrapper > div.Cal__Container__listWrapper > div.Cal__MonthList__root > div div div ul li[data-date="'+date_string + '"]');

	}

	// disable or enable fully/partially date in calender
	toggleDateEnablingInCalender(date_string, enable, selected){

		const this_el = this;
		var date_elem  = this.fetchDateHtmlNodeFromCalender(date_string);

		if(date_elem)
		{
			// enable date
			if(enable){
				// enabling a date partially with adding 'not_available' class
				date_elem.classList.remove('not_available_disable');
				date_elem.classList.add('not_available');
				// setting up
				this.tempEnabledDate = date_string;
				this.tempEnabledDateData[date_string] =  this_el.props.property.calendar_prices[date_string];
				this_el.props.property.calendar_prices[date_string]['disable_class'] = ' not_available ';
				delete this_el.props.property.calendar_prices[date_string];
			}else{

				this_el.props.property.calendar_prices[date_string] =  this.tempEnabledDateData[date_string];

				// partially enabling/disabling date
				if(selected === true){
					date_elem.classList.add('not_available');
					date_elem.classList.remove('not_available_disable');
					this_el.props.property.calendar_prices[date_string]['disable_class'] = ' not_available ';

				}
				else{
					// fully disabling date
					this.tempEnabledDate = "";
					delete this.tempEnabledDateData[date_string];

					this_el.props.property.calendar_prices[date_string]['disable_class'] = ' not_available_disable ';
					date_elem.classList.remove('not_available');
					date_elem.classList.add('not_available_disable');
				}
			}
		}
	}

	// formating date to date string yyyy-mm-dd
	getDateString(date){
		if(date instanceof Date){
			return date.getFullYear()+'-'+("0" + (date.getMonth() + 1)).slice(-2) +'-'+("0" + (date.getDate())).slice(-2);
		}
		return "";
	}

	// enabling first disable date after start date
	enableFirstDisabledDate(startDate){
		var firstDisableDate = this.getFirstDisableDate(startDate);
		if(firstDisableDate !== ""){
			this.toggleDateEnablingInCalender(this.getDateString(firstDisableDate), true);
		}
	}

	// enabling checkout date if disabled and valid to enable.
	enableFirstDisbaleDateOnCalenerOpen(){

		const this_el = this;


		if(this.props && this.props.booking.checkin_date !== 1 && this_el.props.booking.checkin_date !== 1 && this_el.props.booking.checkout_date !== 1){
			var first_disable_date = this.getFirstDisableDate(this_el.props.booking.checkin_date);
			if(first_disable_date !== "" && this.getDateString(this_el.props.booking.checkout_date) === this.getDateString(first_disable_date)){
				this.enableFirstDisabledDate(this_el.props.booking.checkin_date);
			}
		}
	}

	// reseting first disbale date if enabled
	resetEnabledFirstDisabledDate(selected){
		if(this.tempEnabledDate !== ""){
			this.toggleDateEnablingInCalender(this.tempEnabledDate, false, selected);
		}
	}

	// setting disable date in local array to use later
	setDisableDates(){
		var this_el = this;
		// setting disable dates in local array.
		if(typeof this_el.props.property.calendar_prices !== "undefined"
			&& this_el.props.property.calendar_prices !== ""){
			Object.keys(this_el.props.property.calendar_prices).forEach(function(key) {
				if (this_el.props.property.calendar_prices[key].is_available === 0){
					var disdate = new Date(Date.parse(key));
					disdate.setHours(0,0,0,0);
					this_el.disabledDates.push(disdate);
	    		}
			});
		}
	}

	// get first disbale date from start date
	getFirstDisableDate(start_date){
		var firstDisableDate = "";
		var found= false;
		this.disabledDates.forEach(function(disable_date){
			if(!found && disable_date.getTime()>=start_date.getTime()){
				firstDisableDate = disable_date;
				found = true;
			}
		});
		return firstDisableDate;
	}

	// check if range has any disable date
	rangeHasDisableDates(start_date, end_date){
		var found = false;
		this.disabledDates.forEach(function(disable_date){
			if(disable_date.getTime()>=start_date.getTime() && disable_date.getTime() < end_date.getTime()){
				found = true;
			}
		});
		return found;
	}

	formatDate(value, is_checkin_date = 1){

		// checking if it is default value or undefined
		if(value === 1 || typeof value === undefined){
			return (is_checkin_date === 1) ? 'Check-in' : 'Check-out';
		}

		// checking if it is date type of string, if string then convert to date
		let date = (typeof value === 'string') ? new Date(value) : value;
		const locale = "en-us";

		// pulling month name
		let monthName = date.toLocaleString(locale, { month: "short" });
		return date.getDate() + " " + monthName + " " + date.getFullYear();
	}

	dateSelected(){

		const this_el = this;

		if (this.isDirty) {
			if(this_el.props.property.calendar_prices !== undefined){
				let selectedRange = this.selectedDateRangeArray,
				exceptionalDateArray = this_el.props.property.calendar_prices,
				exceptionaFinallDateArray = Object.keys(exceptionalDateArray),
				_ = require('underscore'),
				filterDate = _.intersection(selectedRange, exceptionaFinallDateArray);
				let available_units_array = [];
				for(let i = 0; filterDate.length > i; i++ ){
					if(exceptionalDateArray.hasOwnProperty(filterDate[i])){
						available_units_array.push(exceptionalDateArray[filterDate[i]].available_units);
					}
				}
				let finalAvailableUnit = Math.min.apply(null, available_units_array);
				if(finalAvailableUnit !== Infinity) {
					if(this_el.props.booking.unit_count > finalAvailableUnit){
	
						if (typeof this_el.props.openUnitAlert !== 'undefined'){
							this_el.props.openUnitAlert();
						}
	
						if (this.props.location.pathname.includes('property') && hasAccessToken()) {
							this.props.updateSearchState({
								temp_dates: {
									checkin_date: this_el.startDate,
									checkout_date: this_el.endDate
								}
							})
						}
	
						// updating booking data
						this_el.props.updateBookingDataState({
							resetted: false,
							checkin_date	: this_el.startDate,
							checkout_date	: this_el.endDate,
							unit_count		: 0,
							guest_count		: 0
						});
	
						// updating property data
						this_el.props.updatePropertyState({
							available_units: finalAvailableUnit,
						});
					}else {
	
						if (this.props.location.pathname.includes('property') && hasAccessToken()) {
							this.props.updateSearchState({
								temp_dates: {
									checkin_date: this_el.startDate,
									checkout_date: this_el.endDate
								}
							})
						}
	
						// updating booking data
						this_el.props.updateBookingDataState({
							resetted: false,
							checkin_date: this_el.startDate,
							checkout_date: this_el.endDate,
						});
	
						// updating property data
						this_el.props.updatePropertyState({
							available_units: finalAvailableUnit,
						});
					}
				} else {
	
					if (this_el.props.booking.unit_count > this_el.props.property.available_units){
	
						if (typeof this_el.props.openUnitAlert !== 'undefined') {
							this_el.props.openUnitAlert();
						}
	
						if (this.props.location.pathname.includes('property') && hasAccessToken()) {
							this.props.updateSearchState({
								temp_dates: {
									checkin_date: this_el.startDate,
									checkout_date: this_el.endDate
								}
							})
						}
	
						// updating booking data
						this_el.props.updateBookingDataState({
							resetted: false,
							checkin_date: this_el.startDate,
							checkout_date: this_el.endDate,
							unit_count: 0,
							guest_count: 0,
						});
	
						// updating property data
						this_el.props.updatePropertyState({
							available_units: this_el.props.property.available_units,
						});
	
	
					}else {
	
						if (this.props.location.pathname.includes('property') && hasAccessToken()) {
							this.props.updateSearchState({
								temp_dates: {
									checkin_date: this_el.startDate,
									checkout_date: this_el.endDate
								}
							})
						}
	
						// updating booking data
						this_el.props.updateBookingDataState({
							resetted: false,
							checkin_date: this_el.startDate,
							checkout_date: this_el.endDate,
						});
	
						// updating property data
						this_el.props.updatePropertyState({
							available_units: this_el.props.property.available_units,
						});
	
					}
				}
			} else {
	
				if (this.props.location.pathname.includes('property') && hasAccessToken()) {
					this.props.updateSearchState({
						temp_dates: {
							checkin_date: this_el.startDate,
							checkout_date: this_el.endDate
						}
					})
				}
	
				// updating booking data
				this_el.props.updateBookingDataState({
					resetted: false,
					checkin_date: this_el.startDate,
					checkout_date: this_el.endDate,
				});
	
			}
		}


		// updating modal flags
		let search_flags = Object.assign({}, this_el.props.search.flag);
		search_flags.modal.date_range = false;
		search_flags.modal.select_guest = true;
		this_el.props.updateSearchState({
			flag: search_flags
		});
	}

	afterOpen() {

		const this_el = this;
		this.refs.checkin.innerHTML = this.formatDate(this_el.props.booking.checkin_date, 1);
		this.refs.checkout.innerHTML = this.formatDate(this_el.props.booking.checkout_date, 2);
		this_el.props.booking.checkout_date !== 1 && this.activeFloatBtn();

	}

	resetDateRangeCalendar(e){
		const this_el = this;
		e.target.classList.add("disabled"); // to male disable reset button
		if (this.props.updateResettedDates) {
			this.props.updateResettedDates({
				checkin_date: this.props.booking.checkin_date,
				checkout_date: this.props.booking.checkout_date,
			}, () => {
				this.props.updateBookingDataState({
					checkin_date: 1,
					checkout_date: 1,
					resetted: true
				}).then(() => {
					this.deactiveFloatBtn();	
					this_el.afterOpen();
				})
			})
		} else {
			this.props.updateBookingDataState({
				checkin_date: 1,
				checkout_date: 1,
				resetted: true
			}).then(() => {
				this.deactiveFloatBtn();	
				this_el.afterOpen();
			})
		}
	}

	componentDidMount(){
		this.enableFirstDisbaleDateOnCalenerOpen();
	}

	render(){

		const this_el = this;
		this.setDisableDates();

		return(
			<Modal
				isOpen={this_el.props.search.flag.modal.date_range}
				onRequestClose={this_el.closeModal}
				onAfterClose={() => {
					console.log('Closed')
				}}
				closeTimeoutMS={500}
				contentLabel="Modal"
				onAfterOpen={this.afterOpen}
				ref="calendarContainerModel"
				overlayClassName={{
					base: 'overlay-modal calendar',
					afterOpen: 'active',
					beforeClose: 'active-out'
				}}
				className={{
					base: 'content-modal',
					afterOpen: this_el.props.property.calendar_prices !== undefined ? 'full-screen-modal date-range-calendar property_preview_calendar' : 'full-screen-modal date-range-calendar',
					beforeClose: ''
				}}>

				<div className="modal-header">
					<span className="close-btn" onClick={() => this_el.closeModal()}>Close</span>
					<span className="reset-btn" onClick={(e) => this.resetDateRangeCalendar(e)}>Reset</span>
				</div>
				<div className="modal-body" ref="calendarContainer">
					<div className='calender-header-outer'>
						<div className='calender-header'>
							<span className='checkin' ref='checkin'>Check-in</span>
							<span className='arrow'></span>
							<span className='checkout' ref='checkout'>Check-out</span>
						</div>
						<div className='calendar-gradient-layer'></div>
					</div>
					<InfiniteCalendar
						width={Math.min(window.innerWidth, 400)}
						rowHeight={50}
						Component={withRange(Calendar)}
						min={this_el.props.search.min_date}
						max={this_el.props.search.max_date}
						minDate={this_el.props.search.min_date}
						maxDate={this_el.props.search.max_date}
						selected={{
							start: this_el.props.booking.checkin_date,
							end: this_el.props.booking.checkout_date,
						}}
						locale={{
							default_disable_class: ' not_available_disable ',
							headerFormat: 'DD MMM',
							weekdays: ['Su', 'Mo', 'Tu', 'We', 'Th', 'Fr', 'Sa'],
							pricing: this_el.props.property.calendar_prices !== undefined ?
								this_el.props.property.calendar_prices : '',
							defaultPricing : {
								is_available : 1
							},
							currency:'',
							month_format: 'MMMM',
							year_format: 'YYYY'
						}}
						displayOptions={{
							showOverlay:false,
							showTodayHelper:false,
							overscanMonthCount:12,
						}}
						onSelect={
							this.onDateSelect
						}
					/>
				</div>
				<div className="floating-btn-box text-center" ref="floatingBtn">
					<a className="floating-btn" onClick={() => this.dateSelected()}>Done</a>
				</div>
			</Modal>
		)
	}
}



const mapStateToProps = store => {
	return {
		booking: store.booking,
		search: store.search,
		property: store.property
	};
};

const mapDispatchToProps = {
	updateBookingDataState,
	updateSearchState,
	updatePropertyState
};

export default connect(
	mapStateToProps,
	mapDispatchToProps
)(withRouter(DateRangeCalendar));
