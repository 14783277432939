

/* APIs */
// get search page data
let getSearchApi = function (data, args)
{
	let headers = {
        'Accept': 'application/json',
        'Content-Type': 'application/x-www-form-urlencoded',
        'device-unique-id': data.DEVICE_UNIQUE_ID,
    }


    //console.log('\n\n\ngetSearchApi Console',args);

    if(data.ACCESS_TOKEN){
        headers.Authorization = 'Bearer ' + data.ACCESS_TOKEN;
    }
	return data.AXIOS.get(data.BASE_URL_V+'/search', {
		responseType: 'json',
		params : args,
		// params: {
			
		// 	lat: args.lat,
		// 	lng: args.lng,
		// 	per_page: (args.per_page == undefined) ? 10 : args.per_page,
		// 	item_count_offset: args.offset,
		// 	country: args.country,
		// 	location: args.location,
		// 	state: args.state,
		// 	city: args.city,
		// 	exclude_city:'',
		// 	area: '',
		// 	exclude_area:'' ,
		// 	search_keyword:args.area,
		// 	checkin: args.checkin,
		// 	checkout: args.checkout,
		// 	//units:args.bedroom,
		// 	bedroom:args.bedroom,
		// 	guests: args.guests,
		// 	roomtype: args.room_type,
		// 	view:'',
		// 	cancellation_policy:'',
		// 	property_type:args.property_type,
		// 	amenities:args.amenities,
		// 	min_budget: args.min_budget,
		// 	max_budget: args.max_budget,
		// 	have_images: 1,
		// 	within_distance: 500,
		// 	prive:'',
		// 	sort:args.sort,
		// 	is_promotional:'',
		// 	instant_book: args.instant_book,
		// 	cash_on_arrival:args.cash_on_arrival,
		// 	promo:args.promo,
		// 	slug:args.slug,
		// },
        headers: headers
	});

}

exports.getSearchApi = getSearchApi;