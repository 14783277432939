

/* APIs */
// get signup data
let getEditprofileApi = function (data, args)
{   
    return data.AXIOS.put(data.BASE_URL_V+'/user', data.QS.stringify({
            first_name:args.first_name,
            last_name :args.last_name,
            gender:args.gender,
            dob:args.dob,
            marital_status:args.marital_status,
            profession:args.profession,
            spoken_languages:args.spoken_languages,
            travelled_places:args.travelled_places,
            description:args.description,
        }), {
            headers: {
                'Accept': 'application/json',
                'Content-Type': 'application/x-www-form-urlencoded',
                'device-unique-id': data.DEVICE_UNIQUE_ID,
                'Authorization' :'Bearer ' + data.ACCESS_TOKEN,
            }
        }
    );
}

exports.getEditprofileApi = getEditprofileApi;