import React from 'react';
import Swiper from 'react-id-swiper';
import {connect} from 'react-redux';
import { withRouter } from 'react-router';
import LazyLoad from 'react-lazyload';
import Placeholder from '../../static/img/placeholder-gallery.jpg'

class Img extends React.Component {
	constructor (props) {
		super(props);
		this.	state = {
			imgLoaded: true
		}
	}

	componentWillMount () {
		const image = new Image()
		image.onload = () => {
			this.setState({ imgLoaded: true })
		}
		image.src = this.props.item.property_images[0];
	}
	
	componentDidMount () {
		this.setState({ imgLoaded: false })
	}

	render () {
		const { item, className, onClick, data_index } = this.props;
		return (
			<div>
				{
					this.state.imgLoaded
						? <img src={item.property_images[0]} className={className} data-index={data_index} alt='property' onClick={onClick}  />
						: <img src={Placeholder} height='200' />
				}
			</div>
		)
	}
}

class OfferBanners extends React.Component {



	constructor(props) {
		super(props);
		this.offerClick =  this.offerClick.bind(this);
    }

    offerClick(e){
			const key_index = e.target.dataset.index;
			let data = this.props.home.home_banners[key_index];
			let urlParams = '?lat='+data.latitude+'&lng='+data.longitude+'&location='+data.location+'&city='+data.city+'&state='+data.state+'&country='+data.country + '&property_type=' + data.property_type.reduce((acc, x) => acc.length === 0 ? (acc + x) : (acc + x + ', '), '');
			if (data.promo !== undefined && data.promo.length > 0) {
				urlParams = urlParams + '&promo=' + data.promo;
			}

			if (data.utm_source !== undefined && data.utm_source.length > 0) {
				urlParams = urlParams + '&utm_source=' + data.utm_source;
			}

			if (data.utm_campaign !== undefined && data.utm_campaign.length > 0) {
				urlParams = urlParams + '&utm_campaign=' + data.utm_campaign;
			}

			if (data.utm_medium !== undefined && data.utm_medium.length > 0) {
				urlParams = urlParams + '&utm_medium=' + data.utm_medium;
			}

			this.props.history.push({
				pathname: "/search/s",
				search: urlParams,
			});
			this.props.updateHomeState({
				homeHistory: [
					...this.props.home.homeHistory,
					'/search/s' + urlParams
				]
			})
			localStorage.setItem('homeHistory', JSON.stringify([
				...this.props.home.homeHistory,
				'/search/s' + urlParams
			]))
    }

		render() {
		const this_el = this;
		const params = {
			slidesPerView: 1,
			spaceBetween: 0,
			lazy: {
				loadPrevNext: true,
			},
			// loop:true,
			touchMoveStopPropagation: false,	
			pagination: {
				el: '.swiper-pagination',
				type: 'bullets',
				clickable: false
			},
			autoplay: {
				delay: 2500,
				disableOnInteraction: false
			},
		}

		return (
			<div>
			{/*
				<div className="heading-section">
					<h2 className="heading">Best Offers</h2>
				</div>
			*/}
				<div className="offers-inner-section">
					<LazyLoad height={200}>
						{this.props.home.home_banners.length > 0 &&
							<Swiper {...params}>
								{this.props.home.home_banners.map(function(item, index, arr) {
									return(
										index === 0
										? (
											<div className="minheaight" key={index}>
												<Img item={item} data_index={index} className='swiper-lazy' data-index={index} alt='property' onClick={(e) => this_el.offerClick(e)} />
											</div>
										) : (
											<div className="minheaight" key={index}>
												<img data-src={item.property_images[0]} data-index={index} className='swiper-lazy' alt='property' onClick={(e) => this_el.offerClick(e)} />
											</div>
										)
									)
								})}
							</Swiper>
						}
					</LazyLoad>
				</div>
			</div>
		);
	}
}

const mapStateToProps = (store) => {
	return {
		home: store.home,
		tracker: store.tracker.tracker
	}
}
export default connect(mapStateToProps)(withRouter(OfferBanners));
