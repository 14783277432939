

/* APIs */
// get signup data
let getChangePasswordApi = function (data, args)
{   
    return data.AXIOS.put(data.BASE_URL_V+'/user/password/update', data.QS.stringify({
            password:btoa(args.new_password),
            current_password :btoa(args.old_password),
        }), {
            headers: {
                'Accept': 'application/json',
                'Content-Type': 'application/x-www-form-urlencoded',
                'device-unique-id': data.DEVICE_UNIQUE_ID,
                'Authorization' :'Bearer ' + data.ACCESS_TOKEN,
            }
        }
    );
}

exports.getChangePasswordApi = getChangePasswordApi;