import React from 'react';
import Modal from 'react-modal';
import shareEarn from "../../../static/img/refer-and-earn.png";
import { connect } from 'react-redux';
//services
import {getreferAndEarnDataApi, hasAccessToken} from '../../../services/index.js';
import {
  FacebookShareButton,
  WhatsappShareButton,
  EmailShareButton,
} from 'react-share';
import { checkIfOffline } from '../../../services/helpers';
import noInternet from '../../../static/img/infoWindow/noInternet.svg'
import { updateErrorState } from '../../../actions/error/actions';
import OfflineStatus from '../../Offlinestatus';

class ReferEarn extends React.Component {
	constructor(props) {
        super(props);
        this.state = {
            description:'',
            headline:'',
            refer_url:'',
            referral_code:''
        };

		this.copied = this.copied.bind(this);
		this.getreferAndEarnApiDataSuccess=this.getreferAndEarnApiDataSuccess.bind(this);
		this.getreferAndEarnApiDataError=this.getreferAndEarnApiDataError.bind(this);
		this.getreferAndEarnApi=this.getreferAndEarnApi.bind(this)


	}

    getreferAndEarnApiDataSuccess(response){
	   this.setState({
			'description': response.data.data.description,
			'headline': response.data.data.headline,
			'refer_url': response.data.data.refer_url,
			'referral_code': response.data.data.referral_code,
		});
    }

  	getreferAndEarnApiDataError(error) {
	   console.log(error);
	}

	getreferAndEarnApi(){
		let this_el = this;
		getreferAndEarnDataApi().then(function(response){
			// success
			this_el.getreferAndEarnApiDataSuccess(response);
		}).catch(function(error){
			// error
			this_el.getreferAndEarnApiDataError(error);
		});
	}
	 componentDidMount(){
		this.updateStatus()
	 }
	APIcall = () => {
		const this_el =this;
		if(hasAccessToken()){
			this_el.getreferAndEarnApi();
		}
	}
	
	updateStatus = () => {		
		if (checkIfOffline()) {
			this.props.updateErrorState({ flag: { network: true } })
		} else {
			this.props.updateErrorState({ flag: { network: false } })
		}
	}

	fallbackCopyTextToClipboard(text) {
		var textArea = document.createElement("textarea");
		textArea.value = text;
		document.body.appendChild(textArea);
		textArea.focus();
		textArea.select();
		document.body.removeChild(textArea);
	}

	copyTextToClipboard(text) {
		if (!navigator.clipboard) {
			this.fallbackCopyTextToClipboard(text);
			return;
		}
		navigator.clipboard.writeText(text).then(function() {
		}, function(err) {
			console.error(err);
		});
	}

	copied(){
		this.copyTextToClipboard(this.state.referral_code);

		let snackbar = this.refs.snackbar;
		let copyBtn = this.refs.copyBtn;
		snackbar.classList.add('show');
		copyBtn.classList.add('touchableOff');
		setTimeout(function(){ 
			snackbar.classList.remove('show');
			copyBtn.classList.remove('touchableOff');
		}, 3000);
	}

  	render() {
		  const this_el= this;
		let url= this.state.refer_url;
		return (
		  <div>
			<Modal
			  isOpen={this.props.referEarnModal}
			  onAfterOpen={this_el.APIcall}
			  onRequestClose={this.props.referEarnClose}
			  contentLabel="Modal"
				  overlayClassName={{
					base: 'overlay-modal',
					afterOpen: '',
					beforeClose: ''
				  }}
			  className={{
					base: 'content-modal',
					afterOpen: 'referearn full-screen-modal',
					beforeClose: ''
				  }}

				>
				{
						<div className="modal-body">
							<OfflineStatus offline={checkIfOffline()} bottom />
							<div className="modal-header">
								<div className="header-btn back-btn white-icon" onClick={this.props.goBack}></div>
								<h2 className="text-center">Refer & Earn</h2>
							</div>
								<div className="img" style={{backgroundImage:'url(' + shareEarn + ')'}}></div>
									<div className="top-text text-center">{this.state.headline}</div>
									<div className="footer text-center">{this.state.description}</div>
									<div className="bottom-text">Invite code : <span>{this.state.referral_code}</span></div>
									<div className="socialIcon text-center">
								<ul>
									<li>
										<WhatsappShareButton 
											url={url}
											className="whatsapp"
										>
										</WhatsappShareButton>
									</li>
									<li>
										<FacebookShareButton
											url={url}
											className='fb'
										>
										</FacebookShareButton>
									</li>
									<li>
										<EmailShareButton
												url={url}
												className='msg'
										>
										</EmailShareButton>
									</li>
									
									
									<li ref='copyBtn'>
										<div 
											onClick={this.copied}
											url={url}
											className="more">
										</div>
									</li>
								</ul>
								<span className="terms">*Terms & conditions apply.</span>
								<div ref='snackbar' id="snackbar">Copied successful</div>
								</div>
							</div>		
					// : (
					// 	<div className="info-window">
					// 			<header className={'header active'}>
					// 				<div className="row">
					// 					<div className="col-3 text-left"><div className="back-icon" onClick={this.props.referEarnClose}></div></div>
					// 				</div>
					// 			</header>
					// 		<div className="window-container" style={{ marginTop: '60px' }} >
					// 			<div className="imgb">
					// 				<div className="img" style={{ backgroundImage: "url(" + noInternet + ")" }}/>
					// 			</div>
					// 			<div className="txtb">
					// 				<p>Oops! You don't seem to be connected to the internet.</p>
					// 			</div>
					// 			<button className="retry" onClick={_ => {this.updateStatus()}}>Retry</button>
					// 		</div>
					// 	</div>
					// )
				}
		
		</Modal>
		  </div>
		);
	  }
	}
	const mapStateToProps = (store) => {
		return {
			tracker: store.tracker.tracker,
			error: store.error
		}
	}
	const mapDispatchToProps = {
		updateErrorState
	}
export default connect(mapStateToProps, mapDispatchToProps) (ReferEarn);