import React from 'react';
import { connect } from 'react-redux';
import LazyLoad from 'react-lazyload';
import { updatePropertyState } from '../../actions/property/actions';
class PropertyPhotos extends React.Component{
    constructor(props){
        super(props);
        this.openPropertyGalleryList = this.openPropertyGalleryList.bind(this);
    }

    imageCount(){
        let image_count = this.props.property_images.length;
        if (image_count > this.props.property.maxPhotos){
            return (image_count - this.props.property.maxPhotos) + ' More'; 
        }else {
            return '';
        }
    }
   

    imageLoaded(e){
        let imgSrc = e.target.src;
        e.target.nextElementSibling.style.backgroundImage = 'url('+imgSrc+')';
    }

    openPropertyGalleryList() {
        this.props.history.push("/property/galleryList/"+ this.props.property.property_hash_id);
    }
    
	render(){
        const this_el = this;
		return(
			<section className="property-photos clearfix" onClick={this.openPropertyGalleryList}>
                <h3>Photos and Videos</h3>
                <LazyLoad height={200}>
                <ul >
                {this.props.property_video.map(function(item, index){
                    return(
                        <li key={index}>
                            <img src={item.thumbnail} onLoad={(e) => this_el.imageLoaded(e)} className='hidden' alt="thumb" />
                            <div title={this_el.imageCount()}><i className="play-btn"></i></div>
                        </li>
                    )
                })}

                {this.props.property_images.map(function(item, index){
                    if(index < this_el.props.property.maxPhotos){
                        let thumb = (item.image).replace('2x', 'thumb');
                        return(
                            <li key={index}>
                                <img src={thumb} onLoad={(e) => this_el.imageLoaded(e)} className='hidden' alt="thumb" />
                                <div title={this_el.imageCount()}></div>
                            </li>
                        )
                    }else {
                        return false
                    }
                })}
                </ul>
                </LazyLoad>
            </section>
		);
	}
}

const mapStateToProps = (store) => {
    return {
        property_images: store.property.property_images,
        property_video: store.property.property_video,
        property:store.property
    }
}
const mapDispatchToProps = {
    updatePropertyState
}
export default connect(mapStateToProps, mapDispatchToProps)(PropertyPhotos);