import React from 'react';
import DocumentMeta from 'react-document-meta';
import noInternet from "../../static/img/infoWindow/noInternet.svg";
import {withRouter} from 'react-router';
import Swiper from 'react-id-swiper';
import serverError from "../../static/img/serverError.png";
// css
import '../../static/css/vendors/swiper.css';

//redux
import {connect} from 'react-redux';

/**
 * Actions
 */
import { updatePropertyState } from '../../actions/property/actions';
import { updateBookingDataState } from '../../actions/booking-data/actions';
import { updateCollectionState} from '../../actions/collection/actions';
import { updateSingleCollectionState}  from '../../actions/single-collection/actions';
import { updateErrorState} from '../../actions/error/actions';

//  
import {getCollectionPageDataApi, hasAccessToken, getWishlistAddDataApi, getWishlistDeleteDataApi, 
	tileRatingColor, saveUrlLogin} from '../../services/index.js';
import { updateStoredPropertyWishlist } from '../../services/helpers.js';

import {trackPageView,trackError} from '../../services/tracker/events.js';
import OfflineStatus from '../../components/Offlinestatus';
import { checkIfOffline } from '../../services/helpers';


class Collections extends React.Component {

	constructor(props) {
		super(props);
		this.getColllectionApiData = this.getColllectionApiData.bind(this);
		this.getColllectionApiDataSuccess = this.getColllectionApiDataSuccess.bind(this);
		this.getColllectionApiDataError = this.getColllectionApiDataError.bind(this);
		this.toggleActiveClass = this.toggleActiveClass.bind(this);
		this.toProperty = this.toProperty.bind(this);

		this.state = {
			toggleOfflineStatus: false
		}
	}

	toggleActiveClass(event, itemIndex, parentIndex) {
		const this_el = this;
		event.preventDefault();
		event.stopPropagation();
		let collectionList = this_el.props.collection.collections;
		let homeCollection = this.props.home.collections;
 		if(hasAccessToken()){
			if (collectionList[event.target.dataset.index].properties[event.target.dataset.id].is_liked_by_user === 1) {
				collectionList[event.target.dataset.index].properties[event.target.dataset.id].is_liked_by_user = 0;
				if(homeCollection.length > 0){
					homeCollection[event.target.dataset.index].properties[event.target.dataset.id].is_liked_by_user = 0;// for update home collection
				}
				event.target.className = 'wishlist-icon';
				this_el.getWishlistDeleteApi(event.target.parentNode.parentNode.id);
				updateStoredPropertyWishlist(event.target.parentNode.parentNode.id, 0);
			} else {
				collectionList[event.target.dataset.index].properties[event.target.dataset.id].is_liked_by_user = 1;
				if (homeCollection.length > 0) {
					homeCollection[event.target.dataset.index].properties[event.target.dataset.id].is_liked_by_user = 1;// for update home collection
				}
				event.target.className = 'wishlist-icon active';
				this_el.getWishlistApi(event.target.parentNode.parentNode.id);
				updateStoredPropertyWishlist(event.target.parentNode.parentNode.id, 1);
			}
		}else{
			this_el.props.updateCollectionState({
				itemIndex: itemIndex,
				parentIndex: parentIndex,
			})

			saveUrlLogin();
			sessionStorage.setItem('hashId',event.target.parentNode.parentNode.id);
			this_el.props.history.push("/signup");
		}
	}


	imageLoaded(e){
	    let imgSrc = e.target.src;
	    e.target.nextElementSibling.style.backgroundImage = 'url('+imgSrc+')';
	}


	getWishlistApi(data){
		getWishlistAddDataApi(data).then(function(response){
			// success
		}).catch(function(error){
			// error
		});
	}
	reload = () =>{
		const this_el = this;
		/**
		 * Calling Api
		 */
		this_el.submitParams();
		/**
		 * Active scroll listner
		 */
		this_el.loadMoreResults();
	}
	getWishlistDeleteApi(data){
		getWishlistDeleteDataApi(data).then(function(response){
			// success
		}).catch(function(error){
			// error
		});
	}

	getColllectionApiDataSuccess(response) {
		const this_el = this;
		let flag = Object.assign({}, this_el.props.collection.flag);
		flag.no_more_result = (response.data.data.collections.length < 5); // if no more result flag found, set no_more_result to true
		flag.api.is_loading = false;

		let collections = Object.assign([], this_el.props.collection.collections);
		Array.prototype.push.apply(collections, response.data.data.collections); 

		this.props.updateCollectionState({
			'collections' : collections,
			'meta': response.data.data.meta,
			'flag' : flag,
			dataLoaded: true
		}).then(()=>{
			let error_flag = Object.assign({}, this_el.props.error.flag);
			error_flag.network = false;
			error_flag.server = false;
			this_el.props.updateErrorState({
				flag: error_flag
			});
		})
	}

	toggleStatus = () => {
		this.setState({
			toggleOfflineStatus: !this.state.toggleOfflineStatus
		}, () => {
			this.setState({
				toggleOfflineStatus: !this.state.toggleOfflineStatus
			})
		})
	}

	getColllectionApiDataError(error) {

		const this_el = this;
		if(typeof error.response !== 'undefined' && error.response.status === 1000){
			if (this.props.error.flag.network) {
				this.toggleStatus()
			}
			let flag = Object.assign({}, this_el.props.error.flag);
			flag.network = true;
			this_el.props.updateErrorState({
				flag : flag
			});
		}else if(error.response.status === 500){
			//console.log('dgdg',error);
			let flag =  this.props.error.flag;
			flag.server = true; 
			this.props.updateErrorState({ flag: flag });
			
		}else{
			trackError(error);
		}
	}

	getColllectionApiData(args){
		let this_el = this;
		getCollectionPageDataApi(args).then(function(response){ 
			// success
			this_el.getColllectionApiDataSuccess(response);
		})
		.catch(function(error) {
			// error
			this_el.getColllectionApiDataError(error);
		});
	}

	componentDidUpdate(prevProps, prevState) {
		const this_el = this;
		let myDiv = this_el.refs.myDiv;
		if (myDiv !== undefined && this_el.props.collection.element_height !== myDiv.clientHeight ){
			this.props.updateCollectionState({ element_height: myDiv.clientHeight });
	  	}
	}

	componentWillMount() {

		/**
		 * Calling Api
		 */
		if (!this.props.collection.dataLoaded){
			this.submitParams();
		}

		/**
		 * Active scroll listner
		 */
		this.loadMoreResults();
	}

	loadMoreResults(){
		const this_el = this;
		
		window.onscroll = function(){
			let collection =false;
            if (document.getElementById('collection') !== null){
                collection = true;
            }else {
                collection = false;
			}
			if(collection){
			//let offset = document.documentElement.scrollTop;
			const scrollTopOffset = Math.max(window.pageYOffset, document.documentElement.scrollTop, document.body.scrollTop);

			let windowHeight = window.innerHeight;
			let bodyHeight = document.body.clientHeight-1060;// should be 260
			let offsetWindowHeight = windowHeight + scrollTopOffset;

			if ((offsetWindowHeight >= bodyHeight) && !this_el.props.collection.flag.api.is_loading && !this_el.props.collection.flag.no_more_result && !checkIfOffline()){

				/**
				 * Updating api state to true, so in case of scroll no more api hit called, untill last api result popup
				 */

				let new_offset = this_el.props.collection.offset + 5;
				let flag = Object.assign({}, this_el.props.collection.flag);

				flag.api.is_loading = true;

				this_el.props.updateCollectionState({
					offset : new_offset,
					flag : flag
				}).then(() => {
					this_el.submitParams();
				});

				}
				if ((offsetWindowHeight >= bodyHeight) && checkIfOffline()) {
					this_el.toggleStatus()
				}
			}
		}
	}

	submitParams(){

		const this_el = this;
		let args = {
			offset:this_el.props.collection.offset,
			loader: true,
		}
		this.getColllectionApiData(args);
	}

	toCollectionList(data, itemIndex, parentIndex){
		const this_el= this;

		this_el.props.updateCollectionState({
			itemIndex: itemIndex,
			parentIndex: parentIndex,
		})

		let single_collection = Object.assign({}, this_el.props.single_collection);
		single_collection.id = data.collection_id; 
		single_collection.hash_id = data.collection_hash_id; 
		single_collection.image = data.collection_image; 
		single_collection.title = data.collection_title; 
		single_collection.description = data.description; 
		single_collection.properties = data.properties;
		single_collection.offset = 0;
		single_collection.flag.no_more_result = false;

		this_el.props.updateSingleCollectionState(single_collection).then(() =>{
			this.props.history.push({
				pathname: '/collection/'+ single_collection.hash_id,
			});
		});
	}

	toProperty(data, itemIndex, parentIndex) {
		const this_el = this;
		this_el.props.updateCollectionState({
			itemIndex:itemIndex,
			parentIndex:parentIndex,
		})
		if (data !== undefined) {
            this_el.props.updatePropertyState({
                'property_hash_id': data.property_hash_id,
                'property_images': data.property_images,
                'property_id': data.property_id,
                'property_title': data.property_title,
                'title': data.title,
                'property_score': data.property_score,
                'host_image': data.host_image,
                'host_name': data.host_name,
                'apiData': false,
                'tags': data.property_tags,
                'property_type': data.property_type_name,
            })
            this_el.props.updateBookingDataState({
				'location': data.location.location_name,
				'lat':  data.location.latitude,
				'lng': data.location.longitude,
				checkin_date: 1,
				checkout_date: 1,
				guest_count: 0,
				unit_count: 0

            })
        }

        this_el.props.history.push({
            pathname: '/property/' + data.property_hash_id
        });

    }
	
	componentDidMount(){
		const this_el = this;
		trackPageView(this_el.props.tracker, this_el.props.collection.page);
		window.scrollTo(0, 0);

		if (checkIfOffline()) {
			this.props.updateErrorState({ flag: { network: true } });
			this.toggleStatus()
		} else {
			this.props.updateErrorState({ flag: { network: false } });
		}

		if (hasAccessToken()) {
			let storedHashId = sessionStorage.getItem('hashId');
			if(storedHashId !== null && typeof this_el.refs[storedHashId] !== 'undefined'){
				this_el.getWishlistApi(storedHashId);
				this_el.refs[storedHashId].classList.add('active');
				sessionStorage.removeItem('hashId');
			}
			
			let wishlistForCollection = sessionStorage.getItem('wishlistForCollection');
			if (wishlistForCollection !== null && typeof this_el.refs[wishlistForCollection] !== 'undefined') {
				this_el.getWishlistApi(wishlistForCollection);
				this_el.refs[wishlistForCollection].classList.add('active');
				sessionStorage.removeItem('wishlistForCollection');
			}
		}
	}

	render() {
		const this_el = this;
		const ellipsis = {
			 textOverflow: 'ellipsis',
			 overflow:'hidden',
			 WebkitBoxOrient: 'vertical'
		}
		const params = {
			slidesPerView: 1.24,
			spaceBetween: 20,
			freeMode: true,
			lazy:true,
			touchMoveStopPropagation: false,
			autoHeight: true,
			slidesOffsetBefore: 15,
			slidesOffsetAfter: 15,
			initialSlide:0,
			noSwiping:false,
			on: {
			slideChange: () => {
					this_el.props.updateCollectionState({
						itemIndex:0,
						parentIndex:0,
					})
				}
			}

		}

		const meta = {
		  title: this_el.props.collection.meta.meta_title,
		  description: this_el.props.collection.meta.meta_desc,
		  canonical: this_el.props.collection.meta.canonical_url,
		  meta: {
			charset: 'utf-8',
			 property:{
				'og:site_name':'Guesthouser',
				'og:title':this_el.props.collection.meta.meta_title,
				'og:url':this_el.props.collection.meta.canonical_url,
				'og:type':'mobile web',
				'og:description':this_el.props.collection.meta.meta_desc,
				'og:image':'https://www.guesthouser.com/images/logo/logo_share.png',
			},
			name: {
			  keywords: this_el.props.collection.meta.keyword
			}
		  }
		};

		return (
			<DocumentMeta {...meta}>
				<div className="collection-container" id="collection">
				
					<header className="header active">
						<div className="row">
							<div className="col-12 text-left">
								<span className="back-icon" onClick={() => this.props.history.goBack()}></span>
								<span className="middle-text text-center">Collections</span>
							</div>                                                      
						</div>
					</header>
					{!this_el.props.error.flag.network || this.props.collection.dataLoaded || this.props.home.dataLoaded ?
						<div>
							{!this.props.error.flag.server ?
							<div>
							<OfflineStatus
								offline={checkIfOffline() && !this.state.toggleOfflineStatus}
								bottom
								dontClear
							/>
							<div className="collection-inner-section margin-bottom">
								{this_el.props.collection.collections.map(function(item, index){
									if(index === this_el.props.collection.parentIndex){
										params.initialSlide = this_el.props.collection.itemIndex
									}else{
										params.initialSlide = 0

									}
									let propertie_length = item.properties.length
								return(
									<div key={index}>
									<div className="header-wraper">
										<div className="col-9">
											<span className="title" style={ellipsis}>{item.collection_title}</span>
										</div>
										{propertie_length > 5 &&
										<div className="col-3 text-right">
											<span className="seeall" onClick={() => this_el.toCollectionList(item, 0 ,index)}>See all</span>
										</div>
										}
									</div>
									{item.properties.length === 1
										? params.noSwiping = true
										: params.noSwiping = false
									}
									<Swiper {...params} key={index}>
										{/* <div className="mask" style={{ backgroundImage: 'url(' + item.collection_image + ')', height: this_el.props.collection.element_height }} data-index={index}>
											<div className="innermask"><div className="text">{item.collection_title}</div></div>
										</div> */}
										{item.properties.map(function(itemchild, indexchild){
											if (indexchild < 5) {
												return(
													<div className="collection-item-tile" id={itemchild.property_hash_id} key={indexchild} ref="myDiv" data-pindex={index} data-index={indexchild} onClick={() => this_el.toProperty(itemchild, indexchild, index)}>
														<img data-src={itemchild.property_images[0].image} onLoad={(e) => this_el.imageLoaded(e)} className='hidden swiper-lazy' alt='property'/>
														<div className="imgb">
															<span data-name="wishlist" ref={itemchild.property_hash_id} data-index={index} data-id={indexchild} className={itemchild.is_liked_by_user === 1 ? 'wishlist-icon active' : 'wishlist-icon'} onClick={(e) => this_el.toggleActiveClass(e, indexchild, index)}></span>
														</div>
														<div className="description">
															<div className="row">
																<div className="col-10">
																	<h3>{itemchild.property_type_name} <div className="dot-separation"></div>{itemchild.accomodation} Guests</h3>
																	<span className="location">
																		{itemchild.location.location_name}
																	</span>
																	<span className="price">{itemchild.prices.price_after_discount}</span>
																</div>
																<div className="col-2">
																	{itemchild.property_score > 0 && <span className={"rating pull-right " + tileRatingColor(itemchild.property_score)}>{itemchild.property_score}</span>}
																</div>
															</div>
														</div>
													</div>
												)
											}else{
												return null
											}
										})}
										{propertie_length > 5 &&
												<div className="collection-item-tile see-all-tile" data-index={index} onClick={() => this_el.toCollectionList(item, 5, index)}><span>See all ></span></div>
										}
										</Swiper>
									</div>
									)
								})}
								
							</div>
							{!this_el.props.collection.flag.no_more_result && this_el.props.collection.flag.api.is_loading ?
									<div className={'loader'}>
										<div className="timeline-item">
											<div className="animated-background facebook"></div>
										</div>
									</div>
									:
									<p className='text-center padding-top'>{!checkIfOffline() && 'No more result!'}</p>
							}
					</div>
					:
						<div className="info-window">
							<div className="window-container">
								<div className="imgb">
									<div className="img" style={{ backgroundImage: "url(" + serverError + ")" }}/>
								</div>
								<div className="txtb">
									<p>Sorry, our servers are busy. Please try again in a bit.</p>
								</div>
								<button className="retry" onClick={this.reload}>Retry</button>
							</div>
						</div>
					}
					</div>
					:
						<div className="info-window">
							<div className="window-container">
								<div className="imgb">
									<div className="img" style={{backgroundImage:'url(' + noInternet + ')'}}></div>
								</div>
								<div className="txtb"><p>Oops! You don't seem to be connected to the internet.</p></div>
								<button className="retry" onClick={this.reload}>Retry</button>
							</div>
						</div>
					}
				</div>
			</DocumentMeta>
		);
	}
}
const mapStateToProps = (store) => {
	return {
		collection : store.collection,
		tracker : store.tracker.tracker,
		single_collection : store.single_collection,
		error: store.error,
		home: store.home
	}
}
const mapDispatchToProps = {
	updateCollectionState,
	updateSingleCollectionState,
	updateErrorState,
	updatePropertyState,
	updateBookingDataState
}
export default connect(mapStateToProps, mapDispatchToProps)(withRouter(Collections));