// actions
import { ERROR_UPDATE_STATE } from "../actions/error/vars";

// initial State
const initialState = {
    'message' : '',
    'flag' : {
        'network': false,
        'show':false,
        'status':false,
        'server':false,
        'message':'',
    }
}



// action functions
const fnc = (state = initialState, action) => {
    switch (action.type) {
        case ERROR_UPDATE_STATE:
            return Object.assign({}, state, action.payload);

        default:
            return Object.assign({}, state);
    }
};
export default fnc;
