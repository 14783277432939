import React from 'react';
import {Link} from 'react-router-dom';
import Currency from '../../components/modal/currency/currency.js';
import CurrencyChangedModal from '../../components/modal/currency/Currencymmodal.js';
import ChangePassword from '../../components/modal/setting/changePassword.js';
import PasswordChanged from '../../components/modal/setting/passwordChangedsuccess.js';
import LogOutModal from '../../components/modal/setting/logoutModal.js';
import VarifyEmailModal from "../../components/modal/editProfile/varifyEmail.js";
import UpdateEmail from '../../components/modal/setting/updateEmail.js';
import DeactivateAccount from '../../components/modal/setting/deactivate.js';
import {logout,getLogoutApi,saveUrlLogin, hasAccessToken, getCurrencyChangeApi} from '../../services/index.js';
import TermCondition from '../../components/modal/term/term.js';
import PrivacyPolicy from '../../components/modal/term/policy.js';
import ContentyPolicy from '../../components/modal/term/content.js';
import {trackPageView} from '../../services/tracker/events.js';
import {connect} from 'react-redux';
import { updateUserStateObj} from '../../actions/setting/actions';
import {updateSignupStateObj} from './../../actions/signup/actions';
import { updateHomeState, clearReduxState } from "./../../actions/home/actions";
import { checkIfOffline } from '../../services/helpers.js';
import OfflineStatus from '../../components/Offlinestatus/index.js';
import { updateTripState } from '../../actions/trip/actions';
import { updateRequestState } from '../../actions/request/actions';

class EditProfile extends React.Component {
	constructor(props){
		super(props);
        this.page="settings";
		this.logOut = this.logOut.bind(this);
		this.getLogoutApiDataError = this.getLogoutApiDataError.bind(this);
        this.getLogoutApiDataSuccess = this.getLogoutApiDataSuccess.bind(this);
        this.logoutDetail=this.logoutDetail.bind(this);
        this.currencyModalOpen = this.currencyModalOpen.bind(this);
        this.saveCurrency=this.saveCurrency.bind(this)
        this.submitCurrencyDetail=this.submitCurrencyDetail.bind(this);
        this.getCurrencyApiDataError=this.getCurrencyApiDataError.bind(this);
        this.getCurrencyApiDataSuccess=this.getCurrencyApiDataSuccess.bind(this);
        this.CurrencyChangedClose=this.CurrencyChangedClose.bind(this);
        this.CurrencyChangedModalOpen=this.CurrencyChangedModalOpen.bind(this);
        this.changePasswordOpen=this.changePasswordOpen.bind(this);
        this.deactivateModalOpen=this.deactivateModalOpen.bind(this);
        this.goBack = this.goBack.bind(this);
        this.opentermCondition = this.opentermCondition.bind(this);
        this.openprivacyPolicy = this.openprivacyPolicy.bind(this);
        this.openContentPolicy = this.openContentPolicy.bind(this);
        this.modalRouting=this.modalRouting.bind(this);
        this.updateEmailModalOpen=this.updateEmailModalOpen.bind(this)
        this.changePasswordClose=this.changePasswordClose.bind(this);
        this.passwordChangedModalClose=this.passwordChangedModalClose.bind(this);
        this.passwordChangedModalOpen=this.passwordChangedModalOpen.bind(this);
        this.VarifyEmailModalOpen=this.VarifyEmailModalOpen.bind(this);
        this.VarifyEmailModalClose=this.VarifyEmailModalClose.bind(this);
        this.goBackAccount = this.goBackAccount.bind(this);

        this.state = {
            toggleOfflineStatus: false
        }

        props.updateUserStateObj({logoutModal: false});
    }

	getLogoutApiDataError(error) {
        this.props.updateUserStateObj({
            'message': error.response.data.message,
            'status': 'error',
        });
    }

    getLogoutApiDataSuccess(response) {
        this.props.updateUserStateObj({
            'message': 'Success',
            'status': 'success'
        });
        logout();
        this.props.clearReduxState()
        this.props.updateHomeState({dataLoaded: false}); // to hit home api after logout
        this.props.updateTripState({}, 1); // refresh trip store for new user
        this.props.updateRequestState({}, 1); // refresh request store for new user


        // unwishlist all property from store
        let storedData = JSON.parse(sessionStorage.getItem('storedPropertyList'));
        if (storedData !== null) {
            storedData.map((item, index) => {
                item.data.data.is_wishlisted = 0
            })
        }
        sessionStorage.setItem('storedPropertyList', JSON.stringify(storedData));
        //end unwishlist all property from store

        this.props.history.replace('/account');

    }

    logoutDetail(args){
        let this_el = this;
        getLogoutApi(args).then(function(response){
            // success
            this_el.getLogoutApiDataSuccess(response);
        }).catch(function(error){
            // error
            this_el.getLogoutApiDataError(error);
        });
    }

	logOut(){
        if (checkIfOffline()) {
            this.toggleStatus()
        } else {
            this.logoutDetail();
        }
	}

	componentDidMount() {
        window.scrollTo(0, 0);

        let currencyModal = sessionStorage.getItem('currencyModal')
        if(currencyModal){
            if(hasAccessToken()){
                this.currencyModalOpen();   
                sessionStorage.removeItem('currencyModal');
            }
        }
         // on url change fuction
        let this_el = this;
        window.onpopstate = function(e) {
            this_el.modalRouting(e.currentTarget.location.hash);
        };

        // fuction to check if any modal open
        this.modalRouting(this.props.history.location.hash);

        trackPageView(this.props.tracker, this.page);
    }

    saveCurrency(currency){
        this.submitCurrencyDetail({
            'currency': currency,
        });
    }
    
    submitCurrencyDetail(args){
        let this_el = this;
        getCurrencyChangeApi(args).then(function(response){
            // success
            this_el.getCurrencyApiDataSuccess(response);
        }).catch(function(error){
            // error
            this_el.getCurrencyApiDataError(error);
        });
    }
    getCurrencyApiDataError(error) {
        console.log(error);
    }

    getCurrencyApiDataSuccess(response) {
        this.props.updateUserStateObj({
            'CurrencyChangedModal': true,
            'currency':response.data.data.currency,
        });
    }
    CurrencyChangedModalOpen(){
        this.props.updateUserStateObj({
            'CurrencyChangedModal': true,
        });
    }
    CurrencyChangedClose(){
        this.props.updateUserStateObj({
            'CurrencyChangedModal': false,
        });
    }
    passwordChangedModalOpen(){
        this.props.updateUserStateObj({
            passwordChangedsuccess:true,
            changePasswordModal:false,
        })
    }
    passwordChangedModalClose(){
        this.props.updateUserStateObj({
            passwordChangedsuccess:false,
        })
        this.goBack();
    }
    logOutModalOpen(){
        window.location.hash = 'logoutModal'
        //this.props.updateUserStateObj({logoutModal: true});
    }

    modalRouting(hash){
        let flag =this.props.signup.flag
        switch(hash){
            case '#termConditionModal':
                flag.termConditionModal=true
                this.props.updateSignupStateObj({termConditionModal: true});
                break;
            case '#privacyPolicyModal':
                flag.privacyPolicyModal=true
                this.props.updateSignupStateObj({privacyPolicyModal: true});
                break;
            case '#contentPolicyModal':
                this.props.updateUserStateObj({contentPolicyModal: true});
                break;
            case '#currencyModal':
                this.props.updateUserStateObj({currencyModal: true});
                break;
            case '#changePasswordModal':
                this.props.updateUserStateObj({changePasswordModal: true});
                break;
            case '#deleteAccount':
                this.props.updateUserStateObj({deleteAccount: true});
                break;
            case '#updateEmail':
                this.props.updateUserStateObj({updateEmail: true});
                break;
            case '#logoutModal':
                this.props.updateUserStateObj({logoutModal: true});
                break;
            default:
                flag.termConditionModal=false;
                flag.privacyPolicyModal=false;
                this.props.updateUserStateObj({
                    flag:flag,
                    contentPolicyModal: false,
                    currencyModal: false,
                    changePasswordModal:false,
                    deleteAccount:false,
                    updateEmail: false,
                    logoutModal:false
                });
        }
    }
    VarifyEmailModalOpen(emailMsg){
        //console.log(emailMsg)
        this.props.updateUserStateObj({
            VarifyEmail: true,
            updateEmail:false,
            email:emailMsg,
        });

    }

	toggleStatus = () => {
		this.setState({
			toggleOfflineStatus: !this.state.toggleOfflineStatus
		}, () => {
			this.setState({
				toggleOfflineStatus: !this.state.toggleOfflineStatus
			})
		})
	}

    VarifyEmailModalClose(){
        this.props.updateUserStateObj({VarifyEmail: false});
        this.goBack();
    }

    deactivateModalOpen(){
        if (checkIfOffline()) {
            this.toggleStatus()
        } else {
            window.location.hash = 'deleteAccount'
        }
    }
    changePasswordOpen(){
        if (checkIfOffline()) {
            this.toggleStatus()
        } else {
            window.location.hash = 'changePasswordModal'
        }
    }
    changePasswordClose(){
        this.props.updateUserStateObj({
            changePasswordModal:false,
        })
    }
    currencyModalOpen() {
        if (checkIfOffline()) {
            this.toggleStatus()
        } else {
            window.location.hash = 'currencyModal'
        }
    }
    opentermCondition() {
        if (checkIfOffline()) {
            this.toggleStatus()
        } else {
            window.location.hash = 'termConditionModal'
        }
    }

    openprivacyPolicy() {
        if (checkIfOffline()) {
            this.toggleStatus()
        } else {
            window.location.hash = 'privacyPolicyModal'
        }
    }

    openContentPolicy() {
        if (checkIfOffline()) {
            this.toggleStatus()
        } else {
            window.location.hash = 'contentPolicyModal'
        }
    }
    onLogin(){
        saveUrlLogin();
        sessionStorage.setItem('currencyModal',true);
    }
    updateEmailModalOpen(){
        if (checkIfOffline()) {
            this.toggleStatus()
        } else {
            window.location.hash = 'updateEmail'
        }
    }
    goBack(){
        this.props.history.goBack();
    }
    goBackAccount(){
        this.props.history.push('/account');
    }
    
    render() {
        return (
		      <div className="setting">
		      		<header className="header active">
		      			<div className="row">
							<div className="col-12 text-left">
								<span className="back-icon" onClick={() => this.props.history.push('/account')}></span>
								<span className="middle-text text-center">Settings</span>
							</div>														
						</div>
		      		</header>
		      		<div className="outer-box">
                      <OfflineStatus
                        offline={checkIfOffline() && !this.state.toggleOfflineStatus}
                        bottom
                        highest
                      />
                      {hasAccessToken()
                        ?
		      			<div className="inner-box">
		      				<div className="heading">Account</div>
		      				<ul className="list">
                                <span>
		      					<li onClick={this.changePasswordOpen}>Change password <span className="icons p-arrow-gray pull-right"></span></li>
		      					<li onClick={this.updateEmailModalOpen}>Update email address <span className="icons p-arrow-gray pull-right"></span></li>
		      					<li><Link to="/user/contact">Update contact details <span className="icons p-arrow-gray pull-right"></span></Link></li>
                                <li onClick={this.currencyModalOpen}>Currency preference <span className="icons p-arrow-gray pull-right"></span></li>
		      					<li onClick={this.deactivateModalOpen}>Deactivate account <span className="icons p-arrow-gray pull-right"></span></li>
                                </span>
                                 {/* <li><Link to="/signup" onClick={this.onLogin}>Currency preference <span className="icons p-arrow-gray pull-right"></span></Link></li> */}
		      				</ul>
		      			</div>
                            :
                            ''
                        }
		      			<div className="inner-box">
		      				<div className="heading">About </div>
		      				<ul className="list">
		      					<li onClick={this.opentermCondition}>Terms & conditions <span className="icons p-arrow-gray pull-right"></span></li>
		      					<li onClick={this.openprivacyPolicy}>Privacy policy <span className="icons p-arrow-gray pull-right"></span></li>
		      					<li onClick={this.openContentPolicy}>Content policy <span className="icons p-arrow-gray pull-right"></span></li>
		      					{hasAccessToken()
		      						?
		      					   <li onClick={this.logOutModalOpen}>Logout <span className="icons p-arrow-gray pull-right"></span></li>
		      					   :
		      					   ''
		      					}
		      				</ul>
		      			</div>
		      		</div>
                <Currency
                    currencyModalOpen={this.props.user.currencyModal} 
                    currency={this.props.user.currency} 
                    saveCurrency={this.saveCurrency} 
                    goBack={this.goBack}/>
                <CurrencyChangedModal 
                    CurrencyChangedModalOpen={this.props.user.CurrencyChangedModal}  
                    CurrencyChangedClose={this.CurrencyChangedClose}
                    />
                <ChangePassword 
                    changePasswordOpen={this.props.user.changePasswordModal} 
                    passwordChangedModalOpen={this.passwordChangedModalOpen} 
                    hash={this.props.history.hash}
                    goBack={this.goBack}
                    toggleStatus={this.toggleStatus}
                    />
                <DeactivateAccount 
                    deactivateModalOpen={this.props.user.deleteAccount}  
                    goBackAccount={this.goBackAccount}
                    goBack={this.goBack}
                    toggleStatus={this.toggleStatus}
                    />
                <LogOutModal
                    logOutModalOpen={this.props.user.logoutModal}
                    goBack={this.goBack}
                    logOut={this.logOut}
                />
                <TermCondition/>
                <PrivacyPolicy/>
                <ContentyPolicy/>
                <UpdateEmail
                    updateEmailModalOpen={this.props.user.updateEmail}
                    updateEmailModalClose={this.updateEmailModalClose}
                    VarifyEmailModalOpen={this.VarifyEmailModalOpen}
                    goBack={this.goBack}
                    toggleStatus={this.toggleStatus}
                />
                <PasswordChanged
                    passwordChangedModalOpen={this.props.user.passwordChangedsuccess}
                    goBack={this.passwordChangedModalClose}
                />
                <VarifyEmailModal
                    VarifyEmailModalOpen={this.props.user.VarifyEmail}
                    email={this.props.user.email}
                    goBack={this.VarifyEmailModalClose}
                />
              </div>	
        );
    }
}
const mapStateToProps = (store) =>{
     return{
        user : store.UserChangeData,
        signup : store.signupEmail,
        tracker: store.tracker.tracker
    }
}
const mapDispatchToProps = {
    updateUserStateObj,
    updateSignupStateObj,
    updateHomeState,
    clearReduxState,
    updateRequestState,
    updateTripState
}
export default connect(mapStateToProps, mapDispatchToProps)  (EditProfile);