import React from 'react';
import Modal from 'react-modal';
import InfiniteCalendar from 'react-infinite-calendar';
import 'react-infinite-calendar/styles.css'; // only needs to be imported once
import { connect } from 'react-redux';
import { withRouter } from 'react-router';
import  { removeClassToBody } from '../../services';

class PricingCalendar extends React.Component {
    constructor(props){
        super(props);
        this.goBack = this.goBack.bind(this);
    }

    goBack(){
        this.props.history.goBack();
    }

    componentWillUnmount(){
        removeClassToBody();
    }

    render(){
        return(
            <div className="overlay-modal">
                <div className="content-modal full-screen-modal date-range-calendar pricingCalendar property_preview_calendar" ref="calendarContainerModel">
                    <div className="modal-header">
                        <span className="close-btn" onClick={() => this.goBack()}>Close</span>
                        <span className="reset-btn">{this.props.default_prices.currency_plus_symbol}</span>
                    </div>
                    <div className="modal-body" ref="calendarContainer">
                        <InfiniteCalendar 
                            width={Math.min(window.innerWidth, 400)}
                            rowHeight={50}
                            min={this.props.min_date}
                            max={this.props.max_date}
                            minDate={this.props.min_date}
                            maxDate={this.props.max_date}
                            locale={{
                                default_disable_class: ' not_available_disable ',
                                weekdays: ['Su', 'Mo', 'Tu', 'We', 'Th', 'Fr', 'Sa'],
                                pricing:  this.props.calendar_prices !== undefined ?
                                    this.props.calendar_prices : '',
                                defaultPricing : this.props.default_prices !== undefined ?
                                    this.props.default_prices : '',
                                currency:'',
                                month_format: 'MMMM',
                                year_format: 'YYYY'
                            }}
                            displayOptions={{
                                showOverlay:false,
                                showTodayHelper:false,
                                overscanMonthCount:12,
                                hideYearsOnSelect:false
                            }}

                        />
                    </div>
                </div>
            </div>
        )
    }
}

const mapStateToProps = (store) => {
    return {
        pricingCalendar : store.property.flag.modal.pricingCalendar,
        min_date: store.search.min_date,
        max_date: store.search.max_date,
        calendar_prices : store.property.calendar_prices,
        default_prices : store.property.default_prices,
    }
}

export default connect(mapStateToProps, null)(withRouter(PricingCalendar));
