import React from 'react';
import {Link} from 'react-router-dom';
import ResponseMessage from '../../components/response/';
import GoogleLogin from 'react-google-login';
import FacebookLogin from 'react-facebook-login';
// services
import {getLoginApi, login,SignupLoginSocial,hasAccessToken} from '../../services/index.js';

import {trackPageView, trackLoginSignup} from '../../services/tracker/events.js';
import {connect} from 'react-redux';
/**
 * Actions
 */
import {updateLoginStateObj} from './../../actions/login/actions';
import {updateSignupStateObj} from './../../actions/signup/actions';
import { updateHomeState } from "./../../actions/home/actions";

var Form = require('react-formal');
var yup = require('yup');

var userLoginSchema = yup.object({ 
    userId: yup.string().nullable().required(' User Id required').matches(/^([a-zA-Z0-9_\-\.]+)@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.)|(([a-zA-Z0-9\-]+\.)+))([a-zA-Z]{2,4}|[0-9]{1,3})(\]?)$|([0-9]{10})+$/, 'invalid email or phone number'),
    password: yup.string().nullable().required('Please enter a password').min(6,'minimum length 6 character'),  
});


class LoginEmail extends React.Component {
    constructor(props) {
        super(props);
        this.page="login";
        this.state = {
            current_url : window.location.href,
        }
        
        this.loginDetail = this.loginDetail.bind(this);
        this.showErrorMSg = this.showErrorMSg.bind(this);
        this.getLoginApiDataError = this.getLoginApiDataError.bind(this);
        this.getLoginApiDataSuccess = this.getLoginApiDataSuccess.bind(this);
        this.handleSubmit = this.handleSubmit.bind(this);
        this.resetMessages = this.resetMessages.bind(this);
        this.onBlurShowMessage=this.onBlurShowMessage.bind(this);
        this.onFocusHideMessage=this.onFocusHideMessage.bind(this);
        this.formErrorHandle = this.formErrorHandle.bind(this);
        this.signupCall = this.signupCall.bind(this);
        this.apiDataSuccess = this.apiDataSuccess.bind(this);
        this.apiDataErrors = this.apiDataErrors.bind(this);
        this.keyUpFunction = this.keyUpFunction.bind(this)

        if (hasAccessToken()) {
            if (props.signup.afterLogin.length > 0) {
                this.props.history.replace(props.signup.afterLogin)
           } else {
                this.props.history.replace('/')
           }
        }
    }

    componentDidUpdate () {
        if (hasAccessToken()) {
            this.props.history.replace('/')
        }
    }

    formErrorHandle(errors){

        //console.log(errors);
        for (var property in errors) {
            if (errors.hasOwnProperty(property) && errors[property] !== undefined && errors[property].length > 1) {
                errors[property] = errors[property][0]['message'];
                //console.log("\n\n\n\n", property, errors[property],errors[property].length);
            }
        }
        // errors.forEach((error, index) => {
        //   console.log("\n "+index + "  : ",error);
        // });
    }
    showErrorMSg(){
        this.props.updateLoginStateObj({'errorMSg': true});
    }
    resetMessages(){
        this.props.updateLoginStateObj({
            'message':'',
            'show':false,
        });
    }

    componentDidMount(){
        //console.log(this.props.login.body_bg_color, 'mount');
        window.scrollTo(0, 0);
        document.body.style.backgroundColor = this.props.login.body_bg_color;
        document.body.setAttribute('data-color', this.constructor.name);
        trackPageView(this.props.tracker, this.page);
    }
    
    handleSubmit(event) {
        let flag = this.props.signup.flag;
        flag.loading=true;
        this.props.updateSignupStateObj({
            flag:flag
        }).then(() => {
            this.loginDetail({
                'userId': this.props.login.userId,
                'password': this.props.login.password,
            });    
        });
    }
    
    componentWillUnmount(){

        if (document.body.getAttribute('data-color') === this.constructor.name) {
            document.body.style.backgroundColor = null;
        }
    }

   

    getLoginApiDataError(error) {
        let this_el = this;

        this_el.props.updateLoginStateObj({
            'message': error.response.data.message,
            'status': false,
            'show': true
        });
    }
    getLoginApiDataSuccess(response) {
        let this_el = this;
        trackLoginSignup(this.props.tracker, this.page, 'direct', {'user_id':response.data.user_profile.user_id, type:'email', event:response.data.user_profile.event});
        this.props.updateSignupStateObj({
            'add_email':response.data.user_profile.add_email,   
            'first_name':response.data.user_profile.first_name,
            'last_name':response.data.user_profile.last_name,
            'profile_image':response.data.user_profile.profile_image,
            'active_request_count':response.data.user_profile.active_request_count 
        })
        
        this.props.updateLoginStateObj({
            'message': 'Success',
            'status': true,
            'is_user_referred':response.data.user_profile.is_user_referred,
        });
		localStorage.setItem("request_count",(this_el.props.signup.active_request_count === undefined ? 0: this_el.props.signup.active_request_count));
        
        localStorage.setItem("first_name", this.props.signup.first_name);
        localStorage.setItem("last_name", this.props.signup.last_name);
        localStorage.setItem("profile_image", this.props.signup.profile_image);

        localStorage.setItem('addEmail', response.data.user_profile.add_email)
        localStorage.setItem("is_user_referred", this.props.login.is_user_referred);
        
        login(response.data.access_token, response.data.refresh_token);   

        // for hit home api
        this.props.updateHomeState({
            dataLoaded: false,
        });
        // end for hit home api
        let flag = this.props.signup.flag;
        flag.loading=false;
        this_el.props.updateSignupStateObj({flag:flag});
        
       //console.log(response)
        if(!response.data.user_profile.is_mobile_verified){
            this.props.history.push('/user/contact');
        }else{
            let urlAfterLogin = this.props.signup.afterLogin;
            this.props.history.replace(urlAfterLogin);
        }
    }

    loginDetail(args){

        let this_el = this;
        getLoginApi(args).then(function(response){
            // success
            //console.log(response);
            this_el.getLoginApiDataSuccess(response)

        }).catch(function(error){
            // error
           // console.log(error)
           let flag = this_el.props.signup.flag;
           flag.loading=false;
           this_el.props.updateSignupStateObj({flag:flag})
            if(error.hasOwnProperty('response') && error.response === undefined){
                let networkError = {
                    response:{
                        data:{
                             message:'Please check your internet connection'
                        }
                    }
                }
               // this_el.setState({status:false});
                this_el.getLoginApiDataError(networkError);
            }else if(error.hasOwnProperty('response') && error.response.status === 500){
                let networkError = {
                    response:{
                        data:{
                             message:'Server Error! please try after sometime'
                        }
                    }
                }
				this_el.getLoginApiDataError(networkError);
            }else{
                this_el.getLoginApiDataError(error);
            }

        });
    }
    changeHandle(model){
        //console.log(model);
       model.errorMSg = false;
       let updatedData = {};
        for (var key in model) {
            if (model.hasOwnProperty(key)) {
                updatedData[key] = model[key];
            }
        }
        this.props.updateLoginStateObj(updatedData);
    }
    onFocusHideMessage(e){

        let field_valid = Object.assign({},this.props.login.field_valid);
        field_valid[e.target.name] = true;
        this.props.updateLoginStateObj({
            field_valid : field_valid
        });

    }
    onBlurShowMessage(e){

        let field_valid = Object.assign({}, this.props.login.field_valid);
        field_valid[e.target.name] = false;
        this.props.updateLoginStateObj({
            field_valid : field_valid
        });
    }
    signupCall(args){
        let this_el = this;
        SignupLoginSocial(args).then(function(response){
            // success
            this_el.apiDataSuccess(response, args);
            
        }).catch(function(error){
            // error
            this_el.apiDataErrors(error);
            
        });

    }


    apiDataSuccess(response, args) {
        console.log(response);
        const this_el = this;
        let flag = this.props.signup.flag;
        flag.loading=false;
        this_el.props.updateSignupStateObj({
            'is_mobile_verified':response.data.data.user_profile.is_mobile_verified,
            'is_user_referred':response.data.data.user_profile.is_user_referred,
            'mobile':response.data.data.user_profile.mobile,
            'add_email':response.data.data.user_profile.add_email,
            'first_name':response.data.data.user_profile.first_name,
            'last_name':response.data.data.user_profile.last_name,
            'profile_image':response.data.data.user_profile.profile_image,
            'active_request_count':response.data.data.user_profile.active_request_count ,
            flag:flag,

        }).then( () => {
            var type="facebook";
            if(args.source === "1"){
                type="google";
            }
		    localStorage.setItem("request_count",(this_el.props.signup.active_request_count === undefined ? 0: this_el.props.signup.active_request_count));

            localStorage.setItem('addEmail', response.data.data.user_profile.add_email)

            trackLoginSignup(this_el.props.tracker, this_el.page, 'direct', {'user_id':response.data.data.user_profile.user_id, 
            type:type, event:response.data.data.user_profile.event});

            localStorage.setItem("is_user_referred", this_el.props.signup.is_user_referred);
            localStorage.setItem("first_name", this_el.props.signup.first_name);
            localStorage.setItem("last_name", this_el.props.signup.last_name);
            localStorage.setItem("profile_image", this_el.props.signup.profile_image);
            localStorage.setItem('contact_number',this_el.props.signup.mobile);
            localStorage.setItem('SkipModal',this_el.props.signup.is_mobile_verified);

            const apiData = response.data;
            try {

                if(apiData.status === true){
                    login(apiData.data.access_token, apiData.data.refresh_token);

                    // for hit home api
                    this.props.updateHomeState({
                        dataLoaded: false,
                    });
                    // end for hit home api

                    let urlAfterLogin = sessionStorage.getItem('UrlAfterLogin');
                     if(this_el.props.signup.is_mobile_verified === 1){
                        let urlAfterLogin = this.props.signup.afterLogin;
                        this.props.history.replace(urlAfterLogin);
                    }else{
                        this_el.props.history.push("/user/contact");
                    }   
                    //this.props.history.push(urlAfterLogin);
                }
            } catch (error) {
                // write your code here to handle catch
            }
        });
    }
    keyUpFunction(e){
        if (e.keyCode === 13) {
            e.target.blur();
        }
    }
    apiDataErrors(error) {
        let flag = this.props.signup.flag;
        flag.show = true;
        flag.status = false;
        flag.loading=false;
        this.props.updateSignupStateObj({
            'message': error.response.data.error[0].message,
            flag:flag
        });
    }
    render() {
        const this_el =this;
        const responseGoogle = (response) => {
            let flag = this.props.signup.flag;
            flag.loading = true;
            this.props.updateSignupStateObj({
                flag:flag
            })
            console.log(response);

            //const this_el=this
            if(typeof response.error === "undefined"){
                this.signupCall({
                'access_token':response.tokenId,
                'source':'1'
            }); 
            }
            else{
                // response.details // holds the error detail
                let flag = this.props.signup.flag;
                flag.show = true;
                flag.loading = false; 
                this.props.updateSignupStateObj({
                    'message': "Not a valid origin" ,
                    'status': 'error',
                    flag:flag
                });
            }
        }

        const responseFacebook = (response) => { 
            let flag = this.props.signup.flag;
            flag.loading = true;  
            this.props.updateSignupStateObj({
                flag:flag
            })
            //console.log("Facebook Login Response \n", response);
            if(response.hasOwnProperty("accessToken")){
                this.signupCall({
                    'access_token':response.accessToken,
                    'source':'2',
                });
            }
            else{
                let flag = this.props.signup.flag;
                flag.show = true;
                flag.loading = false; 
                this.props.updateSignupStateObj({
                    'message': "Issue with facebook login",
                    'status': 'error',
                    flag:flag   
                });
            }
            
        }
        return (
			<div className="login-signup">
                <div className={this_el.props.signup.flag.loading ? 'loading active' : 'loading '}></div>
				<header className="header">
	      			<div className="row">
						<div className="col-12 text-left">
							<span className="back-btn-grey" onClick={() => this.props.history.goBack()}></span>
							<span className="middle-text text-center">Login</span>
						</div>														
					</div>
              	</header>	

                <div className="login-signup-wrap">
                    <FacebookLogin
                        appId={process.env.REACT_APP_FACEBOOK_CLIENT_ID}
                        fields="name,email,picture"
                        scope="public_profile,user_friends,user_gender,user_birthday,email"
                        cssClass="btn fb link "
                        textButton="Login with Facebook"
                        callback={responseFacebook}
                        onClick={responseFacebook}
                        redirectUri={this.state.current_url}
                    />

                    <GoogleLogin
                        className="btn google link"
                        clientId={process.env.REACT_APP_GOOGLE_CLIENT_ID}
                        autoLoad ={false}
                        onClick = {responseGoogle}
                        onSuccess={responseGoogle}
                        buttonText="Login with Google+"
                        onFailure={responseGoogle}
                        >
                    </GoogleLogin>

                    <div className="row line-wrap">
                        <div className="col-5 line"></div>
                        <div className="col-2 text-center or"></div>
                        <div className="col-5 line"></div>
                    </div>

                    <Form schema={userLoginSchema} 
                    onSubmit={this.handleSubmit} 
                    onChange={model => this.changeHandle(model)}
                    //errors={this.state ? this.props.login.errors : {}}
                    onError={errors => this.formErrorHandle(errors)}
                    >
                        <div>
                        <Form.Field type="text" name="userId" onKeyUp={(e) => this.keyUpFunction(e)} onFocus={(e) =>this.onFocusHideMessage(e)} onBlur={(e) => this.onBlurShowMessage(e)} placeholder="Email/Phone number"/>
                        </div>

                        {!this.props.login.field_valid.userId &&
                            <Form.Message for='userId' />
                        }

                        <div className="password">
                          <Form.Field type="password" name="password" onKeyUp={(e) => this.keyUpFunction(e)} onFocus={(e) =>this.onFocusHideMessage(e)} onBlur={(e) => this.onBlurShowMessage(e)} placeholder="Password"/>
                          <Link to={{ pathname:'/user/password/forgot/' + this.props.login.userId }} className="forgetpassword"><span>Forgot ?</span></Link>
                        </div>
                         {!this.props.login.field_valid.password &&
                            <Form.Message for='password'/>
                        }
                        <Form.Button type='submit' onClick={this.showErrorMSg} className="btn green link">Login</Form.Button>
                    </Form>
                </div>
                <div className={'responseAPI '+this.props.login.show }>
                    <ResponseMessage responseMessage={this.props.login.message}  responseStatus={this.props.login.status} resetMessages={this.resetMessages}/>
                </div>

                </div>
        	

        );
    }
}
const mapStateToProps = (store) => {
	return {
        login : store.loginEmail,
        signup : store.signupEmail,
        tracker: store.tracker.tracker,
        error : store.error
	}
}
const mapDispatchToProps = {
    updateLoginStateObj,
    updateSignupStateObj,
    updateHomeState
}
export default connect(mapStateToProps, mapDispatchToProps) (LoginEmail);