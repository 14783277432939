

let querystring = require('querystring');

/* APIs */
// register device
let registerDevice = function(data, args)
{
    
    return data.AXIOS.post(data.BASE_URL_V+'/device', querystring.stringify({
        'device_unique_id': data.DEVICE_UNIQUE_ID,
        'device_notification_token': data.DEVICE_NOTIFICATION_TOKEN,
        'app_version': '',
        'device_model': '',
        'device_make': '',
        'brand': '',
        'os_version': '',
        'resolution': '',
        'country': '',
        'screen_width': '',
        'screen_height': '',
        'ram': '',
        'dpi': '',
        'app_version_code': '',
    }), {
        responseType: 'json',
        headers: {
            'Accept': 'application/json',
            'Content-Type': 'application/x-www-form-urlencoded',
        },
    });
}

// is device registered
let isDeviceRegistered = function(data, args)
{
    if (args.error.response.status === 400) {
        if (args.error.response.data.error.length > 0 && args.error.response.data.error[0]['key'].indexOf('device-unique-id') > -1) {
              return false;
        }
    } 
    return true;
}

exports.registerDevice = registerDevice;
exports.isDeviceRegistered = isDeviceRegistered;