

/* APIs */
// get signup data
let getEmailVarifySuccessApi = function (data, args)
{
    return data.AXIOS.put(data.BASE_URL_V+'/user/verify/email', data.QS.stringify({
             confirmation_code:args.confirmation_code,
        }), {
            headers: {
                'Accept': 'application/json',
                'Content-Type': 'application/x-www-form-urlencoded',
                'device-unique-id': data.DEVICE_UNIQUE_ID,
            }
        }
    );
}

exports.getEmailVarifySuccessApi = getEmailVarifySuccessApi;