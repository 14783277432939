import React from 'react';
import ReactDOM from 'react-dom';
import {BrowserRouter as Router, Route, Switch, Redirect} from 'react-router-dom';
import {Tracker } from 'react-tracker';
import { Provider } from 'react-redux';
import * as Sentry from '@sentry/browser';
import store from './store';

// components
import BottomTabs from './components/bottomTabs/';
import {getRedirectUrl} from './services/index.js';

// containers (layouts)
import Errorpage from './containers/404/';
import App from './containers/app/';
import Explore from './containers/explore/';
import RecentlyViewed from './containers/explore/recentlyViewed';
import Request from './containers/request/';
import Trips from './containers/trips/';
import Account from './containers/account/';
import TalkToUs from './containers/talkToUs/';
import SearchResult from './containers/searchResult/';
import PropertyPreview from './containers/propertyPreview/';
import Profile from './containers/account/profile.js';
import MyListing from './containers/account/mylisting.js';
import SimilarListingAll from './containers/propertyPreview/similarListing';
import EditProfile from './containers/account/editProfile.js';
import Setting from './containers/account/setting.js';
import Wallet from './containers/menu/wallet.js';
import Collections from './containers/collection/';
import CollectionList from './containers/collection/collectionList.js';
import Wishlist from './containers/menu/wishlist.js';
import Login from './containers/loginSignup/login.js';
import LoginEmail from './containers/loginSignup/loginEmail.js';
import Signup from './containers/loginSignup/signup.js';
import ForgotPassword from './containers/loginSignup/forgotPassword.js';
import ResetPassword from './containers/loginSignup/resetPassword.js';
import PasswordChanged from './containers/loginSignup/passwordChanged.js';
import OTP from './containers/loginSignup/otp.js';
import EmailVarifySuccess from './containers/loginSignup/emailVarifySuccess.js';
import SearchLocationModal from './components/home/searchBarContainer/searchLocation';

import Prepayment from './containers/prepayment/index.js';
import PrepaymentRequest from './containers/prepayment/request.js';
import RequestDetails from './containers/request/request_details.js';
import TripDetails from './containers/trips/trip_details.js';
import Archivedlsit from './containers/request/archived.js';
import PastTripList from './containers/trips/pastTrip.js';
import AllReviews from './containers/propertyPreview/allReviews.js';

// browser caching
import registerServiceWorker from './registerServiceWorker';
import getGtmTrackEventListener from './services/tracker/gtmtrackEventListener.js'

// Preload Assets
import NoInternet from './static/img/infoWindow/noInternet.svg'
import ServerError from './static/img/serverError.png'
import Landscape from './static/img/landscapemode.jpg'
import Placeholder from './static/img/placeholder-gallery.jpg'
import mapFullView from './components/propertyPreview/mapFullView';

let trackListesners=[];
// Gtm Only on Live
if(process.env.REACT_APP_ENVIRONMENT === 'production'){
    trackListesners.push(getGtmTrackEventListener());
}

const tracker = new Tracker(trackListesners);

const RouteWithProps = ({ path, exact, strict, component:Component, location, ...rest }) => (
                  <Route
                    path={path}
                    exact={exact}
                    strict={strict}
                    location={location}
                    render={(props) => <Component {...props} {...rest} />}
                    />
                )




class Entry extends React.Component {
    
    constructor(props){ 
        super(props);
        this.updateActiveTripCount = this.updateActiveTripCount.bind(this);

        let pathname = window.location.pathname;
        let params   = window.location.search;
        let web_url  = getRedirectUrl();
        
        if(this.checkIfMobileDevice() === false) {
            this.websiteRedirectRoute(pathname, params, web_url);
        }

        this.preLoadAssets()
        
        //Loading google maps API
		const script = document.createElement("script");
        script.src = "https://maps.googleapis.com/maps/api/js?key=AIzaSyDukEAc7oyPDkbs6fRnzTGE_iJbNvZz0PM&libraries=places";
        script.async = true;
        document.body.appendChild(script);
    }

    preLoadAssets = () => {
        [ NoInternet, ServerError, Landscape, Placeholder ].forEach(x => {
            const div = document.createElement('div')
            div.style.backgroundImage = `url(${x})`
            document.body.appendChild(div)
        })
    } 

    updateActiveTripCount (){
        this.changeCount()
    }

    checkIfMobileDevice (){
        let isMobile = false; //initiate as false
        // device detection
        if(/(android|bb\d+|meego).+mobile|avantgo|bada\/|blackberry|blazer|compal|elaine|fennec|hiptop|iemobile|ip(hone|od)|ipad|iris|kindle|Android|Silk|lge |maemo|midp|mmp|netfront|opera m(ob|in)i|palm( os)?|phone|p(ixi|re)\/|plucker|pocket|psp|series(4|6)0|symbian|treo|up\.(browser|link)|vodafone|wap|windows (ce|phone)|xda|xiino/i.test(navigator.userAgent) 
    || /1207|6310|6590|3gso|4thp|50[1-6]i|770s|802s|a wa|abac|ac(er|oo|s)|ai(ko|rn)|al(av|ca|co)|amoi|an(ex|ny|yw)|aptu|ar(ch|go)|as(te|us)|attw|au(di|m|r |s )|avan|be(ck|ll|nq)|bi(lb|rd)|bl(ac|az)|br(e|v)w|bumb|bw(n|u)|c55\/|capi|ccwa|cdm|cell|chtm|cldc|cmd|co(mp|nd)|craw|da(it|ll|ng)|dbte|dcs|devi|dica|dmob|do(c|p)o|ds(12|d)|el(49|ai)|em(l2|ul)|er(ic|k0)|esl8|ez([4-7]0|os|wa|ze)|fetc|fly(|_)|g1 u|g560|gene|gf5|gmo|go(\.w|od)|gr(ad|un)|haie|hcit|hd(m|p|t)|hei|hi(pt|ta)|hp( i|ip)|hsc|ht(c(| |_|a|g|p|s|t)|tp)|hu(aw|tc)|i(20|go|ma)|i230|iac( ||\/)|ibro|idea|ig01|ikom|im1k|inno|ipaq|iris|ja(t|v)a|jbro|jemu|jigs|kddi|keji|kgt( |\/)|klon|kpt |kwc|kyo(c|k)|le(no|xi)|lg( g|\/(k|l|u)|50|54|[a-w])|libw|lynx|m1w|m3ga|m50\/|ma(te|ui|xo)|mc(01|21|ca)|mcr|me(rc|ri)|mi(o8|oa|ts)|mmef|mo(01|02|bi|de|do|t(| |o|v)|zz)|mt(50|p1|v )|mwbp|mywa|n10[0-2]|n20[2-3]|n30(0|2)|n50(0|2|5)|n7(0(0|1)|10)|ne((c|m)|on|tf|wf|wg|wt)|nok(6|i)|nzph|o2im|op(ti|wv)|oran|owg1|p800|pan(a|d|t)|pdxg|pg(13|([1-8]|c))|phil|pire|pl(ay|uc)|pn2|po(ck|rt|se)|prox|psio|ptg|qaa|qc(07|12|21|32|60|[2-7]|i)|qtek|r380|r600|raks|rim9|ro(ve|zo)|s55\/|sa(ge|ma|mm|ms|ny|va)|sc(01|h|oo|p)|sdk\/|se(c(|0|1)|47|mc|nd|ri)|sgh|shar|sie(|m)|sk0|sl(45|id)|sm(al|ar|b3|it|t5)|so(ft|ny)|sp(01|h|v|v )|sy(01|mb)|t2(18|50)|t6(00|10|18)|ta(gt|lk)|tcl|tdg|tel(i|m)|tim|tmo|to(pl|sh)|ts(70|m|m3|m5)|tx9|up(\.b|g1|si)|utst|v400|v750|veri|vi(rg|te)|vk(40|5[0-3]|v)|vm40|voda|vulc|vx(52|53|60|61|70|80|81|83|85|98)|w3c(| )|webc|whit|wi(g |nc|nw)|wmlb|wonu|x700|yas|your|zeto|zte/i.test(navigator.userAgent.substr(0,4))) { 
            isMobile = true;
        }

        return isMobile;
    }

    websiteRedirectRoute(pathname, params, web_url) {

        let url;
        web_url = (web_url !== undefined) ? web_url : 'https://www.guesthouser.com';
        if(pathname.includes("offers") || pathname.includes("search") || pathname.includes("stays") || pathname.includes("property") || (!pathname.includes("prepayment/request") && pathname.includes("prepayment")))
            url = web_url+'/search/mobile-redirect'+params;
        else
            url = web_url;
        
        window.location = url;
    }

    render(){
        return (
            <Provider store={store}>
                <Router>
                    <App tracker={tracker}>
                        {
                            prevRoute => (
                                <React.Fragment>
                                    <Switch>
                                        <RouteWithProps exact path='/' prevRoute={prevRoute} component={Explore} updateActiveTripCount={this.updateActiveTripCount} {...this.props} />
                                        <RouteWithProps exact path='/home/*' prevRoute={prevRoute} component={Explore} updateActiveTripCount={this.updateActiveTripCount} {...this.props} />                            
                                        <RouteWithProps exact path='/offers' prevRoute={prevRoute} component={Explore} updateActiveTripCount={this.updateActiveTripCount}  {...this.props} />
                                        <RouteWithProps exact path='/offers/:offer' prevRoute={prevRoute} component={Explore} updateActiveTripCount={this.updateActiveTripCount} {...this.props} />
                                        <RouteWithProps path='/requests' component={Request} updateActiveTripCount={this.updateActiveTripCount} {...this.props} />
                                        <RouteWithProps path='/user/mailverify' component={EmailVarifySuccess} {...this.props} />
                                        <RouteWithProps path='/trips/' component={Trips} {...this.props} />
                                        <RouteWithProps path='/support' component={TalkToUs} {...this.props} />
                                        <RouteWithProps path='/account' component={Account} {...this.props} />

                                        <RouteWithProps path='/search/s' component={SearchResult} prevRoute={prevRoute} {...this.props} />
                                        <RouteWithProps path='/stays/:location' component={SearchResult} prevRoute={prevRoute} {...this.props} />

                                        {/*Correct order of query params - query?location=Goa..*/}
                                        <RouteWithProps path='/property/galleryList/:hashId' component={PropertyPreview} prevRoute={prevRoute}  {...this.props} />
                                        <RouteWithProps path='/property/gallerySlider/:hashId' component={PropertyPreview} prevRoute={prevRoute}  {...this.props} />
                                        <RouteWithProps path='/property/about/:hashId' component={PropertyPreview} prevRoute={prevRoute}  {...this.props} />
                                        <RouteWithProps path='/property/amenities/:hashId' component={PropertyPreview} prevRoute={prevRoute}  {...this.props} />
                                        <RouteWithProps path='/property/pricingCalendar/:hashId' component={PropertyPreview} prevRoute={prevRoute}  {...this.props} />
                                        <RouteWithProps path='/property/mapFullView/:hashId' component={mapFullView} prevRoute={prevRoute}  {...this.props} />
                                        

                                        <RouteWithProps path='/property/:hashId' component={PropertyPreview} prevRoute={prevRoute}  {...this.props} />
                                        {/*<Route path='/gallerylist' component={GalleryList}  {...this.props}/>*/}
                                        <RouteWithProps path='/user/profile' component={Profile}  {...this.props} />
                                        <RouteWithProps path='/user/editProfile' component={EditProfile}  {...this.props} />
                                        <RouteWithProps path='/user/wallet' component={Wallet}  {...this.props} />
                                        <RouteWithProps path='/user/wishlist' component={Wishlist}  {...this.props} />
                                        <RouteWithProps path='/settings' component={Setting}  {...this.props} />
                                        <RouteWithProps path='/collections' component={Collections}  {...this.props} />
                                        <RouteWithProps path='/recentlyViewed' component={RecentlyViewed}  {...this.props} />
                                        <RouteWithProps path='/similarListing/:property_hash_id?' component={SimilarListingAll}  {...this.props} />
                                        <RouteWithProps path='/myListing' component={MyListing}  {...this.props} />
                                        <RouteWithProps path='/collection/:hashId' component={CollectionList}  {...this.props} />
                                        <RouteWithProps path='/user/contact' component={OTP}  {...this.props} />
                                        <RouteWithProps path='/signup' component={Login}  {...this.props} prevRoute={prevRoute} />
                                        <RouteWithProps path='/login/email' component={LoginEmail}  {...this.props} />
                                        <RouteWithProps path='/email/signup' component={Signup}  {...this.props} />
                                        <RouteWithProps path='/user/password/forgot/:userId?' component={ForgotPassword}  {...this.props} />
                                        <RouteWithProps path='/user/password/reset' component={ResetPassword}  {...this.props} />
                                        <RouteWithProps path='/user/password/success' component={PasswordChanged}  {...this.props} />
                                        <RouteWithProps path='/request/:hashId' component={RequestDetails}  {...this.props} />
                                        <RouteWithProps path='/trip/:hashId' component={TripDetails}  {...this.props} />
                                        <RouteWithProps path='/prepayment/:hashId' component={Prepayment} exact  {...this.props} />
                                        <RouteWithProps path='/prepayment/request/:hashId' exact component={PrepaymentRequest}  {...this.props} />
                                        <RouteWithProps path='/archived/list' component={Archivedlsit}  {...this.props} />
                                        <RouteWithProps path='/past/list' component={PastTripList}  {...this.props} />
                                        {/* <Route path='/requests/:hashId/review-booking' component={Prepayment}  {...this.props}/>*/}
                                        <RouteWithProps path='/reviews/:hashId' component={AllReviews}  {...this.props} />
                                        
                                        {/* redirects of web urls */}
                                        <Redirect from="/steps" exact to="/" />

                                        {/* Error */}
                                        <RouteWithProps component={Errorpage} />
                                    </Switch>
                                    <BottomTabs
                                        ref={this.bottomTabRef}
                                        giveCount={changeCount => {
                                            this.changeCount = changeCount
                                        }}
                                    />
                                </React.Fragment>
                            )
                        }
                    </App>
                </Router>
            </Provider>
        );
    }

}

export default Entry;


ReactDOM.render(

    <Entry />,
    document.getElementById('root')
);

registerServiceWorker();

if (process.env.NODE_ENV === 'production') {
    Sentry.init({
        dsn: 'https://c294d6ce17ac426e84f75017ab2a71f1@sentry.io/1247395',
        release: '0-0-0',
        environment: process.env.REACT_APP_ENVIRONMENT,
    })
}
