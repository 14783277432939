import { SET_STATE_TRIP_OBJ, SET_STATE_TRIP_RESET } from '../actions/trip/vars';

const initialState = {
    'trip_list': [],
    'past_trip_count': '',
    'api': false,
    'activeImg': false,
    'past_trip_list':[],

    'tile': '',
    'title': '',
    'location_name': '',
    'property_images': [],
    'request_hash_id': '',
    'info':'',
    'invoice_section': '',
    'invoice_header': [],
    'invoice_middle': [],
    'invoice_footer': [],
    'booking_status': '',
    'location': '',
    'cancellable': '',
    'cancellation_reasons': [],
    'cancellation_policy_info': '',
    'booking_info_section': '',
    'property_info_section': '',
    'webicon': '',
    'booking_amount_info': '',
    'cancel_text': '',
    'apiData': false,
    'viewInvoice': false,
    'goBack':false,
    'trips_scroll':0,
    offset:0,
    Roffset:0,
    flag : {
        no_more_result : false,
        no_result : false,
        api : {
            is_loading: false,
            is_loader:false,
        }
    },
    RdataLoaded:false,
    dataLoaded: false,
    tripDataLoaded: false,
    tripHash: null
}

const fnc = (state = initialState, action) => {
    switch (action.type) {
        case SET_STATE_TRIP_OBJ:
            let newState = Object.assign({}, state, action.payload);
            return newState;

        case SET_STATE_TRIP_RESET:
            return Object.assign({}, initialState);

        case 'CLEAR_REDUX_STATE':
            return { ...state, dataLoaded: false, RdataLoaded: false, trip_list: [] }
            
        default:
            return state;
    }
}

export default fnc;