import React from 'react';
import Modal from 'react-modal';
import ResponseMessage from '../../response/';
import {connect} from 'react-redux';
import { updateUserStateObj} from '../../../actions/setting/actions';

// services
import {getChangePasswordApi} from '../../../services/index.js';
import { checkIfOffline } from '../../../services/helpers';

var Form = require('react-formal');

var yup = require('yup');

function samePassword() {
 let parent = this.parent;
 if((parent.confirm_password !== undefined && parent.confirm_password.length === 0) || (parent.new_password !== undefined && parent.new_password.length === 0)){
   return true;
 }
 return  parent.new_password === parent.confirm_password
}
var useResetPasswordSchema = yup.object({
	old_password: yup.string().required('Enter your current password'),
	new_password: yup.string().required('Enter new password').min(6,'Password must be at least 6 characters long'),
    confirm_password: yup.string().required('Confirm new password').test('Passwords-match', 'Passwords do not match', samePassword)
});
class ChangePassword extends React.Component {
	 constructor() {
		super();
		this.handleSubmit = this.handleSubmit.bind(this);
		this.changeHandle = this.changeHandle.bind(this);
		this.SubmitChangePasswordDetail = this.SubmitChangePasswordDetail.bind(this);
		this.getChangePasswordApiDataSuccess = this.getChangePasswordApiDataSuccess.bind(this);
		this.getChangePasswordApiDataError = this.getChangePasswordApiDataError.bind(this);
		this.resetMessages = this.resetMessages.bind(this);
		this.formErrorHandle = this.formErrorHandle.bind(this);
		this.onBlurShowMessage=this.onBlurShowMessage.bind(this);
        this.onFocusHideMessage=this.onFocusHideMessage.bind(this);
        //this.passwordChangedModalOpen=this.passwordChangedModalOpen.bind(this);
        //this.modalRouting=this.modalRouting.bind(this);
	}

    formErrorHandle(errors){
        console.log(errors);
        for (var property in errors) {
            if (errors.hasOwnProperty(property) && errors[property] !== undefined && errors[property].length > 1) {
                errors[property] = errors[property][0]['message'];
                //console.log("\n\n\n\n", property, errors[property],errors[property].length);
            }
        }
    }

	resetMessages(){
		this.props.updateUserStateObj({
			'message':'',
			'show':false,
		});
	}
	handleSubmit(event){
		if (checkIfOffline()) {
			this.props.toggleStatus()
			return
		}
		this.props.updateUserStateObj().then(() => {
			this.SubmitChangePasswordDetail({
				'old_password': this.props.user.model.old_password ,
				'new_password': this.props.user.model.new_password,
				'confirm_password': this.props.user.model.confirm_password,
			});
		})
		
	}
	changeHandle(model){
		this.props.updateUserStateObj({ model });
	}
 	onFocusHideMessage(e){

        let field_valid = this.props.user.field_valid;
        field_valid[e.target.name] = true;

        //console.log(e.target.name, fiel);
        this.props.updateUserStateObj({ field_valid : field_valid});
    }
    onBlurShowMessage(e){
        let field_valid = this.props.user.field_valid;
        field_valid[e.target.name] = false;

        //console.log(e.target.name, fiel);
        this.props.updateUserStateObj({ field_valid : field_valid});
        //this.setState({ validation:true,});
    }

	SubmitChangePasswordDetail(args){
        let this_el = this;
        getChangePasswordApi(args).then(function(response){
            // success
            this_el.getChangePasswordApiDataSuccess(response);
        }).catch(function(error){
			// error
			//console.log(error)
			if(error.hasOwnProperty('response') && error.response === undefined){
                let networkError = {
                    response:{
                        data:{
							error:[
								{message:'Please check your internet connection'}
							]
                        }
                    }
                }
               // this_el.setState({status:false});
                this_el.getChangePasswordApiDataError(networkError);
            }else if(error.hasOwnProperty('response') && error.response.status === 500){
				let networkError = {
                    response:{
                        data:{
							error:[
								{message:'Server Error! please try after sometime'}
							]
                        }
                    }
                }
				this_el.getChangePasswordApiDataError(networkError);
            }else{
				this_el.getChangePasswordApiDataError(error);
			}
           
        });
    }

    getChangePasswordApiDataError(error){
        this.props.updateUserStateObj	({
            'message': error.response.data.error[0].message,
			'status': false,
			'show':true
        });
    }
    getChangePasswordApiDataSuccess(response) {
        let this_el = this;
        this_el.props.passwordChangedModalOpen();
    }
    

  render() {
  	const this_el = this;
	return (
	  <div>
		<Modal
		  isOpen={this.props.changePasswordOpen}
		  onAfterOpen={this.afterOpenModal}
		  onRequestClose={this.props.changePasswordClose}
		  contentLabel="Modal"
				overlayClassName={{
				base: 'overlay-modal overlay-popup',
				afterOpen: '',
				beforeClose: ''
			  }}
		  className={{
				base: 'content-modal',
				afterOpen: 'editProfile changepassword',
				beforeClose: ''
			  }}
			>
		  <div className="modal-body text-center">
			<span className="back-btn" onClick={this.props.goBack}></span>
			<div className="verify-text1 text-left">Would you like to change your password?</div>
			<Form 
			schema={useResetPasswordSchema}
			 onSubmit={this.handleSubmit} 
			 onChange={model => this.changeHandle(model)}
			 errors={this.props.user ? this.props.user.errors : {}}
             onError={errors => this.formErrorHandle(errors)}
			 >	
				<Form.Field type="password"className="input-box" name="old_password" onFocus={(e) =>this.onFocusHideMessage(e)} onBlur={(e) => this.onBlurShowMessage(e)}  placeholder="Current password" value={this.props.user.old_password}/>
				{!this.props.user.field_valid.old_password &&
				<Form.Message for='old_password'/>
				}
				<Form.Field type="password"className="input-box" name="new_password" onFocus={(e) =>this.onFocusHideMessage(e)} onBlur={(e) => this.onBlurShowMessage(e)}  placeholder="New password" value={this.props.user.new_password}/>
				{!this.props.user.field_valid.new_password &&
				<Form.Message for='new_password'/>
				}
				<Form.Field type="password"className="input-box" name="confirm_password" onFocus={(e) =>this.onFocusHideMessage(e)} onBlur={(e) => this.onBlurShowMessage(e)}  placeholder="Confirm new password" value={this.props.user.confirm_password}/>
				{!this.props.user.field_valid.confirm_password &&
				<Form.Message for='confirm_password'/>
				}
				<br/>
				<div className={'response-msg '+this.props.user.show }>
					<ResponseMessage responseMessage={this_el.props.user.message} responseStatus={this_el.props.user.status}  resetMessages={this_el.resetMessages}/>
				</div>
				<Form.Button  type='submit' onClick={this_el.showErrorMSg}className="link green" >Change password</Form.Button>
			</Form>
		  </div>
		</Modal>
		
	  </div>
	);
  }
}
const mapStateToProps = (store) =>{
	return{
	   user : store.UserChangeData
   }
}
const mapDispatchToProps = {
   updateUserStateObj
}
export default connect(mapStateToProps, mapDispatchToProps)  (ChangePassword);