import React from 'react';
import Modal from 'react-modal';
import { connect } from 'react-redux';
import { withRouter } from 'react-router';

class ContentPolicy extends React.Component {
    constructor(props) {
        super(props);
        this.goBack = this.goBack.bind(this);
    }
    goBack(){
        this.props.history.goBack();
    }
    render(){
        return(
            <div>
                <Modal
                    isOpen={this.props.user.contentPolicyModal}
                    contentLabel="Modal"
                    overlayClassName={{
                    base: 'overlay-modal',
                    afterOpen: '',
                    beforeClose: ''
                    }}
                    className={{
                    base: 'content-modal',
                    afterOpen: 'full-screen-modal termconditon',
                    beforeClose: ''
                    }}>

                    <div className="about-property-modal">
                        <div className="modal-header active">
                            <div className="header-btn back-btn white-icon" onClick={this.goBack}></div>
                            <h2 className="text-center">Content Policy</h2>
                        </div>
                        <div className="modal-body">
                            <div className="conditions">
                                <div className="text">By posting content on Guesthouser, you agree to abide by these Guidelines and other Guesthouser policies. We reserve the right to remove content, in whole or part, that violates these guidelines or the Guesthouser Terms of Service , or for any other reason in our sole discretion.
                                <br/>
                                <br/>
                                In the event of repeated or severe violations, we may suspend or cancel the account(s) in question. Guesthouser's determination on whether any specific content is prohibited will be final and conclusive.
                                <br/>
                                <br/>
                                You can contact us or flag Group posts to report content that violates our Terms of Service  or Content Guidelines.
                                </div>
                            </div>
                            <div className="conditions">
                                <div className="heading">
                                     Listings And Profiles
                                </div>
                                <div className="text">
                                       <div> We expect Hosts to create listings that are honest, clear, and helpful to potential guests. We prohibit:</div>
                                       <br/>
                                       <ul>
                                            <li>Advertising or other commercial content.</li>
                                            <li>Content that endorses or promotes illegal or harmful activity or violence, or is profane, vulgar, obscene, defamatory, threatening, or discriminatory.</li>
                                            <li>Political, religious, or social commentary.</li>
                                            <li>Attempts to impersonate another person.</li>
                                            <li>Content that is fraudulent, false, misleading or deceptive.</li>
                                            <li>Content that refers to the Guesthouser investigation.</li>
                                            <li>Content that violates another person or entity’s rights, including intellectual property rights and privacy rights. </li>
                                        </ul>
                                </div>
                            </div>
                            <div className="conditions">
                                <div className="heading">
                                     Reviews
                                </div>
                                <div className="text">
                                    <div>Genuine reviews are the cornerstone of our community. We prohibit:</div>
                                       <br/>
                                       <ul>
                                            <li>Reviews that do not represent users’ personal experience.</li>
                                            <li>Reviews unrelated to the actual reservation (ex: political, religious, or social commentary).</li>
                                            <li>Content that endorses or promotes illegal or harmful activity or violence, or is profane, vulgar, obscene, defamatory, threatening, or discriminatory.</li>
                                            <li>Content that violates another person or entity’s rights, including intellectual property rights and privacy rights (ex: publishing another person’s full name, address or other identifying information without permission).</li>
                                            <li>Content that is proven to be used as extortion.</li>
                                            <li>Content that refers to the Guesthouser investigation.</li>
                                            <li>Groups</li>
                                        </ul>
                                        <br/>
                                    <div>The guest by sharing reviews/photographs on any of GuestHouser India Pvt. Ltd.'s platforms unconditionally consents to sharing of the said reviews/photographs for Guest Houser India Pvt. Ltd. marketing purposes and agrees that Guest Houser India Pvt. Ltd. shall not in any way be held responsible and/or liable for misuse, if any, of said reviews/photographs.</div>
                                </div>
                            </div>
                        </div>
                    </div>
                </Modal>
            </div>
        )
    }
}
const mapStateToProps = (store) => {
    return {
        user : store.UserChangeData,
    }
}
export default connect(mapStateToProps, null)(withRouter(ContentPolicy));