import React from 'react';
import Modal from 'react-modal';
import {connect} from 'react-redux';
import { withRouter } from 'react-router';
import { getURLParameter } from '../../../services';
import { cloneObj } from '../../../services/helpers';
import { getVal } from './../../../services/helpers';

// actions

import { updateBookingDataState } from './../../../actions/booking-data/actions';
import { updateSearchState } from './../../../actions/search/actions';
import { updateHomeState } from '../../../actions/home/actions'
import { trackGoToSearchPage } from '../../../services/tracker/events.js';

class selectGuestsModal extends React.Component {
	
	constructor(props){
		
		super(props);
		this.plusGuest = this.plusGuest.bind(this);
		this.minusGuest = this.minusGuest.bind(this);
		this.plusUnit = this.plusUnit.bind(this);
		this.minusUnit = this.minusUnit.bind(this);
		this.closeModal = this.closeModal.bind(this);
		this.openModal = this.openModal.bind(this);
		this.submitSearchData = this.submitSearchData.bind(this);
		this.updateRecentSearchLocalStorage = this.updateRecentSearchLocalStorage.bind(this);
	}
	

	componentWillUpdate () {
		let this_el = this;
	}
	
	
	openModal(){
		
		const this_el = this;
		let flag = Object.assign({}, this_el.props.search.flag);
		flag.modal.date_range = true;
		
		this_el.props.updateSearchState({
			flag: flag
		});
		
	}
	closeModal(){
		const this_el = this;
		this.props.updateBookingDataState({
			guest_count: this.props.location.search.includes('guests') ? (parseInt(getURLParameter(this.props.location.search, 'guests'), 10)) : (0),
			unit_count: this.props.location.search.includes('units') ? (parseInt(getURLParameter(this.props.location.search, 'units'), 10)) : (0),
			checkin_date: this.props.location.search.includes('checkin') ? this.toDate(getURLParameter(this.props.location.search, 'checkin')) : (1),
			checkout_date: this.props.location.search.includes('checkout') ? this.toDate(getURLParameter(this.props.location.search, 'checkout')) : (1),
		});
		
		let flag = Object.assign({}, this_el.props.search.flag);
		flag.modal.select_guest = false;
		
		this_el.props.updateSearchState({
			flag : flag
		});
		
	}
	
	toDate(dateStr) {
		if (dateStr !== null) {
			var parts = dateStr.split("-");
			return new Date(parts[2], parts[1] - 1, parts[0]);
		} else {
			return 1;
		}
	}
	
	
	submitSearchData(){
		const this_el = this;
		if ((this.props.booking.checkin_date === 1 || this.props.booking.checkout_date === 1)) {
			this.props.updateSearchState({
				shake_animation_dates: 'shakeAnimation'
			})
			setTimeout(function(){
				this_el.props.updateSearchState({
					shake_animation_dates : '' 
				});
			},500);
			return
		}
		setTimeout(() => {
			this_el.updateRecentSearchLocalStorage();
			let search_flag = Object.assign({}, this_el.props.search.flag);
			search_flag.modal.date_range = false;
			search_flag.modal.location = false;
			search_flag.modal.select_guest = (this_el.props.booking.guest_count < 1) ? true : false;
			search_flag.show_loader = true;
			search_flag.api.is_loading = true;
			let shake_animation = (this_el.props.booking.guest_count < 1) ? 'shakeAnimation' : ''
			
			this_el.props.updateSearchState({
				flag : search_flag,
				shake_animation : shake_animation
				
			}).then(() => {
				
				
				if(this_el.props.booking.guest_count < 1){
					// guest is not selected
					setTimeout(function(){
						this_el.props.updateSearchState({
							shake_animation : '' 
						});
					},500);
					if ("vibrate" in navigator) {
						navigator.vibrate(500);
		
					}
					
				}else{
					// submitting search
					
					let booking = Object.assign({}, this_el.props.booking);
					const filter_replica = JSON.parse(JSON.stringify(this_el.props.search.filter.filter_replica));
					let offset = this_el.props.search.offset;
					let urlParams = [];
					
					
					urlParams.push('checkin=' + this_el.dateConvertor(booking.checkin_date));
					urlParams.push('checkout=' + this_el.dateConvertor(booking.checkout_date));
					urlParams.push('guests=' + getVal(booking.guest_count));
					urlParams.push('lat=' + getVal(this_el.props.search.search_address_data.lat));
					urlParams.push('lng=' + getVal(this_el.props.search.search_address_data.lng));
					urlParams.push('location=' + getVal(this_el.props.search.search_address_data.location));
					urlParams.push('area=' + getVal(this_el.props.search.search_address_data.area));
					urlParams.push('city=' + getVal(this_el.props.search.search_address_data.city));
					urlParams.push('state=' + getVal(this_el.props.search.search_address_data.state));
					urlParams.push('country=' + getVal(this_el.props.search.search_address_data.country.ccode ? this_el.props.search.search_address_data.country.ccode : this_el.props.search.search_address_data.country));
					if (!this.props.location.pathname.includes('/searchlocation')) { // Because of filter issue in IOS devices
						urlParams.push('min_budget=' + getVal(filter_replica.price.min));
						urlParams.push('max_budget=' + getVal(filter_replica.price.max));
						urlParams.push('roomtype=' + getVal(filter_replica.roomtype));
						urlParams.push('instant_book=' + getVal(filter_replica.instant_book));
						urlParams.push('cash_on_arrival=' + getVal(filter_replica.cash_on_arrival));
						urlParams.push('bedroom=' + getVal(filter_replica.bedroom));
						urlParams.push('property_type=' + getVal(filter_replica.property_type).join(','));
						urlParams.push('amenities=' + getVal(filter_replica.amenities).join(','));
						urlParams.push('area=' + getVal(filter_replica.area).join(','));
					}
					urlParams.push('offset='+offset);
					if (this.props.location.search.includes('promo')) {
						urlParams.push('promo=' + getURLParameter(this.props.location.search, 'promo'));
					}
					
					urlParams = urlParams.filter(x => (x.slice(-1) !== '=' && x.slice(-2) !== '=0') || x.includes('min_budget') || x.includes('max_budget'))
					console.log('Params', urlParams)

					let search_url_params = '?' + urlParams.join('&');

					
					const search_track_data = {
						checkin: this_el.dateConvertor(booking.checkin_date),
						checkout: this_el.dateConvertor(booking.checkout_date),
						guests: booking.guest_count,
						lat: this_el.props.search.search_address_data.lat,
						long: this_el.props.search.search_address_data.lng,
						location: this_el.props.search.search_address_data.location,
						area: booking.area,
						city: this_el.props.search.search_address_data.city,
						state: this_el.props.search.search_address_data.state,
						country: this_el.props.search.search_address_data.country.ccode ? this_el.props.search.search_address_data.country.ccode : this_el.props.search.search_address_data.country
					}

					trackGoToSearchPage(this.props.tracker, this_el.props.home.page, "search btn", search_track_data);

					console.log(search_url_params)
					if(this_el.props.match.path === '/search/s' || this_el.props.match.path === '/stays/:location'){
						// already on search page
						this_el.props.history.replace({
							pathname: "/search/s",
							search: search_url_params,
						});
						
					}else{
						// never visited the search page
						this.props.history.push('/')

						this_el.props.history.push({
							pathname: "/search/s",
							search: search_url_params,
						});

						this_el.props.updateHomeState({
							homeHistory: [
								...this_el.props.home.homeHistory,
								"/search/s" + search_url_params
							]
						})
						
						localStorage.setItem('homeHistory', JSON.stringify([
							...this.props.home.homeHistory,
							"/search/s" + search_url_params
						]))
						}
					if(typeof this_el.props.updateStore !== 'undefined'){
						let reset = true;
						this_el.props.updateStore(reset);
					}
					
					
				}
				
			});
		}, 300);

	}

	updateRecentSearchLocalStorage() {
		
		const this_el = this;
		
		let recent_local_storage_data = {
			location: this_el.props.search.search_address_data.location,
			area: this_el.props.search.search_address_data.area ,
			city: this_el.props.search.search_address_data.city ,
			state: this_el.props.search.search_address_data.state ,
			country: {
				ccode: (x => typeof x === 'string' ? x : x.ccode)(this_el.props.search.search_address_data.country)
			},
			checkin_date: this_el.props.booking.checkin_date,
			checkout_date: this_el.props.booking.checkout_date,
			guest_count: this_el.props.booking.guest_count,
			latitude: this_el.props.booking.lat,
			longitude: this_el.props.booking.lng,
		};
		
		let recentSearch = [];
		if (localStorage.getItem('recentSearch') !== null) {
			recentSearch = JSON.parse(localStorage.getItem('recentSearch'));
			
			for (let i = 0; i < recentSearch.length; i++) {
				const element = recentSearch[i];
				if (element.location === recent_local_storage_data.location){
					recentSearch.splice(i, 1)
				}
			}
			recentSearch.unshift(recent_local_storage_data);

			
			if (recentSearch.length >= 6) {
				recentSearch.splice(5, 1)
			}

		} else {
			recentSearch = [recent_local_storage_data];
		}


		// updating recent search in search header recent_popular_search key
		let header = cloneObj(this_el.props.search.header);
		header.recent_popular_search = recentSearch;
		if (!this.props.location.pathname.includes('stays')) {
				//recent_popular_search
				this_el.props.updateSearchState({
					header : header
				}).then(() => {
					// updating localstorage for recent search
					localStorage.setItem('recentSearch', JSON.stringify(recentSearch));
				});
		}
	}
	
	dateConvertor(date) {
		
		if(typeof date === 'undefined' || !date){
			return '';
		}
		
		let dd = date.getDate();
		let mm = date.getMonth() + 1; //January is 0!
		
		let yyyy = date.getFullYear();
		if (dd < 10) {
			dd = '0' + dd;
		}
		if (mm < 10) {
			mm = '0' + mm;
		}
		return dd + '-' + mm + '-' + yyyy;
	}
	
	
	minusGuest(e) {
		e.preventDefault();
		
		const this_el = this;
		
		// updating guest count
		if (this_el.props.booking.guest_count > 0){
			this_el.props.updateBookingDataState({
				guest_count: this_el.props.booking.guest_count -1
			});
		}
		
		
		// updating unit
		if (this_el.props.booking.guest_count < this_el.props.booking.unit_count * this_el.props.property.guests_per_unit){
			this.minusUnit(e);
		}
	}
	
	plusGuest(e) {
		
		e.preventDefault();
		const this_el = this;
		// updating guest count
		if (this_el.props.booking.guest_count < this_el.props.property.guests_per_unit * this_el.props.property.available_units) {
			this_el.props.updateBookingDataState({
				guest_count: this_el.props.booking.guest_count + 1
			});
		}
		
	}
	
	plusUnit(e) {
		
		if (e !== undefined) e.preventDefault();
		const this_el = this;
		
		// updating unit
		if (this_el.props.booking.unit_count < this_el.props.property.available_units){
			this_el.props.updateBookingDataState({ unit_count: this_el.props.booking.unit_count + 1});
		}
		
	}
	minusUnit(e) {
		
		if (e !== undefined) e.preventDefault();
		const this_el = this;
		
		if (this_el.props.booking.unit_count > 0){
			this_el.props.updateBookingDataState({ unit_count: this_el.props.booking.unit_count - 1 });
		}
	}
	
	autoUnitUpdate() {
		
		const this_el = this;
		
		if ((this_el.props.search.flag.property_preview || this_el.props.search.flag.prepayment) && this_el.props.booking.guest_count > 0 && this_el.props.booking.guest_count > this_el.props.property.guests_per_unit * this_el.props.booking.unit_count){
			this.plusUnit();
		} 
	}
	
	componentDidUpdate(nextProps){
		let this_el = this;
		this.autoUnitUpdate();
		if (this.props.booking.resetted && !this.props.search.flag.modal.date_range) {
			this.props.updateBookingDataState({
				checkin_date: this_el.props.location.search.includes('checkin')
					? (this_el.toDate(getURLParameter(this_el.props.location.search, 'checkin')))
					: this.props.resettedDates
						?	this.props.resettedDates.checkin_date
						: 1,
				checkout_date: this_el.props.location.search.includes('checkout')
					? (this_el.toDate(getURLParameter(this_el.props.location.search, 'checkout')))
					: this.props.resettedDates
						?	this.props.resettedDates.checkout_date
						: 1,
				resetted: false
			})
		}	
	}
	
	render(){
		
		
		const this_el = this;
		const locale = "en-us";
		let checkinMonth = this_el.props.booking.checkin_date.toLocaleString(locale, {month: "short" });
		let checkoutMonth = this_el.props.booking.checkout_date.toLocaleString(locale, { month: "short" });
		
		let roomtype = this_el.props.booking.room_type;
		if (this_el.props.booking.room_type === 'Private Room'){
			roomtype = "Room"
		} else if (this_el.props.booking.room_type === 'Shared Room'){
			roomtype = "Bed"
		}
	
		return(
			<Modal
				isOpen={this_el.props.search.flag.modal.select_guest}
				onRequestClose={this_el.closeModal}
				contentLabel="Modal"
				onAfterOpen={() => {
					if (this.props.location.pathname.includes('search')) {
						this.props.updateSearchState({
							fromSearch: true
						})
					} else {
						this.props.updateSearchState({
							fromSearch: false
						})
					}
				}}

				overlayClassName={{
					base: 'overlay-modal guest-modal-overlay',
					afterOpen: '',
					beforeClose: ''
				}}
				className={{
					base: 'content-modal',
					afterOpen: 'full-screen-modal select-guests-modal',
					beforeClose: ''
			}}>
				
				<div className="modal-header">
					{this_el.props.search.flag.prepayment &&
						<h2>Modify details</h2>
					}
					{this_el.props.search.flag.property_preview &&
						<h2>Add details</h2>
					}
					<div className="header-btn close-btn" onClick={this_el.closeModal}> </div>
				</div>
				<div className="modal-body">
					<h2 className={this_el.props.search.flag.prepayment || this_el.props.search.flag.property_preview ? 'opacity-0' : ''}>{this_el.props.search.search_address_data.location && this_el.props.search.search_address_data.location.split(',').slice(0, 2).join(',')}</h2>
					<div className={"date-picker " + this_el.props.search.shake_animation_dates} onClick={this_el.openModal}>
						<span className="check-in"><div>Check In</div>{this_el.props.booking.checkin_date === 1 || typeof this_el.props.booking.checkin_date.getDate === 'undefined' ? ('Check-in') : (this_el.props.booking.checkin_date.getDate() + ' ' + checkinMonth + ' ' + this_el.props.booking.checkin_date.getFullYear())}</span>
						<i></i>
						<span className="check-out"><div>Check Out</div>{this_el.props.booking.checkout_date === 1 || typeof this_el.props.booking.checkout_date.getDate === 'undefined' ? ('Check-out') : (this_el.props.booking.checkout_date.getDate() + ' ' + checkoutMonth + ' ' + this_el.props.booking.checkout_date.getFullYear())}</span>
					</div>
					<div className="text-center">
						<div className={"guest-count " + this_el.props.search.shake_animation}>
							{this_el.props.booking.guest_count > 1 
								? <span className="minus-btn" onMouseDown={this.minusGuest}></span>
								: <span className="minus-btn disable"></span>
							}
							<div type="text" name="bedrooms" className="total-bedrooms text-center" readOnly="readonly" >
								{this_el.props.booking.guest_count < 1 
									? ('Select Guests') 
									: (this_el.props.booking.guest_count + (this_el.props.booking.guest_count > 1 ? (' Guests'): (' Guest')))
								}
							</div>
							{this_el.props.booking.guest_count === this_el.props.property.available_units * this_el.props.property.guests_per_unit 
								? <span className="plus-btn disable"></span>
								: <span className="plus-btn" onMouseDown={(e) => this.plusGuest(e)}></span>
							}
						</div>
						
						{typeof this.props.submitPropertyDataToApi === 'function' &&
							<div className={"guest-count unitcount " + this_el.props.property.shake_animation_unit}>
							
								{this_el.props.booking.guest_count <= this_el.props.property.guests_per_unit * this_el.props.booking.unit_count && this_el.props.booking.guest_count > this_el.props.property.guests_per_unit * (this_el.props.booking.unit_count-1) 
									? <span className="minus-btn disable"></span>
									: <span className="minus-btn" onMouseDown={this.minusUnit}></span>
								}
								
								<div type="text" name="bedrooms" className="total-bedrooms text-center" readOnly="readonly" >
									{this_el.props.booking.unit_count < 1 
										? ('Select ' + roomtype +'s') 
										:(this_el.props.booking.unit_count +' '+ (this_el.props.booking.unit_count > 1 ? (roomtype+'s'): (roomtype)))
									}
								</div>
									
								{this_el.props.booking.unit_count === parseInt(this_el.props.property.available_units, 10) 
									? <span className="plus-btn disable"></span>
									: <span className="plus-btn" onMouseDown={this.plusUnit}></span>
								}
							</div>
						}
					</div>
					<div className="floating-btn-box text-center active">
						{typeof this.props.submitPropertyDataToApi === 'function' 
							? <a className="floating-btn" onClick={() => this.props.submitPropertyDataToApi(true)}>Done</a>
							: <a className="floating-btn" onClick={() => this_el.submitSearchData()}>Search</a>
						}
					</div>
				</div>
			</Modal>
		)
	}
}
		
		
		
const mapStateToProps = store => {
	return {
		booking	:	store.booking,
		search			:	store.search,
		property		:	store.property,
		home: store.home,
		tracker: store.tracker.tracker
	};
};

const mapDispatchToProps = {
	updateBookingDataState,
	updateSearchState,
	updateHomeState,
};
export default connect(
	mapStateToProps,
	mapDispatchToProps
	)(withRouter(selectGuestsModal));