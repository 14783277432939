import React from 'react'
import { connect } from 'react-redux'
import { Redirect, withRouter } from 'react-router'
import { hasAccessToken, getLoginAccountDataApi } from '../../services/index'
import { getURLParameter } from '../../services/index'
import { updateSignupStateObj } from '../../actions/signup/actions'
import { updateHomeState } from '../../actions/home/actions'

import { attachBlockZoomListener, removeBlockZoomListener } from '../../services/helpers'

class RedirectEmail extends React.Component {
    constructor(props) {
        super(props);

        if (localStorage.getItem('homeHistory')) {
            this.props.updateHomeState({
                homeHistory: JSON.parse(localStorage.getItem('homeHistory'))
            })
        }
    }

    componentDidMount() {
        if (hasAccessToken()) {
            getLoginAccountDataApi(getURLParameter(this.props.location.search, 'host_id'))
                .then(({ data: { data } }) => {
                    localStorage.setItem('addEmail', data.add_email)
                    this.props.updateSignupStateObj({
                        add_email: data.add_email
                    })
                    localStorage.setItem('is_mobile_verified', data.is_mobile_verified)
                    this.props.updateSignupStateObj({
                        is_mobile_verified: data.is_mobile_verified
                    })
                })
                .then(_ => {
                    if (localStorage.getItem('addEmail') === "1") {
                        this.props.history.push('/user/contact?retry') 
                    }
                })
                .catch(err => console.log(err))
        }

        this.props.history.listen((location) => {
            if (location.search === '?retry') {
                return
            }
            if (this.props.addEmail === 1) {
                this.props.history.push('/user/contact?retry')
            } else if ((x => x && x === "1")(localStorage.getItem('addEmail'))) {
                this.props.history.push('/user/contact?retry')
            }
        })
    }

    componentWillReceiveProps(nextProps) {
        if (!nextProps.isDateRangeOpen) {
            attachBlockZoomListener()
        } else {
            removeBlockZoomListener()
        }
    }
    
    render () {
        return (
            <div />
        )
    }
} 
const mapStateToProps = (store) => {
	return {
        addEmail:store.signupEmail.add_email,
        isDateRangeOpen: store.search.flag.modal.date_range
    }
}

export default connect(mapStateToProps, { updateHomeState, updateSignupStateObj })(withRouter(RedirectEmail))
