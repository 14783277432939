

/* APIs */
// get signup data
let SignupLoginSocial = function (data, args)
{
    //console.log('hey1', args);
    return data.AXIOS.post(data.BASE_URL_V+'/user', data.QS.stringify({
            access_token:args.access_token,
            base_currency:args.base_currency,
            device_type: args.device_type,
            referral_code:args.referral_code,
            currency:args.currency,
            source:args.source,
        }), {
            headers: {
                'Accept': 'application/json',
                'Content-Type': 'application/x-www-form-urlencoded',
                'device-unique-id': data.DEVICE_UNIQUE_ID,

            }
        }
    );
}

exports.SignupLoginSocial = SignupLoginSocial;