import {SET_STATE_LOGIN_OBJ} from './../actions/login/vars';

const initialState = {
    'body_bg_color': '#fff',
    'model':{
        'otp_code' :'',
        'reset_password_via':'',
        'password':'',
        'confirm':'',
    },
    'field_valid' :{
        'userId' : false,
        'otp_code' : false,
        'password':false,
    },
    'userId':'',
    'password':'',
    'message':'',
    'show':false,
    'validation':true,
    'status':false,
    'loading':false,
    'is_user_referred':'',
}

const loginEmail = (state = initialState, action) => {
    switch (action.type) {
        case SET_STATE_LOGIN_OBJ :
            let newState = Object.assign({}, state, action.payload);
            return newState;
            
        default:
            newState = Object.assign({}, state);
            return newState;
    }
}
export default loginEmail;