import React from 'react';
import Modal from 'react-modal';

class TravelledPlaces extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      'travelled_places':props.travelledPlace,
      'errorMsg':false,
      //'travelledPlaces':props.travelledPlacesClose
    }

    this.travelledPlacesChange =this.travelledPlacesChange.bind(this);
    this.savetravelledPlaces = this.savetravelledPlaces.bind(this);
    this.afterOpenModal = this.afterOpenModal.bind(this);
    }

    travelledPlacesChange(value){
        this.setState({
            'travelled_places': value.trimLeft()
        });
    }
    hideErroMsg=()=>{
      this.setState({
        errorMsg:false
      })
    }
    afterOpenModal(){
      let this_el = this;
      this.setState({
            'travelled_places': this_el.props.travelledPlace,
            errorMsg:false,
      });
    }
    savetravelledPlaces=(e)=>{
      e.preventDefault();
      if(this.state.travelled_places.length> 0){
      this.props.savetravelledPlaces(this.state.travelled_places);
      this.props.goBack();
      }else{
      this.setState({
        errorMsg:true
      })
    }
  }
  render() {
    return (
      <div>
        <Modal
          isOpen={this.props.travelledPlacesOpen}
          onAfterOpen={this.afterOpenModal}
          contentLabel="Modal"
                overlayClassName={{
                base: 'overlay-modal overlay-popup',
                afterOpen: '',
                beforeClose: ''
              }}
          className={{
                base: 'content-modal',
                afterOpen: 'editProfile',
                beforeClose: ''
              }}
            >
          <div className="modal-body text-center">
              <span className="back-btn" onClick={this.props.goBack}></span>
              <div className="verify-text1 text-left">Places you've travelled</div>
              <form class="editprofile_form" onSubmit={(e) => this.savetravelledPlaces(e)} >
              <textarea className="texarea" placeholder="Travelled Places" value={this.state.travelled_places} onFocus={() => this.hideErroMsg()} onChange={ e => this.travelledPlacesChange(e.target.value)}>
              </textarea>
              {
                this.state.errorMsg &&
                <span className="error textarea">Field can not be empty</span>
              }
              <button type="submit" className="link green" >Done</button>
              </form>
          </div>
        </Modal>
      </div>
    );
  }
}

export default TravelledPlaces;