import React from 'react';
import Modal from 'react-modal';
import { connect } from 'react-redux';
import { withRouter } from 'react-router';
import { removeClassToBody } from '../../services';


class AllAmenitiesList extends React.Component {
	constructor() {
		super();
		this.goBack = this.goBack.bind(this);
	}

	goBack() {
		this.props.history.goBack();
	}

	componentWillUnmount(){
		removeClassToBody();
	}

	render(){
		return(
			<div>
				<div className="overlay-modal">
					<div className="content-modal full-screen-modal">
						<div className="filter-modal">
							<div className="modal-header">
								<div className="close-btn header-btn" onClick={this.goBack}></div>
								<h2 className="text-center">Amenities</h2>
								<div className="transparent-gredient"></div>
							</div>
							<div className="modal-body">
								<div className="amenities-filter">
									<ul className="amenities-list">
									{this.props.property.amenities.map(function(item, index){
										return(
										<li key={index}><label><i className={'amenities-icon-id-' + item.id}></i> {item.name}</label></li>
										)
									})}
									</ul>
								</div>
							</div>
						</div>

					</div>
				</div>
			</div>
		)
	}
}

const mapStateToProps = (store) => {
	return {
		property: store.property
	}
}

export default connect(mapStateToProps, null)(withRouter(AllAmenitiesList));