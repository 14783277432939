import React from 'react';
import { connect } from 'react-redux';

class SpaceList extends React.Component{
    
	render(){
        
        const this_el = this;
        
        return(
            <section className="property-space clearfix">
            {this_el.props.property.flag.api.is_loading ?
                <div>
                    <h3>The space</h3>
                    <div className="space-amenities-list clearfix">
                        <ul>
                        {this_el.props.property.space.map(function(item, index){
                            return(
                                <li key={index}><i className={'icon-id-' + item.icon_id}></i> {item.name}</li>
                                )
                            })
                        }
                        </ul>
                        {this_el.props.booking.unit_count > 1 &&
                            <div className='unit-info'>
                                <span>{this_el.props.booking.unit_count}x</span>
                                <p>This is a description of single unit. <br />You get {this_el.props.booking.unit_count} such units.</p>
                            </div>
                        } 
                    </div>
                </div>
            :
                <div>
                <h3>The space</h3>
                <div className="animated-b animated-2"> 
                <div className="background-masker after-heading-left-overlay"></div>
                <div className="background-masker after-heading-right-overlay"></div>
                <div className="background-masker after-heading-content"></div>
                <div className="background-masker after-heading-content-1"></div>
                <div className="background-masker after-heading-content-2"></div>
                <div className="background-masker after-heading-10"></div>
                </div>
                </div>
                /*<div className="animated-b animated-2"> 
                <div className="background-masker after-heading-left-overlay"></div>
                <div className="background-masker after-heading-right-overlay"></div>
                <div className="background-masker after-heading"></div>
                <div className="background-masker after-heading-space"></div>
                <div className="background-masker after-heading-content"></div>
                <div className="background-masker after-heading-content-1"></div>
                <div className="background-masker after-heading-content-2"></div>
                <div className="background-masker after-heading-content-3"></div>
                <div className="background-masker after-heading-10"></div>
                </div>*/
            }
            </section>
            );
        }
    }
    
    
    const mapStateToProps = (store) => {
        return {
            property : store.property,
            booking : store.booking
        }
    }
    export default connect(mapStateToProps, null)(SpaceList);