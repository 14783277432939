import React from 'react';
import Modal from 'react-modal';
import { withRouter } from 'react-router';
import {connect} from 'react-redux';

	
import { cloneObj, getObjectVal} from '../../../services/helpers.js';

//actions
import { updateSearchState} from './../../../actions/search/actions.js';


class AllAmenities extends React.Component {


    constructor(props) {
        super(props);	


        // in use methods
        this.closeModal = this.closeModal.bind(this);



        // no idea
        this.amenitySelected	=	this.amenitySelected.bind(this);
        this._handleChange	=	this._handleChange.bind(this);
        this.showSubmitButton	=	this.showSubmitButton.bind(this);
        this.submit	=	this.submit.bind(this);
				this.clear	=	this.clear.bind(this);
				
				this.state = {
					selectedAminities: [],
					showButton: false
				}

 		/**
 		 * Local static values
 		 */
 		this.suffix = '_amenity';

	}	

	closeModal(){
		const this_el = this;

 		let flag = Object.assign({}, this_el.props.search.flag);
 		flag.modal.amenity = false;
		this.submit()
 		this_el.props.updateSearchState({
 			flag : flag
 		});
	}


	_handleChange(item){
		if (this.state.selectedAminities.includes(item.id)) {
			this.setState({
				selectedAminities: this.state.selectedAminities.filter(x => x !== item.id),
				showButton: true
			})
		} else {
			this.setState({
				selectedAminities: [...this.state.selectedAminities, item.id],
				showButton: true
			})
		}
	}	

	amenitySelected(item){

		const this_el = this;
 		const selected = this_el.props.search.filter.filter_replica.amenities;

 		if(selected.length){
 			for (var key in selected) {
			    const id = selected[key];
			    if(item.id === id){
					return true;
			    }
			}
 		}
 		return false;
	}

	

    showSubmitButton(){


    	const this_el = this;
    	const selected = this_el.props.search.filter.filter_replica.amenities;
    	const localSelected = this_el.localSelected();

    	if(localSelected.length !== selected.length){
    		this_el.refs.floatBtn.classList.add('active');		
    		return;
    	}
    	else{
    		if(selected){
	    		for(var key in selected){
	    			const selected_id = selected[key];
	    			if(localSelected.indexOf(selected_id) === -1){
	    				this_el.refs.floatBtn.classList.add('active');		
    					return;				
	    			}
	    		}
	    	}
    	}
    	this_el.refs.floatBtn.classList.remove('active');
    }


    submit(){
    	const this_el = this;
		// const localSelected = this_el.localSelected();
	

		let searchObj = cloneObj(this_el.props.search);
 		searchObj.filter.filter_replica.amenities = this.state.selectedAminities;
 		searchObj.flag.modal.amenity = false;

 		// console.log('submit amenity modal', searchObj);

		this_el.props.updateSearchState(searchObj).then(() => {
			this_el.props.checkFilterChange();
		});

    }

    /**
	 * Clear all selected checboxes
	 */
	
	clear(e){
		this.setState({
			selectedAminities: [],
			showButton: true
		})
	}


	render(){
		const this_el = this
		return(
			<div>
				<Modal
					isOpen={this_el.props.search.flag.modal.amenity}
					onRequestClose={this_el.closeModal}
					onAfterOpen={() => {
						this.setState({
							selectedAminities: this.props.search.filter.filter_replica.amenities.length > 0 ? this.props.search.filter.filter_replica.amenities : []
						})
					}}

					overlayClassName={
						{
							base: 'overlay-modal',
							afterOpen: '',
							beforeClose: ''
						}
					}
					className={{
							base: 'content-modal',
							afterOpen: 'full-screen-modal',
							beforeClose: ''
						}
					}
				>
						<div className="filter-modal">
							<div className="modal-header">
								<a className="header-btn back-btn" onClick={this_el.closeModal}> </a>
								<button className={`reset-btn ${this.state.selectedAminities.length === 0 && 'disabled'}`} onClick={this_el.clear}>Clear</button>
								<h2 className="text-center">Filter by amenities</h2>
	                            <div className='transparent-gredient'></div>
							</div>
							<div className="modal-body">
								<div className="amenities-filter">

									<ul className="amenities-list">
										{
											getObjectVal(this_el.props.search.filter.values, 'amenities') && this_el.props.search.filter.values.amenities.map(function(item, index){
												return (
													<li key={index}>
														<label>
															<i className={'amenities-icon-id-' + item.id}></i> {item.amenity_name}
															<input 
																type="checkbox" 
																id={item.id} 
																value={item.id} 
																name={item.amenity_name} 
																className="pull-right"
																checked={this_el.state.selectedAminities.includes(item.id)}
																onChange={() => this_el._handleChange(item)}
															/>
															<span className="pull-right"></span>
														</label>
													</li>
												)
											})
										}
									</ul>
								</div>
								<div className={`floating-btn-box text-center ${this.state.showButton && 'active'}`}>
									<span className="floating-btn" onClick={this_el.submit}>Done</span>
								</div>
							</div>
						</div>
				</Modal>
			</div>
		)
	}
}



const mapStateToProps = store => {
	return {
		search : store.search
	};
};

const mapDispatchToProps = {
	updateSearchState
};

export default connect(mapStateToProps,mapDispatchToProps)(withRouter(AllAmenities));