import React from 'react';
import Modal from 'react-modal';

class Gender extends React.Component {
  constructor(props) {
      super(props);
        this.state = {
          gender:props.gender,
          //genderModal:props.genderModalClose
        }
        this.genderChange =this.genderChange.bind(this);
        this.saveGender =this.saveGender.bind(this);
      }
    genderChange(e) {
      this.setState({
            gender: e.target.value
    });

    }
    saveGender(){
      this.props.saveGender(this.state.gender);
      this.props.goBack();
    }
  render() {
    return (
      <div>
        <Modal
          isOpen={this.props.genderModalOpen}
          onAfterOpen={this.afterOpenModal}
          contentLabel="Modal"
                overlayClassName={{
                base: 'overlay-modal overlay-popup',
                afterOpen: '',
                beforeClose: ''
              }}
          className={{
                base: 'content-modal',
                afterOpen: 'editProfile',
                beforeClose: ''
              }}

        >
          <div className="modal-body text-center">
           <a className="back-btn" onClick={this.props.goBack}> </a>
              <div className="verify-text1 text-left">Please tell us your gender</div>
              <div className="row">
                <div className="col-12">
                <div className="box">
                <label className="container">Male
                  <input type="radio" defaultChecked={this.props.gender === 'Male'} onChange={this.genderChange} name="gender" value='Male'/>
                  <span className="checkmark"></span>
                </label>
                
                <label className="container">Female
                  <input type="radio" defaultChecked={this.props.gender === 'Female'}  onChange={this.genderChange} name="gender" value='Female'/>
                  <span className="checkmark"></span>
                </label>
                </div>
                </div>
              </div>
              <button className="link green" onClick={this.saveGender}>Done</button>
          </div>
        </Modal>
      </div>
    );
  }
}

export default Gender;