

/* APIs */
// get signup data
let getResetPasswordApi = function (data, args)
{
    return data.AXIOS.put(data.BASE_URL_V+'/user/password', data.QS.stringify({
            reset_password_via:args.reset_password_via,
            otp_code:args.otp_code,
            password:btoa(args.password),
        }), {
            headers: {
                'Accept': 'application/json',
                'Content-Type': 'application/x-www-form-urlencoded',
                'device-unique-id': data.DEVICE_UNIQUE_ID,
            }
        }
    );
}

exports.getResetPasswordApi = getResetPasswordApi;