

/* APIs */
// get signup data
let getOtpSendApi = function (data, args)
{
    //console.log(args);
    return data.AXIOS.post(data.BASE_URL_V+'/user/verify/mobile', data.QS.stringify({
            dial_code  : args.dial_code ,
            contact_number :args.contact_number,
            otp_method :args.otp_method,
            referral_code:args.referral_code
        }), {
            headers: {
                'Accept': 'application/json',
                'Content-Type': 'application/x-www-form-urlencoded',
                'device-unique-id': data.DEVICE_UNIQUE_ID,
                'Authorization' :'Bearer ' + data.ACCESS_TOKEN,
            }
        }
    );
}

exports.getOtpSendApi = getOtpSendApi;