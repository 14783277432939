import React from 'react';
import Modal from 'react-modal';

class ResendOtp extends React.Component {
  render() {
	return (
	  <div>
		<Modal
		  isOpen={this.props.resendOtpOpen}
		  onAfterOpen={this.afterOpenModal}
		  onRequestClose={this.props.resendOtpClose}
		  contentLabel="Modal"
				overlayClassName={{
				base: 'overlay-modal overlay-popup',
				afterOpen: '',
				beforeClose: ''
			  }}
		  className={{
				base: 'content-modal',
				afterOpen: 'resendOTP',
				beforeClose: ''
			  }}

		>
		  <div className="modal-body">
			  <a className="back-btn" onClick={this.props.resendOtpClose}> </a>
			  <div className="verify-text">Choose a method to receive phone verification code</div>
			  <button className="link green" onClick={() => this.props.viaSms()}>Send via <span>SMS</span></button>
			  <button className="link green" onClick={() => this.props.viaCall()}>Get OTP on <span>call</span></button>
		  </div>
		</Modal>
	  </div>
	);
  }
}

export default ResendOtp;