import React from 'react';
import {Link} from 'react-router-dom';
import booking_before_login from "../../static/img/infoWindow/booking-request-after-request.png";

class Errorpage extends React.Component {
	constructor(props){
		super(props);
		this.state = {
			'requests':[],
		};
		
	}
	render() {
		return (
			<div className="error-404">
				<div className="info-window">
	                <div className="window-container">
	                	<div className="imgb">
	                        <div className="img" style={{backgroundImage:'url(' + booking_before_login + ')'}}></div>
	                    </div>
	                    <div className="txtb"><p>Oops! Landed at the wrong place</p></div>
	                    <div className="txt"><p>Error Code : 404 Page Not Found	</p></div>
	                    <Link to="/" ><button className="btn">Go to Home Page</button></Link>
	                </div>
	            </div>
			</div>
			
		);
	}
}

export default Errorpage;