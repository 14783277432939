import React from 'react';
import noInternet from "../../static/img/infoWindow/noInternet.svg";
import { connect } from 'react-redux';
import serverError from "../../static/img/serverError.png";
//services
import { getRequestDetailsDataApi} from '../../services/index.js';
import {trackError} from '../../services/tracker/events.js';

/**
 * Actions
 */
import { updateRequestState } from '../../actions/request/actions';
import { updateErrorState } from '../../actions/error/actions';
import { checkIfOffline } from '../../services/helpers';
import OfflineStatus from '../../components/Offlinestatus';


class Archivedlsit extends React.Component {
    constructor(props){
        super(props);
        this.torequestDetails = this.torequestDetails.bind(this);
        this.getRequestApiDataSuccess=this.getRequestApiDataSuccess.bind(this);
        this.getRequestApiDataError= this.getRequestApiDataError.bind(this);

    }
    getRequestApiDataSuccess(response) {
        const this_el = this;
        let flag = Object.assign({}, this_el.props.request.flag);
		flag.no_more_result = (response.data.data.requests.length < 12 ); // if no more result flag found, set no_more_result to true
		flag.api.is_loading = false;

		let properties = Object.assign([], this_el.props.request.archivedRequests);
		
        Array.prototype.push.apply(properties,  response.data.data.requests); 
        
        this.props.updateRequestState({
            'archivedRequests':properties,
            dataLoaded: true,
            flag:flag
        }).then( () =>{
            // updating network state
            let error_flag = Object.assign({}, this_el.props.error.flag);
            error_flag.network = false;
            error_flag.server = false;
            this_el.props.updateErrorState({
                flag: error_flag
            });
        });

    }

    getRequestApiDataError(error) {
        let this_el = this;
        if(typeof error.response !== 'undefined' && error.response.status === 1000){
            let error_flag = Object.assign({}, this_el.props.error.flag);
            error_flag.network = true;

            // updating network state
            this_el.props.updateErrorState({
                flag: error_flag
            });
        }else if(error.response.status === 500){
			//console.log('dgdg',error);
			let flag =  this.props.error.flag;
			flag.server = true; 
			this.props.updateErrorState({ flag: flag });
			
		}else{
            trackError(error);
        }
    }

    getRequestApiData(args){
        let this_el = this;
        getRequestDetailsDataApi(args).then(function(response){
            // success
            this_el.getRequestApiDataSuccess(response);
        }).catch(function(error){
            // error
            this_el.getRequestApiDataError(error);
        });
    }

    componentWillMount() {
        const this_el = this;
        /**
		 * Calling Api
		 */
		if(!this_el.props.request.archivedRequests.length > 0){
			this_el.submitParams();
        }
        
        let flag = Object.assign({}, this.props.request.flag);
		flag.api.is_loading =  true;
		this.props.updateRequestState({
			flag:flag
		})

		/**
		 * Active scroll listner
		 */
		this_el.loadMoreResults();
    }
    componentDidMount() {
        window.scrollTo(0, 0);

        if (checkIfOffline()) {
            this.props.updateErrorState({
                flag: {
                    network: true
                }
            })
        } else {
            this.props.updateErrorState({ flag: { network: false } });
        }
    }
    submitParams(){
		const this_el = this;
		let args = {
            archived:'?archived=' + 1,
            total:12,
			offset:this_el.props.request.offset,
			loader: true,
		}
		//console.log(args);
		this.getRequestApiData(args);
	}
	reload = () =>{
		const this_el = this;
		/**
		 * Calling Api
		 */
		this_el.submitParams();
		/**
		 * Active scroll listner
		 */
		this_el.loadMoreResults();
    }
    imageLoaded(e){
        let imgSrc = e.target.src;
        e.target.nextElementSibling.style.backgroundImage = 'url('+imgSrc+')';
    }
    loadMoreResults(){
		const this_el = this;
		
		window.onscroll = function(){
			let archived_list =false;
            if (document.getElementById('archived_list') !== null){
                archived_list = true;
            }else {
                archived_list = false;
            }
			if(archived_list){
			//let offset = document.documentElement.scrollTop;
			const scrollTopOffset = Math.max(window.pageYOffset, document.documentElement.scrollTop, document.body.scrollTop);

			let windowHeight = window.innerHeight;
			let bodyHeight = document.body.clientHeight-500; // was 260
			let offsetWindowHeight = windowHeight + scrollTopOffset;
			if ((offsetWindowHeight >= bodyHeight) && !this_el.props.request.flag.api.is_loading && !this_el.props.request.flag.no_more_result){

				/**
				 * Updating api state to true, so in case of scroll no more api hit called, untill last api result popup
				 */

				let new_offset = this_el.props.request.offset + 12;
				let flag = Object.assign({}, this_el.props.request.flag);
				flag.api.is_loading = true;


                this_el.props.updateRequestState({
					offset : new_offset,
					flag : flag
				}).then(() => {
					this_el.submitParams();
				});

			}
			}
		}
    }
    
    torequestDetails(data){
        this.props.history.push({
            pathname: '/request/'+data.request_hash_id,
        });
        this.props.updateRequestState({
            booking_status: data.booking_status,
            request_hash_id: data.request_hash_id
        });
    }

    reload = () => {
        this.submitParams();
    }

    render() {
        const this_el = this;
        const ellipsis = {
             textOverflow: 'ellipsis',
             overflow:'hidden',
             WebkitBoxOrient: 'vertical'
        }
        return (
            <div className="archivedlsit" id="archived_list">
                <header className="header active">
                    <div className="row">
                        <div className="col-12 text-left">
                            <div className="back-icon" onClick={() => this.props.history.goBack()}></div>
                            <span className="middle-text text-center">Archived Requests</span>
                        </div>
                    </div>
                </header>
                {!this_el.props.error.flag.network || this.props.request.dataLoaded ?
                    <div className='booking'>
                        {!this.props.error.flag.server ?
					    <div>
                        <OfflineStatus
                            offline={checkIfOffline()}
                            bottom
                        />
                        {this.props.request.archivedRequests.map(function(item, index){
                                return(
                                    <div key={index} onClick={() => this_el.torequestDetails(item)}>
                                        <div className="booking-tile" >
                                            <div className="response">
                                                <div className="row">
                                                    <div className="col-8">
                                                        <div className={'text '+ item.booking_status.class}>{item.booking_status.text}</div>
                                                    </div>
                                                    <div className="col-4 text-right">
                                                        <div className={'icon '+ item.booking_status.class}></div>
                                                    </div>
                                                </div>
                                            </div>
                                            <div className="tile">
                                                <div className="row">
                                                    <div className="col-4">
                                                    {item.property_tile.property_images.length > 0 ?
                                                        item.property_tile.property_images.map(function(imageItem, indexImage){
                                                            let thumb = (imageItem.image).replace('2x', 'thumb');
                                                            return(
                                                                <div key={indexImage}>   
															    <img src={thumb}  onLoad={(e) => this_el.imageLoaded(e)} className='hidden' alt="thumb" />
                                                                <div  className="property-image"></div>
                                                                </div>
                                                            )
                                                        })
                                                        :
                                                        <div className="property-image"></div>

                                                    }
                                                    </div>
                                                    <div className="col-8">
                                                        <div className="heading" style={ellipsis}><p>{item.property_tile.title}</p></div>
                                                        <div className="subheading">{item.property_tile.location.location_name}</div>
                                                        <div className="price">{item.booking_amount}</div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                )
                            })}
                            {!this_el.props.request.flag.no_more_result ?
							<div className={'loader text-center'}>
                                <div className="lds-spinner"><div></div><div></div><div></div><div></div><div></div><div></div><div></div><div></div><div></div><div></div><div></div><div></div></div>
							</div>
							:
							<p className='text-center'>No more result!</p>
						    }
                            </div>
                        :
                        <div className="info-window">
                            <div className="window-container">
                                <div className="imgb">
                                    <div className="img" style={{ backgroundImage: "url(" + serverError + ")" }}/>
                                </div>
                                <div className="txtb">
                                    <p>Sorry, our servers are busy. Please try again in a bit.</p>
                                </div>
                                <button className="retry" onClick={this.reload}>Retry</button>
                            </div>
					    </div>
                        }
                        </div>

                    :
                    <div className="info-window">
                    <div className="window-container">
                        <div className="imgb">
                            <div className="img" style={{backgroundImage:'url(' + noInternet + ')'}}></div>
                        </div>
                        <div className="txtb"><p>Oops! You don't seem to be connected to the internet.</p></div>
                        <button className="retry" onClick={this.reload}>Retry</button>
                    </div>
                </div>
                }
            </div>  
        );
    }
}

const mapStateToProps = (store) => {
    return {
        request: store.request,
        tracker: store.tracker.tracker,
        error: store.error
    }
}

const mapDispatchToProps = {
    updateRequestState,
    updateErrorState
}

export default connect(mapStateToProps, mapDispatchToProps)(Archivedlsit);