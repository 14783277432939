import React from 'react';
import {Link} from 'react-router-dom';
import trip_before_login from "../../static/img/infoWindow/trip_before_login.png";
import trip_after_login from "../../static/img/infoWindow/trip-after-login.png";
import noInternet from "../../static/img/infoWindow/noInternet.svg";
import activeImage from "../../static/img/infoWindow/activeImage.png";
import { Element } from 'react-scroll';
import { connect } from 'react-redux';
import serverError from "../../static/img/serverError.png";
//services
import {hasAccessToken, getTripApi,saveUrlLogin,getURLParameter} from '../../services/index.js';
import {trackPageView,trackError} from '../../services/tracker/events.js';

/**
 * Actions
 */
import { updateTripState } from '../../actions/trip/actions';
import { updateErrorState } from '../../actions/error/actions';
import Offlinestatus from '../../components/Offlinestatus';
import { checkIfOffline } from '../../services/helpers';


class Trips extends React.Component {
	constructor(props){
		super(props);
		this.page="trip";
		this.totripDetails = this.totripDetails.bind(this)
		this.getTripApiDataSuccess= this.getTripApiDataSuccess.bind(this);
        this.getTripApiDataError= this.getTripApiDataError.bind(this);
        this.getTripDataApi= this.getTripDataApi.bind(this)
	}
	imageLoaded(e){
		let imgSrc = e.target.src;
		e.target.nextElementSibling.style.backgroundImage = 'url('+imgSrc+')';
	}
	getTripApiDataSuccess(response){
		const this_el = this;
		let flag = Object.assign({}, this_el.props.tripData.flag);
		flag.no_result = (response.data.data.trips.length < 12 ); // if no more result flag found, set no_result to true
		flag.api.is_loader = false;
		let properties = Object.assign([], this_el.props.tripData.trip_list);
	
		Array.prototype.push.apply(properties,  response.data.data.trips); 
		this_el.props.updateTripState({
			'trip_list': properties,
			'past_trip_count': response.data.data.past_trip_count,
			'api': response.data.data.trips.length === 0 && response.data.data.past_trip_count === 0 ? true : false,				
			'activeImg': response.data.data.trips.length === 0 && response.data.data.past_trip_count > 0 ? true : false,
			dataLoaded: true,
			flag:flag
		}).then(() =>{
			let error_flag = Object.assign({}, this_el.props.error.flag);
			error_flag.network = false;
			error_flag.server =false;
			this_el.props.updateErrorState({
				flag: error_flag
			});
		});
    }
    getTripDataApi(args){
        let this_el = this;
        getTripApi(args).then(function(response){
            // success
            this_el.getTripApiDataSuccess(response);
        }).catch(function(error){
            // error
            this_el.getTripApiDataError(error);
        });
    }
    
    getTripApiDataError(error){
        const this_el = this;
         //console.log(error);
         if(typeof error.response !== 'undefined' && error.response.status === 1000){
            let error_flag = Object.assign({}, this_el.props.error.flag);
            error_flag.network = true;

            // updating network state
            this_el.props.updateErrorState({
                flag: error_flag
            });
        }else if(error.response.status === 500){
			//console.log('dgdg',error);
			let flag =  this.props.error.flag;
			flag.server = true; 
			this.props.updateErrorState({ flag: flag });
			
		}else{
            trackError(error);
        }
    }

	componentWillUnmount() {
		this.props.updateTripState({
			'trips_scroll': window.pageYOffset
		})
	}
	componentWillMount(){
		const this_el= this;
		// if(!this_el.props.request.RdataLoaded){
		// 	this_el.submitParams();
		// }
		if(hasAccessToken()){
			if (!this.props.tripData.dataLoaded || this.props.error.flag.network) {
				this_el.submitParams();
			}		
		}
		let flag = Object.assign({}, this.props.tripData.flag);
		flag.api.is_loader =  false;
		this.props.updateTripState({
			flag:flag
		})
		
		/**
		 * Active scroll listner
		 */
		this_el.loadMoreResults();
	}
	componentDidMount(){
		if (checkIfOffline()) {
			this.props.updateErrorState({ flag: { network: true } });
		} else {
			this.props.updateErrorState({ flag: { network: false } });
		}

		trackPageView(this.props.tracker, this.page);

		this.props.updateTripState({
			'goBack': false
		})

		window.scrollTo(0, this.props.tripData.trips_scroll);
	}

	submitParams(){
		const this_el = this;
		let args = {
			past:'?for=web&past=' + 0,
			total:12,
			offset:this_el.props.tripData.Roffset,
			loader: true,
		}
		if(hasAccessToken()){
			this.getTripDataApi(args);
		}
	}
	loadMoreResults(){
		const this_el = this;
		
		window.onscroll = function(){
			
			let trip_list =false;
            if (document.getElementById('trip_list') !== null){
                trip_list = true;
            }else {
                trip_list = false;
            }
			if(trip_list){
			//let offset = document.documentElement.scrollTop;
			const scrollTopOffset = Math.max(window.pageYOffset, document.documentElement.scrollTop, document.body.scrollTop);

			let windowHeight = window.innerHeight;
			let bodyHeight = document.body.clientHeight-500; // was 260
			let offsetWindowHeight = windowHeight + scrollTopOffset;
			if ((offsetWindowHeight >= bodyHeight) && !this_el.props.tripData.flag.api.is_loader && !this_el.props.tripData.flag.no_result){

				/**
				 * Updating api state to true, so in case of scroll no more api hit called, untill last api result popup
				 */

				let new_offset = this_el.props.tripData.Roffset + 12;
				let flag = Object.assign({}, this_el.props.tripData.flag);
				flag.api.is_loader = true;

                this_el.props.updateTripState({
					Roffset : new_offset,
					flag : flag
				}).then(() => {
					this_el.submitParams();
				});

			}
			}
		}
    }
	

	reload = () => {
		const this_el = this;
		let args = {
			past:'?for=web&past=' + 0,
			total:12,
			offset:this_el.props.tripData.Roffset,
			loader: true,
		}
		this.getTripDataApi(args);		
    }
	
	totripDetails(data){
		if (!checkIfOffline()) {
			this.props.updateTripState({
				tripHash: data.request_hash_id
			})
		}
		this.props.history.push({
			pathname: '/trip/'+data.request_hash_id
		});
		this.props.updateTripState({
			'goBack': true,
			'property_images': data.property_tile.property_images,
			'title': data.property_tile.title,
			'location_name': data.property_tile.location.location_name,
		})
	}

	render() {
		const ellipsis = {
			textOverflow: 'ellipsis',
			overflow:'hidden',
			WebkitBoxOrient: 'vertical'
		}

		const this_el = this;
		let fromWallet = false;
		if(this.props.location.search.includes('fromWallet')){
			fromWallet = getURLParameter(this.props.location.search, 'fromWallet');
		} else {
			fromWallet = false;
		}

		return (
			<div  className={fromWallet ? "trip-container fromWallet" : "trip-container"} id="trip_list">
				<header className="header active">
					<div className="row">
						<div className="col-12 text-left">
							{fromWallet &&
								<span className="back-icon" onClick={() => this.props.history.goBack()}></span>
							}
							<span className="middle-text text-center">Trips</span>
						</div>                                                      
					</div>
				</header>
				{!this.props.error.flag.network || this.props.tripData.dataLoaded ?
				<div>
				{!this.props.error.flag.server ?
				<div>
				<Offlinestatus
					offline={checkIfOffline()}
				/>
				{hasAccessToken()
					?
					<div>
					{this.props.tripData.trip_list.length > 0 ?
					
					<div className="trip">
						{this.props.tripData.trip_list.map(function(item, index){
								return(
									<Element className="element" id={item.request_hash_id} key={index}>
										<div  onClick={() => this_el.totripDetails(item)} >
											<div className="booking-tile">
												<div className="response">
													<div className="row">
														<div className="col-6">
															<div className={'text '+ item.booking_status.class}>{item.booking_status.text}</div>
														</div>
														<div className="col-6 text-right">
															{item.booking_status.class !== 'canceled' &&
																<div className="timeline-text">
																{item.timeline_status === "Ongoing" ?
																	<span className="ongoing-blink"></span>
																	:
																	<span>{item.timeline_status}</span>
																}
																</div>
															}
														</div>
													</div>
												</div>
												<div className="tile">
													<div className="row">
														<div className="col-4">
														{item.property_tile.property_images.length > 0 ?
															item.property_tile.property_images.map(function(imageItem, indexImage){
																let thumb = (imageItem.image).replace('2x', 'thumb');
																return(
																	<div key={indexImage}>
																	<img src={thumb} onLoad={(e) => this_el.imageLoaded(e)} className='hidden' alt="thumb" />
																	<div  className="property-image"></div>
																	</div>
																)
															})
															:
															<div className="property-image"></div>

														}
														</div>
														<div className="col-8">
															<div className="heading" style={ellipsis}><p>{item.property_tile.title}</p></div>
															<div className="subheading">{item.checkin_checkout}</div>
															<div className="price">{item.guests} {item.guests > 1 ? 'Guests' : 'Guest'}</div>
														</div>
													</div>
												</div>
											</div>
										</div>
									</Element>
								)
							})}
							{!this_el.props.tripData.flag.no_result &&
								<div className={'loader text-center'}>
									<div className="lds-spinner"><div></div><div></div><div></div><div></div><div></div><div></div><div></div><div></div><div></div><div></div><div></div><div></div></div>
								</div>
							}
					</div>
					:
					<div>
					{this.props.tripData.activeImg &&
						<div className="active-request">
							<div className="active-container">
								<div className="imgb">
									<div className="img" style={{backgroundImage:'url(' + activeImage + ')'}}></div>
								</div>
								<div className="txtb"><p>Oops! You don't have any upcoming trips.</p></div>
							</div>
						</div>
						}
					</div>
					}
					{this.props.tripData.api &&
					<div className="info-window afterlogin">
						<div className="window-container">
							<div className="imgb">
							   <div className="img" style={{backgroundImage:'url(' + trip_after_login + ')'}}></div>
							</div>
							<div className="txtb"> <p>You don't seem to have any upcoming trips.</p></div>
						</div>
					</div>
					}
					{this.props.tripData.past_trip_count > 0 && this_el.props.tripData.flag.no_result &&
					<div className="row">
						<div className="col-12 text-center">
							<Link to="/past/list" className="button">{this.props.tripData.past_trip_count} past trips</Link>
						</div>
					</div>
					}
					</div>
					:
					<div className="info-window">
						<div className="window-container">
							<div className="imgb">
							   <div className="img" style={{backgroundImage:'url(' + trip_before_login + ')'}}></div>
							</div>
							<div className="txtb"><p>Hi! Log in below to see your upcoming trips</p></div>
							<Link to="/signup" onClick={saveUrlLogin}><div className="btn login">Login</div></Link>
						</div>
					</div>
				}
				</div>
				:
					<div className="info-window">
						<div className="window-container">
							<div className="imgb">
								<div className="img" style={{ backgroundImage: "url(" + serverError + ")" }}/>
							</div>
							<div className="txtb">
								<p>Sorry, our servers are busy. Please try again in a bit.</p>
							</div>
							<button className="retry" onClick={this.reload}>Retry</button>
						</div>
					</div>
				}
				</div>
				:
				<div className="info-window">
	                <div className="window-container">
	                    <div className="imgb">
	                        <div className="img" style={{backgroundImage:'url(' + noInternet + ')'}}></div>
	                    </div>
	                    <div className="txtb"><p>Oops! You don't seem to be connected to the internet.</p></div>
	                    <button className="retry" onClick={this.reload}>Retry</button>
	                </div>
	            </div>
	        	}

			</div>
		);
	}
}

const mapStateToProps = (store) => {
	return {
		tripData: store.tripData,
		tracker: store.tracker.tracker,
		error: store.error
	}
}

const mapDispatchToProps = {
	updateTripState,
	updateErrorState
}

export default connect(mapStateToProps, mapDispatchToProps)(Trips);