import { PROPERTY_UPDATE_STATE, PROPERTY_UPDATE_STATE_RESET } from "./vars";
export const updatePropertyState = (obj = {} ,reset = 0) => dispatch => {


    if(!reset){
        dispatch({
            type: PROPERTY_UPDATE_STATE,
            payload: obj
        });
    }else{
		dispatch({
			type: PROPERTY_UPDATE_STATE_RESET
		});
    }
    return Promise.resolve();
};
