import React from 'react';
import Modal from 'react-modal';

class ProfessionModal extends React.Component {
   constructor(props) {
      super(props);
        this.state = {
          'profession':props.profession,
          'errorMsg':false,
          //'professionClose':props.professionModal
        }
        this.professionChange = this.professionChange.bind(this);
        this.saveProfession =this.saveProfession.bind(this);
        this.afterOpenModal =this.afterOpenModal.bind(this);

        //console.log(this.state.gender + " : " + props.gender);
    }

   
    professionChange(value) {
      this.setState({
            'profession': value.trimLeft()
     })
    }
     afterOpenModal(){
      let this_el = this;
      this.setState({
            'profession': this_el.props.profession,
            errorMsg:false,
      });
    }
    hideErroMsg=()=>{
      this.setState({
        errorMsg:false
      })
    }
    saveProfession(e){
      e.preventDefault();
      if(this.state.profession.length> 0){
        this.props.saveProfession(this.state.profession);
        this.props.goBack();
      }else{
        this.setState({
          errorMsg:true
        })
        
      }
      
      //console.log('status' + this.state.profession)
    }
  render() {
    return (
      <div>
        <Modal
          isOpen={this.props.professionModalOpen}
          onAfterOpen={this.afterOpenModal}
          contentLabel="Modal"
                overlayClassName={{
                base: 'overlay-modal overlay-popup',
                afterOpen: '',
                beforeClose: ''
              }}
          className={{
                base: 'content-modal',
                afterOpen: 'editProfile',
                beforeClose: ''
              }}

        >
          <div className="modal-body text-center">
           <a className="back-btn" onClick={this.props.goBack}> </a>
              <div className="verify-text1 text-left">Please tell us your profession</div>
              <form class="editprofile_form" onSubmit={(e) => this.saveProfession(e)} >
              <input type="text"className="input-box" placeholder="Your profession" value={this.state.profession} onFocus={() => this.hideErroMsg()} onChange={ e => this.professionChange(e.target.value)}>
              </input>
              {
                this.state.errorMsg &&
                <span className="error">Field can not be empty</span>
              }
              
              <button type="submit" className="link green">Done</button>
              </form>
          </div>
        </Modal>
      </div>
    );
  }
}

export default ProfessionModal;