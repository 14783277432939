

/* APIs */
// get signup data
let getVarifyEmailApi = function (data, args)
{
    console.log('email', args.email)
    return data.AXIOS.post(data.BASE_URL_V+'/user/verify/email', data.QS.stringify({
            email: args.email,
        }), {
            headers: {
                'Accept': 'application/json',
                'Content-Type': 'application/x-www-form-urlencoded',
                'device-unique-id': data.DEVICE_UNIQUE_ID,
                'Authorization' :'Bearer ' + data.ACCESS_TOKEN,
            }
        }
    );
}

exports.getVarifyEmailApi = getVarifyEmailApi;