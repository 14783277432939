import React from 'react';
import Modal from 'react-modal';

class RequestApprovedModel extends React.Component {
  constructor(props) {
	super(props);
	this.state = {
	  
	}
}
  render() {
	return (
	  <div>
		<Modal
		  isOpen={this.props.requestApprovedOpenModel}
		  onAfterOpen={this.afterOpenModal}
		  onRequestClose={this.props.requestApprovedCloseModel}
		  contentLabel="Modal"
				overlayClassName={{
				base: 'overlay-modal overlay-popup',
				afterOpen: '',
				beforeClose: ''
			  }}
		  className={{
				base: 'content-modal',
				afterOpen: 'editProfile request-modal',
				beforeClose: ''
			  }}
			>
		  <div className="modal-body text-center">
			  <span className="back-btn" onClick={this.props.requestApprovedCloseModel}></span>
			  <div className="verify-text1 text-center">Congratulations</div>
			  <div className="text">Your request has been approved, please confirm your booking by paying the booking amount</div>
			  <button className="link green" onClick={this.props.requestApprovedCloseModel}>Continue</button>
		  </div>
		</Modal>
	  </div>
	);
  }
}

export default RequestApprovedModel;