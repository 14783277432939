import React from 'react';
import Swiper from 'react-id-swiper';
import { hasAccessToken, getWishlistAddDataApi, getWishlistDeleteDataApi, saveUrlLogin } from '../../services/index.js';
import { updateStoredPropertyWishlist, checkIfOffline } from '../../services/helpers.js';
import {trackGoToPropertyPage} from '../../services/tracker/events.js';
import {connect} from 'react-redux';
import {withRouter } from 'react-router';
import {tileRatingColor} from '../../services/index.js';
/**
* Actions
*/
import {updateHomeState} from './../../actions/home/actions';
import { updatePropertyState } from '../../actions/property/actions';
import { updateBookingDataState } from '../../actions/booking-data/actions';
import { updateProfileStateObj } from '../../actions/profile/actions';
import OfflineStatus from '../Offlinestatus'

class RecentlyViewed extends React.Component {
	constructor(props){
		super(props);
		this.seeAll = this.seeAll.bind(this);
		this.updateWishlist = this.updateWishlist.bind(this);
		this.toProperty=this.toProperty.bind(this);

		this.state = {
      offlineStatus: false
    };
	}	
	
	changeOfflineStatus() {
    console.log("Offline");
    this.setState(
      {
        offlineStatus: true
      },
      () => {
        setTimeout(() => {
          this.setState({
            offlineStatus: false
          });
        }, 200);
      }
    );
  }

	/**
	* To Property
	*/
	toProperty(data,itemIndex, source) {
		const this_el = this;

		let flag = this_el.props.home.flag;
		flag.modal.recent_view = false;
		this_el.props.updateHomeState({
			itemIndex: itemIndex,
			flag : flag
		})

		if (data !== undefined) {
			this_el.props.updatePropertyState({
				'property_hash_id': data.property_hash_id,
				'property_images': data.property_images,
				'property_id': data.property_id,
				'property_title': data.property_title,
				'title': data.title,
				'property_score': data.property_score,
				'host_image': data.host_image,
				'host_name': data.host_name,
				'apiData': false,
				'tags': data.property_tags,
				'property_type': data.property_type_name,
			})
			this_el.props.updateBookingDataState({
				'location': data.location.location_name,
				'lat':  data.location.latitude,
				'lng': data.location.longitude,
				checkin_date: 1,
				checkout_date: 1,
				guest_count: 0,
				unit_count: 0
			})

		}

		this_el.props.history.push({
			pathname: '/property/' + data.property_hash_id,
			search: this_el.props.location.search,
		});
			
			
		trackGoToPropertyPage(
			this_el.props.tracker,
			this_el.props.home.page,
			source,
			data.property_hash_id
		);
	}

		
	seeAll(data) {
		const this_el = this;
		this_el.props.updateHomeState({
			itemIndex:data,
			no_more_result:false
		})
		
		if(this.props.myListing !== undefined && this.props.myListing){
			let profileData = Object.assign({}, this_el.props.profile);
			profileData.offset = 0;
		
			this_el.props.updateProfileStateObj(profileData).then(() =>{
				let urlParams = '?user_hash_id='+this_el.props.profile.user_hash_id + '&host_name='+this_el.props.profile.first_name + '&is_host='+this_el.props.profile.is_host;
				this_el.props.history.push({
					pathname: '/myListing',
					search:urlParams
					
				});
			});
		}else{
			this_el.props.updateHomeState({
				recently_viewed_properties_all: this.props.home.recently_viewed_properties
			});
			
			this_el.props.history.push({
				pathname: '/recentlyViewed',
			});
		}		
	}
	
	renderLocation(data) {
		if(data.area !== '' && data.city !== '') {
			return (data.area + ', ' + data.city);
		} else if (data.city !== '') {
			return (data.city);
		} else if(data.state !== '') {
			return (data.state);
		} else {
			return (data.country);
		}
	}
	
	updateWishlist(event, itemIndex) {
		const this_el = this;
		event.stopPropagation();
		if(hasAccessToken()){
			if (!checkIfOffline()) {
				let property_list = this.props.myListing !== undefined && this.props.myListing ? this.props.profile.property_listings : this.props.home.recently_viewed_properties;
			
				if (property_list[event.target.id].is_liked_by_user === 1) {
					// removing property from wishlist
					property_list[event.target.id].is_liked_by_user = 0;
					event.target.className = 'wishlist-icon';
					this_el.getWishlistDeleteApi(event.target.parentNode.parentNode.id);
					updateStoredPropertyWishlist(event.target.parentNode.parentNode.id,0);
				} else {
					// adding property from wishlist
					property_list[event.target.id].is_liked_by_user = 1;
					event.target.className = 'wishlist-icon active';
					this_el.getWishlistApi(event.target.parentNode.parentNode.id);
					updateStoredPropertyWishlist(event.target.parentNode.parentNode.id,1);
				}
				if(this.props.myListing !== undefined && this.props.myListing){
					this_el.props.updateProfileStateObj({ property_listings: property_list })
				} else {
					this_el.props.updateHomeState({ recently_viewed_properties: property_list })
				}
			} else {
				this.changeOfflineStatus()
			}
		}else{
			this_el.props.updateHomeState({
				itemIndex: itemIndex,
			})

			saveUrlLogin();
			sessionStorage.setItem('hashId',event.target.parentNode.parentNode.id);
			this_el.props.history.push('/signup');
		}
	}
	
	getWishlistApi(data){
		let this_el = this;
		getWishlistAddDataApi(data).then(function(response){
			// success
			this_el.getWishlistApiDataSuccess(response);
		}).catch(function(error){
			// error
		});
	}
	getWishlistDeleteApi(data){
		getWishlistDeleteDataApi(data).then(function(response){
			// success
		}).catch(function(error){
			// error
		});
	}
	
	imageLoaded(e){
		let imgSrc = e.target.src;
		e.target.nextElementSibling.style.backgroundImage = 'url('+imgSrc+')';
	}
	
	componentDidMount(){
		let this_el = this;
		let storedHashId = sessionStorage.getItem('hashId');
		if (storedHashId !== null && typeof this_el.refs[storedHashId] !== 'undefined') {
			this_el.getWishlistApi(storedHashId);
			this_el.refs[storedHashId].classList.add('active');
			sessionStorage.removeItem('hashId');
		}

	}
	render() {
		const this_el = this;
		let property_list = this.props.myListing !== undefined && this.props.myListing ? this.props.profile.property_listings : this.props.home.recently_viewed_properties;
		let params;
		if (this.props.myListing !== undefined && this.props.myListing){
			params = {
				slidesPerView: 1.29,
				spaceBetween: 18,
				freeMode: true,
				lazy: true,
				autoHeight:true,
				touchMoveStopPropagation: false,
				slidesOffsetBefore: 15,
				slidesOffsetAfter: 15,
				initialSlide:this_el.props.home.itemIndex,
				noSwiping:false,
				on: {
					slideChange: () => {
						this_el.props.updateHomeState({
							itemIndex:0,
						})
					}
				}
			}
		}else{ 
			params = {
				slidesPerView: 1.54,
				spaceBetween: 18,
				freeMode: true,
				lazy: true,
				touchMoveStopPropagation: false,
				autoHeight:true,
				slidesOffsetBefore: 15,
				slidesOffsetAfter: 15,
				initialSlide:this_el.props.home.itemIndex,
				noSwiping: false,
				on: {
					slideChange: () => {
						this_el.props.updateHomeState({
							itemIndex:0,
						})
					}
				}

			}
		}

		return (
			
			<div>
				<OfflineStatus
          offline={checkIfOffline() && this_el.state.offlineStatus}
          dontClear
        />
				<div className="heading-section">
					{property_list.length > 4 &&
						<span className="see-all right" onClick={() => this.seeAll(0)}>See all</span>
					}
					{this.props.myListing !== undefined && this.props.myListing ?
						<h2 className="heading">Property listings</h2>
						:
						<h2 className="heading">Recently Viewed</h2>
					}
				</div>
				<div className="recent-inner-section">
					{property_list.length === 1
						? params.noSwiping = true
						: params.noSwiping = false
					}

					<Swiper {...params}>
						{property_list.map(function(item, index) {
							return(
								<div className="recent-item-tile" key={index} id={item.property_hash_id} onClick={(e) => this_el.toProperty(item, index, 'recently_view_tile')}>
									{item.property_images.length > 0 &&
										<img data-src={item.property_images[0].image} onLoad={(e) => this_el.imageLoaded(e)} className='hidden swiper-lazy' alt='property' />
									}
									<div className="imgb">
										<span ref={item.property_hash_id} className={item.is_liked_by_user === 1 ? 'wishlist-icon active' : 'wishlist-icon'} id={index} onClick={(e) => this_el.updateWishlist(e,index)}></span>
									</div>
									<div className="row">
										<div className="description">
											<div className="col-10">
												{this_el.props.myListing !== undefined && this_el.props.myListing ?
													<h3>{item.property_type_name} <div className="dot">.</div> {item.accomodation > 0 ? item.accomodation + ' Guests' : item.accomodation +' Guest'}</h3>
													:
													null
												}
												<span className="location">{item.location.location_name}</span>
												<span className="price">{item.prices.price_before_discount}</span>
											</div>
											<div className="col-2">
												{item.property_score > 0 && 
													<span className={"rating pull-right "+ tileRatingColor(item.property_score)}>{item.property_score}</span>
												}
											</div>
										</div>
									</div>
								</div>
							)
						})}
						{property_list.length > 4 &&
							<div className="recent-item-tile see-all-tile" onClick={() => this.seeAll(5)}><span>See all ></span></div>
						}
					</Swiper>
				</div>
				
			</div>
		);
	}
}


const mapStateToProps = store => {
	return {
		home: store.home,
		profile: store.userProfile,
		tracker: store.tracker.tracker
	};
};

const mapDispatchToProps = {
	updateHomeState,
	updatePropertyState,
	updateBookingDataState,
	updateProfileStateObj

};
export default connect(
	mapStateToProps,
	mapDispatchToProps
)(withRouter(RecentlyViewed));
