

/* APIs */
// get home page data
let getLoginAccountDataApi = function (data, args)
{
    if(args !== null){
        let headers = {
                'Accept': 'application/json',
                'Content-Type': 'application/x-www-form-urlencoded',
                'device-unique-id': data.DEVICE_UNIQUE_ID,
        }
        if(data.ACCESS_TOKEN){
            headers.Authorization = 'Bearer ' + data.ACCESS_TOKEN;
        }
        return data.AXIOS.get(data.BASE_URL_V+'/user?user_id=' + args, {
            responseType: 'json',
            headers:headers 
        });
    }else{
         return data.AXIOS.get(data.BASE_URL_V+'/user', {
            responseType: 'json',
            headers: {
                'Accept': 'application/json',
                'Content-Type': 'application/x-www-form-urlencoded',
                'device-unique-id': data.DEVICE_UNIQUE_ID,
                'Authorization' :'Bearer ' + data.ACCESS_TOKEN,
            }
        });
    }
   
}

exports.getLoginAccountDataApi = getLoginAccountDataApi;