import { SET_STATE_PROFILE_OBJ} from '../actions/profile/vars';

const initialState = {
            'first_name':'',
            'last_name': '',
            'member_since':'',
            'dob':'',
            'marital_status': '',
		    'gender': '',
		    'profession': '',
		    'email': '',
		    'is_email_verified':'',
            'property_listings': [],
		    'property_listings_all':[],
            'time': new Date(),
			'dial_code': '',
			'mobile': '',
			'is_mobile_verified':'',
			'facebook_id': '',
			'description': '',
			'guests_served_count': '',
			'trips_count': '',
            'VarifyEmail':false,
            'current_url' : window.location.href,
            'is_user_referred':'',
            'recentViewModal':false,
            'message':'',
            'profile_image':'',
            'picture':'',
			'profile_image_file': '',
            'downloadAppOpen': false,
            'spoken_languages': '',
			'travelled_places': '',
            'apiData':false,
            'button':false,
            'isTop':true,
            'done':'Done',
            'cancel':null,
            'isOpen': false,
            'customHeader':'Please tell us your date of birth?',
			'theme':'android',
			'dateFormat':['DD','MM','YYYY'],
            'showFormat':'DD/M/YYYY',
            'user_id':'',
            'user_hash_id':'',
            'offset':0,
            'element_height' : 0,
            'is_host':0,
            'host_name':'',
            'itemIndex':0,
            'referEarn':false,
            dataLoaded: false,
            extendedDataLoaded: false,
            profilePage:false,
            'flag':{
                'model':{
                    'dateOfBirth':false ,
                    'maritalStatus':false,
                    'genderModal':false,
                    'professionModal':false,
                    'spokenLanguage':false,
                    'travelledPlaces':false,
                    'descriptions':false,
                    
                },
                'api' : {
                    'profile_data' : false,
                    'profile_image' : false,
                    'is_loading': false
                },
                'profile_image_changed' : false,
                'is_default_pic' : false,
                'no_more_result' : false,
                'network':false,
            }
            
}

const userProfile = (state = initialState, action) => {
    switch (action.type) {
        case SET_STATE_PROFILE_OBJ :
            let newState = Object.assign({}, state, action.payload);
            return newState;

        case 'CLEAR_REDUX_STATE':
            return { ...state, dataLoaded: false, extendedDataLoaded: false }
            
        default:
            return state;
    }
}
export default userProfile;