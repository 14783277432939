import React from 'react';
import DocumentMeta from 'react-document-meta';
import noInternet from "../../static/img/infoWindow/noInternet.svg";
import SearchTile from '../../components/propertyTile';
import serverError from "../../static/img/serverError.png";
// css
import '../../static/css/vendors/swiper.css';
import {connect} from 'react-redux';

/**
 * Actions
 */
import { updatePropertyState } from '../../actions/property/actions';
import { updateBookingDataState } from '../../actions/booking-data/actions';
import { updateSingleCollectionState } from '../../actions/single-collection/actions';
import { updateErrorState } from '../../actions/error/actions';
import OfflineStatus from '../../components/Offlinestatus'
import { updateCollectionState } from '../../actions/collection/actions';

//  
import {getCollectionListDataApi} from '../../services/index.js';
import {trackPageView,trackError} from '../../services/tracker/events.js';
import { checkIfOffline } from '../../services/helpers';


class CollectionList extends React.Component {
	constructor(props) {
		super(props);
		this.getColllectionListApiData = this.getColllectionListApiData.bind(this);
		this.toProperty = this.toProperty.bind(this);

		this.state = {
			toggleOfflineStatus: false
		}
	}
	getColllectionListApiDataSuccess(response) {
		const this_el = this;
		// pulling data from API response
		let api_collection_data = response.data.data.collection;
		let api_meta_info = response.data.data.meta;
		
		let flag = Object.assign({}, this_el.props.single_collection.flag);
		flag.no_more_result = (response.data.data.collection.properties.length === 0); // if no more result flag found, set no_more_result to true
		flag.api.is_loading = false;

		let properties = Object.assign([], this_el.props.single_collection.properties);
		
		Array.prototype.push.apply(properties,  response.data.data.collection.properties); 

		// updating single collection store
		this_el.props.updateSingleCollectionState({
			'id': api_collection_data.collection_id,
			'hash_id': api_collection_data.collection_hash_id,
			'image': api_collection_data.collection_image, // collection image
			'title': api_collection_data.collection_title,
			'description': api_collection_data.description,
			'page': 'collection_details',
			'properties': properties,
			'meta': api_meta_info,
			dataLoaded: true,
			flag:flag
		}).then(()=>{
			let error_flag = Object.assign({}, this_el.props.error.flag);
			error_flag.network = false;
			error_flag.server = false;
			this_el.props.updateErrorState({
				flag: error_flag
			});
		})
	}

	getColllectionListApiDataError(error) {
		const this_el = this;
		if(typeof error.response !== 'undefined' && error.response.status === 1000){
			let flag = Object.assign({}, this_el.props.error.flag);
			flag.network = true;
			this_el.props.updateErrorState({
				flag : flag
			});
		}else if(error.response.status === 500){
			//console.log('dgdg',error);
			let flag =  this.props.error.flag;
			flag.server = true; 
			this.props.updateErrorState({ flag: flag });
			
		}else{
			trackError(error);
		}
	}

	getColllectionListApiData(args){
		let this_el = this;
		getCollectionListDataApi(args).then(function(response){ 
			// success
			this_el.getColllectionListApiDataSuccess(response);
		})
		.catch(function(error) {
			// error
			this_el.getColllectionListApiDataError(error);
		});
	}

	toProperty(data) {
        const this_el = this;

        if (data !== undefined) {
            this_el.props.updatePropertyState({
                'property_hash_id': data.property_hash_id,
                'property_images': data.property_images,
                'property_id': data.property_id,
                'property_title': data.property_title,
                'title': data.title,
                'property_score': data.property_score,
                'host_image': data.host_image,
                'host_name': data.host_name,
                'apiData': false,
                'tags': data.property_tags,
                'property_type': data.property_type_name,
            })
            this_el.props.updateBookingDataState({
				'location': data.location.location_name,
				'lat':  data.location.latitude,
				'lng': data.location.longitude,
				checkin_date: 1,
				checkout_date: 1,
				guest_count: 0,
				unit_count: 0

            })
        }

        this_el.props.history.push({
            pathname: '/property/' + data.property_hash_id
        });

    }

	componentDidMount() {
		window.scrollTo(0, 0)

		if (checkIfOffline()) {
			this.props.updateErrorState({
				flag: { network: true }
			});
			this.toggleStatus()
		} else {
			this.props.updateErrorState({ flag: { network: false } });
		}
	}
	componentDidUpdate(prevProps, prevState) {
		const this_el = this;
		let myDiv = this_el.refs.myDiv;
		if (myDiv !== undefined && this_el.props.single_collection.element_height !== myDiv.clientHeight ){
			this_el.props.updateSingleCollectionState({ element_height: myDiv.clientHeight });
	  	}
	  //console.log(this.state.elementHeight)
	}
	componentWillMount() {
		const this_el = this;
		trackPageView(this_el.props.tracker, this_el.props.single_collection.page);
		/**
		 * Calling Api
		 */
		if (!this_el.props.single_collection.dataLoaded && this.props.single_collection.properties.length === 0){
			this_el.submitParams();
		}
		/**
		 * Active scroll listner
		 */
		this_el.loadMoreResults();
	}
	submitParams(){
		console.log('Api Calling')
		const this_el = this;
		let args = {
			id:this_el.props.match.params.hashId,
			offset:this_el.props.single_collection.offset,
			loader: true,
		}
		//console.log(args);
		this_el.getColllectionListApiData(args);
	}
	reload = () =>{
		const this_el = this;
		/**
		 * Calling Api
		 */
		this_el.submitParams();
		/**
		 * Active scroll listner
		 */
		this_el.loadMoreResults();
	}
	loadMoreResults(){
		const this_el = this;
		
		window.onscroll = function(){
			let collection_list =false;
            if (document.getElementById('collection_list') !== null){
                collection_list = true;
            }else {
                collection_list = false;
			}
			if(collection_list){
			//let offset = document.documentElement.scrollTop;
			const scrollTopOffset = Math.max(window.pageYOffset, document.documentElement.scrollTop, document.body.scrollTop);

			let windowHeight = window.innerHeight;
			let bodyHeight = document.body.clientHeight-1260; // was 260
			let offsetWindowHeight = windowHeight + scrollTopOffset;

			if ((offsetWindowHeight >= bodyHeight) && !this_el.props.single_collection.flag.api.is_loading && !this_el.props.single_collection.flag.no_more_result && !checkIfOffline()){

				/**
				 * Updating api state to true, so in case of scroll no more api hit called, untill last api result popup
				 */

				let new_offset = this_el.props.single_collection.offset + 5;
				let flag = Object.assign({}, this_el.props.single_collection.flag);
				flag.api.is_loading = true;

				//console.log((offsetWindowHeight >= bodyHeight) , !this_el.props.collection.flag.api.is_loading);
				this_el.props.updateSingleCollectionState({
					offset : new_offset,
					flag : flag
				}).then(() => {
					this_el.submitParams();
				});

			}
			if ((offsetWindowHeight >= bodyHeight) && checkIfOffline()) {
				this_el.toggleStatus()
			}
			}
		}
	}

	toggleStatus = () => {
		console.log('toggled')
		this.setState({
			toggleOfflineStatus: !this.state.toggleOfflineStatus
		}, () => {
			this.setState({
				toggleOfflineStatus: !this.state.toggleOfflineStatus
			})
		})
	}

	reload = () => {
		this.submitParams();
	}

	updateWishlist = (propertyIndex) => {
		let collectionList = this.props.home.collections;
		let allCollections = this.props.collection.collections
		let parentIndex = this.props.collection.parentIndex;

		if (collectionList.length > 0) {
			if (collectionList[parentIndex].properties[propertyIndex].is_liked_by_user === 1) {
				collectionList[parentIndex].properties[propertyIndex].is_liked_by_user = 0
			} else {
				collectionList[parentIndex].properties[propertyIndex].is_liked_by_user = 1
			}
		}
		if(collectionList.length > 0){
			this.props.updateHomeState({
				collections: collectionList,
			})
		}

		if (allCollections.length > 0) {
			if (allCollections[parentIndex].properties[propertyIndex].is_liked_by_user === 1) {
				allCollections[parentIndex].properties[propertyIndex].is_liked_by_user = 0
			} else {
				allCollections[parentIndex].properties[propertyIndex].is_liked_by_user = 1
			}
		}
		if (allCollections.length > 0) {
			this.props.updateCollectionState({
				collections: allCollections,
			})
		}

		
	}
	render() {
		const this_el = this;	
		const meta = {
	      title: this_el.props.single_collection.meta.meta_title,
	      description: this_el.props.single_collection.meta.meta_desc,
	      canonical: this_el.props.single_collection.meta.canonical_url,
	      meta: {
	        charset: 'utf-8',
	         property:{
	        	'og:site_name':'Guesthouser',
	        	'og:title':this_el.props.single_collection.meta.meta_title,
	        	'og:url':this_el.props.single_collection.meta.canonical_url,
	        	'og:type':'mobile web',
	        	'og:description':this_el.props.single_collection.meta.meta_desc,
	        	'og:image':'https://www.guesthouser.com/images/logo/logo_share.png',
	        },
	        name: {
	          keywords: this_el.props.single_collection.meta.keyword
	        }
	      }
	    };
		return (
			<div className="collection_list" id="collection_list">
			<DocumentMeta {...meta}>
				<div className="collection-list-container">
				<header className="header active">
					<div className="row">
						<div className="col-12 text-left">
							<span className="back-icon" onClick={() => this_el.props.history.goBack()}></span>
							<span className="middle-text text-center">{this_el.props.single_collection.title}</span>
						</div>                                                      
					</div>
				</header>
				{!this_el.props.error.flag.network || this.props.single_collection.dataLoaded ?
					<div>
						{!this.props.error.flag.server ?
						<div>
						<OfflineStatus
							offline={checkIfOffline() && !this.state.toggleOfflineStatus}
							bottom
							dontClear
						/>
						<div className="banner" style={{backgroundImage:'url(' + this_el.props.single_collection.image + ')'}}>
							<div className="innermask"><div className="text">{this_el.props.single_collection.title}</div></div>
						</div>
						<div className="collection-list" ref="myDiv">
							<SearchTile 
							properties_list={this_el.props.single_collection.properties}
							updateWishlistedProperty={this_el.updateWishlist}			
							toProperty={this_el.toProperty}/>
						</div>
						{!this_el.props.single_collection.flag.no_more_result && this_el.props.single_collection.flag.api.is_loading ?
							<div className={'loader'}>
								<div className="timeline-item">
									<div className="animated-background facebook"></div>
								</div>
							</div>
							:
							<p className='text-center'>{!checkIfOffline() && 'No more result!'}</p>
						}
					</div>
					:
					<div className="info-window">
							<div className="window-container">
								<div className="imgb">
									<div className="img" style={{ backgroundImage: "url(" + serverError + ")" }}/>
								</div>
								<div className="txtb">
									<p>Sorry, our servers are busy. Please try again in a bit.</p>
								</div>
								<button className="retry" onClick={this.reload}>Retry</button>
							</div>
						</div>
					}
					</div>
				:
					<div className="info-window">
		                <div className="window-container">
		                    <div className="imgb">
		                        <div className="img" style={{backgroundImage:'url(' + noInternet + ')'}}></div>
		                    </div>
		                    <div className="txtb"><p>Oops! You don't seem to be connected to the internet.</p></div>
		                    <button className="retry" onClick={this.reload}>Retry</button>
		                </div>
		            </div>
				}
				</div>
						
			</DocumentMeta>
			</div>
		);
	}
}
const mapStateToProps = (store) => {
	return {
		tracker : store.tracker.tracker,	
		single_collection : store.single_collection,
		error : store.error,
		home:store.home,
		collection: store.collection

    }
}
const mapDispatchToProps = {
	updateSingleCollectionState,
	updatePropertyState,
	updateBookingDataState,
	updateErrorState,
	updateCollectionState
}
export default connect(mapStateToProps, mapDispatchToProps) (CollectionList);