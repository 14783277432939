
import React from 'react';
import Swiper from 'react-id-swiper';
import {hasAccessToken, getWishlistAddDataApi, getWishlistDeleteDataApi, tileRatingColor, saveUrlLogin} from '../../services/index.js';
//import { trackGoToSimilarListingModal, trackGoToPropertyPage} from '../../services/tracker/events.js';
import { connect } from 'react-redux';
import { withRouter } from 'react-router';
import { updatePropertyState } from '../../actions/property/actions';
import { updateStoredPropertyWishlist } from '../../services/helpers';

class SimilarListing extends React.Component{
	constructor(props){
		super(props);
		this.toggleActiveClass = this.toggleActiveClass.bind(this);
		this.signup = this.signup.bind(this);
		this.seeAll = this.seeAll.bind(this);
		this.goBack = this.goBack.bind(this);
	}

	updateSimilarListWishlist = (id, status) => {
		let storedData = JSON.parse(sessionStorage.getItem('storedPropertyList'));
		let subResult = undefined;
		if (storedData !== null) {
			let result = storedData.find(obj => {
				return obj.data.data.property_hash_id === this.props.match.params.hashId && obj.pUrl === this.props.location.search;
			})
			if (result !== undefined) {
				subResult = result.data.data.similar_properties.find(obj => {
					return obj.property_hash_id === id;
				})
				
			}
			if (subResult !== undefined) {
				subResult.is_liked_by_user = status
			}

			let index = storedData.indexOf(result);
			if (~index) {
				storedData.splice(index, 1, result);
			}
			sessionStorage.setItem('storedPropertyList', JSON.stringify(storedData));
		}

	}

	toggleActiveClass(event, index, hash) {
		const this_el = this;
		event.preventDefault();
		event.stopPropagation();
		if(hasAccessToken()){
			if(event.target.className.indexOf('active') > -1 ){
				event.target.className = 'wishlist-icon';
				this_el.getWishlistDeleteApi(event.target.parentNode.parentNode.id);
				this.updateSimilarListWishlist(event.target.parentNode.parentNode.id, 0);
				updateStoredPropertyWishlist(event.target.parentNode.parentNode.id, 0);
			} else {
				event.target.className = 'wishlist-icon active';
				this_el.getWishlistApi(event.target.parentNode.parentNode.id);
				this.updateSimilarListWishlist(event.target.parentNode.parentNode.id, 1);
				updateStoredPropertyWishlist(event.target.parentNode.parentNode.id, 1);

			}
		}else{
			this.props.updatePropertyState({
				indexList: this.props.property.indexList.filter(x => x.hash === hash).length > 0
					? this.props.property.indexList.map((x) => {
						if (x.hash === hash) {
							return { hash, index }
						} else {
							return x
						}
					})
					: [...this.props.property.indexList, { hash, index }]
			})

			saveUrlLogin();
			sessionStorage.setItem('hashId',event.target.parentNode.parentNode.id);
			this_el.signup();
		}
	}

	// get wishlist items
	getWishlistApiDataSuccess(response) {

	}

	getWishlistApiDataError(error) {
	   console.log('error', error);
	}

	getWishlistApi(data){
		let this_el = this;
		getWishlistAddDataApi(data).then(function(response){
			// success
			this_el.getWishlistApiDataSuccess(response);
		}).catch(function(error){
			// error
			this_el.getWishlistApiDataError(error);
		});
	}

	// delete wishlist items
	getWishlistDeleteDataSuccess(response) {

	}

	getWishlistDeleteDataError(error) {
	   console.log('error', error);
	}

	getWishlistDeleteApi(data){
		let this_el = this;
		getWishlistDeleteDataApi(data).then(function(response){
			// success
			this_el.getWishlistDeleteDataSuccess(response);
		}).catch(function(error){
			// error
			this_el.getWishlistDeleteDataError(error);
		});
	}

	imageLoaded(e){
		let imgSrc = e.target.src;
		e.target.nextElementSibling.style.backgroundImage = 'url('+imgSrc+')';
	}

	signup() {
		this.props.history.push('/signup');
	}
	seeAll(topSeeAll) {
		const this_el = this;
		let flag = Object.assign({}, this_el.props.property.flag);
		flag.new_similar_list = true;
		flag.no_more_result = false;
		this_el.props.updatePropertyState({
			flag:flag,
			offset:0,
			similar_properties_all: this.props.similar_properties_all.length === 0 ? this.props.similar_properties : this.props.similar_properties_all,
			...(
				topSeeAll 
					? {indexList: [...this.props.property.indexList.filter(x => x.hash !== this.props.property.property_hash_id), { hash: this.props.property.property_hash_id, index: 0 }]} 
					: {indexList: [...this.props.property.indexList.filter(x => x.hash !== this.props.property.property_hash_id), { hash: this.props.property.property_hash_id, index: 5 }]} 
			)
		}).then(() =>{
			this.props.history.push({
				pathname: '/similarListing/'+this.props.property.property_hash_id,
				search: this.props.location.search,
			});
		
		});
	}
	goBack(){
        this.props.history.goBack();
	}
	
	componentDidMount(){
		const this_el = this;
		let storedHashId = sessionStorage.getItem('hashId');
		if (storedHashId !== null && typeof this_el.refs[storedHashId] !== 'undefined') {
			this_el.getWishlistApi(storedHashId);
			this_el.refs[storedHashId].classList.add('active');
			sessionStorage.removeItem('hashId');
		}

	}

	render(){
	   const params = {
		slidesPerView: 1.34,
		spaceBetween: 18,
		freeMode: true,
		autoHeight:true,
		rebuildOnUpdate:true,
		lazy:true,
		touchMoveStopPropagation: false,
		noSwiping:false,
		initialSlide: this.props.property.indexList.filter(x => x.hash === this.props.property.property_hash_id).length > 0
			? this.props.property.indexList.filter(x => x.hash === this.props.property.property_hash_id)[0].index
			: 0
		}
		const this_el = this;
		return(
			<div>
			{this.props.similar_properties !== undefined && this.props.similar_properties.length > 0 &&
			<section className="similar-listing clearfix">
				<div className="heading-section">
					{this.props.similar_properties.length > 4 &&
						<span className="see-all right" onClick={() => this.seeAll(true)}>See all</span>
					}
					<h3>Similar listing</h3>
				</div>
				
				<div className="recent-inner-section">
					{this.props.similar_properties.length === 1
						? params.noSwiping = true
						: params.noSwiping = false
					}
				   <Swiper {...params}>
				   		{this.props.similar_properties.map(function(item, index) {
				   			return(
								<div key={index} className="recent-item-tile" id={item.property_hash_id} onClick={() => this_el.props.toProperty(item, index, this_el.props.property.property_hash_id)}>
								{item.property_images.length > 0 &&
									<img  src={item.property_images[0].image} onLoad={(e) => this_el.imageLoaded(e)} className='hidden' alt="property" />
								}
								<div className="imgb">
									<span ref={item.property_hash_id} className={item.is_liked_by_user === 1 ? 'wishlist-icon active' : 'wishlist-icon'} onClick={(e) => this_el.toggleActiveClass(e, index, this_el.props.property.property_hash_id)}></span>
								</div>
								<div className="description">
									<div className="row">
										<div className="col-10 pull-left">
											<h3>{item.property_type_name}<span className="dot-separation"></span>{item.accomodation}  {item.accomodation > 1 ? 'Guests' : 'Guest'}</h3>
											<span className="location">
												{item.location.location_name}
											</span>
											<span className="price">{item.prices.price_after_discount.toLocaleString('en')}</span>
										</div>
										<div className="col-2 pull-left">
											{item.property_score > 0 && <span className={"tile-rating pull-right " + tileRatingColor(item.property_score)}>{item.property_score}</span>}
										</div>
									</div>
								</div>
							</div>
							
							)
						   }
						   )}
						   {this_el.props.similar_properties.length > 4 &&
						   	<div className="recent-item-tile see-all-tile" onClick={_ => this.seeAll()}><span>See all ></span></div>
						   }
					</Swiper>
					
				</div>
				
			</section>
			
			}
			</div>
		);
	}
}

const mapStateToProps = (store) => {
	return {
		similar_properties : store.property.similar_properties,
		similar_properties_all:store.property.similar_properties_all,
		property:store.property
	}
}
const mapDispatchToProps = {
	updatePropertyState,
}
export default connect(mapStateToProps, mapDispatchToProps)(withRouter(SimilarListing));