import React from 'react';
import ViewInvoice from '../../components/modal/viewInvoice/';
import CancelModal from "../../components/modal/cancelModal/";
import CanceledSuccess from "../../components/modal/cancelModal/canceledSuccess.js";
import {getTripDetailApi} from '../../services/index.js';
import CancelPopUpTrip from "../../components/modal/cancelModal/cancelPopupTrip.js";
import noInternet from "../../static/img/infoWindow/noInternet.svg";
import {addClassToHtml, hasAccessToken, removeClassToHtml, getURLParameter} from '../../services/index.js';
import {trackPageView, trackTripDetailsView,trackError} from '../../services/tracker/events.js';
import { connect } from 'react-redux';
import serverError from "../../static/img/serverError.png";
/**
 * Actions
 */
import { updateTripState } from '../../actions/trip/actions';
import { updateErrorState } from '../../actions/error/actions';
import { updatePropertyState } from '../../actions/property/actions';
import { updateBookingDataState } from '../../actions/booking-data/actions';
import OfflineStatus from '../../components/Offlinestatus';
import { checkIfOffline } from '../../services/helpers';

class TripDetails extends React.Component {
	constructor(props) {
		super(props);

		this.page="trip_details";
		this.state = {
			'invoiceModal':false,
			'cancelMessage':'',
			'cancelModal':false,
			'cancelPopUp':false,
			'canceledSuccess':false,
			'trip':true,
			toggleOfflineStatus: false
		}
		this.viewInvoiceOpen = this.viewInvoiceOpen.bind(this);
		this.makePayment = this.makePayment.bind(this);
		this.cancelModalOpen = this.cancelModalOpen.bind(this);
		this.cancelPopUpOpen=this.cancelPopUpOpen.bind(this);
		this.cancelRequest = this.cancelRequest.bind(this);
		this.reload=this.reload.bind(this);
		this.getTripDetailDataApi=this.getTripDetailDataApi.bind(this);
		this.canceledSuccessOpen = this.canceledSuccessOpen.bind(this);
		this.canceledSuccessClose = this.canceledSuccessClose.bind(this);
		this.goBack =  this.goBack.bind(this);
		this.toProperty = this.toProperty.bind(this);
	}
	getTripDetailDataApi(data){
		let this_el = this;
		getTripDetailApi(data).then(function (response) {
			this_el.props.updateTripState({
				'tile': response.data.data.property_section.tile,
				'title': response.data.data.property_section.tile.title,
				'location_name': response.data.data.property_section.tile.location.location_name,
				'property_images': response.data.data.property_section.tile.property_images,
				'request_hash_id': response.data.data.booking_info_section.info.request_hash_id,
				'info': response.data.data.booking_info_section.info,
				'invoice_section': response.data.data.invoice_section,
				'invoice_header': response.data.data.invoice_section.invoice_header,
				'invoice_middle': response.data.data.invoice_section.invoice_middle,
				'invoice_footer': response.data.data.invoice_section.invoice_footer,
				'booking_status': response.data.data.booking_info_section.info.booking_status,
				'location': response.data.data.property_section.tile.location,
				'cancellable': response.data.data.cancellation_section.cancellable,
				'cancellation_reasons': response.data.data.cancellation_section.cancellation_reasons,
				'cancellation_policy_info': response.data.data.cancellation_section.cancellation_policy_info,
				'booking_info_section': response.data.data.booking_info_section,
				'property_info_section': response.data.data.property_info_section,
				'webicon': response.data.data.booking_info_section.booking_amount_info.currency.webicon,
				'booking_amount_info': response.data.data.booking_info_section.booking_amount_info,
				'cancel_text': response.data.data.cancellation_section.cancellation_policy_info.cancel_text,
				'apiData': true,
				'viewInvoice': response.data.data.booking_info_section.info.booking_status.class !== 'canceled' ? true : false,
				tripDataLoaded: true,
				tripHash: this_el.props.match.params.hashId
			}).then( () => {
				let payment = getURLParameter(this_el.props.location.search, 'payment');
				let from_payment = false;
				if (payment === "true") {
					// CURRENTLY REMOVING ALL QUERY STRING,ONLY PAYMENT FLAG CAN BE REMOVED
					// IN FUTRUE
					this_el.props.history.replace({ search: '' });

					let payment_completed = sessionStorage.getItem('payment_completed') || [];
					try {
						payment_completed = JSON.parse(payment_completed);
						if (!Array.isArray(payment_completed)) {
							payment_completed = [];
						}
					}
					catch (e) {
						payment_completed = [];
					}
					if (!payment_completed.includes(this_el.props.tripDetailData.booking_info_section.info.request_hash_id)) {
						from_payment = true;
						payment_completed.push(this_el.props.tripDetailData.booking_info_section.info.request_hash_id)
						sessionStorage.setItem('payment_completed', JSON.stringify(payment_completed));
					}

				}
				var trip_data = {
					// using YYYY-MM-DD
					checkin: this_el.props.tripDetailData.info.checkin,
					checkout: this_el.props.tripDetailData.info.checkout,
					pid: this_el.props.tripDetailData.tile.property_id,
					hpid: this_el.props.tripDetailData.tile.property_hash_id,
					hbrid: this_el.props.tripDetailData.booking_info_section.info.request_hash_id,
					city: this_el.props.tripDetailData.location.city,
					state: this_el.props.tripDetailData.location.state,
					country: this_el.props.tripDetailData.location.country.ccode,
					country_name: this_el.props.tripDetailData.location.country.name,
					guests: this_el.props.tripDetailData.info.guests > 0 ? this_el.props.tripDetailData.info.guests : 1,
					lat: this_el.props.tripDetailData.location.latitude,
					long: this_el.props.tripDetailData.location.longitude,
					area: this_el.props.tripDetailData.location.area,
					location: this_el.props.tripDetailData.location.location_name,
					payment_page: from_payment,
					payable_amount: this_el.props.tripDetailData.booking_info_section.booking_amount_info.total_amount_unformatted,
					payment_currency: this_el.props.tripDetailData.booking_info_section.booking_amount_info.currency.iso_code,
					instant: this_el.props.tripDetailData.booking_info_section.info.instant,
					coupon_code_used: this_el.props.tripDetailData.booking_info_section.info.coupon_code_used,
					wallet_money_used: this_el.props.tripDetailData.booking_info_section.info.wallet_money_used,
					properties: [{
						hpid: this_el.props.tripDetailData.tile.property_hash_id, title: this_el.props.tripDetailData.title,
						price: this_el.props.tripDetailData.booking_info_section.booking_amount_info.total_amount_unformatted
					}]
				};
				trackTripDetailsView(this_el.props.tracker, this_el.page, 'direct', trip_data);
				let error_flag = Object.assign({}, this_el.props.error.flag);
				error_flag.network = false;
				error_flag.server = false;
				// updating network state
				this_el.props.updateErrorState({
					flag: error_flag
				});

			});
		}).catch(function (error) {
			if(typeof error.response !== 'undefined' && error.response.status === 1000){
				let error_flag = Object.assign({}, this_el.props.error.flag);
				error_flag.network = true;

				// updating network state
				this_el.props.updateErrorState({
					flag: error_flag
				});
            }else if(error.response.status === 500){
				//console.log('dgdg',error);
				let flag =  this.props.error.flag;
				flag.server = true; 
				this.props.updateErrorState({ flag: flag });
				
			}else{
                trackError(error);
            }
		});
	}

	toggleStatus = () => {
		this.setState({
			toggleOfflineStatus: !this.state.toggleOfflineStatus
		}, () => {
			this.setState({
				toggleOfflineStatus: !this.state.toggleOfflineStatus
			})
		})
	}

	componentDidMount(){
		if(hasAccessToken()){
			this.getTripDetailDataApi(this.props.match.params.hashId);
		}
		if (checkIfOffline()) {
			this.props.updateErrorState({ flag: { network: true }  })
		} else {
			this.props.updateErrorState({ flag: { network: false }  })
		}
		window.scrollTo(0, 0);
    	const this_el = this;
        window.onpopstate = function(e) {
            this_el.modalRouting(e.currentTarget.location.hash);
        };
        this.modalRouting(window.location.hash);
        trackPageView(this.props.tracker, this.page);
	}


	makePayment(){
		if (checkIfOffline()) {
			this.toggleStatus()
			return
		}
		if(window.location.port){
			window.location.href = this.props.tripDetailData.booking_amount_info.pending_payment_url + '?source=web&origin='+ window.location.protocol+'//'+window.location.hostname+':'+window.location.port;
		}else{
			window.location.href = this.props.tripDetailData.booking_amount_info.pending_payment_url + '?source=web&origin='+ window.location.protocol+'//'+ window.location.hostname;
		}
	}
	reload(){
		if(hasAccessToken()){
			this.getTripDetailDataApi(this.props.match.params.hashId);
		}
    }
	cancelRequest(){
    	this.getTripDetailDataApi(this.props.match.params.hashId);
    }


    modalRouting(hash){
        addClassToHtml();
        switch(hash){
            case '#invoiceModal':
                this.setState({invoiceModal: true});
                break;

			case '#cancelPopUp':
				this.setState({cancelPopUp: true,cancelModal: false});
				break;

			case '#cancelModal':
				this.setState({cancelModal: true,cancelPopUp:false});
				break;

            default:
                this.setState({
                	invoiceModal: false,
                	cancelPopUp:false,
                	cancelModal:false,
                });
                removeClassToHtml();
        }
    }
	viewInvoiceOpen() {
		window.location.hash = 'invoiceModal';
	}

	cancelPopUpOpen(){
		window.location.hash = 'cancelPopUp';
	}

	cancelModalOpen(){
		window.location.replace('#cancelModal')
	}

	canceledSuccessOpen(messege){
		this.setState({
			cancelMessage:messege,
			canceledSuccess:true,
		});
	}
	canceledSuccessClose(){
		this.setState({
			canceledSuccess:false
		}, function(){
			this.goBack();
		});
	}

	goBack(){
		if(this.props.tripDetailData.goBack){
			this.props.history.goBack();
		} else {
			this.props.history.push('/trips')		
		}
	}

	toProperty() {
		this.props.updatePropertyState({
			'property_hash_id': this.props.tripDetailData.tile.property_hash_id,
			'property_images': this.props.tripDetailData.tile.property_images,
			'property_id': this.props.tripDetailData.tile.property_id,
			'title': this.props.tripDetailData.tile.title,
			'host_name': this.props.tripDetailData.property_info_section.host_name,
			'apiData': false,
			'property_type': this.props.tripDetailData.tile.property_type,
		})
		this.props.updateBookingDataState({
			'location': this.props.tripDetailData.tile.location.location_name,
			'lat':  this.props.tripDetailData.tile.location.latitude,
			'lng': this.props.tripDetailData.tile.location.longitude,
			checkin_date: 1,
			checkout_date: 1,
			guest_count: 0,
			unit_count: 0

		})

		this.props.history.push({
			pathname: '/property/' + this.props.tripDetailData.tile.property_hash_id,
		});

	}

	render() {
		return (
			<div className="booking-details afterlogin trip-detail">
				<header className="header active">
					<div className="row">
						<div className="col-12 text-left">
							<span className="back-icon" onClick={this.goBack}></span>
							<span className="middle-text text-center">Trip details</span>
						</div>                                                      
					</div>
				</header>  
				{
					(!this.props.error.flag.network || this.props.tripDetailData.tripDataLoaded)
					&& (!this.props.tripDetailData.apiData || this.props.tripDetailData.tripHash === this.props.match.params.hashId) ?
				<div>
					{!this.props.error.flag.server ?
				    <div>
				<OfflineStatus
					offline={checkIfOffline() && !this.state.toggleOfflineStatus}
					bottom
				/>
				<div className="booking-property-details">
					<span className="property-link" onClick={this.toProperty}></span>
				   	<div className="row">
						<div className="col-4">
							{this.props.tripDetailData.property_images.length > 0 ?
								<div className="property-image" style={{ backgroundImage: 'url(' + this.props.tripDetailData.property_images[0].image +')'}}></div>
							:
								<div className="property-image"></div>
							}
						</div>
						<div className="col-8">
							<div className="heading" style={{WebkitBoxOrient: 'vertical'}}>{this.props.tripDetailData.title}</div>
							<div className="subheading" style={{WebkitBoxOrient: 'vertical'}}>{this.props.tripDetailData.location_name}</div>
						</div>
				   </div>
				</div>          
				<div className="cancel-by-user">
					<div className="row">   
						<div className="col-12">
							<div className="heading"><span className="icon booking-Info"></span>Booking Information </div>
						</div>
					</div>
					<div className="box">
						<div className="row">   
							<div className="col-6">
								<div className="icon request-details"></div>
								<div className="wrap">
									<div className="title">Booking ID</div>
									<div className="subtitle">{this.props.tripDetailData.info.request_hash_id}</div>
								</div>
							</div>
							<div className="col-6">
								<div className="icon guest"></div>
								<div className="wrap">
									<div className="title">No. of Guests</div>
									<div className="subtitle">{this.props.tripDetailData.info.guests}{this.props.tripDetailData.info.guests > 1 ? ' Guests' :' Guest' }</div>
								</div>
							</div>
							<div className="col-6">
								<div className="icon checkin"></div>
								<div className="wrap">
									<div className="title">Check in</div>
									<div className="subtitle">{this.props.tripDetailData.info.checkin_formatted}</div>
								</div>
							</div>
							<div className="col-6">
								<div className="icon checkout"></div>
								<div className="wrap">
									<div className="title">Check out</div>
									<div className="subtitle">{this.props.tripDetailData.info.checkout_formatted}</div>
								</div>
							</div>
						</div>
						<div className="request-wrap">
							<div className="row">
								<div className="col-6">
									<div className="booking-date">Booking amount</div>
									<div className="booking-details">{this.props.tripDetailData.booking_amount_info.total_amount}</div>
								</div>
								<div className="col-6">
									<div className="modify-btn" onClick={() => this.viewInvoiceOpen()}>Price breakup<span className="arrow-icon"></span></div>
								</div>
							</div>

						</div>

						{this.props.tripDetailData.booking_amount_info.pending_payment === 1 && 
						 <div className="request-wrap">
							<div className="row">
								<div className="col-6">
									<div className="booking-date">{this.props.tripDetailData.booking_amount_info.pending_payment_text}</div>
									<div className="booking-details">{this.props.tripDetailData.booking_amount_info.pending_payment_amount}</div>
								</div>
								<div className="col-6">
									<div className="modify-btn" onClick={this.makePayment}>Pay Now<span className="arrow-icon"></span></div>
								</div>
							</div>
						</div>
						}
							
					</div>
					{this.props.tripDetailData.viewInvoice &&
						<div>
							{this.props.tripDetailData.property_info_section.show_host === 1 &&
							<div className="host-details">
								<div className="row">   
									<div className="col-12">
										<div className="heading"><span className="icon property-details"></span> Property details</div>
									</div>
								</div>
								<div className="box">
									<table className="table">
										<tbody>
											<tr>
												<td className="lable">Host name</td>
												 <td className="seperate">:</td>
												 <td>{this.props.tripDetailData.property_info_section.host_name}</td>
											</tr>
											<tr>
												<td className="lable">Address</td>
												 <td className="seperate">:</td>
												 <td>{this.props.tripDetailData.property_info_section.host_address? this.props.tripDetailData.property_info_section.host_address : '-'}</td>
											</tr>
										</tbody>
									</table>
									{this.props.tripDetailData.property_info_section.host_address ?
									<div className="row">
											<div className="col-6 text-center">
												<a href={"google.navigation:q=" + this.props.tripDetailData.tile.location.latitude+','+this.props.tripDetailData.tile.location.longitude} className="btn">Navigate</a>
											</div>
										<div className="col-6 text-center">
											<a href={"tel:" + (this.props.tripDetailData.property_info_section.host_contact > 0 ? this.props.tripDetailData.property_info_section.host_contact : '18001020404')} className="btn">Call host</a>
										</div>
									</div>
									:
									<div className="row">
										<div className="col-12 text-center">
											<a href={"tel:" + (this.props.tripDetailData.property_info_section.host_contact > 0 ? this.props.tripDetailData.property_info_section.host_contact : '18001020404')} className="btn">Call host</a>
										</div>
									</div>
									}
								</div>
							</div>
							}
							{this.props.tripDetailData.cancellable === 1 &&
							<div className="cacelation-policy">
								<div className="row">   
									<div className="col-12">
										<div className="heading"><span className="icon cancellation"></span> Cancellation policy</div>
									</div>
								</div>
								<div className="box">
									<div className="title">{this.props.tripDetailData.cancellation_policy_info.title}</div>
									<div className="subtitle">{this.props.tripDetailData.cancellation_policy_info.desc}</div>
									<div className="request-wrap">
										<div className="row">
											<div className="col-6">
												<div className="booking-date">Would you like to cancel your trip?</div>
											</div>
											<div className="col-6">
												<div className="modify-btn" onClick={this.cancelPopUpOpen}>Cancel trip<i className="arrow-icon"></i></div>
											</div>
										</div>
									</div>
								</div>
							</div>
							}
						</div>
					}
					{/*this.props.tripDetailData.info.can_review === 1 &&
						<div className="row">
							<div className="col-12 text-center">
								<button className="button">Review this property</button>
							</div>
						</div>
					*/}
					{/*this.props.tripDetailData.info.can_share_fb === 1 &&
					<div className="row">
						<div className="col-12 text-center">
							<button className="button fb">Share your trip on Facebook</button>
						</div>
					</div>
					*/}
				</div>
				<ViewInvoice
					viewInvoiceOpen={this.state.invoiceModal}
					viewInvoiceClose={this.goBack}
					toggleStatus={this.toggleStatus}
				/>
				<CanceledSuccess
					canceledSuccessOpen={this.state.canceledSuccess}
					canceledSuccessClose={this.canceledSuccessClose}
					cancelMessage={this.state.cancelMessage}
				/>
				<CancelPopUpTrip
					cancelPopUpOpen={this.state.cancelPopUp}
					cancelPopUpClose={this.goBack}
					cancelModalOpen={this.cancelModalOpen}
				/>
				<CancelModal
					cancelModalOpen={this.state.cancelModal}
					cancelModalClose={this.goBack}
					booking_status={this.props.tripDetailData.booking_status}
					cancellation_reasons={this.props.tripDetailData.cancellation_reasons}
					info={this.props.tripDetailData.info}
					canceledSuccessOpen={this.canceledSuccessOpen}
					cancelRequest={this.cancelRequest}
					gotoTrip={this.state.trip}
				/>
			</div>
			:
			<div className="info-window">
				<div className="window-container">
					<div className="imgb">
						<div className="img" style={{ backgroundImage: "url(" + serverError + ")" }}/>
					</div>
					<div className="txtb">
						<p>Sorry, our servers are busy. Please try again in a bit.</p>
					</div>
					<button className="retry" onClick={this.reload}>Retry</button>
				</div>
			</div>
			}
			</div>
			:
			<div className="info-window ">
					<div className="window-container">
							<div className="imgb">
									<div className="img" style={{backgroundImage:'url(' + noInternet + ')'}}></div>
							</div>
							<div className="txtb"><p>Oops! You don't seem to be connected to the internet.</p></div>
							<button className="retry" onClick={this.reload}>Retry</button>
					</div>
			</div>
			}
			</div>  
		)
		
	}
}

const mapStateToProps = (store) => {
	return {
		tripDetailData: store.tripData,
		tracker: store.tracker.tracker,
		error: store.error
	}
}

const mapDispatchToProps = {
	updateTripState,
	updateErrorState,
	updateBookingDataState,
	updatePropertyState
}

export default connect(mapStateToProps, mapDispatchToProps)(TripDetails);