import { SET_STATE_WALLET_OBJ }  from '../actions/wallet/vars';

const initialState = {
    walletData:{},
    walletDataLoaded: false
}

const fnc = (state = initialState, action) => {
    switch (action.type) {
        case SET_STATE_WALLET_OBJ:
            let newState = Object.assign({}, state, action.payload);
            return newState;
            
        default:
            return state;

    }
}

export default fnc;