import React from "react";
import Swiper from "react-id-swiper";
import defaultIMage from "../../static/img/placeholder-gallery.jpg";
import { Player, ControlBar, PlayToggle } from "video-react";
import { withRouter } from "react-router";
import { connect } from "react-redux";
import OfflineStatus from "../Offlinestatus";
/**
 * Actions
 */
import { updatePropertyState } from "../../actions/property/actions";

class GallerySlider extends React.Component {
  constructor(props) {
    super(props);

    this.videoToggle = this.videoToggle.bind(this);
    this.openShareModal = this.openShareModal.bind(this);
    this.goBack = this.goBack.bind(this);

    this.state = {
      disabled: false
    };
  }

  componentWillMount() {
    window.addEventListener("offline", event => {
      this.setState({ disabled: true });
    });
    window.addEventListener("online", event => {
      this.setState({ disabled: false });
    });
  }

  componentWillUnmount(){
    document.body.style = ``;
  }

  videoToggle() {
    let ele = document.getElementsByClassName("video-react-big-play-button");

    if (ele !== undefined) {
      let hideBtnExist = document.getElementsByClassName(
        "big-play-button-hide"
      );

      if (hideBtnExist[0] !== undefined) {
        ele[0].classList.remove("big-play-button-hide");
      } else {
        ele[0].classList.add("big-play-button-hide");
      }
    }
  }

  openShareModal() {
    const propertyVideo =  this.props.property_video.length > 0
    window.location.hash = "shareModal";
    if(propertyVideo){
      this.player.pause();
     }
  }

componentDidMount(){
    this.props.updatePropertyState({ galleryLoaded: true });
    if (!navigator.userAgent.match("CriOS")) {
      document.body.style = `
        background:none;
        opacity:1;
      `;
      window.mainListenerFn = e => {
        if (window.orientation === 90 || window.orientation === -90) {
          const element = document.body;
          if (element.requestFullscreen) {
            element.requestFullscreen();
          } else if (element.mozRequestFullScreen) {
            element.mozRequestFullScreen();
          } else if (element.webkitRequestFullScreen) {
            element.webkitRequestFullScreen(Element.ALLOW_KEYBOARD_INPUT);
          }
        } else {
          if (
            document.fullscreenElement ||
            document.webkitFullscreenElement ||
            document.mozFullScreenElement
          ) {
            document.exitFullscreen();
          }
        }
      }
      window.addEventListener("orientationchange", window.mainListenerFn);
    } else {
      window.listenerFn = e => {
        if (window.orientation === 90 || window.orientation === -90) {
          this.goBack();
        }
      };
      window.addEventListener("orientationchange", window.listenerFn);
    }

}

  goBack() {
    if (
      document.fullscreenElement ||
      document.webkitFullscreenElement ||
      document.mozFullScreenElement
    ) {
      document.exitFullscreen();
    }
    this.props.history.goBack();
  }

  render() {
    const propertyVideo =  this.props.property_video.length > 0
    const params = {
      initialSlide: this.props.slider_index,
      lazy: {
        loadPrevNext: true
      },
      navigation: {
        nextEl: ".swiper-button-next",
        prevEl: ".swiper-button-prev"
      },
      pagination: {
        el: ".swiper-pagination",
        type: "fraction"
      },
      on: {
        slideChange: () => {
         if(propertyVideo){
          this.player.pause();
         }
        }
      }
    };
    return (
      <div className="overlay-modal">
        <div
          className={
            "content-modal full-screen-modal gallery-slider " +
            (this.state.disabled && "greyscale")
          }
        >
          <div className="modal-header">
            <div className="close-btn header-btn" onClick={this.goBack} />
            <div
              className="share-btn header-btn"
              onClick={this.openShareModal}
              style={this.state.disabled ? { pointerEvents: "none" } : {}}
            />
          </div>
          <div
            className="modal-body"
            style={this.state.disabled ? { pointerEvents: "none" } : {}}
          >
            {this.props.galleryLoaded && (
              <Swiper {...params}>
                {this.props.property_video.length > 0 && (
                  <div>
                    <Player
                      ref={player => (this.player = player)}
                      poster={this.props.property_video[0].thumbnail}
                      onEnded={this.goBack}
                    >
                      <source src={this.props.property_video[0].url} />
                      <ControlBar autoHide={true} />
                    </Player>
                  </div>
                )}

                {this.props.property_images.map((item, index) => (
                  <div key={index}>
                    <div className="imgWrapper">
                      <img
                        data-src={item.image}
                        src={defaultIMage}
                        className="swiper-lazy"
                        alt="Not loaded"
                        border="0"
                      />
                      <span className="caption">{item.caption}</span>
                    </div>
                  </div>
                ))}
              </Swiper>
            )}
          </div>
          <OfflineStatus offline={this.state.disabled} bottom />
        </div>
      </div>
    );
  }
}

const mapStateToProps = store => {
  return {
    property_images: store.property.property_images,
    property_video: store.property.property_video,
    flag: store.property.flag,
    galleryLoaded: store.property.galleryLoaded,
    slider_index: store.property.slider_index
  };
};

const mapDispatchToProps = {
  updatePropertyState
};

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(withRouter(GallerySlider));
