import { PROPERTY_UPDATE_STATE, PROPERTY_UPDATE_STATE_RESET } from "../actions/property/vars";

const initialState = {
    'page' : 'property',
    'property_hash_id': '',
    'property_id': '',
    'property_images': [],
    'property_video':[],
    'property_title': '',
    'title': '',
    'location_name': '',
    'property_image_count': 0,
    'property_score': '',
    'property_pricing': '',
    'review_count': '',
    'propertyGalleryList': false,
    'maxPhotos':5,
    'host_id': '',
    'host_image': '',
    'host_name': '',

    'about': 'Empty',
    'description': [],
    'space': [],
    'amenities': [],
    'tags': [],
    'usp': '',
    'cancellation_policy': '',
    'payment_methods': [],
    'reviews': [],
    'how_to_reach': '',
    'footer_data': '',
    'left_div': '',
    'right_div': '',
    'similar_properties': [],
    'similar_properties_all':[],
    'slider_index':0,
    'whishlist': '',
    'bookable': 1,

    'meta_image': '',
    'meta_desc': '',
    'meta_title': '',
    'canonical_url': '',

    'calendar_prices':'' ,
    'default_prices': '',
    'offset':0,
    'element_height' : 0,
    'reviews_list': [],
    reviewOffset:0,

    'available_units': 15,
    'guests_per_unit': 2,
    'property_type': '',
    'max_nights': 60,
    'min_nights': 1,
    'shake_animation_unit': '',
    'similar_listing_scroll':0,
    'property_data_stack':[],
    'loading':false,
    'itemIndex':0,
    dataLoaded: false,
    similiarAllLoaded: false,
    galleryLoaded: false,
    calendarPriceLoaded:false,
    indexList: [
        { index: 0 }
    ],
    flag: {
        api : {
            is_loading : false
        },
        modal: {
            propertyGalleryList: false,
            gallerySlider: false,
            shareModal: false,
            allAmenitiesList: false,
            allReviewsList: false,
            aboutPropertyModal: false,
            mapFullView: false,
        },
        is_top : true,
        no_more_result: false,
        network:false,
        new_similar_list:false,
    },
    prepaymentModify: false,
}

const fnc = (state = initialState, action) => {
    switch (action.type) {

        case PROPERTY_UPDATE_STATE:
            return Object.assign({}, state, action.payload);

        case PROPERTY_UPDATE_STATE_RESET:
            return Object.assign({}, initialState) ;

        default:
            return Object.assign({}, state);

    }
};

export default fnc;
