

/* APIs */
// get get payment link instant book
let getResendRequestApi = function (data, args)
{
//console.log(args);
	return data.AXIOS.post(data.BASE_URL_V+'/booking/request/resend', data.QS.stringify({
			request_hash_id: args,
		}), {
			headers: {
				'Accept': 'application/json',
				'Content-Type': 'application/x-www-form-urlencoded',
				'device-unique-id': data.DEVICE_UNIQUE_ID,
				'Authorization' : 'Bearer ' + data.ACCESS_TOKEN,
			}
		}
	);
}

exports.getResendRequestApi = getResendRequestApi;