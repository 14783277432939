import React from 'react';
import Modal from 'react-modal';

class CancelPopUp extends React.Component {
  constructor(props) {
	super(props);
	this.state = {
	  
	}
}
  render() {
	return (
	  <div>
		<Modal
		  isOpen={this.props.cancelPopUpOpen}
		  onAfterOpen={this.afterOpenModal}
		  onRequestClose={this.props.cancelPopUpClose}
		  contentLabel="Modal"
				overlayClassName={{
				base: 'overlay-modal overlay-popup',
				afterOpen: '',
				beforeClose: ''
			  }}
		  className={{
				base: 'content-modal',
				afterOpen: 'editProfile request-modal',
				beforeClose: ''
			  }}
			>
		  <div className="modal-body text-center">
			  <span className="back-btn" onClick={this.props.cancelPopUpClose}></span>
			  <div className="text-cacel">Would you like to cancel your booking request?</div>
			  <button className="link green" onClick={this.props.cancelModalOpen}>Cancel request</button>
		  </div>
		</Modal>
	  </div>
	);
  }
}

export default CancelPopUp;