

const access_token_var = 'access_token';
const refresh_token_var = 'refresh_token';

// set access token in localstorage
let setAccessTokenId = function(args) {
    localStorage.setItem(access_token_var, args);
}

// get  access token id from localstorage
let getAccessTokenId = function() {
    return localStorage.getItem(access_token_var);
}

// set refresh token id in localstorage
let setRefreshTokenId = function(args) {
    localStorage.setItem(refresh_token_var, args);
}

// get efresh token id from localstorage
let getRefreshTokenId = function() {
    return localStorage.getItem(refresh_token_var);
}

exports.setAccessTokenId = setAccessTokenId;
exports.getAccessTokenId = getAccessTokenId;
exports.setRefreshTokenId = setRefreshTokenId;
exports.getRefreshTokenId = getRefreshTokenId;