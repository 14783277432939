import React from 'react';
import {Link} from 'react-router-dom';
import loginVideo from '../../static/img/gh.mp4'
import {trackPageView} from '../../services/tracker/events.js';
import {connect} from 'react-redux';
import {updateSignupStateObj} from './../../actions/signup/actions';
//login with Social
import {hasAccessToken,} from '../../services/index.js';
import { checkIfOffline } from '../../services/helpers';
import OfflineStatus from '../../components/Offlinestatus';

 
class Login extends React.Component {

  constructor(props) {
        super(props);
        this.page="social_login_signup";
        this.state = {
            current_url : window.location.href,
            body_bg_color:'#fff',
            offlineStatus: false
        }
        this.resetMessages = this.resetMessages.bind(this);
        this.goBack = this.goBack.bind(this);

        if(hasAccessToken()){
           if (props.signup.afterLogin.length > 0) {
                this.props.history.replace(props.signup.afterLogin)
           } else {
                this.props.history.replace('/')
           }
        }
    }

    changeOfflineStatus() {
        console.log("Offline");
        this.setState(
          {
            offlineStatus: true
          },
          () => {
            setTimeout(() => {
              this.setState({
                offlineStatus: false
              });
            }, 200);
          }
        );
      }

    resetMessages(){
        this.props.updateSignupStateObj({
            show:false,
            message:'',
        });
    }

    componentDidMount(){
        window.scrollTo(0, 0);
        let urlAfterLogin = sessionStorage.getItem('UrlAfterLogin');
        document.body.style.backgroundColor = this.state.body_bg_color;
        document.body.style.height = this.state.height;
        document.body.setAttribute('data-color', this.constructor.name);

        trackPageView(this.props.tracker, this.page);

    }

    componentWillUnmount(){
        if (document.body.getAttribute('data-color') === this.constructor.name) {
            document.body.style.backgroundColor = null;
        }
    }
    goBack(){
        if(sessionStorage.getItem('hashId')){
            sessionStorage.removeItem('hashId');
        }
       
        let haveToUseDefaultGoBack = true;
        if(this.props.location.hasOwnProperty("state") && this.props.location.state !== undefined){
            haveToUseDefaultGoBack = false;
        }

        if(haveToUseDefaultGoBack){
            this.props.history.goBack();
        }
        else{

            this.props.history.push({
                pathname: this.props.location.data.pathname,
                search: this.props.location.data.search,
                action : 'BACK',
                state : this.props.location.state,
            });
        }
        sessionStorage.removeItem('currencyModal')     
    }
    render() {
        var loader = this.props.signup.flag.loading ? 'loading active' : 'loading ';
        return (
        	<div className="login-signup">
                <OfflineStatus
                    offline={checkIfOffline() && this.state.offlineStatus}
                    bottom
                    dontClear
                />
                <div className={loader}></div>
        		<div className="login-video">
                       <div className="skip" onClick={this.goBack} ></div> 
                       <video src='https://s3.ap-south-1.amazonaws.com/m.beta.guesthouser.com/static/media/gh.c8d6e3e2.mp4'  autoPlay loop muted webkit-playsinline="true" playsInline="true"></video>
                </div>
          		<div className="login-signup-wrap">
                  <div className="login-page-text">
                        <div className="heading">Welcome to GuestHouser</div>
                        <div className="txt">With 175,000+ homes across 2,200+ cities, GuestHouser is India’s largest portal to book holiday homes.</div>
                  </div>
                  <div className="btn login link">
                    <div className="row">
                    <div className="col-6 line-right">
                        <div onClick={_ => {
                            this.props.updateSignupStateObj({
                                afterLogin: this.props.prevRoute.pathname + this.props.prevRoute.search
                            })
                            if (!checkIfOffline()) {
                                this.props.history.push('/email/signup')
                            } else {
                                this.changeOfflineStatus()
                            }
                        }}>Signup</div>
                    </div>
                    <div className="col-6">
                        <div onClick={_ => {
                            this.props.updateSignupStateObj({
                                afterLogin: this.props.prevRoute.pathname + this.props.prevRoute.search
                            })
                            if (!checkIfOffline()) {
                                this.props.history.push('/login/email')
                            } else {
                                this.changeOfflineStatus()
                            }
                        }}>Login</div>
                    </div>
                    </div>
                  </div>

          		</div>
                {/* <div className={'responseAPI hidden '+this.props.signup.flag.show }>
                    <ResponseMessage responseMessage={this.props.signup.flag.message} responseStatus={this.props.signup.flag.status} resetMessages={this.resetMessages}/>
                </div> */}
        	</div>
        );
    }
}
const mapStateToProps = (store) => { 
    return {
        signup : store.signupEmail,
        tracker: store.tracker.tracker,
        error : store.error
    }
}
const mapDispatchToProps = {
    updateSignupStateObj
}
export default connect(mapStateToProps, mapDispatchToProps)(Login);