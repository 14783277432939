import { HOME_UPDATE_STATE } from "../actions/home/vars";

const popular_cities_sample = Array(6).fill(
	{
		property_images: [],
		title: ""
	}
)

const initialState = {
		'home_banners': [],
		'popular_cities': popular_cities_sample,
		'popular_cities_subheading': '',
		'property_types': [],
		'recently_viewed_properties': [],
		'recently_viewed_properties_all': [],
		'collections': [],
		'network': true,
		'active_request_count': '',
		'offer': {},
		'offset':0,
    	'element_height' : 0,
		'propertyAll': [],
		'offerPage': false,
		'keyword': '',
		'meta_desc': '',
		'meta_title': '',
		'canonical_url': '',
		'page' : 'home',
		'home_scroll':0,
		'googlePlaceList':[],
		'popular_search_list':[],
		'itemIndex':0,
		trendingScrollState: [],
		dataLoaded: false,
		dataLoadedRecent: false,
		homeHistory: [],
		flag : {
			modal : {
				trending_cities : false,
				recent_view : false,
				recent_view_1: 1
			},
			no_more_result : false,
			network:false,
			api : {
				is_loading: false
			}
		}
}


const fnc = (state = initialState, action) => {
	switch (action.type) {
		case HOME_UPDATE_STATE:
			return Object.assign({}, state, action.payload);

		case 'CLEAR_REDUX_STATE':
			return { ...state, recently_viewed_properties: [], dataLoaded: false, dataLoadedRecent: false }
			
		default:
			return Object.assign({}, state);
	}
};

export default fnc;
