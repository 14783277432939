import React from 'react';
import Modal from 'react-modal';
import {Button} from 'react-materialize';
import { connect } from "react-redux";
import { withRouter } from "react-router";

import { updatePrepaymentState } from '../../actions/prepayment/actions';

class requestSentModel extends React.Component {
	constructor(props) {
		super(props);
		this.closeRequestSentModal = this.closeRequestSentModal.bind(this);
	}

	closeRequestSentModal() {
		let flag = this.props.prepayment.flag;
		flag.modal.requestSentModal = false;
		this.props.updatePrepaymentState({ 'flag' : flag }).then( () => {
			this.props.history.push('/request/' + this.props.prepayment.request_id);
		});
	}

	render() {
	return (
		<div>
		<Modal
			isOpen={this.props.prepayment.flag.modal.requestSentModal}
			onRequestClose={this.closeRequestSentModal}
			shouldCloseOnOverlayClick={false}
			contentLabel="Modal"
				overlayClassName={{
				base: 'overlay-modal overlay-popup',
				afterOpen: '',
				beforeClose: ''
				}}
			className={{
				base: 'content-modal',
				afterOpen: 'editProfile request-modal',
				beforeClose: ''
				}}
			>
			<div className="modal-body text-center">
				<div className="verify-text1 text-center">Congratulations</div>
				<div className="text">Your request has been sent to host for an approval, while your request has been approved you can see more options.</div>
				<Button waves='light' className="link green" onClick={this.closeRequestSentModal}>Continue</Button>
			</div>
		</Modal>
		</div>
	);
	}
}


const mapStateToPtops = (store) => {
	return {
		prepayment: store.prepayment
	}
}

const mapDispatchToProps = {
	updatePrepaymentState
}

export default connect(mapStateToPtops, mapDispatchToProps)(withRouter(requestSentModel));
