

/* APIs */
// get get payment link
let getCancelBookingRequestDataApi = function (data, args)
{
//console.log(args);
	return data.AXIOS.post(data.BASE_URL_V+'/booking/request/cancel', data.QS.stringify({
			request_hash_id: args.request_hash_id,
			request_status: args.request_status,
			reason_id:args.reason_id,
			reason_detail:args.reason_detail,
		}), {
			headers: {
				'Accept': 'application/json',
				'Content-Type': 'application/x-www-form-urlencoded',
				'device-unique-id': data.DEVICE_UNIQUE_ID,
				'Authorization' : 'Bearer ' + data.ACCESS_TOKEN,
			}
		}
	);
}

exports.getCancelBookingRequestDataApi = getCancelBookingRequestDataApi;