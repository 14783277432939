import React from 'react';
import { connect } from 'react-redux';

class PaymentOption extends React.Component{
	render(){
		return(
			<section className="payment-options clearfix">
                <h3>Preferred payment options</h3>
                <div className="space-amenities-list clearfix">
                    <ul>
                        {this.props.payment_methods.map(function(item, index){
                            return(
                                <li key={index}><i className={item.value.split(" ").join('-').toLowerCase()}></i>{item.value}</li>
                            )
                        })}
                    </ul>
                </div>
            </section>
		);
	}
}

const mapStateToProps = (store) => {
    return {
        payment_methods: store.property.payment_methods
    }
}
export default connect(mapStateToProps,null)(PaymentOption);