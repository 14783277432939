import React from 'react';
import LazyLoad from 'react-lazy-load'
import {withRouter} from 'react-router';


import {Element, Events, scroller } from 'react-scroll';
import { checkIfOffline } from '../../services/helpers'


//services
import {hasAccessToken,getWishlistAddDataApi,getWishlistDeleteDataApi, tileRatingColor,saveUrlLogin} from '../../services/index.js';
import { updateStoredPropertyWishlist } from '../../services/helpers.js';
import OfflineStatus from '../Offlinestatus';

class SearchTile extends React.Component {
	constructor(props){
		
		super(props);
		this.state = {
			activeClassState:' ',
			offlineStatus: false
		}

		this.toggleActiveClass =this.toggleActiveClass.bind(this);
		this.toggleLikeByUser = this.toggleLikeByUser.bind(this);
	}

	changeOfflineStatus () {
		this.setState({
			offlineStatus: true
		}, () => {
			setTimeout(() => {
				this.setState({
					offlineStatus: false
				})
			}, 200)
		})
	}

	toggleActiveClass(event) {
		event.preventDefault();
		event.stopPropagation();
		if (checkIfOffline()) {
			this.changeOfflineStatus();
			return;
		}
		if(hasAccessToken()){

			if(event.target.className.indexOf('active') > -1 ){

				// removing wishlist icon
				event.target.className = 'wishlist-icon';
				this.getWishlistDeleteApi(event.target.parentNode.parentNode.id, event.target.id);
				updateStoredPropertyWishlist(event.target.parentNode.parentNode.id,0);

			} else {

				// user add this property to in their wishlist
				event.target.className = 'wishlist-icon active';
				this.getWishlistApi(event.target.parentNode.parentNode.id, event.target.id);
				updateStoredPropertyWishlist(event.target.parentNode.parentNode.id, 1);

			}
		}else{

			// saving redirect url after login
			saveUrlLogin();
			sessionStorage.setItem('hashId',event.target.parentNode.parentNode.id);

			// user is not logged in, redirecting to signup page
			this.props.history.push('/signup');
		}
	}


	/**
	 * toggleLikeByUser toggle is_like_by_user flag correspond to USER
	 * @param  {[integer]} index [Used to find clicked property]
	 * @return {[void]}       void
	 */
	
	toggleLikeByUser(index){
		let finalList = this.state.properties_list_final;
		if(finalList[index].is_liked_by_user === 1){
			finalList[index].is_liked_by_user = 0;
		} else {
			finalList[index].is_liked_by_user = 1;
		}
		this.setState({
			properties_list_final:finalList,
		})
	}


	getWishlistApi(data, property_index){
		let this_el = this;
		getWishlistAddDataApi(data).then(function(response){

			// success
			this_el.props.updateWishlistedProperty(property_index);

		}).catch(function(error){

			// handle error
		});
	}


	getWishlistDeleteApi(data, property_index){

		let this_el = this;
		getWishlistDeleteDataApi(data).then(function(response){
			
			// success
			this_el.props.updateWishlistedProperty(property_index);
			
		}).catch(function(error){
			
			// error
		});
	}

	componentDidMount(){
		/**
		 * Scrolling to property when user click back from the property preview
		 */

	    let scrollToProperty = false;
		if(this.props.hasOwnProperty('locationProp') &&this.props.locationProp.hasOwnProperty("hash") && this.props.locationProp.hash.length > 0){
			scrollToProperty = this.props.locationProp.hash;
		}
		if(scrollToProperty){
			scrollToProperty = scrollToProperty.substr(1);
			scroller.scrollTo(scrollToProperty, {
		      duration: 400,
		      delay: 0,
		      offset : -80
		      //smooth: 'easeInOutQuart',
		      //scrollOffset : -400
		    });
			if(this.refs["container_"+scrollToProperty]){
				this.refs["container_"+scrollToProperty].classList.add('selected_property');
			}    
		}

	    Events.scrollEvent.register('begin');

	    Events.scrollEvent.register('end');




		let this_el = this;
		let storedHashId = sessionStorage.getItem('hashId');
		if(storedHashId !== null && typeof this_el.refs[storedHashId] !== 'undefined'){
			this_el.getWishlistApi(storedHashId);
			this_el.refs[storedHashId].classList.add('active');
			sessionStorage.removeItem('hashId');
		}
	}

	componentWillUnmount() {
	    Events.scrollEvent.remove('begin');
	    Events.scrollEvent.remove('end');
  	}

    imageLoaded(e){
        let imgSrc = e.target.src;
        e.target.parentNode.nextElementSibling.style.backgroundImage = 'url('+imgSrc+')';
    }

	render() {
		const this_el = this;
		const ellipsis = {
			 textOverflow: 'ellipsis',
			 overflow:'hidden',
			 WebkitBoxOrient: 'vertical'
		}

		return (
			<div>
				{this.props.properties_list.map(function(item, index) {
					return(
						<Element className="element" id={item.property_hash_id} key={index}  >
						<div>
							<div className="search-tile" id={item.property_hash_id} ref={"container_"+item.property_hash_id} onClick={() => this_el.props.toProperty(item,index)}>
								<div className="imgb">
									<OfflineStatus
										offline={checkIfOffline() && this_el.state.offlineStatus}
										dontClear
										bottom
									/>
									<div className="search-slider">
										{item.property_images.length > 0 &&
											<LazyLoad debounce={false} offset={400} >
												<img src={item.property_images[0].image} onLoad={(e) => this_el.imageLoaded(e)} className='hidden' alt='property'/>
		                                	</LazyLoad>
		                            	}
										<div className="slide"></div>
									</div>
									{item.prices.display_discount > 0 ? 
										(<div className="tile-flag discount">{item.prices.display_discount}%<br/>OFF</div>):''
									}
									{/*<div className="tile-flag">Top Booked<span>10,000+ nights booked</span></div>*/}
									<span ref={item.property_hash_id} className={item.is_liked_by_user === 1 ? 'wishlist-icon active' : 'wishlist-icon'} id={index} onClick={this_el.toggleActiveClass}></span>

									{item.property_videos_available === 1 ? <span className="video-tag"><i></i> Watch video</span> : ''}
								</div>
								<div className="txtb">
									{item.property_score > 0 && <span className={"tile-rating pull-right " + tileRatingColor(item.property_score)}>{item.property_score}</span>}
									<h2 style={ellipsis}>{item.title}</h2>
									<div className="features clearfix">
										<span><i></i> {item.property_type_name}</span>
										<span><i className="guest"></i> {item.total_accomodation} Guests</span>
									</div>
									<div className="bottom-txtb clearfix">
										<div className="col-50 tags-list">
											{item.property_tags.length > 0 &&
												<span className={'tag ' + item.property_tags[0].class}><i></i> {item.property_tags[0].text}</span>
											}
											{item.property_tags.length > 0 }
												{item.payment_methods.cash_on_arrival === 1 ? (<span className="payment-note">Book now, pay later!</span>):('')}
												{item.payment_methods.cash_on_arrival === 1 && item.property_tags.length < 1 && item.prices.display_discount > 0 ? (<span className="payment-note" style={{'marginTop':'25px'}}>Book now, pay later!</span>):('')}
										</div>
										<div className="col-50 ">
											<div className="price text-right">
												{item.prices.display_discount > 0 ? 
													(<span className="discount"><del>{item.prices.price_before_discount}</del> {item.prices.display_discount}%</span>):''
												}
												<span className="final-price"> {item.payment_methods.instant_book === 1 ? (<i className="instant-book-icon"></i>):('')}{item.prices.price_after_discount}</span>
											</div>
										</div>
									</div>
								</div>
							</div>
						</div>
						</Element>
					)
				})}
			</div>
		);
	}
}

export default withRouter(SearchTile);

