

/* APIs */
// get get payment link instant book
let getInstantBookPaymentDataApi = function (data, args)
{
//console.log(args);
	let discount = {};
	if(args.discount_type === "wallet"){
		
		discount = {'apply_wallet': args.apply_wallet};

	}else if(args.discount_type === "coupon"){
		discount = {'coupon_code': args.coupon_code};
	}
	return data.AXIOS.post(data.BASE_URL_V+'/booking/request', data.QS.stringify(Object.assign(discount,{
			property_hash_id: args.property_hash_id,
			checkin: args.checkin,
			checkout:args.checkout,
			guests:args.guests,
			units:args.units,
			payment_method:args.payment_method,
			payable_amount:args.payable_amount,
			force_create: args.force_create,

		})), {
			headers: {
				'Accept': 'application/json',
				'Content-Type': 'application/x-www-form-urlencoded',
				'device-unique-id': data.DEVICE_UNIQUE_ID,
				'Authorization' : 'Bearer ' + data.ACCESS_TOKEN,
			}
		}
	);
}

exports.getInstantBookPaymentDataApi = getInstantBookPaymentDataApi;