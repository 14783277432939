// axios
let axios       = require('axios');
let qs       	= require('qs');

// variables
let similarListing      = require('./propertyPreview/similar_listing.js')
let deviceVar           = require('./variables/device.js');
let tokenVar            = require('./variables/token.js');
let socialLogin         = require('./login/signupLoginSocial.js');
let PropertyPreviewApi  = require('./propertyPreview/index.js');
let PrepaymentApi       = require('./prepayment/index.js');
let RequestPrepaymentApi       = require('./prepayment/requestPrepayment.js');
let requestDetailsApi   = require('./request/request.js');
let CancelBookingRequestApi   = require('./request/cancelBookingRequst.js');
let requestDetailsDataApi  = require('./request/requestDetails.js');
let currencyListApi     = require('./currency/currencyList.js');
let propertyReviewApi   = require('./propertyPreview/reviewList.js');
let changePasswordApi   = require('./setting/changePassword.js');
let pricingCalendarApi  = require('./propertyPreview/pricing_calender.js');
let referAndEarnApi 	= require('./referEarn/index.js');
let propertyLinsting 	= require('./propertyListing/index.js');
// apis
let recentlyViewAllApi  = require('./home/recentlyViewApi.js')
let updateProfileImage  = require('./account/updateProfileImage.js');
let deactivateAccount   =require('./account/deactivate.js');
let homeApi             = require('./home/homeApi.js');
let collectionApi       = require('./collection/collection.js');
let collectionListApi   = require('./collection/collectionList.js');
let wishlistApi         = require('./whislist/wishlist.js');
let wishlistAddApi      = require('./whislist/wishlistAdd.js');
let wishlistDeleteApi   = require('./whislist/wishlistDelete.js');
let currencyChangeApi   = require('./currency/currency.js');
let accountApi          = require('./account/loginAccount.js');
let emailVarifySuccessApi = require('./account/emailvarifySuccess.js');
let emailVarifyApi      = require('./account/emailVarify.js');
let walletApi           = require('./account/wallet.js');
let deviceRegisterApi   = require('./device/register.js');
let signUpApi           = require('./signup/index.js');
let otpSendApi          = require('./signup/otpSend.js');
let otpVerifyApi        = require('./signup/otpVerify.js');
let getDialCodes        = require('./signup/dialCodes.js');
let loginApi        	= require('./login/login.js');
let refreshLoginApi     = require('./login/refreshLogin.js');
let logoutApi           = require('./logout/logout.js');
let ForgetPasswordApi   = require('./login/forgetPassword.js');
let searchApi           = require('./search/searchApi.js');
let popularSearchApi    = require('./search/popularSearchApi.js');
let resetPasswordApi    = require('./login/resetPassword.js');
let editProfileApi      = require('./account/editProfile.js');
let tripApi             = require('./trip/tripApi.js');
let invoiceSentApi      = require('./trip/invoiceSenEmail.js');
let tripDetailApi       = require('./trip/tripDetailApi.js');
let originalBodyOverflow = '';
let positionBody 		= '';
let InstantBookPaymentApi = require('./request/instantBookPayment.js');
let RequestPaymentApi 	= require('./request/requestPayment.js');
let resendRequestApi = require('./request/resendRequest.js');
let myListing = require('./propertyListing/index.js');


/* init variables */
let data = {
	AXIOS: axios,
	QS: qs,
	BASE_URL: process.env.REACT_APP_API_BASE_URL,
	BASE_URL_V: process.env.REACT_APP_API_BASE_URL + '/' + process.env.REACT_APP_API_VERSION,
	API_VERSION: process.env.REACT_APP_API_VERSION,
	// DEVICE_UNIQUE_ID: deviceVar.getDeviceUniqueId(),
	// ACCESS_TOKEN: tokenVar.getAccessTokenId(),
	DEVICE_NOTIFICATION_TOKEN: '',
	CLIENT_ID:process.env.REACT_APP_CLIENT_ID,
	GOOGLE_CLIENT_ID:process.env.REACT_APP_GOOGLE_CLIENT_ID,
	CLIENT_SECRET:process.env.REACT_APP_CLIENT_SECRET,
	GRAND_TYPE:process.env.REACT_APP_GRAND_TYPE,
	REFRESH_GRAND_TYPE:process.env.REACT_APP_REFRESH_GRAND_TYPE,
	SCOPE:process.env.REACT_APP_SCOPE,
	WEB_REDIRECT_URL:process.env.REACT_APP_WEB_REDIRECT_URL,
}


exports.addDeviceId = function(){
	data.DEVICE_UNIQUE_ID = deviceVar.getDeviceUniqueId();
	data.ACCESS_TOKEN = tokenVar.getAccessTokenId();
}

exports.addClassToHtml = function() {
  let html = document.getElementsByTagName("html");
  html[0].classList.add("ReactModal__Html--open");
};
exports.removeClassToHtml = function() {
  let html = document.getElementsByTagName("html");
  html[0].classList.remove("ReactModal__Html--open");
};
exports.addClassToBody = function() {
  let body = document.getElementsByTagName("body");
  body[0].classList.add("ReactModal__Body--open");
};
exports.removeClassToBody = function() {
  let body = document.getElementsByTagName("body");
	body[0].classList.remove("ReactModal__Body--open");
	let scrollval = sessionStorage.getItem("scrollVal");
	if (scrollval) {
		console.log(scrollval);
		window.scrollTo(0, parseInt(scrollval));
		sessionStorage.removeItem("scrollVal");
	}

};

/**
 *
 *	Middleware function
 * 
 */

let internetMiddleware = function(apiMethod,args){
	let resp = apiMethod(data,args);
	return resp.then(function(response){
		// success code
		return resp;
	}).catch(function(error){
		let http_code = undefined;
		if(error.response !== undefined){
			http_code = error.response.status;
		}
		if(!http_code || http_code === undefined){
			resp = Promise.reject({
				response : {
					status 	: 1000,
					error 	: 'please check your internet connection'
				}
			});
		}
		return resp;
	});
}

let deviceMiddleware = function(apiMethod, args, redirect = false, attempts = 1){
	if(localStorage.getItem('device_unique_id') && localStorage.getItem('device_unique_id').length > 25){
		return middleWare(apiMethod, args, redirect, attempts);	
	}
	else{
		
		let device_unique_id = deviceVar.getDeviceUniqueId();
		if(device_unique_id.length === 0){
			device_unique_id = deviceVar.generateDeviceUniqueId();
			deviceVar.setDeviceUniqueId(device_unique_id);
			data.DEVICE_UNIQUE_ID = device_unique_id;
		}
		
		return registerDevice().then(function(){
			return middleWare(apiMethod, args, redirect, attempts);	
		});	
	}
	
}

let middleWare = function(apiMethod, args, redirect = false, attempts = 1){


	let resp = apiMethod(data,args);
	let refresh_token = localStorage.getItem("refresh_token");



	if(attempts > 4){
		return resp;
	}


	return resp.then(function(response){
		// success code
		return resp;

	}).catch(function(error){
		//console.log('From m', error.response.status);
		if(error.response.status === 500 || error.response.status === 502 || error.response.status === 503){
			//console.log('sfsf');
			resp = Promise.reject({
				response : {
					status 	: 500,
					error 	: 'server error'
				}
			});
			return resp;
		}
		//
		// authentication issues handle here
		//	
		let http_code = undefined;
		let error_obj = {};
		let device_err_flag = false;
		if(error.response !== undefined){
			http_code = error.response.status;
			error_obj = error.response.data.error;

		}
		
		if(Object.keys(error_obj).length){
			for(var k in error_obj){
				if(error_obj[k].key === "device-unique-id"){
					device_err_flag = true;
				}
			}
		}
			
		if(http_code === 400 && device_err_flag){
			return registerDevice().then(function(){
				return middleWare(apiMethod, args, redirect, attempts+1);	
			});
		}
		else if(http_code === 401){
			
			/**
			 * Token expiry check
			 */

			return refreshLoginApi.refreshLogin(data, refresh_token).then(function(resp){
				// write success code
				//alert("refresh success");		
				login(resp.data.access_token, resp.data.refresh_token);
				return middleWare(apiMethod, args, redirect, attempts+1);	
			}).catch(function(err){
				http_code = err.response.status;
				if(http_code === 400 || http_code === 401){	
					logout();
					saveUrlLogin();
					window.location.replace('/signup');	
				}

	        });	
		}else{

			if(!http_code || http_code === undefined){
				resp = Promise.reject({
					response : {
						status 	: 1000,
						error 	: 'please check your internet connection'
					}
				});
			}
			return resp;
		}
	});
}

/**
 *
 *	Middleware ends
 * 
 */



let login = function(access_token, refresh_token){
	//console.log(access_token, refresh_token);
	tokenVar.setAccessTokenId(access_token);
	tokenVar.setRefreshTokenId(refresh_token);
	data.ACCESS_TOKEN = access_token;
}
exports.login = login;





let logout = function(){
	data.ACCESS_TOKEN = '';
	localStorage.removeItem('access_token');
	localStorage.removeItem('refresh_token');
	localStorage.removeItem('contact_number');
    localStorage.removeItem('referral_code');
    localStorage.removeItem("is_user_referred");
    localStorage.removeItem("request_count");
	localStorage.removeItem("recentSearch");
	localStorage.removeItem("profile_image");
	localStorage.removeItem("last_name");	
	localStorage.removeItem("first_name");	
	localStorage.removeItem("SkipModal");	
}
exports.logout = logout; 



exports.hasAccessToken = function () {
	return   (data.ACCESS_TOKEN === null ) ? false : ((data.ACCESS_TOKEN.length > 0) ? true : false);
}

exports.getURLParameter = function(url, name) {
	return decodeURIComponent((new RegExp('[?|&]' + name + '=([^&;]+?)(&|#|;|$)').exec(url) || [null, ''])[1].replace(/\+/g, '%20')) || null;
}

exports.getAddressDetails = function(address) {
	let location = {
		country: '',
		state: '',
		city: '',
		area: '',
	}
	for (let one_address of address.address_components)
	{
		if (one_address.types.indexOf('country') > -1)
		{
			location.country = one_address.short_name;
		}

		if (one_address.types.indexOf('administrative_area_level_1') > -1)
		{
			location.state = one_address.long_name;
		}

		if (one_address.types.indexOf('locality') > -1)
		{
			location.city = one_address.long_name;
		}

		if (location.city === '' && one_address.types.indexOf('administrative_area_level_2') > -1)
		{
			location.city = one_address.long_name;
		}

		if (one_address.types.indexOf('sublocality_level_1') > -1)
		{
			location.area = one_address.long_name;
		}
	}

	return location;
}

exports.removeURLParameter = function(url, name) {
	url = url.replace("?", "");
	let urlParams = url.split("&");
	if(urlParams.length > 0){
		for(let i =0; i < urlParams.length; i++){
				let keyValuePair = urlParams[i].split("=");
				let key = keyValuePair[0];
				// let value = keyValuePair[1];
				if(key.toLowerCase() === name.toLowerCase()){
					urlParams.splice(i, 1);
				}
		}
	}
	return "?"+urlParams.join("&");
}
exports.updateURLParameter = function(url, name, newValue) {
	url = url.replace("?", "");
	let nameFound = false;
	let urlParams = url.split("&");
	if(urlParams.length > 0){
		for(let i =0; i < urlParams.length; i++){
				let keyValuePair = urlParams[i].split("=");
				let key = keyValuePair[0];
				// let value = keyValuePair[1];
				if(key.toLowerCase() === name.toLowerCase()){
					urlParams[i] = key+"="+newValue;
					nameFound = true;
				}
		}
	}
	if(!nameFound){
		urlParams.push(name+"="+newValue);
	}
	
	return "?"+urlParams.join("&");
}



exports.toDate = (date, return_value = false) => {

	if (typeof date === 'undefined' || !date || date === 1) {
		return (return_value) ? return_value : false;
	}


	date = (typeof date === 'string') ? new Date(date) : date;
	return date;
}

exports.ddMMYYYY = (date, return_value = '') => {

	if (typeof date === 'undefined' || !date || date === 1 || date === 'NAN') {
		return (return_value) ? return_value : '';
	}

	date = (typeof date === 'string') ? new Date(date) : date;

	let dd = date.getDate();
	let mm = date.getMonth() + 1; //January is 0!

	let yyyy = date.getFullYear();
	if (dd < 10) {
		dd = '0' + dd;
	}
	if (mm < 10) {
		mm = '0' + mm;
	}
	return dd + '-' + mm + '-' + yyyy;
}

exports.dateFormat = function(date){


	if (typeof date === 'undefined' || !date || date === 1) {
		return '';
	}

	date = (typeof date === 'string') ? new Date(date) : date;
	const months = ["Jan", "Feb", "Mar", "Apr", "May", "Jun","Jul", "Aug", "Sep", "Oct", "Nov", "Dec"];

	let dd,dd2 = date.getDate();
	let mm,mm2 = date.getMonth();

	if (dd < 10) {
		dd2 = '0' + dd;
	}
	if (mm < 10) {
		mm2 = '0' + mm;
	}
	return dd2+' '+months[mm2];
}

// stop scroll on modal open
exports.stopBodyScroll = function(){
	originalBodyOverflow = document.body.style.overflow;
	positionBody = document.body.style.position;
	document.body.style.overflow = 'hidden';
	document.body.style.position = 'fixed';
}
exports.resumeBodyScroll = function(){
	document.body.style.overflow = originalBodyOverflow
	document.body.style.position = positionBody
}

exports.tileRatingColor = function(rating) {
	let ratingColor;
	if(rating < 1.6){
		ratingColor = 'rating-1';
	} else if (rating >= 1.6 && rating < 2.5){
		ratingColor = 'rating-2';
	} else if (rating >= 2.5 && rating < 3.5){
		ratingColor = 'rating-3';
	} else if (rating >= 3.5 && rating < 4.5){
		ratingColor = 'rating-4';
	}else {
		ratingColor = 'rating-5';
	}
	return ratingColor;
}

let saveUrlLogin = function(){
	sessionStorage.setItem('UrlAfterLogin', window.location.pathname+''+window.location.search);
};
exports.saveUrlLogin=saveUrlLogin;

/* export Apis */
// device
let registerDevice = function(args) { return deviceRegisterApi.registerDevice(data, args); };
exports.registerDevice = registerDevice;


let isDeviceRegistered = function(args) { return deviceRegisterApi.isDeviceRegistered(data, args); }
exports.isDeviceRegistered = isDeviceRegistered;


// // home
// exports.getHomePageDataApi = function(args) { 
// 	let resp = middleWare(homeApi.getHomePageDataApi, args);
// 	return resp;
// };

exports.getHomePageDataApi = function(args) { 
	// let resp = homeApi.getHomePageDataApi(data, args);
	// console.log("\n \nResp",resp,"\n\n\n");
	// return resp;
	return  deviceMiddleware(homeApi.getHomePageDataApi, args);
};

exports.getCollectionListDataApi = function(args) {
	return  deviceMiddleware(collectionListApi.getCollectionListDataApi, args);
};

exports.getCollectionPageDataApi = function(args) { 
	return  deviceMiddleware(collectionApi.getCollectionPageDataApi, args);
};

// Signup
exports.getSignUpApi = function(args) { return signUpApi.getSignUpApi(data, args); };


// otp Send
exports.getOtpSendApi = function(args) { return otpSendApi.getOtpSendApi(data, args); };

// otp verify
exports.getOtpVerifyApi = function(args) { return otpVerifyApi.getOtpVerifyApi(data, args); };

// Get Dial Codes
exports.getDialCodesApi = function(args) { return getDialCodes.getDialCodesApi(data, args); };

// login
exports.getLoginApi = function(args) { return loginApi.getLoginApi(data, args); };
exports.refreshLogin = function(args) { return refreshLoginApi.refreshLogin(data, args); };

// login
exports.getLogoutApi = function(args) { return logoutApi.getLogoutApi(data, args); };


// resetPassword
exports.getResetPasswordApi = function(args) { return resetPasswordApi.getResetPasswordApi(data, args); };

// forget password
exports.getForgetPasswordApi = function(args) { return ForgetPasswordApi.getForgetPasswordApi(data, args); };

// cancel bookin request
exports.getCancelBookingRequestDataApi = function(args) { 
	return deviceMiddleware(CancelBookingRequestApi.getCancelBookingRequestDataApi, args); 
};

// // tokens
// exports.setAccessTokenId = function(args) { return tokenVar.setAccessTokenId(data, args); };
// exports.setRefreshTokenId = function(args) { return tokenVar.setRefreshTokenId(data, args); };


// Search
exports.getSearchApi = function(args) { 
	var resp = deviceMiddleware(searchApi.getSearchApi, args, true);
	return  resp;
};

// editprofile put data
exports.getEditprofileApi = function(args) { return editProfileApi.getEditprofileApi(data, args); 
};
// ecentlyViewAllApi put data
exports.getRecentlyViewAllApi = function(args) { return recentlyViewAllApi.getRecentlyViewAllApi(data, args); 
};
// ecentlyViewAllApi put data
exports.getSimilarListingDataApi = function(args) { return similarListing.getSimilarListingDataApi(data, args);
};
exports.getDeactivateAccountApi = function(args) { return deactivateAccount.getDeactivateAccountApi(data, args); 
};
// update profile data
exports.getUpdateProfileApi = function(args) { return updateProfileImage.getUpdateProfileApi(data, args); };

// varify email
exports.getVarifyEmailApi = function(args) { return emailVarifyApi.getVarifyEmailApi(data, args); };

// goofle login
exports.SignupLoginSocial = function(args) { 
	var resp = deviceMiddleware(socialLogin.SignupLoginSocial, args, true);
	return  resp;
	//return googleLogin.SignupLoginGoogle(data, args); 
};

// varify email success
exports.getEmailVarifySuccessApi = function(args) { return emailVarifySuccessApi.getEmailVarifySuccessApi(data, args); };

//account
exports.getLoginAccountDataApi = function(args) { 
	var resp = deviceMiddleware(accountApi.getLoginAccountDataApi, args, true);
	return  resp;
};


// Search
exports.getPopularSearchApi = function(args) { return popularSearchApi.getPopularSearchApi(data, args); };

// property listing
exports.getPropertyLinstingDataApi = function(args) { return propertyLinsting.getPropertyLinstingDataApi(data, args); };


//wishtlist
// exports.getWishlistDataApi = function(args) { return wishlistApi.getWishlistDataApi(data, args); };
exports.getWishlistDataApi = function(args) { 
	return  internetMiddleware(wishlistApi.getWishlistDataApi, args);
};

exports.getWishlistAddDataApi = function(args) {
	 return deviceMiddleware(wishlistAddApi.getWishlistAddDataApi, args); 
};
exports.getWishlistDeleteDataApi = function(args) { 
	return deviceMiddleware(wishlistDeleteApi.getWishlistDeleteDataApi, args); 
};

//property preview data api
exports.getPropertyPreviewDataApi  = function(args) { 
	return  deviceMiddleware(PropertyPreviewApi.getPropertyPreviewDataApi, args);
};
// review list on proptery preview api
exports.getPropertyReviewDataApi = function(args){
	return deviceMiddleware(propertyReviewApi.getPropertyReviewDataApi, args);
};

// review list on proptery preview api
exports.getreferAndEarnDataApi = function(args){
	return deviceMiddleware(referAndEarnApi.getreferAndEarnDataApi, args);
};

//proepayment data api
exports.getPrepaymentDataApi  = function(args) { 
	return deviceMiddleware(PrepaymentApi.getPrepaymentDataApi, args); 
};
exports.getRequestPrepaymentDataApi  = function(args) { 
	return deviceMiddleware(RequestPrepaymentApi.getRequestPrepaymentDataApi, args); 
};


//request details data api
exports.getRequestDetailsDataApi  = function(args) {
	return deviceMiddleware(requestDetailsApi.getRequestDetailsDataApi, args);
 };
exports.getRequestDetailsApi  = function(args) { 
	return deviceMiddleware(requestDetailsDataApi.getRequestDetailsApi, args); 
};
exports.getInstantBookPaymentApi  = function(args) { 
	return deviceMiddleware(InstantBookPaymentApi.getInstantBookPaymentDataApi, args);
 };
exports.getRequestPaymentApi  = function(args) { 
	return deviceMiddleware(RequestPaymentApi.getRequestPaymentDataApi, args);
 };

exports.getResendRequestApi  = function(args) { 
	return deviceMiddleware(resendRequestApi.getResendRequestApi, args);
 };
//trip api
exports.getTripApi  = function(args) { 
	return deviceMiddleware(tripApi.getTripApi, args);
};


//sent email
exports.getInvoiceSendApi = function(args) { 
	//console.log(invoiceSentApi, invoiceSentApi.getInvoiceSendApi)
	var resp = deviceMiddleware(invoiceSentApi.getInvoiceSendApi, args, true);
	return  resp;
};

exports.getTripDetailApi  = function(args) {
	 return deviceMiddleware(tripDetailApi.getTripDetailApi, args);
};


// wallet api
exports.getWalletApi = function(args){
	return deviceMiddleware(walletApi.getWalletApi, args);
};

// get currency change
exports.getCurrencyChangeApi = function(args){return currencyChangeApi.getCurrencyChangeApi(data, args);};

// currency list api
exports.getCurrencyListApi = function(args){return currencyListApi.getCurrencyListApi(data, args);};

// changepassword api
exports.getChangePasswordApi = function(args){return changePasswordApi.getChangePasswordApi(data, args);};
// pricing calender api
exports.getPricingCalendarDataApi = function (args) { return pricingCalendarApi.getPricingCalendarDataApi(data, args); };

// my listing
exports.getPropertyLinstingDataApi = function (args) { return myListing.getPropertyLinstingDataApi(data, args); };

exports.getRedirectUrl = function () {
	return data.WEB_REDIRECT_URL;
}
