import React from 'react';
import Modal from 'react-modal';
import ResponseMessage from '../../response/';
import {connect} from 'react-redux';
import { updateUserStateObj} from '../../../actions/setting/actions';

// services
import {getVarifyEmailApi} from '../../../services/index.js';
import { checkIfOffline } from '../../../services/helpers';

var Form = require('react-formal');
var yup = require('yup');
var useResetPasswordSchema = yup.object({
	email: yup.string().required('Email required').email('Invalid email'),
});
class UpdateEmail extends React.Component {
	 constructor() {
		super();
		this.changeHandle = this.changeHandle.bind(this);
		this.handleSubmit = this.handleSubmit.bind(this);  
        this.submitEamilVarifySuccessDetail = this.submitEamilVarifySuccessDetail.bind(this);  
        this.getVarifyEmailSuccessApiDataError=this.getVarifyEmailSuccessApiDataError.bind(this);
        this.getVarifyEmailSuccessApiDataSuccess=this.getVarifyEmailSuccessApiDataSuccess.bind(this);
		this.handleChange = this.handleChange.bind(this);
		this.formErrorHandle = this.formErrorHandle.bind(this);
		this.onBlurShowMessage=this.onBlurShowMessage.bind(this);
        this.onFocusHideMessage=this.onFocusHideMessage.bind(this);
        this.resetMessages = this.resetMessages.bind(this);
	}
	resetMessages(){
		this.props.updateUserStateObj({
			'message':'',
			'show':false,
		});
	}
	handleChange(event, key) {
      this.props.updateUserStateObj({[key]: event.target.value});
    }

	handleSubmit(event) {
		if (checkIfOffline()) {
			this.props.toggleStatus()
			return
		}
		this.props.updateUserStateObj().then(() => {
			this.submitEamilVarifySuccessDetail({
				'email': this.props.user.model.email,
			});
		})
       
    }

	changeHandle(model){
		this.props.updateUserStateObj({ model });
	}

	submitEamilVarifySuccessDetail(args){
        let this_el = this;
        getVarifyEmailApi(args).then(function(response){
            // success
            this_el.getVarifyEmailSuccessApiDataSuccess(response);
        }).catch(function(error){
			// error
			//console.log(error)
			if(error.hasOwnProperty('response') && error.response === undefined){
                let networkError = {
                    response:{
                        data:{
							
								message:'Please check your internet connection'	
							
                        }
                    }
                }
               // this_el.setState({status:false});
                this_el.getVarifyEmailSuccessApiDataError(networkError);
            }else{
				this_el.getVarifyEmailSuccessApiDataError(error);
			}
        });
    }
    getVarifyEmailSuccessApiDataError(error) {
        let this_el = this;
        this_el.props.updateUserStateObj({
            'message': error.response.data.message,
			'status':false,
			'show':true,
        });
    }
    
    getVarifyEmailSuccessApiDataSuccess(response) {
    	const this_el=this;
        this_el.props.updateUserStateObj({
			'message': response.data.data.message,
			'show':true,
        });
        this_el.props.VarifyEmailModalOpen(this_el.props.user.model.email);
    }
    
    formErrorHandle(errors){
        for (var property in errors) {
            if (errors.hasOwnProperty(property) && errors[property] !== undefined && errors[property].length > 1) {
                errors[property] = errors[property][0]['message'];
                //console.log("\n\n\n\n", property, errors[property],errors[property].length);
            }
        }
    }
    onFocusHideMessage(){
        this.props.updateUserStateObj({ validation:false,});
    }
    onBlurShowMessage(){
        this.props.updateUserStateObj({ validation:true,});
    }
   
  render() {
  	const this_el = this;
	return (
	  <div>
		<Modal
		  isOpen={this.props.updateEmailModalOpen}
		  onAfterOpen={this.afterOpenModal}
		  onRequestClose={this.props.updateEmailModalClose}
		  contentLabel="Modal"
				overlayClassName={{
				base: 'overlay-modal overlay-popup',
				afterOpen: '',
				beforeClose: ''
			  }}
		  className={{
				base: 'content-modal',
				afterOpen: 'editProfile changepassword',
				beforeClose: ''
			  }}
			>
		  <div className="modal-body text-center">
			<span className="back-btn" onClick={this.props.goBack}></span>
			<div className="verify-text1 text-center">Would you like to update your<br/> email address with us?</div>
			<Form schema={useResetPasswordSchema} 
				onSubmit={this.handleSubmit}
				onChange={model => this.changeHandle(model)}
				//errors={this.state ? this.state.errors : {}}
                onError={errors => this.formErrorHandle(errors)}
				>	
				<Form.Field type="email" className="input-box" autocomplete="off" name="email" placeholder="Enter email" onFocus={this.onFocusHideMessage} onBlur={this.onBlurShowMessage} value={this.props.user.model.email}/>
				{this.props.user.validation &&
					<Form.Message for={['email']}/>
				}
				<br/>
				<div className={'response-msg '+this_el.props.user.show }>
					<ResponseMessage responseMessage={this_el.props.user.message} responseStatus={this_el.props.user.status}  resetMessages={this_el.resetMessages}/>
				</div>
				<Form.Button  type='submit'  className="link green" >Update email</Form.Button>
			</Form>

		  </div>
		</Modal>
		
	  </div>
	);
  }
}
const mapStateToProps = (store) =>{
	return{
	   user : store.UserChangeData
   }
}
const mapDispatchToProps = {
   updateUserStateObj
}
export default connect(mapStateToProps, mapDispatchToProps) (UpdateEmail);