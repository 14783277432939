import React from "react";
import noInternet from "../../static/img/infoWindow/noInternet.svg";
import SearchTile from "../../components/propertyTile";
import { connect } from "react-redux";
import serverError from "../../static/img/serverError.png";
/**
 * Services
 */
import { updatePropertyState } from "../../actions/property/actions";
import { updateBookingDataState } from "../../actions/booking-data/actions";
import {
  getPropertyLinstingDataApi,
  getURLParameter
} from "../../services/index.js";
import { updateErrorState } from "./../../actions/error/actions";
/**
 * Actions
 */
import { updateProfileStateObj } from "../../actions/profile/actions";

//
import { trackError } from "../../services/tracker/events.js";
import OfflineStatus from "../../components/Offlinestatus";
import { checkIfOffline } from "../../services/helpers";

class MyListing extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      host_name: getURLParameter(this.props.location.search, "host_name"),
      is_host: getURLParameter(this.props.location.search, "is_host"),
      user_id: getURLParameter(this.props.location.search, "user_hash_id"),
      toggleOfflineStatus: false
    };
    this.getMyListingApiDataSuccess = this.getMyListingApiDataSuccess.bind(
      this
    );
    this.getMyListingApiDataError = this.getMyListingApiDataError.bind(this);
    this.toProperty = this.toProperty.bind(this);
    console.log(props);
  }

  getPropertyListing(data) {
    let this_el = this;
    getPropertyLinstingDataApi(data)
      .then(function(response) {
        this_el.props.updateProfileStateObj({
          property_listings_all: response.data.data.properties
        });
      })
      .catch(function(error) {
        console.log("error");
      });
  }
  getMyListingApiDataSuccess(response) {
    const this_el = this;
    // pulling data from API response

    let flag = Object.assign({}, this_el.props.profile.flag);
    flag.no_more_result = response.data.data.properties.length === 0; // if no more result flag found, set no_more_result to true
    flag.api.is_loading = false;

    let properties = Object.assign(
      [],
      this_el.props.profile.property_listings_all
    );

    Array.prototype.push.apply(properties, response.data.data.properties);

    // updating single collection store
    this_el.props
      .updateProfileStateObj({
        property_listings_all: properties.filter(
          (x, i, arr) =>
            arr.findIndex(y => y.property_id === x.property_id) === i
        ),
        flag: flag
      })
      .then(() => {
        // checking is data updated
        //console.log('after data updated',this_el.props.home.recently_viewed_properties_all);
        let error_flag = Object.assign({}, this_el.props.error.flag);
        error_flag.network = false;
        error_flag.server = false;
        this_el.props.updateErrorState({
          flag: error_flag
        });
      });
  }

  getMyListingApiDataError(error) {
    const this_el = this;
    if (
      typeof error.response !== "undefined" &&
      error.response.status === 1000
    ) {
      let flag = Object.assign({}, this_el.props.error.flag);
      flag.network = true;
      this_el.props.updateErrorState({
        flag: flag
      });
    } else if (error.response.status === 500) {
      //console.log('dgdg',error);
      let flag = this.props.error.flag;
      flag.server = true;
      this.props.updateErrorState({ flag: flag });
    } else {
      trackError(error);
    }
  }

  componentDidMount() {
    if (checkIfOffline()) {
      this.props.updateErrorState({
        flag: {
          network: true
        }
      });
      this.toggleStatus();
    }
  }

  toProperty(data) {
    const this_el = this;

    if (data !== undefined) {
      this_el.props.updatePropertyState({
        property_hash_id: data.property_hash_id,
        property_images: data.property_images,
        property_id: data.property_id,
        property_title: data.property_title,
        title: data.title,
        property_score: data.property_score,
        host_image: data.host_image,
        host_name: data.host_name,
        apiData: false,
        tags: data.property_tags,
        property_type: data.property_type_name
      });
      this_el.props.updateBookingDataState({
        location: data.location.location_name,
        lat: data.location.latitude,
        lng: data.location.longitude,
        checkin_date: 1,
        checkout_date: 1,
        guest_count: 0,
        unit_count: 0
      });
    }

    this_el.props.history.push({
      pathname: "/property/" + data.property_hash_id
    });
  }
  getMyListingApi(args) {
    let this_el = this;
    getPropertyLinstingDataApi(args)
      .then(function(response) {
        // success
        this_el.getMyListingApiDataSuccess(response);
      })
      .catch(function(error) {
        // error
        this_el.getMyListingApiDataError(error);
      });
  }
  componentWillMount() {
    const this_el = this;
    //trackPageView(this_el.props.tracker, this_el.props.single_collection.page);
    /**
     * Calling Api
     */
    this_el.submitParams();
    /**
     * Active scroll listner
     */
    this_el.loadMoreResults();
  }
  submitParams() {
    const this_el = this;
    let args = {
      id: this_el.state.id,
      offset: this_el.props.profile.offset,
      user_id: this_el.state.user_id,
      loader: true
    };
    this_el.getMyListingApi(args);
    //this_el.getColllectionListApiData(args);
  }

  loadMoreResults() {
    const this_el = this;

    window.onscroll = function() {
      //let offset = document.documentElement.scrollTop;
      const scrollTopOffset = Math.max(
        window.pageYOffset,
        document.documentElement.scrollTop,
        document.body.scrollTop
      );

      let windowHeight = window.innerHeight;
      let bodyHeight = document.body.clientHeight - 260;
      let offsetWindowHeight = windowHeight + scrollTopOffset;

      if (
        offsetWindowHeight >= bodyHeight &&
        !this_el.props.profile.flag.api.is_loading &&
        !this_el.props.profile.flag.no_more_result &&
        !checkIfOffline()
      ) {
        /**
         * Updating api state to true, so in case of scroll no more api hit called, untill last api result popup
         */

        let new_offset = this_el.props.profile.offset + 5;
        let flag = Object.assign({}, this_el.props.profile.flag);
        flag.api.is_loading = true;

        //console.log((offsetWindowHeight >= bodyHeight) , !this_el.props.collection.flag.api.is_loading);
        this_el.props
          .updateProfileStateObj({
            offset: new_offset,
            flag: flag
          })
          .then(() => {
            this_el.submitParams();
          });
      }
      if (offsetWindowHeight >= bodyHeight && checkIfOffline()) {
        this_el.toggleStatus();
      }
    };
  }

  toggleStatus = () => {
    this.setState(
      {
        toggleOfflineStatus: !this.state.toggleOfflineStatus
      },
      () => {
        this.setState({
          toggleOfflineStatus: !this.state.toggleOfflineStatus
        });
      }
    );
  };

  reload = () => {
    const this_el = this;
    /**
     * Calling Api
     */
    this_el.submitParams();
    /**
     * Active scroll listner
     */
    this_el.loadMoreResults();
  };
  render() {
    const this_el = this;
    return (
      <div className="recently-view">
        <div className="collection-list-container">
          <header className="header active">
            <div className="row">
              <div className="col-12 text-left">
                <span
                  className="back-icon"
                  onClick={() => this_el.props.history.goBack()}
                />
                <span className="middle-text text-center">
                  {this.state.is_host === "1" ? (
                    <span>{this.state.host_name}'s Listings</span>
                  ) : (
                    <span>My Listings</span>
                  )}
                </span>
              </div>
            </div>
          </header>
          {!this_el.props.error.flag.network ||
          this.props.profile.property_listings_all.length > 0 ? (
            <div>
              {!this.props.error.flag.server ? (
                <div>
                  <OfflineStatus
                    offline={
                      checkIfOffline() && !this.state.toggleOfflineStatus
                    }
                    bottom
                    dontClear
                  />
                  <div className="collection-list" ref="myDiv">
                    <SearchTile
                      properties_list={this.props.profile.property_listings_all}
                      editFinalList={this_el.props.updateWishlist}
                      toProperty={this_el.toProperty}
                    />
                  </div>
                </div>
              ) : (
                <div className="info-window">
                  <div className="window-container">
                    <div className="imgb">
                      <div
                        className="img"
                        style={{ backgroundImage: "url(" + serverError + ")" }}
                      />
                    </div>
                    <div className="txtb">
                      <p>
                        Sorry, our servers are busy. Please try again in a bit.
                      </p>
                    </div>
                    <button className="retry" onClick={this.reload}>
                      Retry
                    </button>
                  </div>
                </div>
              )}
            </div>
          ) : (
            <div className="info-window">
              <div className="window-container">
                <div className="imgb">
                  <div
                    className="img"
                    style={{ backgroundImage: "url(" + noInternet + ")" }}
                  />
                </div>
                <div className="txtb">
                  <p>Oops! You don't seem to be connected to the internet.</p>
                </div>
                <button className="retry" onClick={this.reload}>
                  Retry
                </button>
              </div>
            </div>
          )}
        </div>
        {!this_el.props.profile.flag.no_more_result &&
        this_el.props.profile.flag.api.is_loading ? (
          <div className={"loader"}>
            <div className="timeline-item">
              <div className="animated-background facebook" />
            </div>
          </div>
        ) : (
          <p className="text-center">
            {!checkIfOffline() && "No more result!"}
          </p>
        )}
      </div>
    );
  }
}
const mapStateToProps = store => {
  return {
    profile: store.userProfile,
    tracker: store.tracker.tracker,
    error: store.error
  };
};
const mapDispatchToProps = {
  updateProfileStateObj,
  updatePropertyState,
  updateBookingDataState,
  updateErrorState
};
export default connect(
  mapStateToProps,
  mapDispatchToProps
)(MyListing);
