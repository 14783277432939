import React from 'react';
import Rheostat from 'rheostat';


var algo = require('./algo');
algo = _interopRequireDefault(algo);

var log10algo = require('./log10algo');
log10algo = _interopRequireDefault(log10algo);

function _interopRequireDefault(obj) { 
	return obj && obj.__esModule ? obj : { 'default': obj }; 
}

class RheoPriceSlider extends React.Component {
	constructor(props) {
		super(props);
 		this.state = {
 			...props.value
 		}
		this.timeout = null

		props.attachResetHandler(this.resetSlider)

 		this.local = {
 			range : [],
			new_range : [],
			step_shift : {},
			step : 0,
			i :  this.state.min,
			count : 0 ,
			shift_count: 1,
 		}


 		/**
 		 * Step functions
 		 */
 		
 		this.step_calc = this.step_calc.bind(this);
 		this.range_calc = this.range_calc.bind(this);
 		this.get_range = this.get_range.bind(this);
	}

	/**
	 * Step Calculator
	 */
	step_calc(i,step_min){
		if(step_min<=1)
		{
		return 1;
		}
		else if(i>=step_min && i<step_min*2)
		{
		return Math.floor(step_min/10);
		}
		else if(i>=step_min*2 && i<step_min*4)
		{
		return Math.floor(step_min/10)*2;
		}
		else
		{
		return Math.floor(step_min/10)*5;
		}
	}


	/**
	 * Calculating range
	 */
	range_calc(){

		const this_el = this;
		do{
			this_el.local.range.push(this_el.local.i); 
			
			let length = this_el.local.i.toString().length;
			let step_min = Math.pow(10,length-1);
			
			let prev_step = this_el.local.step;
			if(this_el.local.i ===  this_el.state.min){
				prev_step = this_el.step_calc(this_el.local.i,step_min);
				// console.log("inside i=====" + prev_step);
			}
			
			this_el.local.step = this_el.step_calc(this_el.local.i,step_min);
			 // console.log(i + "----------------------" + prev_step);
			if(this_el.local.step === prev_step){
				this_el.local.count++;
				// console.log("step count====>>> " +  count);
			}else{
				this_el.local.step_shift[prev_step] = this_el.local.count ;
				// console.log("step is ===>>" + step);
				this_el.local.count = 1;
				this_el.local.shift_count = this_el.local.shift_count +1;
			}
			if((this_el.local.i + this_el.local.step) === this_el.state.max){
				this_el.local.step_shift[this_el.local.step] = this_el.local.count ;
			}
			// console.log("prev step" + prev_step);
			this_el.local.i += this_el.local.step;

		} while(this_el.local.i < this_el.state.max)



		if(this_el.local.range[this_el.local.range.length-1] !== this_el.state.max){
			this_el.local.range.push(this_el.state.max);
		}

		//console.log(this_el.local.range);
		return true;
		
	}

	componentWillReceiveProps(nextProps) {
		if (this.state.min !== nextProps.recentValue.min || this.state.max !== nextProps.recentValue.max)	{
			this.setState({
				...nextProps.recentValue
			})
		}
	}

	/**
	 * Getting range
	 */
	get_range(){

		const this_el = this;

		/**
		 * calculate range
		 */
		let rangeCalculated = this_el.range_calc();

		if(rangeCalculated){

			const range = this_el.local.step_shift;
			var count = 1;	
			var total = Object.keys(range).length
			var percentage_key = 0;
			var start_value =0;
		 	var percent = parseInt(100/total, 10);
		 	var newrange = {};
		  	var prev_key = 0;
		  	for (var key in range)
			{
				if(count === 1){
					start_value = this_el.state.min;
					//newrange['min'] = [this_el.state.value.min, key];
				}else{
					percentage_key = percent + (total - (count -1)) + percentage_key;
					// console.log(percent + "-----" + total + "-----" + count +  "-----" + percentage_key);	
					start_value = range[prev_key] * prev_key  + start_value;
			    	newrange[percentage_key+"%"] =  [  start_value , key ]
			    	 total--;
		    	}
			   count++;
			   prev_key = key;
			}
		 	//newrange['max'] = [this_el.state.value.max,0];
		  	return newrange;
		}

		return {};

	}
	 
	 resetSlider = () => {
		this.setState({
			max:this.props.slider.max,
			min: this.props.slider.min,
		 });
	 }

	render(){
		const this_el = this;
		return (
			<div>
				{ this_el.state.min && this.state.max &&  
							<div>
								<Rheostat 
								 		min={this.props.slider.min}
								    max={this.props.slider.max}
								    values={[this.state.min, this.state.max]}
								    algorithm={log10algo['default']}
								    onValuesUpdated={function(sliderstate){
								    	//console.log(sliderstate);
										let min_budget =  sliderstate.values[0];
										let max_budget =  sliderstate.values[1];
								    	this_el.setState({
												min: min_budget,
												max: max_budget
											});
								    }}

								    onChange={function(sliderstate){
											let min_budget = sliderstate.values[0]
											let max_budget = sliderstate.values[1]
											this_el.props.helper(min_budget, max_budget);
											this_el.setState({
												min: min_budget,
												max: max_budget
											});
									}}

								/>
								<Values webicon={this.props.webicon} {...this.state} slider={this.props.slider} />
							</div>
				}
			</div>
		);
	}
}

const Values = ({ webicon, min, max, slider }) => (
	<>
		<div className='price-label-min'>
			{webicon} {min.toLocaleString('en-IN')}
			</div> 
		<div className='price-label-max'>
			{webicon} {max.toLocaleString('en-IN')}{max === slider.max && '+'}
		</div>
	</>
);


export default RheoPriceSlider;