import React from 'react';
import { connect } from "react-redux";

class Invoice extends React.Component {
    render() {
        return (
            <div className="invoice">
                <div className="row">
                    <div className="col-6">
                        <div className="heading"><span className="invoice-icon"></span> Invoice</div>
                    </div>
                    <div className="col-6">
                        <div className="requestID">Request ID : A134FCV</div>
                    </div>
                </div>
                <div className="invoice-details">
                    <section className="first">
                        {this.props.prepayment.invoice.invoice_header.map(function(item, index){
                            if(item.show === 1){
                                return(
                                    <div className="cell" key={index}>
                                        <div className="row">
                                            <div className="col-8 text-left">
                                                <div className="title">{item.key}</div>
                                                <div className="subtitle">{item.sub_key}</div>
                                            </div>
                                            <div className="col-4 text-right">                                    
                                                <div className="cost">{item.value}</div>
                                            </div>
                                        </div>
                                    </div>
                                )
                            } else {
                                return false
                            }
                        })}
                    </section>
                    <section className="second">
                        {this.props.prepayment.invoice.invoice_middle.map(function(item, index){
                            const color =item.hasOwnProperty("color") ? {color : item.color} : {};
                            const bold =item.hasOwnProperty("bold") ? {fontWeight:'bold',color: '#7b7b7b', fontSize:'16px'}:{};
                            if(item.show === 1){
                                return(
                                    <div className="cell" key={index}>
                                        <div className="row">
                                            <div className="col-8 text-left">
                                                {item.bold === 1 ?
                                                <div className="title" style={bold}>{item.key}</div>
                                                :
                                                <div className="title" style={color}>{item.key}</div>
                                                }
                                                <div className="subtitle">{item.sub_key}</div>
                                            </div>
                                            <div className="col-4 text-right">   
                                                {item.bold === 1 ?                                 
                                                <div className="cost" style={bold}>{item.value}</div>
                                                :
                                                <div className="cost" style={color}>{item.value}</div>
                                                }
                                            </div>
                                        </div>
                                    </div>
                                )
                            } else {
                                return false
                            }
                        })}
                    </section>
                    <section className="third">
                        {this.props.prepayment.invoice.invoice_footer.map(function(item, index){
                            const color =item.hasOwnProperty("color") ? {color : item.color} : {};
                            const bold =item.hasOwnProperty("bold") ? {fontWeight:'bold',color: '#7b7b7b', fontSize:'16px'}:{};
                            if(item.show === 1){
                                return(
                                    <div className="payable" key={index}>
                                        <div className="row">
                                            <div className="col-8 text-left">
                                                {item.bold === 1 ? 
                                                    <div className="title" style={bold}>{item.key}</div>
                                                    :
                                                    <div className="title" style={color}>{item.key}
                                                        {item.sub_key.length > 0  &&
                                                            <div className="green-color">{item.sub_key}</div>
                                                        }
                                                    </div>
                                                }
                                            </div>
                                            <div className="col-4 text-right"> 
                                                {item.bold === 1 ?                                   
                                                <div className="cost" style={bold}>{item.value}</div>
                                                :
                                                <div className="cost" style={color}>{item.value}</div>
                                                }
                                            </div>
                                        </div>
                                    </div>
                                )
                            } else {
                                return false
                            }
                        })}
                    </section>
                </div>                  
            </div>
        );
    }
}

const mapStateToProps = (store) => {
    return {
        prepayment:store.prepayment
    }
}
export default connect(mapStateToProps,null)(Invoice);