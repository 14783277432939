

/* APIs */
// get home page data
let getHomePageDataApi = function (data, args)
{

    let headers = {
        'Accept': 'application/json',
        'Content-Type': 'application/x-www-form-urlencoded',
        'device-unique-id': data.DEVICE_UNIQUE_ID,
    }

    if(data.ACCESS_TOKEN){
        headers.Authorization = 'Bearer ' + data.ACCESS_TOKEN;
    }
    let param;
    if(args !== undefined){
        param = {
            offer:args.offers,
        }
    }
    return data.AXIOS.get(data.BASE_URL_V+'/home', {
        responseType: 'json',
        params: param,
        headers: headers
    });
}

exports.getHomePageDataApi = getHomePageDataApi;