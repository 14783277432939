import React from 'react';
import { connect } from 'react-redux';
import { withRouter } from 'react-router';
import { removeClassToBody } from "../../services/index";
class AboutPropertyModal extends React.Component {
	constructor() {
		super();
		this.goBack = this.goBack.bind(this);
	}

	goBack(){
		this.props.history.goBack();
	}
	componentWillUnmount(){
		removeClassToBody();
	}

	render(){
		return(
			<div>
				<div className="overlay-modal">
					<div className="content-modal full-screen-modal">
					<div className="about-property-modal">
						<div className="modal-header">
							<div className="header-btn close-btn" onClick={this.goBack}></div>
							<h2 className="text-center">About</h2>
							<div className="transparent-gredient"></div>
						</div>
						<div className="modal-body">
							<div className="description-section">
				                <p>{this.props.property.about}</p>
							</div>

							{this.props.property.description.map(function(item, index){
									 // just standard if statement
								    if (item.value.length > 0) {
								        return (
								            <div key={index} className="description-section">
                                                    <h3>{item.title}</h3>
                                                    <p>{item.value}</p>
                                                  
                                                    {item.hasOwnProperty("checkin")
                                                        ? 
                                                        <div>
                                                            <br/>
                                                            <p>{item.checkin.title} : {item.checkin.value}</p>
                                                            <p>{item.checkout.title} : {item.checkout.value}</p>
                                                        </div>
                                                        :
                                                        null
                                                    }                                       
                                                </div>
								        );
								    }
								    return null;
								    
			                })}							
						</div>
					</div>
				</div>
				</div>
			</div>
		)
	}
}

const mapStateToProps = (store) => {
	return {
		property : store.property
	}
}

export default connect(mapStateToProps, null)(withRouter(AboutPropertyModal));