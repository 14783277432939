import React from 'react';
import "../../static/sass/main.scss";
import noInternet from "../../static/img/infoWindow/noInternet.svg";
import {addDeviceId} from '../../services/index.js';
import RedirectEmail from '../redirectemail';
import { connect } from 'react-redux'
import {withRouter} from 'react-router-dom' 
import { updateHomeState } from '../../actions/home/actions'

/**
 * Redux setting
 */

// import entry class
import Tracker from './tracker';
 

class App extends React.Component {
	constructor(props) {
        super(props);
        this.state = {
            localStorage: true,
            prevRoute: false
        }
    }

    componentWillReceiveProps(nextProps) {
        if (nextProps.location !== this.props.location) {
          this.setState({ prevRoute: this.props.location })
        }
    }

    checkCookie() {
        try {
            if (navigator.cookieEnabled) {
                return true;
            }
        } catch (e) {
        }
        return false;
    }


    shouldComponentUpdate(){
        return true;
    }

    checkLocalStorage() {
        try {
            if (typeof localStorage !== 'undefined') {
                localStorage.setItem('feature_test', 'yes');
                if (localStorage.getItem('feature_test') === 'yes') {
                    localStorage.removeItem('feature_test');
                    return true;
                }
            }
        } catch (e) {
        }
        return false;
    }

    componentWillMount() {
        if (!this.checkCookie() && !this.checkLocalStorage()) {
            this.setState({
                localStorage: false,
            })
        } else {
            addDeviceId();
        }
    }



    render() {
        return (       
            <div className="App">
                <Tracker {...this.props}/>
                <RedirectEmail />
                {this.state.localStorage ? 
                    this.props.children(this.state.prevRoute) :

                    <div className="info-window true">
                        <div className="window-container">
                            <div className="imgb">
                                <div className="img" style={{ backgroundImage: 'url(' + noInternet + ')' }}></div>
                            </div>
                            <div className="txtb"><p>Please enable your browser cookie and Local Storage</p></div>
                        </div>
                    </div>
                }
            </div>
        ) 
    }
}

export default withRouter(App);