import React from 'react';
import Modal from 'react-modal';

class VarifyEmailModal extends React.Component {
  render() {
    return (
      <div>
        <Modal
          isOpen={this.props.VarifyEmailModalOpen}
          contentLabel="Modal"
                overlayClassName={{
                base: 'overlay-modal overlay-popup',
                afterOpen: '',
                beforeClose: ''
              }}
          className={{
                base: 'content-modal',
                afterOpen: 'editProfile',
                beforeClose: ''
              }}

        >
          <div className="modal-body text-center"> 
              <a className="back-btn" onClick={this.props.goBack}> </a>
              <div className="text-center varificationEmail">We have sent a verification mail on your email ID {this.props.email} </div>
              <button className="link green" onClick={this.props.goBack}>Done</button>
          </div>
        </Modal>
      </div>
    );
  }
}

export default VarifyEmailModal;