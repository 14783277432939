import { SEARCH_UPDATE_STATE, SEARCH_UPDATE_STATE_RESET, SEARCH_UPDATE_STATE_RESET_FILTER } from "./vars";


export const updateSearchState = (obj = {}, reset = 0) => dispatch => {

    if(!reset){
        dispatch({
            type: SEARCH_UPDATE_STATE,
            payload: obj
        });
    }else{
		dispatch({
			type: SEARCH_UPDATE_STATE_RESET
		});
    }

    return Promise.resolve();
};


export const resetSearchFilter = () => dispatch => {
    dispatch({
        type: SEARCH_UPDATE_STATE_RESET_FILTER
    });
    return Promise.resolve();
};
