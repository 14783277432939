import React from 'react';
import {withRouter} from 'react-router';
import { connect } from 'react-redux';

import { tileRatingColor, addClassToBody } from "../../services/index.js";
import {getObjectVal} from './../../services/helpers';
import { updateProfileStateObj} from '../../actions/profile/actions';
import {updateHomeState} from '../../actions/home/actions';


class PropertyDetail extends React.Component{
    constructor(props) {
        super(props);
		this.imageLoaded = this.imageLoaded.bind(this);
		this.openPropertyGalleryList = this.openPropertyGalleryList.bind(this);
		this.toHostProfile = this.toHostProfile.bind(this);

    }



    decimaltrim(data){
        return Number.parseFloat(data).toFixed(1);
    }

	imageLoaded(e){
		let imgSrc = e.target.src;
		e.target.nextElementSibling.style.backgroundImage = 'url('+imgSrc+')';
	}

	openPropertyGalleryList() {
        sessionStorage.setItem("scrollVal", Math.max(window.pageYOffset, document.documentElement.scrollTop, document.body.scrollTop));
        addClassToBody();
		this.props.history.push("/property/galleryList/"+ this.props.property.property_hash_id);
	}

	toHostProfile() {
		if (this.props.property.host_id){
			let urlParams = '?host_id=' + this.props.property.host_id;
			this.props.history.push({
				pathname: '/user/profile/',
				search: urlParams,
			});
			this.props.updateHomeState({
				itemIndex:0,
			})
			this.props.updateProfileStateObj({
				profile_image:this.props.property.host_image,
				first_name:this.props.property.host_name,
				is_host:1,
				property_listings: [],
				property_listings_all: [],
			})
		}

	}

	render(){
		const this_el = this;
		const ellipsis = {
			 textOverflow: 'ellipsis',
			 overflow:'hidden',
			 WebkitBoxOrient: 'vertical'
		}
	return(

			<div className="preview-wrap">
				<div className="preview-image" onClick={this.openPropertyGalleryList}>
					{this.props.property.property_images.length > 0 &&
						<img src={this.props.property.property_images[0].image} className='hidden' onLoad={(e) => this.imageLoaded(e)} alt="property"/>
					}
					<div className="property-imgb"></div>
					<a className="gallery-btn"><i></i>{this.props.property.property_image_count > 0 ? this.props.property.property_image_count :'- '} photos</a>
					{this.props.property.property_video.length>0 || this.props.property.property_video > 0
					?
					<i className="play-btn"></i>
					:
					null
					}
				</div>
				<div className="property-detail clearfix">
					<div className="row clearfix">
						<div className="property-heading col-9">
							<h1 style={ellipsis}>{this.props.property.property_title}</h1>
							<h3>{this.props.booking.location}</h3>
						</div>
						<div className="rating-review col-3">
							{this.props.property.property_score > 0 &&
								<span>
								<span className={'rating ' + tileRatingColor(this.props.property.property_score)}>{this.decimaltrim(this.props.property.property_score)}</span><br/></span>
							}
							{this.props.property.review_count > 0 &&
								<span className="review">{this.props.property.review_count} {this.props.property.review_count > 1 ? 'reviews' : 'review'}</span>
							}
						</div>
					</div>

					<div className="row clearfix">
						<div className="col-7 host-detail verified">
							<div onClick={this.toHostProfile}>
								<span className="host-pic" style={{backgroundImage:'url('+this.props.property.host_image+')'}}></span>
								<span className="host-name">Hosted By <br/>{this.props.property.host_name !== '' && <span>{this.props.property.host_name}<i></i></span>}</span>
							</div>
						</div>
						{this_el.props.property.flag.api.is_loading ?
							<div className="col-5 property-price">
								{this.props.property.property_pricing.discount > 0
								?
									<div>

										<span className="discount"><span>{this.props.property.property_pricing.original_price}</span> {this.props.property.property_pricing.discount}%</span>
										{this.props.property.property_pricing.is_instant_bookable
										?
										<i className="instant-book-icon"></i>
										:
										null
										}
										<span className="final-price">{this.props.property.property_pricing.per_night_price}<span>Price for 1 night</span></span>
									</div>

								:
									<div>
										{this.props.property.property_pricing.is_instant_bookable
										?
										<i className="instant-book-icon"></i>
										:
										null
										}
										<span className="final-price">{this.props.property.property_pricing.per_night_price}<span>Price for 1 night</span></span>
									</div>
								}
							</div>
						: this.props.error.flag.network
							? <div />
							: <div className="col-5 property-price loading"></div>
						}
					</div>
					{this_el.props.property.flag.api.is_loading &&
						<div>
							{(getObjectVal(this_el.props.property, 'left_div') && this_el.props.property.left_div.title.length > 0 && getObjectVal(this_el.props.property, 'right_div') && this_el.props.property.right_div.title.length > 0)
							?
								<div className="row extra-feature">
									<div className="col-6 pull-left">
										<h4>{this.props.property.left_div.title}</h4>
										<p>{this.props.property.left_div.text}</p>
									</div>

									<div className="col-6 pull-left">
										<h4>{this.props.property.right_div.title}</h4>
										<p>{this.props.property.right_div.text}</p>
									</div>
								</div>
							:
							<div>
								{(getObjectVal(this_el.props.property, 'left_div') && this_el.props.property.left_div.title.length > 0) &&
									<div className="row extra-feature  full-length">
										<div className="col-12 pull-left">
											<h4>{this.props.property.left_div.title}</h4>
											<p>{this.props.property.left_div.text}</p>
										</div>
									</div>
								}

								{(getObjectVal(this_el.props.property, 'right_div') && this_el.props.property.right_div.title.length > 0) &&
									<div className="row extra-feature  full-length">
										<div className="col-12 pull-left">
											<h4>{this.props.property.right_div.title}</h4>
											<p>{this.props.property.right_div.text}</p>
										</div>
									</div>
								}
							</div>
							}
						</div>
					}
				</div>
				{/* <div className="people-mind hidden">
					<i></i>
					<h4>This home is on people’s minds. <span>It’s been viewed 100+ times in 6 days.</span></h4>
				</div> */}
			</div>

		);

	}
}

const mapStateToProps = (store) => {
	return {
		
		property: store.property,
		booking: store.booking,
		error: store.error
	}
}
const mapDispatchToProps = {
	updateProfileStateObj,
	updateHomeState,
}


export default connect(mapStateToProps, mapDispatchToProps)(withRouter(PropertyDetail));
