
/* APIs */
// get signup data
let getUpdateProfileApi = function (data, args)
{   

	var formData = new FormData();
	formData.append('picture', args.picture);
	return data.AXIOS({
		headers: {
                'Accept': 'application/json',
                'Content-Type': 'multipart/form-data',
                'device-unique-id': data.DEVICE_UNIQUE_ID,
                'Authorization' :'Bearer ' + data.ACCESS_TOKEN,
            },
	    method: 'POST',
	    url: data.BASE_URL_V+'/user/picture',
	    data: formData,
	});

    // return data.AXIOS.post(, data.QS.stringify({
    //         picture:args.picture,
    //     }), {
            
    //     }
    // );
}

exports.getUpdateProfileApi = getUpdateProfileApi;