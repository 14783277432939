

/* APIs */
// get signup data
let getLoginApi = function (data, args)
{
    return data.AXIOS.post(data.BASE_URL+'/oauth/token', data.QS.stringify({
            username:args.userId,
            password:btoa(args.password),
            client_id: data.CLIENT_ID,
            client_secret:data.CLIENT_SECRET,
            grant_type:data.GRAND_TYPE,
            scopes:data.SCOPE,
        }), {
            headers: {
                'Accept': 'application/json',
                'Content-Type': 'application/x-www-form-urlencoded',
            }
        }
    );
}

exports.getLoginApi = getLoginApi;