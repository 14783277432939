import React from 'react';
import Modal from 'react-modal';
import { connect } from "react-redux";

class WalletModal extends React.Component {
  render() {
    return (
      <div>
        <Modal
          isOpen={this.props.prepayment.flag.modal.walletModal}
          onRequestClose={this.props.walletModalClose}
          contentLabel="Modal"
                overlayClassName={{
                base: 'overlay-modal overlay-popup',
                afterOpen: '',
                beforeClose: ''
              }}
          className={{
                base: 'content-modal',
                afterOpen: 'editProfile',
                beforeClose: ''
              }}

        >
          <div className="modal-body text-center"> 
              <a className="back-btn" onClick={this.props.walletModalClose}> </a>
              <div className="text-center varificationEmail">If you want to use wallet then your applied promo code will be removed. </div>
              <button className="link green" onClick={this.props.confirmWallet.bind(this, true)}>Ok</button>
          </div>
        </Modal>
      </div>
    );
  }
}

const mapStateToPtops = (store) => {
  return {
    prepayment: store.prepayment
  }
}
export default connect(mapStateToPtops, null)(WalletModal);
