import React from 'react';
import SearchTile from '../../components/propertyTile';
import noInternet from "../../static/img/infoWindow/noInternet.svg";
import whislist_before_login from '../../static/img/infoWindow/whishlist-before-login.png';
import { connect } from 'react-redux';
import serverError from "../../static/img/serverError.png";
//services
import {getWishlistDataApi,hasAccessToken} from '../../services/index.js';
import {trackPageView,trackError} from '../../services/tracker/events.js';

/**
 * Actions
 */
import { updateWishlistStateObj } from '../../actions/wishlist/actions';
import { updatePropertyState } from '../../actions/property/actions';
import { updateBookingDataState } from '../../actions/booking-data/actions';
import { updateErrorState } from '../../actions/error/actions';
import OfflineStatus from '../../components/Offlinestatus';
import { checkIfOffline } from '../../services/helpers';


class Wishlist extends React.Component {
	constructor(props) {
		super(props);
		this.page="wishlist";
		this.state = {
			whishlistStatus:true
		}
		this.toProperty = this.toProperty.bind(this);
		this.reload = this.reload.bind(this);
		this.getWishlistApiData=this.getWishlistApiData.bind(this);
	}

	getWishlistApiData(args){
		let this_el = this;
		getWishlistDataApi(args).then(function(response){
			// success
			this_el.getWishlistApiDataSuccess(response);
		}).catch(function(error){
			// error
			this_el.getWishlistApiDataError(error);
		});
	}

	getWishlistApiDataError(error) {
		let this_el = this;
		if(typeof error.response !== 'undefined' && error.response.status === 1000){
			let error_flag = Object.assign({}, this_el.props.error.flag);
			error_flag.network = true;

			// updating network state
			this_el.props.updateErrorState({
				flag: error_flag
			});

		}else if(error.response.status === 500){
			//console.log('dgdg',error);
			let flag =  this.props.error.flag;
			flag.server = true; 
			this.props.updateErrorState({ flag: flag });
			
		}else{
			trackError(error);
		}
	}

	getWishlistApiDataSuccess(response) {
		sessionStorage.removeItem('wishlistApiHit');

		let this_el = this;

		let flag = Object.assign({}, this_el.props.wishlist.flag);
		flag.no_more_result = (response.data.data.wishlist.length < 5 ); // if no more result flag found, set no_more_result to true
		flag.api.is_loading = false;

		let properties = Object.assign([], this_el.props.wishlist.properties_wishlist);
		Array.prototype.push.apply(properties,  response.data.data.wishlist); 

		this.props.updateWishlistStateObj({ 
				'properties_wishlist': properties,
				dataLoaded: true,
				flag:flag,
				dataLoaded: true 
			}).then( () => {
			if (this.props.wishlist.properties_wishlist.length < 1) {
				this.setState({ whishlistStatus: false })
			}
			// updating network state
			let error_flag = Object.assign({}, this_el.props.error.flag);
			error_flag.network = false;
			error_flag.server= false;
			this_el.props.updateErrorState({
				flag: error_flag
			});
		});
	}
	componentDidMount(){
		const this_el= this;

		let wishlistApiHit = JSON.parse(sessionStorage.getItem('wishlistApiHit'));
		if (wishlistApiHit === null) {
			wishlistApiHit = false;
		}

		let flag = Object.assign({}, this.props.wishlist.flag);
		flag.no_more_result = false;
		flag.api.is_loading = false;

		this.props.updateWishlistStateObj({
			flag:flag,
			properties_wishlist: wishlistApiHit ? [] : this_el.props.wishlist.properties_wishlist
		})

		document.body.classList.add('wishlist-page');

		if (!this_el.props.wishlist.properties_wishlist.length > 0 || wishlistApiHit) {
			this_el.submitParams(wishlistApiHit);
		} else {
			this_el.props.updateWishlistStateObj({
				dataLoaded: true
			})
		}

		if (checkIfOffline()) {
			this.props.updateErrorState({ flag: { network: true } });
		}

		this_el.loadMoreResults();
		
		trackPageView(this.props.tracker, this.page);
	}
	
	componentWillUnmount(){
		const this_el = this;
		document.body.classList.remove('wishlist-page');

		let error_flag = Object.assign({}, this_el.props.error.flag);
		error_flag.network = false; // setting no network error
		console.log('Unmounter')
		window.removeEventListener('scroll', this.scrollFn)
		// Resetting errors to default
		this_el.props.updateErrorState({
			message: '',
			flag: error_flag
		});
	}


	submitParams(reset){
		const this_el = this;
		let args = {
			total:5,
			offset:reset ? 0 : this_el.props.wishlist.offset,
		}
		if (reset) {
			this.props.updateWishlistStateObj({
				offset: 0
			});
		}
		if(hasAccessToken()){
			this.getWishlistApiData(args);
		}
	}

	loadMoreResults(){
		const this_el = this;
		
		this.scrollFn = function(){
			
			let whislist_list =false;
            if (document.getElementById('whislist_list') !== null){
                whislist_list = true;
            }else {
                whislist_list = false;
            }
			if(whislist_list){
			//let offset = document.documentElement.scrollTop;
			const scrollTopOffset = Math.max(window.pageYOffset, document.documentElement.scrollTop, document.body.scrollTop);

			let windowHeight = window.innerHeight;
			let bodyHeight = document.body.clientHeight-860; // was 260
			let offsetWindowHeight = windowHeight + scrollTopOffset;
			if ((offsetWindowHeight >= bodyHeight) && !this_el.props.wishlist.flag.api.is_loading && !this_el.props.wishlist.flag.no_more_result){

				/**
				 * Updating api state to true, so in case of scroll no more api hit called, untill last api result popup
				 */

				let new_offset = this_el.props.wishlist.offset + 5;
				let flag = Object.assign({}, this_el.props.wishlist.flag);
				flag.api.is_loading = true;

                this_el.props.updateWishlistStateObj({
					offset : new_offset,
					flag : flag
				}).then(() => {
					this_el.submitParams();
				});

			}
			}
		}

		window.onscroll = this.scrollFn;
	}

	/**
	* To Property
	*/
	toProperty(data) {
		const this_el = this;

		if (data !== undefined) {
			this_el.props.updatePropertyState({
				'property_images': data.property_images,
				'property_title': data.property_title,
				'title': data.title,
				'property_score': data.property_score,
				'host_image': data.host_image,
				'host_name': data.host_name
			})
			this_el.props.updateBookingDataState({
				'location': data.location.location_name,
				'lat': data.location.latitude,
				'lng': data.location.longitude,
				checkin_date: 1,
				checkout_date: 1,
				guest_count: 0,
				unit_count: 0


			})
		}

		this_el.props.history.push({
			pathname: '/property/' + data.property_hash_id
		});

	}

	reload(){
		this.submitParams();
	}

	render(){
		const this_el= this;
		const style ={
			padding:'20px'
		}
		return (
			<div id="whislist_list">
				<header className="header active">
					<div className="row">
						<div className="col-12 text-left">
							<span className="back-icon" onClick={() => this.props.history.push('/account')}></span>
							<span className="middle-text text-center">Wishlist</span>
						</div>                                                      
					</div>
				</header>
				<div className="wishlist-content">
					{!this.props.error.flag.network || this.props.wishlist.dataLoaded ?
						<div>
							{!this.props.error.flag.server ?
							<div>
							<OfflineStatus
								offline={checkIfOffline()}
								bottom
							/>
						{this.state.whishlistStatus ?
						<SearchTile properties_list={this.props.wishlist.properties_wishlist} toProperty={this.toProperty} />
						:
						<div className="info-window">
							<div className="window-container afterlogin">
								<div className="imgb">
									<div className="img" style={{backgroundImage:'url(' + whislist_before_login + ')'}}></div>
								</div>
								<div className="txtb"><p>You don't seem to have selected any homes you love</p></div>
							</div>
						</div>
						}
						<img src={noInternet} className="hidden" alt='no internet'/>
						
						{ this_el.props.wishlist.flag.api.is_loading ?
							<div className={'loader'}>
								<div className="timeline-item">
									<div className="animated-background facebook"></div>
								</div>
							</div>
							: this_el.props.wishlist.flag.no_more_result && this.state.whishlistStatus && <p className='text-center' style={style}>No more result!</p>
						}
						
					</div>
					:
						<div className="info-window">
							<div className="window-container">
								<div className="imgb">
									<div className="img" style={{ backgroundImage: "url(" + serverError + ")" }}/>
								</div>
								<div className="txtb">
									<p>Sorry, our servers are busy. Please try again in a bit.</p>
								</div>
								<button className="retry" onClick={this.reload}>Retry</button>
							</div>
						</div>
					}
					</div>
					:
						<div>
							<div className="info-window">
								<div className="window-container">
									<div className="imgb">
										<div className="img" style={{backgroundImage:'url(' + noInternet + ')'}}></div>
									</div>
									<div className="txtb"><p>Oops! You don't seem to be connected to the internet.</p></div>
									<button className="retry" onClick={this.reload}>Retry</button>
								</div>
							</div>
						</div>
						
					}
				</div>
				{!this.props.error.flag.network && 
				<div>
				</div>
			}
			</div>
		)
	}
}
const mapStateToProps = (store) => {
	return {
		wishlist: store.wishlist,
		tracker: store.tracker.tracker,
		error: store.error
	}
}
const mapDispatchToProps = {
	updateWishlistStateObj,
	updatePropertyState,
	updateBookingDataState,
	updateErrorState
}

export default connect(mapStateToProps, mapDispatchToProps)(Wishlist);