

/* APIs */
// get getDialCodes data
let getDialCodesApi = function (data, args)
{
    return data.AXIOS.get(data.BASE_URL_V+'/dialcodes', {
            headers: {
                'Accept': 'application/json',
                'Content-Type': 'application/x-www-form-urlencoded',
                'device-unique-id': data.DEVICE_UNIQUE_ID,
                'Authorization' :'Bearer ' + data.ACCESS_TOKEN,
            }
        }
    );
}

exports.getDialCodesApi = getDialCodesApi;