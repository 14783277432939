import React from 'react';
import ReactDOM from 'react-dom';
import ResendOtp from "../../components/modal/resendOTP/resend.js";
import varifyImg from "../../static/img/otp2.png";
import ResponseMessage from '../../components/response/';
import {connect} from 'react-redux';
/**
 * Actions
 */
import {updateOtpStateObj} from './../../actions/otp/actions';
import {updateSignupStateObj} from '../../actions/signup/actions'

// services
import {getOtpVerifyApi} from '../../services/index.js';
var Form = require('react-formal');

var yup = require('yup');
var useOTPSchema = yup.object({ });

class OtpVerify extends React.Component {
    constructor(props) {
        super(props);
        this.sms_otp_method = 1;
        this.call_otp_method = 2;
        this.viaSms = this.viaSms.bind(this);
        this.viaCall = this.viaCall.bind(this);
        this.submitOTPVerifyDetail = this.submitOTPVerifyDetail.bind(this);
        this.getOtpVerifyApiDataError = this.getOtpVerifyApiDataError.bind(this);
        this.getOtpVerifyiDataSuccess = this.getOtpVerifyiDataSuccess.bind(this);
        this.resendOtpOpen = this.resendOtpOpen.bind(this);
        this.resendOtpClose = this.resendOtpClose.bind(this);
        this.otpInput = this.otpInput.bind(this);
        this.handleSubmit = this.handleSubmit.bind(this);
        this.onKeyDown = this.onKeyDown.bind(this);
        this.resetMessages = this.resetMessages.bind(this);
        this.getOtpApiDataError = this.getOtpApiDataError.bind(this);
        this.getOtpApiDataSuccess = this.getOtpApiDataSuccess.bind(this);
        window.onpopstate = this.onBackButtonEvent.bind(this);
        this.otpTimer = this.otpTimer.bind(this);
        this.onInputClick = this.onInputClick.bind(this);
    }


    onInputClick(event){

            var SearchInput = event.target;
            var value = SearchInput.value;
            SearchInput.value = '';
            SearchInput.value = value;
            ReactDOM.findDOMNode(event.target).focus();
    }
    


    resetMessages(){
        this.props.updateOtpStateObj({
            show:false,
            'message':'',
        });
    }
    handleSubmit(event) {
        this.props.updateOtpStateObj({loading:true});
        
        this.submitOTPVerifyDetail({
            'otp_code': this.props.otp.inputValue.join(''),
            'dial_code': this.props.dial_code,
            'contact_number': this.props.contact_number,
        });
    }

    submitOTPVerifyDetail(args){
        let this_el = this;
        getOtpVerifyApi(args).then(function(response){
            // success
            this_el.getOtpVerifyiDataSuccess(response);
            this_el.props.updateOtpStateObj({loading:false});
        }).catch(function(error){
            // error
            this_el.props.updateOtpStateObj({loading:false});
            if(error.hasOwnProperty('response') && error.response === undefined){
                let networkError = {
                    response:{
                        data:{
                            status:false,
                            error:[{
                                message:'Please check your internet connection'
                            }]
                        }
                    }
                }
               // this_el.props.updateOtpStateObj({status:false});
                this_el.getOtpVerifyApiDataError(networkError);
            }
            else{
                this_el.getOtpVerifyApiDataError(error);
            }
        });
    }

    getOtpVerifyApiDataError(error) {
        this.props.updateOtpStateObj({
            'message': error.response && error.response.data.error[0].message,
            'status': error.response && error.response.data.status,
            show:true,
        });
    }
    
    getOtpVerifyiDataSuccess(response) {
       const urlAfterLogin = this.props.afterLogin;
       if (urlAfterLogin.length > 0) {
           this.props.openNewUrl(urlAfterLogin)
       } else {
        this.props.openNewUrl('/account')
       }
    }

    resendOtpOpen() {
        this.props.updateOtpStateObj({show_resend_otp: true});
    }
       
    resendOtpClose() {
        this.props.updateOtpStateObj({show_resend_otp: false});
    }

    onKeyDown(e, item) {
        if (e.keyCode === 8) {
            if (e.target.value.length === 0) {
                let prevSibling = ReactDOM.findDOMNode(e.target).previousSibling;
                if (prevSibling !== null && prevSibling.nodeName.toLowerCase() === 'input') {
                    prevSibling.focus();
                    e.preventDefault();
                }
            }
        }
    }

    otpInput(e){
        if (e.target.value.length > 1) {
            e.target.value = e.target.value[e.target.value.length - 1];
        }

        if (e.target.value.length === 1) {
            let nextSibling = ReactDOM.findDOMNode(e.target).nextSibling;
            if (nextSibling !== null && nextSibling.nodeName.toLowerCase() === 'input') {
                nextSibling.focus();
            }
        }
    }

    onBackButtonEvent() {
        this.props.updateState({show_send_otp: true});
        this.props.updateOtpStateObj({
            inputValue:['', '', '', '']
        })
    }

    updateInputValue(e) {
        let items = this.props.otp.inputValue;
        items[e.target.dataset.index] = e.target.value;
        this.props.updateOtpStateObj({
            inputValue: items
        });
    }

    viaSms(){
        this.props.updateOtpStateObj({show_resend_otp:false});
        this.props.submitOTPDetail({
            'dial_code': this.props.otp.model.dial_code,
            'contact_number': this.props.otp.model.contact_number,
            'otp_method': this.sms_otp_method
        }, this.getOtpApiDataSuccess, this.getOtpApiDataError);
        this.otpTimer();
    }

    viaCall(){
        this.props.updateOtpStateObj({show_resend_otp:false});
        this.props.submitOTPDetail({
            'dial_code': this.props.otp.model.dial_code,
            'contact_number': this.props.otp.model.contact_number,
            'otp_method': this.call_otp_method
        }, this.getOtpApiDataSuccess, this.getOtpApiDataError);
        this.otpTimer();
    }

    getOtpApiDataError(error) {
        this.props.updateOtpStateObj({
            'message': error.response.data.error[0].message,
            'status': error.response.data.status
        });
    }
    
    getOtpApiDataSuccess(response) {
        this.props.updateOtpStateObj({
            'message': response.data.data.message,
            'status': response.data.status
        });
    }

    otpTimer(){
        const this_el = this;
        var timer = 30;
        var timeinterval = setInterval(function(){
            timer--
            if(timer<1){
                clearInterval(timeinterval);
            }
            this_el.props.updateOtpStateObj({
                otpTimer:timer,
            })
        },1000)
    }

   
    render() {

        const this_el = this;
        var loader = this.props.otp.loading ? 'loading active' : 'loading ';
        return (
            <div className="otp">  
            <div className={loader}></div>

                <header className="header" >
                    <span className="back-btn-grey" onClick={() => this.onBackButtonEvent()}></span>
                </header>
                <div className="otpImg"><img src={varifyImg} alt="varify" /></div>
                <div className="otp-wrap">                                   
                    <Form schema={useOTPSchema} className="step3" onSubmit={this.handleSubmit}>
                        <div className="text">We have sent a four digit code on <span>+{this.props.dial_code}-{this.props.contact_number}</span></div>
                        <div className="control">
                            {[1,2,3,4].map(function(item, index){
                                return (
                                    <input type="number"
                                        pattern="\d*"
                                        key={index}
                                        ref="input"
                                        data-index={index}
                                        value={this_el.props.otp.inputValue[index]}
                                        onChange={(e) => this_el.updateInputValue(e)}
                                        className="form-control"
                                        placeholder=""
                                        maxLength="1"
                                        onInput={(e) => this_el.otpInput(e)}
                                        onKeyDown={(e, item) => this_el.onKeyDown(e, item)}
                                        onClick={(e) => this_el.onInputClick(e)}
                                    />
                                )
                            })}
                        </div>
                        {this.props.otp.otpTimer > 0 ?
                            <div className="termss">Please enter the code or resend in {this.props.otp.otpTimer !== 0 && <span className="time">{this.props.otp.otpTimer}</span>} seconds</div> 
                        :
                            <div>
                                <div className="termss">Please enter the code or resend if not received.</div> 
                                <div className="referal text-center" onClick={() => this.resendOtpOpen()}>Resend code</div>
                            </div>
                        }
                        
                        <Form.Button  type='submit' className="btn login link">Continue</Form.Button>
                    </Form>
                </div>
                <div className={'responseAPI '+this.props.otp.show }>
                    <ResponseMessage responseMessage={this.props.otp.message} responseStatus={this.props.otp.status} resetMessages={this.resetMessages}/>
                </div>
                <ResendOtp
                    resendOtpOpen={this.props.otp.show_resend_otp}
                    resendOtpClose={this.resendOtpClose}
                    viaCall={this.viaCall}
                    viaSms={this.viaSms}/>
            </div>
        );
    }
}
const mapStateToProps = (store) => {
	return {
        otp : store.otp,
        afterLogin: store.signupEmail.afterLogin
    }
}
const mapDispatchToProps = {
    updateOtpStateObj,
    updateSignupStateObj
}
export default connect(mapStateToProps, mapDispatchToProps) (OtpVerify);