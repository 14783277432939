

const device_unique_id_var = 'device_unique_id';

// generate device uniuq id
let generateDeviceUniqueId = function() {
    let dt = new Date().getTime();
    let uuid = 'xxxxxxxx-xxxx-4xxx-yxxx-xxxxxxxxxxxx'.replace(/[xy]/g, function(c) {
        let r = (dt + Math.random()*16)%16 | 0;
        dt = Math.floor(dt/16);
        return (c === 'x' ? r : (r & (0x3|0x8))).toString(16);
    });
    return uuid;
}

// set device unique id in localstorage
let setDeviceUniqueId = function(device_unique_id) {
    localStorage.setItem(device_unique_id_var, device_unique_id);
}

// get device unique id from localstorage
let getDeviceUniqueId = function() {
    let device_unique_id = localStorage.getItem(device_unique_id_var);
    if (device_unique_id === null || device_unique_id === '' || device_unique_id.length < 25) {
        //device_unique_id = generateDeviceUniqueId();
        //setDeviceUniqueId(device_unique_id);
        device_unique_id = '';    
    }
    return device_unique_id;
}


exports.getDeviceUniqueId = getDeviceUniqueId;
exports.setDeviceUniqueId = setDeviceUniqueId;
exports.generateDeviceUniqueId = generateDeviceUniqueId;
