import React from 'react';
import ReactDOM from 'react-dom';
import { connect } from "react-redux";
import { checkIfOffline } from '../../services/helpers';

class PaymentSection extends React.Component {
    constructor(props){
        super(props);
        this.getPaymentMathod = this.getPaymentMathod.bind(this);
    }
    componentDidMount(){
        ReactDOM.findDOMNode(this.refs[this.props.prepayment.invoice.selected_payment_method]).checked = 1;
    }

    getPaymentMathod(e){
        if (checkIfOffline()) {
            this.props.toggleStatus()
        } else {
            this.props.getPaymentMathod(e.target.value);
        }
    }
   
    render() {
        const this_el = this;
       
        return (
            <section className="paymentSection">
                <div className="heading">How would you like to pay?</div>
                    <div className="choose-payment-mathod">
                        {this.props.prepayment.payment_methods.map(function(item, index){
                            return(
                            <div className="box" key={index}>
                                <span className={'icon ' + item.key}></span>
                                <span className="text">
                                    <span className="title">{item.title}</span>
                                    <span className="subtitle">{item.description}</span>
                                </span>

                                <span className="checkbox">
                                    <input type="radio" name="radiog_dark" id={item.key} value={item.key} ref={item.key} onChange={(e) => this_el.getPaymentMathod(e)} className="css-checkbox" />
                                    <label htmlFor={item.key} className="css-label radGroup2"></label>
                                </span>
                            </div>
                            )
                        })}
                    </div>
            </section>
        );
    }
}

const mapStateToProps = (store) => {
    return {
        prepayment:store.prepayment
    }
}
export default connect(mapStateToProps,null)(PaymentSection);