// actions
import { BOOKING_DATA_UPDATE_STATE, BOOKING_DATA_UPDATE_STATE_RESET } from "../actions/booking-data/vars";
import { getURLParameter } from '../services';

// initialState
const toDate = (dateStr) => {
    if (dateStr !== null) {
        var parts = dateStr.split("-");
        return new Date(parts[2], parts[1] - 1, parts[0]);
    } else {
        return 1;
    }
}

const initialState = {
    location: '',
    area: '',
    city: '',
    state: '',
    country: {},
    lat: '',
    lng: '',
    bedroom : 0,

    checkin_date: window.location.search.includes('checkin') ? toDate(getURLParameter(window.location.search, 'checkin')) : (1),
    checkout_date: window.location.search.includes('checkout') ? toDate(getURLParameter(window.location.search, 'checkout')) : (1),
    guest_count: window.location.search.includes('guests') ? (parseInt(getURLParameter(window.location.search, 'guests'), 10)) : (0),
    unit_count: window.location.search.includes('units') ? (parseInt(getURLParameter(window.location.search, 'units'), 10)) : (0),
    room_type: '',
    property_type: '',
    resetted: false
}

// action functions
const fnc = (state = initialState, action) => {
    switch (action.type) {
        case BOOKING_DATA_UPDATE_STATE:

            let newState = Object.assign({}, state, action.payload);
            return newState;

        case BOOKING_DATA_UPDATE_STATE_RESET:
            return initialState;

        default:
            return Object.assign({}, state);
    }
};
export default fnc;
