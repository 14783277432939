import { SET_STATE_TRIP_OBJ, SET_STATE_TRIP_RESET } from './vars';

export const updateTripState = (obj = {}, reset = 0) => dispatch => {
    if (!reset) {
        dispatch({
            type: SET_STATE_TRIP_OBJ,
            payload: obj
        });
    }else {
        dispatch({
            type: SET_STATE_TRIP_RESET,
        });

    }
    return Promise.resolve();
}